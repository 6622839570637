<template>

    <v-app  fluid >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>

        <v-main v-if="VUE_APP_SITE_ID === '1'">
            <v-col class="py-0">
                <v-row>
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 tc_blue font-weight-bold rotate">
                            Jantes - Monobloc
                        </h1>

                </v-col>
                <v-col lg="5" >
                    <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5 tc_blue font-weight-bold ">
                        Jantes - Monobloc
                    </h1>
                    <v-content class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                        <p>Les jantes monobloc sont composées d’une seule pièce. Cette technique désormais courante est apparue dans les années 1970.
                            On emploi un procédé appelé rhéoformage et thixoformage.
                            Ces termes scientifiques désignent la mise en forme d’un alliage à partir de son état liquide.
                            Cela concerne les jantes – monobloc en aluminium et en magnésium. Elles sont coulées dans un moule puis on injecte de l’azote dans l’alliage liquide afin d’éviter d’éventuelles bulles d’air. Puis après refroidissement et démoulage, on obtient la jante à la forme désirée.
                            Le produit ainsi obtenu à des caractéristiques de haute compacité, d’excellente tenue mécanique, et la précision dimensionnelle des pièces est parfaite.</p>
                        <p>
                            On peut obtenir des détails et des ciselures d’une grande exactitude tout en étant résistante.
                        </p>
                        <p class=" mb-3 pl-4 border_left tc_turquoise font-weight-bold
                                            text-xl-h5
                                        text-lg-body-1
                                        text-md-body-1
                                        text-sm-body-1
                                        text-body-1">Avec les jantes monoblocs on parle de branches et non de rayons. </p>
                        <p>
                            Le nombre de branches sur une jante varie de 3 à 7.
                            Cette méthodologie permet d’obtenir des meilleures performances grâce à un poids plus faible, et une rigidité supérieure.
                            Par contre la jante monobloc n’apprécie pas les chocs et reste sensible aux coups.
                        </p>
                    </v-content>

                </v-col>
                <v-col class="bc_blue" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                    <v-img src="@/assets/AboutUs.png"/>
                </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phone.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold tc_blue align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="3" xl="3" class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="2" offset-lg="1" xl="3" lg="3">
                        <v-img style="position: absolute; width: 20%;" src="@/assets/illustration-man.png"/>
                    </v-col>
                </v-row>
            </v-container>

            <v-col v-if="$vuetify.breakpoint.lgAndUp" class="bc_white tc_black" >
                <v-row class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col offset-lg="1" lg="10">
                        <br>
                        <br>
                        <p class="font-weight-bold
                                   text-xl-h5
                                   text-lg-h6
                                   text-md-subtitle-1
                                   text-sm-subtitle-1
                                   text-subtitle-2">JANTES – MONOBLOC – DÉMONTABLES OU À RAYONS</p>
                        <br>
                        <v-row>
                            <v-col>
                                <p class="font-weight-bold
                                   text-xl-h5
                                   text-lg-h6
                                   text-md-subtitle-1
                                   text-sm-subtitle-1
                                   text-subtitle-2">JANTES DÉMONTABLES</p>
                                <br>
                                <p>
                                    Les jantes démontables en deux ou trois parties sont le haut de gamme.
                                    C’est la jante taillée sur-mesure pour répondre à toutes vos attentes.
                                    Vous allez sublimer votre véhicule. Vous allez pouvoir choisir le déport, la largeur et la couleur de la jante.
                                </p>
                                <br>
                                <p>
                                    Les jantes démontables sont ajustées au millimètre près à la carrosserie de votre véhicule.
                                    Ce système de jante est adapté au tuning comme à la compétition.
                                    Avec ce dispositif, vous aurez des jantes uniques et conçus avec un déport et un entraxe réglementaire.
                                    De plus les collectionneurs peuvent se procurer des jantes – monobloc d’époques ou rares.
                                </p>
                                <br>
                            </v-col>
                            <v-col>
                                <p class="font-weight-bold
                                   text-xl-h5
                                   text-lg-h6
                                   text-md-subtitle-1
                                   text-sm-subtitle-1
                                   text-subtitle-2">JANTES À RAYON</p>
                                <br>
                                <p>
                                    La fabrication des jantes à rayon est très ancienne, elle a succédé aux jantes en bois du début du siècle.
                                    On trouve encore ce style de jante sur les voitures de collection.
                                    Les rayons étaient croisés entre eux et formait des triangles pour plus de solidité.
                                    La conception des jantes – monobloc à rayon existe toujours.
                                    Maintenant elles sont en aluminium et sont munies d’une centaine de rayons partant du moyeu et vont s’étaler au cerclage extérieur de la jante.
                                </p>
                                <br>
                                <p>
                                    Ce système est relativement simple et économique.
                                    La jante est reliée au moyeu par une multitude de rayons.
                                    On peut dire que la forme en rayon donne un meilleur coefficient de déformation de la roue et atténue les chocs dus aux aspérités des routes.
                                    Par contre le nettoyage des rayons de la jante nécessite beaucoup de minutie.
                                </p>

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                Pour en savoir plus sur les jantes, le lecteur pourra se reporter aux différents articles qui traitent de cette question, sur le présent site.
                            </v-col>
                        </v-row>



                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="$vuetify.breakpoint.mdAndDown" class="bc_white tc_black" >
            <v-row class="font-weight-regular
                                    text-xl-h5
                                    text-lg-body-1
                                    text-md-caption
                                    text-sm-caption
                                    text-caption">
                <v-col offset-lg="1" lg="10">
                    <p class="font-weight-bold
                               text-xl-h5
                               text-lg-h6
                               text-md-subtitle-1
                               text-sm-subtitle-1
                               text-subtitle-2">JANTES – MONOBLOC – DÉMONTABLES OU À RAYONS</p>
                    <br>
                    <p class="font-weight-bold
                               text-xl-h5
                               text-lg-h6
                               text-md-subtitle-1
                               text-sm-subtitle-1
                               text-subtitle-2">JANTES DÉMONTABLES</p>

                    <p>
                        Les jantes démontables en deux ou trois parties sont le haut de gamme.
                        C’est la jante taillée sur-mesure pour répondre à toutes vos attentes.
                        Vous allez sublimer votre véhicule. Vous allez pouvoir choisir le déport, la largeur et la couleur de la jante.
                    </p>
                    <br>
                    <p>
                        Les jantes démontables sont ajustées au millimètre près à la carrosserie de votre véhicule.
                        Ce système de jante est adapté au tuning comme à la compétition.
                        Avec ce dispositif, vous aurez des jantes uniques et conçus avec un déport et un entraxe réglementaire.
                        De plus les collectionneurs peuvent se procurer des jantes – monobloc d’époques ou rares.
                    </p>
                    <br>

                    <p class="font-weight-bold
                               text-xl-h5
                               text-lg-h6
                               text-md-subtitle-1
                               text-sm-subtitle-1
                               text-subtitle-2">JANTES À RAYON</p>
                    <p>
                        La fabrication des jantes à rayon est très ancienne, elle a succédé aux jantes en bois du début du siècle.
                        On trouve encore ce style de jante sur les voitures de collection.
                        Les rayons étaient croisés entre eux et formait des triangles pour plus de solidité.
                        La conception des jantes – monobloc à rayon existe toujours.
                        Maintenant elles sont en aluminium et sont munies d’une centaine de rayons partant du moyeu et vont s’étaler au cerclage extérieur de la jante.
                    </p>
                    <br>
                    <p>
                        Ce système est relativement simple et économique.
                        La jante est reliée au moyeu par une multitude de rayons.
                        On peut dire que la forme en rayon donne un meilleur coefficient de déformation de la roue et atténue les chocs dus aux aspérités des routes.
                        Par contre le nettoyage des rayons de la jante nécessite beaucoup de minutie.
                    </p>
                    <br>
                    <p>Pour en savoir plus sur les jantes, le lecteur pourra se reporter aux différents articles qui traitent de cette question, sur le présent site.</p>
                </v-col>
            </v-row>
        </v-col>
        </v-main>

        <v-main v-if="VUE_APP_SITE_ID === '2'">
            <v-col class="py-0">
                <v-row>
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 font-weight-bold rotate">
                            Jantes - Monobloc
                        </h1>

                </v-col>
                <v-col lg="5" >
                    <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5 font-weight-bold ">
                        Jantes - Monobloc
                    </h1>
                    <v-content class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">Les différents types de jantes : monobloc et démontables
                        </p>
                        <p>
                            Il existe différents types de jantes, les plus courants étant monobloc ou démontables. Nous évoquerons également, de manière anecdotique, la jante à rayons.
                        </p>

                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">Jantes monobloc
                        </p>
                        <p>
                            Les jantes dites monobloc, très répandues, sont moulées d’une seule pièce.
                            Les jantes monobloc sont composées de 3 à 7 branches (à ne pas confondre avec les jantes à rayons) ce qui permet de réduire leur poids tout en améliorant leur solidité avec une petite réserve malgré tout quant à leur résistance aux chocs.
                            Le principe de fabrication d’une jante consiste à couler de l’aluminium dans un moule conçu pour l’usage. Toutefois, les techniques diffèrent, voici les principales :
                        </p>
                        <p>
                           - Coulage par gravitation : <br>
                            Très schématiquement, il s’agit, ici, de faire couler de manière gravitaire de l’aluminium (800°) dans le moule.
                        </p>
                        <p>
                            - Moulage basse pression : <br>
                            L’aluminium basse pression est « propulsé » dans le moule ce qui garantit une répartition uniforme dans ce dernier. La jante, ainsi produite, affiche un ratio poids-résistance très satisfaisant.
                        </p>
                        <p>
                            -Fluage ou fluotournage : <br>
                            Une triple combinaison : procédé basse pression avec chaleur et effet de rotation du moule.
                        </p>
                        <p>
                            - Jantes forgées : <br>
                            Une technique pointue pour obtenir la Rolls-Royce de la jante, à la fois légère, dense et robuste, modelée à partir d’un bloc aluminium.
                        </p>

                    </v-content>

                </v-col>
                <v-col class="pa-0" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                    <v-img src="@/assets/AboutUsDJ.png"/>
                </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phoneDJ.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33974191801">09 74 19 18 01</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="5" offset-lg="1"  class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>

                </v-row>
            </v-container>

            <v-col class="bc_white tc_black">
                <v-row style="margin-top: 5%"  class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col offset-lg="1" lg="10" >
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">Jantes démontables
                        </p>
                        <br>
                        <p>
                            Les jantes démontables se composent de trois éléments, centre, voile et flasque.
                            Ces derniers sont conçus indépendamment selon les modes de fabrication décrits ci-dessus. Ainsi, les disques peuvent être conçus à partir d’aluminium ou de magnésium.
                            La jante démontable, considérée comme une véritable jante « de luxe » sur mesure, s’adapte à toutes les configurations (déport, largeur…). Elle est largement utilisée dans le cadre du tuning ou lors de compétitions sportives.
                        </p>
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">Jantes à rayon
                        </p>
                        <p>
                            Un type de jante qui équipe notamment nombre de voitures de collection.
                        </p>
                        <p>
                            La jante à rayons est dotée d’une centaine de rayons en aluminium, combinés solidement entre eux par des croisements en forme de triangles. Chaque rayon s’étend du moyeu central jusqu’à l’extérieur de la jante.
                            D’un point de vue mécanique, la présence et le positionnement des rayons induisent un meilleur amortissement des déformations de la chaussée, courantes au 19ème siècle !
                            Maintenant que vous connaissez toutes les notions techniques pour choisir une jante, à vous de jouer !
                        </p>

                    </v-col>
                </v-row>

            </v-col>


        </v-main>

        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "../headers/HomepageHeaderEj";
import HomepageHeaderDj from "../headers/HomepageHeaderDj";
import HomepageFooterEj from "../footer/HomepageFooterEj";
import HomepageFooterDj from "../footer/HomepageFooterDj";

    export default {
        name: "JantesMonobloc",
        components:{
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,

        },
        data: () => ({
            VUE_APP_SITE_ID:null
        }),
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        },
        metaInfo() {
            return {
                title: `Jantes Monobloc - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + `  - Jantes, Pneus & Accessoires`,
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `Jantes Monobloc - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {name: 'twitter:description', content: `Les jantes monobloc sont composées d’une seule pièce. Découvrez les détails présentés par ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `Jantes Monobloc -` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {property: 'og:site_name', content: this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`},
                    {property: 'og:type', content: 'Website'},
                    {property: 'og:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},
                    {property: 'og:description', content: `Les jantes monobloc sont composées d’une seule pièce. Découvrez les détails présentés par ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    {name: 'robots', content: 'index,follow'}
                ]
            }
    },
    }
</script>

<style scoped>
    .border_left{
        border-left: 2px solid #1DE4AF
    }
    .rotate {
        transform: rotate(-90deg);
        margin-left: -8%;
        margin-top: 12%;
        position: absolute;
    }
</style>
