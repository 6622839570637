<template>

    <v-app fluid >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>

        <v-main v-if="VUE_APP_SITE_ID === '1'">
            <v-col class="py-0">
                <v-row>
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                            <h1 class="text-xl-h2 text-lg-h3 tc_blue font-weight-bold rotate">
                                Jantes - Description
                            </h1>

                    </v-col>
                    <v-col lg="5" >
                        <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5  tc_blue font-weight-bold ">
                            Jantes - Description
                        </h1>
                        <v-content class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                        <p class=" mb-3 pl-4 border_left tc_turquoise font-weight-bold
                                            text-xl-h5
                                        text-lg-body-1
                                        text-md-body-1
                                        text-sm-body-1
                                        text-body-1" >La jante est une pièce mécanique de forme circulaire tournant autour d’un axe.</p>
                        <p>
                            Même si cette invention est très ancienne, elle constitue un des fondements des systèmes de locomotion.
                            Les jantes sont utilisées dans tous les moyens de transport que ce soit le vélo, la voiture, le camion ou l’avion. Cette technologie permet de transporter des charges importantes en diminuant les forces de friction.
                        </p>

                        <p class="font-weight-medium">La jante est étroitement liée à plusieurs composants :</p>
                        <ul>
                            <li>
                                Le pneu qui se fixe sur l’extérieur de la jante et la protège des frottements avec le revêtement de sol.
                                La qualité du pneu contribue au rendement du mécanisme. L’ensemble pneu-jante permet d’obtenir des qualités variables de vitesse, d’usure, de fiabilité, de tenue de route.
                                Les pneus de voitures sont en caoutchouc à armature d’acier, et ce fût Michelin qui le mit au point en 1891.
                            </li>
                            <br>
                            <li>Le voile est l’élément central qui se vient se fixer à l’intérieur de la jante, sur la flasque du moyeu. Le voile est vissé par des écrous de roue.</li>
                            <br>
                            <li>
                                Le moyeu est la partie centrale de la jante. Il assure le guidage de la roue autour de l’essieu qui relie deux roues.
                                Ces moyeux sont constitués de roulement à billes ou de paliers hydrodynamiques.
                                L’ensemble garantie une liaison mécanique parfaite. Le tambour ou le disque de frein sont aussi coalescent du moyeu.
                            </li>
                            <br>
                            <li>
                                La structure des jantes – définition, peut être en différentes matières tel que l’acier, la tôle ou bien encore l’aluminium.
                                Pour la compétition on trouve des jantes en magnésium et en fibres composites comme le carbone.
                                Les jantes peuvent être monobloc ou à rayons.
                            </li>


                        </ul>

                        </v-content>
                    </v-col>
                    <v-col class="bc_blue" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-img src="@/assets/AboutUs.png"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phone.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold tc_blue align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="3" xl="3" class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="2" offset-lg="1" xl="3" lg="3">
                        <v-img style="position: absolute; width: 20%;" src="@/assets/illustration-man.png"/>
                    </v-col>
                </v-row>
            </v-container>

            <v-col v-if="$vuetify.breakpoint.lgAndUp" class="bc_white tc_black">
                <v-row style="margin-top: 5%"  class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col offset-lg="1" lg="5" >
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">L’ANTIQUITÉ</p>
                        <br>
                        <p>
                            Pour les archéologues l’invention de la roue est située vers 3500 ans avant J.-C.
                            Les premières roues ont été trouvées en Mésopotamie dans la ville de Sumer.
                            Elles étaient en bois ou en pierre et servaient au transport de lourdes charges.
                            Des pictogrammes sumériens montrent des chariots dotés de roues pleines, tractées par des animaux.
                            Parallèlement, des roues à quatre rayons ont été découvertes en Egypte vers 1700 ans avant J.-C.
                            Le principe de la roue n’a pratiquement pas évolué pendant 5000 ans.
                            Quelque soit le matériau utilisé, c’étaient des jantes garnies de clous ou d’une bande métallique qui ralentissaient l’usure de la roue.
                        </p>
                        <br>
                        <p>
                            En général la roue, pour la majorité des civilisations, marque un certain degré d’évolution.
                            Ce sont les celtes qui apporteront un niveau de perfection à la roue de charron.
                            Cette période faste se situe à la fin de la protohistoire vers 60 ans avant J.-C.
                            Ils utilisaient un mélange à base de bois et de fer pour fabriquer les jantes.
                        </p>
                        <br>
                        <p>
                            La conquête de l’occident par les Romains leur a permit de régner sans partage, de bénéficier et d’affiner les découvertes antérieures.
                            Tous les historiens s’accordent pour dire que les Romains possédaient une supériorité incontestée dans l’art des Ponts et Chaussées.
                            Les bases de leurs savoir-faire sur les jantes se sont maintenues jusqu’au 18ème siècle.
                        </p>
                        <br>
                        <p>
                            Les Romains ont inventé les jantes – définition, à rayons dite “forme parapluie”.
                            Elle a permis d’accroître la durée de vie des roues. La traction en était facilitée. Les jantes étaient fabriquées d’un seul bloc, puis taillées et polies.
                            Elles étaient lourdes mais solides.
                            Après avoir été conçu en pierre, avec l’évolution, les jantes furent en bronze puis en fer pour devenir en bois/acier et enfin en métal comme on les connaît aujourd’hui.
                        </p>
                        <br>
                        <p>
                            Chez les Etrusques peut avant l’invasion Romaine, on trouvait une gamme de jantes très variées.
                            Certaines jantes étaient destinées aux véhicules de transports des guerriers, d’autres à l’agriculture ou bien encore aux voyageurs.
                            Avec l’expansion de l’empire Romain, le Consul Lucius Julius Caesar III, promulgua la Lex Julia.
                            Cette réglementation était destinée à codifier la circulation devenue intense sur les voies Romaines.
                            La Lex Julia est l’ancêtre du Code de la route.
                        </p>
                        <br>
                        <p>
                            Après cette avancée fulgurante dans le domaine de la jante vint la décadence de l’empire occidental et les invasions Barbares.
                            Le développement des moyens de transport se mit à stagner.
                            Les premiers changements dans la fabrication de la roue apparurent au 15ème siècle avec l’introduction des ressorts à lame, des courroies, des essieux et des freins.
                            Techniquement les progrès sur la jante restèrent lents.
                        </p>

                    </v-col>
                    <v-col offset-lg="1" lg="4">
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">L’ÉPOQUE MODERNE</p>
                        <br>
                        <p>
                            Ce sera l’influence de la bicyclette qui sera un facteur déterminant pour le développement de l’industrie automobile et des jantes.
                            A partir de cette période, les jantes – définition évoluent. Elles sont recouvertes d’un bandage en caoutchouc, et elles sont dotées de rayons.
                            Ces roues ont équipés les premiers vélos, tout d’abord le célérifère (1790), puis la draisienne (1817).
                            Ce n’est qu’en 1861 qu’apparaît la jante de vélo recouverte d’un latex cuit aux propriétés élastiques.
                            Cette petite révolution a permis d’obtenir un confort et d’amortir nettement les chocs et le bruit en roulant.
                        </p>
                        <br>
                        <p>
                            L’évolution de la jante a surtout subi des mutations dans son aspect esthétique et dans les matériaux utilisés.
                            L’industrie de la jante a vraiment pris son essor avec Dunlop en 1888 qui inventa le premier pneumatique gonflé à l’air puis en France avec Edouard Michelin en 1891 et son pneu démontable.
                            La guerre de 1914-1918 a contribué à pérenniser ce développement industriel.
                            Dès lors, la plus grande invention de l’humanité, l’automobile, n’a cessé d’évoluer.
                        </p>
                        <br>
                        <p>
                            Le réseau routier des années 1900 était déplorable. Les premiers automobilistes qui s’aventuraient sur les routes d’Europe s’apparentaient plus à des explorateurs.
                            Ils devaient faire face à la traîtrise des routes, qui occasionnaient des accidents et des crevaisons en série. Au début les jantes étaient monobloc, et les roues pas toujours démontables.
                            Les pneumatiques n’étaient pas d’une grande qualité et éclataient avant d’être usé. D’engin expérimental, la voiture est devenue un moyen de transport inégalable.
                            Tout au long de l’évolution de la jante, les chercheurs ont innové et amélioré les qualités techniques comme la tenue de route, la vitesse, la robustesse et la fiabilité.
                        </p>
                        <br>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption" offset-lg="1">
                        <p>Pour en savoir plus sur les jantes, reportez-vous aux différents articles qui traitent de cette question, sur le présent site.</p>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="$vuetify.breakpoint.mdAndDown" class="bc_white tc_black">
            <v-row style="margin-top: 5%" class="font-weight-regular
                                    text-xl-h5
                                    text-lg-body-1
                                    text-md-caption
                                    text-sm-caption
                                    text-caption">
                <v-col offset-lg="1" lg="5">
                    <p class="font-weight-bold 
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">L’ANTIQUITÉ</p>
                    <br>
                    <p>
                        Pour les archéologues l’invention de la roue est située vers 3500 ans avant J.-C.
                        Les premières roues ont été trouvées en Mésopotamie dans la ville de Sumer.
                        Elles étaient en bois ou en pierre et servaient au transport de lourdes charges.
                        Des pictogrammes sumériens montrent des chariots dotés de roues pleines, tractées par des animaux.
                        Parallèlement, des roues à quatre rayons ont été découvertes en Egypte vers 1700 ans avant J.-C.
                        Le principe de la roue n’a pratiquement pas évolué pendant 5000 ans.
                        Quelque soit le matériau utilisé, c’étaient des jantes garnies de clous ou d’une bande métallique qui ralentissaient l’usure de la roue.
                    </p>
                    <br>
                    <p>
                        En général la roue, pour la majorité des civilisations, marque un certain degré d’évolution.
                        Ce sont les celtes qui apporteront un niveau de perfection à la roue de charron.
                        Cette période faste se situe à la fin de la protohistoire vers 60 ans avant J.-C.
                        Ils utilisaient un mélange à base de bois et de fer pour fabriquer les jantes.
                    </p>
                    <br>
                    <p>
                        La conquête de l’occident par les Romains leur a permit de régner sans partage, de bénéficier et d’affiner les découvertes antérieures.
                        Tous les historiens s’accordent pour dire que les Romains possédaient une supériorité incontestée dans l’art des Ponts et Chaussées.
                        Les bases de leurs savoir-faire sur les jantes se sont maintenues jusqu’au 18ème siècle.
                    </p>
                    <br>
                    <p>
                        Les Romains ont inventé les jantes – définition, à rayons dite “forme parapluie”.
                        Elle a permis d’accroître la durée de vie des roues. La traction en était facilitée. Les jantes étaient fabriquées d’un seul bloc, puis taillées et polies.
                        Elles étaient lourdes mais solides.
                        Après avoir été conçu en pierre, avec l’évolution, les jantes furent en bronze puis en fer pour devenir en bois/acier et enfin en métal comme on les connaît aujourd’hui.
                    </p>
                    <br>
                    <p>
                        Chez les Etrusques peut avant l’invasion Romaine, on trouvait une gamme de jantes très variées.
                        Certaines jantes étaient destinées aux véhicules de transports des guerriers, d’autres à l’agriculture ou bien encore aux voyageurs.
                        Avec l’expansion de l’empire Romain, le Consul Lucius Julius Caesar III, promulgua la Lex Julia.
                        Cette réglementation était destinée à codifier la circulation devenue intense sur les voies Romaines.
                        La Lex Julia est l’ancêtre du Code de la route.
                    </p>
                    <br>
                    <p>
                        Après cette avancée fulgurante dans le domaine de la jante vint la décadence de l’empire occidental et les invasions Barbares.
                        Le développement des moyens de transport se mit à stagner.
                        Les premiers changements dans la fabrication de la roue apparurent au 15ème siècle avec l’introduction des ressorts à lame, des courroies, des essieux et des freins.
                        Techniquement les progrès sur la jante restèrent lents.
                    </p>
                    <br>
                    <br>
                    <p class="font-weight-bold 
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">L’ÉPOQUE MODERNE</p>
                    <br>
                    <p>
                        Ce sera l’influence de la bicyclette qui sera un facteur déterminant pour le développement de l’industrie automobile et des jantes.
                        A partir de cette période, les jantes – définition évoluent. Elles sont recouvertes d’un bandage en caoutchouc, et elles sont dotées de rayons.
                        Ces roues ont équipés les premiers vélos, tout d’abord le célérifère (1790), puis la draisienne (1817).
                        Ce n’est qu’en 1861 qu’apparaît la jante de vélo recouverte d’un latex cuit aux propriétés élastiques.
                        Cette petite révolution a permis d’obtenir un confort et d’amortir nettement les chocs et le bruit en roulant.
                    </p>
                    <br>
                    <p>
                        L’évolution de la jante a surtout subi des mutations dans son aspect esthétique et dans les matériaux utilisés.
                        L’industrie de la jante a vraiment pris son essor avec Dunlop en 1888 qui inventa le premier pneumatique gonflé à l’air puis en France avec Edouard Michelin en 1891 et son pneu démontable.
                        La guerre de 1914-1918 a contribué à pérenniser ce développement industriel.
                        Dès lors, la plus grande invention de l’humanité, l’automobile, n’a cessé d’évoluer.
                    </p>
                    <br>
                    <p>
                        Le réseau routier des années 1900 était déplorable. Les premiers automobilistes qui s’aventuraient sur les routes d’Europe s’apparentaient plus à des explorateurs.
                        Ils devaient faire face à la traîtrise des routes, qui occasionnaient des accidents et des crevaisons en série. Au début les jantes étaient monobloc, et les roues pas toujours démontables.
                        Les pneumatiques n’étaient pas d’une grande qualité et éclataient avant d’être usé. D’engin expérimental, la voiture est devenue un moyen de transport inégalable.
                        Tout au long de l’évolution de la jante, les chercheurs ont innové et amélioré les qualités techniques comme la tenue de route, la vitesse, la robustesse et la fiabilité.
                    </p>
                    <br>
                    <p>Pour en savoir plus sur les jantes, reportez-vous aux différents articles qui traitent de cette question, sur le présent site.</p>
                </v-col>
            </v-row>
        </v-col>
        </v-main>



        <v-main v-else-if="VUE_APP_SITE_ID === '2'">
            <v-col class="py-0">
                <v-row>
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                            <h1 class="text-xl-h2 text-lg-h3 font-weight-bold rotate">
                                Jantes - Description
                            </h1>

                    </v-col>
                    <v-col lg="5" >
                        <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5 font-weight-bold ">
                            Jantes - Description
                        </h1>
                        <v-content class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                        <p class=" mb-3 font-weight-bold
                                            text-xl-h5
                                        text-lg-body-1
                                        text-md-body-1
                                        text-sm-body-1
                                        text-body-1" >Tout ce que vous devez savoir sur la jante</p>
                        <p>
                            Qu’est-ce qu’une jante ? Quelles différences entre une jante, un pneu, un voile, un moyeu ? A quelle époque remonte l’invention de la jante ?
                            Nous vous racontons tout !
                        </p>
                            <p class=" mb-3 font-weight-bold
                                            text-xl-h5
                                        text-lg-body-1
                                        text-md-body-1
                                        text-sm-body-1
                                        text-body-1" >Qu’est-ce qu’une jante ?</p>

                            <p>
                                Composant essentiel de la roue, la jante désigne, à l’origine, un élément circulaire tournant autour d’un axe central. Historiquement très ancienne, la jante est devenue ainsi la pièce maîtresse de tous les moyens de locomotion, vélo, avion, camion et voiture.
                                Concrètement, la jante assure la liaison entre le moyeu (axe de rotation) et le pneumatique qu’elle supporte.
                            </p>
                            <p>
                                Les jantes monobloc (moulées d’une seule pièce), démontables ou à rayons, se sont substituées, au fil du temps, aux jantes en bois.
                                Jantes alu, acier, tôle, chrome ou composite, le choix de la matière sera fonction du type de véhicule mais également des propriétés recherchées : résistance, légèreté, design, performance. A titre d’exemple, les véhicules sportifs sont équipés de jantes en composites (magnésium, carbone).
                            </p>

                            <p class=" mb-3 font-weight-bold
                                            text-xl-h5
                                        text-lg-body-1
                                        text-md-body-1
                                        text-sm-body-1
                                        text-body-1" >Autour de la jante : le pneu, le moyeu, le voile</p>

                            <p>
                                Attachons-nous maintenant aux rôles des différents éléments périphériques à la jante
                            </p>
                            <p>
                                -Le pneu :
                                Sorte de tampon de protection entre l’asphalte et la jante, le pneu, également appelé gomme, porte, amortit, assure la trajectoire et l’adhérence d’où l’importance du choix d’un pneumatique de qualité.
                                L’ensemble jantes-pneus joue un rôle majeur sur la performance, sur la sécurité du véhicule et en particulier sur sa tenue de route, son usure…
                            </p>
                            <p>
                                -Le moyeu de la roue :
                                Partie intégrante du système de direction, situé au centre de la jante, il est traversé par l’essieu. Tambour et disque de frein y sont fixés. Le moyeu supporte la roue dans son ensemble. Les roulements à bille qui le composent, permettent la rotation de la roue.
                            </p>
                            <p>
                                -Le voile :
                                Il s’agit de la partie centrale, fixée par des écrous, venant recouvrir la flasque du moyeu, à l’intérieur de la jante.
                            </p>

                        </v-content>
                    </v-col>
                    <v-col class="pa-0" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-img src="@/assets/AboutUsDJ.png"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phoneDJ.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33974191801">09 74 19 18 01</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="5" offset-lg="1"  class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>

                </v-row>
            </v-container>

            <v-col class="bc_white tc_black">
                <v-row style="margin-top: 5%"  class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col offset-lg="1" lg="10" >
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">Un peu d’histoire : les origines de la jante</p>
                        <br>
                        <p>
                            Les grandes évolutions de la jante sont imputables à l’essor du marché de l’automobile et au développement de la bicyclette au 19ème siècle, il n’en demeure pas moins que ses origines sont intimement liées à l’invention de la roue.
                        </p>
                        <p>
                            Il faut remonter à 3500 ans av. J-C, en Mésopotamie, pour trouver les premières roues en bois ou en pierre. C’est donc entre le tigre et l’Euphrate que la jante est née !
                            Des recherches archéologiques font état de la découverte de roues à rayons en Egypte en 1700 av. J-C.
                        </p>
                        <p>
                            Même s’il est prouvé que les celtes et les étrusques ont su développer une gamme de jantes particulièrement « ingénieuses », nous « enjamberons » cette période pour nous intéresser directement à l’époque romaine.
                            Les évolutions en matière de développement de moyens de transport y ont été particulièrement profuses. Ainsi, la jante a profité d’innovations considérables. Les romains sont à l’origine de la jante à rayons (“forme parapluie”) aux performances notables en termes de traction et de durabilité.
                            Les jantes, alors monobloc, réalisées en pierre, ont successivement été fabriquées en bronze, en fer, avec une combinaison bois/acier et en métal.
                        </p>
                        <p>
                            Il faudra attendre le 19ème siècle pour enregistrer des progrès significatifs avec, notamment, le recours au latex pour habiller les jantes des bicyclettes : une des premières grandes avancées sur le confort et la sécurité routière en matière d’amortissement, de réduction de bruit...
                        </p>
                        <p>
                            Restent, malgré tout, deux dates importantes à retenir dans l’histoire de la jante et de la roue de voiture :
                            <br>
                            -1888 : Dunlopp met au point le premier pneu gonflé à l’air,
                            <br>
                            -1891 : Michelin crée le pneumatique en caoutchouc à armature acier, démontable.
                        </p>
                        <p>
                            Deux véritables révolutions d’un point de vue esthétique, technique et sécuritaire d’autant que l’état des routes de l’époque, laissées à “l’état sauvage”, était à l’origine de nombreux accidents et crevaisons.
                        </p>
                        <p>
                            Avec l’omniprésence de la voiture dans notre quotidien en tant que moyen de déplacement prioritaire, la roue, et plus précisément la jante, sont devenues des éléments essentiels jouant un rôle clef dans le confort de conduite, la tenue de route, la performance du véhicule et son design.
                        </p>
                        <p>
                            Depuis, de nombreuses marques de Jantes telles que ACRWheels, 24hduMans, Alutec, Bayern et bien d’autres, n’ont de cesse de faire évoluer la jante en ce sens.
                        </p>



                    </v-col>
                </v-row>

            </v-col>

        </v-main>



        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "../headers/HomepageHeaderEj";
import HomepageHeaderDj from "../headers/HomepageHeaderDj";
import HomepageFooterEj from "../footer/HomepageFooterEj";
import HomepageFooterDj from "../footer/HomepageFooterDj";

    export default {
        name: "JantesDescription",
        components:{
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,

        },
        data: () => ({
            VUE_APP_SITE_ID:null
        }),
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        },
        metaInfo() {
            return {
                title: `Description Jantes - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + `  - Jantes, Pneus & Accessoires`,
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `Description Jantes - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {name: 'twitter:description', content: `Découvrez les Description Jantes ` + (this.VUE_APP_SITE_ID === '1' ? `d'Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `de Distri Jantes l'expert en ligne des jantes`)},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `Description Jantes -` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {property: 'og:site_name', content: this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`},
                    {property: 'og:type', content: 'Website'},
                    {property: 'og:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},
                    {property: 'og:description', content: `Découvrez les Description Jantes ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    {name: 'robots', content: 'index,follow'}
                ]
            }
        },
    }
</script>

<style scoped>
    .border_left{
        border-left: 2px solid #1DE4AF
    }
    .rotate{
        transform: rotate(-90deg);
        margin-left: -8%;
        margin-top: 12%;
        position: absolute;
    }
</style>
