<template>
    <v-content  id="templateConfigurateurHome" class="ej-menu TextPoppins ej-config">
       <v-row class="align-center rowConfigurateur">
            <v-col lg="5" offset="1" offset-lg="0" offset-xl="0" offset-md="0" cols="10" style='padding:unset;'>
                 <v-select ref="selectMarque" solo class="" :items="marques" :value="marqueChoisi" item-text="Nom" item-value="id_manufacturer" v-model="marqueChoisi" label="Marque"  outlined style="padding-right: 0px;"  @change="changeMarque">
                  <template slot="selection" slot-scope="marques">
                        <v-flex xs2>
                            <v-avatar size="40px">
                                 <!-- <img :src="marques.item.logo_url"/>  -->
                                <img  :src="getImgUrl(marques.item.Nom)" :alt="marques.item.Nom" />
                            </v-avatar>
                        </v-flex>
                        <span class="ml-4">{{marques.item.Nom}}</span>
                    </template>
                    <template slot="item" slot-scope="marques">
                        <v-flex xs2>
                            <v-avatar size="40px">
                                <!-- <img :src="marques.item.logo_url"/>  -->
                                <img  :src="getImgUrl(marques.item.Nom)" :alt="marques.item.Nom" />
                            </v-avatar>
                        </v-flex>
                        <span class="ml-4">{{marques.item.Nom}}</span>
                    </template>
                </v-select>
            </v-col>
            <v-col lg="5" offset="1" offset-lg="0" offset-xl="0" offset-md="0" cols="10" style='padding:unset;'>
                <v-select ref="select" disabled loading solo v-if="showModeleLoading" :items="ModeleUnique" :value="modeleChoisi" v-model="modeleChoisi" label="Modèle"  outlined style="padding-right: 0px;" @change="changeModele"></v-select>
                <v-select ref="select" solo v-if="showModele" :items="ModeleUnique" :value="modeleChoisi" v-model="modeleChoisi" label="Modèle"  outlined style="padding-right: 0px;" @change="changeModele" v-bind:disabled="isButtonDisabled" ></v-select>
            </v-col>
       </v-row>
       <v-row class="align-center rowConfigurateur">
           <v-col lg="5" offset="1" offset-lg="0" offset-xl="0" offset-md="0" cols="10" style='padding:unset;'>
                <v-select ref="select" solo v-if="showType" :items="type" item-value="id_modele"  item-text="modelToShow" :value="typeChoisi" v-model="typeChoisi" label="Type" outlined style="padding-right: 0px;" @change="changeType"></v-select>
            </v-col>

            <v-col lg="5" offset="1" offset-lg="0" offset-xl="0" offset-md="0" cols="10" style='padding:unset;'>
                <v-select ref="select" disabled loading solo v-if="showMotorisationLoading" :items="motorisation" item-value="Ktype"  item-text="motorisationToShow" :value="motorisationChoisi" v-model="motorisationChoisi" label="Motorisation"  outlined style="padding-right: 0px;" @change="changeMotorisation"></v-select>
                <v-select ref="select" solo v-if="showMotorisation" :items="motorisation" item-value="Ktype"  item-text="motorisationToShow" :value="motorisationChoisi" v-model="motorisationChoisi" label="Motorisation"  outlined style="padding-right: 0px;" @change="changeMotorisation"></v-select>

            </v-col>
            <v-col lg="5" cols="10" offset="1" offset-lg="0" offset-xl="0" offset-md="0" style='padding:unset;'>
                <v-select solo v-if="showDimension" class="dimension hiddenInput" :items="dimension" :value="dimensionChoisi" v-model="dimensionChoisi" label="Dimension"  outlined style="padding-right: 0px;" @change="changeDimension"></v-select>
            </v-col>
       </v-row>
    </v-content>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
  import VueAxios from 'vue-axios'

  import SecureLS from "secure-ls";
  var ls = new SecureLS({ isCompression: false });
  Vue.use(VueAxios, axios)

  export default {
    name: "ConfigurateurHomeEj",
    data: () => ({
        marques: [],
        modele: [],
        type: [],
        motorisation: [],
        ModeleUnique:[],
        dimension: [],
        showDimension:false,
        showType:false,
        showMotorisation:false,
        showMotorisationLoading:false,
        showModeleLoading:false,
        showModele:true,
        isButtonDisabled:true,
        marqueChoisi: '',
        typeChoisi:'',
        modeleChoisi:'',
        motorisationChoisi:'',
        dimensionChoisi:'',
        dataToRouter:'',
        listModele:[],
        listChassis: [],
        listJantesSansDimension:[],
        infoVehicule: {}
    }),
    mounted () { // Cycle de vie : Vue chargé
        this.$store.commit('SavePage/deletePage')

      // Hydratation de la liste Marques 
      var arrayMarqueHide = ['AC','ACURA','ALPINA','AMC','ARO','ARTEGA','ASIA MOTORS','AUSTIN','AUSTIN-HEALEY','AUTO UNION','AUTOBIANCHI','AUVERLAND','BEDFORD','BARKAS','BERTONE','BITTER','BLUECAR','BOND','BORGWARD','BRISTOL',
                            'BUGATTI','BUICK','CALLAWAY','CARBODIES','CATERHAM','CHATENET','CHECKER','COMARTH','DAF','DAIMLER','DALLAS','De La Chapelle','DE LOREAN','DE TOMASO','DONKERVOORT','EBRO','FISKER','FORD OTOSAN','FSO',
                              'GAZ','GEELY','GEO','GINETTA','GIOTTI VICTORIA','GLAS','GMC','GME','GOUPIL','GUMPERT','HINDUSTAN','HOBBYCAR','INDIGO','INNOCENTI','IRAN KHODRO (IKCO)','IRMSCHER','ISDERA','IVECO','IZH','JENSEN',
                              'KTM','LANDWIND (JMC)','LDV','LIGIER','LINCOLN','LTI','MAHINDRA','MAN','MARCOS','MARUTI','MAYBACH','MEGA','METROCAB','MIA ELECTRIC','MICROCAR','MIDDLEBRIDGE','MINELLI','MITSUOKA','MORGAN','MORRIS',
                                'MOSKVICH','MPM MOTORS','NSU','OLDSMOBILE','OLTCIT','OSCA','PAGANI','PANOZ','PANTHER','PGO','PIAGGIO','PININFARINA','PLYMOUTH','POLARIS','PONTIAC','PREMIER','PROTON','PUCH','RAM','RANGER',
                                'RAYTON FISSORE','RELIANT','RENAULT TRUCKS','RILEY','RUF','SANTANA','SATURN','SHELBY','SIPANI','SPECTRE','SPYKER','STANDARD','STANGUELLINI','STEYR','STREETSCOOTER','TALBOT','TATA','TAZZARI',
                                'THINK','TRABANT','TRIUMPH','TVR','UAZ','UMM','VAUXHALL','VECTOR','VENTURI','VICTORY','VUHL','WARTBURG','WESTFIELD','WIESMANN','WOLSELEY','YUGO','YULON','ZASTAVA','ZAZ','ZENOS CARS']

      Vue.axios.get('https://api.espace-jantes.com/carMarque').then((response) => {
        response.data.forEach(element => {
            if(arrayMarqueHide.indexOf(element.Nom) == -1){
              this.marques.push(element)
            }
        })
        ls.set('monteType', 'par4')
        ls.set('MontageSave', 'par4')
        
        //Gestion Is mobile is not mobile
        // if(!this.$vuetify.breakpoint.smAndDown){
        //   this.$refs.selectMarque.isMenuActive = true
        // }
        
      })
      // const selectMarque = this.$refs["selectMarque"]
      // selectMarque.activate()
    },
    methods:{
        changeMarque: function () {
          /* Systeme de gestion du configurateur */
          this.isButtonDisabled = false
          this.modeleChoisi = ''
          this.typeChoisi = ''
          this.motorisationChoisi = ''
          this.dimensionChoisi = ''
          this.showMotorisation = false
          this.showMotorisationLoading = false
          this.showType = false
          this.showDimension = false
          this.showModele = false
          this.showModeleLoading = true
          this.modele = []
          this.ModeleUnique = []
          this.dimension = []

          Vue.axios.get('https://api.espace-jantes.com/VehiculeInfo',{params: {marqueID: this.marqueChoisi}})
          .then((response) => {
              const _this_modele = this.modele
              const _this_modeleUnique = this.ModeleUnique

              response.data.forEach(function(element) {
                  if(_this_modeleUnique.indexOf(element.modele) == -1){
                      _this_modeleUnique.push(element.modele)
                  }
                  _this_modele.push(element)
              })
              this.ModeleUnique = _this_modeleUnique
              this.modele = _this_modele
              this.showModeleLoading = false
              this.showModele = true
          })

          var marqueLabel =  this.marques.filter(it => it.id_manufacturer == this.marqueChoisi);
          // this.$localStorage.set('marque', marqueLabel[0].Nom)
          ls.set('marque', marqueLabel[0].Nom)
            this.infoVehicule.marque = marqueLabel[0].Nom
          this.$store.commit('infoVehicule/setVehicule', {'marque':marqueLabel[0].Nom})


        },
        changeModele: function () {
        /* Systeme de gestion du configurateur */
        this.showType = true
        this.motorisationChoisi = ''
        this.dimensionChoisi = ''
        this.typeChoisi = ''
        this.motorisation = []
        this.type = []
        this.dimension = []
        this.showDimension = false
        this.showMotorisation = false
        this.showMotorisationLoading = false

        var modele = this.modele
        var _type_ = this.type
        var _modeleChoisi_ = this.modeleChoisi

        //   function compare(el1, el2, index) {
        //     return el1[index] == el2[index] ? 0 : (el1[index] < el2[index] ? -1 : 1);
        // }
        // var  newModele = modele.sort(function(el1,el2){
        //     return compare(el1,el2,"Type")
        //   })
        var arrayTmpModele = []
        modele.forEach(function(element){
        if(element.modele === _modeleChoisi_){
        arrayTmpModele.push(element)
        }
        })
        arrayTmpModele.forEach(element => {
        var dateBrutFrom = JSON.parse(JSON.stringify(element.date_construction_from));
        var dateFrom =  dateBrutFrom.split('/')
        element.DateToSort = dateFrom[2]

        var indexParentheseAvant = element.Type.indexOf('(')
        var indexParentheseArriere = element.Type.indexOf(')')
        // var parenthese = element.Type.substr(indexParentheseAvant,indexParentheseArriere-1)
        var parenthese = element.Type.substr(indexParentheseAvant, (indexParentheseArriere-indexParentheseAvant)+1).trim()
        element.toSort = (element.Type.replace(parenthese,"")).replace('  ', " ")

        // console.log(element)
        })

        arrayTmpModele.sort(function(a,b){
        return (a.toSort).localeCompare(b.toSort)
        })
        arrayTmpModele.sort(function(a,b){
        if(a.toSort.trim() === b.toSort.trim()){
        if(a.DateToSort <= b.DateToSort ){
         return -1
        }else{
         return 1
        }
        }
        else{
        return 0
        }
        })
        modele = arrayTmpModele
        modele.forEach(function(element){

          if(element.modele === _modeleChoisi_){
            var dateBrutFrom = JSON.parse(JSON.stringify(element.date_construction_from));
            var dateFrom =  dateBrutFrom.split('/')
            // console.log(element.Type)
            element.modelToShow = element.Type + "  " + dateFrom[2] + " > "

            if(element.date_construction_to.length != 0){
                var dateBrutTo = JSON.parse(JSON.stringify(element.date_construction_to));
                var dateTo =  dateBrutTo.split('/')
                element.modelToShow += dateTo[2]
            }
            _type_.push(element)
          }
        })
        //   _type_.sort(function(el1,el2){
        //   return compare(el1,el2,"modelToShow")
        // })
        this.type = _type_

        // this.$localStorage.set('modele', this.modeleChoisi)
        ls.set('modele', this.modeleChoisi)
          this.infoVehicule.modele = this.modeleChoisi
          this.$store.commit('infoVehicule/setVehicule', {'modele':this.modeleChoisi})


        },
        changeType: function () {
        /* Systeme de gestion du configurateur */
        this.motorisationChoisi = ''
        this.dimensionChoisi = ''
        this.motorisation = []
        this.showDimension = false
        this.showMotorisation = false
        this.showMotorisationLoading = true
        this.listChassis = []
        this.dimension = []

        var TypeMotorisation = []
        var listInfovehicules = []
        var listKtype = []

        Vue.axios.get('https://api.espace-jantes.com/carModeles', {params: {modeleID : this.typeChoisi}})
        .then((response) => {

          this.listModele = response.data
          response.data.sort(function(a,b){
            if(a.carName == b.carName){
              if(parseInt(a.powerKwFrom) <= parseInt(b.powerKwFrom) ){
                return -1
              }else{
                return 1
              }
            }else if (a.carName > b.carName) {
              return 1
            }
            else if(a.carName < b.carName){
              return -1
            }
          })


          response.data.forEach(function(element) {
            var motorisationToShow = element.carName +"   ("+ ( Math.round(element.powerKwFrom * 1.36 )) + " CH ) "
            element.motorisationToShow = motorisationToShow

            TypeMotorisation.push(motorisationToShow)
            element.motorisationToShow = motorisationToShow

            listInfovehicules.push(element)
            listKtype.push(element.Ktype)
          })
          // this.$localStorage.set('listKtype', listKtype)
          ls.set('listKtype', listKtype)

          return Vue.axios.get('https://api.espace-jantes.com/infoChassis', {params: {listKtype : listKtype}})
        })
        .then((response) => {
          this.showMotorisationLoading = false
          this.showMotorisation = true
          var _this_dimension = this.dimension
          const _this_listChassis = this.listChassis

          response.data.forEach(function(element) {
            _this_listChassis.push(element)
            var wheelSizeSplit = element.wheelSize.split('x')
            if(_this_dimension.indexOf(wheelSizeSplit[1]) == -1 && parseInt(wheelSizeSplit[1].trim()) >= 13 &&  parseInt(wheelSizeSplit[1].trim()) <= 23){
              _this_dimension.push(wheelSizeSplit[1])
            }
          })

          const byValue  = (a,b) => a - b;
          const sorted = [... _this_dimension].sort(byValue)
          this.listChassis =_this_listChassis
          this.dimension = sorted
        })
        this.motorisation = listInfovehicules
        this.listModele = listInfovehicules

        var typeModeleLabel =  this.type.filter(it => it.id_modele == this.typeChoisi);
        // this.$localStorage.set('type', typeModeleLabel[0].modelToShow)
        ls.set('type', typeModeleLabel[0].modelToShow)
          this.$store.commit('infoVehicule/setVehicule', {'type':typeModeleLabel[0].modelToShow})


          //console.log(this.listChassis)
        },
        changeMotorisation: function () {
                var _motorisationChoisi = this.motorisationChoisi
                var chassisVehiculeUtilisateur = this.listChassis.filter(it => it.ktype == _motorisationChoisi);
                // console.log(this.listChassis)
                // console.log(_motorisationChoisi)
                //KTYPE A RETIRER : 123175, 123176 , 123161
                // //var chassisVehiculeUtilisateur = this.listChassis.filter(it => it.ktype == 57433);
                // console.log(_motorisationChoisi)
                // console.log(chassisVehiculeUtilisateur)
                // console.log( this.listChassis)

                var MotorisationLabel =  this.motorisation.filter(it => it.Ktype == _motorisationChoisi);

                // this.$localStorage.set('motorisation', MotorisationLabel[0].motorisationToShow)
                ls.set('motorisation', MotorisationLabel[0].motorisationToShow)
                // this.$localStorage.set('chassisVehiculeUtilisateur', JSON.stringify(chassisVehiculeUtilisateur[0]))
                ls.set('chassisVehiculeUtilisateur', JSON.stringify(chassisVehiculeUtilisateur[0]))

                  this.$store.commit('infoVehicule/setVehicule', {'motorisation':MotorisationLabel[0].motorisationToShow})

                this.showDimension = true

                //Reset the configurateur's value
                this.dimensionChoisi = ''
                },
        changeDimension: function () {
          //Appel algolia ou mettre en session les caracteristique de la voiture pour ensuite faire l'appel dans algolia page listing
          //   this.$localStorage.set('diametre', this.dimensionChoisi)
            ls.set('diametre', this.dimensionChoisi)

          this.$router.push({
              name: 'Listing'
          });
        },
        getImgUrl(logo) {
              try{
                  var logoUrl = require('@/assets/logo3x/'+ logo.toLowerCase() +'.png')
                  return logoUrl
                }
              catch{
                return require('@/assets/ConfigurateurHome.jpg')
              }
          }
      },

    };
</script>

<style scoped>

    .TextPoppins{
        font-family: poppins;
        font-weight: 800;
    }

    .rowConfigurateur{
        padding: 5px 10px;
    }
    .ej-config >>> .v-input__slot{
      box-shadow: 0 3px 15px #232D4D50 !important;
      min-height: 70px !important;
      border-color:none !important
    }

</style>
