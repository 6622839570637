<template>

    <v-app  fluid >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>

        <v-main v-if="VUE_APP_SITE_ID === '1'">
            <v-col class="py-0">
                <v-row>
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 tc_blue font-weight-bold rotate">
                            Jantes - Matériaux
                        </h1>

                    </v-col>
                    <v-col lg="5">
                        <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5 tc_blue font-weight-bold ">
                            Jantes - Matériaux
                        </h1>
                        <v-content class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                            <p class="font-weight-bold title">JANTES EN ACIER/TÔLE</p>
                            <p>
                                Les jantes en tôle sont montées en série par les constructeurs de voiture.
                                Ce type de jante est surtout utilisé sur les petites voitures citadines.
                                Si vous avez un double équipement pneumatique, les pneus neiges sont souvent montés sur des jantes acier.
                                La roue de secours est elle aussi souvent en tôle.
                            </p>
                            <p>
                                Ce style de jante ne tolère pas les chocs et l’esthétisme laisse souvent à désirer.
                                La plupart des jantes en tôle sont équipées d’enjoliveur, destiné à rendre un aspect moderne à la voiture.
                                Ce sont les jantes – matériaux les moins chères du marché, et les constructeurs n’hésitent pas à les utiliser à grande échelle pour économiser sur les coups de production.
                            </p>
                            <br>
                            <p class="font-weight-bold title">JANTES EN ALUMINIUM</p>
                            <p>
                                L’aluminium est un métal récent, découvert au milieu du 19ème siècle.
                                On l’utilise pour la première fois en 1907 dans l’automobile pour la carrosserie de la “Silver-Ghost ” de Rolls-Royce.
                                Désormais les jantes en aluminium sont les plus vendues sur le marché.
                                La technologie permet d’obtenir un rapport entre la qualité, la fiabilité et surtout l’esthétisme.
                                Les véhicules équipés de jantes en aluminium ont un caractère plus prononcé, plus racé et original.
                            </p>

                            <p class=" mb-3 pl-4 border_left tc_turquoise font-weight-bold
                                            text-xl-h5
                                        text-lg-body-1
                                        text-md-body-1
                                        text-sm-body-1
                                        text-body-1" :class="{'headline':$vuetify.breakpoint.lgAndUp, 'title': $vuetify.breakpoint.mdAndDown}">
                                Au-delà du design, les caractéristiques des jantes aluminium influencent les performances de la voiture.
                                <br>
                                Les jantes en aluminium sont plus légères que les jantes en acier.
                            </p>
                            <p>

                                Les jantes – matériaux étant un élément non suspendu, leurs masses influencent nettement moins la force centrifuge du véhicule.
                                La tenue de route est améliorée surtout dans les virages.
                                De plus les alliages utilisés pour le façonnage des jantes alu sont des bons conducteurs de chaleur.
                                Cette propriété permet d’évacuer plus rapidement la chaleur des freins à disque, et ainsi améliorer la force de freinage.
                            </p>
                            <p>
                                Le véhicule équipé de jante en aluminium est plus léger, donc plus maniable, avec des performances bonifiées à l’accélération et au freinage.
                                L’aluminium reste malgré sa légèreté plus rigide que l’acier.
                                Ce qui implique une meilleure endurance des jantes aluminium et ainsi diminue la sensation de carrossage des roues dans les courbes.
                            </p>

                        </v-content>
                    </v-col>
                    <v-col class="bc_blue" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-img src="@/assets/AboutUs.png"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phone.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold tc_blue align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="3" xl="3" class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2" offset-lg="1" xl="3" lg="3">
                        <v-img style="position: absolute; width: 20%;" src="@/assets/illustration-man.png"/>
                    </v-col>
                </v-row>
            </v-container>

            <v-col class="bc_white tc_black" >
            <v-row class="font-weight-regular
                                    text-xl-h5
                                    text-lg-body-1
                                    text-md-caption
                                    text-sm-caption
                                    text-caption">
                <v-col offset-lg="1" lg="10">
                        <v-col lg="10">
                            <br>
                            <br>
                            <p class="font-weight-bold
                               text-xl-h5
                               text-lg-h6
                               text-md-subtitle-1
                               text-sm-subtitle-1
                               text-subtitle-2">JANTES – MATÉRIAUX – MATÉRIAUX COMPOSITES</p>
                            <br>
                            <p>
                                Les jantes composites sont constituées de matériaux très légers, qui permettent d’accroître les performances des véhicules pour la vitesse et le poids.
                                Cet un matériau de haute technologie qui a des avantages indéniables, mais qui nécessite encore des recherches pour une diffusion et une utilisation plus large.
                                Les jantes – matériaux peuvent être conçues avec plusieurs matériaux assemblés. On trouve le carbone, le kevlar, le magnésium et l’aluminium.
                                Pour certains assemblages on utilise des colles époxy ultra résistantes.
                            </p>
                            <br>
                            <p>
                                Ce type de jantes est surtout utilisé par les adeptes de la performance.
                                Le prix des jantes en composite étant élevé, on le retrouve surtout dans les voitures de sport haut de gamme ainsi que les écuries de compétition.
                                Les jantes sont plus légères mais aussi très fragile.
                                Ce type de jantes accepte très bien des vitesses élevées constantes.
                                En revanche les accélérations et les décélérations créées des points des ruptures au point d’ancrages des bâtons.
                                Désormais, dans les écuries de compétition, on ne trouve que des jantes en magnésium.
                            </p>
                            <br>
                        </v-col>
                        <v-col lg="10">
                            <p class="font-weight-bold
                               text-xl-h5
                               text-lg-h6
                               text-md-subtitle-1
                               text-sm-subtitle-1
                               text-subtitle-2">JANTES – MATÉRIAUX – ROUE DE SECOURS</p>
                            <br>
                            <p>
                                La tâche primaire de la roue de secours est d’atteindre le garage ou l’atelier de réparation le plus proche.
                                Son choix n’est pas anodin, même si on le remarque que lors d’une crevaison.
                                Tout d’abord, il existe la roue de secours de taille normale.
                                Elle prend de l’espace dans le véhicule.
                                Elles sont souvent montées sur des jantes – matériaux en acier, avec une bande de roulement moins profonde et souvent plus légère.
                            </p>
                            <br>
                            <p>
                                Ensuite vous avez la roue de secours compacte.
                                Elle a pour caractéristique une taille différente, moins épaisse et plus basse que les pneumatiques du véhicule.
                                La jante est en acier monté sur un pneu adapté mais qui nécessite un gonflage à une pression supérieure que les pneus d’origine.
                                Son avantage est de prendre moins de place dans le coffre, et d’alléger le poids du véhicule.
                                Par contre elle n’est pas interchangeable avec un autre véhicule, et vous devez limiter la vitesse de votre voiture quand vous l’utilisez.
                            </p>
                            <p>
                                Enfin, il existe la roue de secours à gonfler.
                                Ce système est peu utilisé. Dans le véhicule, vous trouverez un kit de réparation muni d’un mini compresseur ou d’un récipient à air comprimé et de la roue gonflable.
                                Il n’y a donc pas de jante dans ce système.
                                Son coté pratique est le gain de place énorme dans la voiture.</p>

                        </v-col>

                        <v-col>
                            <br>
                            <p>Pour en savoir plus sur les jantes, le lecteur pourra se reporter aux différents articles qui traitent de cette question, sur le présent site.</p>
                            <p>Droit d’auteur protégé par les articles L. 111-1 et L. 123-1, du code de la propriété intellectuelle.</p>
                        </v-col>



                </v-col>
            </v-row>
        </v-col>
        </v-main>

        <v-main v-else-if="VUE_APP_SITE_ID === '2'">
            <v-col class="py-0">
                <v-row>
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 font-weight-bold rotate">
                            Jantes - Matériaux
                        </h1>

                    </v-col>
                    <v-col lg="5">
                        <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5 font-weight-bold ">
                            Jantes - Matériaux
                        </h1>
                        <v-content class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                            <p class="font-weight-bold title">Jantes alu, en tôle ou jantes alliage : quel type de matériau choisir</p>
                            <p>
                                Quelles sont les différences entre une jante alu (aluminium), une jante alliage ou une jante en tôle ? Nous vos proposons de reprendre l’essentiel des spécificités des différents types de jantes selon leur matériau.
                            </p>

                            <p class="font-weight-bold title">Les jantes acier et tôle d’acier</p>
                            <p>
                                On retrouve principalement les jantes en tôle d’acier sur les véhicules d’entrée et de moyenne gamme tels que les petites citadines ainsi que, le plus souvent, sur les roues de secours.
                                Notons que les pneus neige sont fréquemment montés sur des jantes acier.
                            </p>
                            <p>
                                Même si elle est très économique à la production pour le constructeur et à l’achat pour l’automobiliste, on reconnait à la jante en tôle des limites quant à son poids nécessairement élevé, à sa faible capacité à résister aux chocs et à son design. C’est pourquoi une grande majorité des jantes en tôle sont habillées d’un enjoliveur.
                            </p>

                            <p class="font-weight-bold title">Les jantes en aluminium</p>
                            <p>
                                La jante aluminium équipe, de nos jours, une grande partie des voitures.
                            </p>
                            <p>
                                L’aluminium, dans son usage industriel, est obtenu à partir de minerais (bauxite) auquel sont associés d’autres composants. Il se révèle très malléable.
                            </p>
                            <p>
                                Ses atouts sont multiples :
                            </p>
                            <p>
                                - Légèreté :
                                Une qualité primordiale pour une jante compte tenu de la notion de masse suspendue que nous avons abordé dans la partie Notions Techniques et de son impact sur la tenue de route, en particulier dans les virages.
                                Cette légèreté joue sur la manœuvrabilité, sur la vitesse lors des phases d’accélération.
                            </p>
                            <p>
                               - Conductivité thermique :
                                Une propriété physique qui permet aux freins à disque de se refroidir plus rapidement et optimise le freinage.
                            </p>
                            <p>
                               - Endurance et résistance
                            </p>
                            <p>
                                Les constructeurs de voiture privilégient l’aluminium pour son design épuré et contemporain.
                            </p>

                            <p class="font-weight-bold title">Les Jantes alliage</p>
                            <p>
                                On parle de jantes alliage ou jantes composites dans le sens où elles sont fabriquées à partir d’une combinaison de plusieurs matériaux : aluminium, magnésium, carbone, kevlar…On utilise pour l’assemblage une colle extrêmement efficace, résistant aux variations thermiques, dite époxy c’est-à-dire cumulant résine et durcisseur.
                            </p>
                            <p>
                                Cet alliage hi-tech confère à la jante résistance et extrême légèreté d’où une faible masse suspendue.
                                Autre atout : son look mais également le choix de couleurs et de modèles (nids d’abeilles, rayons…)
                            </p>
                            <p>
                                La jante alliage trouve écho auprès des sportifs ou du marché de l’automobile haut de gamme.
                            </p>
                            <p>
                                Pour autant, les professionnels soulignent sa faible aptitude à « encaisser » les phases rapides d’accélération et de décélération ainsi que son prix élevé ou encore sa fragilité.
                            </p>
                            <p>
                                Il semblerait que la jante en magnésium soit devenue très tendance pour équiper les voitures de sport de compétition.
                            </p>
                            <p>
                                Les jantes alliage font l’objet d’innovations permanentes et ne sont, pour le moment, diffusées que sur un marché extrêmement restreint.
                            </p>


                        </v-content>
                    </v-col>
                    <v-col class="pa-0" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-img src="@/assets/AboutUsDJ.png"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phoneDJ.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33974191801">09 74 19 18 01</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="5" offset-lg="1"  class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>

                </v-row>
            </v-container>

            <v-col class="bc_white tc_black" >
                <v-row class="font-weight-regular
                                    text-xl-h5
                                    text-lg-body-1
                                    text-md-caption
                                    text-sm-caption
                                    text-caption">
                    <v-col offset-lg="1" lg="10">
                            <v-col lg="10">
                                <br>
                                <br>
                                <p class="font-weight-bold
                                   text-xl-h5
                                   text-lg-h6
                                   text-md-subtitle-1
                                   text-sm-subtitle-1
                                   text-subtitle-2">Petite aparté sur la roue de secours et sa jante</p>
                                <br>
                                <p>
                                    Qui dit roue de secours, dit crevaison et dépannage donc un équipement de remplacement transitoire !
                                </p>
                                <p>
                                    Même si nous vous conseillons vivement de vous équiper d’une roue de secours, le code de la route ne la rend pas obligatoire.
                                </p>
                                <p>
                                    Pour information, il existe différents types de roues de secours :
                                </p>
                                <p>
                                    - La roue dite complète : Il s’agit d’un modèle de roue similaire à celui qui équipe la voiture et relativement encombrante. La taille de la jante, souvent en acier, peut être légèrement inférieure à celle montées sur les 4 roues et donc plus légère. Quelle que soit la taille de la jante, le diamètre de la roue sera, de toute manière, identique.
                                </p>
                                <p>
                                    - La roue galette ou roue de dépannage :
                                    Une roue de secours spécifique, plus petite et plus légère, avec une jante de faible largeur qui limite la vitesse à 80 KM/H et est réservée à de petites distances.
                                </p>
                                <p>
                                    Autre solution : le kit de dépannage anti crevaison
                                </p>
                                <p>
                                    Le kit comprend un compresseur et un liquide colmatant pour des crevaisons dites réparables. Là encore, il s’agit d’une solution de dépannage transitoire pour atteindre le garage le pus proche.
                                </p>
                            </v-col>
                    </v-col>
                </v-row>
            </v-col>
        </v-main>

        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "../headers/HomepageHeaderEj";
import HomepageHeaderDj from "../headers/HomepageHeaderDj";
import HomepageFooterEj from "../footer/HomepageFooterEj";
import HomepageFooterDj from "../footer/HomepageFooterDj";

    export default {
        name: "JantesMateriaux",
        components:{
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,

        },
        data: () => ({
            VUE_APP_SITE_ID:null
        }),
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        },
        metaInfo() {
            return {
                title: `Materiaux - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + `  - Jantes, Pneus & Accessoires`,
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `Materiaux - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {name: 'twitter:description', content: `Découvrez les matériaux qui composent une jante, ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `Materiaux -` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {property: 'og:site_name', content: this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`},
                    {property: 'og:type', content: 'Website'},
                    {property: 'og:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},
                    {property: 'og:description', content: `Découvrez les matériaux qui composent une jante, ` + (this.VUE_APP_SITE_ID === '1' ? `d'Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `de Distri Jantes l'expert en ligne des jantes`)},
                    {name: 'robots', content: 'index,follow'}
                ]
            }
    },
    }
</script>

<style scoped>
    .border_left{
        border-left: 2px solid #1DE4AF
    }
    .rotate {
        transform: rotate(-90deg);
        margin-left: -6%;
        margin-top: 12%;
        position: absolute;
    }
</style>
