

const state = () =>({
    typePayement:null,
})



const mutations = {
    setType(state, config){
        state.typePayement = config
    },
    deleteType(state){
        state.typePayement = null
    },



}


const actions = {

}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}