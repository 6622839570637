<template>

    <v-app fluid >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>

        <v-main v-if="VUE_APP_SITE_ID === '1'">
            <v-col class="py-0" >
                <v-row>
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 tc_blue font-weight-bold rotate">
                            Livraison
                        </h1>
                    </v-col>
                    <v-col lg="5" >
                        <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5  tc_blue font-weight-bold ">
                            Livraison
                        </h1>
                        <v-content class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                            <span>Le temps de préparation de commande est de 2 à 7 jours ouvrés après réception de votre paiement.</span>
                            <br>
                            <p>Ce délai est donné à titre indicatif et peut varier en fonction des disponibilités et des périodes (fêtes, congés etc).</p>
                            <p>Espace- Jantes Sarl effectue les livraisons de ses produits ( jantes- pneus- pack jantes complet) sous 48h/72h après expédition. </p>
                            <v-content class=" mb-3 pl-4 border_left tc_turquoise headline font-weight-bold">
                                <span>Livraison 100% Gratuite</span>
                                <br>
                                <span>Pour la France métropolitaine, la Belgique et l’ Italie</span>
                            </v-content>
                            <p>Nos livraisons peuvent être effectuées à votre convenance : à domicile, sur votre lieu de travail , dans un centre de montage…</p>
                        </v-content>
                    </v-col>
                    <v-col class="bc_blue" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-img src="@/assets/AboutUs.png"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phone.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold tc_blue align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="3" xl="3" class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="2" offset-lg="1" xl="3" lg="3">
                        <v-img style="position: absolute; width: 20%;" src="@/assets/illustration-man.png"/>
                    </v-col>
                </v-row>
            </v-container>

            <v-col v-if="$vuetify.breakpoint.lgAndUp" class="bc_white tc_black" >
                <v-row style="margin-top: 5%"  class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col offset-lg="1" lg="5" class="px-0">
                       <p class="font-weight-bold headline">LIVRAISON :</p>
                        <p>Toutes nos LIVRAISONS sont assurées par des professionnels de renom réputés pour leur fiabilité : GLS, UPS, TNT.</p>
                        <p>Les commandes sont uniquement acheminées à l’adresse de votre choix.</p>
                        <span>Aucune marchandise ne peut être prise dans nos locaux.</span>
                        <p>Espace-jantes Sarl ne travaille que par correspondance.</p>
                        <span>
                            A la réception de votre commande, il est important de bien vérifier que votre colis n’ait pas été endommagé pendant le transport et que le matériel est en parfait état.
                            Cette vérification doit se faire en présence du chauffeur.
                            Dans le cas où le matériel est endommagé, refusez- le et notez le détail des dommages sur le bon de livraison du transporteur.
                        </span>
                        <br>
                        <span>Ceci est très important car sans ce document nous ne serons pas en mesure de vous représenter auprès du transporteur en cas de problème.</span>
                        <p>En dehors de cette procédure, aucun dédommagement ne pourra être demandé à la société Espace-Jantes.</p>
                        <br>
    <!--                    <p class="font-weight-bold headline">LIVRAISON DANS LES DOM-TOM :</p>-->
    <!--                    <p>-->
    <!--                        Pour les envois dans les DOM-TOM : Les territoires désignés par l’abréviation DOM-TOM et dans lesquels LDPrestige expédie ses produits sont les suivants :-->
    <!--                        Guadeloupe, Guyane, Martinique, La Réunion, Nouvelle- Calédonie, Mayotte, Polynésie française, Saint-Barthélemy, Saint-Martin, Saint-Pierre- et-Miquelon, Wallis-et- Futuna.-->
    <!--                    </p>-->
    <!--                    <span class="font-weight-bold">Important :</span>-->
    <!--                    <br>-->
    <!--                    <span>Votre commande vous sera facturée hors taxe (l’adresse de livraison devra se situer dans les DOM-TOM).</span>-->
    <!--                    <br>-->
    <!--                    <span>Votre commande sera soumise à la perception des octrois de mer par les services territoriaux de la douane-->
    <!--                        <a :href="'https://www.douane.gouv.fr/fiche/fiscalite-douaniere-dans-les-departements-doutre-mer'" target="_blank">(voir le lien ici)</a>-->
    <!--                        .-->
    <!--                    </span>-->
    <!--                    <br>-->
    <!--                    <p>Vous devrez vous acquitter des taxes et frais de dédouanement directement auprès des services de la douane.</p>-->
    <!--                    <p>Le délai moyen de transport vers les DOM-TOM se situe entre 7 et 15 jours ouvrés. Ce délai peut varier en fonction des périodes (fêtes, congés etc.)</p>-->
    <!--                    <br>-->
                        <p class="font-weight-bold headline">LIVRAISON EN SUISSE :</p>
                        <p>La Suisse ne faisant pas parti de l’Union Européenne, nous vous facturons hors-taxes</p>
                        <span class="font-weight-bold">Important :</span>
                        <br>
                        <span>La livraison se fera par notre partenaire UPS. Vous règlerez la TVA Suisse au moment de la livraison, il en va de même pour les frais de douane.</span>
                        <br>
                        <span>Ces frais varient selon le poids et l’encombrement de la marchandise.</span>
                        <br>
                        <span>Il est de la responsabilité du client de respecter les règles locales de taxation qui lui sont applicables.</span>

                    </v-col>
                    <v-col offset-lg="1" lg="4">
                        <p class="font-weight-bold headline">GARANTIES</p>
                        <p class="font-weight-bold">Garantie des vices et défaut apparents</p>
                        <p>
                            Il appartient au Client de vérifier le bon état des Produits au moment de la livraison. Cette vérification doit notamment porter sur la qualité, les quantités et les références des Produits ainsi que leur conformité à la commande.
                            Aucune réclamation ne sera prise en compte après un délai de trois jours à compter de la livraison.
                            En tout état de cause, toute réclamation concernant les colis livrés ne sera prise en compte que si le Client ayant la qualité de commerçant a émis des réserves auprès du transporteur conformément aux articles L. 133-3 et suivants du Code de commerce.
                        </p>
                        <p class="font-weight-bold">Garantie des vices et défauts cachés</p>
                        <p>Les Clients disposent d’une garantie légale de délivrance conforme (article 1604 du Code civil), d’une garantie légale contre les vices cachés (article 1604 et s. du Code civil) et d’une garantie de sécurité (article 1386-1 et s. du Code civil).</p>
                        <p>Les Clients ayant la qualité de consommateurs disposent en outre d’une garantie légale de conformité (article L. 211-1 et s. Code de la consommation).</p>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="$vuetify.breakpoint.mdAndDown" class="bc_white tc_black" >
            <v-row style="margin-top: 5%" class="font-weight-regular
                                    text-xl-h5
                                    text-lg-body-1
                                    text-md-caption
                                    text-sm-caption
                                    text-caption">
                <v-col offset-lg="1" lg="5">
                    <p class="font-weight-bold headline">LIVRAISON :</p>
                    <p>Toutes nos LIVRAISONS sont assurées par des professionnels de renom réputés pour leur fiabilité : GLS, UPS, TNT.</p>
                    <p>Les commandes sont uniquement acheminées à l’adresse de votre choix.</p>
                    <span>Aucune marchandise ne peut être prise dans nos locaux.</span>
                    <p>Espace-jantes Sarl ne travaille que par correspondance.</p>
                    <span>
                        A la réception de votre commande, il est important de bien vérifier que votre colis n’ait pas été endommagé pendant le transport et que le matériel est en parfait état.
                        Cette vérification doit se faire en présence du chauffeur.
                        Dans le cas où le matériel est endommagé, refusez- le et notez le détail des dommages sur le bon de livraison du transporteur.
                    </span>
                    <br>
                    <span>Ceci est très important car sans ce document nous ne serons pas en mesure de vous représenter auprès du transporteur en cas de problème.</span>
                    <p>En dehors de cette procédure, aucun dédommagement ne pourra être demandé à la société Espace-Jantes.</p>
                    <br>
<!--                    <p class="font-weight-bold headline">LIVRAISON DANS LES DOM-TOM :</p>-->
<!--                    <p>-->
<!--                        Pour les envois dans les DOM-TOM : Les territoires désignés par l’abréviation DOM-TOM et dans lesquels LDPrestige expédie ses produits sont les suivants :-->
<!--                        Guadeloupe, Guyane, Martinique, La Réunion, Nouvelle- Calédonie, Mayotte, Polynésie française, Saint-Barthélemy, Saint-Martin, Saint-Pierre- et-Miquelon, Wallis-et- Futuna.-->
<!--                    </p>-->
<!--                    <span class="font-weight-bold">Important :</span>-->
<!--                    <br>-->
<!--                    <span>Votre commande vous sera facturée hors taxe (l’adresse de livraison devra se situer dans les DOM-TOM).</span>-->
<!--                    <br>-->
<!--                    <span>Votre commande sera soumise à la perception des octrois de mer par les services territoriaux de la douane-->
<!--                        <a :href="'https://www.douane.gouv.fr/fiche/fiscalite-douaniere-dans-les-departements-doutre-mer'" target="_blank">(voir le lien ici)</a>-->
<!--                        .-->
<!--                    </span>-->
<!--                    <br>-->
<!--                    <p>Vous devrez vous acquitter des taxes et frais de dédouanement directement auprès des services de la douane.</p>-->
<!--                    <p>Le délai moyen de transport vers les DOM-TOM se situe entre 7 et 15 jours ouvrés. Ce délai peut varier en fonction des périodes (fêtes, congés etc.)</p>-->
<!--                    <br>-->
                    <p class="font-weight-bold headline">LIVRAISON EN SUISSE :</p>
                    <p>La Suisse ne faisant pas parti de l’Union Européenne, nous vous facturons hors-taxes</p>
                    <span class="font-weight-bold">Important :</span>
                    <br>
                    <span>La livraison se fera par notre partenaire UPS. Vous règlerez la TVA Suisse au moment de la livraison, il en va de même pour les frais de douane.</span>
                    <br>
                    <span>Ces frais varient selon le poids et l’encombrement de la marchandise.</span>
                    <br>
                    <span>Il est de la responsabilité du client de respecter les règles locales de taxation qui lui sont applicables.</span>
                    <br>
                    <p class="font-weight-bold headline">GARANTIES</p>
                    <p class="font-weight-bold">Garantie des vices et défaut apparents</p>
                    <p>
                        Il appartient au Client de vérifier le bon état des Produits au moment de la livraison. Cette vérification doit notamment porter sur la qualité, les quantités et les références des Produits ainsi que leur conformité à la commande.
                        Aucune réclamation ne sera prise en compte après un délai de trois jours à compter de la livraison.
                        En tout état de cause, toute réclamation concernant les colis livrés ne sera prise en compte que si le Client ayant la qualité de commerçant a émis des réserves auprès du transporteur conformément aux articles L. 133-3 et suivants du Code de commerce.
                    </p>
                    <p class="font-weight-bold">Garantie des vices et défauts cachés</p>
                    <p>Les Clients disposent d’une garantie légale de délivrance conforme (article 1604 du Code civil), d’une garantie légale contre les vices cachés (article 1604 et s. du Code civil) et d’une garantie de sécurité (article 1386-1 et s. du Code civil).</p>
                    <p>Les Clients ayant la qualité de consommateurs disposent en outre d’une garantie légale de conformité (article L. 211-1 et s. Code de la consommation).</p>


                </v-col>
            </v-row>
        </v-col>
        </v-main>


        <v-main v-if="VUE_APP_SITE_ID === '2'">
            <v-col class="py-0" >
                <v-row>
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 font-weight-bold rotate">
                            Livraison
                        </h1>
                    </v-col>
                    <v-col lg="5" >
                        <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5 font-weight-bold ">
                            Livraison
                        </h1>
                        <v-content class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                            <p class="font-weight-bold
                               text-xl-h5
                               text-lg-h6
                               text-md-subtitle-1
                               text-sm-subtitle-1
                               text-subtitle-2">Livraison Jantes - Généralités</p>

                            <p>
                                - Livraison gratuite en France métropolitaine, en Belgique et en Italie,
                            </p>
                            <p>
                                - Temps de préparation des commandes : 2 à 7 jours après réception du paiement (délais indicatifs susceptibles de varier avec les fêtes, les congés et les jours fériés) ,
                            </p>
                            <p>
                                - Livraison : entre 48h et 72 h après expédition,
                            </p>
                            <p>
                                - Pas de retrait possible de jantes ou autres accessoires au siège social de Distri Jantes, à Aix-en Provence.
                            </p>
                            <p>
                                - Sociétés de transport missionnées par Distri Jantes : GLS, TNT, UPS
                            </p>
                            <p>
                                <b>Important :</b>
                                Le client, destinataire de la commande, procèdera à une vérification du ou des produits reçus, au moment de la livraison. Tout constat d’une détérioration liée au transport devra être notifié devant le chauffeur. Les marchandises (jantes, pneus ou accessoires) devront être refusées avec notification obligatoire sur le bon de livraison conservé par le chauffeur.
                                <br>
                                Sans bon de livraison portant mention et cause du refus de la marchandise, aucune réclamation, ni dédommagement ne pourront être envisagés.
                            </p>

                        </v-content>
                    </v-col>
                    <v-col class="pa-0" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-img src="@/assets/AboutUsDJ.png"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phoneDJ.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33974191801">09 74 19 18 01</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="5" offset-lg="1"  class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>

                </v-row>
            </v-container>

            <v-col class="bc_white tc_black" >
                <v-row class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col offset-lg="1" lg="10">
                        <br>
                        <br>
                        <p class="font-weight-bold
                                   text-xl-h5
                                   text-lg-h6
                                   text-md-subtitle-1
                                   text-sm-subtitle-1
                                   text-subtitle-2">Livraison Jantes dans les DOM TOM</p>

                        <br>
                        <p>
                            - Liste des DOM-TOM où la livraison de jantes est possible : Guadeloupe, Guyane, Martinique, La Réunion, Nouvelle- Calédonie, Mayotte, Polynésie française, Saint-Barthélemy, Saint-Martin, Saint-Pierre- et-Miquelon, Wallis-et- Futuna.
                        </p>
                        <p>
                            - Facturation HT
                            <br>
                            Les frais de dédouanement et les taxes d’outre-mer (Octroi de Mer) seront à acquitter directement aux services de la Douane.
                        </p>
                        <p>
                            - Estimation des délais d’acheminement (variable selon la période, vacances, jours fériés...) : entre 7 et 15 jours.
                        </p>
                        <br>
                        <p class="font-weight-bold
                                   text-xl-h5
                                   text-lg-h6
                                   text-md-subtitle-1
                                   text-sm-subtitle-1
                                   text-subtitle-2">Livraison Jantes en Suisse</p>

                        <br>
                        <p>
                            - Livraison opérée par la société UPS
                        </p>
                        <p>
                            - Facturation HT (pays hors CE)
                            <br>
                            Frais de douane (calculés selon le poids et l’encombrement) et TVA acquittés au moment de la livraison.
                        </p>
                        <br>
                        <p class="font-weight-bold
                                   text-xl-h5
                                   text-lg-h6
                                   text-md-subtitle-1
                                   text-sm-subtitle-1
                                   text-subtitle-2">Garanties légales
                        </p>

                        <br>
                        <p>
                            - Garanties des vices et défauts apparents
                        </p>
                        <p>
                            En substance, la responsabilité du contrôle de l’état de la marchandise lors de sa livraison revient au client. Ce dernier s’assurera de la qualité, de la quantité et de la conformité des produits livrés par rapport à la commande.
                        </p>
                        <p>
                            Délai de réclamation obligatoire dans les trois jours suivant la livraison.
                        </p>
                        <p>
                            L’article L-133-3 et suivants du Code de commerce rappelle que toute réclamation relative à un colis livré ne pourra être effective que si le client a émis des réserves auprès du transporteur.
                        </p>
                        <p>
                            - Facturation HT (pays hors CE)
                            <br>
                            Frais de douane (calculés selon le poids et l’encombrement) et TVA acquittés au moment de la livraison.
                        </p>
                        <p>
                           - Garanties des vices et défauts cachés
                        </p>
                        <p>
                            Au nombre de 3 : la garantie légale de délivrance conforme (Article 1604 du Code civil), la garantie légale contre les vices cachés (article 1604 et suivants du Code civil) et la garantie de sécurité (article 1386-1 et suivants du Code civil).
                        </p>

                    </v-col>
                </v-row>
            </v-col>

        </v-main>

        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "../headers/HomepageHeaderEj";
import HomepageHeaderDj from "../headers/HomepageHeaderDj";
import HomepageFooterEj from "../footer/HomepageFooterEj";
import HomepageFooterDj from "../footer/HomepageFooterDj";

    export default {
        name: "Livraison",
        components:{
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,

        },
        data: () => ({
            VUE_APP_SITE_ID:null
        }),
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        },
        metaInfo() {
            return {
                title: `Livraison - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + `  - Jantes, Pneus & Accessoires`,
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `Livraison - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {name: 'twitter:description', content: `Conditions de livraison ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `Livraison -` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {property: 'og:site_name', content: this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`},
                    {property: 'og:type', content: 'Website'},
                    {property: 'og:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},
                    {property: 'og:description', content: `Conditions de livraison ` + (this.VUE_APP_SITE_ID === '1' ? `d'Espace Jantes` : `de Distri Jantes`) + ` , pour vos Jantes, pneus et accessoires.`},
                    {name: 'robots', content: 'index,follow'}
                ]
            }
    },
    }
</script>

<style scoped>
    .border_left{
        border-left: 2px solid #1DE4AF
    }
    .rotate {
        transform: rotate(-90deg);
        margin-top: 6%;
        position: absolute;
    }
</style>
