<template>
    <v-app>
        <v-row class="bc_white 1 py-0">
            <!--            <v-row>-->
            <v-col cols="12" lg="9" class="pa-0">

                <ConfigurateurHeader :header-title="headerTitle" class="bc_lightGrey"/>

                <v-col class="bc_white">
                      <v-content v-if="this.loadingData === true">
                          <v-img v-if="VUE_APP_SITE_ID === '1'" src="@/assets/loader.gif" style="max-width: 40%; margin: auto" alt='Espace-Jantes - Chargement Jantes' title="Espace-Jantes - Chargement Jantes"/>
                          <v-img v-if="VUE_APP_SITE_ID === '2'" src="@/assets/loaderDJ.gif" style="max-width: 40%; margin: auto" alt='Distri-Jantes - Chargement Jantes' title="Distri-Jantes - Chargement Jantes"/>
                      </v-content>
                    <v-col v-else offset-lg="1" lg="11" class="mt-7 tc_grey">

                        <v-radio-group v-if="$vuetify.breakpoint.lgAndUp" v-model="payment" class="font-weight-bold " @change="changePayement">
                            <v-col cols="12" class="text__img" style="justify-content: flex-start;">
                                <v-radio class="mb-0" value="CB" label="Paiement par carte bancaire"/>
                                <span style="visibility: collapse; white-space: pre-wrap">    space     </span>
                                <v-img src="@/assets/all_cartes.png" style="max-width: 7%"/>
                            </v-col>

                            <v-col cols="12" class="text__img" style="justify-content: flex-start;">
                                <v-radio  value="cofidis" label="Paiement par carte bancaire en 4 fois By Cofidis. Accord immédiat sans justificatif."/>
                                <span style="visibility: collapse; white-space: pre-wrap">    space     </span>
                                <v-img style="max-width: 6%;"  src="@/assets/logo/logo-Cofidis-Pay_4xcb_resized.png" />
                            </v-col>

                            <v-col cols="12" class="text__img" style="justify-content: flex-start;">
                                <v-radio class="mb-0" value="paypal" label="Paiement par PayPal"/>
                                <span style="visibility: collapse; white-space: pre-wrap">    space     </span>
                                <v-img src="@/assets/logo/paypal.svg" max-width="6%"/>
                            </v-col>

                            <v-col cols="12" class="text__img">
                                <v-radio class="mb-0" value="virement" label="Paiement par virement bancaire"/>
                            </v-col>

                            <v-col cols="12" class="text__img">
                                <v-radio class="mb-0" value="cheque" label="Paiement par chèque"/>
                            </v-col>

                            <!--                           <v-col lg="4" class="text__img">-->
                            <!--                               <v-radio class="mb-0" value="applePay" label="Paiement par Apple pay"/>-->
                            <!--                               <v-img src="@/assets/logo/apple_pay.png" max-width="20%"/>-->
                            <!--                           </v-col>-->

                        </v-radio-group>

                        <v-col v-if="this.errorPaypal" class="tc_red">Oups, il semblerait qu'un probleme soit survenu, veuillez essayer un autre moyen de payement</v-col>


                        <v-radio-group v-if="$vuetify.breakpoint.mdAndDown" v-model="payment" class="font-weight-bold " @change="changePayement">
                            <v-row>
                                <v-col cols="12" class="text__img">
                                    <v-radio class="mb-0" value="CB" label="Paiement par carte bancaire"/>
                                    <v-img src="@/assets/all_cartes.png" style="max-width: 25%"/>
                                </v-col>

                                <v-col cols="12" class="text__img">
                                    <v-radio  value="cofidis" label="Paiement par carte bancaire en 4 fois By Cofidis. Accord immédiat sans justificatif."/>
                                    <v-img style="max-width: 15%;"  src="@/assets/logo/logo-Cofidis-Pay_4xcb_resized.png" />
                                </v-col>

                                <v-col cols="12" class="text__img">
                                    <v-radio class="mb-0" value="paypal" label="Paiement par PayPal"/>
                                    <v-img src="@/assets/logo/paypal.svg" max-width="18%"/>
                                </v-col>

                                <v-col cols="12" class="text__img">
                                    <v-radio class="mb-0" value="virement" label="Paiement par virement bancaire"/>
                                </v-col>

                                <v-col cols="12" class="text__img">
                                    <v-radio class="mb-0" value="cheque" label="Paiement par chèque"/>
                                </v-col>
                            </v-row>
                            <!--                           <v-col lg="4" class="text__img">-->
                            <!--                               <v-radio class="mb-0" value="applePay" label="Paiement par Apple pay"/>-->
                            <!--                               <v-img src="@/assets/logo/apple_pay.png" max-width="20%"/>-->
                            <!--                           </v-col>-->

                        </v-radio-group>

                        <v-col cols="12" class="font-weight-bold text__img">
                            <v-checkbox v-model="CGVCheckBox" hide-details style="white-space: pre-wrap" class="mb-0" label=" J’ai lu et j’accepte les conditions générales *" @click="CheckBoxIsChecked" />
                        </v-col>

<!--                        <form action="https://p.monetico-services.com/test/paiement.cgi" method="post" id="PaymentRequest" ref="formPayement">-->
                        <form action="https://p.monetico-services.com/paiement.cgi" method="post" id="PaymentRequest" ref="formPayement">
                            <input type="hidden" name="MAC"               v-bind:value="sceauMac" />
                            <input type="hidden" name="3dsdebrayable"     v-bind:value="1"/>
                            <input type="hidden" name="TPE"               v-bind:value="TPENUMBER" />
                            <input type="hidden" name="contexte_commande" v-bind:value="contexteCommandeFinal" />
                            <input type="hidden" name="date"              v-bind:value="datePayement" />

                            <input type="hidden" name="lgue"              v-bind:value="LGE" />
                            <input type="hidden" name="mail"              v-bind:value="infoUser.mail" />
                            <input type="hidden" name="montant"           v-bind:value="montant" />

                            <input type="hidden" name="reference"         v-bind:value="ref"/>
                            <input type="hidden" name="societe"           v-bind:value="SOCIETE" />
                            <input type="hidden" name="texte-libre"       :value="TEXTLIBRE" />
                            <input type="hidden" name="url_retour_err"    :value="nextStepRouteError"/>
                            <input type="hidden" name="url_retour_ok"     :value="nextStepRouteSuccess"/>
                            <input type="hidden" name="version"           value="3.0" />

                            <template v-if="paiementdiffere">
                                <input type="hidden" name="prenomclient" v-bind:value="formulaireMonetico.prenomClient"/>
                                <input type="hidden" name="nomclient" v-bind:value="formulaireMonetico.nomClient"/>
                                <input type="hidden" name="telephonemobileclient" v-bind:value="formulaireMonetico.telephoneClient"/>
                                <input type="hidden" name="adresseclient" v-bind:value="formulaireMonetico.adresseClient"/>
                                <input type="hidden" name="codepostalclient" v-bind:value="formulaireMonetico.codePostalClient"/>
                                <input type="hidden" name="villeclient" v-bind:value="formulaireMonetico.villeClient"/>
                                <input type="hidden" name="protocole" value="4xcb"/>
                            </template>
                        </form>
                        <div style='width:30px'>
                            <div id="paypal-button-container"></div>
                        </div>
                        <template v-if="this.payment != 'paypal'">
                            <v-btn v-if="btnLoading === false" color="primary" @click="this.sendCommande" :disabled="disabled" >Continuer</v-btn>
                            <v-btn v-if="btnLoading === true" loading color="primary" @click="this.sendCommande" :disabled="disabled" >Continuer</v-btn>
                        </template>
                    </v-col>
                </v-col>
                <div v-if="$vuetify.breakpoint.lgAndUp" style="height: 30vh"></div>
            </v-col>

            <v-col lg="3" class="pa-0">
                <Menu/>
            </v-col>
            <!--            </v-row>-->
        </v-row>
        <ConfigurateurFooter/>

    </v-app>
</template>

<!--      Live Paypal-->
    <script src="https://www.paypal.com/sdk/js?client-id={{process.env.VUE_APP_PAYPAL_ID}}&currency=EUR&disable-funding=credit,card" data-namespace="paypal_sdk"></script>

<!--    Sandbox Paypal-->
<!--<script src="https://www.paypal.com/sdk/js?client-id=AYKgeCHpln2EQIRBKQB2-sdZP4na_UCuJSTHoEFzRdkyG0GOzryiCyZQdDGP_G5z7Q2mxklhZZ_EljWH&currency=EUR&disable-funding=credit,card" data-namespace="paypal_sdk"></script>-->


<script>
import ConfigurateurHeader from "../headers/ConfigurateurHeader";
import ConfigurateurFooter from "../footer/ConfigurateurFooter";
import Menu from "./Menu";
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {mapState} from 'vuex'
import utf8 from 'utf8'
import algoliasearch from "algoliasearch";
import SecureLS from "secure-ls";

var ls = new SecureLS({ isCompression: false });
import CryptoJS from "crypto-js";
Vue.use(VueAxios, axios)

let TPENUMBER
let LGE
let SOCIETE
let KEY
let TEXTLIBRE

if(process.env.VUE_APP_SITE_ID === '1'){
     TPENUMBER = 2823141
     LGE = "FR"
     SOCIETE = "espacejant"
     KEY = "C292E713B418948B97A1CC8467F1823EA78B229D"
     TEXTLIBRE = "Paiement Espace-Jantes"
}else if(process.env.VUE_APP_SITE_ID === '2'){
     TPENUMBER = 2951824
     LGE = "FR"
     SOCIETE = "distrijant"
     KEY = "4B13E0E95B4ED984F58E01F2C38D7D8F6AF3209D"
     TEXTLIBRE = "Paiement Distri-Jantes"
}

const DEVISE = "EUR"
export default {
    name: "Etape5",
    components:{
        ConfigurateurHeader,
        ConfigurateurFooter,
        Menu
    },
    data:() =>({
        CGVCheckBox: false,
        headerTitle:8,
        payment:'',
        statusCommande:null,
        paymentCommande:null,
        sceauMac:'',
        TPENUMBER:TPENUMBER,
        commandeContexte:'',
        datePayement:'',
        LGE:LGE,
        infoUser:'',
        montant: '',
        montantPaypal:'',
        SOCIETE:SOCIETE,
        KEY:KEY,
        TEXTLIBRE:TEXTLIBRE,
        DEVISE:DEVISE,
        contexteCommande:{},
        contexteCommandeFinal:"",
        usableKey:"",
        ref:'',
        paiementdiffere:false,
        montantech1:"",
        dateech1:"",
        montantech2:"",
        dateech2:"",
        montantech3:"",
        dateech3:"",
        montantech4:"",
        dateech4:"",
        nbrech:"",
        paiementPaypal:false,
        disabled:true,
        btnLoading:false,
        loadingData: true,
        formulaireMonetico:{
            nomClient:'MR'
        },
        typeMonte: 'par4',
        infoCommande: {},
        formatedJanteAv : {},
        formatedJanteArr : {},
        formatedPneuAv : {},
        formatedPneuArr : {},
        itemsPaypal:[],
        SelectedJanteAv: {},
        SelectedJanteArr: {},
        SelectedPneuAv: {},
        SelectedPneuArr: {},
        nextStepRouteSuccess:"",
        nextStepRouteError:"",
        errorPaypal:false,
        VUE_APP_SITE_ID:null
    }),
    methods:{
        setOnglet(item, value){
            // console.log('setOnglet',value, item)

            switch(value){
                case"4jantes":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : item, jantesArr : item})
                    this.infoJante = item
                    this.currentColor = this.colors[0].name;
                    this.photoPrincipal = this.infoJante.photo_1
                    this.triggerUpdate ++
                    break
                case"jantesAv":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : item, jantesArr : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr})
                    this.triggerUpdate ++
                    break
                case"jantesArr":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv, jantesArr : item})
                    this.triggerUpdate ++
                    break
                case"4pneus":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : item, pneusArr : item})
                    this.triggerUpdate ++
                    break
                case"pneusAv":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : item, pneusArr : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr})
                    this.triggerUpdate ++
                    break
                case"pneusArr":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv, pneusArr : item})
                    this.triggerUpdate ++
                    break
                case"accessoires":
                    this.TotalPriceAccessoires = 0
                    Object.values(item).forEach((e) => {
                        if(e != null) {
                            this.TotalPriceAccessoires += parseInt(e.data.price)
                        }
                    })
                    // console.log('this.TotalPriceAccessoires', this.TotalPriceAccessoires)
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires', {accessoires: item, totalPrice: this.TotalPriceAccessoires})

                    this.triggerUpdate ++
                    break
                case"indexAccessoires":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires', {accessoires: ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').accessoires,totalPrice: ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').totalPrice , selected: item })
                    this.triggerUpdate ++
                    break
                case"modelePossibleAuCarre":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre', item)
                    break
                case"modelePossibleFront":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront', item)
                    break
                case"modelePossibleRear":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear', item)
                    break
                case"reference":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__reference', item)
                    break
            }

            ls.set('last_updated', this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale)

        },
        getOnglet(value){
            switch(value){
                case'jantesAv':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv
                case 'jantesArr':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr
                case 'pneusAv':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv
                case 'pneusArr':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr
                case 'accessoires':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').accessoires
                case 'totalPriceAccessoires':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').totalPrice
                case 'modelePossibleAuCarre':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre')
                case 'modelePossibleFront':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront')
                case 'modelePossibleRear':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear')
            }
        },
        changePayement : function(){
            this.errorPaypal = false
            if(this.payment != "" && this.CGVCheckBox){
                this.disabled = false
            }
            this.paiementdiffere = false
            this.paiementPayPal = false
            this.$el.querySelector('#paypal-button-container').innerHTML = ""
            if(this.payment == "cofidis"){
                this.paiementdiffere = true

            }
            if(this.payment == "paypal"){
                this.paiementPayPal = true
                const montantPaypal = this.montantPaypal
                self = this
                paypal_sdk.Buttons({
                    //https://github.com/paypal/Checkout-NodeJS-SDK/blob/develop/samples/AuthorizeIntentExamples/createOrder.js
                    createOrder: function(data, actions) {
                        return actions.order.create({
                            "purchase_units": [
                                {
                                    "amount": {
                                        "currency_code": "EUR",
                                        "value": parseFloat(montantPaypal) + 9.90,
                                        "breakdown": {
                                            "item_total": {
                                                "currency_code": "EUR",
                                                // "value":  self.getRoundPriceTVA(montantPaypal - 9.90, "total witout TVA"),
                                                "value":  montantPaypal,
                                            },
                                            "shipping": {
                                                "currency_code": "EUR",
                                                "value": "0.00"
                                            },
                                            "handling": {
                                                "currency_code": "EUR",
                                                "value": "9.90"
                                            },
                                            // "tax_total": {
                                            //     "currency_code": "EUR",
                                            //     "value":  self.getRoundPriceTVA(montantPaypal - 9.90, "total only TVA"),
                                            // },
                                            "shipping_discount": {
                                                "currency_code": "EUR",
                                                "value": "0"
                                            }
                                        }
                                    },
                                    "items": self.itemsPaypal,
                                    "shipping": {
                                        "name": {
                                            "full_name":self.InfoUserAdresseLivr.nom + ' ' + self.InfoUserAdresseLivr.prenom
                                        },
                                        "address": {
                                            "address_line_1": self.InfoUserAdresseLivr.adresse,
                                            "address_line_2": self.InfoUserAdresseLivr.complement_adresse,
                                            "admin_area_2": self.InfoUserAdresseLivr.ville,
                                            "admin_area_1": "",
                                            "postal_code": self.InfoUserAdresseLivr.code_postal,
                                            "country_code": "FR"
                                        },
                                    }
                                }
                            ]
                        });
                    },
                    onApprove: function(data, actions) {
                        return actions.order.capture().then(function(details) {
                            self.statusCommande = 2
                            self.paymentCommande = 2
                            self.$store.commit('typePayement/setType', self.paymentCommande)
                            // self.$store.commit('loader/setLoader', true)
                            self.loadingData = true

                            console.log('paypal success', data)

                            setTimeout(function(){
                                self.$router.push({name:'Etape6Success',params: { refPaypal : 'true', marque: self.$route.params.marque, modele : self.$route.params.modele, diametre: self.$route.params.diametre, couleur: self.$route.params.couleur, decale: self.$route.params.decale }})
                            },1000)
                            // console.log('data', data)
                            // console.log('actions', actions)
                        });
                    },
                    onCancel: function () {
                        setTimeout(function(){
                            console.log('paypal canceled')
                        },1000)
                    },
                    onError: function () {
                        setTimeout(function(){
                            console.log('error', [
                                {
                                    "amount": {
                                        "currency_code": "EUR",
                                        "value": parseFloat(montantPaypal) + 9.90,
                                        "breakdown": {
                                            "item_total": {
                                                "currency_code": "EUR",
                                                // "value":  self.getRoundPriceTVA(montantPaypal - 9.90, "total witout TVA"),
                                                "value":  montantPaypal,
                                            },
                                            "shipping": {
                                                "currency_code": "EUR",
                                                "value": "0.00"
                                            },
                                            "handling": {
                                                "currency_code": "EUR",
                                                "value": "9.90"
                                            },
                                            // "tax_total": {
                                            //     "currency_code": "EUR",
                                            //     "value":  self.getRoundPriceTVA(montantPaypal - 9.90, "total only TVA"),
                                            // },
                                            "shipping_discount": {
                                                "currency_code": "EUR",
                                                "value": "0"
                                            }
                                        }
                                    },
                                    "items": self.itemsPaypal,
                                    "shipping": {
                                        "name": {
                                            "full_name":self.InfoUserAdresseLivr.nom + ' ' + self.InfoUserAdresseLivr.prenom
                                        },
                                        "address": {
                                            "address_line_1": self.InfoUserAdresseLivr.adresse,
                                            "address_line_2": self.InfoUserAdresseLivr.complement_adresse,
                                            "admin_area_2": self.InfoUserAdresseLivr.ville,
                                            "admin_area_1": "",
                                            "postal_code": self.InfoUserAdresseLivr.code_postal,
                                            "country_code": "FR"
                                        },
                                    }
                                }
                            ])
// console.log(", self.SelectedJanteAv, self.SelectedPneuArr", self.SelectedJanteAv, self.SelectedPneuArr)
                            self.errorPaypal = true
                            // self.$router.push({name:'Etape6',params: { refPaypal : 'false', marque: self.$route.params.marque, modele : self.$route.params.modele, diametre: self.$route.params.diametre, couleur: self.$route.params.couleur, decale: self.$route.params.decale }})
                        },1000)
                    },
                }).render('#paypal-button-container');
            }
            this.PrepareToHmac()
        },
        sendCommande: function (){
            switch (this.payment){
                case "CB" :
                    this.statusCommande = 2
                    this.paymentCommande = 1
                    break
                case "paypal" :
                    this.statusCommande = 2
                    this.paymentCommande = 2
                    break
                case "cofidis" :
                    this.statusCommande = 2
                    this.paymentCommande = 3
                    break
                case "virement" :
                    this.statusCommande = 1
                    this.paymentCommande = 4
                    break
                case "cheque" :
                    this.statusCommande = 1
                    this.paymentCommande = 5
                    break
                case "applePay" :
                    this.statusCommande = 2
                    this.paymentCommande = 6
                    break
            }
            this.$store.commit('typePayement/setType', this.paymentCommande)
            this.$store.commit('loader/setLoader', true)
            if(this.payment == "cofidis" || this.payment == "CB"){
                this.btnLoading = true
                setTimeout(()=>{
                    this.$refs.formPayement.submit()
                },3000)
            }
            if(this.payment == "cheque" || this.payment == "virement") {
                var data = this.infoUser
                this.$router.push({name:'Etape6Bis', params: { typePayment: this.paymentCommande, infoUser : this.infoUser, marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale}})
            }
        },
        CheckBoxIsChecked: function(){
            if(this.payment != "" && this.CGVCheckBox){
                this.disabled = false
            }
            else{
                this.disabled = true
            }
        },
        getRoundPrice: function (Prixjante, PrixPneu, type){
            let price
            var tmp = Math.pow(10, 2);

            if(this.isProfessional){
                let taux = (1 - parseFloat('0.' + this.tauxRemise))
                taux = (Math.round( taux * tmp )/tmp).toFixed(2)

                Prixjante = Prixjante * taux
            }

            Prixjante = (Math.round( Prixjante * tmp )/tmp).toFixed(2)
            PrixPneu = Math.round(PrixPneu * tmp )/tmp.toFixed(2)

            switch(type){
                case('4jantes'):
                    if(Prixjante){
                        price  = (Prixjante * 4)
                    }
                    break;
                case('1jante'):
                    if(Prixjante){
                        price  = Prixjante
                    }
                    break;
                case('2jantes'):
                    if(Prixjante){
                        price  = (Prixjante * 2)
                    }
                    break;
                case('4pneus'):
                    if(PrixPneu){
                        price  = ((PrixPneu + 10) * 4)
                    }
                    break;
                case('2pneus'):
                    if(PrixPneu){
                        price  = ((PrixPneu + 10) * 2)
                    }
                    break;
                case('1pneu'):
                    if(PrixPneu){
                        price  = (PrixPneu + 10)
                    }
                    break;
                case('SOUS TOTAL'):
                    if(Prixjante && PrixPneu){
                        price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires)
                    }else if(Prixjante){
                        price  = ((Prixjante * 4) + this.TotalPriceAccessoires)
                    }
                    break;
                case('TOTAL'):
                    if(Prixjante && PrixPneu){
                        price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90)
                    }else if(Prixjante){
                        price  = ((Prixjante * 4) + this.TotalPriceAccessoires + 9.90)
                    }
                    break;
                case('TOTAL COFIDIS'):
                    if(Prixjante && PrixPneu){
                        price  = (((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90) * 1.022)
                    }else if(Prixjante){
                        price  = (((Prixjante * 4) + this.TotalPriceAccessoires + 9.90) * 1.022)
                    }
                    break;
                case('COFIDIS'):
                    if(Prixjante && PrixPneu){
                        price  = (((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires) * 1.022) /4
                    }else if(Prixjante){
                        price  = (((Prixjante * 4) + this.TotalPriceAccessoires) * 1.022) /4
                    }
                    break;
                case('TVA'):
                    if(Prixjante && PrixPneu){
                        price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90 ) * 0.2
                    }else if(Prixjante){
                        price  = ((Prixjante * 4) + this.TotalPriceAccessoires + 9.90) * 0.2
                    }
                    break;
            }

            return (Math.round( price * tmp )/tmp).toFixed(2)
        },
        getRoundPriceMonteDecale: function (PrixjanteAv, PrixjanteArr, PrixPneuAv, PrixPneuArr, type){
            let price
            var tmp = Math.pow(10, 2);

            if(this.isProfessional){
                let taux = (1 - parseFloat('0.' + this.tauxRemise))
                taux = (Math.round( taux * tmp )/tmp).toFixed(2)

                PrixjanteAv = PrixjanteAv * taux
                PrixjanteArr = PrixjanteArr * taux
            }

            PrixjanteAv = Math.round(PrixjanteAv * tmp )/tmp.toFixed(2)
            PrixjanteArr = Math.round(PrixjanteArr * tmp )/tmp.toFixed(2)
            PrixPneuAv = Math.round(PrixPneuAv * tmp )/tmp.toFixed(2)
            PrixPneuArr = Math.round(PrixPneuArr * tmp )/tmp.toFixed(2)

            switch(type){
                case('4jantes decale'):
                    if(PrixjanteAv && PrixjanteArr){
                        price  = (PrixjanteAv * 2) + (PrixjanteArr * 2)
                    }
                    break;
                case('4pneus decale'):
                    if(PrixPneuAv && PrixPneuArr){
                        price  = (((PrixPneuAv + 10) * 2) + ((PrixPneuArr + 10) * 2))
                    }
                    break;
                case('2jantesAv'):
                    if(PrixjanteAv){
                        price  = (PrixjanteAv * 2)
                    }
                    break;
                case('2jantesArr'):
                    if(PrixjanteArr){
                        price  = (PrixjanteArr * 2)
                    }
                    break;
                case('2pneusAv'):
                    if(PrixPneuAv){
                        price  = ((PrixPneuAv + 10) * 2)
                    }
                    break;
                case('2pneusArr'):
                    if(PrixPneuArr){
                        price  = ((PrixPneuArr + 10) * 2)
                    }
                    break;
                case('1jante'):
                    if(PrixjanteAv){
                        price  = PrixjanteAv
                    }
                    if(PrixjanteArr){
                        price  = PrixjanteArr
                    }
                    break;
                case('1pneu'):
                    if(PrixPneuAv){
                        price  = (PrixPneuAv + 10)
                    }
                    if(PrixPneuArr){
                        price  = (PrixPneuArr + 10)
                    }
                    break;
                case('SOUS TOTAL DECALE'):
                    if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                        price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires)
                    }else if(PrixjanteAv && PrixjanteArr){
                        price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires)
                    }
                    break;
                case('TOTAL'):
                    if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                        price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires + 9.90)
                    }else if(PrixjanteAv && PrixjanteArr){
                        price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires + 9.90)
                    }
                    break;
                case('TOTAL COFIDIS'):
                    if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                        price  = (((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires + 9.90) * 1.022)
                    }else if(PrixjanteAv && PrixjanteArr){
                        price  = (((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires + 9.90) * 1.022)
                    }
                    break;
                case('TVA'):
                    if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                        price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires + 9.90) * 0.2
                    }else if(PrixjanteAv && PrixjanteArr){
                        price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires + 9.90) * 0.2
                    }
                    break;
                case('COFIDIS DECALE'):
                    if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                        price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)  +  (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires) /4
                    }else if(PrixjanteAv && PrixjanteArr){
                        price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)  + this.TotalPriceAccessoires) /4
                    }
                    break;
            }
            return (Math.round( price * tmp )/tmp).toFixed(2)
        },
        getRoundPriceTVA: function (Prix, type, isPneu){
            let price
            var tmp = Math.pow(10, 2);

            if(this.isProfessional){
                if(!isPneu) {
                    if(type !== "total only TVA" && type !== "total witout TVA") {
                        let taux = (1 - parseFloat('0.' + this.tauxRemise))
                        taux = (Math.round(taux * tmp) / tmp).toFixed(2)

                        Prix = Prix * taux
                    }
                }
            }
            if(isPneu){
                Prix = Prix + 10
            }
            Prix = (Math.round(Prix * tmp) / tmp).toFixed(2)

            switch(type){
                case('only TVA'):
                    price  = Prix * 0.2
                    break;
                case('witout TVA'):
                    price  = Prix * (1 - 0.2)
                    break;
                case('total only TVA'):
                    price  = Prix * 0.2
                    break;
                case('total witout TVA'):
                    price  = Prix * (1 - 0.2)
                    break;
            }
            return (Math.round( price * tmp )/tmp).toFixed(2)
        },
        getContexteCommande: function() {
            var adresseBilling = {
                firstName : this.InfoUserAdresseFact.prenom.replace(/\s*$/,'').replace(/\s/,'-'),
                lastName : this.InfoUserAdresseFact.nom.replace(/\s*$/,'').replace(/\s/,'-'),
                addressLine1: this.InfoUserAdresseFact.adresse,
                mobilePhone : '+33-612345678',
                city: this.InfoUserAdresseFact.ville,
                postalCode: this.InfoUserAdresseFact.code_postal.toString(),
                country:(this.InfoUserAdresseFact.pays == "France")? "FR":"FR"
            }
            var adresseShipping = {
                firstName : this.InfoUserAdresseFact.prenom.replace(/\s*$/,'').replace(/\s/,'-'),
                lastName : this.InfoUserAdresseFact.nom.replace(/\s*$/,'').replace(/\s/,'-'),
                addressLine1: this.InfoUserAdresseFact.adresse,
                city: this.InfoUserAdresseFact.ville,
                postalCode: this.InfoUserAdresseFact.code_postal.toString(),
                country:(this.InfoUserAdresseFact.pays == "France")? "FR":"FR",
                email:this.infoUser.mail,
                phone : "+33-612345678",
                shipIndicator : "billing_address",
                deliveryTimeframe: "two_day",
                firstUseDate : "2017-01-25",
                matchBillingAddress : true
            }
            this.contexteCommande.billing = adresseBilling
            this.contexteCommande.shipping = adresseShipping

            var user = {
                email: this.infoUser.mail
            }
            this.contexteCommande.client = user
            //var FromMonetico = '{ "billing" : { "firstName" : "Adé", "lastName" : "Lovelace", "addressLine1" : "101 Rue de Roisel", "mobilePhone" : "+33-612345678", "city" : "Y", "postalCode" : "80190", "country" : "FR" }, "shipping" : { "firstName" : "Adé", "lastName" : "Lovelace", "addressLine1" : "101 Rue de Roisel", "city" : "Y", "postalCode" : "80190", "country" : "FR", "email" : "ada@some.tld", "phone" : "+33-612345678", "shipIndicator" : "billing_address", "deliveryTimeframe" : "two_day", "firstUseDate" : "2017-01-25", "matchBillingAddress" : true }, "client" : { "email" : "ada@some.tld" } }'
            var ContexteCommande = '{ "billing" : { "firstName" : "'+ this.InfoUserAdresseFact.prenom.replace(/\s*$/,'').replace(/\s/,'-')  +'", "lastName" : "'+ this.InfoUserAdresseFact.nom.replace(/\s*$/,'').replace(/\s/,'-') +'", "addressLine1" : "101 Rue de Roisel", "mobilePhone" : "+33-612345678", "city" : "Y", "postalCode" : "80190", "country" : "FR" }, "shipping" : { "firstName" : "Adé", "lastName" : "Lovelace", "addressLine1" : "101 Rue de Roisel", "city" : "Y", "postalCode" : "80190", "country" : "FR", "email" : "ada@some.tld", "phone" : "+33-612345678", "shipIndicator" : "billing_address", "deliveryTimeframe" : "two_day", "firstUseDate" : "2017-01-25", "matchBillingAddress" : true }, "client" : { "email" : "ada@some.tld" } }'
            // var ContexteCommande = '{ "billing" : { "firstName" : "azel", "lastName" : "aze", "addressLine1" : "10 route de galice", "mobilePhone" : "+33-612345678", "city" : "Aix-en-Provence", "postalCode" : "13100", "country" : "FR" }, "shipping" : { "firstName" : "azel", "lastName" : "aze", "addressLine1" : "10 route de galice", "city" : "Aix-en-Provence", "postalCode" : "13100", "country" : "FR", "email" : "aze@email.com", "phone" : "+33-612345678", "shipIndicator" : "billing_address", "deliveryTimeframe" : "two_day", "firstUseDate" : "2017-01-25", "matchBillingAddress" : true }, "client" : { "email" : "aze@email.com" } }'
            // var ContexteCommande = '{ "billing" : { "firstName" : "JEAN", "lastName" : "ANDRE", "addressLine1" : "101 Rue de Roisel", "mobilePhone" : "+33-612345678", "city" : "Y", "postalCode" : "80190", "country" : "FR" }, "shipping" : { "firstName" : "Adé", "lastName" : "Lovelace", "addressLine1" : "101 Rue de Roisel", "city" : "Y", "postalCode" : "80190", "country" : "FR", "email" : "ada@some.tld", "phone" : "+33-612345678", "shipIndicator" : "billing_address", "deliveryTimeframe" : "two_day", "firstUseDate" : "2017-01-25", "matchBillingAddress" : true }, "client" : { "email" : "ada@some.tld" } }'
            // console.log('ContexteCommande', ContexteCommande)

            setTimeout(()=>{
                var ut8EncodeContexteCommande = utf8.encode(ContexteCommande)

                this.contexteCommandeFinal = btoa(unescape( encodeURIComponent( ut8EncodeContexteCommande) ) )
                // console.log('contexteCommandeFinal', this.contexteCommandeFinal)
                var data = new URLSearchParams(); // N'est pas supporté partout
                data.append('contexteBrut', ContexteCommande);
                // console.log('data', data)
self = this

                    Vue.axios.post(process.env.VUE_APP_PAY_BASEURL +'/Monetico_EJ_DJ/getContexteCommande.php',data).then((response) => {
                        self.contexteCommandeFinal = response.data.contexteTransformed
                        // console.log('resp', response)
                        setTimeout(()=>{
                            self.loadingData = false
                        },1500)
                    })


            }, 1000)

        },
        PrepareToHmac: function () {
            var fields = []
            fields['3dsdebrayable'] = "3dsdebrayable=" + this.$refs.formPayement[1].value
            fields['TPE'] = "TPE=" + this.$refs.formPayement[2].value
            if(this.paiementdiffere === true){
                fields['adresseclient'] = 'adresseclient=' + this.formulaireMonetico.adresseClient
                fields['codepostalclient'] = 'codepostalclient=' + this.formulaireMonetico.codePostalClient
            }
            fields['contexte_commande'] = "contexte_commande=" + this.contexteCommandeFinal
            fields['date'] = "date=" + this.$refs.formPayement[4].value
            fields['lgue'] = "lgue=" + this.$refs.formPayement[5].value
            fields['mail'] = "mail=" + this.$refs.formPayement[6].value
            fields['montant'] = "montant=" + this.montant
            if(this.paiementdiffere === true){
                fields['nomclient'] = 'nomclient=' + this.formulaireMonetico.nomClient
                fields['prenomclient'] = 'prenomclient=' + this.formulaireMonetico.prenomClient
                fields['protocole'] = 'protocole=4xcb'
            }
            fields['reference'] = "reference=" + this.$refs.formPayement[8].value
            fields['societe'] = "societe=" + this.$refs.formPayement[9].value
            if(this.paiementdiffere === true){
                fields['telephonemobileclient'] = 'telephonemobileclient=' + this.formulaireMonetico.telephoneClient
            }
            fields['texte-libre'] = "texte-libre=" + this.$refs.formPayement[10].value
            fields['url_retour_err'] = "url_retour_err=" + this.$refs.formPayement[11].value
            fields['url_retour_ok'] = "url_retour_ok=" + this.$refs.formPayement[12].value
            fields['version'] = "version=3.0"
            if(this.paiementdiffere === true){
                fields['villeclient'] = 'villeclient=' + this.formulaireMonetico.villeClient
            }

            var phase1go_fields =  Object.keys(fields).map(function(x){return fields[x];}).join('*')
            var data = new URLSearchParams(); // N'est pas supporté partout
            data.append('phase1go_fields', phase1go_fields);

                Vue.axios.post(process.env.VUE_APP_PAY_BASEURL +'/Monetico_EJ_DJ/dataToTransform.php',data).then((response) => {
                    this.sceauMac = response.data.Mac
                })


        },
        getAdresseUser:function (idUser) {
            var userId = idUser
            Vue.axios.get('https://api.espace-jantes.com/getAdresseUser', {
                params: { id_user: userId },
            })
                .then((response) => {
                    response.data.forEach(element =>{
                        if(element.type_adresse == 1 ){
                            var removeDiacritics = require('diacritics').remove;

                            this.InfoUserAdresseFact = element
                            this.formulaireMonetico.adresseClient = this.ascii_to_hex(element.adresse)
                            this.formulaireMonetico.telephoneClient = this.ascii_to_hex(this.infoUser.telephone)
                            this.formulaireMonetico.codePostalClient = this.ascii_to_hex(element.code_postal.toString())
                            this.formulaireMonetico.villeClient = this.ascii_to_hex(element.ville)
                            this.formulaireMonetico.prenomClient = this.ascii_to_hex(removeDiacritics(element.prenom.replace(/\s*$/,'').replace(/\s/,'-')))
                            this.formulaireMonetico.nomClient = this.ascii_to_hex(removeDiacritics(element.nom.replace(/\s*$/,'').replace(/\s/,'-')))
                        }
                        if(element.type_adresse == 2 ){
                            this.InfoUserAdresseLivr = element
                        }
                    })
                    this.getContexteCommande()
                })
        },
        ascii_to_hex:function (str){
            var arr1 = [];
            for (var n = 0, l = str.length; n < l; n ++)
            {
                var hex = Number(str.charCodeAt(n)).toString(16);
                arr1.push(hex);
            }
            return arr1.join('');
        }
    },
    created() {

        const client = algoliasearch('IXJF8AJTH7', '27829f3918903605b2f7665097a13737');
        const index = client.initIndex('dev_EJ');

        this.typeMonte = (this.$route.params.decale).toString() === '0' ? 'par4' : 'decale'
        this.typeMonte === 'decale' ? this.monteDecaJante = true : this.monteDecaJante = false
        this.typeMonte === 'decale' ? this.monteDecaPneu = true : this.monteDecaPneu = false
        this.SelectedJanteAv = this.getOnglet('jantesAv')
        this.SelectedJanteArr = this.getOnglet('jantesArr')
        this.SelectedPneuAv = this.getOnglet('pneusAv') ? this.getOnglet('pneusAv') : null
        this.SelectedPneuArr = this.getOnglet('pneusArr') ? this.getOnglet('pneusArr') : null
        this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
        this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
        this.isSelected = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
        this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0



        if(process.env.VUE_APP_SITE_ID === '1'){
            this.nextStepRouteSuccess = "https://www.espace-jantes.com/etape6-mE62-9EJ-iAs-gQ-Bm98/" + this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale
            this.nextStepRouteError = "https://www.espace-jantes.com/etape6_ko/" + this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale

            // this.nextStepRouteSuccess = "https://dev-espacejantes.netlify.app/etape6-mE62-9EJ-iAs-gQ-Bm98/" + this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale
            // this.nextStepRouteError = "https://dev-espacejantes.netlify.app/etape6_ko/" + this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale

            // this.nextStepRouteSuccess = "http://localhost:8080/etape6-mE62-9EJ-iAs-gQ-Bm98/" + this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale
            // this.nextStepRouteError = "http://localhost:8080/etape6_ko/" + this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale

        }
        else if (process.env.VUE_APP_SITE_ID === '2'){
            this.nextStepRouteSuccess = "https://www.distri-jantes.com/etape6-mE62-9EJ-iAs-gQ-Bm98/" + this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale
            this.nextStepRouteError = "https://www.distri-jantes.com/etape6_ko/" + this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale

            // this.nextStepRouteSuccess = "https://distri-jantes-dev.netlify.app/etape6-mE62-9EJ-iAs-gQ-Bm98/" + this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale
            // this.nextStepRouteError = "https://distri-jantes-dev.netlify.app/etape6_ko/" + this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale

            // this.nextStepRouteSuccess = "http://localhost:8080/etape6-mE62-9EJ-iAs-gQ-Bm98/" + this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale
            // this.nextStepRouteError = "http://localhost:8080/etape6_ko/" + this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale
        }

        index.getObjects([this.SelectedJanteAv.objectID, this.SelectedJanteArr.objectID])
            .then(({ results }) => {
                if (((results[0].marque).toString() === (this.SelectedJanteAv.marque).toString()) && ((results[0].modele).toString() === (this.SelectedJanteAv.modele).toString()) && ((results[0].offset).toString() === (this.SelectedJanteAv.offset).toString()) && ((results[0].objectID).toString() === (this.SelectedJanteAv.objectID).toString())) {
                } else {
                    this.$store.commit('infoJantes/setJante', {Av: results[0]})
                }
                if (((results[1].marque).toString() === (this.SelectedJanteArr.marque).toString()) && ((results[1].modele).toString() === (this.SelectedJanteArr.modele).toString()) && ((results[1].offset).toString() === (this.SelectedJanteArr.offset).toString()) && ((results[1].objectID).toString() === (this.SelectedJanteArr.objectID).toString())){
                }else{
                    this.$store.commit('infoJantes/setJante', {Arr:results[1]})
                }
            })
        var hexFinal = "" + KEY.substr(38,2) + "00"
        hexFinal.charCodeAt(0)
        if(this.typeMonte != 'par4' && this.monteDecaJante === true){
            this.montant = this.getRoundPriceMonteDecale(this.SelectedJanteAv.public_price,this.SelectedJanteArr.public_price_rear, this.SelectedPneuAv ? this.SelectedPneuAv.prixNet : null, this.SelectedPneuArr ? this.SelectedPneuArr.prixNetRear : null, 'TOTAL')
            this.montantPaypal = this.getRoundPriceMonteDecale(this.SelectedJanteAv.public_price,this.SelectedJanteArr.public_price_rear, this.SelectedPneuAv ? this.SelectedPneuAv.prixNet : null, this.SelectedPneuArr ? this.SelectedPneuArr.prixNetRear : null, 'SOUS TOTAL DECALE')
        }else{
            this.montant = this.getRoundPrice(this.SelectedJanteAv.public_price, this.SelectedPneuAv?.prixNet, 'TOTAL')
            this.montantPaypal = this.getRoundPrice(this.SelectedJanteAv.public_price, this.SelectedPneuAv?.prixNet, 'SOUS TOTAL')
        }
        this.montant += DEVISE
        var ts = Date.now();
        var uniqueTS = ts.toString().substr(-6)
        Vue.axios.get('https://api.espace-jantes.com/getRefToMonetico')
            .then((response) => {

                this.ref = (this.VUE_APP_SITE_ID === '1' ? 'EJ-' : 'DJ-') + response.data.numeroToReturn

                this.setOnglet(this.ref,'reference')
                // this.$store.commit('refChecking/setRef', this.ref)
            })
            .catch(error => console.log(error))

        var today = new Date();
        var day = today.getDate() + "";
        var month = (today.getMonth() + 1) + "";
        var year = today.getFullYear() + "";
        var hour = today.getHours() + "";
        var minutes = today.getMinutes() + "";
        var seconds = today.getSeconds() + "";
        day = checkZero(day);
        month = checkZero(month);
        year = checkZero(year);
        hour = checkZero(hour);
        minutes = checkZero(minutes);
        seconds = checkZero(seconds);
        this.datePayement = day + "/" + month + "/" + year + ":" + hour + ":" + minutes + ":" + seconds
        function checkZero(data){
            if(data.length == 1){
                data = "0" + data;
            }
            return data;
        }
        Vue.axios.get('https://api.espace-jantes.com/getOneUser', {
            params: { id_user: this.getUserId },
        })
            .then((response) => {
                this.infoUser = response.data[0]
                this.getAdresseUser(this.getUserId)
            })
            .catch(error => console.log(error))
    },
    mounted() {
        this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        if( process.env.NODE_ENV.trim() !== "development") {
            window.dataLayer.push({
                'event': 'Pageview',
                'pagePath': '/etape5',
                'pageTitle': 'Etape5'
            });
        }
        if(this.SelectedJanteAv && JSON.stringify(this.SelectedJanteAv) !== '""') {
            this.formatedJanteAv.id = this.SelectedJanteAv.id ? this.SelectedJanteAv.id : 123
            this.formatedJanteAv.quantite = 2
            this.formatedJanteAv.reference = this.SelectedJanteAv.reference ? this.SelectedJanteAv.reference : ""
            this.formatedJanteAv.marque = this.SelectedJanteAv.marque
            this.formatedJanteAv.modele = this.SelectedJanteAv.modele
            this.formatedJanteAv.public_price = this.getRoundPrice(this.SelectedJanteAv.public_price, null, "2jantes")
            if (this.monteDecaJante) {
                this.formatedJanteAv.largeur = this.SelectedJanteAv.largeurMinAv
                this.formatedJanteAv.offset = this.SelectedJanteAv.offsetFront
            } else {
                this.formatedJanteAv.largeur = this.SelectedJanteAv.largeur
                this.formatedJanteAv.offset = this.SelectedJanteAv.offset
            }
            // this.SelectedJanteAv.largeurMinAv ? this.formatedJanteAv.largeur = this.SelectedJanteAv.largeurMinAv : this.formatedJanteAv.largeur = this.SelectedJanteAv.largeur
            this.formatedJanteAv.couleur = this.SelectedJanteAv.couleur
            this.formatedJanteAv.diametre = this.SelectedJanteAv.diametre
            this.formatedJanteAv.alesage = this.SelectedJanteAv.alesage
            this.formatedJanteAv.entraxe = this.SelectedJanteAv.entraxe
            this.formatedJanteAv.type = 1
            this.formatedJanteAv.flux = this.SelectedJanteAv.flux
            this.formatedJanteAv.buying_price = this.getRoundPrice(this.SelectedJanteAv.buying_price ? this.SelectedJanteAv.buying_price : 0, null, "2jantes")
            if (this.SelectedJanteArr) {
                this.formatedJanteArr.id = this.SelectedJanteArr.id ? this.SelectedJanteArr.id : 123
                this.formatedJanteArr.quantite = 2
                this.formatedJanteArr.reference = this.SelectedJanteArr.reference ? this.SelectedJanteArr.reference : ""
                this.formatedJanteArr.marque = this.SelectedJanteArr.marque
                this.formatedJanteArr.modele = this.SelectedJanteArr.modele
                if (this.monteDecaJante) {
                    this.formatedJanteArr.largeur = this.SelectedJanteArr.largeurMaxArr
                    this.formatedJanteArr.public_price = this.getRoundPriceMonteDecale(null, this.SelectedJanteArr.public_price_rear, null, null, '2jantesArr')
                    this.formatedJanteArr.offset = this.SelectedJanteArr.offsetRear
                } else {
                    this.formatedJanteArr.largeur = this.SelectedJanteArr.largeur
                    this.formatedJanteArr.public_price = this.getRoundPrice(this.SelectedJanteArr.public_price, null, '2jantes')
                    this.formatedJanteArr.offset = this.SelectedJanteArr.offset
                }
                // this.SelectedJanteArr.largeurMaxArr ? this.formatedJanteArr.largeur = this.SelectedJanteArr.largeurMaxArr : this.formatedJanteArr.largeur = this.SelectedJanteArr.largeur
                this.formatedJanteArr.couleur = this.SelectedJanteArr.couleur

                this.formatedJanteArr.diametre = this.SelectedJanteArr.diametre
                this.formatedJanteArr.alesage = this.SelectedJanteArr.alesage
                this.formatedJanteArr.entraxe = this.SelectedJanteArr.entraxe
                this.formatedJanteArr.type = 1
                this.formatedJanteArr.flux = this.SelectedJanteArr.flux
                this.formatedJanteArr.buying_price = this.getRoundPrice(this.SelectedJanteArr.buying_price ? this.SelectedJanteArr.buying_price : 0, null, '2jantes')
            }
            if (this.SelectedPneuAv) {
                this.formatedPneuAv.id = this.SelectedPneuAv.id ? this.SelectedPneuAv.id : 123
                this.formatedPneuAv.quantite = 2
                this.formatedPneuAv.reference = this.SelectedPneuAv.reference ? this.SelectedPneuAv.reference : ""
                this.formatedPneuAv.marque = this.SelectedPneuAv.marque
                this.formatedPneuAv.modele = this.SelectedPneuAv.modele
                this.formatedPneuAv.prixNet = this.getRoundPrice(null, this.SelectedPneuAv.prixNet, '2pneus')
                this.formatedPneuAv.width = this.SelectedPneuAv.width
                this.formatedPneuAv.HBV = this.SelectedPneuAv.HBV
                this.formatedPneuAv.diametre = this.SelectedPneuAv.diametre
                this.formatedPneuAv.runFlat = this.SelectedPneuAv.runFlat
                this.formatedPneuAv.indiceDeCharge = this.SelectedPneuAv.indiceDeCharge
                this.formatedPneuAv.indiceDeVitesse = this.SelectedPneuAv.indiceDeVitesse
                this.formatedPneuAv.specificite = this.SelectedPneuAv.specificite
                this.formatedPneuAv.saison = this.SelectedPneuAv.ete === 1 ? "ete" : this.SelectedPneuAv.hiver === 1 ? "hiver" : this.SelectedPneuAv.allsaison ? "4 saisons" : null
                this.formatedPneuAv.type = 2
                this.formatedPneuAv.flux = null
                this.formatedPneuAv.buying_price = this.getRoundPrice(null, this.SelectedPneuAv.prixBrut ? this.SelectedPneuAv.prixBrut : 0, '2pneus')
            }
            if (this.SelectedPneuArr) {
                this.formatedPneuArr.id = this.SelectedPneuArr.id ? this.SelectedPneuArr.id : 123
                this.formatedPneuArr.quantite = 2
                this.formatedPneuArr.reference = this.SelectedPneuArr.reference ? this.SelectedPneuArr.reference : ""
                this.formatedPneuArr.marque = this.SelectedPneuArr.marque
                this.formatedPneuArr.modele = this.SelectedPneuArr.modele
                if (this.monteDecaPneu) {
                    this.formatedPneuArr.width = this.SelectedPneuArr.widthRear ? this.SelectedPneuArr.widthRear : this.SelectedPneuArr.width
                    this.formatedPneuArr.HBV = this.SelectedPneuArr.HBVREAR ? this.SelectedPneuArr.HBVREAR : this.SelectedPneuArr.HBV
                    this.formatedPneuArr.prixNet = this.getRoundPriceMonteDecale(null,null,null, this.SelectedPneuArr.prixNetRear, '2pneusArr')
                    this.formatedPneuArr.indiceDeCharge = this.SelectedPneuArr.indiceDeChargeRear
                } else {
                    this.formatedPneuArr.width = this.SelectedPneuArr.width
                    this.formatedPneuArr.HBV = this.SelectedPneuArr.HBV
                    this.formatedPneuArr.prixNet = this.getRoundPrice(null, this.SelectedPneuArr.prixNet, '2pneus')
                    this.formatedPneuArr.indiceDeCharge = this.SelectedPneuArr.indiceDeCharge
                }
                // this.SelectedPneuArr.widthRear ? this.formatedPneuArr.width = this.SelectedPneuArr.widthRear : this.formatedPneuArr.width = this.SelectedPneuArr.width
                // this.SelectedPneuArr.HBVREAR ? this.formatedPneuArr.HBV = this.SelectedPneuArr.HBVREAR : this.formatedPneuArr.HBV = this.SelectedPneuArr.HBV
                this.formatedPneuArr.buying_price = this.SelectedPneuArr.prixBrut
                this.formatedPneuArr.diametre = this.SelectedPneuArr.diametre
                this.formatedPneuArr.runFlat = this.SelectedPneuArr.runFlat
                this.formatedPneuArr.indiceDeVitesse = this.SelectedPneuArr.indiceDeVitesse
                this.formatedPneuArr.specificite = this.SelectedPneuArr.specificite
                this.formatedPneuArr.saison = this.SelectedPneuArr.ete === 1 ? "ete" : this.SelectedPneuArr.hiver === 1 ? "hiver" : this.SelectedPneuArr.allsaison ? "4 saisons" : null
                this.formatedPneuArr.type = 2
                this.formatedPneuArr.flux = null
                this.formatedPneuArr.buying_price = this.getRoundPrice(null, this.SelectedPneuArr.prixBrut ? this.SelectedPneuArr.prixBrut : 0, '2pneus')
            }
            if (this.SelectedJanteAv && this.SelectedPneuAv && this.SelectedAccessoires.length) {
                this.infoCommande = {
                    // "montant": this.monteDecaJante && this.typeMonte != "par4" ? this.getRoundPriceMonteDecale(this.SelectedJanteAv.public_price, this.SelectedJanteArr.public_price_rear, this.SelectedPneuAv.prixNet, this.SelectedPneuArr.prixNetRear, 'SOUS TOTAL DECALE') : this.getRoundPrice(this.SelectedJanteAv.public_price, this.SelectedPneuAv.prixNet, 'SOUS TOTAL'),
                    "montant": this.monteDecaJante ? this.getRoundPriceMonteDecale(this.SelectedJanteAv.public_price, this.SelectedJanteArr.public_price_rear, this.SelectedPneuAv.prixNet, this.SelectedPneuArr.prixNetRear, 'SOUS TOTAL DECALE') : this.getRoundPrice(this.SelectedJanteAv.public_price, this.SelectedPneuAv.prixNet, 'SOUS TOTAL'),
                    "user_id": this.getUserId,
                    "type_paiement": this.paymentCommande,
                    "prix_expedition": '',
                    "note_commande": this.notesCommande,
                    "adresse_facturation": this.AddrFact,
                    "adresse_livraison": this.AddrLivr,
                    "produits": {
                        "jantes": [this.formatedJanteAv, this.formatedJanteArr],
                        "pneus": [this.formatedPneuAv, this.formatedPneuArr],
                        "accessoires": this.SelectedAccessoires
                    },
                    "valves": this.infoValves ? this.infoValves : {'hadValves': '0', 'circulation': 'Mars/2021'},
                    "vehicule": this.Vehicule
                }
            }
            else if (this.SelectedJanteAv && this.SelectedPneuAv) {
                this.infoCommande = {
                    // "montant": this.monteDecaJante && this.typeMonte != "par4" ? this.getRoundPriceMonteDecale(this.SelectedJanteAv.public_price, this.SelectedJanteArr.public_price_rear, this.SelectedPneuAv.prixNet, this.SelectedPneuArr.prixNetRear, 'SOUS TOTAL DECALE') : this.getRoundPrice(this.SelectedJanteAv.public_price, this.SelectedPneuAv.prixNet, 'SOUS TOTAL'),
                    "montant": this.monteDecaJante ? this.getRoundPriceMonteDecale(this.SelectedJanteAv.public_price, this.SelectedJanteArr.public_price_rear, this.SelectedPneuAv.prixNet, this.SelectedPneuArr.prixNetRear, 'SOUS TOTAL DECALE') : this.getRoundPrice(this.SelectedJanteAv.public_price, this.SelectedPneuAv.prixNet, 'SOUS TOTAL'),
                    "user_id": this.getUserId,
                    "type_paiement": this.paymentCommande,
                    "prix_expedition": '',
                    "note_commande": this.notesCommande,
                    "adresse_facturation": this.AddrFact,
                    "adresse_livraison": this.AddrLivr,
                    "produits": {
                        "jantes": [this.formatedJanteAv, this.formatedJanteArr],
                        "pneus": [this.formatedPneuAv, this.formatedPneuArr]
                    },
                    "valves": this.infoValves ? this.infoValves : {'hadValves': '0', 'circulation': 'Mars/2021'},
                    "vehicule": this.Vehicule
                }
            }
            else if (this.SelectedJanteAv && this.SelectedAccessoires) {
                this.infoCommande = {
                    // "montant": this.monteDecaJante && this.typeMonte != "par4" ? this.getRoundPriceMonteDecale(this.SelectedJanteAv.public_price, this.SelectedJanteArr.public_price_rear, null, null, 'SOUS TOTAL DECALE') : this.getRoundPrice(this.SelectedJanteAv.public_price, null, 'SOUS TOTAL'),
                    "montant": this.monteDecaJante ? this.getRoundPriceMonteDecale(this.SelectedJanteAv.public_price, this.SelectedJanteArr.public_price_rear, null, null, 'SOUS TOTAL DECALE') : this.getRoundPrice(this.SelectedJanteAv.public_price, null, 'SOUS TOTAL'),
                    "user_id": this.getUserId,
                    "type_paiement": this.paymentCommande,
                    "prix_expedition": '',
                    "note_commande": this.notesCommande,
                    "adresse_facturation": this.AddrFact,
                    "adresse_livraison": this.AddrLivr,
                    "produits": {
                        "jantes": [this.formatedJanteAv, this.formatedJanteArr],
                        "accessoires": this.SelectedAccessoires
                    },
                    "valves": this.infoValves ? this.infoValves : {'hadValves': '0', 'circulation': 'Mars/2021'},
                    "vehicule": this.Vehicule
                }
            }
            else if (this.SelectedJanteAv) {
                this.infoCommande = {
                    // "montant": this.monteDecaJante && this.typeMonte != "par4" ? this.getRoundPriceMonteDecale(this.SelectedJanteAv.public_price, this.SelectedJanteArr.public_price_rear, null, null, 'SOUS TOTAL DECALE') : this.getRoundPrice(this.SelectedJanteAv.public_price, null, 'SOUS TOTAL'),
                    "montant": this.monteDecaJante ? this.getRoundPriceMonteDecale(this.SelectedJanteAv.public_price, this.SelectedJanteArr.public_price_rear, null, null, 'SOUS TOTAL DECALE') : this.getRoundPrice(this.SelectedJanteAv.public_price, null, 'SOUS TOTAL'),
                    "user_id": this.getUserId,
                    "type_paiement": this.paymentCommande,
                    "prix_expedition": '',
                    "note_commande": this.notesCommande,
                    "adresse_facturation": this.AddrFact,
                    "adresse_livraison": this.AddrLivr,
                    "produits": {"jantes": [this.formatedJanteAv, this.formatedJanteArr]},
                    "valves": this.infoValves ? this.infoValves : {'hadValves': '0', 'circulation': 'Mars/2021'},

                    "vehicule": this.Vehicule
                }
            }
        }

        if((JSON.stringify(this.SelectedJanteAv) === JSON.stringify(this.SelectedJanteArr)) && this.SelectedJanteAv ) {
            if(this.monteDecaJante) {
                if (JSON.stringify(this.SelectedJanteAv.largeurMinAv) === JSON.stringify(this.SelectedJanteAv.largeurMaxArr)) {

                    this.itemsPaypal = [
                        {
                            "name": this.SelectedJanteAv.marque + ' ' + this.SelectedJanteAv.modele + ' ' + this.SelectedJanteAv.couleur + ' ' +  this.formatedJanteAv.largeur + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.formatedJanteAv.offset,
                            "description":this.formatedJanteAv.largeur + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.formatedJanteAv.offset,
                            "unit_amount": {
                                "currency_code": "EUR",
                                // "value": this.getRoundPriceTVA(this.SelectedJanteAv.public_price, "witout TVA")
                                "value": this.getRoundPrice(this.SelectedJanteAv.public_price, null,"1jante")
                            },
                            // "tax": {
                            //     "currency_code": "EUR",
                            //     "value": this.getRoundPriceTVA(this.SelectedJanteAv.public_price, "only TVA")
                            // },
                            "quantity": "4",
                        }
                    ]

                }
                else if (JSON.stringify(this.SelectedJanteAv.largeurMinAv) !== JSON.stringify(this.SelectedJanteAv.largeurMaxArr)) {
                    this.itemsPaypal = [
                        {
                            "name": this.SelectedJanteAv.marque + ' ' + this.SelectedJanteAv.modele + ' ' + this.SelectedJanteAv.couleur + ' ' +  this.formatedJanteAv.largeur + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.formatedJanteAv.offset,
                            "description": this.formatedJanteAv.largeur + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.formatedJanteAv.offset,
                            "unit_amount": {
                                "currency_code": "EUR",
                                // "value": this.getRoundPriceTVA(this.SelectedJanteAv.public_price, "witout TVA")
                                "value": this.getRoundPrice(this.SelectedJanteAv.public_price, null,"1jante")
                            },
                            // "tax": {
                            //     "currency_code": "EUR",
                            //     "value": this.getRoundPriceTVA(this.SelectedJanteAv.public_price, "only TVA")
                            // },
                            "quantity": "2",
                        },
                        {
                            "name": this.SelectedJanteArr.marque + ' ' + this.SelectedJanteArr.modele + ' ' + this.SelectedJanteArr.couleur + ' ' +  this.formatedJanteArr.largeur + 'X' + this.SelectedJanteArr.diametre + ' - ' + this.SelectedJanteArr.entraxe + ' - ET ' + this.formatedJanteArr.offset,
                            "description": this.formatedJanteArr.largeur + 'X' + this.SelectedJanteArr.diametre + ' - ' + this.SelectedJanteArr.entraxe + ' - ET ' + this.formatedJanteArr.offset,
                            "unit_amount": {
                                "currency_code": "EUR",
                                // "value": this.getRoundPriceTVA(this.SelectedJanteArr.public_price_rear, 'witout TVA')
                                "value": this.getRoundPrice(this.SelectedJanteArr.public_price_rear, null,"1jante")
                            },
                            // "tax": {
                            //     "currency_code": "EUR",
                            //     "value": this.getRoundPriceTVA(this.SelectedJanteArr.public_price_rear, "only TVA")
                            // },
                            "quantity": "2",
                        }
                    ]
                }
            }
            else {
                this.itemsPaypal = [
                    {
                        "name": this.SelectedJanteAv.marque + ' ' + this.SelectedJanteAv.modele + ' ' + this.SelectedJanteAv.couleur + ' ' +  this.formatedJanteAv.largeur + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.formatedJanteAv.offset,
                        "description": this.formatedJanteAv.largeur + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.formatedJanteAv.offset,
                        "unit_amount": {
                            "currency_code": "EUR",
                            // "value": this.getRoundPriceTVA(this.SelectedJanteAv.public_price, "witout TVA")
                            "value": this.getRoundPrice(this.SelectedJanteAv.public_price, null,"1jante")
                        },
                        // "tax": {
                        //     "currency_code": "EUR",
                        //     "value": this.getRoundPriceTVA(this.SelectedJanteAv.public_price, "only TVA")
                        //     // "value": this.getRoundPriceTVA(this.montantPaypal - 9.90, "unit only TVA")
                        //
                        // },
                        "quantity": "4",
                    }
                ]
            }
        }
        else if(this.SelectedJanteAv && this.SelectedJanteArr) {
            this.itemsPaypal = [
                {
                    "name": this.SelectedJanteAv.marque + ' ' + this.SelectedJanteAv.modele + ' ' + this.SelectedJanteAv.couleur + ' ' +  this.formatedJanteAv.largeur + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.formatedJanteAv.offset,
                    "description": this.formatedJanteAv.largeur + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.formatedJanteAv.offset,
                    "unit_amount": {
                        "currency_code": "EUR",
                        // "value": this.getRoundPriceTVA(this.SelectedJanteAv.public_price, "witout TVA")
                        "value": this.getRoundPrice(this.SelectedJanteAv.public_price, null,"1jante")
                    },
                    // "tax": {
                    //     "currency_code": "EUR",
                    //     "value": this.getRoundPriceTVA(this.SelectedJanteAv.public_price, "only TVA")
                    // },
                    "quantity": "2",
                },
                {
                    "name": this.SelectedJanteArr.marque + ' ' + this.SelectedJanteArr.modele + ' ' + this.SelectedJanteArr.couleur + ' ' +  this.formatedJanteArr.largeur + 'X' + this.SelectedJanteArr.diametre + ' - ' + this.SelectedJanteArr.entraxe + ' - ET ' + this.formatedJanteArr.offset,
                    "description": this.formatedJanteArr.largeur + 'X' + this.SelectedJanteArr.diametre + ' - ' + this.SelectedJanteArr.entraxe + ' - ET ' + this.formatedJanteArr.offset,
                    "unit_amount": {
                        "currency_code": "EUR",
                        // "value": this.getRoundPriceTVA(this.SelectedJanteArr.public_price_rear, 'witout TVA')
                        "value": this.getRoundPrice(this.SelectedJanteArr.public_price_rear, null,"1jante")
                    },
                    // "tax": {
                    //     "currency_code": "EUR",
                    //     "value": this.getRoundPriceTVA(this.SelectedJanteArr.public_price_rear, "only TVA")
                    // },
                    "quantity": "2",
                }
            ]
        }


        if((JSON.stringify(this.SelectedPneuAv) === JSON.stringify(this.SelectedPneuArr)) && this.SelectedPneuAv ) { //Si pneu avant === pneu arriere
            if(this.monteDecaPneu === true) { //Si monte décalée
                if (this.SelectedPneuAv.HBV === this.SelectedPneuAv.HBVREAR) { //Si pneu avant  HBV === pneu arriere HBVREAR => pneu X4

                    this.itemsPaypal.push(
                        {
                            "name": this.SelectedPneuAv.marque + ' ' + this.SelectedPneuAv.modele + ' ' +  this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' + this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' ' + this.SelectedPneuAv?.indiceDeVitesse + ' ' + (this.SelectedPneuAv.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuAv.hiver === 1 ? 'Hiver' : this.SelectedPneuAv.ete === 1 ? 'Eté' : ' 4 saisons'),
                            "description": this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' + this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' ' + this.SelectedPneuAv?.indiceDeVitesse + ' ' + (this.SelectedPneuAv.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuAv.hiver === 1 ? 'Hiver' : this.SelectedPneuAv.ete === 1 ? 'Eté' : ' 4 saisons'),
                            "unit_amount": {
                                "currency_code": "EUR",
                                // "value": this.getRoundPriceTVA(this.SelectedPneuAv.prixNet, 'witout TVA', true)
                                "value": this.getRoundPrice(null, this.SelectedPneuAv.prixNet, "1pneu")
                            },
                            // "tax": {
                            //     "currency_code": "EUR",
                            //     "value": this.getRoundPriceTVA(this.SelectedPneuAv.prixNet, 'only TVA', true)
                            // },
                            "quantity": "4",
                        }
                    )
                }
                else if (this.SelectedPneuAv.HBV !== this.SelectedPneuAv.HBVREAR) { //Si pneu avant  HBV !== pneu arriere HBVREAR => pneuAvant X2 && pneuArriere X2

                    this.itemsPaypal.push(
                        {
                            "name": this.SelectedPneuAv.marque + ' ' + this.SelectedPneuAv.modele + ' ' +  this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' + this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' ' + this.SelectedPneuAv?.indiceDeVitesse + ' ' + (this.SelectedPneuAv.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuAv.hiver === 1 ? 'Hiver' : this.SelectedPneuAv.ete === 1 ? 'Eté' : ' 4 saisons'),
                            "description": this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' + this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' ' + this.SelectedPneuAv?.indiceDeVitesse + ' ' + (this.SelectedPneuAv.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuAv.hiver === 1 ? 'Hiver' : this.SelectedPneuAv.ete === 1 ? 'Eté' : ' 4 saisons'),
                            "unit_amount": {
                                "currency_code": "EUR",
                                // "value": this.getRoundPriceTVA(this.SelectedPneuAv.prixNet, 'witout TVA', true)
                                "value": this.getRoundPrice(null, this.SelectedPneuAv.prixNet, "1pneu")
                            },
                            // "tax": {
                            //     "currency_code": "EUR",
                            //     "value": this.getRoundPriceTVA(this.SelectedPneuAv.prixNet, 'only TVA', true)
                            // },
                            "quantity": "2",
                        },
                        {
                            "name": this.SelectedPneuArr.marque + ' ' + this.SelectedPneuArr.modele + ' ' +  this.SelectedPneuArr.HBVREAR + '/' + this.SelectedPneuArr.widthRear + 'R' + this.SelectedPneuArr.diametre + ' ' + this.SelectedPneuArr.indiceDeChargeRear + ' ' + this.SelectedPneuArr.indiceDeVitesse + ' ' + (this.SelectedPneuArr.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuArr.hiver === 1 ? 'Hiver' : this.SelectedPneuArr.ete === 1 ? 'Eté' : ' 4 saisons'),
                            "description": this.SelectedPneuArr.HBVREAR + '/' + this.SelectedPneuArr.widthRear + 'R' + this.SelectedPneuArr.diametre + ' ' + this.SelectedPneuArr.indiceDeChargeRear + ' ' + this.SelectedPneuArr.indiceDeVitesse + ' ' + (this.SelectedPneuArr.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuArr.hiver === 1 ? 'Hiver' : this.SelectedPneuArr.ete === 1 ? 'Eté' : ' 4 saisons'),
                            "unit_amount": {
                                "currency_code": "EUR",
                                // "value": this.getRoundPriceTVA(this.SelectedPneuArr.prixNetRear, 'witout TVA', true)
                                "value": this.getRoundPrice(null, this.SelectedPneuArr.prixNetRear,"1pneu")
                            },
                            // "tax": {
                            //     "currency_code": "EUR",
                            //     "value": this.getRoundPriceTVA(this.SelectedPneuArr.prixNetRear, 'only TVA', true)
                            // },
                            "quantity": "2",
                        }
                    )
                }
            }
            else{ //Si monte par 4
                this.itemsPaypal.push(
                    {
                        "name": this.SelectedPneuAv.marque + ' ' + this.SelectedPneuAv.modele + ' ' +  this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' + this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' ' + this.SelectedPneuAv?.indiceDeVitesse + ' ' + (this.SelectedPneuAv.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuAv.hiver === 1 ? 'Hiver' : this.SelectedPneuAv.ete === 1 ? 'Eté' : ' 4 saisons'),
                        "description": this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' + this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' ' + this.SelectedPneuAv?.indiceDeVitesse + ' ' + (this.SelectedPneuAv.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuAv.hiver === 1 ? 'Hiver' : this.SelectedPneuAv.ete === 1 ? 'Eté' : ' 4 saisons'),
                        "unit_amount": {
                            "currency_code": "EUR",
                            // "value": this.getRoundPriceTVA(this.SelectedPneuAv.prixNet, 'witout TVA', true)
                            "value": this.getRoundPrice(null, this.SelectedPneuAv.prixNet, "1pneu")
                        },
                        // "tax": {
                        //     "currency_code": "EUR",
                        //     "value": this.getRoundPriceTVA(this.SelectedPneuAv.prixNet, 'only TVA', true)
                        // },
                        "quantity": "4",
                    }
                )
            }
        }
        else if(this.SelectedPneuAv && this.SelectedPneuArr) { //Sinon si pneu avant !== pneu arriere
            this.itemsPaypal.push(
                {
                    "name": this.SelectedPneuAv.marque + ' ' + this.SelectedPneuAv.modele + ' ' +  this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' + this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' ' + this.SelectedPneuAv?.indiceDeVitesse + ' ' + (this.SelectedPneuAv.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuAv.hiver === 1 ? 'Hiver' : this.SelectedPneuAv.ete === 1 ? 'Eté' : ' 4 saisons'),
                    "description": this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' + this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' ' + this.SelectedPneuAv?.indiceDeVitesse + ' ' + (this.SelectedPneuAv.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuAv.hiver === 1 ? 'Hiver' : this.SelectedPneuAv.ete === 1 ? 'Eté' : ' 4 saisons'),
                    "unit_amount": {
                        "currency_code": "EUR",
                        // "value": this.getRoundPriceTVA(this.SelectedPneuAv.prixNet, 'witout TVA', true)
                        "value": this.getRoundPrice(null, this.SelectedPneuAv.prixNet, "1pneu")
                    },
                    // "tax": {
                    //     "currency_code": "EUR",
                    //     "value": this.getRoundPriceTVA(this.SelectedPneuAv.prixNet, 'only TVA', true)
                    // },
                    "quantity": "2",
                },
                {
                    "name": this.SelectedPneuArr.marque + ' ' + this.SelectedPneuArr.modele + ' ' +  this.SelectedPneuArr.HBVREAR + '/' + this.SelectedPneuArr.widthRear + 'R' + this.SelectedPneuArr.diametre + ' ' + this.SelectedPneuArr.indiceDeChargeRear + ' ' + this.SelectedPneuArr.indiceDeVitesse + ' ' + (this.SelectedPneuArr.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuArr.hiver === 1 ? 'Hiver' : this.SelectedPneuArr.ete === 1 ? 'Eté' : ' 4 saisons'),
                    "description": this.SelectedPneuArr.HBVREAR + '/' + this.SelectedPneuArr.widthRear + 'R' + this.SelectedPneuArr.diametre + ' ' + this.SelectedPneuArr.indiceDeChargeRear + ' ' + this.SelectedPneuArr.indiceDeVitesse + ' ' + (this.SelectedPneuArr.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuArr.hiver === 1 ? 'Hiver' : this.SelectedPneuArr.ete === 1 ? 'Eté' : ' 4 saisons'),
                    "unit_amount": {
                        "currency_code": "EUR",
                        // "value": this.getRoundPriceTVA(this.SelectedPneuArr.prixNetRear, 'witout TVA', true)
                        "value": this.getRoundPrice(null, this.SelectedPneuArr.prixNetRear,"1pneu")
                    },
                    // "tax": {
                    //     "currency_code": "EUR",
                    //     "value": this.getRoundPriceTVA(this.SelectedPneuArr.prixNetRear, 'only TVA', true)
                    // },
                    "quantity": "2",
                }
            )
        }

        if(this.SelectedAccessoires) { //Si accessoires

            Object.values(this.SelectedAccessoires).forEach((accessoire)=> {
                if(accessoire != null){
                    this.itemsPaypal.push(
                        {
                            "name": accessoire.data.name,
                            "description": '',
                            "unit_amount": {
                                "currency_code": "EUR",
                                // "value": parseFloat(accessoire.data.price) - (parseFloat(accessoire.data.price) * 0.2)
                                "value": parseFloat(accessoire.data.price)
                            },
                            // "tax": {
                            //     "currency_code": "EUR",
                            //     "value": parseFloat(accessoire.data.price) * 0.2
                            // },
                            "quantity": "1",
                        }
                    )
                }

            })
        }

        setTimeout(()=> {
            this.infoCommande.logs = {"browserUa":this.$browserDetect.meta.ua}
            this.infoCommande.websiteid = this.VUE_APP_SITE_ID
            console.log('this.infoCommande', this.infoCommande)
            Vue.axios.get('https://api.espace-jantes.com/SavePreCommande', {
                params: {infoCommande: this.infoCommande},
            })
                .then((response) => {
                    // console.log('this.infoCommande', this.infoCommande)
                    this.$store.commit('infoCommande/setInfoCommande', this.infoCommande)
                    // console.log('response', response.data)
                }).catch((err)=>{console.log(err)})
            let cryptedinfoCommande = CryptoJS.AES.encrypt(JSON.stringify(this.infoCommande), process.env.VUE_APP_SECRET_KEY).toString()
            Vue.axios.post('https://api.espace-jantes.com/preOrder', {
                params: {infoCommande: cryptedinfoCommande, websiteid: this.VUE_APP_SITE_ID},
            })
                .then((response) => {
                    console.log('preOrder')
                }).catch((err)=>{console.log(err)})
        }, 3000)
    },
    computed:{
        ...mapState({
            getUserId: state => state.auth.userId,
            AddrFact : state => state.addr_commande.addr_commande_facturation,
            AddrLivr : state => state.addr_commande.addr_commande_livraison,
            notesCommande : state => state.addr_commande.notesCommande,
            infoValves : state => state.infoVehicule.infoValves,
            Vehicule : state => state.infoVehicule.Vehicule,
            isProfessional: state => state.auth.isRevendeur,
            loaderStatus: state => state.loader.isLoader,
            tauxRemise: state => state.auth.tauxRemise,


        })
    }
}
</script>

<style scoped>
</style>
