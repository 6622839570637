<template >
    <v-container fluid class="pa-0">
        <v-col class="py-0">
            <v-row style="position: relative;" >
                <v-col cols="12" sm="12" md="7" lg="7" xl="7" class="pa-0" >
                    <h1 v-if="$vuetify.breakpoint.smAndDown" class="tc_blue font-weight-bold pa-3
                                                    text-md-h4
                                                    text-sm-h5
                                                    text-h5">
                        Spécialiste de la jante en ligne
                    </h1>
                    <v-carousel v-if="$vuetify.breakpoint.smAndDown"
                                height="380"
                                cycle
                                style="background-color: white "
                                hide-delimiters
                                show-arrows-on-hover
                    >
                        <v-carousel-item
                            v-for="(item,i) in caroussel"
                            :key="i"
                            :src="item"
                        ></v-carousel-item>
                    </v-carousel>
                    <v-col offset-lg="1" offset-sm="1" cols="12" sm="8" md="8" lg="8" style="position: relative; z-index: 3"  >

                        <h1 v-if="$vuetify.breakpoint.mdAndUp" class="tc_blue font-weight-bold
                                                    text-xl-h2
                                                    text-lg-h3">
                            Spécialiste <br > de la jante en ligne

                        </h1>

                        <p class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption"
                           style="padding: 10px 0px;line-height: 190%;background-color: rgba(255,255,255,0.7)">Espace Jantes vous propose un large choix de jantes et pneus pour la plupart des marques de véhicules sur le marché actuellement.<span class="tc_turquoise font-weight-bold"> Veuillez sélectionner les données de votre véhicule.</span></p>


                        <v-img src="@/assets/jante_pneus_.png" class='mb-6' style="max-width: 55%" :style="[$vuetify.breakpoint.mdAndUp ? {'max-width' : '55%'} :  {'max-width' : '85%'}]" :class="{'ma-auto ':$vuetify.breakpoint.smAndDown}" alt='Espace-Jantes Jantes Pneu' title='Espace-Jantes Jantes Pneu'/>

                        <ConfigurateurHomeEj style=""/>
                    </v-col>

                </v-col>
                <v-col cols="5" v-if="$vuetify.breakpoint.mdAndUp" class="bc_blue" ></v-col>
                <v-carousel v-if="$vuetify.breakpoint.mdAndUp"
                            hide-delimiters
                            show-arrows-on-hover
                            cycle
                            class="carouselMedia"
                            style="z-index:5"
                >
                    <v-carousel-item
                        class="test"
                        v-for="(item,i) in slider"
                        :key="i"
                        :src="item"
                    ></v-carousel-item>
                </v-carousel>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
    import ConfigurateurHomeEj from "./configurateur/ConfigurateurHomeEj"

    import caroussel_7 from '@/assets/caroussel/caroussel_7.jpg'
    import caroussel_13 from '@/assets/caroussel/caroussel_13.jpg'
    import caroussel_17 from '@/assets/caroussel/caroussel_17.jpg'
    import caroussel_22 from '@/assets/caroussel/caroussel_22.jpg'
    import caroussel_24 from '@/assets/caroussel/caroussel_24.jpg'
    import caroussel_montagne from '@/assets/caroussel/Slider_montagne_mobile-EJ.png'
    import slide2Court from '@/assets/caroussel/slide2_court.png'


    import slider_1 from '@/assets/caroussel/slider1.png'
    import slider_2 from '@/assets/caroussel/slider2.png'
    import slider_3 from '@/assets/caroussel/slider3.png'
    import slider_4 from '@/assets/caroussel/slider4.png'
    import slider_5 from '@/assets/caroussel/slider5.png'
    import slider_6 from '@/assets/caroussel/slider6.png'
    import slider_montagne from '@/assets/caroussel/Slider_montagne-EJ.png'


    export default {
        name: "TopHomepage",

        components:{
            ConfigurateurHomeEj
        },
        data: () => ({
            Logo: require("@/assets/jante_pneus_.png"),
            url: require("@/assets/jante_pneus_.png"),
            slider1: require("@/assets/img_slider1.png"),
            caroussel:[
                caroussel_montagne,
                slide2Court,
                caroussel_7,
                caroussel_13,
                caroussel_17,
                caroussel_22,
                caroussel_24,
            ],
            slider:[
                slider_montagne,
                slider_6,
                slider_1,
                slider_2,
                slider_3,
                slider_4,
                slider_5,

            ]
        }),
    }
</script>

<style scoped>
    .test >>> .v-image__image.v-image__image--cover{
        background-size: contain !important;
    }


    @media (min-width: 960px) and (max-width: 1264px) {
        .carouselMedia {
            position: absolute;
            left: 45%;
            width: 50%;
        }
        .test >>> .v-image.v-responsive.v-carousel__item.theme--dark{
            height: 600px!important
        }
    }
    @media (min-width: 1264px) and (max-width: 1904px) {
        .carouselMedia {
            position: absolute;
            left: 45%;
            width: 50%;
        }
        .test >>> .v-image.v-responsive.v-carousel__item.theme--dark{
            height: 500px!important;
        }
    }
    @media (min-width: 1904px) {
        .carouselMedia {
            position: absolute;
            left: 50%;
            width: 45%;
            height: 700px!important;
        }
        .test >>> .v-image.v-responsive.v-carousel__item.theme--dark{
            height: 700px!important;
        }
    }
</style>





<!--SAVE carousel homepage-->



<!--<v-container fluid class="pa-0">-->
<!--<v-col  cols="12" class="py-0" >-->
<!--    <h1 v-if="$vuetify.breakpoint.smAndDown" class="tc_blue font-weight-bold pl-2 pb-2 mt-6-->
<!--                                                                            text-sm-h6-->
<!--                                                                            text-h5">-->
<!--        Spécialiste <br v-if="$vuetify.breakpoint.mdAndUp"> de la jante en ligne-->
<!--    </h1>-->
<!--    <v-row style="position: relative "  >-->
<!--        <v-carousel v-if="$vuetify.breakpoint.mdAndUp"-->
<!--                    height="650"-->
<!--                    style=" background-color: white;position:absolute; z-index: 0"-->
<!--                    cycle-->
<!--                    hide-delimiters-->
<!--                    show-arrows-on-hover-->
<!--        >-->
<!--            <v-carousel-item-->
<!--                v-for="(item,i) in caroussel"-->
<!--                :key="i"-->
<!--                :src="item"-->
<!--            ></v-carousel-item>-->
<!--        </v-carousel>-->
<!--        <v-carousel v-if="$vuetify.breakpoint.smAndDown"-->
<!--                    height="250"-->
<!--                    cycle-->
<!--                    style=" background-color: white "-->
<!--                    hide-delimiters-->
<!--                    show-arrows-on-hover-->
<!--        >-->
<!--            <v-carousel-item-->

<!--                v-for="(item,i) in caroussel"-->
<!--                :key="i"-->
<!--                :src="item"-->
<!--            ></v-carousel-item>-->
<!--        </v-carousel>-->

<!--        <v-col offset-lg="1" offset-sm="1" cols="12" sm="10" md="7" lg="5" :class="{'pl-6':$vuetify.breakpoint.mdAndUp}" style="z-index: 333; background-color: rgba(255,255,255,0.9)" :style="[$vuetify.breakpoint.mdAndUp ? {'min-height': '650px'} : '']" >-->

<!--            <h1 v-if="$vuetify.breakpoint.mdAndUp" class="tc_blue font-weight-bold-->
<!--                                                                    text-xl-h2-->
<!--                                                                    text-lg-h3-->
<!--                                                                    text-md-h4">-->
<!--                Spécialiste <br v-if="$vuetify.breakpoint.mdAndUp"> de la jante en ligne-->
<!--            </h1>-->

<!--            <p class="font-weight-regular-->
<!--                                text-xl-h5-->
<!--                                text-lg-caption-->
<!--                                text-md-caption" style="padding: 10px 0px;line-height: 190%;">Espace Jantes vous propose un large choix de jantes et pneus pour la plupart des marques de véhicules sur le marché actuellement. Veuillez saisir les données de votre véhicule.</p>-->


<!--            <v-img src="@/assets/jante_pneus_.png" class='mb-6' style="max-width: 55%" :style="[$vuetify.breakpoint.mdAndUp ? {'max-width' : '55%'} :  {'max-width' : '85%'}]" :class="{'ma-auto ':$vuetify.breakpoint.smAndDown}" alt='Espace-Jantes Jantes Pneu' title='Espace-Jantes Jantes Pneu'/>-->


<!--            <ConfigurateurHome style=""/>-->
<!--        </v-col>-->
<!--    </v-row>-->

<!--</v-col>-->
<!--</v-container>-->
