<template>
    <!--    <v-app >-->
    <v-container fluid class="pa-0 bc_grey
                                    text-xl-subtitle-1
                                    text-lg-body-2
                                    text-md-caption
                                    text-sm-caption
                                    text-caption" id="footer" >
        <!--        <v-col class="py-0" v-if="$vuetify.breakpoint.lgAndUp" >-->
        <v-navigation-drawer v-if="$vuetify.breakpoint.lgAndUp" fixed bottom permanent hide-overlay width="100%" height="16%"   class="bc_grey" style="top: auto; bottom: 0" >

            <v-row style="height: 100%">
                <!-- <span style="color: white">{{configurateurJson}}</span> -->
<!--                <v-col lg="2"  class="ml-8 justify-end tc_white text__img pr-0 pt-1">-->
                <v-col  class="ml-8 justify-end tc_white text__img pr-0 pt-1" :class="{'col-3' : VUE_APP_SITE_ID === '1', 'col-2' : VUE_APP_SITE_ID === '2'}">
                    <v-img v-if="VUE_APP_SITE_ID === '1'" style="max-width: 10%;" class="" src="@/assets/phone.svg"/>
                    <v-img v-else-if="VUE_APP_SITE_ID === '2'" style="max-width: 15%;" class="" src="@/assets/phoneDJ.svg"/>
                    <v-col class="ml-3 pa-0">
                        <span v-if="VUE_APP_SITE_ID === '1'" class="mb-0 font-weight-medium " :class="{'display-1': $vuetify.breakpoint.xl, 'title':$vuetify.breakpoint.lg}"><a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a></span>
                        <span v-else-if="VUE_APP_SITE_ID === '2'" class="mb-0 font-weight-medium text-h5" ><a class="no_deco" href="tel:+33974191801">09 74 19 18 01</a></span>
                        <br>
                        <span class="text-caption" >Commandez par téléphone</span>
                        <br>
                        <span class="text-caption">Nous vous accompagnons !</span>
                    </v-col>
                </v-col>
                    <v-col v-if="VUE_APP_SITE_ID === '1'" lg="4" class="pa-0 align-self-center tc_white pt-1">
                        <!--                    <v-col style="padding: 0;display: flex;justify-content: space-between" :class="{'headline': $vuetify.breakpoint.xl, 'subtitle-1':$vuetify.breakpoint.lg}">-->
                        <!--                        <span class="font-weight-bold  align-self-end" :class="{'display-1': $vuetify.breakpoint.xl, 'headline':$vuetify.breakpoint.lg}">461,36 € TTC</span>-->
                        <!--                        <span class="  align-self-end" :class="{'title': $vuetify.breakpoint.xl, 'subtitle-2':$vuetify.breakpoint.lg}">OU</span>-->
                        <!--                        <span class="font-weight-bold  align-self-end" :class="{'headline': $vuetify.breakpoint.xl, 'title':$vuetify.breakpoint.lg}">4x117,88 €*</span>-->
                        <!--                        <v-img style="max-width: 17%;" class="ml-4" src="@/assets/logo/logo_cofidis.png" />-->

                        <!--                    </v-col>-->
                        <v-col class="pa-0 d-flex" v-if="monteType === 'par4'">
                            <v-content v-if="loaderStatus === false && SelectedJanteAv.public_price" class="align-self-end" style="display:inline-block;line-height: 1px" :class="{'display-1': $vuetify.breakpoint.xl, 'headline':$vuetify.breakpoint.lg}">
                                <!--                                <div class="font-weight-bold text-xl-h4 text-lg-h6" style="line-height: 0.8em" >{{getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : null, 'SOUS TOTAL')}} € TTC <span class="font-weight-regular" style="white-space: pre-wrap">     OU</span> </div>-->
                                <span class="font-weight-bold text-xl-h4 text-lg-h6" style="line-height: 0.8em" >{{getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, 'SOUS TOTAL')}}€ <sup class="font-weight-regular"> TTC</sup> <span class="font-weight-regular" style="white-space: pre-wrap">     OU    </span> </span>
                                <!--                                <div class="text-xl-h6 text-lg-h6" style="line-height: 0.5em" > OU </div>-->
                                <span class="font-weight-bold text-xl-h4 text-lg-h6"  >4 x {{getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, 'COFIDIS')}}€ <sup class="font-weight-regular"> TTC</sup></span>
                            </v-content>
                            <v-img v-if="loaderStatus === false && SelectedJanteAv.public_price" style="max-width: 20%;" class="ml-4" src="@/assets/logo/logo-Cofidis-Pay_4xcb.png" />

                        </v-col>

                        <v-col class="px-0 pb-0 d-flex" v-if="monteType === 'decale'">
                            <v-content v-if="loaderStatus === false && SelectedJanteAv.public_price" class="align-self-end " style="display:inline-block;line-height: 1px" :class="{'display-1': $vuetify.breakpoint.xl, 'headline':$vuetify.breakpoint.lg}">
                                <div class="font-weight-bold text-xl-h4 text-lg-h6" style="line-height: 0.8em" >{{getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, SelectedPneuArr ? SelectedPneuArr.prixNetRear : 0, 'SOUS TOTAL DECALE')}} € TTC <span class="font-weight-regular" style="white-space: pre-wrap">     OU</span> </div>
                                <!--                                <div class="text-xl-h6 text-lg-h6" style="line-height: 0.5em" > OU </div>-->
                                <div class="font-weight-bold text-xl-h4 text-lg-h6"  >4 x {{getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, SelectedPneuArr ? SelectedPneuArr.prixNetRear : 0, 'COFIDIS DECALE')}} €* TTC</div>
                            </v-content>
                            <v-img v-if="loaderStatus === false && SelectedJanteAv.public_price" style="max-width: 20%;" class="ml-4" src="@/assets/logo/logo-Cofidis-Pay_4xcb.png" />

                        </v-col>

                        <v-col class="font-weight-medium pa-0" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">Prix pour 4 jantes + accessoires de montage si nécessaire</v-col>
                        <v-col class="font-weight-medium pa-0" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}" >Montage offert : pack complet, prêt à rouler !</v-col>
                    </v-col>
                    <v-col v-if="VUE_APP_SITE_ID === '1'" lg="2" class="align-self-center tc_white text-center pt-1">
                        <v-col style="line-height: initial;" class="pa-0" >Livraison Gratuite</v-col>
                        <v-col style="line-height: initial;" class="pa-0" >Expédition sous 2 à 7 jours</v-col>
                        <RouterLink :to="{name: 'Livraison'}" target='_blank' class="tc_white">
                            <v-col style="line-height: initial;text-decoration: underline;" class="pa-0" >Voir détails</v-col>
                        </RouterLink>
                    </v-col>
                    <v-col v-if="VUE_APP_SITE_ID === '2'" lg="3" class="align-self-center tc_white  pt-1" style="display: flex; align-items: center;">
                        <v-img style="max-width: 15%;" class="mr-4" src="@/assets/delivery.svg" />
                        <v-content>
                        <v-col style="line-height: initial;" class="pa-0" >Livraison Gratuite</v-col>
                        <v-col style="line-height: initial;" class="pa-0" >Expédition sous 2 à 7 jours</v-col>
                        <RouterLink :to="{name: 'Livraison'}" target='_blank' class="tc_white">
                            <v-col style="line-height: initial;text-decoration: underline;" class="pa-0" >Voir détails</v-col>
                        </RouterLink>
                        </v-content>
                    </v-col>
                    <v-col v-if="VUE_APP_SITE_ID === '2'" lg="4" class="pa-0 align-self-center tc_white pt-1">

                            <v-col class="pa-0 d-flex" v-if="monteType === 'par4'">
                                <v-content v-if="loaderStatus === false && SelectedJanteAv.public_price" class="align-self-end" style="line-height: 1px" :class="{'display-1': $vuetify.breakpoint.xl, 'headline':$vuetify.breakpoint.lg}">

                                    <span class="font-weight-bold text-lg-h4" style="line-height: 0.8em; display: flex; align-items: center" >
                                        {{getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, 'SOUS TOTAL')}}
                                        <sup class="font-weight-regular text-subtitle-2">€ TTC</sup>
                                        <span class="font-weight-regular" style="white-space: pre-wrap"> OU</span>
                                        <v-img v-if="loaderStatus === false && SelectedJanteAv.public_price" style="max-width: 20%;" class="" src="@/assets/logo/logo-Cofidis-Pay_4xcb.png" />
                                        <span class="font-weight-bold text-lg-h4" style="display: contents;">
                                            {{getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, 'COFIDIS')}}
                                            <sup class="font-weight-regular text-subtitle-2">€ TTC</sup>
                                        </span>
                                    </span>


                                </v-content>

                            </v-col>

                            <v-col class="px-0 pb-0 d-flex" v-if="monteType === 'decale'">

                                <v-content v-if="loaderStatus === false && SelectedJanteAv.public_price" class="align-self-end " style="line-height: 0.8em; display: flex; align-items: center" :class="{'display-1': $vuetify.breakpoint.xl, 'headline':$vuetify.breakpoint.lg}">
                                    <span class="font-weight-bold text-lg-h4" style="line-height: 0.8em; display: flex; align-items: center" >
                                        {{getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, SelectedPneuArr ? SelectedPneuArr.prixNetRear : 0, 'SOUS TOTAL DECALE')}}
                                        <sup class="font-weight-regular text-subtitle-2">€ TTC</sup>
                                        <span class="font-weight-regular" style="white-space: pre-wrap"> OU</span>
                                        <v-img v-if="loaderStatus === false && SelectedJanteAv.public_price" style="max-width: 20%;" class="ml-4" src="@/assets/logo/logo-Cofidis-Pay_4xcb.png" />
                                        <span class="font-weight-bold text-lg-h4" style="display: contents;" >
                                            {{getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, SelectedPneuArr ? SelectedPneuArr.prixNetRear : 0, 'COFIDIS DECALE')}}
                                            <sup class="font-weight-regular text-subtitle-2">€ TTC</sup>
                                        </span>
                                    </span>

                                </v-content>

                            </v-col>

                            <v-col class="font-weight-medium pa-0 text-caption" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">Prix pour 4 jantes + accessoires de montage si nécessaire</v-col>
                            <v-col class="font-weight-medium pa-0 text-caption" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}" >Montage offert : pack complet, prêt à rouler !</v-col>
                        </v-col>

                <v-col lg="2" style="align-self: center;text-align: center" class="pt-1 px-0 pb-0">

                    <v-content v-if="this.nextStep === 4 && Next === 'true'">
                        <RouterLink  :to="{ name:'Etape'+ this.nextStep, params: { marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}"  style="text-decoration: none">
                            <v-btn rounded large :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" >
                                Suivant
                            </v-btn>
                        </RouterLink>
                        <RouterLink :to="{ name:'Etape'+ this.nextStep, params: { marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}" @click.native="deletePneu()" style="text-decoration: none">
                            <p class=" tc_white underline pointer">Je ne souhaite pas de pneus</p>
                        </RouterLink>
                    </v-content>
                    <RouterLink v-else-if="this.nextStep < 4 && Next === 'true'" :to="{ name:'Etape'+ this.nextStep, params: { marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}"  style="text-decoration: none">
                        <v-btn rounded large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" >
                            Suivant
                        </v-btn>
                    </RouterLink>
                    <RouterLink v-else-if="this.nextStep === 4 && pneuChoisi" :to="{ name:'Etape'+ this.nextStep, params: { marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}"  style="text-decoration: none">
                        <v-btn rounded large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" >
                            Suivant
                        </v-btn>
                    </RouterLink>
                    <RouterLink v-else-if="connected" :to="{ name:'Etape'+ this.nextStep, params: { marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}"  style="text-decoration: none">
                        <v-btn rounded large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" >
                            Suivant
                        </v-btn>
                    </RouterLink>
                    <v-content v-else-if="this.nextStep === 4 && Next !== 'true'">
                        <v-btn  rounded large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" @click="showMsg = true">
                            Suivant
                        </v-btn>
                        <RouterLink :to="{ name:'Etape'+ this.nextStep, params: { marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}" @click.native="deletePneu()" style="text-decoration: none">
                            <p class=" tc_white underline pointer ">Je ne souhaite pas de pneus</p>
                        </RouterLink>
                    </v-content>
                    <RouterLink v-else-if="this.nextStep < 4 && Next === 'false'" :to="{ name:'Etape'+ this.nextStep, params: { marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}"  style="text-decoration: none">
                        <v-btn rounded disabled large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" >
                            Suivant
                        </v-btn>
                    </RouterLink>

                        <v-row v-else class="tc_white">

                            <v-col cols="3" class="pt-0 font-weight-bold">Total</v-col>
                            <v-col  class="pa-0" v-if="this.monteType === 'par4'">
                                <v-col  class="pa-0 font-weight-bold body-1" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}" >{{ getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, 'TOTAL') }} €</v-col>
                                <v-col class="pa-0">dont {{ getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, 'TVA') }} €</v-col>

                                <v-col class="pa-0">de TVA</v-col>
                            </v-col>
                            <v-col v-else class="pa-0">
                                <v-col class="pa-0 font-weight-bold body-1" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}" >{{ getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv ? SelectedPneuAv.prixNet : 0,SelectedPneuArr ? SelectedPneuArr.prixNetRear : 0 , 'TOTAL') }}  €</v-col>
                                <v-col class="pa-0">dont {{ getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv ? SelectedPneuAv.prixNet : 0,SelectedPneuArr ? SelectedPneuArr.prixNetRear : 0 , 'TVA') }} €</v-col>
                                <v-col class="pa-0">de TVA</v-col>
                            </v-col>
                        </v-row>


                </v-col>
            </v-row>
        </v-navigation-drawer>
        <!--        </v-col>-->

        <v-overlay
            :absolute="true"
            :opacity="0.46"
            :value="showMsg"
            :z-index="5"
        >
            <v-col cols="12">

                <v-card light  >

                    <v-card-text  class="tc_darkBlue text-center">
                        <span class="font-weight-bold title">Attention</span>
                        <br>
                        <span>Vous n'avez pas sélectionné de pneus</span>
                        <v-row>
                            <v-col lg="5">
                                <v-btn @click="showMsg = false">
                                    Choisir des pneus
                                </v-btn>
                            </v-col>
                            <v-col lg="7">
                                <v-btn @click="goToNext">
                                    Je ne souhaite pas de pneus
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>

                </v-card>
            </v-col>
        </v-overlay>

        <v-col class="py-0" v-if="$vuetify.breakpoint.mdAndDown" >

            <v-col v-if="loaderStatus === false && monteType === 'par4'" cols="12" class=" d-flex tc_white text-md-h4 text-sm-h5 text-h6 text-center">
                <span class="font-weight-bold text-lg-h4" style="line-height: 0.8em; display: flex; align-items: center;justify-content: center;" >
                    {{getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, 'SOUS TOTAL')}}
                    <sup class="font-weight-regular text-subtitle-2">€ TTC</sup>
                    <span class="font-weight-regular" style="white-space: pre-wrap"> OU</span>
                    <img v-if="loaderStatus === false && SelectedJanteAv.public_price" style="max-width: 20%;" class="" src="@/assets/logo/logo-Cofidis-Pay_4xcb.png" />
                    <span class="font-weight-bold text-lg-h4" style="display: contents;">
                        {{getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, 'COFIDIS')}}
                        <sup class="font-weight-regular text-subtitle-2">€ TTC</sup>
                    </span>
                </span>
            </v-col>


            <v-col v-if="loaderStatus === false && monteType === 'decale'"  cols="12" class=" d-flex tc_white text-md-h4 text-sm-h5 text-h6 ">
                <span class="font-weight-bold text-lg-h4" style="line-height: 0.8em; display: flex; align-items: center;justify-content: center;" >
                    {{getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, SelectedPneuArr ? SelectedPneuArr.prixNetRear : 0, 'SOUS TOTAL DECALE')}}
                    <sup class="font-weight-regular text-subtitle-2">€ TTC</sup>
                    <span class="font-weight-regular" style="white-space: pre-wrap"> OU</span>
                    <img  style="max-width: 20%;" class="" src="@/assets/logo/logo-Cofidis-Pay_4xcb.png"/>
                    <span class="font-weight-bold text-lg-h4" style="display: contents;">
                        {{getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, SelectedPneuArr ? SelectedPneuArr.prixNetRear : 0, 'COFIDIS DECALE')}}
                        <sup class="font-weight-regular text-subtitle-2">€ TTC</sup>
                    </span>
                </span>
           </v-col>




            <v-col  class=" text-center ">
                <v-col class="font-weight-medium pa-0" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}"  >Prix pour 4 jantes + accessoires de montage si nécessaire</v-col>
                <v-col class="font-weight-medium pa-0" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}" >Montage offert : pack complet, prêt à rouler !</v-col>
            </v-col>

            <v-col cols="12" class="align-self-center tc_white text-center">
                <v-col style="line-height: initial;" class="pa-0">Livraison Gratuite</v-col>
                <v-col style="line-height: initial;" class="pa-0">Expédition sous 2 à 7 jours</v-col>
                <RouterLink :to="{name: 'Livraison'}" target='_blank' class="tc_white">
                    <v-col style="line-height: initial;text-decoration: underline;" class="pa-0" >Voir détails</v-col>
                </RouterLink>                </v-col>
            <v-col lg="2" style="align-self: center;text-align: center">

                <v-content v-if="this.nextStep === 4 && Next === 'true'">
                    <RouterLink  :to="{ name:'Etape'+ this.nextStep, params: { marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}"  style="text-decoration: none">
                        <v-btn rounded large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" style="width: 100%" >
                            Suivant
                        </v-btn>
                    </RouterLink>
                    <RouterLink :to="{ name:'Etape'+ this.nextStep, params: { marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}" @click.native="deletePneu()" style="text-decoration: none">
                        <p class=" tc_white underline pointer pt-3">Je ne souhaite pas de pneus</p>
                    </RouterLink>
                </v-content>
                <RouterLink v-else-if="this.nextStep < 4 && Next === 'true'" :to="{ name:'Etape'+ this.nextStep, params: { marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}"  style="text-decoration: none">
                    <v-btn rounded large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" style="width: 100%" >
                        Suivant
                    </v-btn>
                </RouterLink>
                <RouterLink v-else-if="this.nextStep === 4 && pneuChoisi" :to="{ name:'Etape'+ this.nextStep, params: { marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}"  style="text-decoration: none">
                    <v-btn rounded large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" style="width: 100%" >
                        Suivant
                    </v-btn>
                </RouterLink>
                <RouterLink v-else-if="connected" :to="{ name:'Etape'+ this.nextStep, params: { marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}"  style="text-decoration: none">
                    <v-btn rounded large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" style="width: 100%" >
                        Suivant
                    </v-btn>
                </RouterLink>
                <v-content v-else-if="this.nextStep === 4 && Next !== 'true'">
                    <v-btn  rounded large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" @click="showMsg = true" style="width: 100%">
                        Suivant
                    </v-btn>
                    <RouterLink :to="{ name:'Etape'+ this.nextStep, params: { marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}" @click.native="deletePneu()" style="text-decoration: none">
                        <p class=" tc_white underline pointer pt-3">Je ne souhaite pas de pneus</p>
                    </RouterLink>
                </v-content>
                <RouterLink v-else-if="this.nextStep < 4 && Next === 'false'" :to="{ name:'Etape'+ this.nextStep, params: { marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}"  style="text-decoration: none">
                    <v-btn rounded disabled large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" style="width: 100%" >
                        Suivant
                    </v-btn>
                </RouterLink>
                <!--                    <RouterLink :to="{ name:'Etape'+ this.nextStep}"  style="text-decoration: none">-->
                <!--                        <v-btn rounded disabled large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" style="width: 100%" >-->
                <!--                            Suivant {{nextStep}}-->
                <!--                        </v-btn>-->
                <!--                    </RouterLink>-->


            </v-col>
        </v-col>

      <v-navigation-drawer v-if="$vuetify.breakpoint.mdAndDown && drawerD" v-model="drawer" fixed bottom permanent hide-overlay width="100%" height="14%"   class="bc_grey" style="top: auto; bottom: 0" >
        <v-col class="bc_grey pa-0" cols="12">
          <!--                <v-col class="px-0 pb-0 d-flex tc_white  ">-->
          <!--                <v-col v-if="this.nextStep !== 5" class="pa-0 d-flex tc_white justify-center text-center  text-md-h5 text-sm-h6 text-subtitle-2 ">-->
          <v-col v-if="this.nextStep < 5" class="pa-0 d-flex tc_white justify-center text-center  text-md-h5 text-sm-h6 text-subtitle-2 ">

            <v-col v-if="$vuetify.breakpoint.xs && loaderStatus === false && monteType === 'par4'" cols="6" class="pa-1 justify-center align-center">
              <span class="font-weight-bold ml-6">{{getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, 'TOTAL')}} € TTC </span>
              <br/>
              <span class="body-2"> OU</span>
              <br/>
              <span class="font-weight-bold" > 4 x {{getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, 'COFIDIS')}} €* TTC</span>
              <div style="max-width: 30%; margin:auto">
                <v-img src="@/assets/logo/home-cofidispay.png"/>
              </div>
            </v-col>

            <v-col v-else-if="$vuetify.breakpoint.xs && loaderStatus === false && monteType === 'decale'" cols="6" class="pa-1 justify-center align-center">
              <span class="font-weight-bold">{{getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, SelectedPneuArr ? SelectedPneuArr.prixNetRear : 0, 'SOUS TOTAL DECALE')}} € TTC </span>
              <br/>
              <span class="body-2"> OU </span>
              <br/>
              <span class="font-weight-bold" > 4 x {{getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, SelectedPneuArr ? SelectedPneuArr.prixNetRear : 0, 'COFIDIS DECALE')}} €* TTC</span>
              <div style="max-width: 30%; margin:auto">
                <v-img src="@/assets/logo/home-cofidispay.png"/>
              </div>
            </v-col>

            <v-row v-else-if="loaderStatus === false & monteType === 'par4'">
              <v-col  cols="4" sm="5" class="pt-6">
                <span class="font-weight-bold">{{getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, 'SOUS TOTAL')}} € TTC </span>
              </v-col>
              <v-col cols="3" sm="1" class="pt-6">
                <span class="body-2"> OU </span>
              </v-col>
              <v-col cols="5" sm="6" class="pt-6">
                <span class="font-weight-bold" > 4 x {{getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, 'COFIDIS')}} €* TTC</span>
                <div style="max-width: 30%; margin:auto">
                  <v-img src="@/assets/logo/home-cofidispay.png"/>
                </div>
              </v-col>
            </v-row>

            <v-row v-else-if="loaderStatus === false & monteType === 'decale'">
              <v-col  cols="4" sm="5" class="pt-6">
                <span class="font-weight-bold">{{getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv.prixNet ? SelectedPneuAv.prixNet : null, SelectedPneuArr.prixNetRear ? SelectedPneuArr.prixNetRear : null, 'SOUS TOTAL DECALE')}} € TTC </span>
              </v-col>
              <v-col cols="3" sm="1" class="pt-6">
                <span class="body-2"> OU </span>
              </v-col>
              <v-col cols="5" sm="6" class="pt-6">
                <span class="font-weight-bold" > 4 x {{getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv.prixNet ? SelectedPneuAv.prixNet : null, SelectedPneuArr.prixNetRear ? SelectedPneuArr.prixNetRear : null, 'COFIDIS DECALE')}} €* TTC</span>
                <div style="max-width: 30%; margin:auto">
                  <v-img src="@/assets/logo/home-cofidispay.png"/>
                </div>
              </v-col>
            </v-row>

            <v-col cols="6" style="align-self: center;text-align: center">

              <v-content v-if="this.nextStep === 4 && Next === 'true'">
                <RouterLink  :to="{ name:'Etape'+ this.nextStep}"  style="text-decoration: none">
                  <v-btn rounded large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" style="width: 100%" >
                    Suivant
                  </v-btn>
                </RouterLink>
                <RouterLink :to="{ name:'Etape'+ this.nextStep}" @click.native="deletePneu()" style="text-decoration: none">
                  <p class=" tc_white underline pointer pt-3" style="font-size: 14px!important;">Je ne souhaite pas de pneus</p>
                </RouterLink>
              </v-content>
              <RouterLink v-else-if="this.nextStep < 4 && Next === 'true'" :to="{ name:'Etape'+ this.nextStep}"  style="text-decoration: none">
                <v-btn rounded large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" style="width: 100%" >
                  Suivant
                </v-btn>
              </RouterLink>
              <RouterLink v-else-if="this.nextStep === 4 && pneuChoisi" :to="{ name:'Etape'+ this.nextStep}"  style="text-decoration: none">
                <v-btn rounded large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" style="width: 100%" >
                  Suivant
                </v-btn>
              </RouterLink>
              <RouterLink v-else-if="connected" :to="{ name:'Etape'+ this.nextStep}"  style="text-decoration: none">
                <v-btn rounded large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" style="width: 100%" >
                  Suivant
                </v-btn>
              </RouterLink>
              <v-content v-else-if="this.nextStep === 4 && Next !== 'true'">
                <v-btn  rounded large  :class="{'bc_turquoise': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2', 'tc_white':VUE_APP_SITE_ID === '2'}" class="font-weight-bold" @click="showMsg = true" style="width: 100%">
                  Suivant
                </v-btn>
                <RouterLink :to="{ name:'Etape'+ this.nextStep}" @click.native="deletePneu()" style="text-decoration: none">
                  <p class=" tc_white underline pointer" style="font-size: 14px!important;">Je ne souhaite pas de pneus</p>
                </RouterLink>
              </v-content>

            </v-col>
          </v-col>
          <v-col v-else class="pa-0 d-flex tc_white justify-center text-center  text-md-h5 text-sm-h6 text-subtitle-2">

            <v-row class=" justify-center align-center"  v-if="loaderStatus === false && monteType === 'par4'" >
              <v-col  cols="5" sm="5" >
                <span class="font-weight-bold ml-6">{{getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, 'TOTAL')}} € TTC </span>
              </v-col>
              <v-col cols="2" sm="1">
                <span class="body-2"> OU </span>
              </v-col>
              <v-col cols="5" sm="6">
                <span class="font-weight-bold" > 4 x {{getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, 'COFIDIS')}} €* TTC</span>

              </v-col>
              <v-col offset="7" cols="3" class="pa-0">
                <div style="max-width: 60%; margin:auto">
                  <v-img src="@/assets/logo/home-cofidispay.png"/>
                </div>
              </v-col>
            </v-row>

            <v-row class=" justify-center align-center"  v-if="loaderStatus === false && monteType === 'decale'" >
              <v-col  cols="5" sm="5" >
                <span class="font-weight-bold">{{getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv.prixNet ? SelectedPneuAv.prixNet : null, SelectedPneuArr.prixNetRear ? SelectedPneuArr.prixNetRear : null, 'SOUS TOTAL DECALE')}} € TTC</span>
              </v-col>
              <v-col cols="2" sm="1">
                <span class="body-2"> OU </span>
              </v-col>
              <v-col cols="5" sm="6">
                <span class="font-weight-bold" > 4 x {{getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv.prixNet ? SelectedPneuAv.prixNet : null, SelectedPneuArr.prixNetRear ? SelectedPneuArr.prixNetRear : null, 'COFIDIS DECALE')}} €* TTC</span>
              </v-col>
            </v-row>

          </v-col>
        </v-col>
      </v-navigation-drawer>


    </v-container>
    <!--    </v-app>-->
</template>

<script>
import {mapState} from 'vuex'

import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });
export default {
    name: "ConfigurateurFooter.vue",
    data:() =>({
        config:{},
        nextStep:1,
        connected:'',
        drawerD:true,
        drawer:true,
        footer:false,
        pneuChoisi: false,
        infoJante:'',
        showMsg:false,
        monteType:'par4',
        SelectedJanteAv: {},
        SelectedJanteArr: {},
        SelectedPneuAv: {},
        SelectedPneuArr: {},
        VUE_APP_SITE_ID:null,
    }),
    methods: {
        goToNext(){
            this.$router.push({name:'Etape4', params: {marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }})
        },
        deletePneu:function (){
            this.$store.commit('infoPneus/deletePneu')
        },
        onScreen: function () {
            var rect = document.getElementById('footer').getBoundingClientRect();
            var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
            // console.log('ici ', (rect.bottom < 0 || rect.top - viewHeight >= 0))
            this.drawerD = (rect.bottom < 0 || rect.top - viewHeight >= 0)

        },
        destroyEvent:function () {
            window.removeEventListener('scroll', this.onScreen);
        },
        getRoundPrice: function (Prixjante, PrixPneu, type){
            let price
            var tmp = Math.pow(10, 2);

            switch(type){
                case('4jantes'):
                    if(Prixjante){
                        price  = (Prixjante * 4)
                    }
                    if(this.SelectedJanteAv.public_price === null) {
                        if (this.SelectedJanteAv.buying_price) {
                            this.SelectedJanteAv.public_price = this.SelectedJanteAv.buying_price * 1.2
                        } else {
                            this.SelectedJanteAv.public_price = 300
                        }
                    }
                    break;
                case('1jante'):
                    if(Prixjante){
                        price  = Prixjante
                    }

                    break;
                case('2jantes'):
                    if(Prixjante){
                        price  = (Prixjante * 2)
                    }
                    if(this.SelectedJanteAv.public_price === null) {
                        if (this.SelectedJanteAv.buying_price) {
                            this.SelectedJanteAv.public_price = this.SelectedJanteAv.buying_price * 1.2
                        } else {
                            this.SelectedJanteAv.public_price = 300
                        }
                    }
                    break;
                case('4pneus'):
                    if(PrixPneu){
                        price  = ((PrixPneu + 10) * 4)
                    }
                    break;
                case('2pneus'):
                    if(PrixPneu){
                        price  = ((PrixPneu + 10) * 2)
                    }
                    break;
                case('1pneu'):
                    if(PrixPneu){
                        price  = (PrixPneu + 10)
                    }
                    break;
                case('SOUS TOTAL'):
                    if(Prixjante && PrixPneu){
                        price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires)
                    }else if(Prixjante){
                        price  = ((Prixjante * 4) + this.TotalPriceAccessoires)
                    }
                    break;
                case('TOTAL'):
                    if(Prixjante && PrixPneu){
                        price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90)
                    }else if(Prixjante){
                        price  = ((Prixjante * 4) + this.TotalPriceAccessoires + 9.90)
                    }
                    break;
                case('TOTAL COFIDIS'):
                    if(Prixjante && PrixPneu){
                        price  = (((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90) * 1.022)
                    }else if(Prixjante){
                        price  = (((Prixjante * 4) + this.TotalPriceAccessoires + 9.90) * 1.022)
                    }
                    break;
                case('COFIDIS'):
                    if(Prixjante && PrixPneu){
                        price  = (((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires) * 1.022) /4
                    }else if(Prixjante){
                        price  = (((Prixjante * 4) + this.TotalPriceAccessoires) * 1.022) /4
                    }
                    break;
                case('TVA'):
                    if(Prixjante && PrixPneu){
                        price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90 ) * 0.2
                    }else if(Prixjante){
                        price  = ((Prixjante * 4) + this.TotalPriceAccessoires + 9.90) * 0.2
                    }
                    break;
            }


            if(this.isProfessional){
                let taux = (1 - parseFloat('0.' + this.tauxRemise))
                taux = (Math.round( taux * tmp )/tmp).toFixed(2)

                Prixjante = Prixjante * taux
            }

            Prixjante = (Math.round( Prixjante * tmp )/tmp).toFixed(2)
            PrixPneu = Math.round(PrixPneu * tmp )/tmp.toFixed(2)

            switch(type){
                case('4jantes'):
                    if(Prixjante){
                        price  = (Prixjante * 4)
                    }
                    break;
                case('1jante'):
                    if(Prixjante){
                        price  = Prixjante
                    }
                    break;
                case('2jantes'):
                    if(Prixjante){
                        price  = (Prixjante * 2)
                    }
                    break;
                case('4pneus'):
                    if(PrixPneu){
                        price  = ((PrixPneu + 10) * 4)
                    }
                    break;
                case('2pneus'):
                    if(PrixPneu){
                        price  = ((PrixPneu + 10) * 2)
                    }
                    break;
                case('1pneu'):
                    if(PrixPneu){
                        price  = (PrixPneu + 10)
                    }
                    break;
                case('SOUS TOTAL'):
                    if(Prixjante && PrixPneu){
                        price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires)
                    }else if(Prixjante){
                        price  = ((Prixjante * 4) + this.TotalPriceAccessoires)
                    }
                    break;
                case('TOTAL'):
                    if(Prixjante && PrixPneu){
                        price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90)
                    }else if(Prixjante){
                        price  = ((Prixjante * 4) + this.TotalPriceAccessoires + 9.90)
                    }
                    break;
                case('TOTAL COFIDIS'):
                    if(Prixjante && PrixPneu){
                        price  = (((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90) * 1.022)
                    }else if(Prixjante){
                        price  = (((Prixjante * 4) + this.TotalPriceAccessoires + 9.90) * 1.022)
                    }
                    break;
                case('COFIDIS'):
                    if(Prixjante && PrixPneu){
                        price  = (((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires) * 1.022) /4
                    }else if(Prixjante){
                        price  = (((Prixjante * 4) + this.TotalPriceAccessoires) * 1.022) /4
                    }
                    break;
                case('TVA'):
                    if(Prixjante && PrixPneu){
                        price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90 ) * 0.2
                    }else if(Prixjante){
                        price  = ((Prixjante * 4) + this.TotalPriceAccessoires + 9.90) * 0.2
                    }
                    break;
            }

            return (Math.round( price * tmp )/tmp).toFixed(2)
        },
        getRoundPriceMonteDecale: function (PrixjanteAv, PrixjanteArr, PrixPneuAv, PrixPneuArr, type){
            let price
            var tmp = Math.pow(10, 2);

            if(this.isProfessional){
                let taux = (1 - parseFloat('0.' + this.tauxRemise))
                taux = (Math.round( taux * tmp )/tmp).toFixed(2)

                PrixjanteAv = PrixjanteAv * taux
                PrixjanteArr = PrixjanteArr * taux
            }

            PrixjanteAv = Math.round(PrixjanteAv * tmp )/tmp.toFixed(2)
            PrixjanteArr = Math.round(PrixjanteArr * tmp )/tmp.toFixed(2)
            PrixPneuAv = Math.round(PrixPneuAv * tmp )/tmp.toFixed(2)
            PrixPneuArr = Math.round(PrixPneuArr * tmp )/tmp.toFixed(2)

            switch(type){
                case('4jantes decale'):
                    if(PrixjanteAv && PrixjanteArr){
                        price  = (PrixjanteAv * 2) + (PrixjanteArr * 2)
                    }
                    break;
                case('4pneus decale'):
                    if(PrixPneuAv && PrixPneuArr){
                        price  = (((PrixPneuAv + 10) * 2) + ((PrixPneuArr + 10) * 2))
                    }
                    break;
                case('2jantesAv'):
                    if(PrixjanteAv){
                        price  = (PrixjanteAv * 2)
                    }
                    break;
                case('2jantesArr'):
                    if(PrixjanteArr){
                        price  = (PrixjanteArr * 2)
                    }
                    break;
                case('2pneusAv'):
                    if(PrixPneuAv){
                        price  = ((PrixPneuAv + 10) * 2)
                    }
                    break;
                case('2pneusArr'):
                    if(PrixPneuArr){
                        price  = ((PrixPneuArr + 10) * 2)
                    }
                    break;
                case('1jante'):
                    if(PrixjanteAv){
                        price  = PrixjanteAv
                    }
                    if(PrixjanteArr){
                        price  = PrixjanteArr
                    }
                    break;
                case('1pneu'):
                    if(PrixPneuAv){
                        price  = (PrixPneuAv + 10)
                    }
                    if(PrixPneuArr){
                        price  = (PrixPneuArr + 10)
                    }
                    break;
                case('SOUS TOTAL DECALE'):
                    if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                        price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires)
                    }else if(PrixjanteAv && PrixjanteArr){
                        price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires)
                    }
                    break;
                case('TOTAL'):
                    if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                        price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires + 9.90)
                    }else if(PrixjanteAv && PrixjanteArr){
                        price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires + 9.90)
                    }
                    break;
                case('TOTAL COFIDIS'):
                    if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                        price  = (((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires + 9.90) * 1.022)
                    }else if(PrixjanteAv && PrixjanteArr){
                        price  = (((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires + 9.90) * 1.022)
                    }
                    break;
                case('TVA'):
                    if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                        price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires + 9.90) * 0.2
                    }else if(PrixjanteAv && PrixjanteArr){
                        price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires + 9.90) * 0.2
                    }
                    break;
                case('COFIDIS DECALE'):
                    if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                        price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)  +  (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires) /4
                    }else if(PrixjanteAv && PrixjanteArr){
                        price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)  + this.TotalPriceAccessoires) /4
                    }
                    break;
            }
            return (Math.round( price * tmp )/tmp).toFixed(2)
        },
        getOnglet(value){

            switch(value){
                case'jantesAv':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv
                case 'jantesArr':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr
                case 'pneusAv':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv
                case 'pneusArr':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr
                case 'accessoires':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').accessoires
                case 'totalPriceAccessoires':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').totalPrice
                case 'modelePossibleAuCarre':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre')
                case 'modelePossibleFront':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront')
                case 'modelePossibleRear':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear')
            }
        },
    },
    created() {
        this.SelectedJanteAv = this.getOnglet('jantesAv')
        this.SelectedJanteArr = this.getOnglet('jantesArr')
        this.SelectedPneuAv = this.getOnglet('pneusAv') ? this.getOnglet('pneusAv') : null
        this.SelectedPneuArr = this.getOnglet('pneusArr') ? this.getOnglet('pneusArr') : null
        this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
        this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
        this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0

    },
    mounted(){
        this.monteType =  (this.$route.params.decale).toString() === '0' ? 'par4' : 'decale'
        this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID

        this.SelectedJanteAv = this.getOnglet('jantesAv')
        this.SelectedJanteArr = this.getOnglet('jantesArr')
        this.SelectedPneuAv = this.getOnglet('pneusAv') ? this.getOnglet('pneusAv') : null
        this.SelectedPneuArr = this.getOnglet('pneusArr') ? this.getOnglet('pneusArr') : null
        this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
        this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
        this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0

        this.nextStep = (parseInt(this.$route.name.substring(5)) + 1);

        if(this.$vuetify.breakpoint.mdAndDown){
            window.addEventListener('scroll', this.onScreen);

        }
    },
    computed:{
        ...mapState({
            Next: state => state.footerNext.Next,
            isProfessional: state => state.auth.isRevendeur,
            loaderStatus: state => state.loader.isLoaderPrix,
            tauxRemise: state => state.auth.tauxRemise,

        })
    },
    watch:{
        '$props':{
            handler: function () {
                // console.log('triggerUpdateFooter')

                this.SelectedJanteAv = this.getOnglet('jantesAv')
                this.SelectedJanteArr = this.getOnglet('jantesArr')
                this.SelectedPneuAv = this.getOnglet('pneusAv') ? this.getOnglet('pneusAv') : null
                this.SelectedPneuArr = this.getOnglet('pneusArr') ? this.getOnglet('pneusArr') : null
                this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
                this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
                this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0


                this.TotalPriceAccessoires = 0
                Object.values(this.SelectedAccessoires).forEach((e) => {
                    if(e != null) {
                        this.TotalPriceAccessoires += parseInt(e.data.price)
                    }
                })
                // console.log('jantesAv', this.getOnglet('jantesAv'))
                // console.log('jantesArr', this.getOnglet('jantesArr'))
                // console.log('pneusAv', this.getOnglet('pneusAv'))
                // console.log('pneusArr', this.getOnglet('pneusArr'))

            },
            deep: true
        }
    },
    props:['update']
}
</script>

<style scoped>
@media (max-width: 1264px) {
    div >>> .v-overlay__content{
        width: 100%;
        bottom: 10%;
        position: absolute;
    }
}

</style>
