<template>
    <v-container fluid class="bc_white" >
        <v-col fluid offset-lg="1" lg="10" class="bc_white pt-0">

            <v-col offset-md="2" md="8" class="pt-0" v-if="forgottenPassw === false">
                <v-card @click="switchType">
                    <v-row>
                        <v-col lg="5" class="py-0 border-right" align-self="center">
                            <v-card-text v-if="type === 'sign_up'" @click="addListenerLogin" class="font-weight-bold pa-2">Déjà client ?</v-card-text>
                            <v-card-text v-if="type === 'sign_in'" @click="addListenerRegister" class="font-weight-bold pa-2">Pas encore client ?</v-card-text>
                        </v-col>
                        <v-col class="pa-0">
                            <v-card-text v-if="type === 'sign_up'" @click="addListenerLogin">Cliquez ici pour vous connecter</v-card-text>
                            <v-card-text v-if="type === 'sign_in'" @click="addListenerRegister">Cliquez ici pour vous inscrire</v-card-text>
                        </v-col>
                    </v-row>

                </v-card>
            </v-col>
<!--            <v-col v-if="$vuetify.breakpoint.mdAndUp"></v-col>-->


            <v-row v-if="type === 'sign_up' && forgottenPassw === false" style="position: relative">

                <v-col v-if="VUE_APP_SITE_ID === '1'" :class="{'bulle--padding-bottom-xl':$vuetify.breakpoint.xl}">
                    <v-img v-if="$vuetify.breakpoint.mdAndUp" src="@/assets/forme.svg" style="position: absolute; width: 70%; right: 14%"/>
                    <v-col offset-lg="5" offset-xl="4" class="font-weight-bold tc_blue " id="anchor"
                           :class="{
                                'headline':$vuetify.breakpoint.lg,
                                // 'pt-6':$vuetify.breakpoint.lg,
                                'mt-8':$vuetify.breakpoint.xl,
                                'display-1':$vuetify.breakpoint.xl,
                                'bulle--padding-top-xl':$vuetify.breakpoint.xl,
                                'display-1':$vuetify.breakpoint.xl,
                                'pb-6':$vuetify.breakpoint.xl,
                            }"
                           style="position: relative">Créer un compte</v-col>

                    <form style="position: relative;">
                        <v-col offset-lg="3" lg="7" class="py-0">
                            <v-row>
                                <v-col lg="6" class="py-0">
                                    <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Nom <span class="tc_turquoise">*</span></p>
                                    <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.firstname"
                                        :error-messages="firstnameRegisterErrors"
                                        required
                                        @input="$v.register.firstname.$touch()"
                                        @blur="$v.register.firstname.$touch()"
                                    />
                                </v-col>

                                <v-col lg="6" class="py-0">
                                    <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Prénom <span class="tc_turquoise">*</span></p>
                                    <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.lastname"
                                        :error-messages="lastnameRegisterErrors"
                                        required
                                        @input="$v.register.lastname.$touch()"
                                        @blur="$v.register.lastname.$touch()"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col lg="6" class="py-0">
                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Téléphone <span class="tc_turquoise">*</span></p>
                                    <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.phone"
                                        :error-messages="phoneRegisterErrors"
                                        required
                                        @input="$v.register.phone.$touch()"
                                        @blur="$v.register.phone.$touch()"
                                    />
                                </v-col>

                                <v-col lg="6" class="py-0">
                                    <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_turquoise">*</span></p>
                                    <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.email"
                                        :error-messages="emailRegisterErrors"
                                        required
                                        @input="$v.register.email.$touch()"
                                        @blur="$v.register.email.$touch()"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col lg="6" class="py-0">
                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Mot de passe <span class="tc_turquoise">*</span></p>
                                    <v-text-field
                                        id="textboxMdpRegister"
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.password"
                                        :error-messages="passwordRegisterErrors"
                                        required
                                        @input="$v.register.password.$touch()"
                                        @blur="$v.register.password.$touch()"
                                        :append-icon="show_register ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show_register ? 'text' : 'password'"
                                        @click:append="show_register = !show_register"
                                    />
                                </v-col>

                                <v-col lg="6" class="text-center d-flex py-0">
                                    <v-btn v-if="!isSubmitted" class="font-weight-bold ma-auto" dark color="#1840DA" id="buttonMdpRegister" @click="submit_register">S'enregistrer</v-btn>
                                    <v-btn v-if="isSubmitted" loading class="font-weight-bold ma-auto" dark color="#1840DA" @click="submit_register">S'enregistrer</v-btn>
                                </v-col>
                                <v-col offset="6" cols="6" class="text-center my-0" v-if="error_register">
                                    <span  class="" style="color:red">{{ errorMsg_register }}</span>

                                </v-col>

                            </v-row>

                        </v-col>
                    </form>

                </v-col>

                <v-col offset-md="2" md="8" v-if="VUE_APP_SITE_ID === '2'" :class="{'bulle--padding-bottom-xl':$vuetify.breakpoint.xl}">
                <v-card>
                    <v-col offset-lg="3" offset-xl="4" class="font-weight-bold tc_orange " id="anchor"
                           :class="{
                                'headline':$vuetify.breakpoint.lg,
                                // 'pt-6':$vuetify.breakpoint.lg,
                                'mt-8':$vuetify.breakpoint.xl,
                                'display-1':$vuetify.breakpoint.xl,
                                'bulle--padding-top-xl':$vuetify.breakpoint.xl,
                                'display-1':$vuetify.breakpoint.xl,
                                'pb-6':$vuetify.breakpoint.xl,
                            }"
                           style="position: relative">Créer un compte</v-col>

                    <form style="position: relative;">
                        <v-col offset-lg="1" lg="10" class="py-0">
                            <v-row>
                                <v-col lg="6" class="py-0">
                                    <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Nom <span class="tc_orange">*</span></p>
                                    <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.firstname"
                                        :error-messages="firstnameRegisterErrors"
                                        required
                                        @input="$v.register.firstname.$touch()"
                                        @blur="$v.register.firstname.$touch()"
                                    />
                                </v-col>

                                <v-col lg="6" class="py-0">
                                    <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Prénom <span class="tc_orange">*</span></p>
                                    <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.lastname"
                                        :error-messages="lastnameRegisterErrors"
                                        required
                                        @input="$v.register.lastname.$touch()"
                                        @blur="$v.register.lastname.$touch()"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col lg="6" class="py-0">
                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Téléphone <span class="tc_orange">*</span></p>
                                    <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.phone"
                                        :error-messages="phoneRegisterErrors"
                                        required
                                        @input="$v.register.phone.$touch()"
                                        @blur="$v.register.phone.$touch()"
                                    />
                                </v-col>

                                <v-col lg="6" class="py-0">
                                    <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_orange">*</span></p>
                                    <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.email"
                                        :error-messages="emailRegisterErrors"
                                        required
                                        @input="$v.register.email.$touch()"
                                        @blur="$v.register.email.$touch()"
                                    />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col lg="6" class="py-0">
                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Mot de passe <span class="tc_orange">*</span></p>
                                    <v-text-field
                                        id="textboxMdpRegister"
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.password"
                                        :error-messages="passwordRegisterErrors"
                                        required
                                        @input="$v.register.password.$touch()"
                                        @blur="$v.register.password.$touch()"
                                        :append-icon="show_register ? 'mdi-eye' : 'mdi-eye-off'"
                                        :type="show_register ? 'text' : 'password'"
                                        @click:append="show_register = !show_register"
                                    />
                                </v-col>

                                <v-col lg="6" class="text-center d-flex py-0">
                                    <v-btn v-if="!isSubmitted" class="font-weight-bold ma-auto" dark color="#FC5C1F" id="buttonMdpRegister" @click="submit_register">S'enregistrer</v-btn>
                                    <v-btn v-if="isSubmitted" loading class="font-weight-bold ma-auto" dark color="#FC5C1F" @click="submit_register">S'enregistrer</v-btn>
                                </v-col>
                                <v-col offset="6" cols="6" class="text-center my-0" v-if="error_register">
                                    <span  class="" style="color:red">{{ errorMsg_register }}</span>

                                </v-col>

                            </v-row>

                        </v-col>
                    </form>
</v-card>
                </v-col>

            </v-row>

            <v-row v-if="type === 'sign_in' && forgottenPassw === false" style="position: relative">

                <v-col v-if="VUE_APP_SITE_ID === '1'" class="bulle--padding-bottom-xl">

                    <v-img v-if="$vuetify.breakpoint.mdAndUp" src="@/assets/forme.svg" style="position: absolute; width: 73%;right: 13%"/>
                    <v-col offset-lg="5" class="font-weight-bold tc_blue "
                           :class="{
                                'headline':$vuetify.breakpoint.lg,
                                'pt-12':$vuetify.breakpoint.xl,
                                'mt-8':$vuetify.breakpoint.xl,
                                'display-1':$vuetify.breakpoint.xl,
                                'bulle--padding-top-xl':$vuetify.breakpoint.xl,
                                'display-1':$vuetify.breakpoint.xl,
                                'pb-6':$vuetify.breakpoint.xl,
                            }"
                           style="position: relative"
                    >Se connecter</v-col>

                    <form style="position: relative;">
                        <v-col offset-lg="3" lg="7">
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                dense
                                outlined
                                background-color="white"
                                v-model="login.email"
                                :error-messages="emailLogInErrors"
                                required
                                @input="$v.login.email.$touch()"
                                @blur="$v.login.email.$touch()"
                            />
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Mot de passe <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                id="textboxMdpLogin"
                                dense
                                outlined
                                background-color="white"
                                v-model="login.password"
                                :error-messages="passwordLogInErrors"
                                required
                                @input="$v.login.password.$touch()"
                                @blur="$v.login.password.$touch()"
                                :append-icon="show_logIn ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show_logIn ? 'text' : 'password'"
                                @click:append="show_logIn = !show_logIn"
                            />
                            <v-btn v-if="!isSubmitted" class="font-weight-bold " dark color="#1840DA" id="buttonMdpLogin" @click="submit_logIn">Identification</v-btn>
                            <v-btn v-if="isSubmitted" loading class="font-weight-bold " dark color="#1840DA" @click="submit_logIn">Identification</v-btn>

                            <v-row>
                                <span v-if="error_login" class="mt-5 ml-3" style="color:red">{{ errorMsg_login }}</span>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <span class="tc_black pointer" @click="forgottenPassw = true" :class="{'caption':$vuetify.breakpoint.lg, 'body-2':$vuetify.breakpoint.xl}">Mot de passe oublié ?</span>
                                    <!--                                    <v-btn class="font-weight-bold " dark color="#1840DA" @click="FonctionDeMotDePasseOublie">MOT DE PASSE OUBLIé FONCTION OPérationnel</v-btn>-->
                                    <!--                                    <v-btn class="font-weight-bold " dark color="#1840DA" @click="forgottenPassw = true">MOT DE PASSE OUBLIé FONCTION OPérationnel</v-btn>-->
                                </v-col>

                            </v-row>
                        </v-col>
                    </form>

                </v-col>

                <v-col offset-md="2" md="8" v-if="VUE_APP_SITE_ID === '2'" class="bulle--padding-bottom-xl">

                    <v-card>
                        <v-col offset-md="3" class="font-weight-bold tc_orange "
                               :class="{
                                    'headline':$vuetify.breakpoint.lg,
                                    'pt-12':$vuetify.breakpoint.xl,
                                    'mt-8':$vuetify.breakpoint.xl,
                                    'display-1':$vuetify.breakpoint.xl,
                                    'bulle--padding-top-xl':$vuetify.breakpoint.xl,
                                    'display-1':$vuetify.breakpoint.xl,
                                    'pb-6':$vuetify.breakpoint.xl,
                                }"
                               style="position: relative"
                        >Se connecter</v-col>

                        <form style="position: relative;">
                        <v-col offset-md="2" md="8">
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_orange">*</span></p>
                            <v-text-field
                                dense
                                outlined
                                background-color="white"
                                v-model="login.email"
                                :error-messages="emailLogInErrors"
                                required
                                @input="$v.login.email.$touch()"
                                @blur="$v.login.email.$touch()"
                            />
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Mot de passe <span class="tc_orange">*</span></p>
                            <v-text-field
                                id="textboxMdpLogin"
                                dense
                                outlined
                                background-color="white"
                                v-model="login.password"
                                :error-messages="passwordLogInErrors"
                                required
                                @input="$v.login.password.$touch()"
                                @blur="$v.login.password.$touch()"
                                :append-icon="show_logIn ? 'mdi-eye' : 'mdi-eye-off'"
                                :type="show_logIn ? 'text' : 'password'"
                                @click:append="show_logIn = !show_logIn"
                            />
                            <v-btn v-if="!isSubmitted" class="font-weight-bold "  dark color="#FC5C1F"  id="buttonMdpLogin" @click="submit_logIn">Identification</v-btn>
                            <v-btn v-if="isSubmitted" loading class="font-weight-bold " dark color="#FC5C1F"  @click="submit_logIn">Identification</v-btn>

                            <v-row>
                                <span v-if="error_login" class="mt-5 ml-3" style="color:red">{{ errorMsg_login }}</span>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <span class="tc_black pointer" @click="forgottenPassw = true" :class="{'caption':$vuetify.breakpoint.lg, 'body-2':$vuetify.breakpoint.xl}">Mot de passe oublié ?</span>
                                    <!--                                    <v-btn class="font-weight-bold " dark color="#1840DA" @click="FonctionDeMotDePasseOublie">MOT DE PASSE OUBLIé FONCTION OPérationnel</v-btn>-->
                                    <!--                                    <v-btn class="font-weight-bold " dark color="#1840DA" @click="forgottenPassw = true">MOT DE PASSE OUBLIé FONCTION OPérationnel</v-btn>-->
                                </v-col>

                            </v-row>
                        </v-col>
                    </form>
                    </v-card>
                </v-col>

            </v-row>

            <v-row v-if="forgottenPassw === true"  style="position: relative" >

                <v-col v-if="VUE_APP_SITE_ID === '1'" offset-lg="1" class="bulle--padding-bottom-xl">

                    <v-img src="@/assets/forme.svg" style="position: absolute; width: 70%"/>
                    <v-col offset-lg="4" class="font-weight-bold  tc_blue"
                           :class="{
                                'headline':$vuetify.breakpoint.lg,
                                'pt-12':$vuetify.breakpoint.lg,
                                'mt-8':$vuetify.breakpoint.lg,
                                'display-1':$vuetify.breakpoint.xl,
                                'bulle--padding-top-xl':$vuetify.breakpoint.xl,
                                'display-1':$vuetify.breakpoint.xl,
                                'pb-6':$vuetify.breakpoint.xl,
                            }"
                           style="position: relative;"
                    >Mot de passe oublié ?</v-col>
                    <v-col offset-lg="3" lg="5">
                        <p class="mb-0" style="position: relative">Entrez votre e-mail et recevez votre nouveau mot de passe.</p>

                    </v-col>


                    <form style="position: relative;">
                        <v-col offset-lg="3" lg="5">
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                dense
                                outlined
                                background-color="white"
                                v-model="forgotten_email"
                                :error-messages="emailForgottenErrors"
                                required
                                @input="$v.forgotten_email.$touch()"
                                @blur="$v.forgotten_email.$touch()"
                            />

                            <v-btn class="font-weight-bold " dark color="#1840DA" @click="submit_forgot">Identification</v-btn>

                        </v-col>
                    </form>

                </v-col>

                <v-col offset-md="2" md="8" v-if="VUE_APP_SITE_ID === '2'" offset-lg="1" class="bulle--padding-bottom-xl">

                    <v-card>
                        <v-col offset-md="3" class="font-weight-bold  tc_orange"
                               :class="{
                                    'headline':$vuetify.breakpoint.lg,
                                    'pt-12':$vuetify.breakpoint.lg,
                                    'mt-8':$vuetify.breakpoint.lg,
                                    'display-1':$vuetify.breakpoint.xl,
                                    'bulle--padding-top-xl':$vuetify.breakpoint.xl,
                                    'display-1':$vuetify.breakpoint.xl,
                                    'pb-6':$vuetify.breakpoint.xl,
                                }"
                               style="position: relative;"
                        >Mot de passe oublié ?</v-col>
                        <v-col offset-lg="2" lg="7">
                            <p class="mb-0" style="position: relative">Entrez votre e-mail et recevez votre nouveau mot de passe.</p>
                        </v-col>


                        <form style="position: relative;">
                            <v-col offset-md="2" md="8">
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                dense
                                outlined
                                background-color="white"
                                v-model="forgotten_email"
                                :error-messages="emailForgottenErrors"
                                required
                                @input="$v.forgotten_email.$touch()"
                                @blur="$v.forgotten_email.$touch()"
                            />

                            <v-btn class="font-weight-bold " dark color="#FC5C1F" @click="submit_forgot">Identification</v-btn>

                        </v-col>
                    </form>
                    </v-card>
                </v-col>

            </v-row>

        </v-col>
    </v-container>
</template>

<script>

    import CryptoJS from "crypto-js";

    const { validationMixin } = require('vuelidate')
    const { required, minLength, email , numeric, maxLength} = require('vuelidate/lib/validators')
    import Vue from 'vue'
    import axios from 'axios'
    import VueAxios from 'vue-axios'

    import SecureLS from "secure-ls";
    import $ from "jquery";
    var ls = new SecureLS({ isCompression: false });

    Vue.use(VueAxios, axios)

    export default {
        name: "Etape4_signInUp",
        components: {

        },
        mixins: [validationMixin],

        validations: {
            register: {
                firstname: {required},
                lastname: {required},
                phone: {required, numeric, minLength: minLength(10), maxLength: maxLength(10)},
                email: { required, email },
                password: {
                    required,
                    minLength: minLength(8)
                },
            },
            login:{
                email: { required, email },
                password: {
                    required,
                    minLength: minLength(8)
                },
            },

            forgotten_email : { required, email },

            select: { required },
            checkbox: {
                checked (val) {
                    return val
                },
            },
        },
        data: () => ({
            name: '',
            email_logIn: '',
            password_logIn: '',
            email_register: '',
            password_register: '',
            checkbox: '',
            show_logIn: false,
            show_register: false,
            login : {},
            register : {},
            type:'sign_up',
            forgottenPassw: false,
            forgotten_email:'',
            isSubmitted:false,
            error_register:false,
            errorMsg_register:'',
            error_login:false,
            errorMsg_login:'',
            VUE_APP_SITE_ID:null,
        }),

        computed: {
            emailLogInErrors () {
                const errors = [];
                if (!this.$v.login.email.$dirty) return errors
                !this.$v.login.email.email && errors.push('E-mail invalide')
                !this.$v.login.email.required && errors.push('E-mail obligatoire')
                return errors
            },
            passwordLogInErrors () {
                const errors = [];
                if (!this.$v.login.password.$dirty) return errors
                !this.$v.login.password.minLength && errors.push('Le mot de passe doit faire minimum 8 caractères')
                !this.$v.login.password.required && errors.push('Mot de passe obligatoire')
                return errors
            },

            firstnameRegisterErrors () {
                const errors = [];
                if (!this.$v.register.firstname.$dirty) return errors
                // !this.$v.register.firstname.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.register.firstname.required && errors.push('Le prénom est obligatoire')
                return errors
            },
            lastnameRegisterErrors () {
                const errors = [];
                if (!this.$v.register.lastname.$dirty) return errors
                // !this.$v.register.lastname.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.register.lastname.required && errors.push('Le nom est obligatoire')
                return errors
            },

            phoneRegisterErrors () {
                const errors = [];
                if (!this.$v.register.phone.$dirty) return errors
                !this.$v.register.phone.required && errors.push('Téléphone obligatoire')
                !this.$v.register.phone.minLength && errors.push('Téléphone invalide')
                !this.$v.register.phone.maxLength && errors.push('Téléphone invalide')
                return errors
            },
            emailRegisterErrors () {
                const errors = [];
                if (!this.$v.register.email.$dirty) return errors
                !this.$v.register.email.email && errors.push('E-mail invalide')
                !this.$v.register.email.required && errors.push('E-mail obligatoire')
                return errors
            },
            passwordRegisterErrors () {
                const errors = [];
                if (!this.$v.register.password.$dirty) return errors
                !this.$v.register.password.minLength && errors.push('Le mot de passe doit faire minimum 8 caractères')
                !this.$v.register.password.required && errors.push('Mot de passe obligatoire')
                return errors
            },

            emailForgottenErrors () {
                const errors = [];
                if (!this.$v.forgotten_email.$dirty) return errors
                !this.$v.forgotten_email.email && errors.push('E-mail invalide')
                !this.$v.forgotten_email.required && errors.push('E-mail obligatoire')
                return errors
            },
        },
        mounted (){
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID

            setTimeout(()=>{
                $("#textboxMdpRegister").keyup(function(event) {
                    if (event.keyCode === 13) {
                        $("#buttonMdpRegister").click();
                    }
                });
            }, 2000)

        },
        updated(){


        },
        methods: {
            anchor(){
                setTimeout(()=>{
                    var element = document.getElementById('anchor');

                    if (this.$vuetify.breakpoint.lgAndUp){
                        element.scrollTo({
                            top: 400,
                            behavior: 'smooth'
                        });
                    }else{
                        window.scrollTo({
                            top: 400,
                            behavior: 'smooth'
                        });
                    }


                }, 100)

            },
            addListenerLogin(){
                setTimeout(()=>{
                    $("#textboxMdpLogin").keyup(function(event) {
                        if (event.keyCode === 13) {
                            $("#buttonMdpLogin").click();
                        }
                    });
                },100)

            },
            addListenerRegister(){
                setTimeout(()=>{
                    $("#textboxMdpRegister").keyup(function(event) {
                        if (event.keyCode === 13) {
                            $("#buttonMdpRegister").click();
                        }
                    });
                },100)

            },
            switchType:function(){

                if(this.type === 'sign_up'){
                    this.type = 'sign_in';
                    return
                }
                if(this.type === 'sign_in'){
                    this.type = 'sign_up';
                    return
                }
            },
            submit_logIn () {
                this.$v.login.email.$touch();
                this.$v.login.password.$touch();
                this.isSubmitted = true

                if (!this.$v.login.email.$invalid && !this.$v.login.password.$invalid) {

                    this.error_login = false
                    this.login.email = this.$v.login.email.$model
                    this.login.password = this.$v.login.password.$model
                    let cryptedPassword = CryptoJS.AES.encrypt(this.login.password, process.env.VUE_APP_SECRET_KEY).toString()


                    Vue.axios.get('https://api.espace-jantes.com/loginUser', {
                        params: {
                            mail: this.login.email,
                            password: cryptedPassword,
                            websiteid: this.VUE_APP_SITE_ID === "1" ? "1" : "2"

                        }
                    })
                        .then((response) => {
                            if(response.data.etat === true){

                                let userId = response.data.data.id
                                let token = response.data.token
                                let isRevendeur = false
                                let waitingRevendeur = false
                                let tauxRemise = response.data.data.taux_remise

                                response.data.data.status === 2 ? isRevendeur = true : isRevendeur = false
                                response.data.data.status === 4 ? waitingRevendeur = true : waitingRevendeur = false


                                this.$store.dispatch('auth/AUTH_REQUEST', { userId, token, isRevendeur, waitingRevendeur, tauxRemise }).then(() => {
                                    this.$emit('connect', true)
                                })

                                return true
                            }else{
                                this.errorMsg_login = response.data.message
                            }
                            this.isSubmitted = false
                            this.error_login = true
                            // console.log("La connexion à échouer ")
                            // localStorage.removeItem('user-token')
                            ls.remove('user-token')
                            return false
                    })

                }else{
                    this.isSubmitted = false
                    this.error_login = true
                }
            },
            submit_register () {
                this.$v.register.firstname.$touch();
                this.$v.register.lastname.$touch();
                this.$v.register.phone.$touch();
                this.$v.register.email.$touch();
                this.$v.register.password.$touch();

                this.isSubmitted = true
                console.log('touch',  this.$v.register.firstname.$touch())
                console.log('touch',  this.$v.register.lastname.$touch())


                if (!this.$v.register.firstname.$invalid && !this.$v.register.lastname.$invalid && !this.$v.register.phone.$invalid && !this.$v.register.email.$invalid && !this.$v.register.password.$invalid ) {

                this.register.firstname = this.$v.register.firstname.$model
                this.register.lastname = this.$v.register.lastname.$model
                this.register.phone = this.$v.register.phone.$model
                this.register.email = this.$v.register.email.$model
                this.register.password = this.$v.register.password.$model
                    let cryptedPassword = CryptoJS.AES.encrypt(this.register.password, process.env.VUE_APP_SECRET_KEY).toString()


                Vue.axios.post('https://api.espace-jantes.com/addUser', {
                    params: {
                        mail: this.register.email,
                        password: cryptedPassword,
                        nom: this.register.firstname,
                        prenom:this.register.lastname,
                        status: '3',
                        telephone:this.register.phone,
                        websiteid: this.VUE_APP_SITE_ID === "1" ? "1" : "2"
                    }
                })
                    .then((response) => {
                        if(response.data.etat){
                            let cryptedPassword = CryptoJS.AES.encrypt(this.register.password, process.env.VUE_APP_SECRET_KEY).toString()


                            Vue.axios.get('https://api.espace-jantes.com/loginUser', {
                                params: {
                                    mail: this.register.email,
                                    password: cryptedPassword,
                                    websiteid: this.VUE_APP_SITE_ID === "1" ? "1" : "2"
                                }
                            })
                                .then((response) => {
                                    if(response.data.etat === true){

                                        let email = this.register.email
                                        let password = this.register.password
                                        let token = response.data.token
                                        let userId = response.data.data.id
                                        let isRevendeur = false
                                        let waitingRevendeur = false

                                        response.data.data.status === 2 ? isRevendeur = true : isRevendeur = false
                                        response.data.data.status === 4 ? waitingRevendeur = true : waitingRevendeur = false


                                        this.$store.dispatch('auth/AUTH_REQUEST', { userId, email, password, token, isRevendeur, waitingRevendeur }).then(() => {
                                            this.$emit('connect', true)
                                        })

                                        return true
                                    }else if(response.data.etat === false) {

                                        this.errorMsg_login = response.data.message
                                        this.error_login = true
                                        this.isSubmitted = false
                                        // console.log("La connexion à échouer ")
                                        // localStorage.removeItem('user-token')
                                        ls.remove('user-token')
                                        return false
                                    }
                                })

                            return true
                        }else{
                            this.errorMsg_register = response.data.message
                        }
                        this.isSubmitted = false
                        this.error_register = true
                        this.anchor()
                        // console.log("L'inscription à échouer ")
                        return false
                    })
                    return false
                }else{
                    this.isSubmitted = false
                    this.error_register = true
                    this.anchor()
                }
            },
            submit_forgot () {
                this.$v.forgotten_email.$touch();

                if (this.$v.forgotten_email.$invalid) {
                    return false
                }
                this.forgotten_email = this.$v.forgotten_email.$model
                Vue.axios.put('https://api.espace-jantes.com/mdpOublie', {
                    params: {
                        mail: this.forgotten_email,
                        websiteid: this.VUE_APP_SITE_ID === "1" ? "1" : "2"
                    }
                })
                    .then((response) => {
                        if(response.data.etat){
                            this.forgottenPassw = false
                            this.passwReseted = true
                            // console.log("Email mdp modifié")
                            return true
                        }
                        //Faire savoir a l'utilisateur
                        // console.log("L email n'est pas correct")
                        return false
                    })

            },
        },
    }
</script>

<style scoped>
    .background{
        background-size: contain;
        background-repeat: repeat;
    }
    .dark--text-lg /deep/ label {
        font-size: 13px;
        color: black;
    }
    .dark--text-xl /deep/ label {
        font-size: 16px;
        color: black;
    }
    .bulle--padding-top-xl {
        padding-top: 12%;
    }
    .bulle--padding-bottom-xl {
        padding-bottom: 11%;
    }
    .border-right{
        border-right: 1px solid rgba(137, 158, 168, 0.15)
    }
</style>
