<template>

    <v-app  class="" fluid >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>

        <v-main v-if="VUE_APP_SITE_ID === '1'">
            <v-col class="py-0">
                <v-row>
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 tc_blue font-weight-bold rotate">
                            Mentions Légales
                        </h1>
                    </v-col>
                    <v-col lg="5" >
                        <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5 tc_blue font-weight-bold ">
                            Mentions Légales
                        </h1>
                        <v-content class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                            <p class="font-weight-bold headline">Condition Générales d’utilisation / Mentions Légales</p>
                            <p>Nous vous invitons à lire attentivement les présentes conditions d’utilisation qui régissent la navigation sur ce site (ci-après les «<b> Conditions d’Utilisation</b> »).
                                En utilisant le site vous acceptez sans réserve les présentes Conditions d’Utilisation.
                                Pour toute demande relative à votre utilisation du Site, vous pouvez nous contacter à l’adresse suivante :
                                <br>
                                <a href="mailto:contact@espace-jantes.com" class="no_deco tc_black font-weight-bold">contact@espace-jantes.com</a>
                            </p>
                            <p class="font-weight-bold">MENTIONS LEGALES</p>
                            <span>URL du Site: espace-jantes.com</span>
                            <br>
                            <span>Contact : Courrier électronique : <a href="mailto:contact@espace-jantes.com" class="no_deco tc_black font-weight-bold">contact@espace-jantes.com</a>  – Numéro de téléphone : <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a></span>
                            <br>
                            <span>Numéro de déclaration CNIL : déclaration en cours</span>
                            <br>
                            <span>Directeur de la Publication : ARNAUD TREILLON</span>
                            <br>
                            <span>Editeur : Espace Jantes SARL au capital de 10000 euros, dont le siège social 23 Rue Benjamin Franklin 84120 Pertuis, inscrite au Registre du Commerce et des Sociétés d’Avignon sous le numéro 530 437 375 (ci-après la « Société Editrice » ou « Nous »).</span>
                            <br>
                            <span>Créateur du site : Société PUBLICOM, SARL au capital de 30 000 euros, dont le siège social est 10 route de Galice 13100 Aix-en-Provence, inscrite au Registre du Commerce et des Sociétés d’Aix-en-Provence sous le numéro 410 765 663</span>
                            <br>
                            <span>Hébergeur : PUBLICOM (voir info ci-dessus).</span>
                            <br>
                            <span>Numéro de téléphone : 04 42 99 01 20</span>
                            <br>
                            <span>Contact : www.publicom.fr</span>
                        </v-content>
                    </v-col>
                    <v-col class="bc_blue" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-img src="@/assets/AboutUs.png"/>
                    </v-col>
                </v-row>
            </v-col>


            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phone.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold tc_blue align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="3" xl="3" class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2" offset-lg="1" xl="3" lg="3">
                        <v-img style="position: absolute; width: 20%;" src="@/assets/illustration-man.png"/>
                    </v-col>
                </v-row>
            </v-container>


            <v-col class="bc_white tc_black font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
            <v-row style="margin-top: 5%">
                <v-col offset-lg="1" lg="7">
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">1. ACCÈS AU SITE</p>
                    <br>
                    <span>
                        Pour accéder et utiliser ce Site, vous devez avoir 18 ans ou plus. Si vous avez moins de 18 ans l’autorisation préalable de vos parents sera alors nécessaire.
                    </span>
                    <br>
                    <span>
                       Vous pouvez accéder librement et gratuitement au Site, sans inscription ni création de compte préalable.
                    </span>
                    <br>
                    <span>
                        L’accès au Site et/ou à certaines de ses Rubriques peut nécessiter l’utilisation de codes d’accès personnels.
                        Dans ce cas, il vous appartient de prendre les mesures propres à assurer le secret de ces codes.
                        Vous pouvez bien entendu les modifier à tout moment.
                        Toutefois, le nombre de tentatives d’accès au Site et/ou à certaines de ses Rubriques peut être limité afin de prévenir un usage frauduleux desdits codes.
                        Nous vous invitons à nous informer de toute utilisation frauduleuse dont vous pourriez éventuellement avoir connaissance.
                        Par ailleurs, en cas de non-respect des règles décrites dans les présentes Conditions d’Utilisation, Nous nous réservons le droit de suspendre votre accès.
                    </span>
                    <br>
                    <span>Les frais d’accès et d’utilisation du réseau de télécommunication restent néanmoins à votre charge.</span>
                </v-col>
            </v-row>

            <v-row>
                <v-col offset-lg="1" lg="10">
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">2. PROPRIÉTÉ INTELLECTUELLE</p>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-subtitle-1
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">a) Droits de propriété intellectuelle</p>
                    <span>
                        Le développement de ce Site a impliqué des investissements importants.
                        Le Site et chacun des éléments qui le composent (tels que marques, images, textes, vidéos, etc.) sont protégés au titre de la propriété intellectuelle.
                        Toute utilisation, reproduction ou représentation de du Site (en tout ou partie), sur quelque support que ce soit, à d’autres fins, et notamment commerciales, est interdite.
                    </span>
                    <br>
                    <span>
                        Nous pouvons être amenés à mettre à votre disposition sur ce Site des contenus que vous êtes autorisés à télécharger (ci-après le(s) « Contenu(s) Téléchargeable(s) »).
                        Nous vous concédons, pour vos seuls besoins personnels et privés, à titre gracieux et pour la durée légale de protection des droits de propriété intellectuelle telle que définie par les législations françaises et étrangères et les conventions internationales, un droit non exclusif et non transférable d’utilisation du Contenu Téléchargeable.
                        Toute reproduction, représentation, modification ou distribution du Contenu Téléchargeable est interdite.
                        En téléchargeant ou en utilisant ces Contenus Téléchargeables, vous vous engagez à les utiliser conformément aux présentes Conditions d’Utilisation.
                    </span>
                    <br>
                    <span>
                        Dans le cas où Nous mettons à votre disposition un Site permettant de retoucher une image, vous reconnaissez et acceptez que ce Site ne puisse être utilisé que pour un usage purement privé et conforme à sa destination.
                        Vous n’êtes pas autorisés à utiliser ce Site d’une manière qui porterait atteinte à l’honneur, à la réputation ou encore aux droits d’un tiers tel que décrit ci-dessous.
                    </span>
                    <br>
                    <br>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-subtitle-1
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">b) Droit des tiers</p>
                    <span>
                        Nous vous rappelons que vous devez impérativement obtenir/vous faire céder l’ensemble des autorisations et droits nécessaires auprès des différents ayants droit concernés par les éventuels contenus que vous souhaiteriez publier sur le Site, en ce compris l’ensemble des droits de propriété intellectuelle et/ou des droits de propriété littéraire, artistique et/ou industrielle, ainsi que les droits de la personnalité (et notamment droit à l’image), afin que vous puissiez exploiter paisiblement lesdits contenus.
                        A titre d’exemple, vous devez impérativement obtenir/vous faire céder les droits sur les contenus, et notamment les droits d’auteur sur les photographies, ainsi que sur l’ensemble des éléments apparaissant dans lesdits contenus, comme par exemple des éléments d’architecture, de design, des créations publicitaires, ou encore des créations vestimentaires
                    </span>
                    <br>
                    <br>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-subtitle-1
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">c) Contenus Utilisateurs</p>
                    <span>
                        Nous pouvons être amenés à mettre à disposition sur ce Site un espace destiné à accueillir des contenus des utilisateurs, comme par exemple des textes, photos, vidéos, avis, etc. (ci-après les « Contenus Utilisateurs »).
                    </span>
                    <br>
                    <span>En publiant un Contenu Utilisateur sur le Site, vous nous accordez par la présente une licence gratuite, irrévocable, non exclusive, mondiale et pour toute la durée des droits de propriété intellectuelle, littéraire et artistique et/ou industrielle, et notamment du droit d’auteur telle que définie par les législations françaises et étrangères et les conventions internationales (y compris toutes règlementations supplétives ou modificatives ultérieures), de reproduire, représenter, utiliser, copier, modifier, adapter, traduire, créer des œuvres dérivées, intégrer dans d’autres œuvres, distribuer ce Contenu Utilisateur (en tout ou en partie).</span>
                    <br>
                    <span>Cette utilisation est autorisée à toutes fins de communication interne ou externe, corporate ou financière, de publicité, ainsi qu’à toutes fins de relations publiques, et à des fins historiques ou d’archives, de notre société ou de ses filiales, de ses produits et/ou de ses marques, et notamment sur les supports suivants :</span>
                    <br>
                    <ul>
                        <li>Affichages tous format, en quantité illimitée,</li>
                        <li>Presse, nombre de parutions illimitées,</li>
                        <li>Edition, nombre de publications illimitées, notamment édition pour communication interne, y compris force de</li>
                        <li>vente et réseau de distribution (grossistes, détaillants, agents, etc..), événementiel, affichettes pour congrès, salons, stands…; communication BtoB, dans la presse professionnelle, pour un nombre de parutions et/ou des quantités illimités,</li>
                        <li>Edition électronique, informatique, numérique, multimédia, Internet et Intranets, tous sites (quel que soit le site et/ou le support, incluant les sites dits « réseaux sociaux » comme par exemple Facebook, Twitter, Youtube ou encore Dailymotion) nombre d’insertions et diffusion illimitées,</li>
                        <li>sur tout autre support publicitaire (Ci-après le(s) « Support(s) »).</li>
                    </ul>
                    <br>
                    <span>
                        Vous êtes informés que ces réseaux sociaux sont des plateformes appartenant à des tiers et qu’en conséquence la diffusion et l’utilisation du Contenu Utilisateur sur ces réseaux sociaux sont gouvernées par les conditions d’utilisation établies par ces tiers.
                        Ainsi, Nous ne pouvons être tenus responsables de toute utilisation du Contenu par Nous-mêmes ou par des tiers en conformité avec les conditions d’utilisation établies par les réseaux sociaux, et notamment, en termes de périmètre de droits concédés, de durée des droits et de suppression des Contenus.
                        Vous ferez votre affaire de toute réclamation de tiers portant sur l’utilisation du Contenu en conformité avec les conditions d’utilisation établies par les réseaux sociaux.</span>
                    <br>
                    <span>Par ailleurs, Nous vous rappelons notamment que tout Contenu est susceptible d’être référencé sur un moteur de recherche et, donc, d’être consulté par un public extérieur à celui du Site.</span>
                    <br>
                    <span>Cette autorisation Nous donne la possibilité d’adapter votre Contenu et/ou d’apporter à la fixation initiale toute précision que Nous jugerions utile dès lors que ledit Contenu n’altère pas votre image ou vos propos.</span>
                    <br>
                    <span>En outre, l’utilisation du Contenu Utilisateur peut être accompagnée de certaines informations dépersonnalisées telle que votre ville, votre pays ou votre âge, et/ou, le cas échéant si vous l’avez expressément autorisé pour ce faire, d’informations permettant votre identification telles que votre prénom, ou votre pseudo.</span>
                    <br>
                    <span>Les Contenus Utilisateurs que vous publiez sur ce Site relèvent de votre choix et de votre responsabilité exclusive. Toutefois, Nous vous rappelons que ces Contenus Utilisateurs ne devront pas être contraires à la législation en vigueur, aux bonnes mœurs et aux principes énoncés aux présentes. A ce titre, Nous nous réservons le droit de retirer à tout moment tout Contenu Utilisateur qui ne respecte pas les présentes Conditions d’Utilisation et notamment la Charte de Bonne Conduite.</span>
                    <br>
                    <span>Par ailleurs, si vous accédez à un Contenu Utilisateur créé par un autre utilisateur, vous devez respecter les droits de cet utilisateur et notamment veiller à ne pas reproduire et diffuser ledit Contenu publié sur d’autres supports sans l’accord préalable de l’utilisateur concerné.</span>

                </v-col>
            </v-row>

            <v-row>
                <v-col offset-lg="1" lg="10" >
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">3. CHARTE DE BONNE CONDUITE</p>

                    <span>
                        Nous défendons des valeurs de tolérance et de respect d’autrui.
                    </span>
                    <br>
                    <span>
                       Pour cette raison, en utilisant ce Site, vous vous interdisez de :
                    </span>
                    <br>
                    <ul>
                        <li>véhiculer des propos racistes, violents, xénophobes, malveillants, vulgaires, obscènes ou encore illicites,</li>
                        <li>diffuser un contenu préjudiciable, diffamatoire, non autorisé, malveillant, portant atteinte à la vie privée ou aux droits à l’image, incitant à la violence, à la haine raciale ou ethnique, ou un contenu constituant un outrage aux bonnes mœurs ou une incitation à la réalisation de certains délits et crimes ;</li>
                        <li>utiliser le Site pour faire de la politique, de la propagande ou du prosélytisme ;</li>
                        <li>publier des contenus publicitaires ou promotionnels  pour des produits et/ou services concurrents de la(ou les) marque(s) présentée(s) sur le Site ;</li>
                        <li>détourner le Site de sa finalité, notamment en l’utilisant comme un espace de rencontre ;</li>
                        <li>diffuser des informations permettant, directement ou indirectement l’identification nominative et précise d’une personne physique sans son consentement exprès et préalable, telles que notamment le nom de famille, l’adresse postale, l’adresse de courrier électronique, le numéro de téléphone ;</li>
                        <li>diffuser des informations ou contenus susceptibles de heurter la sensibilité des plus jeunes ;</li>
                        <li>intimider ou harceler autrui ;</li>
                        <li>mener des activités illégales, notamment portant atteinte aux titulaires de droits sur des logiciels, marques, photographies, images, textes, vidéos etc. ;</li>
                        <li>diffuser des contenus (notamment des photographies et des vidéos) représentant des mineurs.</li>
                    </ul>
                    <br>
                    <span>Si vous découvrez un Contenu Utilisateur faisant l’apologie des crimes contre l’humanité, incitant à la haine raciale et/ou à la violence ou concernant la pornographie enfantine, vous devez Nous en informer immédiatement à l’adresse de courrier électronique suivante :<a href="mailto:contact@espace-jantes.com" class="no_deco tc_black font-weight-bold">contact@espace-jantes.com</a>, soit en adressant un courrier circonstancié à l’adresse suivante : 23 Rue Benjamin Franklin 84120 Pertuis , en indiquant dans votre courriel/courrier la date à laquelle vous avez constaté ce contenu, votre identité, l’adresse URL du contenu litigieux, sa description et l’identifiant de son auteur.</span>
                    <br>
                    <span>Si vous estimez qu’un Contenu Utilisateur porte atteinte aux principes énoncés ci-dessus et à vos droits ou aux droits d’un tiers (par exemple contrefaçon, insulte, atteinte à la vie privée), vous pouvez le notifier à l’adresse de courrier électronique suivante :<a href="mailto:contact@espace-jantes.com" class="no_deco tc_black font-weight-bold">contact@espace-jantes.com</a> , soit en adressant un courrier circonstancié à l’adresse suivante : 23 Rue Benjamin Franklin 84120 Pertuis , en indiquant dans votre courriel/courrier la date à laquelle vous avez constaté ce contenu, votre identité, l’adresse URL du contenu litigieux, sa description et l’identifiant de son auteur.</span>
                    <br>
                    <span>Conformément aux dispositions de l’article 6-I-5 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, la notification devra contenir :</span>
                    <br>
                    <span>la date de la notification ;</span>
                    <br>
                    <ul>
                        <li>pour une personne physique : nom, prénoms, profession, domicile, nationalité, date et lieu de naissance ;</li>
                        <li>le nom et le domicile du destinataire ou, s’il s’agit d’une personne morale, sa dénomination et son siège social ;</li>
                        <li>la description des faits litigieux et leur localisation précise (ex : lien URL du contenu litigieux) ;</li>
                        <li>les motifs pour lesquels le contenu doit être retiré, comprenant la mention des dispositions légales et des justifications de faits ;</li>
                        <li>la copie de la correspondance adressée à l’auteur ou à l’éditeur des informations ou activités litigieuses demandant leur interruption, leur retrait ou leur modification, ou la justification de ce que l’auteur ou l’éditeur n’a pu être contacté.</li>
                    </ul>
                    <span>Toute notification incomplète sera susceptible de ne pas être traitée. ATTENTION : Le fait, pour toute personne, de présenter un contenu ou une activité comme étant illicite dans le but d’en obtenir le retrait ou d’en faire cesser la diffusion en lui présentant des informations fausses ou inexactes est puni d’une peine d’un an d’emprisonnement et de 15 000 euros d’amende.</span>
                </v-col>
            </v-row>

            <v-row>
                <v-col offset-lg="1" lg="10">
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">4. INFORMATIONS CONTENUES SUR LE SITE</p>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-subtitle-1
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">a) Dispositions générales</p>
                    <span>
                       Nous vous rappelons que des inexactitudes ou omissions peuvent apparaître dans les informations disponibles sur ce Site notamment du fait de tiers. Nous nous engageons à retirer les inexactitudes ou à compléter ces informations sur le Site dès que possible.
                    </span>
                    <br>
                    <span>
                    Informations sur les produits et services
                    </span>
                    <br>
                    <span>
                    Les produits et services qui vous sont présentés sur ce Site ne constituent pas une offre de vente mais une présentation générale de la gamme de produits et services que Nous distribuons dans le pays dans lequel ce Site est diffusé.
                    </span>
                    <br>
                    <br>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-subtitle-1
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">b) Liens hypertextes</p>
                    <span>
                        Les liens hypertextes mis en place sur ce Site peuvent vous conduire sur des sites Internet édités par des tiers dont Nous ne maîtrisons pas le contenu. En conséquence, et dans la mesure où les liens hypertextes ont été inclus sur ce Site uniquement afin de faciliter votre navigation sur Internet, la consultation de sites de tiers relèvera de votre choix et de votre responsabilité exclusive.
                    </span>
                    <br>
                </v-col>
            </v-row>
            <v-row>
                <v-col offset-lg="1" lg="10">
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">5. DONNÉES À CARACTÈRE PERSONNEL</p>

                    <span>
                    Nous pouvons être amenés à collecter des données à caractère personnel vous concernant, notamment lorsque vous  (i) vous souscrivez à un service, (ii) téléchargez un Contenu Téléchargeable, (iii) vous authentifiez, (iv) vous inscrivez à un jeu/concours, (v) Nous faites parvenir un courrier électronique, (vi) répondez à un sondage ou à une étude.
                    </span>
                    <br>
                    <span>
                    Pour plus d’informations sur le traitement de vos données à caractère personnel, Nous vous invitons à consulter notre Politique relative aux données à caractère personnel accessible ici.
                    </span>
                    <br>

                </v-col>
            </v-row>
            <v-row>
                <v-col offset-lg="1" lg="10">
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">6. COOKIES</p>
                    <span>
                        Les cookies sont de petits fichiers qui sont déposés sur votre dispositif lors de votre navigation sur le Site (comme par exemple les pages que vous avez consultées, la date et l’heure de la consultation, etc.) et qui pourront être lus lors de vos visites sur ce même Site.
                    </span>
                    <br>

                </v-col>
            </v-row>
            <v-row>
                <v-col offset-lg="1" lg="10">
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">7. MODIFICATION DU SITE ET DES CONDITIONS D’UTILISATION</p>

                    <span>
                    Nous pouvons être amenés à modifier les contenus et informations inclus dans ce Site ainsi que les présentes Conditions d’Utilisation, notamment afin de respecter toute nouvelle législation et/ou réglementation applicable et/ou afin d’améliorer le Site.
                    </span>
                    <br>
                    <span>
                    Toute modification sera portée à votre connaissance sur le Site avant sa prise d’effet dans le cadre des présentes Conditions d’Utilisation.
                    </span>
                    <br>
                    <span>
                    Sauf si la modification implique votre acceptation expresse, le fait que vous continuiez à utiliser le Site implique que vous acceptez les nouvelles Conditions d’Utilisation.
                    </span>
                    <br>

                </v-col>
            </v-row>
            <v-row>
                <v-col offset-lg="1" lg="10">
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">8. CRÉDITS</p>

                    <span>
                    Le Site a été développé pour la Société ESPACE JANTES (530 437 375) , 23 Rue Benjamin Franklin 84120 Pertuis par PUBLICOM (410 765 663), 10 route de galice – Aix-en-Provence.
                    </span>
                    <br>

                </v-col>
            </v-row>
            <v-row>
                <v-col offset-lg="1" lg="10">
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">9. AVERTISSEMENT</p>

                    <span>
                    Nous nous efforçons de maintenir le Site et le Contenu Téléchargeable accessibles à tout moment. En revanche, nous ne pouvons garantir la disponibilité et l’accessibilité permanente du Site. En effet, nous pouvons nous trouver dans l’obligation de suspendre momentanément l’accès partiel ou total au Site notamment pour des raisons techniques de maintenance.
                    </span>
                    <br>
                    <span>
                    Il est précisé également que le réseau Internet et les systèmes informatiques et de télécommunications ne sont pas exempts d’erreurs et que des interruptions et pannes peuvent survenir. Nous ne pouvons fournir aucune garantie à cet égard et ne saurait dès lors être responsable pour tous dommages inhérents auxdites utilisations du réseau Internet et des systèmes informatiques et de télécommunications, notamment sans que cette liste ne soit limitative :
                    </span>
                    <br>
                    <ul>
                        <li>la mauvaise transmission et/ou réception de toute donnée et/ou information sur Internet ;</li>
                        <li>une intrusion extérieure ou la présence de virus informatique ;</li>
                        <li>la défaillance de tout matériel de réception ou des lignes de communication ; et</li>
                        <li>tout autre dysfonctionnement du réseau Internet empêchant le bon fonctionnement de du Site.</li>
                    </ul>
                    <br>
                    <span>
                        Enfin, notre responsabilité ne pourra être engagée qu’au titre de dommages directs, à l’exclusion de tous autres dommages ou préjudices de quelque nature que ce soit. En particulier, les dommages indirects liés tels que, de manière non exhaustive, la perte de profits ou de revenus ou la perte de clientèle.
                    </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col offset-lg="1" lg="10">
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">10. DROIT APPLICABLE ET LITIGES</p>

                    <span>
                    Les Conditions d’Utilisation sont soumises à la loi française.
                    </span>
                    <br>
                    <span>
                    Pour tout problème, merci de nous contacter à l’adresse suivante : <a href="mailto:contact@espace-jantes.com" class="no_deco tc_black font-weight-bold">contact@espace-jantes.com</a>
                    </span>
                    <br>

                </v-col>
            </v-row>

        </v-col>
        </v-main>

        <v-main v-if="VUE_APP_SITE_ID === '2'">
            <v-col class="py-0">
                <v-row>
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 font-weight-bold rotate">
                            Mentions Légales
                        </h1>
                    </v-col>
                    <v-col lg="5" >
                        <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5 font-weight-bold ">
                            Mentions Légales
                        </h1>
                        <v-content class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                            <p class="font-weight-bold headline">Condition Générales d’utilisation / Mentions Légales</p>
                            <p>Nous vous invitons à lire attentivement les présentes conditions d’utilisation qui régissent la navigation sur ce site (ci-après les «<b> Conditions d’Utilisation</b> »).
                                En utilisant le site vous acceptez sans réserve les présentes Conditions d’Utilisation.
                                Pour toute demande relative à votre utilisation du Site, vous pouvez nous contacter à l’adresse suivante :
                                <br>
                                <a href="mailto:contact@distri-jantes.com" class="no_deco tc_black font-weight-bold">contact@distri-jantes.com</a>
                            </p>
                            <p class="font-weight-bold">MENTIONS LEGALES</p>
                            <span>URL du Site: distri-jantes.com</span>
                            <br>
                            <span>Contact : Courrier électronique : <a href="mailto:contact@distri-jantes.com" class="no_deco tc_black font-weight-bold">contact@distri-jantes.com</a>  – Numéro de téléphone : <a class="no_deco" href="tel:+33974191801">09 74 19 18 01</a></span>
                            <br>
                            <span>Numéro de déclaration CNIL : déclaration en cours</span>
                            <br>
                            <span>Directeur de la Publication : ARNAUD TREILLON</span>
                            <br>
                            <span>Editeur : Distri-jantes SAS au capital 1000€, dont le siège social 1175 Route d'Avignon 13090 Aix-en-Provence, inscrite au Registre du Commerce et des Sociétés d’Aix en Provence sous le numéro 878 147 115 (ci-après la « Société Editrice » ou « Nous »).</span>
                            <br>
                            <span>Siret : 87814711500017</span>
                            <br>
                            <span>Créateur du site : Société PUBLICOM, SARL au capital de 30 000 euros, dont le siège social est 10 route de Galice 13100 Aix-en-Provence, inscrite au Registre du Commerce et des Sociétés d’Aix-en-Provence sous le numéro 410 765 663</span>
                            <br>
                            <span>Hébergeur : PUBLICOM (voir info ci-dessus).</span>
                            <br>
                            <span>Numéro de téléphone : 04 42 99 01 20</span>
                            <br>
                            <span>Contact : www.publicom.fr</span>
                        </v-content>
                    </v-col>
                    <v-col class="pa-0" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-img src="@/assets/AboutUsDJ.png"/>
                    </v-col>
                </v-row>
            </v-col>


            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phoneDJ.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33974191801">09 74 19 18 01</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="5" offset-lg="1"  class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>

                </v-row>
            </v-container>


            <v-col class="bc_white tc_black font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
            <v-row style="margin-top: 5%">
                <v-col offset-lg="1" lg="7">
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">1. ACCÈS AU SITE</p>
                    <br>
                    <span>
                        Pour accéder et utiliser ce Site, vous devez avoir 18 ans ou plus. Si vous avez moins de 18 ans l’autorisation préalable de vos parents sera alors nécessaire.
                    </span>
                    <br>
                    <span>
                       Vous pouvez accéder librement et gratuitement au Site, sans inscription ni création de compte préalable.
                    </span>
                    <br>
                    <span>
                        L’accès au Site et/ou à certaines de ses Rubriques peut nécessiter l’utilisation de codes d’accès personnels.
                        Dans ce cas, il vous appartient de prendre les mesures propres à assurer le secret de ces codes.
                        Vous pouvez bien entendu les modifier à tout moment.
                        Toutefois, le nombre de tentatives d’accès au Site et/ou à certaines de ses Rubriques peut être limité afin de prévenir un usage frauduleux desdits codes.
                        Nous vous invitons à nous informer de toute utilisation frauduleuse dont vous pourriez éventuellement avoir connaissance.
                        Par ailleurs, en cas de non-respect des règles décrites dans les présentes Conditions d’Utilisation, Nous nous réservons le droit de suspendre votre accès.
                    </span>
                    <br>
                    <span>Les frais d’accès et d’utilisation du réseau de télécommunication restent néanmoins à votre charge.</span>
                </v-col>
            </v-row>

            <v-row>
                <v-col offset-lg="1" lg="10">
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">2. PROPRIÉTÉ INTELLECTUELLE</p>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-subtitle-1
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">a) Droits de propriété intellectuelle</p>
                    <span>
                        Le développement de ce Site a impliqué des investissements importants.
                        Le Site et chacun des éléments qui le composent (tels que marques, images, textes, vidéos, etc.) sont protégés au titre de la propriété intellectuelle.
                        Toute utilisation, reproduction ou représentation de du Site (en tout ou partie), sur quelque support que ce soit, à d’autres fins, et notamment commerciales, est interdite.
                    </span>
                    <br>
                    <span>
                        Nous pouvons être amenés à mettre à votre disposition sur ce Site des contenus que vous êtes autorisés à télécharger (ci-après le(s) « Contenu(s) Téléchargeable(s) »).
                        Nous vous concédons, pour vos seuls besoins personnels et privés, à titre gracieux et pour la durée légale de protection des droits de propriété intellectuelle telle que définie par les législations françaises et étrangères et les conventions internationales, un droit non exclusif et non transférable d’utilisation du Contenu Téléchargeable.
                        Toute reproduction, représentation, modification ou distribution du Contenu Téléchargeable est interdite.
                        En téléchargeant ou en utilisant ces Contenus Téléchargeables, vous vous engagez à les utiliser conformément aux présentes Conditions d’Utilisation.
                    </span>
                    <br>
                    <span>
                        Dans le cas où Nous mettons à votre disposition un Site permettant de retoucher une image, vous reconnaissez et acceptez que ce Site ne puisse être utilisé que pour un usage purement privé et conforme à sa destination.
                        Vous n’êtes pas autorisés à utiliser ce Site d’une manière qui porterait atteinte à l’honneur, à la réputation ou encore aux droits d’un tiers tel que décrit ci-dessous.
                    </span>
                    <br>
                    <br>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-subtitle-1
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">b) Droit des tiers</p>
                    <span>
                        Nous vous rappelons que vous devez impérativement obtenir/vous faire céder l’ensemble des autorisations et droits nécessaires auprès des différents ayants droit concernés par les éventuels contenus que vous souhaiteriez publier sur le Site, en ce compris l’ensemble des droits de propriété intellectuelle et/ou des droits de propriété littéraire, artistique et/ou industrielle, ainsi que les droits de la personnalité (et notamment droit à l’image), afin que vous puissiez exploiter paisiblement lesdits contenus.
                        A titre d’exemple, vous devez impérativement obtenir/vous faire céder les droits sur les contenus, et notamment les droits d’auteur sur les photographies, ainsi que sur l’ensemble des éléments apparaissant dans lesdits contenus, comme par exemple des éléments d’architecture, de design, des créations publicitaires, ou encore des créations vestimentaires
                    </span>
                    <br>
                    <br>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-subtitle-1
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">c) Contenus Utilisateurs</p>
                    <span>
                        Nous pouvons être amenés à mettre à disposition sur ce Site un espace destiné à accueillir des contenus des utilisateurs, comme par exemple des textes, photos, vidéos, avis, etc. (ci-après les « Contenus Utilisateurs »).
                    </span>
                    <br>
                    <span>En publiant un Contenu Utilisateur sur le Site, vous nous accordez par la présente une licence gratuite, irrévocable, non exclusive, mondiale et pour toute la durée des droits de propriété intellectuelle, littéraire et artistique et/ou industrielle, et notamment du droit d’auteur telle que définie par les législations françaises et étrangères et les conventions internationales (y compris toutes règlementations supplétives ou modificatives ultérieures), de reproduire, représenter, utiliser, copier, modifier, adapter, traduire, créer des œuvres dérivées, intégrer dans d’autres œuvres, distribuer ce Contenu Utilisateur (en tout ou en partie).</span>
                    <br>
                    <span>Cette utilisation est autorisée à toutes fins de communication interne ou externe, corporate ou financière, de publicité, ainsi qu’à toutes fins de relations publiques, et à des fins historiques ou d’archives, de notre société ou de ses filiales, de ses produits et/ou de ses marques, et notamment sur les supports suivants :</span>
                    <br>
                    <ul>
                        <li>Affichages tous format, en quantité illimitée,</li>
                        <li>Presse, nombre de parutions illimitées,</li>
                        <li>Edition, nombre de publications illimitées, notamment édition pour communication interne, y compris force de</li>
                        <li>vente et réseau de distribution (grossistes, détaillants, agents, etc..), événementiel, affichettes pour congrès, salons, stands…; communication BtoB, dans la presse professionnelle, pour un nombre de parutions et/ou des quantités illimités,</li>
                        <li>Edition électronique, informatique, numérique, multimédia, Internet et Intranets, tous sites (quel que soit le site et/ou le support, incluant les sites dits « réseaux sociaux » comme par exemple Facebook, Twitter, Youtube ou encore Dailymotion) nombre d’insertions et diffusion illimitées,</li>
                        <li>sur tout autre support publicitaire (Ci-après le(s) « Support(s) »).</li>
                    </ul>
                    <br>
                    <span>
                        Vous êtes informés que ces réseaux sociaux sont des plateformes appartenant à des tiers et qu’en conséquence la diffusion et l’utilisation du Contenu Utilisateur sur ces réseaux sociaux sont gouvernées par les conditions d’utilisation établies par ces tiers.
                        Ainsi, Nous ne pouvons être tenus responsables de toute utilisation du Contenu par Nous-mêmes ou par des tiers en conformité avec les conditions d’utilisation établies par les réseaux sociaux, et notamment, en termes de périmètre de droits concédés, de durée des droits et de suppression des Contenus.
                        Vous ferez votre affaire de toute réclamation de tiers portant sur l’utilisation du Contenu en conformité avec les conditions d’utilisation établies par les réseaux sociaux.</span>
                    <br>
                    <span>Par ailleurs, Nous vous rappelons notamment que tout Contenu est susceptible d’être référencé sur un moteur de recherche et, donc, d’être consulté par un public extérieur à celui du Site.</span>
                    <br>
                    <span>Cette autorisation Nous donne la possibilité d’adapter votre Contenu et/ou d’apporter à la fixation initiale toute précision que Nous jugerions utile dès lors que ledit Contenu n’altère pas votre image ou vos propos.</span>
                    <br>
                    <span>En outre, l’utilisation du Contenu Utilisateur peut être accompagnée de certaines informations dépersonnalisées telle que votre ville, votre pays ou votre âge, et/ou, le cas échéant si vous l’avez expressément autorisé pour ce faire, d’informations permettant votre identification telles que votre prénom, ou votre pseudo.</span>
                    <br>
                    <span>Les Contenus Utilisateurs que vous publiez sur ce Site relèvent de votre choix et de votre responsabilité exclusive. Toutefois, Nous vous rappelons que ces Contenus Utilisateurs ne devront pas être contraires à la législation en vigueur, aux bonnes mœurs et aux principes énoncés aux présentes. A ce titre, Nous nous réservons le droit de retirer à tout moment tout Contenu Utilisateur qui ne respecte pas les présentes Conditions d’Utilisation et notamment la Charte de Bonne Conduite.</span>
                    <br>
                    <span>Par ailleurs, si vous accédez à un Contenu Utilisateur créé par un autre utilisateur, vous devez respecter les droits de cet utilisateur et notamment veiller à ne pas reproduire et diffuser ledit Contenu publié sur d’autres supports sans l’accord préalable de l’utilisateur concerné.</span>

                </v-col>
            </v-row>

            <v-row>
                <v-col offset-lg="1" lg="10" >
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">3. CHARTE DE BONNE CONDUITE</p>

                    <span>
                        Nous défendons des valeurs de tolérance et de respect d’autrui.
                    </span>
                    <br>
                    <span>
                       Pour cette raison, en utilisant ce Site, vous vous interdisez de :
                    </span>
                    <br>
                    <ul>
                        <li>véhiculer des propos racistes, violents, xénophobes, malveillants, vulgaires, obscènes ou encore illicites,</li>
                        <li>diffuser un contenu préjudiciable, diffamatoire, non autorisé, malveillant, portant atteinte à la vie privée ou aux droits à l’image, incitant à la violence, à la haine raciale ou ethnique, ou un contenu constituant un outrage aux bonnes mœurs ou une incitation à la réalisation de certains délits et crimes ;</li>
                        <li>utiliser le Site pour faire de la politique, de la propagande ou du prosélytisme ;</li>
                        <li>publier des contenus publicitaires ou promotionnels  pour des produits et/ou services concurrents de la(ou les) marque(s) présentée(s) sur le Site ;</li>
                        <li>détourner le Site de sa finalité, notamment en l’utilisant comme un espace de rencontre ;</li>
                        <li>diffuser des informations permettant, directement ou indirectement l’identification nominative et précise d’une personne physique sans son consentement exprès et préalable, telles que notamment le nom de famille, l’adresse postale, l’adresse de courrier électronique, le numéro de téléphone ;</li>
                        <li>diffuser des informations ou contenus susceptibles de heurter la sensibilité des plus jeunes ;</li>
                        <li>intimider ou harceler autrui ;</li>
                        <li>mener des activités illégales, notamment portant atteinte aux titulaires de droits sur des logiciels, marques, photographies, images, textes, vidéos etc. ;</li>
                        <li>diffuser des contenus (notamment des photographies et des vidéos) représentant des mineurs.</li>
                    </ul>
                    <br>
                    <span>Si vous découvrez un Contenu Utilisateur faisant l’apologie des crimes contre l’humanité, incitant à la haine raciale et/ou à la violence ou concernant la pornographie enfantine, vous devez Nous en informer immédiatement à l’adresse de courrier électronique suivante :<a href="mailto:contact@distri-jantes.com" class="no_deco tc_black font-weight-bold">contact@distri-jantes.com</a>, soit en adressant un courrier circonstancié à l’adresse suivante : 23 Rue Benjamin Franklin 84120 Pertuis , en indiquant dans votre courriel/courrier la date à laquelle vous avez constaté ce contenu, votre identité, l’adresse URL du contenu litigieux, sa description et l’identifiant de son auteur.</span>
                    <br>
                    <span>Si vous estimez qu’un Contenu Utilisateur porte atteinte aux principes énoncés ci-dessus et à vos droits ou aux droits d’un tiers (par exemple contrefaçon, insulte, atteinte à la vie privée), vous pouvez le notifier à l’adresse de courrier électronique suivante :<a href="mailto:contact@distri-jantes.com" class="no_deco tc_black font-weight-bold">contact@distri-jantes.com</a> , soit en adressant un courrier circonstancié à l’adresse suivante : 23 Rue Benjamin Franklin 84120 Pertuis , en indiquant dans votre courriel/courrier la date à laquelle vous avez constaté ce contenu, votre identité, l’adresse URL du contenu litigieux, sa description et l’identifiant de son auteur.</span>
                    <br>
                    <span>Conformément aux dispositions de l’article 6-I-5 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, la notification devra contenir :</span>
                    <br>
                    <span>la date de la notification ;</span>
                    <br>
                    <ul>
                        <li>pour une personne physique : nom, prénoms, profession, domicile, nationalité, date et lieu de naissance ;</li>
                        <li>le nom et le domicile du destinataire ou, s’il s’agit d’une personne morale, sa dénomination et son siège social ;</li>
                        <li>la description des faits litigieux et leur localisation précise (ex : lien URL du contenu litigieux) ;</li>
                        <li>les motifs pour lesquels le contenu doit être retiré, comprenant la mention des dispositions légales et des justifications de faits ;</li>
                        <li>la copie de la correspondance adressée à l’auteur ou à l’éditeur des informations ou activités litigieuses demandant leur interruption, leur retrait ou leur modification, ou la justification de ce que l’auteur ou l’éditeur n’a pu être contacté.</li>
                    </ul>
                    <span>Toute notification incomplète sera susceptible de ne pas être traitée. ATTENTION : Le fait, pour toute personne, de présenter un contenu ou une activité comme étant illicite dans le but d’en obtenir le retrait ou d’en faire cesser la diffusion en lui présentant des informations fausses ou inexactes est puni d’une peine d’un an d’emprisonnement et de 15 000 euros d’amende.</span>
                </v-col>
            </v-row>

            <v-row>
                <v-col offset-lg="1" lg="10">
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">4. INFORMATIONS CONTENUES SUR LE SITE</p>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-subtitle-1
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">a) Dispositions générales</p>
                    <span>
                       Nous vous rappelons que des inexactitudes ou omissions peuvent apparaître dans les informations disponibles sur ce Site notamment du fait de tiers. Nous nous engageons à retirer les inexactitudes ou à compléter ces informations sur le Site dès que possible.
                    </span>
                    <br>
                    <span>
                    Informations sur les produits et services
                    </span>
                    <br>
                    <span>
                    Les produits et services qui vous sont présentés sur ce Site ne constituent pas une offre de vente mais une présentation générale de la gamme de produits et services que Nous distribuons dans le pays dans lequel ce Site est diffusé.
                    </span>
                    <br>
                    <br>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-subtitle-1
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">b) Liens hypertextes</p>
                    <span>
                        Les liens hypertextes mis en place sur ce Site peuvent vous conduire sur des sites Internet édités par des tiers dont Nous ne maîtrisons pas le contenu. En conséquence, et dans la mesure où les liens hypertextes ont été inclus sur ce Site uniquement afin de faciliter votre navigation sur Internet, la consultation de sites de tiers relèvera de votre choix et de votre responsabilité exclusive.
                    </span>
                    <br>
                </v-col>
            </v-row>
            <v-row>
                <v-col offset-lg="1" lg="10">
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">5. DONNÉES À CARACTÈRE PERSONNEL</p>

                    <span>
                    Nous pouvons être amenés à collecter des données à caractère personnel vous concernant, notamment lorsque vous  (i) vous souscrivez à un service, (ii) téléchargez un Contenu Téléchargeable, (iii) vous authentifiez, (iv) vous inscrivez à un jeu/concours, (v) Nous faites parvenir un courrier électronique, (vi) répondez à un sondage ou à une étude.
                    </span>
                    <br>
                    <span>
                    Pour plus d’informations sur le traitement de vos données à caractère personnel, Nous vous invitons à consulter notre Politique relative aux données à caractère personnel accessible ici.
                    </span>
                    <br>

                </v-col>
            </v-row>
            <v-row>
                <v-col offset-lg="1" lg="10">
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">6. COOKIES</p>
                    <span>
                        Les cookies sont de petits fichiers qui sont déposés sur votre dispositif lors de votre navigation sur le Site (comme par exemple les pages que vous avez consultées, la date et l’heure de la consultation, etc.) et qui pourront être lus lors de vos visites sur ce même Site.
                    </span>
                    <br>

                </v-col>
            </v-row>
            <v-row>
                <v-col offset-lg="1" lg="10">
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">7. MODIFICATION DU SITE ET DES CONDITIONS D’UTILISATION</p>

                    <span>
                    Nous pouvons être amenés à modifier les contenus et informations inclus dans ce Site ainsi que les présentes Conditions d’Utilisation, notamment afin de respecter toute nouvelle législation et/ou réglementation applicable et/ou afin d’améliorer le Site.
                    </span>
                    <br>
                    <span>
                    Toute modification sera portée à votre connaissance sur le Site avant sa prise d’effet dans le cadre des présentes Conditions d’Utilisation.
                    </span>
                    <br>
                    <span>
                    Sauf si la modification implique votre acceptation expresse, le fait que vous continuiez à utiliser le Site implique que vous acceptez les nouvelles Conditions d’Utilisation.
                    </span>
                    <br>

                </v-col>
            </v-row>
            <v-row>
                <v-col offset-lg="1" lg="10">
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">8. CRÉDITS</p>

                    <span v-if="VUE_APP_SITE_ID === '1'">
                    Le Site a été développé pour la Société ESPACE JANTES (530 437 375) , 23 Rue Benjamin Franklin 84120 Pertuis par PUBLICOM (410 765 663), 10 route de galice – Aix-en-Provence.
                    </span>
                    <span v-if="VUE_APP_SITE_ID === '2'">
                    Le Site a été développé pour la Société DISTRI JANTES (878 147 115) , 1175 Route d'Avignon 13090 Aix-en-Provence par PUBLICOM (410 765 663), 10 route de galice – Aix-en-Provence.
                    </span>
                    <br>

                </v-col>
            </v-row>
            <v-row>
                <v-col offset-lg="1" lg="10">
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">9. AVERTISSEMENT</p>

                    <span>
                    Nous nous efforçons de maintenir le Site et le Contenu Téléchargeable accessibles à tout moment. En revanche, nous ne pouvons garantir la disponibilité et l’accessibilité permanente du Site. En effet, nous pouvons nous trouver dans l’obligation de suspendre momentanément l’accès partiel ou total au Site notamment pour des raisons techniques de maintenance.
                    </span>
                    <br>
                    <span>
                    Il est précisé également que le réseau Internet et les systèmes informatiques et de télécommunications ne sont pas exempts d’erreurs et que des interruptions et pannes peuvent survenir. Nous ne pouvons fournir aucune garantie à cet égard et ne saurait dès lors être responsable pour tous dommages inhérents auxdites utilisations du réseau Internet et des systèmes informatiques et de télécommunications, notamment sans que cette liste ne soit limitative :
                    </span>
                    <br>
                    <ul>
                        <li>la mauvaise transmission et/ou réception de toute donnée et/ou information sur Internet ;</li>
                        <li>une intrusion extérieure ou la présence de virus informatique ;</li>
                        <li>la défaillance de tout matériel de réception ou des lignes de communication ; et</li>
                        <li>tout autre dysfonctionnement du réseau Internet empêchant le bon fonctionnement de du Site.</li>
                    </ul>
                    <br>
                    <span>
                        Enfin, notre responsabilité ne pourra être engagée qu’au titre de dommages directs, à l’exclusion de tous autres dommages ou préjudices de quelque nature que ce soit. En particulier, les dommages indirects liés tels que, de manière non exhaustive, la perte de profits ou de revenus ou la perte de clientèle.
                    </span>
                </v-col>
            </v-row>
            <v-row>
                <v-col offset-lg="1" lg="10">
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">10. DROIT APPLICABLE ET LITIGES</p>

                    <span>
                    Les Conditions d’Utilisation sont soumises à la loi française.
                    </span>
                    <br>
                    <span>
                    Pour tout problème, merci de nous contacter à l’adresse suivante : <a href="mailto:contact@distri-jantes.com" class="no_deco tc_black font-weight-bold">contact@distri-jantes.com</a>
                    </span>
                    <br>

                </v-col>
            </v-row>

        </v-col>
        </v-main>

        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "../headers/HomepageHeaderEj";
import HomepageHeaderDj from "../headers/HomepageHeaderDj";
import HomepageFooterEj from "../footer/HomepageFooterEj";
import HomepageFooterDj from "../footer/HomepageFooterDj";

    export default {
        name: "Livraison",
        components:{
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,

        },
        data: () => ({
            VUE_APP_SITE_ID:null
        }),
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        },
        metaInfo() {
            return {
                title: `Mentions Légales - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + `  - Jantes, Pneus & Accessoires`,
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `Mentions Légales - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {name: 'twitter:description', content: `Découvrez les Mentions Légales ` + (this.VUE_APP_SITE_ID === '1' ? `d'Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `de Distri Jantes l'expert en ligne des jantes`)},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `Mentions Légales -` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {property: 'og:site_name', content: this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`},
                    {property: 'og:type', content: 'Website'},
                    {property: 'og:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},
                    {property: 'og:description', content: `Découvrez les Mentions Légales ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    {name: 'robots', content: 'index,follow'}
                ]
            }
        },
    }
</script>

<style scoped>
    .border_left{
        border-left: 2px solid #1DE4AF
    }
    .rotate {
        transform: rotate(-90deg);
        margin-left: -3%;
        margin-top: 12%;
        position: absolute;
    }
</style>
