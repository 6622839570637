

const state = () =>({
    infoCommande:{},
})



const mutations = {
    setInfoCommande(state, config){

        state.infoCommande = config

    },

    deleteInfoCommande: state => {
        state.infoCommande = {}
    },
}


const actions = {

}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}