<template class="pa-0" data-app="true" >
    <v-app>
    <router-view></router-view>
    </v-app>

</template>

<script>

import axios from 'axios'
import SecureLS from "secure-ls";
// eslint-disable-next-line no-unused-vars
import Vue from "vue";
// eslint-disable-next-line no-unused-vars
import VueTidio from 'vue-tidio';

var ls = new SecureLS({ isCompression: false });

import $ from "jquery";


export default {
  name: 'App',
      metaInfo() {
          return {
              title: (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com - Jantes, Pneus & Accessoires` : `Distri-Jantes.com - Des jantes de qualité pour tous - Pneus et Accessoires`),
              meta: [
                  {name: 'twitter:card', content: 'summary'},
                  {name: 'twitter:title', content:  (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com - Jantes, Pneus & Accessoires` : `Distri-Jantes.com - Des jantes de qualité pour tous - Pneus et Accessoires`)},
                  {name: 'twitter:description', content: (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes` : `Distri Jantes` + `, le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.`)},
                  // image must be an absolute path // 280 par 150
                  {name: 'twitter:image', content: (this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``)},

                  {property: 'og:title', content: (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com - Jantes, Pneus & Accessoires` : `Distri-Jantes.com - Des jantes de qualité pour tous - Pneus et Accessoires`)},
                  {property: 'og:site_name', content: (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`)},
                  {property: 'og:type', content: 'Website'},
                  {property: 'og:image', content: (this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``)},
                  {property: 'og:description', content: (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                  {name: 'robots', content: 'index,follow'}
              ]
          }
    },
    created() {
        this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
    },
    //Hide scrollbar area if there is nothing to scroll
    mounted () {


        console.log('xs ',this.$vuetify.breakpoint.xs)
        console.log('sm ',this.$vuetify.breakpoint.sm)
        console.log('md ',this.$vuetify.breakpoint.md)
        console.log('lg ',this.$vuetify.breakpoint.lg)
        console.log('xl ',this.$vuetify.breakpoint.xl)

        console.log('$browserDetect.meta.ua ',this.$browserDetect.meta.ua)
        console.log('app id ',this.VUE_APP_SITE_ID)

        // console.log('this.$gtm.enabled();', this.$gtm.enabled())

        let elHtml = document.getElementsByTagName('html')[0]
        elHtml.style.overflowY = 'auto'


        // const token = localStorage.getItem('user-token')
        const token = ls.get('user-token')
        if (token) {
            axios.defaults.headers.common['Authorization'] = token
        }



    },
    destroyed () {
        let elHtml = document.getElementsByTagName('html')[0]
        elHtml.style.overflowY = null
    },
    updated() {
        // if( process.env.NODE_ENV.trim() !== "development") {
          console.log('process.env.VUE_APP_SITE_ID', process.env.VUE_APP_SITE_ID )
        // }
        if (this.$vuetify.breakpoint.smAndDown) {
          console.log('route', this.$route.name)
          if( this.$route.name !== "Etape1" &&
              this.$route.name !== "Etape2" &&
              this.$route.name !== "Etape3" &&
              this.$route.name !== "Etape4" &&
              this.$route.name !== "Etape5" &&
              this.$route.name !== "Etape6Success" &&
              this.$route.name !== "Etape6_bis" ){

              $('#tidio-chat').css({display:""})

          }else{
              $('#tidio-chat').css({display:"none"})
          }
      }

  }

};
</script>

<style>
    html { overflow-y: auto }


/*CSS font (overwite vuetify default)*/
    .v-application div {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .display-4 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .display-3 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .display-2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .display-1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .headline {
        font-family: 'Poppins', Arial !important;
        font-size: 1.4em!important;
    }
    .v-application div .title {
        font-family: 'Poppins', Arial !important;
        /*font-size: 1.22em !important;*/
    }
    .v-application div .subtitle-1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .subtitle-2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .body-1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .body-2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .caption {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .overline {
        font-family: 'Poppins', Arial !important;

    }

    .v-application div .text-h1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-xl-h1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-lg-h1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-md-h1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-sm-h1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-h1 {
        font-family: 'Poppins', Arial !important;

    }

    .v-application div .text-h2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-xl-h2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-lg-h2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-md-h2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-sm-h2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-h2 {
        font-family: 'Poppins', Arial !important;

    }

    .v-application div .text-h3 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-xl-h3 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-lg-h3 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-md-h3 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-sm-h3 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-h3 {
        font-family: 'Poppins', Arial !important;

    }

    .v-application div .text-h4 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-xl-h4 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-lg-h4 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-md-h4 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-sm-h4 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-h4 {
        font-family: 'Poppins', Arial !important;

    }

    .v-application div .text-h5 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-xl-h5 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-lg-h5 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-md-h5 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-sm-h5 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-h5 {
        font-family: 'Poppins', Arial !important;

    }

    .v-application div .text-h6 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-xl-h6 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-lg-h6 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-md-h6 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-sm-h6 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-h6 {
        font-family: 'Poppins', Arial !important;

    }

    .v-application div .subtitle-1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-xl-subtitle-1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-lg-subtitle-1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-md-subtitle-1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-sm-subtitle-1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-subtitle-1 {
        font-family: 'Poppins', Arial !important;

    }

    .v-application div .subtitle-2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-xl-subtitle-2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-lg-subtitle-2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-md-subtitle-2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-sm-subtitle-2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-subtitle-2 {
        font-family: 'Poppins', Arial !important;

    }

    .v-application div .body-1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-xl-body-1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-lg-body-1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-md-body-1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-sm-body-1 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-body-1 {
        font-family: 'Poppins', Arial !important;

    }

    .v-application div .body-2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-xl-body-2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-lg-body-2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-md-body-2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-sm-body-2 {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-body-2 {
        font-family: 'Poppins', Arial !important;

    }

    .v-application div .caption {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-xl-caption {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-lg-caption {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-md-caption {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-sm-caption {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-caption {
        font-family: 'Poppins', Arial !important;

    }

    .v-application div .overline {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-xl-overline {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-lg-overline {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-md-overline {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-sm-overline {
        font-family: 'Poppins', Arial !important;

    }
    .v-application div .text-overline {
        font-family: 'Poppins', Arial !important;

    }

    /*tc for text-color*/
    .tc_darkBlue {
        color:#232D4D!important;
    }
    .tc_turquoise {
        color: #1DE4AF!important;
    }
    .tc_lightBlue {
        color: #38A0F4!important;
    }
    .tc_lightGrey{
        color: #899EA8!important;
    }
    .tc_white{
        color: #ffffff!important;
    }
    .tc_blue{
        color: #1840DA!important;
    }
    .tc_grey{
        color:#49484F!important;
    }
    .tc_black{
        color:#000000!important;
    }
    .tc_red{
        color: #d00000 !important;
    }
    .tc_dark{
        color:#434246!important;
    }
    .tc_orange{
        color:#FC5C1F!important;
    }
    .tc_darkOrange{
        color:#FC5C1F!important;
    }


    /*bc for background-color*/
    .bc_lightGrey{
        background-color: #E5E5E5!important;
    }
    .bc_white{
        background-color: #ffffff!important;
    }
    .bc_darkBlue {
        background-color:#232D4D!important;
    }
    .bc_blue{
        background-color: #1840DA!important;;
    }
    .bc_grey{
        background-color:#49484F!important;
    }
    .bc_turquoise {
        background-color: #1DE4AF!important;
    }
    .bc_orange{
        background-color:#FC5C1F!important;
    }
    .bc_darkOrange{
        background-color:#de3d00!important;
    }
    .bc_darker{
        background-color:#373639!important;
    }


    /*align horizontally text && img*/
    .text__img{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .link{
        text-decoration: underline;
        cursor: pointer;
        color: #38A0F4
    }
    .linkDJ{
        text-decoration: underline;
        cursor: pointer;
        color: #FC5C1F
    }

    .pointer{
        cursor: pointer;
    }

    .no_deco{
        text-decoration: none;
        color: inherit!important;
    }

    .underline{
        text-decoration: underline;
    }



    /*!*CSS Select content etape 2*!*/
    /*.theme--light.v-select .v-select__selection--comma{*/
    /*    color:#1DE4AF!important;*/
    /*}*/


    /*Pagination style*/
    li.VuePagination__pagination-item.page-item.active{
        background-color: #1DE4AF;
    }

    li.VuePagination__pagination-item.page-item{
        display: inline;
        padding: 2% 1% 0 1%;
        border-radius: 15%;
    }

    li.VuePagination__pagination-item.page-item.VuePagination__pagination-item-prev-chunk.disabled :nth-child(1n+0){
        color: #E5E5E5!important;
    }
    li.VuePagination__pagination-item.page-item.VuePagination__pagination-item-prev-page.disabled :nth-child(1n+0){
        color: #E5E5E5!important;
    }
    li.VuePagination__pagination-item.page-item.VuePagination__pagination-item-next-page.disabled :nth-child(1n+0){
        color: #E5E5E5!important;
    }
    li.VuePagination__pagination-item.page-item.VuePagination__pagination-item-next-chunk.disabled :nth-child(1n+0){
        color: #E5E5E5!important;
    }


    ul.pagination.VuePagination__pagination{
        text-align: center!important;
    }

    ul.pagination.VuePagination__pagination{
        padding: 0;
    }

    a.page-link{
        text-decoration: none!important;
        color:#232D4D!important;
    }

    @media (min-width: 960px) {
        a.page-link {
            font-size: larger;
        }
    }

    a.page-link.active{
        color: white!important;
        font-weight: bold;
    }
    p.VuePagination__count{
        display: none;
    }


    .backgroud_gradient{
        background: rgb(255,255,255)!important;
        background: -moz-linear-gradient(left, rgb(255,255,255) 0%, rgb(255,255,255) 38%, rgb(255,255,255) 60%, rgb(24,64,218) 60%, rgb(24,64,218) 100%)!important;
        background: -webkit-linear-gradient(left, rgb(255,255,255) 0%,rgb(255,255,255) 38%,rgb(255,255,255) 60%,rgb(24,64,218) 60%,rgb(24,64,218) 100%)!important;
        background: linear-gradient(to right, rgb(255,255,255) 0%,rgb(255,255,255) 38%,rgb(255,255,255) 60%,rgb(24,64,218) 60%,rgb(24,64,218) 100%)!important;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#1840da',GradientType=1 )!important;
    }



</style>
