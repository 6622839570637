<template>
    <v-app >

        <HomepageHeaderEj class="backgroud_gradient" v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>


        <v-col offset="2" cols="9" class="bc_white " v-if="$vuetify.breakpoint.mdAndUp">
            <v-img v-if="loaderStatus === true && VUE_APP_SITE_ID === '1'" src="@/assets/loader.gif" style="max-width: 40%; margin: auto"/>
            <v-img v-if="loaderStatus === true && VUE_APP_SITE_ID === '2'" src="@/assets/loaderDJ.gif" style="max-width: 40%; margin: auto"/>
            <v-row>
                <v-col cols="5">
                    <v-img :src="infoJante.photo_1" :alt='"Jante - " + infoJante.marque + " - " + infoJante.modele + " - " + Vehicule.marque + " - " + Vehicule.modele ' :title='"Jante - " + infoJante.marque + " - " + infoJante.modele + " - " + Vehicule.marque + " - " + Vehicule.modele '/>
                </v-col>

                <v-col v-if="loaderStatus === false" offset="1" cols="5" class="bc_lightGrey" style="border-radius: 20px">
                    <p class="font-weight-bold text-h4" style="border-bottom: 1px solid black">4 JANTES {{ infoJante.modele }} {{infoJante.couleur}}</p>
                    <v-row>
                        <v-col cols="6" class="tc_grey subtitle-1">
                            <p>Données spécifiques :</p>

                            <ul>
                                <li>Marque : {{ infoJante.marque }}</li>
                                <li>Modele : {{ infoJante.modele }}</li>
                                <li>Couleur : {{ infoJante.couleur }}</li>
                            </ul>
                        </v-col>
                        <v-col cols="6" class="text-right">
                            <p class="font-weight-bold text-h3 tc_blue" v-if=" !isNaN(getRoundPrice(infoJante.public_price, null, '4jantes'))">{{ getRoundPrice(infoJante.public_price, null, "4jantes") }} €</p>
                            <p class="font-weight-bold text-h5">OU</p>
                            <span class="font-weight-bold text-h3 " :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}" v-if="!isNaN(getRoundPrice(infoJante.public_price, null, 'COFIDIS'))"> {{ getRoundPrice(infoJante.public_price, null, "COFIDIS") }} €</span>
                            <v-col offset="8" cols="4" class="py-0" >
                                <v-img src="@/assets/logo/logo-Cofidis-Pay_4xcb_resized.png" alt='Cofidis - 4xcb' title='Cofidis - 4xcb'></v-img>
                            </v-col>
                            <br>
                            <span class="tc_grey caption">TVA comprise à 20%</span><br>
                            <p class="tc_grey caption">Prix pour 4 jantes et accessoires de montage</p>
                            <p>Livraison gratuite <v-icon color="black">mdi mdi-truck</v-icon></p>
                            <p class="font-weight-bold title" :class="{'tc_darkBlue' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}" >En Stock</p>
                        </v-col>
                    </v-row>
                   <p> Veuillez sélectionner votre véhicule afin de vérifier la compatibilité de la jante.</p>
                    <v-btn @click="$router.push({name:'Home'})" width="100%">Choisir mon vehicule</v-btn>
                </v-col>
                <v-col offset="1" cols="10">
                    <v-content v-if="VUE_APP_SITE_ID === '1'" class="bc_white">

                        <v-col offset-lg="1" offset-xl="2" lg="10" xl="8">
                            <v-row >
                                <v-col v-for="card in cardsEJ" :key="card.title" lg="3" xl="3" md="3" sm="3" cols="6" class="px-2" >
                                    <v-card style=" height: 100%" hover >
                                        <v-card-text class="text-center align-self-center justify-center">
                                            <v-content class="px-5">
                                                <v-img  :src="card.img"></v-img>
                                            </v-content>
                                            <div class="
                                        font-weight-bold
                                        text-xl-h5
                                        text-lg-h6
                                        text-md-subtitle-1
                                        text-sm-body-2
                                        text-caption" style="margin-top: 15%" :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">
                                                {{card.title}}
                                            </div>
                                            <p class="text--primary
                                        font-weight-regular
                                        text-xl-h5
                                        text-lg-subtitle-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption" style="color:black;padding-top:4%">
                                                {{card.text}}
                                            </p>
                                        </v-card-text>
                                    </v-card>

                                </v-col>

                            </v-row>
                        </v-col>
                    </v-content>

                    <v-content v-else-if="VUE_APP_SITE_ID === '2'" class="bc_white">

                        <v-col offset-lg="1" offset-xl="2" lg="10" xl="8">
                            <v-row >
                                <v-col v-for="card in cardsDJ" :key="card.title" lg="3" xl="3" md="3" sm="3" cols="6" class="px-2" >
                                    <v-card style=" height: 100%" hover >
                                        <v-card-text class="text-center align-self-center justify-center">
                                            <v-content class="px-5">
                                                <v-img  :src="card.img"></v-img>
                                            </v-content>
                                            <div class="tc_orange
                                        font-weight-bold
                                        text-xl-h5
                                        text-lg-h6
                                        text-md-subtitle-1
                                        text-sm-body-2
                                        text-caption" style="margin-top: 15%">
                                                {{card.title}}
                                            </div>
                                            <p class="text--primary
                                        font-weight-regular
                                        text-xl-h6
                                        text-lg-subtitle-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption" style="color:black;padding-top:4%">
                                                {{card.text}}
                                            </p>
                                        </v-card-text>
                                    </v-card>

                                </v-col>

                            </v-row>
                        </v-col>
                    </v-content>

                </v-col>
            </v-row>
        </v-col>

        <v-col cols="12" class="bc_white " v-if="$vuetify.breakpoint.smAndDown">
            <v-col>
                <p class="font-weight-bold text-h4" style="border-bottom: 1px solid black">4 JANTES {{ infoJante.modele }} {{infoJante.couleur}}</p>

                <v-row cols="5">
                    <v-img :src="infoJante.photo_1" :alt='"Jante - " + infoJante.marque + " - " + infoJante.modele + " - " + Vehicule.marque + " - " + Vehicule.modele ' :title='"Jante - " + infoJante.marque + " - " + infoJante.modele + " - " + Vehicule.marque + " - " + Vehicule.modele '/>
                </v-row>
                <v-row  class="bc_lightGrey" style="border-radius: 20px">
                    <v-col cols="12">
                        <v-col cols="12" class="tc_grey subtitle-1">
                            <p>Données spécifiques :</p>
                            <ul>
                                <li>Marque : {{ infoJante.marque }}</li>
                                <li>Modele : {{ infoJante.modele }}</li>
                                <li>Couleur : {{ infoJante.couleur }}</li>
                            </ul>
                        </v-col>
                        <v-col cols="12" class="">
                            <p class="font-weight-bold text-h3 tc_blue" v-if="!isNaN(getRoundPrice(infoJante.public_price, null, '4jantes'))">{{ getRoundPrice(infoJante.public_price, null, "4jantes") }} €</p>
                            <p class="font-weight-bold text-h5">OU</p>
                            <span class="font-weight-bold text-h3 " :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}" v-if="!isNaN(getRoundPrice(infoJante.public_price, null, 'COFIDIS'))"> {{ getRoundPrice(infoJante.public_price, null, "COFIDIS") }} €</span>
                            <v-col cols="4" class="pa-0" >
                                <v-img src="@/assets/logo/logo-Cofidis-Pay_4xcb_resized.png" alt='Cofidis - 4xcb' title='Cofidis - 4xcb'></v-img>
                            </v-col>
                            <br>
                            <span class="tc_grey caption">TVA comprise à 20%</span><br>
                            <p class="tc_grey caption">Prix pour 4 jantes et accessoires de montage</p>
                            <p>Livraison gratuite <v-icon color="black">mdi mdi-truck</v-icon></p>
                            <p class="font-weight-bold title" :class="{'tc_darkBlue' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">En Stock</p>
                        </v-col>
                    </v-col>
                    <p class="px-2"> Veuillez sélectionner votre véhicule afin de vérifier la compatibilité de la jante.</p>
                    <v-btn @click="$router.push({name:'Home'})" width="100%">Choisir mon vehicule</v-btn>
                </v-row>


                <v-content v-if="VUE_APP_SITE_ID === '1'" class="bc_white">

                    <v-col offset-lg="1" offset-xl="2" lg="10" xl="8">
                        <v-row >
                            <v-col v-for="card in cardsEJ" :key="card.title" lg="3" xl="3" md="3" sm="3" cols="6" class="px-2" >
                                <v-card style=" height: 100%" hover >
                                    <v-card-text class="text-center align-self-center justify-center">
                                        <v-content class="px-5">
                                            <v-img  :src="card.img"></v-img>
                                        </v-content>
                                        <div class="
                                        font-weight-bold
                                        text-xl-h5
                                        text-lg-h6
                                        text-md-subtitle-1
                                        text-sm-body-2
                                        text-caption" :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}" style="margin-top: 15%">
                                            {{card.title}}
                                        </div>
                                        <p class="text--primary
                                        font-weight-regular
                                        text-xl-h5
                                        text-lg-subtitle-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption" style="color:black;padding-top:4%">
                                            {{card.text}}
                                        </p>
                                    </v-card-text>
                                </v-card>

                            </v-col>

                        </v-row>
                    </v-col>
                </v-content>

                <v-content v-else-if="VUE_APP_SITE_ID === '2'" class="bc_white">

                    <v-col offset-lg="1" offset-xl="2" lg="10" xl="8">
                        <v-row >
                            <v-col v-for="card in cardsDJ" :key="card.title" lg="3" xl="3" md="3" sm="3" cols="6" class="px-2" >
                                <v-card style=" height: 100%" hover >
                                    <v-card-text class="text-center align-self-center justify-center">
                                        <v-content class="px-5">
                                            <v-img  :src="card.img"></v-img>
                                        </v-content>
                                        <div class="tc_orange
                                        font-weight-bold
                                        text-xl-h5
                                        text-lg-h6
                                        text-md-subtitle-1
                                        text-sm-body-2
                                        text-caption" style="margin-top: 15%">
                                            {{card.title}}
                                        </div>
                                        <p class="text--primary
                                        font-weight-regular
                                        text-xl-h6
                                        text-lg-subtitle-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption" style="color:black;padding-top:4%">
                                            {{card.text}}
                                        </p>
                                    </v-card-text>
                                </v-card>

                            </v-col>

                        </v-row>
                    </v-col>
                </v-content>



            </v-col>
        </v-col>

        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>
    </v-app>
</template>

<script>
    import HomepageHeaderEj from "./headers/HomepageHeaderEj";
    import HomepageHeaderDj from "./headers/HomepageHeaderDj";
    import HomepageFooterEj from "./footer/HomepageFooterEj";
    import HomepageFooterDj from "./footer/HomepageFooterDj";

    import {mapState} from "vuex";

    import cardCbEJ from "@/assets/card-cbEJ.svg";
    import cardDeliveryEJ from "@/assets/card-deliveryEJ.svg";
    import cardGuaranteeEJ from "@/assets/card-guaranteeEJ.svg";
    import cardRefundedEJ from "@/assets/card-refundedEJ.svg";

    import cardCbDJ from "@/assets/card-cbDJ.svg";
    import cardDeliveryDJ from "@/assets/card-deliveryDJ.svg";
    import cardGuaranteeDJ from "@/assets/card-guaranteeDJ.svg";
    import cardRefundedDJ from "@/assets/card-refundedDJ.svg";

    import SecureLS from "secure-ls";





    import algoliasearch from "algoliasearch";
    import Vue from "vue";
    import BagueCentrage from "@/assets/accessoires/bague-de-centrage.jpg";
    var ls = new SecureLS({ isCompression: false });

    export default {
        name: "DetailsJantes",
        components: {
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,
        },
        data: () => ({
            optionDeMonte: [],
            optionFront:[],
            optionRear:[],
            photoPrincipal:"",
            photoSecond:"",
            photoThird:"",
            zoomerOptions : {
                zoomFactor: 3,
                pane: "pane",
                hoverDelay: 300,
                namespace: "zoomer-right",
                move_by_click: false,
                scroll_items: 2,
                choosed_thumb_border_color: "black",
                scroller_position: "left",
                zoomer_pane_position: "left"
            },
            bagueAlesage:"",
            infoJante: {},
            infoJanteArriere:'',
            selected: '9 pouces',
            headerTitle:1,
            configurateurJson: {},
            monte: 'par4',


            colorisDisponible: [],
            dimensions:[],
            dimensionsPossibleChassis: [],
            dimensionConfigurateur:"",
            filter_default: { value: '17 pouces'},
            filters: [
                { value: '17 pouces'},
                { value: '18 pouces'},
                { value: '19 pouces'},
                { value: '20 pouces'},
            ],
            currentColorIndex:0,
            currentColor: '',
            colors : [
                {id: '0', name: 'Black mat'},
                {id: '1', name: 'Antracite'},
                {id: '2', name: 'Silver'},
            ],
            options:[
                {name:'9 pouces'},
                {name:'10 pouces'},
                {name:'11 pouces'},
                {name:'12 pouces'},
            ],
            zoomPhoto:false,
            params:{},
            triggerUpdate: 1,
            cardsEJ: [
                {title:'Facilités de paiement',text:'En 4x par CB, sans justificatifs\n Accord immédiat!',img:cardCbEJ},
                {title:'Livraison gratuite', text:'Avec suivi des commandes', img:cardDeliveryEJ},
                {title:'Satisfait ou remboursé', text:'Délai de 14 jours de rétractation', img:cardRefundedEJ},
                {title:'Jantes garanties et controlées', text:'2 ans', img:cardGuaranteeEJ}
            ],
            cardsDJ: [
                {title:'Facilités de paiement',text:'En 4x par CB, sans justificatifs\n Accord immédiat!',img:cardCbDJ},
                {title:'Livraison gratuite', text:'Avec suivi des commandes', img:cardDeliveryDJ},
                {title:'Satisfait ou remboursé', text:'Délai de 14 jours de rétractation', img:cardRefundedDJ},
                {title:'Jantes garanties et controlées', text:'2 ans', img:cardGuaranteeDJ}
            ],
            VUE_APP_SITE_ID:null
        }),
        beforeCreate(){
            this.monteType =  (this.$route.params.decale).toString() === '0' ? 'par4' : 'decale'
        },
        mounted() {
            this.infoJante = {marque: this.$route.params.marque, modele: this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur}

            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID

            if( process.env.NODE_ENV.trim() !== "development") {
                window.dataLayer.push({
                    'event': 'Pageview',
                    'pagePath': '/etape1',
                    'pageTitle': 'Etape1'
                });
            }

            this.monteType =  (this.$route.params.decale).toString() === '0' ? 'par4' : 'decale'
        },
        created() {
            this.$store.commit('loader/setLoader', true)
            this.$store.commit('loader/setLoaderPrix', true)

            this.getOptionchassis()
            setTimeout(() =>{ this.methodOncreated() }, 2000);


        },
        methods: {

            getRoundPriceMonteDecale: function (PrixjanteAv, PrixjanteArr, PrixPneuAv, PrixPneuArr, type){
                let price

                if(this.isProfessional){

                    let taux = (1 - parseFloat('0.' + this.tauxRemise))
                    let tmp = Math.pow(10, 2);
                    taux = (Math.round( taux * tmp )/tmp).toFixed(2)

                    PrixjanteAv = PrixjanteAv * taux
                    PrixjanteArr = PrixjanteArr * taux
                    PrixPneuAv = PrixPneuAv * taux
                    PrixPneuArr = PrixPneuArr * taux

                }

                switch(type){
                    case('4jantes decale'):
                        if(PrixjanteAv && PrixjanteArr){
                            price  = (PrixjanteAv * 2) + (PrixjanteArr * 2)
                        }
                        break;
                    case('4pneus decale'):
                        if(PrixPneuAv && PrixPneuArr){
                            price  = (((PrixPneuAv + 10) * 2) + ((PrixPneuArr + 10) * 2))
                        }
                        break;
                    case('2jantesAv'):
                        if(PrixjanteAv){
                            price  = (PrixjanteAv * 2)
                        }
                        break;
                    case('2jantesArr'):
                        if(PrixjanteArr){
                            price  = (PrixjanteArr * 2)
                        }
                        break;
                    case('2pneusAv'):
                        if(PrixPneuAv){
                            price  = ((PrixPneuAv + 10) * 2)
                        }
                        break;
                    case('2pneusArr'):
                        if(PrixPneuArr){
                            price  = ((PrixPneuArr + 10) * 2)
                        }
                        break;
                    case('SOUS TOTAL DECALE'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires)
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires)
                        }
                        break;
                    case('TOTAL'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires + 9.90)
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires + 9.90)
                        }
                        break;
                    case('TVA'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires) * 0.2
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires) * 0.2
                        }
                        break;
                    case('COFIDIS DECALE'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = (((PrixjanteAv * 2) + (PrixjanteArr * 2)  +  (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires) * 1.022) /4
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = (((PrixjanteAv * 2) + (PrixjanteArr * 2)  + this.TotalPriceAccessoires) * 1.022) /4
                        }
                        break;
                }
                var tmp = Math.pow(10, 2);
                return (Math.round( price * tmp )/tmp).toFixed(2)
            },
            getRoundPrice: function (Prixjante, PrixPneu, type){
                let price

                if(this.isProfessional){

                    let taux = (1 - parseFloat('0.' + this.tauxRemise))
                    let tmp = Math.pow(10, 2);
                    taux = (Math.round( taux * tmp )/tmp).toFixed(2)

                    Prixjante = Prixjante * taux
                    PrixPneu = PrixPneu * taux

                }

                switch(type){
                    case('4jantes'):
                        if(Prixjante){
                            price  = (Prixjante * 4)
                        }
                        break;
                    case('2jantes'):
                        if(Prixjante){
                            price  = (Prixjante * 2)
                        }
                        break;
                    case('4pneus'):
                        if(PrixPneu){
                            price  = ((PrixPneu + 10) * 4)
                        }
                        break;
                    case('2pneus'):
                        if(PrixPneu){
                            price  = ((PrixPneu + 10) * 2)
                        }
                        break;
                    case('SOUS TOTAL'):
                        if(Prixjante && PrixPneu){
                            price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires)
                        }else if(Prixjante){
                            price  = ((Prixjante * 4) + this.TotalPriceAccessoires)
                        }
                        break;
                    case('TOTAL'):
                        if(Prixjante && PrixPneu){
                            price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90)
                        }else if(Prixjante){
                            price  = ((Prixjante * 4) + this.TotalPriceAccessoires + 9.90)
                        }
                        break;
                    case('TVA'):
                        if(Prixjante && PrixPneu){
                            price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires ) * 0.2
                        }else if(Prixjante){
                            price  = ((Prixjante * 4) + this.TotalPriceAccessoires ) * 0.2
                        }
                        break;
                    case('COFIDIS'):
                        if(Prixjante && PrixPneu){
                            price  = (((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires) * 1.022) /4
                        }else if(Prixjante){
                            // price  = (((Prixjante * 4) + this.TotalPriceAccessoires) * 1.022) /4
                            price  = ((Prixjante * 4) * 1.022) /4
                        }
                        break;
                }

                var tmp = Math.pow(10, 2);
                return (Math.round( price * tmp )/tmp).toFixed(2)
            },
            setOnglet(item, value){
                // console.log('setOnglet',value, item)

                switch(value){
                    case"4jantes":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : item, jantesArr : item})
                        this.infoJante = item
                        this.currentColor = this.colors[0].name;
                        this.photoPrincipal = this.infoJante.photo_1
                        this.triggerUpdate ++
                        break
                    case"jantesAv":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : item, jantesArr : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr})
                        this.triggerUpdate ++
                        break
                    case"jantesArr":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv, jantesArr : item})
                        this.triggerUpdate ++
                        break
                    case"4pneus":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : item, pneusArr : item})
                        this.triggerUpdate ++
                        break
                    case"pneusAv":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : item, pneusArr : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr})
                        this.triggerUpdate ++
                        break
                    case"pneusArr":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv, pneusArr : item})
                        this.triggerUpdate ++
                        break
                    case"accessoires":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires', {accessoires: item})
                        this.triggerUpdate ++
                        break
                    case"indexAccessoires":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires', {accessoires: ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').accessoires, selected: item })
                        this.triggerUpdate ++
                        break
                    case"modelePossibleAuCarre":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre', item)
                        break
                    case"modelePossibleFront":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront', item)
                        break
                    case"modelePossibleRear":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear', item)
                        break
                }

                ls.set('last_updated', this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale)

            },
            getOnglet(value){

                switch(value){
                    case'jantesAv':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv
                    case 'jantesArr':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr
                    case 'pneusAv':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv
                    case 'pneusArr':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr
                    case 'accessoires':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').accessoires
                    case 'modelePossibleAuCarre':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre')
                    case 'modelePossibleFront':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront')
                    case 'modelePossibleRear':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear')

                }

            },
            onClickOutside:function(){
                this.zoomPhoto = false
            },
            selectedJantes(color) {
                console.log('change color')
                this.photoPrincipal = color.image
                this.photoSecond = color.image2
                this.photoThird = color.image2
                // console.log('photoPrincipal', this.photoPrincipal)
                // console.log('photoSecond', this.photoSecond)
                // console.log('photoThird', this.photoThird)
                // console.log('color', color)

                var monteType = (this.$route.params.decale).toString() === '0' ? 'par4' : 'decale'
                if(monteType == 'decale') {
                    var largeurMinAv = this.infoJante.largeurMinAv
                    var largeurMaxArr = this.infoJante.largeurMaxArr
                    var offsetFront = this.infoJante.offsetFront
                    var offsetRear = this.infoJante.offsetRear
                }

                const client = algoliasearch('IXJF8AJTH7', '27829f3918903605b2f7665097a13737');
                const index = client.initIndex('dev_EJ');

                index.search('', {
                    filters:'objectID: "' + color.reference  +'"'
                })
                    .then(({ hits }) => {
                        this.infoJante = hits[0]

                        if(monteType == 'decale') {
                            this.infoJante.largeurMinAv = largeurMinAv
                            this.infoJante.largeurMaxArr = largeurMaxArr
                            this.infoJante.offsetFront = offsetFront
                            this.infoJante.offsetRear = offsetRear


                            var couleurFront = []
                            var couleurRear = []
                            var largeurMini = []
                            var largeurMaxi = []

                            this.getOnglet('modelePossibleFront').forEach(elementFront => {
                                if(largeurMini.length == 0 || (largeurMini[0].largeur > elementFront.largeur  )){
                                    if(this.infoJante.couleur == elementFront.couleur){
                                        // if(largeurMini.length == 0 || largeurMini[0].offset < elementFront.offset) {
                                        largeurMini = []
                                        largeurMini.push(elementFront)
                                        // console.log('elementFront')
                                        // console.log(elementFront)
                                    }
                                }
                                const resultat = couleurFront.find( couleur => couleur.couleur === elementFront.couleur);

                                if( resultat == 'undefined' || resultat == undefined){
                                    var infoCouleur = {}
                                    var couleurFinale =  elementFront.couleur
                                    // var couleurFinale =  elementFront.couleur.replace(/\s/g, "+")
                                    infoCouleur.couleurFinale = couleurFinale
                                    infoCouleur.couleur = elementFront.couleur
                                    infoCouleur.image = elementFront.photo_1
                                    infoCouleur.reference = elementFront.objectID
                                    couleurFront.push(infoCouleur)
                                }
                            })
                            this.getOnglet('modelePossibleRear').forEach(elementRear => {
                                if(largeurMaxi.length == 0 || largeurMaxi[0].largeur < elementRear.largeur){
                                    if(largeurMaxi.length == 0 || (largeurMaxi[0].offset < elementRear.offset   ) ) {
                                        if(this.infoJante.couleur == elementRear.couleur){
                                            largeurMaxi = []
                                            largeurMaxi.push(elementRear)
                                        }
                                    }
                                }
                                const resultat = couleurRear.find( couleur => couleur.couleur === elementRear.couleur);

                                if( resultat == 'undefined' || resultat == undefined){
                                    var infoCouleur = {}
                                    var couleurFinale =  elementRear.couleur
                                    // var couleurFinale =  elementRear.couleur.replace(/\s/g, "+")
                                    infoCouleur.couleurFinale = couleurFinale
                                    infoCouleur.couleur = elementRear.couleur
                                    infoCouleur.image = elementRear.photo_1
                                    infoCouleur.reference = elementRear.objectID
                                    couleurRear.push(infoCouleur)
                                }
                            })

                            this.infoJante.public_price = largeurMini[0].public_price
                            this.infoJante.public_price_rear = largeurMaxi[0].public_price
                        }
                        // localStorage.setItem("infoJantes", JSON.stringify(this.infoJante));
                        // ls.set("infoJantes", JSON.stringify(this.infoJante));

                        // this.$store.commit('infoJantes/setJante', {'Av': this.infoJante})
                        // this.$store.commit('infoJantes/setJante', {'Arr': this.infoJante})

                        this.updateRoute(color.couleur, 'couleur')

                        this.setOnglet(this.infoJante, '4jantes')
                    })
            },
            methodOncreated(onCreate = true) {

                this.$store.commit('footerNext/setNext', 'true')
                var modelePossible = []


                var monteType = (this.$route.params.decale).toString() === '0' ? 'par4' : 'decale'

                // var infoChassis = JSON.parse(this.$localStorage.get('chassisVehiculeUtilisateur'))
                var infoChassis
                if( typeof ls.get('chassisVehiculeUtilisateur') == "string" ){
                    infoChassis = JSON.parse(ls.get('chassisVehiculeUtilisateur'))
                }
                else if(typeof ls.get('chassisVehiculeUtilisateur') == "object"){
                    infoChassis = ls.get('chassisVehiculeUtilisateur')
                }
                var stock = ' ( NOT stock:0 AND NOT stock:1 AND NOT stock:2 AND NOT stock:3 ) '
                var visibility = ' AND visible:1 '

                if(monteType == "par4" || monteType == "") {
                    Vue.axios.get('https://api.espace-jantes.com/getOptionsChassis', {params: {idChassisVehiculeUtilisateur : infoChassis.id_chassis}})
                        .then((response) => {
                            response.data.forEach(element => {
                                if(element.wheelSize.split("x")[1].trim() == this.dimensionConfigurateur.trim() && element.upsetType != 'RearUpstep' ){
                                    this.optionDeMonte.push(element)
                                }
                            })
                        })
                    if(onCreate){
                        // this.SelectedJante ? this.infoJante = this.SelectedJante : this.infoJante = JSON.parse(this.$localStorage.get('infoJantes'))
                        // this.SelectedJante ? this.infoJante = this.SelectedJante : this.infoJante = JSON.parse(ls.get('infoJantes'))
                        //this.infoJante = {marque: this.$route.params.marque, modele: this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur}
                        // this.dimensionConfigurateur = this.$localStorage.get('diametre')
                        this.dimensionConfigurateur = this.$route.params.diametre.toString()
                    }

                    const client = algoliasearch('IXJF8AJTH7', '27829f3918903605b2f7665097a13737');
                    const index = client.initIndex('dev_EJ');
                    //this.infoJante = {marque: this.$route.params.marque, modele: this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur}

                    index.search('', {
                        filters:'modele: "' + this.$route.params.modele + '" AND marque: "' + this.$route.params.marque + '" AND diametre: ' + this.$route.params.diametre +' AND entraxe:"'+ infoChassis.entraxe +'" AND ' + stock + visibility,
                        // filters:'modele: "' + this.infoJante.modele + '" AND marque: "' + 'PLATIN' + '" AND diametre: ' + this.infoJante.diametre +' AND entraxe:"'+ this.infoJante.entraxe +'" AND ' + stock + visibility,
                        distinct: false,
                        hitsPerPage: 1000,
                    })
                        .then(({ hits }) => {
                            // Boucle pour la récuperation des couleurs
                            hits.forEach(element => {
                                modelePossible.push(element)

                                setTimeout(() => {
                                    this.optionDeMonte.forEach(optionDeMonte => {
                                        if(parseFloat(optionDeMonte.wheelSize.split("x")[0].trim()).toFixed(1) == element.largeur && element.offset >= optionDeMonte.minOffset && element.offset <= optionDeMonte.maxOffset ){
                                            var couleurExiste = this.colorisDisponible.find( coloris => coloris.couleur == element.couleur)

                                            if(couleurExiste == undefined || couleurExiste == 'undefined'){

                                                var couleurReference =  {}
                                                // var couleurFinale =  element.couleur.replace(/\s/g, "+")
                                                var couleurFinale =  element.couleur
                                                couleurReference.couleurFinale = couleurFinale
                                                couleurReference.couleur = element.couleur
                                                couleurReference.reference = element.objectID
                                                couleurReference.image = element.photo_1
                                                couleurReference.image2 = element.photo_2
                                                couleurReference.image3 = element.photo_3

                                                this.colorisDisponible.push(couleurReference)
                                            }
                                        }
                                    })
                                }, 2000);
                            })

                            // ls.set('modelePossibleAuCarre', JSON.stringify(modelePossible))
                            this.setOnglet(modelePossible, 'modelePossibleAuCarre')
                            //Recuperation de la plus petite taille de jantes
                            var resultTMP = hits.filter(element =>  element.diametre == this.dimensionConfigurateur && element.couleur == this.infoJante.couleur );

                            var min = Math.min(...resultTMP.map(e => e.largeur))
                            var arrayTMP = resultTMP.filter(element => element.largeur == min)
                            var arrayJantesOffset = arrayTMP.filter(element => element.offset > infoChassis.min_deport_front && element.offset < infoChassis.max_deport_front)

                            if(arrayJantesOffset.length == 0){
                                var arrayLargeur = []
                                Vue.axios.get('https://api.espace-jantes.com/getOptionsChassis', {params: {idChassisVehiculeUtilisateur : infoChassis.id_chassis}})
                                    .then((response) => {
                                        response.data.forEach(element => {
                                            var wheelSize = element.wheelSize.split("x")
                                            if(wheelSize[1].trim() == this.dimensionConfigurateur.trim()){
                                                arrayLargeur.push(wheelSize[0].trim())
                                            }
                                        })
                                        min = Math.min(...arrayLargeur)
                                        arrayTMP = resultTMP.filter(element => element.largeur == min)
                                        while(arrayTMP.length == 0 && arrayLargeur.length >= 1) {

                                            var index = arrayLargeur.indexOf(min)
                                            arrayLargeur.splice(index, 1);

                                            if(arrayLargeur != 0) {
                                                min = Math.min(...arrayLargeur)
                                                arrayTMP = resultTMP.filter(element => element.largeur == min)
                                            }
                                        }

                                        response.data.forEach(element => {
                                            var wheelSize = element.wheelSize.split("x")
                                            if(wheelSize[0].trim() == min && this.dimensionConfigurateur.trim() == wheelSize[1].trim() && element.upsetType == "Upstep") {
                                                if(arrayTMP.length == 0 ) {

                                                    this.infoJante = resultTMP[0]
                                                }else{
                                                    arrayJantesOffset = arrayTMP.filter(elementBis => elementBis.offset >= element.minOffset && elementBis.offset <= element.maxOffset)

                                                    this.infoJante = arrayJantesOffset[0]
                                                }
                                            }
                                        })


                                        if(this.infoJante !== undefined || this.infoJante !== "undefined" ){
                                            this.setOnglet(this.infoJante, '4jantes')
                                        }
                                    })
                            }else{

                                var arrayOffset = []
                                var minOffsetOption
                                var maxOffsetOption


                                Vue.axios.get('https://api.espace-jantes.com/getOptionsChassis', {params: {idChassisVehiculeUtilisateur : infoChassis.id_chassis}})
                                    .then((response) => {
                                        response.data.forEach(element => {
                                            var wheelSize = element.wheelSize.split("x")

                                            if(element.upsetType != "RearUpset" && this.dimensionConfigurateur.trim() == wheelSize[1].trim() && wheelSize[0].trim() == min) {
                                                minOffsetOption = element.minOffset
                                                maxOffsetOption = element.maxOffset
                                            }
                                        })

                                        arrayJantesOffset.forEach(element => {
                                            if(element.offset >= minOffsetOption && element.offset <= maxOffsetOption){
                                                arrayOffset.push(element.offset)
                                            }
                                        })

                                        var minOffset = Math.max(...arrayOffset)
                                        arrayJantesOffset.forEach(element => {

                                            if(element.offset == minOffset){
                                                this.infoJante = element
                                            }
                                        })
                                        if(this.infoJante != undefined || this.infoJante != "undefined" ){
                                            // localStorage.setItem("infoJantes", JSON.stringify(this.infoJante));
                                            // ls.set("infoJantes", JSON.stringify(this.infoJante));
                                            // this.$store.commit('infoJantes/setJante', {'Av': this.infoJante})
                                            // this.$store.commit('infoJantes/setJante', {'Arr': this.infoJante})
                                            this.setOnglet(this.infoJante, '4jantes')

                                            this.$store.commit('loader/setLoader', false)
                                            this.$store.commit('loader/setLoaderPrix', false)

                                        }
                                    })
                            }
                            this.infoJante = Object.assign({}, this.infoJante)
//                     this.$store.commit('infoJantes/setJante', {'Av': this.infoJante})

//                     this.$store.commit('infoJantes/setJante', {'Arr': this.infoJante})
// console.log('D', this.infoJante.public_price)
// //                     localStorage.setItem("infoJantes", JSON.stringify(this.infoJante));
//                     ls.set("infoJantes", JSON.stringify(this.infoJante));

                            this.setOnglet(this.infoJante ,'4jantes')
                            this.$store.commit('loader/setLoader', false)
                            this.$store.commit('loader/setLoaderPrix', false)


                        });
                    if(onCreate){

                        // var listKtype = (this.$localStorage.get('listKtype')).split(',')
                        var listKtype = ls.get('listKtype')
                        // console.log(getListKtype)
                        // var listKtype = getListKtype.split(',')
                        Vue.axios.get('https://api.espace-jantes.com/infoChassis', {params: {listKtype : listKtype}})
                            .then((response) => {
                                const _this_dimension = []

                                response.data.forEach(function(element) {
                                    var wheelSizeSplit = element.wheelSize.trim().split('x')
                                    wheelSizeSplit[1] = parseInt(wheelSizeSplit[1].trim())
                                    if(_this_dimension.indexOf(wheelSizeSplit[1]) == -1 ){
                                        _this_dimension.push(wheelSizeSplit[1])
                                    }
                                })

                                const byValue  = (a,b) => a - b;
                                const sorted = [... _this_dimension].sort(byValue)
                                this.dimensionsPossibleChassis = sorted
                                //this.infoJante = {marque: this.$route.params.marque, modele: this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur}

                                index.search('', {
                                    // filters:'modele: "' + this.infoJante.modele + '" AND marque: "' + this.infoJante.marque + '" AND entraxe:"'+ this.infoJante.entraxe +'" AND ' + stock + visibility,
                                    filters:'modele: "' + this.$route.params.modele + '" AND marque: "' + this.$route.params.marque +'" AND entraxe:"'+ infoChassis.entraxe +'" AND ' + stock + visibility,
                                    distinct: false,
                                    hitsPerPage: 1000,
                                })
                                    .then(({ hits }) => {
                                        var dimensionsPossibleChassis = this.dimensionsPossibleChassis
                                        hits.forEach(element => {
                                            if(this.dimensions.indexOf(element.diametre) == -1 && dimensionsPossibleChassis.indexOf(element.diametre) != -1){
                                                this.dimensions.push(element.diametre)
                                            }
                                        })
                                    })
                            })
                        this.photoPrincipal = this.infoJante.photo_1

                    }

                }else if(monteType == "decale"){
                    // // var infoOptionET = JSON.parse(this.$localStorage.get('storeEt'))
                    // var infoOptionET = JSON.parse(ls.get('storeEt'))
                    var modelePossibleFront = []
                    var modelePossibleRear = []

                    if(onCreate){
                        // this.SelectedJante ? this.infoJante = this.SelectedJante : this.infoJante = JSON.parse(this.$localStorage.get('infoJantes'))
                        // this.SelectedJante ? this.infoJante = this.SelectedJante : this.infoJante = JSON.parse(ls.get('infoJantes'))
                        //this.infoJante = {marque: this.$route.params.marque, modele: this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur}

                        //this.SelectedJante ? this.infoJanteArriere = this.SelectedJante : this.infoJanteArriere = JSON.parse(ls.get('infoJantes'))

                        // this.dimensionConfigurateur = this.$localStorage.get('diametre')
                        this.dimensionConfigurateur = this.$route.params.diametre.toString()
                    }
                    const client = algoliasearch('IXJF8AJTH7', 'c4c7c9439e003674579cb68a3352acc5');
                    const index = client.initIndex('dev_EJ');
                    //this.infoJante = {marque: this.$route.params.marque, modele: this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur}


                    index.search('', {
                        // filters:'modele: "' + this.infoJante.modele + '" AND marque: "' + this.infoJante.marque + '" AND diametre: ' + this.infoJante.diametre +' AND entraxe:"'+ this.infoJante.entraxe +'" AND ' + stock + ' AND  alesage >='+ infoChassis.alesage + ' AND '+ stock + visibility,
                        filters:'modele: "' + this.$route.params.modele + '" AND marque: "' + this.$route.params.marque + '" AND diametre: ' + this.$route.params.diametre +' AND entraxe:"'+ infoChassis.entraxe +'" AND  alesage >='+ infoChassis.alesage + ' AND ' + stock + visibility,
                        distinct: false,
                        hitsPerPage: 1000,
                    })
                        .then(({ hits }) => {
                            hits.forEach(element => {

                                this.optionFront.forEach(elementFront => {
                                    if(parseFloat(elementFront.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                        if(element.offset >= elementFront.minOffset && element.offset <= elementFront.maxOffset){
                                            if(modelePossibleFront.indexOf(element) == -1){
                                                modelePossibleFront.push(element)
                                            }
                                        }
                                    }
                                })

                                this.optionRear.forEach(elementRear => {
                                    if(parseFloat(elementRear.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                        if(element.offset >= elementRear.minOffset && element.offset <= elementRear.maxOffset){
                                            if(modelePossibleRear.indexOf(element) == -1){
                                                modelePossibleRear.push(element)
                                            }
                                        }
                                    }
                                })
                            })

                            var couleurFront = []
                            var couleurRear = []

                            var largeurMini = []
                            var largeurMaxi = []
                            //Passage pour l'etape 2
                            // ls.set('modelePossibleFront', JSON.stringify(modelePossibleFront))
                            this.setOnglet(modelePossibleFront, 'modelePossibleFront' )
                            // console.log('modelePossibleFront', modelePossibleFront)
                            // this.$localStorage.set('modelePossibleRear', JSON.stringify(modelePossibleRear))
                            // ls.set('modelePossibleRear', JSON.stringify(modelePossibleRear))
                            this.setOnglet(modelePossibleRear,'modelePossibleRear')

                            modelePossibleFront.forEach(elementFront => {
                                if(largeurMini.length == 0 || (largeurMini[0].largeur > elementFront.largeur  )){
                                    if(this.infoJante.couleur == elementFront.couleur){
                                        // if(largeurMini.length == 0 || largeurMini[0].offset < elementFront.offset) {
                                        largeurMini = []
                                        largeurMini.push(elementFront)
                                        // console.log('elementFront')
                                        // console.log(elementFront)
                                    }
                                }
                                const resultat = couleurFront.find( couleur => couleur.couleur === elementFront.couleur);

                                if( resultat == 'undefined' || resultat == undefined){
                                    var infoCouleur = {}
                                    var couleurFinale =  elementFront.couleur
                                    // var couleurFinale =  elementFront.couleur.replace(/\s/g, "+")
                                    infoCouleur.couleurFinale = couleurFinale
                                    infoCouleur.couleur = elementFront.couleur
                                    infoCouleur.image = elementFront.photo_1
                                    infoCouleur.reference = elementFront.objectID
                                    couleurFront.push(infoCouleur)
                                }
                            })
                            modelePossibleRear.forEach(elementRear => {
                                if(largeurMaxi.length == 0 || largeurMaxi[0].largeur < elementRear.largeur){
                                    if(largeurMaxi.length == 0 || (largeurMaxi[0].offset < elementRear.offset   ) ) {
                                        if(this.infoJante.couleur == elementRear.couleur){
                                            largeurMaxi = []
                                            largeurMaxi.push(elementRear)
                                        }
                                    }
                                }
                                const resultat = couleurRear.find( couleur => couleur.couleur === elementRear.couleur);

                                if( resultat == 'undefined' || resultat == undefined){
                                    var infoCouleur = {}
                                    var couleurFinale =  elementRear.couleur
                                    // var couleurFinale =  elementRear.couleur.replace(/\s/g, "+")
                                    infoCouleur.couleurFinale = couleurFinale
                                    infoCouleur.couleur = elementRear.couleur
                                    infoCouleur.image = elementRear.photo_1
                                    infoCouleur.reference = elementRear.objectID
                                    couleurRear.push(infoCouleur)
                                }
                            })

                            var couleurDispo = []
                            couleurFront.forEach(elementFront =>{
                                couleurRear.forEach(elementRear => {

                                    if(elementFront.couleur == elementRear.couleur && couleurDispo.indexOf(elementRear) == -1 &&  couleurDispo.indexOf(elementFront) == -1){
                                        couleurDispo.push(elementRear)
                                    }
                                })
                            })
                            this.colorisDisponible = couleurDispo

                            this.infoJante.largeurMinAv = largeurMini[0].largeur
                            this.infoJante.largeurMaxArr = largeurMaxi[0].largeur
                            this.infoJante.offsetFront = largeurMini[0].offset
                            this.infoJante.offsetRear = largeurMaxi[0].offset
                            this.infoJante.entraxe = infoChassis.entraxe
                            this.infoJante.alesage = infoChassis.alesage
                            this.infoJante.photo_1 = this.colorisDisponible.find(element => element.couleurFinale === this.$route.params.couleur).image
                            this.photoPrincipal = this.infoJante.photo_1
                            // this.public_price = largeurMini[0].public_price
                            this.infoJante.public_price = largeurMini[0].public_price
                            this.infoJante.public_price_rear = largeurMaxi[0].public_price

                            //Pour la reactivité des elements
                            //https://fr.vuejs.org/v2/guide/reactivity.html
                            this.infoJante = Object.assign({}, this.infoJante)


                            this.setOnglet(this.infoJante, '4jantes')
                        })
                    this.$store.commit('loader/setLoader', false)
                    this.$store.commit('loader/setLoaderPrix', false)

                }
                setTimeout(()=>{

                    if(parseFloat(infoChassis.alesage).toString() < this.infoJante.alesage.toString()) {
                        this.bagueAlesage = 'oui'

                        this.isAccessoire = {}
                        this.isAccessoire.index = 4
                        this.isAccessoire.data = {name:'4 Bagues de centrage', price:0, reference:'EJ-accessoires', marque:"EJ-accessoires", img:BagueCentrage, quantite: 1, type:3, flux:null, buying_price: null}
                        this.isAccessoire.selected = true

                        this.choosedAccessoires[4] = this.isAccessoire
                        this.isSelectedIndex[4] = true

                        this.setOnglet(this.choosedAccessoires, 'accessoires')
                        this.setOnglet(this.isSelectedIndex, 'indexAccessoires')
                        // this.$store.commit('infoAccessoires/setAccessoire', this.choosedAccessoires  )
                        // this.$store.commit('infoAccessoires/setSelected', this.isSelectedIndex)

                    }
                    if(parseFloat(infoChassis.alesage).toString() === this.infoJante.alesage.toString()) {
                        this.bagueAlesage = 'non'

                    }
                },2000)


            },
            getOptionchassis(){
                // var infoChassis = JSON.parse(this.$localStorage.get('chassisVehiculeUtilisateur'))
                var infoChassis
                if( typeof ls.get('chassisVehiculeUtilisateur') == "string" ){
                    infoChassis = JSON.parse(ls.get('chassisVehiculeUtilisateur'))
                }
                else if(typeof ls.get('chassisVehiculeUtilisateur') == "object"){
                    infoChassis = ls.get('chassisVehiculeUtilisateur')
                }

                var OptionFront = []
                var OptionRear = []

                Vue.axios.get('https://api.espace-jantes.com/getOptionsChassis', {params: {idChassisVehiculeUtilisateur : infoChassis.id_chassis}})
                    .then((response) => {
                        // var diametre = this.$localStorage.get('diametre')
                        var diametre = this.$route.params.diametre.toString()

                        response.data.forEach(element => {
                            var wheelSizeSplit = element.wheelSize.split('x')
                            if(element.upsetType == "RearUpstep") {

                                if(wheelSizeSplit[1].trim() == diametre.trim()) {
                                    OptionRear.push(element)
                                }
                            }else{
                                if(wheelSizeSplit[1].trim() == diametre.trim()) {
                                    OptionFront.push(element)
                                }
                            }
                        })
                        // console.log(OptionFront)
                        this.optionFront = OptionFront
                        this.optionRear = OptionRear

                    })
            },
            getImgUrl(marque){
                try{
                    var logoUrl = require('@/assets/logosMarquesJantes/'+ marque +'.png')
                    return logoUrl
                }
                catch{
                    if(this.VUE_APP_SITE_ID === '1'){
                        return require('@/assets/logo/ej-logo.svg')
                    }
                    else if(this.VUE_APP_SITE_ID === '2'){
                        return require('@/assets/distri.svg')
                    }
                }
            },
            updateRoute(params, type) {


                var splitedUrlNew = this.$route.path.split('/')
                var splitedUrlOld = this.$route.path.split('/')
                // eslint-disable-next-line no-unused-vars
                var joinedUrl

                switch(type){
                    case 'marque':
                        splitedUrlNew[2] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.marque = params
                        break
                    case 'modele':
                        splitedUrlNew[3] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.modele = params
                        break
                    case 'diametre':
                        splitedUrlNew[4] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.diametre = params
                        break
                    case 'couleur':
                        splitedUrlNew[5] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.couleur = params
                        break
                    case 'decale':
                        splitedUrlNew[6] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.decale = params
                        break
                }

                history.pushState(
                    {},
                    null,
                    joinedUrl
                )
            },
            updateOnglet(oldName, newName){

                ls.get(oldName + '__infosJantes') ?  ls.set(newName + '__infosJantes', ls.get(oldName + '__infosJantes')) : null
                ls.get(oldName + '__infosPneus') ?  ls.set(newName + '__infosPneus', ls.get(oldName + '__infosPneus')) : null
                ls.get(oldName + '__modelePossibleAuCarre') ?  ls.set(newName + '__modelePossibleAuCarre', ls.get(oldName + '__modelePossibleAuCarre')) : null
                ls.get(oldName + '__modelePossibleFront') ?  ls.set(newName + '__modelePossibleFront', ls.get(oldName + '__modelePossibleFront')) : null
                ls.get(oldName + '__modelePossibleRear') ?  ls.set(newName + '__modelePossibleRear', ls.get(oldName + '__modelePossibleRear')) : null


            }
        },
        beforeUpdate() {
            this.configurateurJson.color = this.currentColor;
            this.configurateurJson.diametre = this.selected;


            //for footer's watcher to detect object changes
            this.configurateurJson = Object.assign({}, this.configurateurJson)
            // console.log(this.configurateurJson)
        },
        computed: {

            ...mapState({
                loaderStatus: state => state.loader.isLoader,
                Vehicule: state => state.infoVehicule.Vehicule,
                isProfessional: state => state.auth.isRevendeur,
                tauxRemise: state => state.auth.tauxRemise,

            })

        },

    }
</script>

<style scoped>

</style>
