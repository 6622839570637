

const state = () =>({
    SaveMarque: null,
    SaveModele: null,
    SaveMontage: null,
    SaveTaille: null,
    SaveCouleur:null,
})



const mutations = {
    saveFilter(state, config){

        if (config.Marque) {
            state.SaveMarque = config.Marque
        }
        if (config.Modele) {
            state.SaveModele = config.Modele
        }
        if (config.Montage) {
            state.SaveMontage = config.Montage
        }
        if (config.Taille) {
            state.SaveTaille = config.Taille
        }
        if (config.Couleur) {
            state.SaveCouleur = config.Couleur
        }

    },
    deleteFilter: state => {
        state.SaveMarque = null
        state.SaveModele = null
        state.SaveMontage = null
        state.SaveTaille = null
        state.SaveCouleur = null
    },

}


const actions = {

}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}