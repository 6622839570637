

const state = () =>({
    isSelected:{},
    SelectedAccessoires : {},
    TotalPriceAccessoires : 0

})



const mutations = {
    setSelected(state, config){

        state.isSelected = config

    },
    deleteAllSelected(state){

        state.isSelected = {}

    },

    setAccessoire(state, config){

        state.SelectedAccessoires = config

        state.TotalPriceAccessoires = 0
        Object.values(state.SelectedAccessoires).forEach((e) => {
            if(e != null) {
                state.TotalPriceAccessoires += parseInt(e.data.price)
            }

        })

    },


    deleteOneAccessoire(state, config){

        delete state.SelectedAccessoires[config]
        state.TotalPriceAccessoires = 0
        Object.values(state.SelectedAccessoires).forEach((e) => {
            if(e != null){
                state.TotalPriceAccessoires += parseInt(e.data.price)
            }

        })
    },
    deleteAllAccessoires: state => {
        state.SelectedAccessoires = []
        state.TotalPriceAccessoires = 0
        state.isSelected = {}

    },


}


const actions = {

}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}