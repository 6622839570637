<template>

    <v-app   fluid  >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>

        <v-main v-if="VUE_APP_SITE_ID === '1'">
            <v-col class="py-0">
                <v-row>
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 tc_blue font-weight-bold rotate">
                            Jantes - Fabrication
                        </h1>

                </v-col>
                <v-col lg="5" >
                    <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5  tc_blue font-weight-bold ">
                        Jantes - Fabrication
                    </h1>
                    <v-content class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">LE DÉPORT</p>
                        <p class=" mb-3 pl-4 border_left tc_turquoise font-weight-bold
                                            text-xl-h5
                                        text-lg-body-1
                                        text-md-body-1
                                        text-sm-body-1
                                        text-body-1">Le déport des jantes correspond à la distance entre la face d’appui des fixations et le milieu fictif des jantes.</p>
                        <p>
                            Il est nommé l’ET, cette distance se mesure en millimètre.
                            Le déport peut être positif, neutre ou négatif.
                            Cette indication est souvent notée sur le voile de la jante.
                            En fait, plus le déport s’éloigne du nul, plus le train roulant se fera ressentir.
                            La tenue de route sera modifiée et les vibrations ressenties dans le volant seront accrues.
                            Par contre, un léger déport favorise les manœuvres de braquage et la réversibilité de la direction.
                            Le temps de réactivité du freinage est lui aussi amélioré.
                        </p>

                        <p>
                            On peut modifier le déport d’une jante, mais il faut rester prudent, car ce changement influence la mécanique.
                            Si vous augmentez le déport, cela veut dire que vous ajoutez des élargisseurs de voie.
                            Les roulements de la roue ainsi que les bras, les cardans et les rotules auront un taux d’usure plus rapides et le freinage sera modifié et moins homogène.
                            Le moyeu subira une pression mécanique qui apportera une détérioration.
                        </p>
                        <br>
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">L’ENTRAXE</p>
                        <p>
                            L’entraxe des jantes correspond au diamètre du cercle fictif par lequel passe le centre de tous les trous de fixation des roues.
                            Ces données peuvent être fournies par le constructeur.
                            Pour le calculer, il faut mesurer la distance entre deux trous de fixation côte à côte.
                            Pour avoir une bonne précision de lecture, il est conseillé de mesurer au pied à coulisse.
                        </p>
                        <p>
                            La mesure se prend du bord d’un trou à l’autre, soit du bord extérieur d’un goujon au bord du goujon opposé sans oublier de soustraire le diamètre du goujon à la valeur lue.
                            Le chiffre ainsi obtenu s’appelle une cote. Vous multipliez cette cote par un coefficient prédéfini.
                            Si votre jante, comporte 3 trous le coefficient sera 1.155, si la roue comporte 4 trous le coefficient sera 1.414, et si la roue comporte 5 trous le coefficient sera 1.1701.
                            Le résultat de cette multiplication s’appelle l’entraxe.
                        </p>
                        <br>
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">LE SIÈGE D’UNE JANTE</p>
                        <p>
                            Le siège des jantes se situe sur l’extérieur, à l’endroit ou le pneumatique va venir se placer.
                            La surface n’est pas plane, mais elle a une forme de siège bordé par une protubérance appelée joue.
                            Le milieu de la jante est nommé la gorge centrale, elle est plus basse que le siège.
                            Le bord du pneu vient se positionner sur la joue de la jante.
                            Ces bords sont donc très importants car ils déterminent la bonne tenue du pneumatique montée sur la jante.
                            Le pneumatique est muni d’un bourrelet qui assure un accrochage parfait avec la jante.
                            Toutes les jantes – fabrication n’ont pas un profil de siège identique.
                        </p>
                        <p>
                            Le siège de la jante est relativement fragile, il faut donc prendre des outils adaptés pour le démontage et le montage des pneumatiques afin de ne pas endommager la jante.
                            La capacité d’un pneumatique à ne pas déjanter dépend très largement du bord intérieur du bourrelet.
                            Les bourrelets doivent rester en place sur le siège de la jante dans les virages brusque même avec des pressions réduites.
                            Il est donc vital de vérifier la bonne pression des pneumatiques ainsi que le serrage du bourrelet.
                            L’adéquation pneu-jante vous apporte un bon compromis entre la sécurité (faible sensibilité au déjantage) et la rigidité en rotation du bourrelet (facilité au montage/démontage).
                        </p>
                    </v-content>
                </v-col>
                <v-col class="bc_blue" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                    <v-img src="@/assets/AboutUs.png"/>
                </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phone.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold tc_blue align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33974191801">09 74 19 18 01</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="3" xl="3" class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="2" offset-lg="1" xl="3" lg="3">
                        <v-img style="position: absolute; width: 20%;" src="@/assets/illustration-man.png"/>
                    </v-col>
                </v-row>
            </v-container>


            <v-col v-if="$vuetify.breakpoint.lgAndUp" class="bc_white tc_black">
                <v-row style="margin-top: 5%" class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col offset-lg="1" lg="7" >
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">JANTE – FABRICATION – GÉNÉRALITÉS</p>
                        <br>
                        <p>
                            Chaque fabricant de jantes garde ses secrets de fabrication.
                            Mais on peut parler de généralités. La mise en forme d’une jante commence par le choix de l’alliage pour sa fabrication.
                            La jante c’est-à-dire le voile et la fusée sont alésés de manière à obtenir une précision et un calibrage parfait.
                            La surface de la jante et l’intérieur ainsi que chaque trou sont usiné avec soin.
                            Les indications sont exprimés en pouce (1 pouce = 2.54 cm).
                        </p>
                        <br>
                        <p>
                            La fusée est un cercle profilé en tôle d’acier roulé ou confectionné dans un alliage léger.
                            Le voile vient se placer à l’intérieur du cerclage. Les jantes peuvent être solidaires du voile, c’est-à-dire monobloc ou démontable.
                            Le tout est riveté ou soudé sur le voile ou bien encore coulés d’une seule pièce.
                            Le poids de la jante est un élément capital, car plus la masse non suspendue est diminuée meilleur sera le comportement routier.
                        </p>
                        <br>
                        <p>
                            Pour fabriquer une jante en acier, il faut un voile qui sert à la fixation et des nervures.
                            La création du voile se fait à partir d’une forme pleine suivant un profil précis.
                            Ensuite, elle sera évidée en déterminant les dessins et les trous pour le passage des goujons.
                            Puis le voile sera nervuré pour donner l’aspect final et la résistance mécanique à la jante.
                        </p>

                    </v-col>

                </v-row>
                <v-row class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col offset-lg="1" lg="10">
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">JANTES – FABRICATION – LA RÉGLEMENTATION</p>
                        <br>
                        <v-row>
                            <v-col>
                                <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">CODE ROUTIER</p>
                                <br>
                                <p>
                                    Dès 1806, on voit apparaître en France un décret concernant la police de la circulation et du roulage qui fixe des dispositions générales concernant notamment la longueur des essieux, la largeur des jantes, la pression des pneus et le poids des voitures.
                                    Puis une première loi sur la circulation des véhicules en 1852 est parue.
                                    Depuis, les arrêtés et les décrets se succèdent pour homologuer ou annuler des normes pour les jantes.
                                </p>
                                <br>
                                <p>
                                    Chaque pays veille à une application des lois autoroutières pour automobile.
                                    La première base de l’article R314-1 du code de la route indique que tout véhicule à moteur doit être muni de jantes et de pneus conformes.
                                    Les pneus nécessitent une attention particulière et très importante de la part des services de l’ordre qui n’hésite pas à contrevenir en cas d’infraction par des amendes et voir à confisquer le véhicule.
                                </p>
                                <br>
                            </v-col>
                            <v-col>
                                <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">LES ORGANISMES</p>
                                <br>
                                <p>
                                    En ce qui concerne les véhicules ayant subis des transformations comme le tuning, la législation française est peu adaptée.
                                    On sait que chaque véhicule est homologué par type constructeur.
                                    Mais il est très coûteux de refaire une homologation auprès de la DRIRE (direction régionale de l’industrie, de la recherche et de l’environnement) après avoir modifié la voiture.
                                    Vous devez vous-même être rigoureux quand à la sécurité du conducteur et à celle de la collectivité. Les jantes de votre véhicule ainsi que le montage parfois spécifique doivent être contrôlée et réalisée dans le respect des normes de sécurité.
                                    Les constructeurs de jantes – fabrication, donnent des indications fiables et précises. Vous devez les respecter.
                                </p>
                                <br>
                                <p>
                                    L’UTAC est un cabinet d’expertise, de contrôle, d’homologation et de normalisation au service de l’automobile.
                                    Elle intervient donc auprès des industrielles dans le développement, les essais et l’homologation de produits.
                                    L’UTAC participe à l’élaboration des jantes et prend en compte les performances des véhicules en lien direct avec le roulage, le freinage, la stabilité, la manœuvrabilité et les pneumatiques.
                                    Elle utilise des bancs de roulage et teste les jantes et les pneus à vitesse très élevée et en charge.
                                </p>
                                <br>
                                <p>
                                    La Fédération de tuning FI2A a été créée pour remédier au problème du vide juridique concernant le manque de repère en matière d’accessoire de voiture.
                                    La fédération aide les passionnés de la discipline du tuning à rester raisonnable dans les transformations.
                                    Et comme il n’y a pas de loi précise sur les jantes, leur but est d’expliquer le respect des rapports châssis, suspensions, freinage et vitesse.
                                </p>
                                <br>

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <p>Pour en savoir plus sur les jantes, le lecteur se reportera aux différents articles qui traitent de cette question, sur le présent site.</p>
                                <p>Droit d’auteur protégé par les articles L. 111-1 et L. 123-1, du code de la propriété intellectuelle.</p>
                            </v-col>
                        </v-row>


                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="$vuetify.breakpoint.mdAndDown" class="bc_white tc_black" >
            <v-row style="margin-top: 5%" class="font-weight-regular
                                    text-xl-h5
                                    text-lg-body-1
                                    text-md-caption
                                    text-sm-caption
                                    text-caption">
                <v-col offset-lg="1" lg="7" >
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">JANTE – FABRICATION – GÉNÉRALITÉS</p>
                    <br>
                    <p>
                        Chaque fabricant de jantes garde ses secrets de fabrication.
                        Mais on peut parler de généralités. La mise en forme d’une jante commence par le choix de l’alliage pour sa fabrication.
                        La jante c’est-à-dire le voile et la fusée sont alésés de manière à obtenir une précision et un calibrage parfait.
                        La surface de la jante et l’intérieur ainsi que chaque trou sont usiné avec soin.
                        Les indications sont exprimés en pouce (1 pouce = 2.54 cm).
                    </p>
                    <br>
                    <p>
                        La fusée est un cercle profilé en tôle d’acier roulé ou confectionné dans un alliage léger.
                        Le voile vient se placer à l’intérieur du cerclage. Les jantes peuvent être solidaires du voile, c’est-à-dire monobloc ou démontable.
                        Le tout est riveté ou soudé sur le voile ou bien encore coulés d’une seule pièce.
                        Le poids de la jante est un élément capital, car plus la masse non suspendue est diminuée meilleur sera le comportement routier.
                    </p>
                    <br>
                    <p>
                        Pour fabriquer une jante en acier, il faut un voile qui sert à la fixation et des nervures.
                        La création du voile se fait à partir d’une forme pleine suivant un profil précis.
                        Ensuite, elle sera évidée en déterminant les dessins et les trous pour le passage des goujons.
                        Puis le voile sera nervuré pour donner l’aspect final et la résistance mécanique à la jante.
                    </p>
                    <br>
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">JANTES – FABRICATION – LA RÉGLEMENTATION</p>
                    <br>
                        <p class="font-weight-bold
                   text-xl-h5
                   text-lg-h6
                   text-md-subtitle-1
                   text-sm-subtitle-1
                   text-subtitle-2">CODE ROUTIER</p>
                        <br>
                        <p>
                            Dès 1806, on voit apparaître en France un décret concernant la police de la circulation et du roulage qui fixe des dispositions générales concernant notamment la longueur des essieux, la largeur des jantes, la pression des pneus et le poids des voitures.
                            Puis une première loi sur la circulation des véhicules en 1852 est parue.
                            Depuis, les arrêtés et les décrets se succèdent pour homologuer ou annuler des normes pour les jantes.
                        </p>
                        <br>
                        <p>
                            Chaque pays veille à une application des lois autoroutières pour automobile.
                            La première base de l’article R314-1 du code de la route indique que tout véhicule à moteur doit être muni de jantes et de pneus conformes.
                            Les pneus nécessitent une attention particulière et très importante de la part des services de l’ordre qui n’hésite pas à contrevenir en cas d’infraction par des amendes et voir à confisquer le véhicule.
                        </p>
                        <br>

                        <p class="font-weight-bold
                   text-xl-h5
                   text-lg-h6
                   text-md-subtitle-1
                   text-sm-subtitle-1
                   text-subtitle-2">LES ORGANISMES</p>
                        <br>
                        <p>
                            En ce qui concerne les véhicules ayant subis des transformations comme le tuning, la législation française est peu adaptée.
                            On sait que chaque véhicule est homologué par type constructeur.
                            Mais il est très coûteux de refaire une homologation auprès de la DRIRE (direction régionale de l’industrie, de la recherche et de l’environnement) après avoir modifié la voiture.
                            Vous devez vous-même être rigoureux quand à la sécurité du conducteur et à celle de la collectivité. Les jantes de votre véhicule ainsi que le montage parfois spécifique doivent être contrôlée et réalisée dans le respect des normes de sécurité.
                            Les constructeurs de jantes – fabrication, donnent des indications fiables et précises. Vous devez les respecter.
                        </p>
                        <br>
                        <p>
                            L’UTAC est un cabinet d’expertise, de contrôle, d’homologation et de normalisation au service de l’automobile.
                            Elle intervient donc auprès des industrielles dans le développement, les essais et l’homologation de produits.
                            L’UTAC participe à l’élaboration des jantes et prend en compte les performances des véhicules en lien direct avec le roulage, le freinage, la stabilité, la manœuvrabilité et les pneumatiques.
                            Elle utilise des bancs de roulage et teste les jantes et les pneus à vitesse très élevée et en charge.
                        </p>
                        <br>
                        <p>
                            La Fédération de tuning FI2A a été créée pour remédier au problème du vide juridique concernant le manque de repère en matière d’accessoire de voiture.
                            La fédération aide les passionnés de la discipline du tuning à rester raisonnable dans les transformations.
                            Et comme il n’y a pas de loi précise sur les jantes, leur but est d’expliquer le respect des rapports châssis, suspensions, freinage et vitesse.
                        </p>
                        <br>
                        <p>Pour en savoir plus sur les jantes, le lecteur se reportera aux différents articles qui traitent de cette question, sur le présent site.</p>
                        <br>
                        <p>Droit d’auteur protégé par les articles L. 111-1 et L. 123-1, du code de la propriété intellectuelle.</p>


                </v-col>
            </v-row>
        </v-col>
        </v-main>



        <v-main v-else-if="VUE_APP_SITE_ID === '2'">
            <v-col class="py-0">
                <v-row>
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 font-weight-bold rotate">
                            Jantes - Fabrication
                        </h1>

                </v-col>
                <v-col lg="5" >
                    <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5  font-weight-bold ">
                        Jantes - Fabrication
                    </h1>
                    <v-content class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">Quelques notions techniques pour choisir une jante</p>
                       <p>
                           Avant de choisir <b>une jante pour votre véhicule</b> , il convient de connaître quelques notions techniques, de savoir ce qu’est un <b> déport, un entraxe </b> ou un <b> siège de jante </b>… et de ne pas ignorer la règlementation en vigueur…
                        </p>


                        <br>
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">Les bases de la fabrication d’une jante</p>
                        <p>
                            L’entraxe des jantes correspond au diamètre du cercle fictif par lequel passe le centre de tous les trous de fixation des roues.
                            Ces données peuvent être fournies par le constructeur.
                            Pour le calculer, il faut mesurer la distance entre deux trous de fixation côte à côte.
                            Pour avoir une bonne précision de lecture, il est conseillé de mesurer au pied à coulisse. Le mode de fabrication diffère selon les marques de jante, BBS, Racer, Japan Racing....
                        </p>
                        <p>
                            Pour autant, voici quelques notions de base :
                        </p>
                        <p>
                            Pour rappel, la fusée désigne la pièce circulaire en alliage léger à l’intérieur de laquelle vient se fixer le voile de la jante.
                            Le voile est doté de trous pour le passage des goujons (boulons) et présente un certain nombre de nervures qui, outre l’aspect design, jouent un rôle sur la résistance mécanique de la roue.
                            Voile et fusée sont parfaitement alésés c’est à dire ajustés et calibrés.
                        </p>
                        <p>
                            <b>La jante</b> peut être démontable (en plusieurs parties) ou monobloc auquel cas jante et voile se présentent en une seule pièce moulée.
                        </p>
                        <p>
                            Revenons sur une notion importante et déterminante dans le choix de l’alliage lié au poids de la <b>jante </b> : la masse non suspendue.
                        </p>
                        <p>
                            A la différence de l’ossature (ou châssis) d’un véhicule, de sa carrosserie et de son moteur, la roue dans son ensemble (<b>jante </b>, pneu…) est considérée comme une masse non suspendue.
                            Plus celle-ci est légère, meilleure sera l’adhérence dans la mesure où la roue sera plus enclin à suivre les ondulations de la chaussée.
                        </p>
                    </v-content>
                </v-col>
                <v-col class="pa-0" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                    <v-img src="@/assets/AboutUsDJ.png"/>
                </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phoneDJ.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33974191801">09 74 19 18 01</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="5" offset-lg="1"  class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>

                </v-row>
            </v-container>


            <v-col cols="12" class="bc_white tc_black">
                <v-row style="margin-top: 5%" class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col offset-xl="1" offset-lg="1" xl="7" lg="7" cols="12">
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">Déport, entraxe et siège d’une jante : 3 notions indispensables</p>
                        <br>
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">1) Le déport de jante</p>
                        <p>
                            Le déport, également appelé ET (ou offset en anglais), désigne la distance, exprimée en mm, entre le centre de la <b>jante </b>et le point d’appui pour la fixation sur le moyeu (plus précisément sur le disque ou le tambour).
                            Il s’agit là d’une indication (mentionnée sur le voile) relative au positionnement de la <b>jante </b> par rapport au passage de roue.
                        </p>
                        <p>
                            Dans le cas d’un <b> déport de jante élevé </b>, les roues s’enfoncent dans le passage de roue ce qui modifie la tenue de route et accroît la sensation de vibration au volant.
                            <b>A l’inverse, un déport de jante</b> faible optimisera le temps de freinage et la manœuvrabilité, en particulier lors des braquages.
                        </p>
                        <p>
                            Nous attirons votre attention sur les risques d’usure et de pression mécanique d’une augmentation de déport rendue possible par l’installation d’élargisseurs de voie (sorte de cales insérées entre le moyeu et la <b>jante </b>).
                        </p>
                        <br>
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">2) L’entraxe d’une jante</p>
                        <p>
                            On définit l'entraxe comme la distance séparant les trous de fixation des roues diamétralement opposées.
                        </p>
                        <p>
                            Cette indication, à défaut d'être renseignée par le constructeur, peut être calculée comme suit :
                        </p>
                        <p>
                            - mesurer à l'aide d'un pied à coulisse la distance entre les bords extérieurs des deux goujons opposés,
                            - retirer de cette mesure, la valeur des diamètres des goujons,
                        </p>
                        <p>
                            Pour définir <b>l'entraxe de la jante</b>, il suffit alors d'appliquer à la côte, ainsi obtenue, un coefficient multiplicateur, prédéterminé selon le nombre de trous soit :
                        </p>
                        <span>
                            1,155 pour 3 trous,
                            <br>
                            1,414 pour 4 trous,
                            <br>
                            1,1701 pour 5 trous.
                        </span>
                        <br>
                        <br>
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">3) Le siège d’une jante </p>
                        <p>
                            Comme son nom l’indique, le siège, situé en extérieur de la <b>jante </b>, est destiné à accueillir le pneu qui vient se positionner entre chacun des deux bords, appelés joues, assurant la bonne tenue du pneumatique.
                        </p>
                        <p>
                            Le pneu est quant à lui doté de bourrelet pour optimiser l’accrochage dans la <b>jante </b>.
                        </p>
                        <p>
                            Point important à vérifier lors du montage et démontage du pneumatique : le bon positionnement du bourrelet. La mesure de la pression des pneumatiques est vivement recommandée.
                        </p>
                        <p>
                            Rappelons-le, la <b>compatibilité du couple jante-pneu</b> est au cœur de la tenue de route : un "déjantage" peut être lourd de conséquences.
                        </p>
                        <p>
                            Le profil des sièges n’est pas uniforme, il varie selon les <b> modèles de jantes</b>.
                        </p>

                    </v-col>

                    <v-col offset-lg="1" lg="7" >
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">Quelques notions règlementaires sur la jante</p>
                        <br>
                        <p>Le législateur, en tant que garant de la sécurité, s’attache à statuer et contrôler la conformité des équipements des véhicules. La roue,<b> ensemble pneu-jantes</b>, est donc directement concernée.</p>
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">1) Code de la Route</p>
                        <p>
                            L’article R314-1 prévoit que “les roues de tout véhicule à moteur (...) et des engins de déplacement personnel motorisés doivent être munis de pneumatiques (…)”
                            Il apporte des précisions quant aux caractéristiques des pneumatiques : sculptures apparentes, absence de toile et de déchirures sur les flancs.
                            “(…) Nature, forme, état, conditions d’utilisation des pneumatiques et autres dispositifs (…)” sont déterminés par arrêté ministériel.
                        </p>
                        <p>
                            Toute potentielle infraction sera punie d’une amende (contravention 4ème classe) voire d’une immobilisation du véhicule (cf articles L.325-1, L325-2, L325-3).
                        </p>
                        <br>
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">2) Contrôle technique relatif aux jantes</p>
                        <p>
                            Depuis 2018, le contrôle technique passe en revue 400 points.
                            Une des grandes nouveautés sur les <b>jantes </b> : des jantes non conformes ne sont plus validées et requièrent une contre-visite
                        </p>
                        <br>
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">3) Règlementation pour modifier une jante</p>
                        <p>
                            Pour rester conforme à la réglementation, l’installation d’une <b>nouvelle jante </b>devra être conforme aux normes du constructeur.
                        </p>
                        <p>
                            Quelques conditions à respecter :
                        </p>
                        <p>
                            Compatibilité de la largeur de la <b>jante </b> avec celle du pneu,
                            Les passages d’ailes doivent pouvoir recevoir un pneu plus large,
                        </p>
                        <p>
                            Largeur équivalente à celle de l’ancienne <b>jante </b>, nombre de trous identique
                            Les jantes ne doivent pas déborder du véhicule,
                        </p>
                        <p>
                            Charges et de vitesse : indices équivalents ou supérieurs à ceux
                            de l’équipement d’origine.
                        </p>
                        <br>
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">4) Quelques organismes à connaître</p>
                        <p>
                            <u>UTAC-OTC</u>
                        </p>
                        <p>
                            <b>OTC</b> : Organisme Technique Central du Contrôle Technique
                        </p>
                        <p>
                            <b>UTAC</b> : Société de droit privé agréée par les pouvoirs publics (français et autres pays européens) auprès de la CE et des Nations Unies pour l’homologation des véhicules et de leurs équipements.
                        </p>
                        <p>
                            L’UTAC dispose d’un laboratoire officiel Euro NCAP destiné à mesurer la sécurité des véhicules.
                            Elle joue ainsi un rôle actif dans les différentes innovations en matière de <b>jantes </b> notamment au niveau de la réalisation de tests pour évaluer leurs performances (roulage, freinage, stabilité…).

                        </p>
                        <p>
                            <b>DRIRE</b> : Direction Régionale de l’Industrie, de la Recherche et l’Environnement
                        </p>
                        <p>
                            Organisme compétant pour homologuer votre voiture quand les transformations, que vous y apportez, modifient les caractéristiques techniques figurant sur la carte grise.
                        </p>
                        <p>
                            Pour les amateurs de tuning et si vous envisagez de changer les jantes de votre voiture, compte tenu de l’absence de textes réglementaires précis en la matière et des risques sécuritaires, nous vous conseillons de vous rapprocher de l’une des fédérations de tuning : FICAT, F12A, Caradisiac, F.F.D.T…
                        </p>

                    </v-col>

                </v-row>
            </v-col>
        </v-main>

        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "../headers/HomepageHeaderEj";
import HomepageHeaderDj from "../headers/HomepageHeaderDj";
import HomepageFooterEj from "../footer/HomepageFooterEj";
import HomepageFooterDj from "../footer/HomepageFooterDj";

    export default {
        name: "JantesFabrication",
        components:{
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,

        },
        data: () => ({
            VUE_APP_SITE_ID:null
        }),
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        },
        metaInfo() {
            return {
                title: `Fabrication Jantes - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + `  - Jantes, Pneus & Accessoires`,
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `Fabrication Jantes - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {name: 'twitter:description', content: `Découvrez les détails de fabrication d'une jante ` + (this.VUE_APP_SITE_ID === '1' ? `d'Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `de Distri Jantes l'expert en ligne des jantes`)},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `Fabrication Jantes -` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {property: 'og:site_name', content: this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`},
                    {property: 'og:type', content: 'Website'},
                    {property: 'og:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},
                    {property: 'og:description', content: `Découvrez les détails de fabrication d'une jante ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    {name: 'robots', content: 'index,follow'}
                ]
            }
        },
    }
</script>

<style scoped>
    .border_left{
        border-left: 2px solid #1DE4AF
    }
    .rotate {
        transform: rotate(-90deg);
        margin-left: -8%;
        margin-top: 14%;
        position: absolute;
    }
</style>
