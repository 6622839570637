<template>
    <v-app class="bc_lightGrey">
        <v-main style="background-color: #2D2C2F">
            <v-col offset-xl="2" offset-lg="2" offset-md="2" xl="8" lg="8" md="8" cols="12">

                <v-row>
                    <v-col cols="3" v-for="logoPayment in logoPayments" :key="logoPayment.img" class=" d-flex text-center" style="" >
                        <v-img class="align-self-center ma-auto" style="max-width: 50%" :src="logoPayment.img"/>
                    </v-col>
                </v-row>

            </v-col>
        </v-main>



        <v-main style="background-color: #434246" class="tc_white">
            <v-col offset-xl="2" offset-lg="2" offset-md="2" xl="8" lg="8" md="8" cols="12"  :style="{ 'background-image': 'url(' + background_dj_footer.img + ')' }">
                <v-row>
                    <v-col cols="2" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-img src="@/assets/logo/logo_distri_jantes.svg"/>
                    </v-col>
                    <v-col offset-xl="1" offset-lg="1" offset-md="1" xl="3" lg="3" md="3" sm="4" cols="6" class="font-weight-bold mb-3 text-h6 " >
                        <h2 class="text-xl-h5
                                    text-lg-h6
                                    text-md-h6
                                    text-sm-h6
                                    text-h6
                                    half-a-border-on-bottom" > <span class="font-weight-bold">Nous</span> contacter</h2>

                        <RouterLink :to="{name:'ContactUs'}" class="no_deco  text-subtitle-1" >
                             <span class="font-weight-regular
                                text-xl-h6
                                text-lg-caption
                                text-md-caption
                                text-sm-caption
                                text-caption">Formulaire de contact</span>
                        </RouterLink>
                        <br>
                        <a class="no_deco tc_orange  " href="tel:+33974191801" style="color: #FC5C1F!important">09 74 19 18 01</a>
                        <v-row class="px-3">
                            <a  v-if="$vuetify.breakpoint.xl" href="https://www.instagram.com/distri_jantes/" target="_blank" class="no_deco">
                                <v-icon large class="tc_white"> mdi-instagram</v-icon>
                            </a>
                            <a v-if="$vuetify.breakpoint.lgAndDown"  href="https://www.instagram.com/distri_jantes/" target="_blank" class="no_deco" >
                                <v-icon style="padding-right: 2%;" class="tc_white">mdi-instagram</v-icon>
                            </a>
                            <a v-if="$vuetify.breakpoint.xl"  href="https://www.facebook.com/distri.jantes" target="_blank" class="no_deco" >
                                <v-icon large style="padding-right: 2%;" class="tc_white">mdi-facebook</v-icon>
                            </a>
                            <a  v-if="$vuetify.breakpoint.lgAndDown" href="https://www.facebook.com/distri.jantes" target="_blank" class="no_deco">
                                <v-icon style="padding-right: 2%;" class="tc_white">mdi-facebook</v-icon>
                            </a>
                        </v-row>
                    </v-col>


                    <v-col  xl="3" lg="3" md="3" sm="4" cols="6" style="margin-bottom: 3%">
<!--                        <v-row class="font-weight-bold pt-0 mb-3 text-h6 "  >-->
                        <h2 class="text-xl-h5
                                    text-lg-h6
                                    text-md-h6
                                    text-sm-h6
                                    text-h6
                                    half-a-border-on-bottom " > <span class="font-weight-bold ">A</span> Propos</h2>
<!--                        </v-row>-->
                        <v-row v-for="about in abouts" :key="about.title" class="px-3" >
                            <RouterLink :to="{name:about.link}" class="no_deco  text-subtitle-1">
                                <span class="font-weight-regular
                                            text-xl-h6
                                            text-lg-caption
                                            text-md-caption
                                            text-sm-caption
                                            text-caption">{{about.title}}</span>
                            </RouterLink>
                        </v-row>
                    </v-col>

                    <v-col offset-xl="0" offset-lg="0" offset-md="0" offset="0" xl="3" lg="3" md="3" sm="4" cols="6" class="ml-3 ml-xl-0 ml-lg-0 ml-md-0 ml-sm-0 ml-md-0">
<!--                        <v-row class="font-weight-bold pt-0 mb-3 text-h6"  >-->
                            <h2 class=" text-xl-h5
                                        text-lg-h6
                                        text-md-h6
                                        text-sm-h6
                                        text-h6
                                        half-a-border-on-bottom"> <span class="font-weight-bold">Accès</span> Rapide</h2>
<!--                        </v-row>-->
                        <v-row v-for="fast in fastAccess" :key="fast.title" class="px-3" >
                            <RouterLink :to="{name:fast.link}" class="no_deco  ">
                                <span class="font-weight-regular
                                            text-xl-h6
                                            text-lg-caption
                                            text-md-caption
                                            text-sm-caption
                                            text-caption">{{fast.title}}</span>
                            </RouterLink>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-main>




            <v-main v-if="$vuetify.breakpoint.mdAndUp"  class="bc_darker tc_white">
                <v-col offset="2" cols="8">
                  <v-row>
                    <v-col cols="6" style="padding-top: 3%" >
                        <v-row class="font-weight-bold pt-0 text-h6 " >
                            <h2 class="text-xl-h5
                                        text-lg-h6
                                        text-md-h6 half-a-border-on-bottom-darker"><span class="font-weight-bold">Marques</span> de Véhicules</h2>
                        </v-row>
                    </v-col>

                    <v-col cols="6" style="padding-top: 3%"  >
                        <v-row class="font-weight-bold pa-0 text-h6 " >
                            <h2 class="text-xl-h5
                                        text-lg-h6
                                        text-md-h6 half-a-border-on-bottom-darker"><span class="font-weight-bold">Marques</span> de Jantes</h2>
                        </v-row>
                    </v-col>

                    <v-col style="padding-top: 1%!important;padding-bottom: 2%;" class="" cols="6" >
                        <v-row>
                            <v-col cols="6"  no-gutters v-for="marque in marques" @click="toStoreVoiture(marque.Nom, marque.id_manufacturer)" :key="marque.id_manufacturer" class=" pa-0 " style="cursor: pointer" >
                                   <span class="
                                        font-weight-regular
                                        text-xl-h6
                                        text-lg-caption
                                        text-md-caption">Jantes pour  {{marque.Nom}}</span>
                            </v-col>
                        </v-row>
                    </v-col>




                    <v-col style="padding-top: 1%!important;padding-bottom: 2%;" class="" cols="6" >
                        <v-row>
                            <v-col cols="6" no-gutters v-for="(marque) in marquesJantes" @click="toStoreJante(marque)" :key="marque.id_manufacturer" class="  text-subtitle-1 pa-0" style="cursor: pointer" >
                                    <span class="
                                        font-weight-regular
                                        text-xl-h6
                                        text-lg-caption
                                        text-md-caption">Jantes Alu {{marque}}</span>
                            </v-col>
                        </v-row>
                    </v-col>



                </v-row>
                </v-col>
            </v-main>

            <v-main v-if="$vuetify.breakpoint.smAndDown"  class="bc_darker tc_white">
                    <v-col cols="12" style="padding-top: 3%" >
                        <v-row class="font-weight-bold pt-0 text-h6 pa-3" >
                            <h2 class="text-xl-h5
                                        text-lg-h6
                                        text-md-h6
                                        text-sm-h6
                                        text-h6
                                        half-a-border-on-bottom-darker"><span class="font-weight-bold">Marques</span> de Véhicules</h2>
                        </v-row>
                        <v-col style="padding-top: 1%!important;padding-bottom: 2%;" class="" cols="12" >
                            <v-row>
                                <v-col cols="6"  no-gutters v-for="marque in marques" @click="toStoreVoiture(marque.Nom, marque.id_manufacturer)" :key="marque.id_manufacturer" class=" pa-0 " style="cursor: pointer" >
                                   <span class="font-weight-regular
                                            text-sm-caption
                                            text-caption">Jantes pour  {{marque.Nom}}</span>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-row class="font-weight-bold pa-0 text-h6 mt-3 pa-3" >
                            <h2 class="text-xl-h5
                                        text-lg-h6
                                        text-md-h6
                                        text-sm-h6
                                        text-h6
                                        half-a-border-on-bottom-darker"><span class="font-weight-bold">Marques</span> de Jantes</h2>
                        </v-row>
                        <v-col style="padding-top: 1%!important;padding-bottom: 2%;" class="" cols="12" >
                            <v-row>
                                <v-col cols="6" no-gutters v-for="(marque) in marquesJantes" @click="toStoreJante(marque)" :key="marque.id_manufacturer" class="  text-subtitle-1 pa-0" style="cursor: pointer" >
                                    <span class="font-weight-regular
                                            text-sm-caption
                                            text-caption">Jantes Alu {{marque}}</span>
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-col>








            </v-main>


    </v-app>

</template>

<script>


    import avisverifies from "@/assets/logo/avisverifies.png";
    import gls from "@/assets/logo/gls.png";
    import dpd from "@/assets/logo/dpd.png";
    import ups from "@/assets/logo/ups.png";
    import background_dj_footer from "@/assets/background-dj-footer.png";
    // import avisVerif from "@/assets/logo/avis_verifies.png"

    import Vue from 'vue'
    import algoliasearch from 'algoliasearch/lite';
    import 'instantsearch.css/themes/algolia-min.css';


    export default {
        name: "HomepageFooterDj",
        data: () =>({
            shops:[
                {title:'Jantes par marques'}  ,
                {title:'Pneus par marques'}  ,
                {title:'Accessoires de jantes'}  ,
            ],
            fastAccess:[
                {title:'Mon Compte', link: 'Account'},
                {title:'Accès Revendeurs', link: 'Revendeurs'},
                {title:'Jantes - Description', link: 'JantesDescription'},
                {title:'Jantes - Fabrication', link: 'JantesFabrication'},
                {title:'Jantes - Monobloc', link: 'JantesMonobloc'},
                {title:'Jantes - Matériaux', link: 'JantesMateriaux'},
                {title:'Jantes - Options', link: 'JantesOptions'},
                {title:'Jantes - Entretien', link: 'JantesEntretien'},
                {title:'Jantes - Conseils', link: 'JantesConseils'},
                {title:'Jantes - Permutation', link: 'JantesPermutation'},
            ],
            abouts:[
                {title:'Qui sommes-nous ?', link: 'AboutUs'},
                {title:'Nos engagements', link:'NosEngagements'},
                {title:'Conditions Générales de Vente', link:'CGV'},
                {title:'Livraison', link: 'Livraison'},
                {title:'Mentions légales + RGPD', link: 'MentionsLegales'},
            ],
            logoPayments:[
                {img:avisverifies},
                {img:ups},
                {img:gls},
                {img:dpd},
            ],
            jantes1:[
                {title:'Alfa Romeo'},
                {title:'Audi'},
                {title:'BMW'},
                {title:'Citroen'},
                {title:'Fiat'},
                {title:'Land Rover'},
                {title:'Mercedes'},
                {title:'Mini'},
            ],
            jantes2:[
                {title:'Peugeot'},
                {title:'Porsche'},
                {title:'Renault'},
                {title:'Seat'},
                {title:'Skoda'},
                {title:'Volkswagen'},
                {title:'Volvo'},
            ],
            jantes3:[
                {title:'24h du Mans'},
                {title:'Alutec'},
                {title:'BBS'},
                {title:'Beyern'},
                {title:'Fox'},
                {title:'GMP'},
                {title:'Infiny'},
                {title:'Inter Action'},
                {title:'Japan Racing'},
                {title:'MAK'},
                {title:'Mandrus'},
            ],
            jantes4:[
                {title:'Mille Miglia'},
                {title:'Monaco'},
                {title:'MSW'},
                {title:'OZ'},
                {title:'Racer'},
                {title:'Radi8'},
                {title:'Ronal'},
                {title:'Sparco'},
                {title:'WSP Italy'},
            ],
            marques:[],
            marquesJantes:[],
            searchClient: algoliasearch(
                'IXJF8AJTH7',
                '27829f3918903605b2f7665097a13737'
            ),
            background_dj_footer:{img:background_dj_footer},
        }),
        mounted() {

            var arrayMarqueHide = ['AC','ACURA','ALPINA','AMC','ARO','ARTEGA','ASIA MOTORS','AUSTIN','AUSTIN-HEALEY','AUTO UNION','AUTOBIANCHI','AUVERLAND','BEDFORD','BARKAS','BERTONE','BITTER','BLUECAR','BOND','BORGWARD','BRISTOL',
                'BUGATTI','BUICK','CALLAWAY','CARBODIES','CATERHAM','CHATENET','CHECKER','COMARTH','DAF','DAIMLER','DALLAS','De La Chapelle','DE LOREAN','DE TOMASO','DONKERVOORT','EBRO','FISKER','FORD OTOSAN','FSO',
                'GAZ','GEELY','GEO','GINETTA','GIOTTI VICTORIA','GLAS','GMC','GME','GOUPIL','GUMPERT','HINDUSTAN','HOBBYCAR','INDIGO','INNOCENTI','IRAN KHODRO (IKCO)','IRMSCHER','ISDERA','IVECO','IZH','JENSEN',
                'KTM','LANDWIND (JMC)','LDV','LIGIER','LINCOLN','LTI','MAHINDRA','MAN','MARCOS','MARUTI','MAYBACH','MEGA','METROCAB','MIA ELECTRIC','MICROCAR','MIDDLEBRIDGE','MINELLI','MITSUOKA','MORGAN','MORRIS',
                'MOSKVICH','MPM MOTORS','NSU','OLDSMOBILE','OLTCIT','OSCA','PAGANI','PANOZ','PANTHER','PGO','PIAGGIO','PININFARINA','PLYMOUTH','POLARIS','PONTIAC','PREMIER','PROTON','PUCH','RAM','RANGER',
                'RAYTON FISSORE','RELIANT','RENAULT TRUCKS','RILEY','RUF','SANTANA','SATURN','SHELBY','SIPANI','SPECTRE','SPYKER','STANDARD','STANGUELLINI','STEYR','STREETSCOOTER','TALBOT','TATA','TAZZARI',
                'THINK','TRABANT','TRIUMPH','TVR','UAZ','UMM','VAUXHALL','VECTOR','VENTURI','VICTORY','VUHL','WARTBURG','WESTFIELD','WIESMANN','WOLSELEY','YUGO','YULON','ZASTAVA','ZAZ','ZENOS CARS',
                'BEDFORD', 'AIXAM', 'CHATENET', 'CUPRA', 'DAEWOO', 'DAIHATSU', 'De La Chapelle','DODGE', 'FORD USA', 'IRAN KHODRO (IKCO)','HUMMER','INFINITI','ISUZU','LADA','LANDWIND (JMC)','PIAGGIO',
                'RENAULT TRUCKS','SAAB', 'SSANGYONG','LANCIA','ROVER']


            // var arrayMarqueHide = ['AC','ACURA','ALPINA','AMC','ARO','ARTEGA','ASIA MOTORS','AUSTIN','AUSTIN-HEALEY','AUTO UNION','AUTOBIANCHI','AUVERLAND','BARKAS','BERTONE','BITTER','BLUECAR','BOND','BORGWARD','BRISTOL',
            //                 'BUGATTI','BUICK','CALLAWAY','CARBODIES','CATERHAM','CHECKER','COMARTH','DAF','DAIMLER','DALLAS','DE LA CHAPELLE','DE LOREAN','DE TOMASO','DONKERVOORT','EBRO','FISKER','FORD OTOSAN','FSO',
            //                   'GAZ','GEELY','GEO','GINETTA','GIOTTI VICTORIA','GLAS','GMC','GME','GOUPIL','GUMPERT','HINDUSTAN','HOBBYCAR','INDIGO','INNOCENTI','IRAN KHODRO','IRMSCHER','ISDERA','IVECO','IZH','JENSEN',
            //                   'KTM','LANDWIND','LDV','LIGIER','LINCOLN','LTI','MAHINDRA','MAN','MARCOS','MARUTI','MAYBACH','MEGA','METROCAB','MIA ELECTRIC','MICROCAR','MIDDLEBRIDGE','MINELLI','MITSUOKA','MORGAN','MORRIS',
            //                     'MOSKVICH','MPM MOTORS','NSU','OLDSMOBILE','OLTCIT','OSCA','PAGANI','PANOZ','PANTHER','PGO','PIAGGO','PININFARINA','PLYMOUTH','POLARIS','PONTIAC','PREMIER','PROTON','PUCH','RAM','RANGER',
            //                     'RAYTON FISSORE','RELIANT','RENAULT TRUCK','RILEY','RUF','SANTANA','SATURN','SHELBY','SIPANI','SPECTRE','SPYKER','STANDARD','STANGUELLINI','STEYR','STREETSCOOTER','TALBOT','TATA','TAZZARI',
            //                     'THINK','TRABANT','TRIUMPH','TVR','UAZ','UMM','VAUXHALL','VECTOR','VENTURI','VICTORY','VUHL','WARTBURG','WESTFIELD','WIESMANN','WOLSELEY','YUGO','YULON','ZASTAVA','ZAZ','ZENOS CARS',
            //                     'BEDFORD', 'AIXAM', 'CHATENET', 'CUPRA', 'DAEWOO', 'DAIHATSU', 'De La Chapelle','DODGE', 'FORD USA', 'IRAN KHODRO (IKCO)','HUMMER','INFINITI','ISUZU','LADA','LANDWIND (JMC)','PIAGGIO',
            //                      'RENAULT TRUCKS','SAAB', 'SSANGYONG','VW','LANCIA','ROVER']

            Vue.axios.get('https://api.espace-jantes.com/carMarque').then((response) => {
                response.data.forEach(element => {
                    if(arrayMarqueHide.indexOf(element.Nom) == -1){
                      this.marques.push(element)
                    }
                })
            })

            Vue.axios.get('https://api.espace-jantes.com/marquesJantes').then((response) => {
                response.data.forEach(element => {
                        this.marquesJantes.push(element)
                })
            })
        },
        methods:{
            transformItems(items) {
                // console.log(items)
                return items.map(item => ({
                    ...item,
                    name: item.name,
                }));
            },
            toStoreVoiture:function (marque, id){
                this.$store.dispatch('catalogueJantes/setVoiture', {marque: marque, idMarque: id}).then(() => {
                    this.$router.push({name:'catalogue', params:{marque: this.encoded(marque), id:id}})
                })
            },
            toStoreJante:function (marque){

                this.$store.dispatch('catalogueJantes/setJante', {marque}).then(() => {
                    this.$router.push({name:'catalogue', params:{marque: this.encoded(marque)}})
                })
            },
            encoded:function(nom){
                return(nom.replace(/\s/g, "+"))
            }
        }
    }
</script>

<style scoped>

    .shadow{
        box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07)
    }


    .border_left{
        border-left:solid #ECEBED;
    }


    .half-a-border-on-bottom {
        border-bottom:3px solid #FC5C1F;
        position: relative;
    }
    .half-a-border-on-bottom:after {
        padding:0;margin:0;display:block;/* probably not really needed? */
        content: "";
        width:70%;
        height:3px;
        background-color:#434246;
        position: absolute;
        right:0;
        /*bottom:-1px;*/
    }

    .half-a-border-on-bottom-darker {
        border-bottom:3px solid #FC5C1F;
        position: relative;
    }
    .half-a-border-on-bottom-darker:after {
        padding:0;margin:0;display:block;/* probably not really needed? */
        content: "";
        width:70%;
        height:3px;
        background-color:#373639;
        position: absolute;
        right:0;
        /*bottom:-1px;*/
    }
</style>
