

const state = () =>({
    addr_commande_facturation:{},
    addr_commande_livraison:{},
    notesCommande:''
})



const mutations = {
    setaddr(state, config){
        if(config){
            state.addr_commande_facturation = config.addr_facturation
            state.addr_commande_livraison = config.addr_livraison

        }
    },
    setNotes(state, config){
        if(config){
            state.notesCommande = config
        }
    },



}


const actions = {

}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}