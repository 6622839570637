<template>

    <v-content id="app" fluid >

        <v-col class="py-0">
            <v-row class="bc_lightGrey " v-if="$vuetify.breakpoint.mdAndUp">
                <v-col xl="7" lg="7" md="7" class="garantie">
                    <v-row>
                        <v-col cols="3" class=" py-1 d-flex align-center pl-3">
                            <v-icon v-if="$vuetify.breakpoint.xl" >mdi mdi-credit-card-check</v-icon>
                            <v-icon v-if="$vuetify.breakpoint.lgAndDown" >mdi mdi-credit-card</v-icon>
                            <span class="ml-2
                                        font-weight-regular
                                        text-caption-custom
                                        "><strong>Facilités de paiement<br>en 4x par CB,</strong></span>
                        </v-col>
                        <v-col cols="3" class="py-1 d-flex align-center ">
                            <v-icon v-if="$vuetify.breakpoint.xl" >mdi mdi-truck-delivery</v-icon>
                            <v-icon v-if="$vuetify.breakpoint.lgAndDown" >mdi mdi-truck-delivery</v-icon>
                            <span class="ml-2
                                        font-weight-regular
                                        text-caption-custom
                                        "><strong>Livraison<br>gratuite</strong></span>
                        </v-col>
                        <v-col cols="3" class="py-1 d-flex align-center ">
                            <v-icon v-if="$vuetify.breakpoint.xl" >mdi mdi-emoticon-cool</v-icon>
                            <v-icon v-if="$vuetify.breakpoint.lgAndDown" >mdi mdi-emoticon-cool</v-icon>
                            <span class="ml-2
                                        font-weight-regular
                                        text-caption-custom
                                        "><strong>Satisfait ou<br>remboursé</strong></span>
                        </v-col>
                        <v-col cols="3" class="py-1 d-flex align-center ">
                            <v-icon v-if="$vuetify.breakpoint.xl" >mdi mdi-check-decagram</v-icon>
                            <v-icon v-if="$vuetify.breakpoint.lgAndDown" >mdi mdi-check-decagram</v-icon>
                            <span class="ml-2
                                        font-weight-regular
                                        text-caption-custom
                                        "><strong>Jantes garanties et<br>controlées</strong></span>
                        </v-col>

                    </v-row>
                </v-col>
                <!-- End Garantie -->
                <v-col xl="4" lg="5" md="5" offset-xl="1" offset-md="0" class="garantie">
                    <v-row>
                        <v-col offset="1" cols="2" class="py-0">
<!--                            <v-img contain class="" src="@/assets/logo/home-autoplus.png"/>-->
                            <v-img contain class="" :src="require('@/assets/logo/home-autoplus.png')"/>
                        </v-col>
                        <v-col offset="1" cols="2" class="py-0">
<!--                            <v-img contain class="" src="@/assets/logo/home-cofidispay.png"/>-->
                            <v-img contain class="" :src="require('@/assets/logo/home-cofidispay.png')"/>
                        </v-col>
                        <v-col offset="1" cols="2" class="py-0">
<!--                            <v-img contain class="" src="@/assets/logo/home-avisverifies.png"/>-->
                            <v-img contain class="" :src="require('@/assets/logo/home-avisverifies.png')"/>
                        </v-col>
                    <!-- <v-col class="py-1">

                        </v-col> -->
                        <v-col lg="3" class="py-1 d-flex align-center justify-end ">
                            <RouterLink :to="{name: 'FAQ'}" class="no_deco tc_black">

                                <v-icon v-if="$vuetify.breakpoint.xl" >mdi mdi-help-circle-outline</v-icon>
                                <v-icon v-if="$vuetify.breakpoint.lgAndDown" small>mdi mdi-help-circle-outline</v-icon>
                                <span class="ml-3
                                            font-weight-bold
                                            text-xl-h6
                                            text-lg-caption
                                            text-md-caption">Aide</span>
                            </RouterLink>
                        </v-col>

                    </v-row>
                </v-col>

            </v-row>
            <v-row class="bc_lightGrey " v-if="$vuetify.breakpoint.smAndDown">
                <!-- End Garantie -->
                <v-col cols="12" class="garantie">
                    <v-row>
                        <v-col cols="3" sm="2" class="py-0">
<!--                            <v-img contain class="" src="@/assets/logo/home-autoplus.png"/>-->
                            <v-img contain class="" :src="require('@/assets/logo/home-autoplus.png')"/>
                        </v-col>
                        <v-col cols="3" sm="2" class="py-0">
<!--                            <v-img contain class="" src="@/assets/logo/home-cofidispay.png"/>-->
                            <v-img contain class="" :src="require('@/assets/logo/home-cofidispay.png')"/>

                        </v-col>
                        <v-col cols="3" sm="2" class="py-0">
<!--                            <v-img contain class="" src="@/assets/logo/home-avisverifies.png"/>-->
                            <v-img contain class="" :src="require('@/assets/logo/home-avisverifies.png')"/>

                        </v-col>

                        <v-col  cols="3" sm="5" class="py-1 d-flex align-center justify-end px-0">
                            <RouterLink :to="{name: 'FAQ'}" class="no_deco tc_black">
                                <v-icon v-if="$vuetify.breakpoint.xl" >mdi mdi-help-circle-outline</v-icon>
                                <v-icon v-if="$vuetify.breakpoint.lgAndDown" small>mdi mdi-help-circle-outline</v-icon>
                                <span class="ml-1 mr-2
                                            font-weight-bold
                                            text-xl-h6
                                            text-lg-caption
                                            text-md-caption">Aide</span>
                            </RouterLink>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        <!-- End Preheader -->
<!--            </v-row>-->
        <!-- Header -->

        <v-row>
            <v-col cols="7"  style="padding:10px!important;" class="pl-0">
                <v-col class="pa-0">
                    <router-link title='Espace-Jantes.com : Jantes aux meilleurs prix - Jantes, Pneus et Accessoires' :to="{name: 'Home' }">
    <!--                    <router-link :to="{name: 'Etape6Bis' }">-->
                        <img title='Logo - Espace Jantes' alt='Logo - Espace Jantes' :src="require('@/assets/logo/ej-logo-baseline.svg')" style="background-size:auto;margin-left: 8%" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                                                                                                    $vuetify.breakpoint.lg ? {'max-width' : '40%'} :
                                                                                                                    $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                                                                                                    $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                                                                                                    $vuetify.breakpoint.xs ? {'max-width' : '80%'} : '']"/>
                    </router-link><br>
                </v-col>
                <!-- <v-col offset="1" class="pa-0">
                    <span class="tc_turquoise font-weight-bold
                                    text-xl-h4
                                    text-lg-h5
                                    text-md-h6
                                    text-caption">À vos côtés depuis plus de 10 ans !</span>
                </v-col> -->
            </v-col>
            <v-col cols="5" class="d-flex align-center bc_blue" v-if="$vuetify.breakpoint.mdAndUp">
                <v-col offset="2" cols="3">
                    <router-link title='Contactez-nous - Espace-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'ContactUs'}" class="no_deco ">
                        <v-btn class=" pa-0 " text style="color:white;" >
                            <span class="font-weight-bold
                                        text-xl-h5
                                        text-lg-h6
                                        text-md-body-1"
                                    style="text-transform: capitalize;">Contact</span>
                        </v-btn>
                    </router-link>
                </v-col>
                <v-col cols="4">
                    <router-link title='Mon compte - Espace-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'Account'}" class="no_deco " >
                        <v-btn class="font-weight-bold pa-0"  text style="color:white;font-size: large;text-transform: capitalize;" @click="isConnected" >
                            <span class="font-weight-bold
                                        text-xl-h5
                                        text-lg-h6
                                        text-md-body-1"
                                    style="text-transform: capitalize;">Mon Compte</span>
                        </v-btn>
                    </router-link>
                </v-col>
                <v-col cols="3">
                    <router-link title='Mon panier - Espace-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'Panier'}" class="no_deco"  >

                            <v-img src="@/assets/cart.svg" style="position:relative" :style="[$vuetify.breakpoint.xl ? {'max-width' : '30%'} :
                                                                                            $vuetify.breakpoint.lg ? {'max-width' : '40%'} :
                                                                                            $vuetify.breakpoint.md ? {'max-width' : '45%'} : '']">
                                <span v-if="nbrCart > 0" style="color: white;
                                            position: absolute;
                                            background-color: darkblue;
                                            border: 1px solid azure;
                                            border-radius: 20px;
                                            width: 50%;
                                            text-align: center;"
                                      class="font-weight-bold">{{ nbrCart }}</span>

                            </v-img>

                    </router-link>
                </v-col>
            </v-col>

            <v-col cols="5" class="text-right bc_blue d-flex align-center justify-end" v-if="$vuetify.breakpoint.smAndDown">

                <router-link title='Mon compte - Espace-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'Account'}" class="no_deco " >
                    <v-col  v-if="$vuetify.breakpoint.sm">
                        <v-icon  color="white" @click="isConnected" large>mdi mdi-account-circle</v-icon>
                    </v-col>
                    <v-icon v-if="$vuetify.breakpoint.xs" color="white" @click="isConnected" >mdi mdi-account-circle</v-icon>
                </router-link>

                <router-link title='Mon panier - Espace-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'Panier'}" class="no_deco " >
                        <v-btn text small>
                            <v-img src="@/assets/cart.svg" style="position:relative" :style="[$vuetify.breakpoint.sm ? {'max-width' : '85%'} :
                                                                                            $vuetify.breakpoint.xs ? {'max-width' : '75%'} : '']">
                                <span v-if="nbrCart > 0" style="color: white;
                                            position: absolute;
                                            background-color: darkblue;
                                            border: 1px solid azure;
                                            border-radius: 20px;
                                            width: 50%;
                                            left:0;
                                            text-align: center;"
                                      class="font-weight-bold">{{ nbrCart }}</span>
                            </v-img>
                        </v-btn>
                </router-link>

                <v-col cols="4" v-if="$vuetify.breakpoint.sm">
                    <v-icon  @click="showMenu" color="white" large>mdi mdi-menu</v-icon>
                </v-col>
                <v-icon v-if="$vuetify.breakpoint.xs" @click="showMenu" color="white" >mdi mdi-menu</v-icon>

            </v-col>
        </v-row>
        <!-- End Header -->

        </v-col>




        <v-content v-if="menu" class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">
            <v-navigation-drawer v-if="$vuetify.breakpoint.smAndDown" v-model="menu" temporary fixed  width="60%" style=" top:0; left:0; overflow-y:scroll;">
                <v-col cols="11"  class="text-right" >
                    <v-icon @click="menu = false" color="black" large>mdi mdi-close</v-icon>
                </v-col>

<!--                <v-col  offset="1" cols="10" class="pa-0 ej-menu-rwd">-->
<!--                    <router-link :to="{ name: ''}" class="no_deco ">-->
<!--                        <v-btn class="font-weight-bold"  width="100%"   text style="text-transform: capitalize;">Expert en jantes</v-btn>-->
<!--                    </router-link>-->
<!--                </v-col>-->
                <v-col  offset="1" cols="10" class="pa-0 ej-menu-rwd">
                    <router-link title='FAQ - Espace-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'FAQ'}" class="no_deco ">
                        <v-btn class="font-weight-bold"  width="100%"   text style="text-transform: capitalize;">Aide</v-btn>
                    </router-link>
                </v-col>
                <!-- <v-col  offset="1" cols="10" class="pa-0 ej-menu-rwd">
                    <router-link :to="{ name: ''}" class="no_deco ">
                        <v-btn class="font-weight-bold"  width="100%"   text style="text-transform: capitalize;">Modes de paiement</v-btn>
                    </router-link>
                </v-col> -->
                <v-col  offset="1" cols="10" class="pa-0 ej-menu-rwd">
                    <router-link title='Livraison - Espace-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'Livraison'}" class="no_deco ">
                        <v-btn class="font-weight-bold"  width="100%"   text style="text-transform: capitalize;">Livraison</v-btn>
                    </router-link>
                </v-col>

                <v-col  offset="1" cols="10" class="pa-0 ej-menu-rwd">
                    <router-link title='Contactez-nous - Espace-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'ContactUs'}" class="no_deco ">
                        <v-btn class="font-weight-bold"  width="100%"   text style="text-transform: capitalize;">Contact</v-btn>
                    </router-link>
                </v-col>

<!--                <v-col style="position: absolute; bottom: 0">-->
<!--                    <v-row>-->
<!--                        <v-col cols="3" class="">-->
<!--                            <v-img src="@/assets/logo/auto-plus.png"/>-->
<!--                        </v-col>-->
<!--                        <v-col cols="5" class="px-0">-->
<!--                            <v-img src="@/assets/logo/logo-Cofidis-Pay_4xcb.png"/>-->
<!--                        </v-col>-->
<!--                        <v-col cols="3" class="pa-0">-->
<!--                            <v-img src="@/assets/logo/logoAvisVerifies.jpg"/>-->
<!--                        </v-col>-->
<!--                    </v-row>-->
<!--                </v-col>-->


<!--                <v-col style="position: absolute; bottom: 0" class="pb-0">-->
<!--                    <v-row class="bc_lightGrey">-->
<!--                        <v-col offset="2" cols="4" class="px-0 d-flex align-center">-->
<!--                            <v-img src="@/assets/logo/logo-Cofidis-Pay_4xcb.png"/>-->
<!--                        </v-col>-->
<!--                        <v-col cols="4" class="d-flex align-center">-->
<!--                            <v-img src="@/assets/logo/avisverifies.png"/>-->
<!--                        </v-col>-->
<!--                        -->
<!--                    </v-row>-->
<!--                </v-col>-->
            </v-navigation-drawer>

        </v-content>
    </v-content>

</template>

<script>

import axios from "axios";
import {mapState} from "vuex";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

export default {
    name: "HomepageHeaderEj",
    data: () => ({
        menu: false,
        drawer:false,
        nbrCartCounter: 0,
        SelectedJanteAv: {},
        SelectedJanteArr: {},
        SelectedPneuAv: {},
        SelectedPneuArr: {},
        monteDecaJante: false,
        monteDecaPneu: false,
    }),
    methods: {
        showMenu:function(){
            this.menu = !this.menu
        },
        isConnected:function () {
            const myLoginRoutine = user => new Promise ((resolve, reject) => {
                axios.get('https://api.espace-jantes.com/verificationToken', {
                    headers: {
                        Authorization: 'Bearer ' + user.token,
                    }
                })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        // localStorage.removeItem('user-token')
                        ls.remove('user-token')
                        reject(err)
                    })
            })

            // let token = localStorage.getItem("user-token")
            let token = ls.get("user-token")

            myLoginRoutine({ token }).then(() => {
                this.$emit('connect', true)
            })
        }
    },
    created(){

        this.SelectedJanteAv = ls.get(ls.get('last_updated') + '__infosJantes').jantesAv ? ls.get(ls.get('last_updated') + '__infosJantes').jantesAv : null
        this.SelectedJanteArr = ls.get(ls.get('last_updated') + '__infosJantes').jantesArr ? ls.get(ls.get('last_updated') + '__infosJantes').jantesArr : null
        this.SelectedPneuAv = ls.get(ls.get('last_updated') + '__infosPneus').pneusAv ? ls.get(ls.get('last_updated') + '__infosPneus').pneusAv : null
        this.SelectedPneuArr = ls.get(ls.get('last_updated') + '__infosPneus').pneusArr ? ls.get(ls.get('last_updated') + '__infosPneus').pneusArr : null
        this.SelectedAccessoires = ls.get(ls.get('last_updated') + '__infosAccessoires').accessoires ? ls.get(ls.get('last_updated') + '__infosAccessoires').accessoires : null

        var splitedUrl = ls.get('last_updated').split('/')

        this.typeMonte =  splitedUrl.splice(-1, 1).toString() === '0' ? 'par4' : 'decale'
        this.typeMonte === 'decale' ? this.monteDecaJante = true : this.monteDecaJante = false
        this.typeMonte === 'decale' ? this.monteDecaPneu = true : this.monteDecaPneu = false
    },
    mounted() {

        if((JSON.stringify(this.SelectedJanteAv) === JSON.stringify(this.SelectedJanteArr)) && this.SelectedJanteAv ) {

            if(this.monteDecaJante) {
                if (JSON.stringify(this.SelectedJanteAv.largeurMinAv) === JSON.stringify(this.SelectedJanteAv.largeurMaxArr)) {
                    // this.nbrCart ++

                    this.nbrCartCounter ++
                    this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)

                }
                else if (JSON.stringify(this.SelectedJanteAv.largeurMinAv) !== JSON.stringify(this.SelectedJanteAv.largeurMaxArr)) {
                    // this.nbrCart += 2

                    this.nbrCartCounter += 2
                    this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                }
            }
            else {
                // this.nbrCart ++

                this.nbrCartCounter ++
                this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
            }
        }
        else if(this.SelectedJanteAv && this.SelectedJanteArr) {

            this.nbrCartCounter += 2
            this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
        }


        if((JSON.stringify(this.SelectedPneuAv) === JSON.stringify(this.SelectedPneuArr)) && this.SelectedPneuAv ) { //Si pneu avant === pneu arriere

            if(this.monteDecaPneu === true) { //Si monte décalée
                if (this.SelectedPneuAv.HBV === this.SelectedPneuAv.HBVREAR) { //Si pneu avant  HBV === pneu arriere HBVREAR => pneu X4
                    // this.nbrCart ++

                    this.nbrCartCounter ++
                    this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                }
                else if (this.SelectedPneuAv.HBV !== this.SelectedPneuAv.HBVREAR) { //Si pneu avant  HBV !== pneu arriere HBVREAR => pneuAvant X2 && pneuArriere X2
                    // this.nbrCart += 2

                    this.nbrCartCounter += 2
                    this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                }
            }
            else{ //Si monte par 4
                // this.nbrCart ++

                this.nbrCartCounter ++
                this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
            }
        }
        else if(this.SelectedPneuAv && this.SelectedPneuArr) { //Sinon si pneu avant !== pneu arriere

            this.nbrCartCounter += 2
            this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
        }

        if(this.SelectedAccessoires) { //Si accessoires

            Object.values(this.SelectedAccessoires).forEach((accessoire) => {
                if (accessoire != null) {

                    this.nbrCartCounter ++
                    this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                }

            })
        }
    },
    computed: {

        ...mapState({
            // SelectedJanteAv : state => state.infoJantes.SelectedJanteAv,
            // SelectedJanteArr : state => state.infoJantes.SelectedJanteArr,
            // SelectedPneuAv : state => state.infoPneus.SelectedPneuAv,
            // SelectedPneuArr : state => state.infoPneus.SelectedPneuArr,
            // monteDecaJante : state => state.infoJantes.monteDecaJante,
            // monteDecaPneu : state => state.infoPneus.monteDecaPneu,
            // SelectedAccessoires : state => state.infoAccessoires.SelectedAccessoires,
            nbrCart : state => state.nbrCart.nbrCart,
        })

    },
};
</script>

<style scoped>
.text-caption-custom {
    font-size: 0.7rem;
    line-height: 0.8rem;
}
.garantie .v-icon {
    background: #fff;
    border-radius: 50px;
    color:rgb(35, 45, 77);
    padding:0.35rem
}
.ej-menu-rwd {
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.ej-menu-rwd:after {
    content:"";
    position: absolute;
    top:100%;
    right: 0;
    bottom:0;
    left:0;
    background:linear-gradient(130deg, rgba(56,160,244,1) 0%, rgba(29,228,175,1) 100%);;
    height:2px;
}
@media (min-width: 960px) and (max-width:1264px) {
    .text-caption-custom {
    font-size: 0.6rem;
    line-height: 0.7rem;
}
}
</style>
