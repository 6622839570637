<template>

    <v-app fluid >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>

        <v-main  v-if="VUE_APP_SITE_ID === '1'">
            <v-col class="py-0" >
                <v-row class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 tc_blue font-weight-bold rotate">
                            Jantes - Permutation
                        </h1>

                    </v-col>
                    <v-col lg="5" >
                        <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5 tc_blue font-weight-bold ">
                            Jantes - Permutation
                        </h1>
                        <p class="font-weight-bold
                               text-xl-h5
                               text-lg-h6
                               text-md-subtitle-1
                               text-sm-subtitle-1
                               text-subtitle-2 ">JANTES – PERMUTATION – CHANGEMENT DE JANTES</p>
                        <br>

                        <p>
                            Vous pouvez changer la dimension de vos jantes et de vos pneus par rapport aux roues d’origine.
                            Dans ce cas, il faut rester vigilant, la conduite du véhicule sera modifiée.
                            La consommation de carburant, le freinage ainsi que la performance du moteur vont varier.
                            Néanmoins, pour des raisons de sécurité, le choix de vos nouvelles roues doit rester proche de celles préconisées par le constructeur.
                        </p>
                        <p class=" mb-3 pl-4 border_left tc_turquoise font-weight-bold
                                            text-xl-h5
                                        text-lg-body-1
                                        text-md-body-1
                                        text-sm-body-1
                                        text-body-1" >
                            Avec des roues surbaissées et des flancs réduits, la conduite sera généralement plus ferme.
                            <br>
                            Avec des jantes plus larges, la direction de la voiture sera plus sensible et son comportement dans les courbes sera plus précis.
                        </p>
                        <p>
                            En augmentant la largeur des jantes – permutation, vous découvrirez de nouvelles sensations de conduite due en partie à la réactivité des pneumatiques en contact avec la route.
                        </p>

                    </v-col>
                    <v-col class="bc_blue" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-img src="@/assets/AboutUs.png"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phone.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold tc_blue align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="3" xl="3" class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2" offset-lg="1" xl="3" lg="3">
                        <v-img style="position: absolute; width: 20%;" src="@/assets/illustration-man.png"/>
                    </v-col>
                </v-row>
            </v-container>

            <v-col  v-if="$vuetify.breakpoint.lgAndUp" class="bc_white tc_black" >
                <v-row class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col offset-lg="1" lg="11">
                        <br>
                        <br>
                        <p class="font-weight-bold
                               text-xl-h5
                               text-lg-h6
                               text-md-subtitle-1
                               text-sm-subtitle-1
                               text-subtitle-2 ">JANTES – PERMUTATION – LE DÉPORT ET L’ENTRAXE</p>
                        <br>
                        <v-row>
                            <v-col lg="5">
                                <p class="font-weight-bold
                               text-xl-h5
                               text-lg-h6
                               text-md-subtitle-1
                               text-sm-subtitle-1
                               text-subtitle-2 ">LE DÉPORT</p>
                                <p>
                                    Si vous souhaitez changer de jante, il est indispensable de calculer le déport.
                                    Pour établir l’ET, il faut prendre les caractéristiques actuelles indiquées sur la jante.
                                    Prenez le diamètre de votre jante et convertissez le en centimètre (1pouce = 2.54 cm) puis divisez le par 2 et soustraire l’ET (convertis en cm).
                                    Vous procédez de la même façon avec la future jante.
                                    Ensuite, vous allez soustraire les résultats ainsi obtenus soit jante actuelle moins future jante.
                                    Si le déport est négatif, la nouvelle jante sera en retrait par rapport à l’ancienne.
                                    Si le déport est positif, la nouvelle jante sera saillante par rapport à l’ancienne.
                                    Les pneumatiques devront être adaptés à ces nouvelles jantes, car eux aussi ont un calcul de déport.
                                </p>
                                <br>
                                <p>
                                    Vous pouvez aussi changer de jantes sans faire varier le déport.
                                    Il suffit de prendre des jantes – permutation de diamètre strictement identiques mais de largeurs différentes.
                                    Dans ce cas, il y a uniquement la cote de parallélisme qui est modifiée.
                                    Les autres organes de votre voiture sont préservés.
                                    Vous pouvez augmenter encore la cote d’appui en installant des pneus nettement plus larges.
                                    Vous devez vous assurez que le pneu et la jante ne touche pas à l’amortisseur, ni à la carrosserie.
                                    Si vous êtes trop juste vous pouvez monter une entretoise ou bien un élargisseur de voie.
                                </p>
                            </v-col>
                            <v-col offset-lg="1" lg="4">
                                <p class="font-weight-bold
                               text-xl-h5
                               text-lg-h6
                               text-md-subtitle-1
                               text-sm-subtitle-1
                               text-subtitle-2 ">L’ENTRAXE</p>
                                <p>
                                    Vous pouvez modifier, adapter l’entraxe d’une jante.
                                    Dans ce cas, on utilise un élargisseur de voies.
                                    Soit pour un motif esthétique, vous souhaitez que vos jantes dépassent la largeur de la carrosserie de la voiture.
                                    Soit vous pouvez être amené à employer des élargisseurs de voies si vous avez une ancienne voiture à rénover ou bien que le déport de votre véhicule soit insuffisant suite à l’achat de jantes spécifiques.
                                </p>
                                <br>
                                <p>
                                    Si vous modifier l’entraxe de votre voiture, vous pouvez être amené à percer les tambours ou les disques de frein, ou tout simplement acquérir un nouvel équipement de freinage.
                                    L’entraxe a une influence dans les courbes puisqu’il tient compte de la longueur du véhicule.
                                    Chaque marque détermine sous forme de tableau l’entraxe de ses jantes – permutation suivant le modèle de la voiture.
                                </p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col lg="7">
                                <p class="font-weight-bold
                               text-xl-h5
                               text-lg-h6
                               text-md-subtitle-1
                               text-sm-subtitle-1
                               text-subtitle-2 ">JANTES – PERMUTATION – L’ÉLARGISSEUR DE VOIES</p>

                                <p>
                                    Vous trouverez des kits complets d’élargisseurs qui se composent de cales et de boulons nécessaires au montage.
                                    Ils sont fabriqués en aluminium de haute résistance.
                                    Ces adaptateurs vous permettent de monter des jantes d’autres provenances sur votre voiture.
                                    Néanmoins, vous devez respecter des normes de sécurité.
                                    Ce genre de montage occasionne souvent des vibrations car le centrage de la roue est modifié.
                                </p>
                                <br>
                                <p>
                                    Il existe plusieurs sortes d’élargisseurs de voies.
                                    Celui nommé élargisseur universel est certes peu coûteux, mais très peu fiable.
                                    La sécurité n’est plus garantie car il peut provoquer des vibrations pouvant aller jusqu’à la rupture des goujons de la roue.
                                    Le poids de la voiture s’appuie davantage sur les vis de la jante que sur le moyeu.
                                </p>
                                <br>
                                <p>
                                    Vous avez aussi les élargisseurs spécifiques sans double centrage.
                                    Ils sont donc homologués, spécifiques et s’adaptent précisément à l’entraxe de votre voiture et au moyeu de la jante.
                                    L’épaisseur de l’élargisseur de voies ne doit pas être supérieure à 3 mm, sinon les risques de rupture et de vibrations sont présents.
                                    Vous pouvez aussi mettre un élargisseur de voie à double centrage.
                                    Il autorise une épaisseur jusqu’à 25 mm, sans remettre en cause la sécurité du véhicule.
                                    Bien entendu vous devez employer des boulons plus longs pour serrer vos jantes – permutation.
                                </p>
                                <br>
                                <p>
                                    Le meilleur des élargisseurs est celui à double centrage et à double boulonnage.
                                    Vous pouvez sans danger dépasser l’épaisseur des 25 mm.
                                    L’entraxe est modifié mais votre sécurité ainsi que la visserie de la jante sont garanties.
                                    Les cales de conversions peuvent avoir une longueur jusqu’à 20 cm.
                                    Néanmoins, il faut respecter la portée de vos jantes en choisissant le type de visserie adapté, boule, conique ou plat et vérifier la longueur.
                                </p>

                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <p>Pour en savoir plus sur les jantes, le lecteur pourra se reporter aux différents articles qui traitent de cette question, sur le présent site.</p>
                                <p>Droit d’auteur protégé par les articles L. 111-1 et L. 123-1, du code de la propriété intellectuelle.</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>

            <v-col  v-if="$vuetify.breakpoint.mdAndDown" class="bc_white tc_black" >
            <v-row class="font-weight-regular
                                    text-xl-h5
                                    text-lg-body-1
                                    text-md-caption
                                    text-sm-caption
                                    text-caption">
                <v-col>
                    <br>
                    <br>
                    <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2 ">JANTES – PERMUTATION – LE DÉPORT ET L’ENTRAXE</p>
                    <br>
                    <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2 ">LE DÉPORT</p>
                    <p>
                        Si vous souhaitez changer de jante, il est indispensable de calculer le déport.
                        Pour établir l’ET, il faut prendre les caractéristiques actuelles indiquées sur la jante.
                        Prenez le diamètre de votre jante et convertissez le en centimètre (1pouce = 2.54 cm) puis divisez le par 2 et soustraire l’ET (convertis en cm).
                        Vous procédez de la même façon avec la future jante.
                        Ensuite, vous allez soustraire les résultats ainsi obtenus soit jante actuelle moins future jante.
                        Si le déport est négatif, la nouvelle jante sera en retrait par rapport à l’ancienne.
                        Si le déport est positif, la nouvelle jante sera saillante par rapport à l’ancienne.
                        Les pneumatiques devront être adaptés à ces nouvelles jantes, car eux aussi ont un calcul de déport.
                    </p>
                    <br>
                    <p>
                        Vous pouvez aussi changer de jantes sans faire varier le déport.
                        Il suffit de prendre des jantes – permutation de diamètre strictement identiques mais de largeurs différentes.
                        Dans ce cas, il y a uniquement la cote de parallélisme qui est modifiée.
                        Les autres organes de votre voiture sont préservés.
                        Vous pouvez augmenter encore la cote d’appui en installant des pneus nettement plus larges.
                        Vous devez vous assurez que le pneu et la jante ne touche pas à l’amortisseur, ni à la carrosserie.
                        Si vous êtes trop juste vous pouvez monter une entretoise ou bien un élargisseur de voie.
                    </p>
                    <br>
                    <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2 ">L’ENTRAXE</p>
                    <p>
                        Vous pouvez modifier, adapter l’entraxe d’une jante.
                        Dans ce cas, on utilise un élargisseur de voies.
                        Soit pour un motif esthétique, vous souhaitez que vos jantes dépassent la largeur de la carrosserie de la voiture.
                        Soit vous pouvez être amené à employer des élargisseurs de voies si vous avez une ancienne voiture à rénover ou bien que le déport de votre véhicule soit insuffisant suite à l’achat de jantes spécifiques.
                    </p>
                    <br>
                    <p>
                        Si vous modifier l’entraxe de votre voiture, vous pouvez être amené à percer les tambours ou les disques de frein, ou tout simplement acquérir un nouvel équipement de freinage.
                        L’entraxe a une influence dans les courbes puisqu’il tient compte de la longueur du véhicule.
                        Chaque marque détermine sous forme de tableau l’entraxe de ses jantes – permutation suivant le modèle de la voiture.
                    </p>
                    <br>
                    <br>
                    <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2 ">JANTES – PERMUTATION – L’ÉLARGISSEUR DE VOIES</p>

                    <p>
                        Vous trouverez des kits complets d’élargisseurs qui se composent de cales et de boulons nécessaires au montage.
                        Ils sont fabriqués en aluminium de haute résistance.
                        Ces adaptateurs vous permettent de monter des jantes d’autres provenances sur votre voiture.
                        Néanmoins, vous devez respecter des normes de sécurité.
                        Ce genre de montage occasionne souvent des vibrations car le centrage de la roue est modifié.
                    </p>
                    <br>
                    <p>
                        Il existe plusieurs sortes d’élargisseurs de voies.
                        Celui nommé élargisseur universel est certes peu coûteux, mais très peu fiable.
                        La sécurité n’est plus garantie car il peut provoquer des vibrations pouvant aller jusqu’à la rupture des goujons de la roue.
                        Le poids de la voiture s’appuie davantage sur les vis de la jante que sur le moyeu.
                    </p>
                    <br>
                    <p>
                        Vous avez aussi les élargisseurs spécifiques sans double centrage.
                        Ils sont donc homologués, spécifiques et s’adaptent précisément à l’entraxe de votre voiture et au moyeu de la jante.
                        L’épaisseur de l’élargisseur de voies ne doit pas être supérieure à 3 mm, sinon les risques de rupture et de vibrations sont présents.
                        Vous pouvez aussi mettre un élargisseur de voie à double centrage.
                        Il autorise une épaisseur jusqu’à 25 mm, sans remettre en cause la sécurité du véhicule.
                        Bien entendu vous devez employer des boulons plus longs pour serrer vos jantes – permutation.
                    </p>
                    <br>
                    <p>
                        Le meilleur des élargisseurs est celui à double centrage et à double boulonnage.
                        Vous pouvez sans danger dépasser l’épaisseur des 25 mm.
                        L’entraxe est modifié mais votre sécurité ainsi que la visserie de la jante sont garanties.
                        Les cales de conversions peuvent avoir une longueur jusqu’à 20 cm.
                        Néanmoins, il faut respecter la portée de vos jantes en choisissant le type de visserie adapté, boule, conique ou plat et vérifier la longueur.
                    </p>
                    <br>
                    <p>Pour en savoir plus sur les jantes, le lecteur pourra se reporter aux différents articles qui traitent de cette question, sur le présent site.</p>
                    <p>Droit d’auteur protégé par les articles L. 111-1 et L. 123-1, du code de la propriété intellectuelle.</p>

                </v-col>
            </v-row>
        </v-col>
        </v-main>


        <v-main v-else-if="VUE_APP_SITE_ID === '2'">
            <v-col class="py-0" >
                <v-row class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 font-weight-bold rotate">
                            Jantes - Permutation
                        </h1>

                    </v-col>
                    <v-col lg="5" >
                        <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5 font-weight-bold ">
                            Jantes - Permutation
                        </h1>
                        <p class="font-weight-bold
                               text-xl-h5
                               text-lg-h6
                               text-md-subtitle-1
                               text-sm-subtitle-1
                               text-subtitle-2 ">Changement de dimension de jantes</p>
                        <br>

                        <p>
                            Peut-on changer la dimension des jantes et des pneus d’un véhicule ? Si oui quelles en sont les incidences directes et quelles consignes particulières respecter ?
                        </p>
                        <p>
                            Tout d’abord, un changement de la dimension des jantes et des pneus, par rapport à celle d’origine, est possible sous certaines conditions et avec certaines précautions.
                            Toutefois, Il faudra ne pas s’éloigner des normes-constructeurs et tenir compte des paramètres suivants :
                        </p>
                        <p>
                            -Trou central de la jante identique ou supérieur au diamètre du moyeu. Des bagues de centrage peuvent permettre de rectifier les différences en cas d’alésage supérieur,
                        </p>
                        <p>
                            -Entraxe : identique ou adaptable (ajout d’élargisseurs de voie),
                        </p>
                        <p>
                            -Déport.
                        </p>
                        <p>
                            D’un point de vue schématique, l’installation de jantes plus larges sur un véhicule influe sur la sensibilité de la direction ainsi que sur sa tenue de route dans les virages.
                        </p>


                    </v-col>
                    <v-col class="pa-0" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-img src="@/assets/AboutUsDJ.png"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phoneDJ.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33974191801">09 74 19 18 01</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="5" offset-lg="1"  class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>

                </v-row>
            </v-container>

            <v-col class="bc_white tc_black" >
                <v-row class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col offset-xl="1" offset-lg="1" xl="11" lg="11" cols="12">
                        <v-main v-if="$vuetify.breakpoint.lgAndUp">
                            <br>
                            <br>
                        </v-main>

                        <v-row>
                            <v-col xl="5" lg="5" cols="12">
                                <p class="font-weight-bold
                               text-xl-h5
                               text-lg-h6
                               text-md-subtitle-1
                               text-sm-subtitle-1
                               text-subtitle-2 ">Calcul du déport de la jante</p>
                                <br>
                                <p>
                                    Vous pouvez choisir une jante de dimension différente sans impact sur le déport ce qui revient à choisir des jantes de largeurs différentes et de diamètre similaire. Le parallélisme sera modifié. Si vous installez des pneus plus larges, veillez à ce que les roues ne viennent pas « caresser » amortisseurs et carrosserie.
                                </p>
                                <p>
                                    Dans d’autres cas, un calcul du déport est nécessaire.
                                </p>
                                <p>
                                    Il vous faudra estimer le déport de la jante (ET) existante et celui de la future jante à monter.
                                    Une soustraction entre les deux valeurs ET ainsi obtenues (Et jante en place – ET nouvelle jante) donnera un résultat positif ou négatif.
                                </p>
                                <p>Déport négatif : la nouvelle jante est « enfoncée » par rapport à la jante retirée.
                                    <br>
                                    Déport positif : la jante à monter déborde par rapport à la jante d’origine.
                                </p>
                                <p>
                                    Important : adapter les pneumatiques à ces nouvelles jantes.
                                </p>
                            </v-col>
                            <v-col xl="5" lg="5" cols="12">
                                <p class="font-weight-bold
                               text-xl-h5
                               text-lg-h6
                               text-md-subtitle-1
                               text-sm-subtitle-1
                               text-subtitle-2 ">Entraxe de la jante et élargisseurs de voie</p>
                                <br>

                                <p>
                                    Il est possible d’intervenir sur l’entraxe pour que les jantes de votre voiture débordent de la carrosserie, en intégrant des élargisseurs de voie.
                                </p>
                                <p>
                                    Audi, BMW, Porsche, Volkswagen…chaque marque automobile propose un tableau de correspondance entre modèle de véhicule et entraxe de jantes.
                                </p>
                                <p>
                                    Les élargisseurs de voie se présentent sous la forme de cales que l’on insère entre la jante et le moyeu pour intervenir au niveau de l’écart entre les deux roues. Ils permettent d’adapter sur une voiture des jantes différentes à celles d’origine
                                </p>
                                <p>
                                    Ils se doivent d’être nécessairement très résistants et relativement légers d’où leur conception en aluminium. Ils sont livrés en packs prêts au montage (boulons fournis).
                                    Attention à bien respecter les consignes de sécurité lors du montage notamment pour éviter les vibrations.
                                </p>
                                <p>
                                    Vous retrouverez sur le marché différents types d’élargisseurs de voie :
                                </p>
                                <p>
                                    -Elargisseurs universels : économiques mais d’une fiabilité relative car ils génèrent des vibrations et à terme un risque de rupture des boulons de roue (goujons).
                                </p>
                                <p>
                                    -Elargisseurs sans double centrage spécifiques à l’entraxe et au moyeu de votre voiture.
                                    <br>
                                    Attention : l’épaisseur ne doit pas dépasser 3 mm maxi au risque de provoquer des vibrations et que les goujons ne résistent pas.
                                </p>
                                <p>
                                    -Elargisseurs à double centrage et offrent une épaisseur possible de 25 mm (pour les serrage des jantes : prévoir des boulons plus longs)
                                </p>
                                <p>
                                    -Elargisseurs double centrage, double boulonnage : on peut ici sans risque aller au-delà des 25 mm d’épaisseur.
                                </p>
                                <p>
                                    Petite précision finale sur la réglementation en matière de changement de dimension des jantes : les roues ne doivent pas déborder par rapport à l’axe d’alignement des ailes de voiture.
                                </p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-main>

        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "../headers/HomepageHeaderEj";
import HomepageHeaderDj from "../headers/HomepageHeaderDj";
import HomepageFooterEj from "../footer/HomepageFooterEj";
import HomepageFooterDj from "../footer/HomepageFooterDj";

    export default {
        name: "JantesPermutation",
        components:{
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,

        },
        data: () => ({
            VUE_APP_SITE_ID:null
        }),
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        },
        metaInfo() {
            return {
                title: `Permutation - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + `  - Jantes, Pneus & Accessoires`,
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `Permutation - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {name: 'twitter:description', content: `Découvrez les détails de la permutation de vos jantes. ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes` : `Distri Jantes`) + `, le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.`},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `Permutation -` + this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com` + ` - Jantes, Pneus & Accessoires`},
                    {property: 'og:site_name', content: this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`},
                    {property: 'og:type', content: 'Website'},
                    {property: 'og:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},
                    {property: 'og:description', content: `Découvrez les détails de la permutation de vos jantes. ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    {name: 'robots', content: 'index,follow'}
                ]
            }
    },
    }
</script>

<style scoped>
    .border_left{
        border-left: 2px solid #1DE4AF
    }
    .rotate {
        transform: rotate(-90deg);
        margin-left: -8%;
        margin-top: 14%;
        position: absolute;
    }
</style>
