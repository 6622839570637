<template>

    <v-app  class="backgroud_gradient" fluid >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>

              <v-col class="bc_white text-center">
                  <p class="font-weight-bold" :class="{'display-2': $vuetify.breakpoint.mdAndUp,'display-1': $vuetify.breakpoint.smAndDown}">Oups !</p>
                  <p class="font-weight-bold" :class="{'headline': $vuetify.breakpoint.mdAndUp,'title': $vuetify.breakpoint.smAndDown}">CETTE PAGE N’EXISTE PAS OU N’EXISTE PLUS.</p>

                  <RouterLink :to="{name: 'Home'}" class="no_deco">
                      <v-btn>Retour à la page d’accueil</v-btn>
                  </RouterLink>


              </v-col>


        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
    import HomepageHeaderEj from "../headers/HomepageHeaderEj";
    import HomepageHeaderDj from "../headers/HomepageHeaderDj";
    import HomepageFooterEj from "../footer/HomepageFooterEj";
    import HomepageFooterDj from "../footer/HomepageFooterDj";

    export default {
        name: "404",
        components:{
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,

        },
        data: () => ({
            VUE_APP_SITE_ID:null
        }),
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        }
    }
</script>

<style scoped>
    .border_left{
        border-left: 2px solid #1DE4AF
    }
    .rotate {
        transform: rotate(-90deg);
        margin-left: -10%;
        margin-top: 12%;
        position: absolute;
    }
</style>
