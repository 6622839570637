

const state = () =>({
    nbrCart:''
})



const mutations = {
    setNbrCart(state, config){

        state.nbrCart = config
    }
}


const actions = {

}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}