<template>

    <v-app   fluid >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>

        <v-main v-if="VUE_APP_SITE_ID === '1'">

            <v-col class="py-0" >
            <v-row>
                <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                    <h1 class="text-xl-h2 text-lg-h3 tc_blue font-weight-bold rotate">
                        Nos engagements
                    </h1>
                </v-col>
                <v-col lg="5" >
                    <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5  tc_blue font-weight-bold ">
                        Nos engagements
                    </h1>
                    <v-content class="font-weight-regular
                                    text-xl-h5
                                    text-lg-body-1
                                    text-md-caption
                                    text-sm-caption
                                    text-caption">
                        <p>Notre site est actualisé quotidiennement</p>
                        <p class=" mb-3 pl-4 border_left tc_turquoise headline font-weight-bold">La société espace-jantes s’engage à répondre aux besoins de ses clients et met tout en œuvre pour les satisfaire.</p>
                        <p>- Notre équipe commerciale est à votre disposition pour vous guider dans votre choix de pneumatiques à prix discount et jantes pas chères.</p>
                        <p>- Notre service commercial répond à vos courriels en moins de 3 jours ouvrés.</p>
                        <p>- La livraison de vos jantes et pneus pas chères à l’adresse de votre choix est assurée par un réseau de professionnels de renom réputés pour leur fiabilité.</p>
                        <p>- Nous contrôlons chaque commande avant son expédition.</p>
                        <p>- Nous vous livrons à votre convenance soit à votre domicile, soit sur votre lieu de travail ou dans un centre de montage.</p>
                        <p>- Vous bénéficiez d’une grande Qualité de service.</p>
                        <p>- Sécurité de paiement en ligne.</p>
                        <p>- Qualité accessible grâce à une diversité de jantes et pneus de marques pas chères garanties au meilleur prix.</p>
                    </v-content>
                </v-col>
                <v-col class="bc_blue" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                    <v-img src="@/assets/AboutUs.png"/>
                </v-col>
            </v-row>
        </v-col>

        </v-main>

        <v-main v-if="VUE_APP_SITE_ID === '2'">

            <v-col class="py-0" >
            <v-row>
                <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                    <h1 class="text-xl-h2 text-lg-h3 font-weight-bold rotate">
                        Nos engagements
                    </h1>
                </v-col>
                <v-col lg="5" >
                    <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5  font-weight-bold ">
                        Nos engagements
                    </h1>
                    <v-content class="font-weight-regular
                                    text-xl-h5
                                    text-lg-body-1
                                    text-md-caption
                                    text-sm-caption
                                    text-caption">
                        <p>Notre site est actualisé quotidiennement</p>
                        <p >La société distri-jantes s’engage à répondre aux besoins de ses clients et met tout en œuvre pour les satisfaire.</p>
                        <p>- Notre équipe commerciale est à votre disposition pour vous guider dans votre choix de pneumatiques à prix discount et jantes pas chères.</p>
                        <p>- Notre service commercial répond à vos courriels en moins de 3 jours ouvrés.</p>
                        <p>- La livraison de vos jantes et pneus pas chères à l’adresse de votre choix est assurée par un réseau de professionnels de renom réputés pour leur fiabilité.</p>
                        <p>- Nous contrôlons chaque commande avant son expédition.</p>
                        <p>- Nous vous livrons à votre convenance soit à votre domicile, soit sur votre lieu de travail ou dans un centre de montage.</p>
                        <p>- Vous bénéficiez d’une grande Qualité de service.</p>
                        <p>- Sécurité de paiement en ligne.</p>
                        <p>- Qualité accessible grâce à une diversité de jantes et pneus de marques pas chères garanties au meilleur prix.</p>
                    </v-content>
                </v-col>
                <v-col class="pa-0" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                    <v-img src="@/assets/AboutUsDJ.png"/>
                </v-col>
            </v-row>
        </v-col>

        </v-main>


        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "../headers/HomepageHeaderEj";
import HomepageHeaderDj from "../headers/HomepageHeaderDj";
import HomepageFooterEj from "../footer/HomepageFooterEj";
import HomepageFooterDj from "../footer/HomepageFooterDj";

    export default {
        name: "NosEngagements",
        components:{
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,

        },
        data: () => ({
            VUE_APP_SITE_ID:null
        }),
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        },
        metaInfo() {
            return {
                title: `Nos engagements - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + `  - Jantes, Pneus & Accessoires`,
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `Nos engagements - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {name: 'twitter:description', content: `Découvrez les engagements ` + (this.VUE_APP_SITE_ID === '1' ? `d'Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `de Distri Jantes l'expert en ligne des jantes`)},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `Nos engagements -` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {property: 'og:site_name', content: this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`},
                    {property: 'og:type', content: 'Website'},
                    {property: 'og:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},
                    {property: 'og:description', content: `Découvrez les engagements ` + (this.VUE_APP_SITE_ID === '1' ? `d'Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `de Distri Jantes l'expert en ligne des jantes`)},
                    {name: 'robots', content: 'index,follow'}
                ]
            }
    },
    }
</script>

<style scoped>
    .border_left{
        border-left: 2px solid #1DE4AF
    }
    .rotate {
        transform: rotate(-90deg);
        margin-left: -5%;
        margin-top: 12%;
        position: absolute;
    }
</style>
