

const state = () =>({
    MarqueVoiture:'',
    ModeleVoiture:'',
    TypeVoiture:'',
    MotorisationVoiture:'',
    DimensionVoiture:'',
    idMarque:'',
    MarqueJante:'',
    lastType:''
})



const mutations = {
    updateMarque: (state, data) => {

        state.MarqueVoiture = data.marque
        state.lastType = 'voiture'

    },
    updateIdMarque: (state, data) => {
        state.idMarque = data.idMarque
        state.lastType = 'voiture'
    },
    updateMarqueIdMarque: (state, data) => {
        state.MarqueVoiture = data.marque
        state.idMarque = data.idMarque
        state.lastType = 'voiture'
    },
    updateMarqueJante: (state, data) => {

        state.MarqueJante = data.marque
        state.lastType = 'jante'

    },
    updateModele: (state, data) => {

        state.ModeleVoiture = data.modele
        state.lastType = 'voiture'

    },
    updateType: (state, data) => {

        state.TypeVoiture = data.type
        state.lastType = 'voiture'

    },
    updateMotorisation: (state, data) => {

        state.MotorisationVoiture = data.motorisation
        state.lastType = 'voiture'

    },
    updateDimension: (state, data) => {

        state.DimensionVoiture = data.dimension
        state.lastType = 'voiture'

    },
    setLastType: (state, data) => {
        state.lastType = data
    }
}


const actions = {
    setVoiture: ({commit}, config) => {

        var idMarque= config.idMarque
        var marque= config.marque
        var modele= config.modele
        var type= config.type
        var motorisation= config.motorisation
        var dimension= config.dimension

        return new Promise((resolve) => {
            if (config.marque && config.idMarque) {
                commit('updateMarqueIdMarque', {marque, idMarque} )
                resolve()
            }else if (config.idMarque) {
                commit('updateIdMarque', {marque} )
                resolve()
            }else if (config.marque) {
                commit('updateMarque', {marque} )
                resolve()
            }
            if (config.modele) {
                commit('updateModele', {modele} )
                resolve()
            }
            if (config.type) {
                commit('updateType', {type} )
                resolve()
            }
            if (config.motorisation) {
                commit('updateMotorisation', {motorisation} )
                resolve()
            }
            if (config.dimension) {
                commit('updateDimension', {dimension} )

                resolve()
            }


        })
    },
    setJante: ({commit}, config) => {

        return new Promise((resolve) => {
            if (config) {
                commit('updateMarqueJante', config )
                resolve()
            }
        })
    }
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}