<template>
    <v-app class="backgroud_gradient" >


        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>


        <v-col offset-lg="2" class="bc_white">
            <h1>Nos marques de jantes</h1>
        </v-col>


        <v-content class="bc_white">
            <v-col offset-lg="2" lg="8">
                <v-row >
                    <v-col lg="3" cols="4" v-for="logo in logosJantes" :key="logo.id">
                        <v-img :src="logo.img" :alt="logo.alt"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-content>


        <v-col offset-lg="2" class="bc_white">
            <h1>Nos marques de pneus</h1>
        </v-col>


        <v-content class="bc_white">
            <v-col offset-lg="2" lg="8">
                <v-row >
                    <v-col lg="3" cols="4" v-for="logo in logosPneus" :key="logo.id">
                        <v-img :src="logo.img" :alt="logo.alt"/>
                    </v-col>
                </v-row>
            </v-col>
        </v-content>


        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>


    </v-app>
</template>

<script>
    import HomepageHeaderEj from "../headers/HomepageHeaderEj";
    import HomepageHeaderDj from "../headers/HomepageHeaderDj";
    import HomepageFooterEj from "../footer/HomepageFooterEj";
    import HomepageFooterDj from "../footer/HomepageFooterDj";

    import Dumans from '@/assets/logosMarquesJantes/24hdumans.png'
    import acr from '@/assets/logosMarquesJantes/ACR.png'
    import alutec from '@/assets/logosMarquesJantes/ALUTEC.png'
    import anzio from '@/assets/logosMarquesJantes/ANZIO.png'
    import ats from '@/assets/logosMarquesJantes/ATS.png'
    import bbs from '@/assets/logosMarquesJantes/BBS.png'
    import beyernWheels from '@/assets/logosMarquesJantes/beyern-wheels-logo.png'
    import blackRhino from '@/assets/logosMarquesJantes/black-rhino.png'
    import borbet from '@/assets/logosMarquesJantes/BORBET.png'
    import brock from '@/assets/logosMarquesJantes/BROCK.png'
    import cms from '@/assets/logosMarquesJantes/CMS.png'
    import coventry from '@/assets/logosMarquesJantes/coventry-logo.png'
    import crayWheels from '@/assets/logosMarquesJantes/cray-wheels-logo.png'
    import diversen from '@/assets/logosMarquesJantes/diversen.png'
    import ejWheel from '@/assets/logosMarquesJantes/EJ-WHEELS.png'
    import etaBeta from '@/assets/logosMarquesJantes/eta-beta.png'
    import eurasteel from '@/assets/logosMarquesJantes/eurasteel.png'
    import evyce from '@/assets/logosMarquesJantes/EVYCE.png'
    import fox from '@/assets/logosMarquesJantes/FOX.png'
    import gmp from '@/assets/logosMarquesJantes/GMP.png'
    import gulf from '@/assets/logosMarquesJantes/gulf.png'
    import infinyindividuel from '@/assets/logosMarquesJantes/infinyindividuel.png'
    import interAction from '@/assets/logosMarquesJantes/INTER-ACTION.png'
    import irmscher from '@/assets/logosMarquesJantes/irmscher.png'
    import itWheels from '@/assets/logosMarquesJantes/IT WHEELS.png'
    import japanRacing from '@/assets/logosMarquesJantes/JAPAN RACING.png'
    import keskin from '@/assets/logosMarquesJantes/KESKIN.png'
    import kronprinz from '@/assets/logosMarquesJantes/kronprinz-logo-1.png'
    import league from '@/assets/logosMarquesJantes/LEAGUE.png'
    import lumarai from '@/assets/logosMarquesJantes/lumarai.png'
    import mak from '@/assets/logosMarquesJantes/MAK.png'
    import mandrusWheels from '@/assets/logosMarquesJantes/mandrus-wheels-logo.png'
    import mbDesign from '@/assets/logosMarquesJantes/mb-design.png'
    import milleMiglia from '@/assets/logosMarquesJantes/MILLE MIGLIA.png'
    import monaco from '@/assets/logosMarquesJantes/MONACO.png'
    import motec from '@/assets/logosMarquesJantes/MOTEC.png'
    import msw from '@/assets/logosMarquesJantes/msw.png'
    import mw from '@/assets/logosMarquesJantes/mw.png'
    import originalEquipment from '@/assets/logosMarquesJantes/original-equipment.png'
    import ozracing from '@/assets/logosMarquesJantes/ozracing.png'
    import petrol from '@/assets/logosMarquesJantes/petrol.png'
    import platin from '@/assets/logosMarquesJantes/PLATIN.png'
    import racer from '@/assets/logosMarquesJantes/racer.png'
    import redbourneWheels from '@/assets/logosMarquesJantes/redbourne-wheels-logo.png'
    import rial from '@/assets/logosMarquesJantes/RIAL.png'
    import romac from '@/assets/logosMarquesJantes/romac.png'
    import ronal from '@/assets/logosMarquesJantes/RIAL.png'
    import soleil from '@/assets/logosMarquesJantes/SOLEIL.png'
    import sparco from '@/assets/logosMarquesJantes/sparco.png'
    import speedline from '@/assets/logosMarquesJantes/speedline.png'
    import staal from '@/assets/logosMarquesJantes/staal.png'
    import tec from '@/assets/logosMarquesJantes/tec.png'
    import tomason from '@/assets/logosMarquesJantes/TOMASON.png'
    import tsw from '@/assets/logosMarquesJantes/TSW.png'
    import victorEquipment from '@/assets/logosMarquesJantes/victor-equipment.png'
    import wheelworld from '@/assets/logosMarquesJantes/WHEELWORLD.png'
    import wsp from '@/assets/logosMarquesJantes/WSP.png'


    import accelera from '@/assets/logosMarquesPneus/accelera.png'
    import achilles from '@/assets/logosMarquesPneus/achilles-radial.png'
    import aeolus from '@/assets/logosMarquesPneus/aeolus.png'
    import aoteli from '@/assets/logosMarquesPneus/aoteli.png'
    import apollo from '@/assets/logosMarquesPneus/apollo.png'
    import aptany2 from '@/assets/logosMarquesPneus/aptany2.png'
    import ardent from '@/assets/logosMarquesPneus/ardent.png'
    import atlas from '@/assets/logosMarquesPneus/atlas.png'
    import atturo from '@/assets/logosMarquesPneus/atturo.png'
    import aurora from '@/assets/logosMarquesPneus/aurora-1.png'
    import austone from '@/assets/logosMarquesPneus/austone.png'
    import avon from '@/assets/logosMarquesPneus/avon-tyres.png'
    import barkley from '@/assets/logosMarquesPneus/barkley.png'
    import barum from '@/assets/logosMarquesPneus/barum.png'
    import bf from '@/assets/logosMarquesPneus/bf-goodrich.png'
    import blacklion from '@/assets/logosMarquesPneus/blacklion.png'
    import bridgestone from '@/assets/logosMarquesPneus/bridgestone.png'
    import chengshin from '@/assets/logosMarquesPneus/chengshin.png'
    import cheyen from '@/assets/logosMarquesPneus/cheyen.png'
    import continental from '@/assets/logosMarquesPneus/continental.png'
    import cooper_tire from '@/assets/logosMarquesPneus/cooper_tire.png'
    import dayton from '@/assets/logosMarquesPneus/dayton.png'
    import debica from '@/assets/logosMarquesPneus/debica.png'
    import deestone from '@/assets/logosMarquesPneus/deestone.png'
    import delinte from '@/assets/logosMarquesPneus/delinte.png'
    import dunlop from '@/assets/logosMarquesPneus/dunlop.png'
    import duraturn from '@/assets/logosMarquesPneus/duraturn.png'
    import event from '@/assets/logosMarquesPneus/event.png'
    import evergreen from '@/assets/logosMarquesPneus/evergreen.png'
    import falken from '@/assets/logosMarquesPneus/falken.png'
    import farroad from '@/assets/logosMarquesPneus/farroad.png'
    import federal from '@/assets/logosMarquesPneus/federal.png'
    import firemax from '@/assets/logosMarquesPneus/firemax.png'
    import firestone from '@/assets/logosMarquesPneus/firestone.png'
    import formula from '@/assets/logosMarquesPneus/formula.png'
    import fortuna from '@/assets/logosMarquesPneus/fortuna.png'
    import fulda from '@/assets/logosMarquesPneus/fulda.png'
    import generaltyres from '@/assets/logosMarquesPneus/generaltyres.png'
    import gislaved from '@/assets/logosMarquesPneus/gislaved.png'
    import giti from '@/assets/logosMarquesPneus/giti.png'
    import goform from '@/assets/logosMarquesPneus/goform.png'
    import goodride from '@/assets/logosMarquesPneus/goodride.png'
    import goodyear from '@/assets/logosMarquesPneus/goodyear.png'
    import gremax from '@/assets/logosMarquesPneus/gremax.png'
    import gt from '@/assets/logosMarquesPneus/gt-radial-tires.png'
    import habilead from '@/assets/logosMarquesPneus/habilead.png'
    import hankook from '@/assets/logosMarquesPneus/hankook.png'
    import headway from '@/assets/logosMarquesPneus/headway.png'
    import heidenau from '@/assets/logosMarquesPneus/heidenau.png'
    import hifly from '@/assets/logosMarquesPneus/hifly.png'
    import infinity from '@/assets/logosMarquesPneus/infinity.png'
    import insaturbo from '@/assets/logosMarquesPneus/insaturbo.png'
    import jinyu from '@/assets/logosMarquesPneus/jinyu.png'
    import kama from '@/assets/logosMarquesPneus/kama.png'
    import keter from '@/assets/logosMarquesPneus/keter.png'
    import kingmeiler from '@/assets/logosMarquesPneus/kingmeiler.png'
    import kingstar from '@/assets/logosMarquesPneus/kingstar.png'
    import kleber from '@/assets/logosMarquesPneus/kleber.png'
    import kormoran from '@/assets/logosMarquesPneus/kormoran.png'
    import kumho from '@/assets/logosMarquesPneus/kumho.png'
    import landsail from '@/assets/logosMarquesPneus/landsail.png'
    import lassa from '@/assets/logosMarquesPneus/lassa.png'
    import laufenn from '@/assets/logosMarquesPneus/laufenn.png'
    import linglong from '@/assets/logosMarquesPneus/linglong.png'
    import mabor from '@/assets/logosMarquesPneus/mabor.png'
    import marangoni from '@/assets/logosMarquesPneus/marangoni.png'
    import marshal from '@/assets/logosMarquesPneus/marshal.png'
    import mastersteel from '@/assets/logosMarquesPneus/mastersteel.png'
    import matador from '@/assets/logosMarquesPneus/matador.png'
    import maxtrek from '@/assets/logosMarquesPneus/maxtrek.png'
    import maxxis from '@/assets/logosMarquesPneus/maxxis.png'
    import mazzini from '@/assets/logosMarquesPneus/mazzini.png'
    import meteor from '@/assets/logosMarquesPneus/meteor.png'
    import michelin from '@/assets/logosMarquesPneus/michelin.png'
    import milestone from '@/assets/logosMarquesPneus/milestone.png'
    import minerva from '@/assets/logosMarquesPneus/minerva.png'
    import motrio from '@/assets/logosMarquesPneus/motrio.png'
    import nankang from '@/assets/logosMarquesPneus/nankang.png'
    import nexen from '@/assets/logosMarquesPneus/nexen.png'
    import nokian from '@/assets/logosMarquesPneus/nokian.png'
    import nordexx from '@/assets/logosMarquesPneus/nordexx.png'
    import novex from '@/assets/logosMarquesPneus/novex.png'
    import ovation from '@/assets/logosMarquesPneus/ovation.png'
    import pirelli from '@/assets/logosMarquesPneus/pirelli.png'
    import powertrac from '@/assets/logosMarquesPneus/powertrac.png'
    import riken from '@/assets/logosMarquesPneus/riken.png'
    import rotalla from '@/assets/logosMarquesPneus/rotalla.png'
    import sailun from '@/assets/logosMarquesPneus/sailun.png'
    import sava from '@/assets/logosMarquesPneus/sava.png'
    import seiberling from '@/assets/logosMarquesPneus/seiberling.png'
    import semperit from '@/assets/logosMarquesPneus/semperit.png'
    import sonar from '@/assets/logosMarquesPneus/sonar.png'
    import sportiva from '@/assets/logosMarquesPneus/sportiva.png'
    import starfire from '@/assets/logosMarquesPneus/starfire.png'
    import starmaxx from '@/assets/logosMarquesPneus/starmaxx.png'
    import sumitomo from '@/assets/logosMarquesPneus/sumitomo.png'
    import sunitrac from '@/assets/logosMarquesPneus/sunitrac.png'
    import superia from '@/assets/logosMarquesPneus/superia.png'
    import syron from '@/assets/logosMarquesPneus/syron.png'
    import taurus from '@/assets/logosMarquesPneus/taurus.png'
    import three from '@/assets/logosMarquesPneus/three-a.png'
    import tigar from '@/assets/logosMarquesPneus/tigar.png'
    import toledo from '@/assets/logosMarquesPneus/toledo.png'
    import torque from '@/assets/logosMarquesPneus/torque.png'
    import toyo from '@/assets/logosMarquesPneus/toyo-tires.png'
    import tristar from '@/assets/logosMarquesPneus/tristar.png'
    import tyfoon from '@/assets/logosMarquesPneus/tyfoon.png'
    import tyresimperial from '@/assets/logosMarquesPneus/tyresimperial.png'
    import uniroyal from '@/assets/logosMarquesPneus/uniroyal.png'
    import vredestein from '@/assets/logosMarquesPneus/vredestein.png'
    import wanli from '@/assets/logosMarquesPneus/wanli.png'
    import westlake from '@/assets/logosMarquesPneus/westlake.png'
    import windforce from '@/assets/logosMarquesPneus/windforce.png'
    import yokohama from '@/assets/logosMarquesPneus/yokohama.png'
    import zeta from '@/assets/logosMarquesPneus/zeta.png'



    export default {
        name: "CatalogueJantes",
        components: {
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,
        },
        data: () => ({
            logosJantes:[
                {img: Dumans, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes DUMANS " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: acr, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes ACR " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: alutec, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes ALUTEC " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: anzio, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes ANZIO " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: ats, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes ATS " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: bbs, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes BBS " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: beyernWheels, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes BEYERNWHEELS " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: blackRhino, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes BLACKRHINO " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: borbet, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes BORBET " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: brock, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes BROCK " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: cms, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes CMS " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: coventry, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes COVENTRY " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: crayWheels, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes CRAYWHEEL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: diversen, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes DIVERSEN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: ejWheel, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes EJWHEEL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: etaBeta, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes ETABETA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: eurasteel, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes EURASTEEL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: evyce, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes EVYCE " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: fox, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes FOX " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: gmp, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes GMP " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: gulf, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes GULF " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: infinyindividuel, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes INFINYINDIVIDUEL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: interAction, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes INTERACTION " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: irmscher, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes IRMCHER " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: itWheels, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes ITWHEELS " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: japanRacing, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes JAPANRACING " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: keskin, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes KESKIN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: kronprinz, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes KRONPRINZ " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: league, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes LEAGUE " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: lumarai, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes LUMARAI " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: mak, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MAK " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: mandrusWheels, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MANDRUSWHEELS " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: mbDesign, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MBDESIGN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: milleMiglia, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MILLEMIGLIA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: monaco, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MONACO " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: motec, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MOTEC " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: msw, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MSW " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: mw, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MW " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: originalEquipment, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes ORIGINALEQUIPEMENT " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: ozracing, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes OZRACING " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: petrol, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes PETROL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: platin, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes PLATIN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: racer, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes RACER " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: redbourneWheels, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes REDBOURNEWHEELS " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: rial, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes RIAL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: romac, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes ROMAC " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: ronal, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes RONAL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: soleil, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes SOLEIL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: sparco, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes SPARCO " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: speedline, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes SPEEDLINE " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: staal, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes STAAL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: tec, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes TEC " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: tomason, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes TOMASON " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: tsw, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes TSW " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: victorEquipment, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes VICTOREQUIPEMENT " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: wheelworld, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes WHEELWORLD " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: wsp, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes WSP " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."}
            ],
            logosPneus:[
                {img: accelera, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes ACCESLERA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: achilles, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes ACHILLES " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: aeolus, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes AEOLUS " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: aoteli, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes AOTELI " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: apollo, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes APOLLO " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: aptany2, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes APTANY " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: ardent, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes ARDENT " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: atlas, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes ATLAS " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: atturo, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes ATTURO " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: aurora, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes AURORA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: austone, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes AUSTONE " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: avon, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes AVON " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: barkley, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes BARKLEY " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: barum, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes BARUM " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: bf, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes BF-GOODRICH " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: blacklion, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes BLACKLION " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: bridgestone, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes BRIGESTONE " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: chengshin, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes CHENGSHIN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: cheyen, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes CHEYEN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: continental, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes CONTINENTAL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: cooper_tire, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes COOPERTIRE " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: dayton, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes DAYTON " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: debica, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes DEBICA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: deestone, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes DEESTONE " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: delinte, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes DELINTE " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: dunlop, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes DUNLOP " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: duraturn, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes DURATURN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: event, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes EVENT " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: evergreen, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes EVERGREEN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: falken, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes FALKEN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: farroad, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes FARROAD " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: federal, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes FEDERAL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: firemax, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes FIREMAX " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: firestone, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes FIRESTONE " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: formula, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes FORMULA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: fortuna, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes FORTUNA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: fulda, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes FULDA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: generaltyres, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes GENERALTYRES " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: gislaved, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes GISLAVED " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: giti, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes GITI " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: goform, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes GOFORM " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: goodride, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes GOODRIDE " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: goodyear, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes GOODYEAR " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: gremax, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes GREMAX " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: gt, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes GT-RADIAL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: habilead, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes HABILEAD " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: hankook, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes HANKOOK " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: headway, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes HEADWAY " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: heidenau, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes HEIDENAU " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: hifly, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes HIFLY " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: infinity, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes INFINITY " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: insaturbo, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes INSTATURBO " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: jinyu, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes JINYU " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: kama, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes KAMA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: keter, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes KETER " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: kingmeiler, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes KINGMEILER " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: kingstar, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes KINGSTAR " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: kleber, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes KLEBER " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: kormoran, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes KORMORAN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: kumho, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes KUMHO " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: landsail, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes LANDSAIL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: lassa, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes LASSA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: laufenn, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes LAUFENN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: linglong, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes LINGLONG " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: mabor, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MABOR " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: marangoni, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MARANGONI " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: marshal, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MARSHAL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: mastersteel, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MASTERSTEEL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: matador, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MATADOR " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: maxtrek, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MAXTREK " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: maxxis, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MAXXIS " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: mazzini, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MAZZINI " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: meteor, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes METEOR " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: michelin, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MICHELIN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: milestone, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MILESTONE " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: minerva, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MINERVA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: motrio, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes MOTRIO " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: nankang, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes NANKANG " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: nexen, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes NEXEN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: nokian, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes NOKIAN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: nordexx, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes NORDEXX " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: novex, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes NOVEX " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: ovation, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes OVATION " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: pirelli, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes PIRELLI " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: powertrac, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes POWERTRAC " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: riken, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes RIKEN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: rotalla, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes ROTALLA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: sailun, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes SAILUN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: sava, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes SAVA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: seiberling, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes SEIBERLING " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: semperit, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes SEMPERIT " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: sonar, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes SONAR " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: sportiva, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes SPORTIVA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: starfire, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes STARFIRE " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: starmaxx, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes STARMAX " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: sumitomo, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes SUMITOMO " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: sunitrac, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes SUNITRAC " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: superia, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes SUPERIA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: syron, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes SYRON " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: taurus, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes TAURUS " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: three, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes THREE " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: tigar, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes TIGAR " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: toledo, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes TOLEDO " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: torque, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes TORQUE " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: toyo, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes TOYO " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: tristar, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes TRISTAR " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: tyfoon, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes TYFOON " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: tyresimperial, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes TYREIMPERIAL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: uniroyal, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes UNIROYAL " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: vredestein, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes VREDESTEIN " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: wanli, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes WANLI " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: westlake, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes WESTLAKE " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: windforce, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes WINDFORCE " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: yokohama, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes YOKOHAMA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
                {img: zeta, alt:this.VUE_APP_SITE_ID === '1' ? "Espace-jantes est spécialisée dans la vente de jantes" : "Distri-jantes l'expert en ligne des jantes ZETA " + this.VUE_APP_SITE_ID === '1' ? "et roues complètes pour votre voiture. Leader sur internet. Livraison gratuite. Paiement en 4xCB." : "mais aussi pneus et accessoires pour votre véhicule - Paiement en plusieurs fois."},
            ],
            VUE_APP_SITE_ID:null

        }),
        metaInfo() {
            return {
                title: `Nos Marques - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com - Jantes, Pneus & Accessoires` : `Distri-Jantes.com - Des jantes de qualité pour tous - Pneus et Accessoires`),
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `Nos Marques - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {name: 'twitter:description', content: `Nos Marques. ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `Nos Marques -` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    {property: 'og:site_name', content: this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`},
                    {property: 'og:type', content: 'Website'},
                    {property: 'og:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},
                    {property: 'og:description', content: `Nos Marques. ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    {name: 'robots', content: 'index,follow'}
                ]
            }
    },

        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        }

    }
</script>

<style scoped>

</style>
