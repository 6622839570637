

const state = () =>({
    ref:'',
    typePaiement:null
})


const mutations = {
    setRef(state, config){
        state.ref = config

    },

    deleteRef: state => {
        state.ref = ''
    },

    setTypePayement(state, config){
        state.typePaiement = config

    },

    deleteTypePayement: state => {
        state.typePaiement = ''
    },
}


const actions = {

}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}
