

const state = () =>({
    SavePage:null,
})



const mutations = {

    setPage(state, config){
        state.SavePage = config
    },
    deletePage(state){
        state.SavePage = null
    },

}


const actions = {

}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}