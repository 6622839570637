<template>
    <v-app class="bc_lightGrey">


            <v-col v-if="$vuetify.breakpoint.mdAndUp" offset="1" class="mt-3 pa-3">
                <v-row>
                    <v-col cols="2" class="shadow font-weight-bold d-flex align-self-center" style="max-height:90px;min-height: 90px;" :class="{'display-1':$vuetify.breakpoint.xl, 'headline':$vuetify.breakpoint.lg}">
                        <p class="align-self-center mb-0 tc_grey font-weight-bold
                                            text-xl-h4
                                            text-lg-h6
                                            text-md-subtitle-1">Les + Espace Jantes</p>
                    </v-col>

                    <v-col cols="1" v-for="logoPayment in logoPayments" :key="logoPayment.img" class="shadow d-flex border_left" style="max-height:90px" >
                        <v-img class="align-self-center " :src="logoPayment.img"/>
                    </v-col>

                    <v-col cols="1" class="shadow d-flex border_left" style="max-height:90px" >
                        <v-img src="@/assets/logo/avisverifies.png" class="ma-auto"  :style="[$vuetify.breakpoint.xl ? {'max-width' : '80%'} :
                                                                                               $vuetify.breakpoint.lg ? {'max-width' : '100%'} :
                                                                                               $vuetify.breakpoint.md ? {'max-width' : '80%'} : '']"/>
                    </v-col>

                    <v-col cols="1" class="shadow d-flex border_left" style="max-height:90px" >
                        <v-img src="@/assets/logo/ups.png" class=" ma-auto" :style="[$vuetify.breakpoint.xl ? {'max-width' : '70%'} :
                                                                                      $vuetify.breakpoint.lg ? {'max-width' : '90%'} :
                                                                                      $vuetify.breakpoint.md ? {'max-width' : '50%'} : '']" />
                    </v-col>

                    <v-col cols="1" class="shadow d-flex border_left" style="max-height:90px">
                        <v-img class="ma-auto" src="@/assets/logo/gls.png" :style="[$vuetify.breakpoint.xl ? {'max-width' : '70%'} :
                                                                                      $vuetify.breakpoint.lg ? {'max-width' : '90%'} :
                                                                                      $vuetify.breakpoint.md ? {'max-width' : '50%'} : '']"/>
                    </v-col>

                    <v-col cols="1" class="shadow d-flex border_left" style="max-height:90px">
                        <v-img class="ma-auto" src="@/assets/logo/dpd.png" :style="[$vuetify.breakpoint.xl ? {'max-width' : '80%'} :
                                                                                      $vuetify.breakpoint.lg ? {'max-width' : '90%'} :
                                                                                      $vuetify.breakpoint.md ? {'max-width' : '50%'} : '']"/>
                    </v-col>

                </v-row>
            </v-col>

            <v-container v-if="$vuetify.breakpoint.smAndDown" fluid>
                <v-col>
                    <v-row class="font-weight-bold pt-0  tc_grey" >
                        <p class="align-self-center mb-0 tc_grey font-weight-bold
                                    text-sm-h6
                                    text-subtitle-1">Les + Espace Jantes</p>
                    </v-row>
                </v-col>


                <v-row >
                    <v-col cols="3" sm="2" v-for="logoPayment in logoPayments" :key="logoPayment.img" >
                        <v-card height="100%" class="d-flex align-center">
                            <v-col>
                                <v-img class="align-self-center" :src="logoPayment.img" />
                            </v-col>
                        </v-card>
                    </v-col>
                    <v-col cols="3" sm="2">
                        <v-card height="100%" class="d-flex align-center">
                            <v-col>
                                <v-img src="@/assets/logo/logo_ups.png" class=" ma-auto" :style="[$vuetify.breakpoint.sm ? {'max-width' : '40%'} :
                                                                                                  $vuetify.breakpoint.xs ? {'max-width' : '50%'} :'']" />
                            </v-col>
                        </v-card>
                    </v-col>
                    <v-col cols="3" sm="2">
                        <v-card height="100%" class="d-flex align-center">
                            <v-col>
                                <v-img src="@/assets/logo/avis_verifies.png" class="ma-auto"  :style="[$vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                                                                                       $vuetify.breakpoint.xs ? {'max-width' : '65%'} : '']"/>
                            </v-col>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>



            <v-container v-if="$vuetify.breakpoint.mdAndUp" fluid>
                    <v-col offset="1" cols="10">
                        <v-row>
                        <v-col cols="4">
                            <v-row class="font-weight-bold pt-0 mb-3 text-h6 tc_grey"  >
                                <h2 class="font-weight-bold
                                            text-xl-h5
                                            text-lg-h6
                                            text-md-h6">Accès Rapide</h2>
                            </v-row>
                            <v-row v-for="fast in fastAccess" :key="fast.title" class="tc_grey" >
                                <RouterLink :to="{name:fast.link}" class="no_deco tc_grey ">
                                    <span class="font-weight-regular
                                                text-xl-h6
                                                text-lg-caption
                                                text-md-caption">{{fast.title}}</span>
                                </RouterLink>
                            </v-row>
                        </v-col>

                        <v-col cols="4" style="margin-bottom: 3%">
                            <v-row class="font-weight-bold pt-0 mb-3 text-h6 tc_grey"  >
                            <h2 class="font-weight-bold
                                            text-xl-h5
                                            text-lg-h6
                                            text-md-h6">A Propos</h2>
                            </v-row>
                            <v-row v-for="about in abouts" :key="about.title" class="tc_grey" >
                                <RouterLink :to="{name:about.link}" class="no_deco tc_grey text-subtitle-1">
                                    <span class="font-weight-regular
                                                text-xl-h6
                                                text-lg-caption
                                                text-md-caption">{{about.title}}</span>
                                </RouterLink>
                            </v-row>
                        </v-col>

                        <v-col cols="4" >
                            <v-row class="font-weight-bold pt-0 mb-3 text-h6 tc_grey"  >
                                <h2 class="font-weight-bold
                                            text-xl-h5
                                            text-lg-h6
                                            text-md-h6">Nous contacter</h2>
                            </v-row>
                            <v-row class="tc_grey" >
                                <RouterLink :to="{name:'ContactUs'}" class="no_deco tc_grey text-subtitle-1" >
                                     <span class="font-weight-regular
                                        text-xl-h6
                                        text-lg-caption
                                        text-md-caption">Formulaire de contact</span>
                                </RouterLink>
                            </v-row>
                            <v-row class="tc_turquoise
                                        font-weight-regular
                                        text-xl-h6
                                        text-lg-caption
                                        text-md-caption" >
                                <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a>
                            </v-row>
                            <v-row>
                                <a v-if="$vuetify.breakpoint.xl"  href="https://www.facebook.com/espace-jantes" target="_blank" class="no_deco" >
                                    <v-icon large style="padding-right: 2%;" class="tc_darkBlue">mdi-facebook</v-icon>
                                </a>
                                <a  v-if="$vuetify.breakpoint.lgAndDown" href="https://www.facebook.com/espace-jantes" target="_blank" class="no_deco">
                                    <v-icon style="padding-right: 2%;" class="tc_darkBlue">mdi-facebook</v-icon>
                                </a>

                                <a  v-if="$vuetify.breakpoint.xl" href="https://instagram.com/espace_jantes" target="_blank" class="no_deco">
                                    <v-icon large class="tc_darkBlue"> mdi-instagram</v-icon>
                                </a>
                                <a v-if="$vuetify.breakpoint.lgAndDown"  href="https://instagram.com/espace_jantes" target="_blank" class="no_deco" >
                                    <v-icon style="padding-right: 2%;" class="tc_darkBlue">mdi-instagram</v-icon>
                                </a>

                            </v-row>
                        </v-col>
                    </v-row>
                    </v-col>
                </v-container>


            <v-container v-if="$vuetify.breakpoint.smAndDown" fluid>
                <v-col cols="12">
                    <v-row>

                        <v-col cols="6" sm="4">
                            <v-row class=" pt-0 tc_grey"  >
                                <h2 class="px-3 font-weight-bold
                                                text-sm-h6
                                                text-subtitle-1">Accès Rapide</h2>
                            </v-row>
                            <v-row class="tc_grey" v-for="(fast) in fastAccess" :key="fast.title">
                                <v-col class="py-0">
                                    <RouterLink :to="{name:fast.link}" class="no_deco tc_grey ">
                                        <span class="font-weight-regular
                                                text-sm-caption
                                                text-caption">{{fast.title}}</span>
                                    </RouterLink>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="6" sm="4">
                            <v-row class="font-weight-bold pt-0 tc_grey">
                                <h2 class="px-3 font-weight-bold
                                                text-sm-h6
                                                text-subtitle-1">A Propos</h2>
                            </v-row>
                            <v-row class="tc_grey" v-for="(about) in abouts" :key="about.title">
                                <v-col class="py-0">
                                    <RouterLink :to="{name:about.link}" class="no_deco tc_grey ">
                                        <span class="font-weight-regular
                                                text-sm-caption
                                                text-caption">{{about.title}}</span>
                                    </RouterLink>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col cols="12" sm="4">
                            <v-row class="font-weight-bold pt-0 tc_grey"  >
                                <h2 class="px-3 font-weight-bold
                                                text-sm-h6
                                                text-subtitle-1">Nous contacter</h2>
                            </v-row>
                            <v-row class="tc_grey">
                                <RouterLink :to="{name:'ContactUs'}" class="no_deco tc_grey " >
                                    <span class="pl-3
                                                font-weight-regular
                                                text-sm-caption
                                                text-caption">Formulaire de contact</span>
                                </RouterLink>
                            </v-row>
                            <v-row class="tc_turquoise pl-3"><a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a></v-row>
                            <v-row>
                                <a v-if="$vuetify.breakpoint.lgAndDown"  href="https://www.facebook.com/espace-jantes" target="_blank" class="no_deco" >
                                    <v-icon  style="padding-right: 2%;" class="tc_darkBlue pl-3">mdi-facebook</v-icon>
                                </a>
                                <a  href="https://instagram.com/espace_jantes?igshid=5iq05c32x8z" target="_blank" class="no_deco">
                                    <v-icon  color="#232D4D"> mdi-instagram</v-icon>
                                </a>
                            </v-row>
                        </v-col>

                    </v-row>
                </v-col>
            </v-container>


            <v-container v-if="$vuetify.breakpoint.mdAndUp" fluid>
                <v-col cols="12" >
                  <v-row>
                    <v-col offset-lg="1" offset-md="1" cols="5" style="padding-top: 3%" >
                        <v-row class="font-weight-bold pt-0 text-h6 tc_grey" >
                            <h2 class="font-weight-bold
                                            text-xl-h5
                                            text-lg-h6
                                            text-md-h6">Nos marques de Véhicules</h2>
                        </v-row>
                    </v-col>

                    <v-col cols="5" style="padding-top: 3%"  >
                        <v-row class="font-weight-bold pa-0 text-h6 tc_grey" >
                            <h2 class="font-weight-bold
                                            text-xl-h5
                                            text-lg-h6
                                            text-md-h6">Nos Marques de Jantes</h2>
                        </v-row>
                    </v-col>

                    <v-col style="padding-top: 1%!important;padding-bottom: 2%;" class="" offset-lg="1" offset-md="1"  cols="5" >
                        <v-row>
                            <v-col cols="6"  no-gutters v-for="marque in marques" @click="toStoreVoiture(marque.Nom, marque.id_manufacturer)" :key="marque.id_manufacturer" class="tc_grey pa-0 " style="cursor: pointer" >
                                   <span class="
                                        font-weight-regular
                                        text-xl-h6
                                        text-lg-caption
                                        text-md-caption">Véhicules  {{marque.Nom}}</span>
                            </v-col>
                        </v-row>
                    </v-col>




                    <v-col style="padding-top: 1%!important;padding-bottom: 2%;" class="" cols="5" >
                        <v-row>
                            <v-col cols="6" no-gutters v-for="(marque) in marquesJantes" @click="toStoreJante(marque)" :key="marque.id_manufacturer" class=" tc_grey text-subtitle-1 pa-0" style="cursor: pointer" >
                                    <span class="
                                        font-weight-regular
                                        text-xl-h6
                                        text-lg-caption
                                        text-md-caption">Jantes  {{marque}}</span>
                            </v-col>
                        </v-row>
                    </v-col>



                </v-row>
                </v-col>
            </v-container>

            <v-container v-if="$vuetify.breakpoint.smAndDown" fluid>

                <v-col >
                    <v-row class=" tc_grey" >
                        <h2 class="px-3 font-weight-bold
                                    text-sm-h6
                                    text-subtitle-1">Nos marques de Véhicules</h2>
                    </v-row>
                    <v-row class="tc_grey" >

                        <v-col style="padding-top: 2%!important;padding-bottom: 2%;" class="body-2"  xl="6" lg="6" >
                            <v-row>
                                <v-col cols="6" sm="4" no-gutters v-for="marque in marques" @click="toStoreVoiture(marque.Nom, marque.id_manufacturer)" :key="marque.id_manufacturer" class=" tc_grey py-0 pr-0 " style="cursor: pointer" >
                                        <span class="font-weight-regular
                                                text-sm-caption
                                                text-caption">Véhicules  {{marque.Nom}}</span>
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-row>
                    <v-row class="pt-3 tc_grey" >
                        <h2 class="px-3 font-weight-bold
                                    text-sm-h6
                                    text-subtitle-1">Nos Marques de Jantes</h2>
                    </v-row>
                    <v-row class="tc_grey">
                        <v-col style="padding-top: 2%!important;padding-bottom: 2%;" class="body-2"  xl="6" lg="6" >
                            <v-row>
                                <v-col cols="6" sm="4" no-gutters v-for="(marque) in marquesJantes" @click="toStoreJante(marque)" :key="marque.id_manufacturer" class=" tc_grey py-0 pr-0" style="cursor: pointer" >
                                   <span class="font-weight-regular
                                                text-sm-caption
                                                text-caption">Jantes  {{marque}}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>



            </v-container>

    </v-app>

</template>

<script>

    import paypal from "@/assets/logo/paypal.png";
    import visa from "@/assets/logo/visa.png";
    import mastercard from "@/assets/logo/mastercard.png";
    import virement from "@/assets/logo/virementbancaire.png";
    import paiement from "@/assets/logo/paiementparcheque.png";
    import cofidis from "@/assets/logo/logo-Cofidis-Pay_4xcb.png";

    import Vue from 'vue'
    import algoliasearch from 'algoliasearch/lite';
    import 'instantsearch.css/themes/algolia-min.css';


    export default {
        name: "HomepageFooterEj",
        data: () =>({
            shops:[
                {title:'Jantes par marques'}  ,
                {title:'Pneus par marques'}  ,
                {title:'Accessoires de jantes'}  ,
            ],
            fastAccess:[
                {title:'Mon Compte', link: 'Account'},
                {title:'Accès Revendeurs', link: 'Revendeurs'},
                {title:'Jantes - Description', link: 'JantesDescription'},
                {title:'Jantes - Fabrication', link: 'JantesFabrication'},
                {title:'Jantes - Monobloc', link: 'JantesMonobloc'},
                {title:'Jantes - Matériaux', link: 'JantesMateriaux'},
                {title:'Jantes - Options', link: 'JantesOptions'},
                {title:'Jantes - Entretien', link: 'JantesEntretien'},
                {title:'Jantes - Conseils', link: 'JantesConseils'},
                {title:'Jantes - Permutation', link: 'JantesPermutation'},
            ],
            abouts:[
                {title:'Qui sommes-nous ?', link: 'AboutUs'},
                {title:'Nos engagements', link:'NosEngagements'},
                {title:'Conditions Générales de Vente', link:'CGV'},
                {title:'Livraison', link: 'Livraison'},
                {title:'Mentions légales + RGPD', link: 'MentionsLegales'},
            ],
            logoPayments:[
                {img:paypal},
                {img:visa},
                {img:mastercard},
                {img:virement},
                {img:paiement},
                {img:cofidis},
            ],
            logoPayments1:[
                {img:paypal},
                {img:visa},
                {img:mastercard},
                {img:virement},
            ],
            jantes1:[
                {title:'Alfa Romeo'},
                {title:'Audi'},
                {title:'BMW'},
                {title:'Citroen'},
                {title:'Fiat'},
                {title:'Land Rover'},
                {title:'Mercedes'},
                {title:'Mini'},
            ],
            jantes2:[
                {title:'Peugeot'},
                {title:'Porsche'},
                {title:'Renault'},
                {title:'Seat'},
                {title:'Skoda'},
                {title:'Volkswagen'},
                {title:'Volvo'},
            ],
            jantes3:[
                {title:'24h du Mans'},
                {title:'Alutec'},
                {title:'BBS'},
                {title:'Beyern'},
                {title:'Fox'},
                {title:'GMP'},
                {title:'Infiny'},
                {title:'Inter Action'},
                {title:'Japan Racing'},
                {title:'MAK'},
                {title:'Mandrus'},
            ],
            jantes4:[
                {title:'Mille Miglia'},
                {title:'Monaco'},
                {title:'MSW'},
                {title:'OZ'},
                {title:'Racer'},
                {title:'Radi8'},
                {title:'Ronal'},
                {title:'Sparco'},
                {title:'WSP Italy'},
            ],
            marques:[],
            marquesJantes:[],
            searchClient: algoliasearch(
                'IXJF8AJTH7',
                '27829f3918903605b2f7665097a13737'
            ),
        }),
        mounted() {

            var arrayMarqueHide = ['AC','ACURA','ALPINA','AMC','ARO','ARTEGA','ASIA MOTORS','AUSTIN','AUSTIN-HEALEY','AUTO UNION','AUTOBIANCHI','AUVERLAND','BEDFORD','BARKAS','BERTONE','BITTER','BLUECAR','BOND','BORGWARD','BRISTOL',
                'BUGATTI','BUICK','CALLAWAY','CARBODIES','CATERHAM','CHATENET','CHECKER','COMARTH','DAF','DAIMLER','DALLAS','De La Chapelle','DE LOREAN','DE TOMASO','DONKERVOORT','EBRO','FISKER','FORD OTOSAN','FSO',
                'GAZ','GEELY','GEO','GINETTA','GIOTTI VICTORIA','GLAS','GMC','GME','GOUPIL','GUMPERT','HINDUSTAN','HOBBYCAR','INDIGO','INNOCENTI','IRAN KHODRO (IKCO)','IRMSCHER','ISDERA','IVECO','IZH','JENSEN',
                'KTM','LANDWIND (JMC)','LDV','LIGIER','LINCOLN','LTI','MAHINDRA','MAN','MARCOS','MARUTI','MAYBACH','MEGA','METROCAB','MIA ELECTRIC','MICROCAR','MIDDLEBRIDGE','MINELLI','MITSUOKA','MORGAN','MORRIS',
                'MOSKVICH','MPM MOTORS','NSU','OLDSMOBILE','OLTCIT','OSCA','PAGANI','PANOZ','PANTHER','PGO','PIAGGIO','PININFARINA','PLYMOUTH','POLARIS','PONTIAC','PREMIER','PROTON','PUCH','RAM','RANGER',
                'RAYTON FISSORE','RELIANT','RENAULT TRUCKS','RILEY','RUF','SANTANA','SATURN','SHELBY','SIPANI','SPECTRE','SPYKER','STANDARD','STANGUELLINI','STEYR','STREETSCOOTER','TALBOT','TATA','TAZZARI',
                'THINK','TRABANT','TRIUMPH','TVR','UAZ','UMM','VAUXHALL','VECTOR','VENTURI','VICTORY','VUHL','WARTBURG','WESTFIELD','WIESMANN','WOLSELEY','YUGO','YULON','ZASTAVA','ZAZ','ZENOS CARS',
                'BEDFORD', 'AIXAM', 'CHATENET', 'CUPRA', 'DAEWOO', 'DAIHATSU', 'De La Chapelle','DODGE', 'FORD USA', 'IRAN KHODRO (IKCO)','HUMMER','INFINITI','ISUZU','LADA','LANDWIND (JMC)','PIAGGIO',
                'RENAULT TRUCKS','SAAB', 'SSANGYONG','LANCIA','ROVER']


            // var arrayMarqueHide = ['AC','ACURA','ALPINA','AMC','ARO','ARTEGA','ASIA MOTORS','AUSTIN','AUSTIN-HEALEY','AUTO UNION','AUTOBIANCHI','AUVERLAND','BARKAS','BERTONE','BITTER','BLUECAR','BOND','BORGWARD','BRISTOL',
            //                 'BUGATTI','BUICK','CALLAWAY','CARBODIES','CATERHAM','CHECKER','COMARTH','DAF','DAIMLER','DALLAS','DE LA CHAPELLE','DE LOREAN','DE TOMASO','DONKERVOORT','EBRO','FISKER','FORD OTOSAN','FSO',
            //                   'GAZ','GEELY','GEO','GINETTA','GIOTTI VICTORIA','GLAS','GMC','GME','GOUPIL','GUMPERT','HINDUSTAN','HOBBYCAR','INDIGO','INNOCENTI','IRAN KHODRO','IRMSCHER','ISDERA','IVECO','IZH','JENSEN',
            //                   'KTM','LANDWIND','LDV','LIGIER','LINCOLN','LTI','MAHINDRA','MAN','MARCOS','MARUTI','MAYBACH','MEGA','METROCAB','MIA ELECTRIC','MICROCAR','MIDDLEBRIDGE','MINELLI','MITSUOKA','MORGAN','MORRIS',
            //                     'MOSKVICH','MPM MOTORS','NSU','OLDSMOBILE','OLTCIT','OSCA','PAGANI','PANOZ','PANTHER','PGO','PIAGGO','PININFARINA','PLYMOUTH','POLARIS','PONTIAC','PREMIER','PROTON','PUCH','RAM','RANGER',
            //                     'RAYTON FISSORE','RELIANT','RENAULT TRUCK','RILEY','RUF','SANTANA','SATURN','SHELBY','SIPANI','SPECTRE','SPYKER','STANDARD','STANGUELLINI','STEYR','STREETSCOOTER','TALBOT','TATA','TAZZARI',
            //                     'THINK','TRABANT','TRIUMPH','TVR','UAZ','UMM','VAUXHALL','VECTOR','VENTURI','VICTORY','VUHL','WARTBURG','WESTFIELD','WIESMANN','WOLSELEY','YUGO','YULON','ZASTAVA','ZAZ','ZENOS CARS',
            //                     'BEDFORD', 'AIXAM', 'CHATENET', 'CUPRA', 'DAEWOO', 'DAIHATSU', 'De La Chapelle','DODGE', 'FORD USA', 'IRAN KHODRO (IKCO)','HUMMER','INFINITI','ISUZU','LADA','LANDWIND (JMC)','PIAGGIO',
            //                      'RENAULT TRUCKS','SAAB', 'SSANGYONG','VW','LANCIA','ROVER']

            Vue.axios.get('https://api.espace-jantes.com/carMarque').then((response) => {
                response.data.forEach(element => {
                    if(arrayMarqueHide.indexOf(element.Nom) == -1){
                      this.marques.push(element)
                    }
                })
            })

            Vue.axios.get('https://api.espace-jantes.com/marquesJantes').then((response) => {
                response.data.forEach(element => {
                        this.marquesJantes.push(element)
                })
            })
        },
        methods:{
            transformItems(items) {
                // console.log(items)
                return items.map(item => ({
                    ...item,
                    name: item.name,
                }));
            },
            toStoreVoiture:function (marque, id){
                this.$store.dispatch('catalogueJantes/setVoiture', {marque: marque, idMarque: id}).then(() => {
                    this.$router.push({name:'catalogue', params:{marque: this.encoded(marque), id:id}})
                })
            },
            toStoreJante:function (marque){

                this.$store.dispatch('catalogueJantes/setJante', {marque}).then(() => {
                    this.$router.push({name:'catalogue', params:{marque: this.encoded(marque)}})
                })
            },
            encoded:function(nom){
                return(nom.replace(/\s/g, "+"))
            }
        }
    }
</script>

<style scoped>

    .shadow{
        box-shadow: 0px 7px 64px rgba(0, 0, 0, 0.07)
    }


    .border_left{
        border-left:solid #ECEBED;
    }
</style>
