<template>
    <v-app style="position: relative">
<!--        <v-container   ref='TopListPneu' :style="[montagePreco ? {'max-height': '400px'} : {'max-height' : '360px'}]">-->

        <v-row v-if="$vuetify.breakpoint.lgAndUp" class="py-0" >
<!--            <v-row class="bc_white " >-->
            <v-col lg="9" class="pa-0">

                <ConfigurateurHeader :header-title="headerTitle" class="bc_lightGrey"/>

                <v-row >
                    <v-col offset-lg="1" lg="10" style="overflow-y: scroll;overflow-x: hidden" id="anchor" :style="[$vuetify.breakpoint.xl ? {'max-height': '700px'} :
                                                                                                                             $vuetify.breakpoint.lg ? {'max-height': '600px'} : '']">

                            <v-row>

                                <v-img v-if="isConnected === 'pending' && VUE_APP_SITE_ID === '1'" src="@/assets/loader.gif" style="max-width: 40%; margin: auto"/>
                                <v-img v-if="isConnected === 'pending' && VUE_APP_SITE_ID === '2'" src="@/assets/loaderDJ.gif" style="max-width: 40%; margin: auto"/>



                                <v-col v-if="isConnected === false">
                                    <SignInUp @clicked="logged" @connect="connect"/>

                                </v-col>


                                <Formulaire v-if="isConnected === true" @infoClicked="overlayValvesSwitch" @updated="updatePriceFooter" @hideDelete="hideDeleteFunction" ref="Formulaire"/>

                            </v-row>


                        <v-overlay
                                :absolute="true"
                                :value="overlayValves"
                                :z-index="10"
                        >
                            <v-col >

                                <v-card class="bc_lightGrey" style="max-width:1000px;border-radius:50px;"  >

                                    <v-card-text class="text-center tc_grey" >
                                        <v-row>
                                            <v-col v-if="!tpmsInfo" offset-lg="2" lg="8" class="text-left" >
                                                <p class="font-weight-bold "  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">MON VÉHICULE EST-IL ÉQUIPÉ DE VALVES ÉLECTRONIQUES ?</p>
                                                <p class="font-weight-bold">Deux informations doivent être présentes sur votre tableau de bord ainsi que sur l’ordinateur de votre véhicule.</p>
                                                <span class="font-weight-bold">Vous devez vérifier :</span>
                                                <p>La présence du symbole allumé sur le tableau de bord (lorsque vous mettez le contact)</p>
                                                <v-img src="@/assets/info_valve1.png"/>
                                                <br>
                                                <p>L'indication de pression réel de chacun de vos pneus dans l'ordinateur de votre véhicule.</p>
                                                <v-img src="@/assets/info_valve2.png"/>
                                                <br>
                                                <p class="font-weight-bold">Dans le cas où ces deux indications sont présentes sur votre véhicule alors oui, votre véhicule est équipé de Valves électroniques !</p>

                                                <div class="d-flex" style="align-items: center">
                                                    <span class="underline">Qu’est-ce que le système de surveillance de la pression des pneus (TPMS) ?</span>
                                                    <v-img v-if="VUE_APP_SITE_ID === '1'" @click="tpmsInfo = true" src="@/assets/icons/icon_infos2.svg"
                                                           :style="[$vuetify.breakpoint.xl ? {'max-width' : '6%'} :
                                                                 $vuetify.breakpoint.lg ? {'max-width' : '5%'} : '']"  class="pointer"/>
                                                    <v-img v-if="VUE_APP_SITE_ID === '2'" @click="tpmsInfo = true" src="@/assets/icons/icon_infos_orange.svg"
                                                           :style="[$vuetify.breakpoint.xl ? {'max-width' : '6%'} :
                                                                 $vuetify.breakpoint.lg ? {'max-width' : '5%'} : '']"  class="pointer"/>
                                                </div>

                                            </v-col>

                                            <v-col v-if="tpmsInfo" offset-lg="1" lg="10" class="text-left">
                                                <span class="font-weight-bold">Système de surveillance de la pression des pneus (TPMS)</span>
                                                <br>
                                                <span>Les systèmes de mesure de la pression des pneus surveillent de manière automatique la pression des pneus. </span>
                                                <br>
                                                <span>  En cas de perte de pression sur un ou plusieurs pneus, le conducteur en est informé via l'affichage sur le tableau de bord.</span>
                                                <p>Il existe deux types de systèmes de surveillance de la pression des pneus :</p>
                                                <span class="font-weight-bold">SYSTÈME TPMS PASSIF</span>
                                                <br>
                                                <span>- La pression des pneus est mesurée de manière indirecte par rapport à la circonfèrence des roues ou en fonction des vibrations entre la jante et le pneu.</span>
                                                <br>
                                                <span>- La technologie déjà intégrée dans le véhicule comme le système ABS ou encore les capteurs de traction sont utilisés.</span>
                                                <br>
                                                <span>- La mesure n'est effectuée que pendant la conduite.</span>
                                                <br>
                                                <span>- Un témoin lumineux sur le tableau de bord indique une pression de pneu divergente de la valeur normale, sans indiquer la valeur réelle.</span>
                                                <br>
                                                <span>-Après le changement de roue, les valeurs normales de la pression des pneus doivent être à nouveau sauvegardées dans le système (le manuel du véhicule vous fournira l'aide nécessaire).</span>
                                                <br>
                                                <p>- Aucun capteur TPMS séparé n'est nécessaire lors de l'achat de pneus neufs.</p>
                                                <span class="font-weight-bold">SYSTÈME TPMS ACTIF</span>
                                                <br>
                                                <span>- La pression et la température des pneus sont mesurées en permanence dans le pneu de manière directe et précise à l'aide d'un capteur intégré dans la valve.</span>
                                                <br>
                                                <span>- Les données sont transmises sur l'appareil de commande à travers des capteurs sans fil.</span>
                                                <br>
                                                <span>- Certains systèmes affichent en continu les données au conducteur sur le tableau de bord, d'autres uniquement lors de changements de pression.</span>
                                                <br>
                                                <span>- Selon l'équipement du véhicule, le pneu ayant une perte de pression peut être localisé exactement.</span>
                                                <br>
                                                <span>- Une mesure à l'arrêt est également possible.</span>
                                                <br>
                                                <span>- Une configuration des capteurs après un changement de roue est effectuée individuellement par certains systèmes, pour d'autres, l'intervention d'un expert est nécessaire.</span>
                                                <br>
                                                <p>- Lors de l'achat de nouvelles roues complètes, vous avez besoin de capteurs TPMS - vous pouvez sélectionner ceux-ci chez nous, dans votre panier en ligne.</p>
                                                <span>Malheureusement, l'affichage sur le tableau de bord ne peut déterminer, si votre véhicule est équipé d'un système TPMS actif ou passif. En cas de doute, veuillez directement vous informer auprès de votre concessionnaire ou de votre garagiste.</span>


                                            </v-col>


                                            <v-col :class="{'col-lg-2': !tpmsInfo, 'col-lg-1': tpmsInfo}"  class="py-0 basline">
                                                <v-btn text @click="overlayValvesSwitch">
                                                    <v-icon large color="black">mdi mdi-close</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>

                                    </v-card-text>

                                </v-card>
                            </v-col>
                        </v-overlay>



                        <v-overlay
                                :absolute="true"
                                :value="overlayAccessoires"
                                :z-index="5"
                        >
                            <v-col offset-lg="2" lg="8">
                                    <v-card light class="resizeHeight" hover>
                                        <v-col class="pb-0 baseline">
                                            <v-btn text @click="overlayAccessoires = !overlayAccessoires">
                                                <v-icon large color="black">mdi mdi-close</v-icon>
                                            </v-btn>
                                        </v-col>
                                        <v-col class="pa-6">
                                            <v-row>
                                                <v-col class=" font-weight-bold text-center
                                                                               text-xl-h5
                                                                               text-lg-h6
                                                                               text-md-subtitle-1
                                                                               text-sm-subtitle-1
                                                                               text-subtitle-2">
                                                    Choisissez vos accessoires
                                                </v-col>
                                            </v-row>
                                            <v-row>

                                                <v-col lg="3" v-for="(accessoire, index) in accessoires" :key="index" >
<!--                                                    <v-card @click="addCart(index, accessoire.name)" hover height="100%">-->
                                                    <v-card @click="addCart(index, accessoire)" hover height="100%">
                                                        <v-img :src="accessoire.img"/>
                                                        <v-col class=" py-0 px-2 text-center align-end" >
                                                            <v-checkbox @click="addCart(index, accessoire)" class="ma-0" hide-details dense v-model="isSelectedIndex[index]" :label="accessoire.name"/>

                                                            <span>{{accessoire.price}} €</span>
                                                        </v-col>
                                                    </v-card>
                                                </v-col>

                                            </v-row>
                                        </v-col>
                                        <v-col  class="pb-6 d-flex justify-center" >
<!--                                            <v-btn  @click="overlayAccessoires = !overlayAccessoires">-->
                                            <v-btn  @click="overlayAccessoires = !overlayAccessoires">
                                                Confirmer
                                            </v-btn>
                                        </v-col>
                                    </v-card>
                            </v-col>




                        </v-overlay>


                    </v-col>
                </v-row>
            </v-col>
            <v-col lg="3" class="pa-0">
<!--                <Menu @clicked="overlayAccessoiresSwitch" :is-selected="isSelectedIndex"/>-->
                <Menu @clicked="overlayAccessoiresSwitch" @updated="updatePriceFooter" :update="triggerUpdate" :hide="hideDelete"/>

            </v-col>
<!--        </v-row>-->
        </v-row>
        <div v-if="$vuetify.breakpoint.lgAndUp" style="height: 16vh"></div>

        <v-col v-if="$vuetify.breakpoint.mdAndDown" class="bc_lightGrey pa-0" >
            <v-col lg="9" class="pa-0">

                <ConfigurateurHeader :header-title="headerTitle"/>

                <v-col class="bc_white" style="height: 100%; ">
                    <v-col offset-lg="1" lg="10" class="pa-0" >

                        <v-row>

                            <v-img v-if="isConnected === 'pending' && VUE_APP_SITE_ID === '1'" src="@/assets/loader.gif" style="max-width: 40%; margin: auto"/>
                            <v-img v-if="isConnected === 'pending' && VUE_APP_SITE_ID === '2'" src="@/assets/loaderDJ.gif" style="max-width: 40%; margin: auto"/>

                            <v-col cols="12" class="pt-0" v-if="isConnected !== 'pending'">
                                <v-col class="pa-0" cols="12" sm="8" offset-sm="2">
                                    <v-card @click="showOverlayMenu">
                                        <v-row class="px-2">
                                            <v-col cols="10" class="py-0" align-self="center">
                                                <v-card-text class="font-weight-bold pa-2 " :class="{'tc_blue' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">Voir détails commande et accessoires</v-card-text>
                                            </v-col>
                                            <v-col cols="2" class="pa-0" align-self="center">
                                                <v-icon large class="" >mdi mdi-chevron-down</v-icon>
                                            </v-col>
                                        </v-row>

                                    </v-card>
                                </v-col>
                            </v-col>
<!--                            <v-btn>Voir détails commande <br>et accessoires</v-btn>-->

                            <v-col v-if="isConnected === false">
                                <SignInUp @clicked="logged" @connect="connect"/>

                            </v-col>


                            <Formulaire v-if="isConnected === true" @infoClicked="overlayValvesSwitch" @updated="updatePriceFooter" @hideDelete="hideDeleteFunction" ref="Formulaire"/>

                        </v-row>


                        <v-overlay
                                :absolute="true"
                                :value="overlayValves"
                                :z-index="3"
                        >
                            <v-col cols="12">

                                <v-card class="bc_lightGrey" style="max-width:1000px;border-radius:50px;" >

                                    <v-card-text class="text-center tc_grey" id="valvesModal">
                                        <v-col>
                                            <v-col v-if="!tpmsInfo" offset-lg="2" lg="8" class="text-left" >
                                                <p class="font-weight-bold "  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">MON VÉHICULE EST-IL ÉQUIPÉ DE VALVES ÉLECTRONIQUES ?</p>
                                                <p class="font-weight-bold">Deux informations doivent être présentes sur votre tableau de bord ainsi que sur l’ordinateur de votre véhicule.</p>
                                                <span class="font-weight-bold">Vous devez vérifier :</span>
                                                <p>La présence du symbole allumé sur le tableau de bord (lorsque vous mettez le contact)</p>
                                                <v-img src="@/assets/info_valve1.png"/>
                                                <br>
                                                <p>L'indication de pression réel de chacun de vos pneus dans l'ordinateur de votre véhicule.</p>
                                                <v-img src="@/assets/info_valve2.png"/>
                                                <br>
                                                <p class="font-weight-bold">Dans le cas où ces deux indications sont présentes sur votre véhicule alors oui, votre véhicule est équipé de Valves électroniques !</p>

<!--                                                <div class="d-flex" style="align-items: center">-->
<!--                                                    <span class="underline">Qu’est-ce que le système de surveillance de la pression des pneus (TPMS) ?</span>-->
<!--                                                    <v-img @click="tpmsInfo = true" src="@/assets/icons/icon_infos2.svg" style="max-width: 5%" class="pointer"/>-->
<!--                                                </div>-->
                                                <div class="d-flex" style="align-items: center" @click="toTmpsInfos">
                                                    <v-col cols="10" class="pa-0">
                                                        <span class="underline">Qu’est-ce que le système de surveillance de la pression des pneus (TPMS) ?</span>
                                                    </v-col>
                                                    <v-col cols="2" class="pa-0">
                                                        <v-img v-if="VUE_APP_SITE_ID === '1'" src="@/assets/icons/icon_infos2.svg"
                                                                :style="[$vuetify.breakpoint.md ? {'max-width' : '25%'} :
                                                                         $vuetify.breakpoint.sm ? {'max-width' : '30%'} :
                                                                         $vuetify.breakpoint.xs ? {'max-width' : '70%'} : '']" class="pointer"/>
                                                        <v-img v-if="VUE_APP_SITE_ID === '2'" src="@/assets/icons/icon_infos_orange.svg"
                                                                :style="[$vuetify.breakpoint.md ? {'max-width' : '25%'} :
                                                                         $vuetify.breakpoint.sm ? {'max-width' : '30%'} :
                                                                         $vuetify.breakpoint.xs ? {'max-width' : '70%'} : '']" class="pointer"/>
                                                    </v-col>
                                                </div>

                                            </v-col>

                                            <v-col v-if="tpmsInfo" offset-lg="1" lg="10" class="text-left">
                                                <span class="font-weight-bold">Système de surveillance de la pression des pneus (TPMS)</span>
                                                <br>
                                                <span>Les systèmes de mesure de la pression des pneus surveillent de manière automatique la pression des pneus. </span>
                                                <br>
                                                <span>  En cas de perte de pression sur un ou plusieurs pneus, le conducteur en est informé via l'affichage sur le tableau de bord.</span>
                                                <p>Il existe deux types de systèmes de surveillance de la pression des pneus :</p>
                                                <span class="font-weight-bold">SYSTÈME TPMS PASSIF</span>
                                                <br>
                                                <span>- La pression des pneus est mesurée de manière indirecte par rapport à la circonfèrence des roues ou en fonction des vibrations entre la jante et le pneu.</span>
                                                <br>
                                                <span>- La technologie déjà intégrée dans le véhicule comme le système ABS ou encore les capteurs de traction sont utilisés.</span>
                                                <br>
                                                <span>- La mesure n'est effectuée que pendant la conduite.</span>
                                                <br>
                                                <span>- Un témoin lumineux sur le tableau de bord indique une pression de pneu divergente de la valeur normale, sans indiquer la valeur réelle.</span>
                                                <br>
                                                <span>-Après le changement de roue, les valeurs normales de la pression des pneus doivent être à nouveau sauvegardées dans le système (le manuel du véhicule vous fournira l'aide nécessaire).</span>
                                                <br>
                                                <p>- Aucun capteur TPMS séparé n'est nécessaire lors de l'achat de pneus neufs.</p>
                                                <span class="font-weight-bold">SYSTÈME TPMS ACTIF</span>
                                                <br>
                                                <span>- La pression et la température des pneus sont mesurées en permanence dans le pneu de manière directe et précise à l'aide d'un capteur intégré dans la valve.</span>
                                                <br>
                                                <span>- Les données sont transmises sur l'appareil de commande à travers des capteurs sans fil.</span>
                                                <br>
                                                <span>- Certains systèmes affichent en continu les données au conducteur sur le tableau de bord, d'autres uniquement lors de changements de pression.</span>
                                                <br>
                                                <span>- Selon l'équipement du véhicule, le pneu ayant une perte de pression peut être localisé exactement.</span>
                                                <br>
                                                <span>- Une mesure à l'arrêt est également possible.</span>
                                                <br>
                                                <span>- Une configuration des capteurs après un changement de roue est effectuée individuellement par certains systèmes, pour d'autres, l'intervention d'un expert est nécessaire.</span>
                                                <br>
                                                <p>- Lors de l'achat de nouvelles roues complètes, vous avez besoin de capteurs TPMS - vous pouvez sélectionner ceux-ci chez nous, dans votre panier en ligne.</p>
                                                <span>Malheureusement, l'affichage sur le tableau de bord ne peut déterminer, si votre véhicule est équipé d'un système TPMS actif ou passif. En cas de doute, veuillez directement vous informer auprès de votre concessionnaire ou de votre garagiste.</span>


                                            </v-col>


                                            <v-col :class="{'col-lg-2': !tpmsInfo, 'col-lg-1': tpmsInfo}"  class="py-0 basline">
                                                <v-btn text @click="overlayValvesSwitch">
                                                    <v-icon large color="black">mdi mdi-close</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-col>

                                    </v-card-text>

                                </v-card>
                            </v-col>
                        </v-overlay>






                    </v-col>
                </v-col>
            </v-col>
            <v-col lg="3" class="pa-0">
<!--                <Menu @clicked="overlayAccessoiresSwitch" :is-selected="isSelectedIndex"/>-->
                <Menu @clicked="overlayAccessoiresSwitch"  @updated="updatePriceFooter" :update="triggerUpdate" :hide="hideDelete"/>
            </v-col>
        </v-col>


<!--        <ConfigurateurFooter :configurateur-json="configurateur"/>-->
<!--                <ConfigurateurFooter :is-connected="isConnected" :is-selected="isSelectedIndex"/>-->
                <ConfigurateurFooter :is-connected="isConnected"  :update="triggerUpdate"/>
    </v-app>
</template>

<script>
import ConfigurateurHeader from "../headers/ConfigurateurHeader";
import ConfigurateurFooter from "../footer/ConfigurateurFooter";
import Menu from "./Menu";
import SignInUp from "./Etape4_signInUp";
import Formulaire from "./Etape4_formulaire";

import cacheEcrou from "@/assets/accessoires/cache-ecrou.jpg"
import antivols from "@/assets/accessoires/antivols_de_roues.jpg"
import valvesAlu from "@/assets/accessoires/valve-alu.jpg"
import valvesElectro from "@/assets/accessoires/valve_electro.jpg"
import BagueCentrage from "@/assets/accessoires/bague-de-centrage.jpg"
import valvesCoudees from "@/assets/accessoires/valves-coudees.jpg"
import antiInsect from "@/assets/accessoires/nettoyant-insecte.jpg"
import changementEntraxe from "@/assets/accessoires/g_elargisseur_ch_entraxe.jpg"
import PeintureBleu from "@/assets/accessoires/peinture-etrier-blue.jpg"
import PeintureJaune from "@/assets/accessoires/peinture-etrier-yellow.jpg"
import PeintureRouge from "@/assets/accessoires/peinture-etrier-red.jpg"

import brillantPneus from "@/assets/accessoires/brillant_pneus.jpg"

import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });


import $ from "jquery";

import {mapState} from 'vuex'
// import axios from "axios";


export default {
    name: "Etape4",
    components:{
        Menu,
        ConfigurateurHeader,
        ConfigurateurFooter,
        SignInUp,
        Formulaire

    },
    data: () => ({
        headerTitle: 7,
        hideDelete:false,
        // isSelected:{},
        // choosedAccessoires:{},
        SelectedAccessoires: [],
        selected:'',
        overlayValves:false,
        overlayAccessoires:false,
        tpmsInfo:false,
        isConnected:'pending',
        isAccessoire:{},
        isSelectedIndex:[],
        accessoires:[
            {name:'20 Caches boulon', price:'20,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:cacheEcrou, quantite: 1, type:3, flux:null, buying_price: null},
            {name:'4 Antivols de roues', price:'30,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:antivols, quantite: 1, type:3, flux:null, buying_price: null},
            {name:'4 Valves de roues et bouchons alu', price:'10,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:valvesAlu, quantite: 1, type:3, flux:null, buying_price: null},
            {name:'4 Valves electroniques', price:'200,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:valvesElectro, quantite: 1, type:3, flux:null, buying_price: null},
            {name:'4 Bagues de centrage', price:'30,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:BagueCentrage, quantite: 1, type:3, flux:null, buying_price: null},
            {name:'Valves coudées', price:'150,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img: valvesCoudees, quantite: 1, type:3, flux:null, buying_price: null},
            {name:'Anti insect', price:'12,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:antiInsect, quantite: 1, type:3, flux:null, buying_price: null},
            {name:'Changement entraxe Audi/VW', price:'380,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:changementEntraxe, quantite: 1, type:3, flux:null, buying_price: null},
            {name:'Kit peinture étrier bleu', price:'50,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:PeintureBleu, quantite: 1, type:3, flux:null, buying_price: null},
            {name:'Kit peinture étrier jaune', price:'50,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:PeintureJaune, quantite: 1, type:3, flux:null, buying_price: null},
            {name:'Kit peinture étrier rouge', price:'50,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:PeintureRouge, quantite: 1, type:3, flux:null, buying_price: null},
            {name:'Brillant pour pneus', price:'12,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:brillantPneus, quantite: 1, type:3, flux:null, buying_price: null},
        ],
        triggerUpdate: 1,
        VUE_APP_SITE_ID:null,
    }),
    methods: {
        hideDeleteFunction(){
          console.log('trigger')
            this.hideDelete = true
        },
        updatePriceFooter(){
            console.log('updatePriceFooter')
            this.triggerUpdate ++
        },
        setOnglet(item, value){
            // console.log('setOnglet',value, item)

            switch(value){
                case"4jantes":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : item, jantesArr : item})
                    this.infoJante = item
                    this.currentColor = this.colors[0].name;
                    this.photoPrincipal = this.infoJante.photo_1
                    this.triggerUpdate ++
                    break
                case"jantesAv":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : item, jantesArr : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr})
                    this.triggerUpdate ++
                    break
                case"jantesArr":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv, jantesArr : item})
                    this.triggerUpdate ++
                    break
                case"4pneus":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : item, pneusArr : item})
                    this.triggerUpdate ++
                    break
                case"pneusAv":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : item, pneusArr : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr})
                    this.triggerUpdate ++
                    break
                case"pneusArr":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv, pneusArr : item})
                    this.triggerUpdate ++
                    break
                case"accessoires":
                    this.TotalPriceAccessoires = 0
                    Object.values(item).forEach((e) => {
                        if(e != null) {
                            this.TotalPriceAccessoires += parseInt(e.data.price)
                        }
                    })
                    // console.log('this.TotalPriceAccessoires', this.TotalPriceAccessoires)
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires', {accessoires: item, totalPrice: this.TotalPriceAccessoires})

                    this.triggerUpdate ++
                    break
                case"indexAccessoires":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires', {accessoires: ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').accessoires,totalPrice: ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').totalPrice , selected: item })
                    this.triggerUpdate ++
                    break
                case"modelePossibleAuCarre":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre', item)
                    break
                case"modelePossibleFront":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront', item)
                    break
                case"modelePossibleRear":
                    ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear', item)
                    break
            }

            ls.set('last_updated', this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale)

        },
        getOnglet(value){
// console.log('access', ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires'))
            switch(value){
                case'jantesAv':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv
                case 'jantesArr':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr
                case 'pneusAv':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv
                case 'pneusArr':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr
                case 'accessoires':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').accessoires
                case 'totalPriceAccessoires':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').totalPrice
                case 'indexAccessoires':
                    // console.log('getIndexAccessoires', ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').selected)
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').selected
                case 'modelePossibleAuCarre':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre')
                case 'modelePossibleFront':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront')
                case 'modelePossibleRear':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear')
            }
        },
        addCart:function (index,  accessoire) {

            // this.isSelected = this.isSelectedIndex
            if(this.isSelectedIndex[index] === undefined || this.isSelectedIndex[index] === false){
            console.log('now true')


                this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
                this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
                this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0



                this.isSelectedIndex[index] = true

                this.isAccessoire = {}
                this.isAccessoire.index = index
                this.isAccessoire.data = accessoire
                this.isAccessoire.selected = true

                this.SelectedAccessoires[index] = this.isAccessoire

                this.isSelectedIndex = Object.assign({}, this.isSelectedIndex)

                this.setOnglet(this.SelectedAccessoires, 'accessoires')
                this.setOnglet(this.isSelectedIndex, 'indexAccessoires')



                this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
                this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
                this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0


                this.TotalPriceAccessoires = 0
                Object.values(this.SelectedAccessoires).forEach((e) => {
                    if(e != null) {
                        this.TotalPriceAccessoires += parseInt(e.data.price)
                    }
                })

                return
            }
            if(this.isSelectedIndex[index] === true){
                console.log('now false')


                this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
                this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
                this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0


                this.isSelectedIndex[index] = false


                this.isSelectedIndex = Object.assign({}, this.isSelectedIndex)
                var newCart = this.getOnglet('accessoires')

                // console.log('oldCart', newCart)

                delete newCart[index]

                // console.log('newcart', newCart)

                this.setOnglet(newCart, 'accessoires')
                this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []

                this.TotalPriceAccessoires = 0
                Object.values(this.SelectedAccessoires).forEach((e) => {
                    if(e != null) {
                        this.TotalPriceAccessoires += parseInt(e.data.price)
                    }
                })

                this.setOnglet(this.isSelectedIndex, 'indexAccessoires')


                return
            }
        },
        addCart1:function (index,  accessoire) {

            if(this.isSelectedIndex[index] === true){
                // if(this.isSelectedIndex[index] === undefined || this.isSelectedIndex[index] === false){
                console.log('now true')

                this.isSelectedIndex[index] = true

                this.isAccessoire = {}
                this.isAccessoire.index = index
                this.isAccessoire.data = accessoire
                this.isAccessoire.selected = true

                this.SelectedAccessoires[index] = this.isAccessoire

                // console.log('this.SelectedAccessoires', this.SelectedAccessoires)
                // this.isSelected = Object.assign({}, this.isSelected)
                // this.$store.commit('infoAccessoires/setAccessoire', this.SelectedAccessoire)
                // this.$store.commit('infoAccessoires/setSelected', this.isSelectedIndex)

                this.setOnglet(this.SelectedAccessoires, 'accessoires')
                this.setOnglet(this.isSelectedIndex, 'indexAccessoires')


                this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
                this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
                this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0

                return
            }
            if(this.isSelectedIndex[index] === false || this.isSelectedIndex[index] === undefined ){
                console.log('now false')
                this.isSelectedIndex[index] = false


                // this.isSelected = Object.assign({}, this.isSelected)
                // this.$store.commit('infoAccessoires/deleteOneAccessoire', index)
                // this.$store.commit('infoAccessoires/setSelected', this.isSelected)
                // this.$store.commit('infoAccessoires/setSelected', this.isSelectedIndex)

                var newCart = this.getOnglet('accessoires')

                // console.log('oldCart', newCart)

                delete newCart[index]

                // console.log('newcart', newCart)

                this.setOnglet(newCart, 'accessoires')
                this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []

                this.TotalPriceAccessoires = 0
                Object.values(this.SelectedAccessoires).forEach((e) => {
                    if(e != null) {
                        this.TotalPriceAccessoires += parseInt(e.data.price)
                    }
                })

                this.setOnglet(this.isSelectedIndex, 'indexAccessoires')

                return
            }
        },
        logged:function(){
            this.isConnected = true
        },
        overlayValvesSwitch: function(){
            this.overlayValves = !this.overlayValves;
            this.tpmsInfo =  false
            $(window).scrollTop($('#valvesModal').offset().top);

        },
        overlayAccessoiresSwitch: function(data){
            this.overlayAccessoires = !this.overlayAccessoires;
            // console.log(data)
            this.isSelectedIndex = data
        },
        toTmpsInfos:function () {
            this.tpmsInfo = true;
            window.scrollTo(0,0)
        },
        connect: function (e) {
            if(e === true){
                this.isConnected = true
            }else if(e === false){
                this.isConnected = false
            }
        },
        showOverlayMenu:function(){
            this.$store.commit('detailCommande/setDetailCommande', true)
        },

    },
    created() {

        this.SelectedJanteAv = this.getOnglet('jantesAv')
        this.SelectedJanteArr = this.getOnglet('jantesArr')
        this.SelectedPneuAv = this.getOnglet('pneusAv') ? this.getOnglet('pneusAv') : null
        this.SelectedPneuArr = this.getOnglet('pneusArr') ? this.getOnglet('pneusArr') : null
        this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
        this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
        // this.isSelected = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
        this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0

        this.$store.commit('footerNext/setNext', 'false')

        let token = this.getToken
        let userId = this.getUserId
        let isRevendeur = this.isRevendeur
        let waitingRevendeur = this.waitingRevendeur
        let tauxRemise = this.tauxRemise


        this.$store.dispatch('auth/AUTH_REQUEST', { token, userId, isRevendeur, waitingRevendeur, tauxRemise }).then(() => {
            this.isConnected = true

        }).catch(() => {
            this.isConnected = false
        })
    },
    mounted() {
        this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        if( process.env.NODE_ENV.trim() !== "development") {
            window.dataLayer.push({
                'event': 'Pageview',
                'pagePath': '/etape4',
                'pageTitle': 'Etape4'
            });
        }
    },

    computed: {

        ...mapState({
            getToken: state => state.auth.token,
            getUserId: state => state.auth.userId,
            isRevendeur: state => state.auth.isRevendeur,
            waitingRevendeur: state => state.auth.waitingRevendeur,
            tauxRemise: state => state.auth.tauxRemise
        })

    },


}
</script>

<style scoped>
@media (max-width: 1264px) {
   div >>> .v-overlay__content{
        width: 100%;
        top: 10% !important;
        position: absolute;
    }
}

@media (min-width: 1264px) {
   div >>> .v-overlay__content{
        top: 4% !important;
        position: absolute;
    }
}

.resizeHeight {
    height: 800px!important;
    overflow-y: scroll;
}

.size-font >>> label{
    font-size: 13px;
}

</style>

<style>

    .baseline{
        display: flex;
        justify-content: flex-end;
        align-self: baseline;
    }


</style>
