<template>

    <v-app  class="backgroud_gradient" fluid >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>

        <v-content v-if="loaderStatus === true">
            <v-col class="bc_white text-center">
                <v-img v-if="VUE_APP_SITE_ID === '1'" src="@/assets/loader.gif" style="max-width: 40%; margin: auto" alt='Espace-Jantes - Chargement Jantes' title="Espace-Jantes - Chargement Jantes"/>
                <v-img v-if="VUE_APP_SITE_ID === '2'" src="@/assets/loaderDJ.gif" style="max-width: 40%; margin: auto" alt='Distri-Jantes - Chargement Jantes' title="Distri-Jantes - Chargement Jantes"/>
            </v-col>
        </v-content>
        <v-content v-if="loaderStatus === false">
            <v-col class="bc_white text-center" v-if="!paymentError">
                <p class="font-weight-bold mt-12" :class="{'display-2': $vuetify.breakpoint.mdAndUp,'display-1': $vuetify.breakpoint.smAndDown}">Merci pour votre achat !</p>
                <p class="font-weight-bold" :class="{'headline': $vuetify.breakpoint.mdAndUp,'title': $vuetify.breakpoint.smAndDown}">Vous pouvez consulter la facture de votre commande depuis votre espace "Mon Compte".</p>

                <RouterLink :to="{name: 'Home'}" class="no_deco">
                    <v-btn class="mb-12">Retour à la page d’accueil</v-btn>
                </RouterLink>
            </v-col>

            <v-col class="bc_white text-center" v-else>
                <p class="font-weight-bold" :class="{'display-2': $vuetify.breakpoint.mdAndUp,'display-1': $vuetify.breakpoint.smAndDown}">Malheureusement votre mode de paiement a été refusé.</p>
                <p class="font-weight-bold" :class="{'headline': $vuetify.breakpoint.mdAndUp,'title': $vuetify.breakpoint.smAndDown}">Quelque chose s'est mal passé durant le paiement de votre commande.</p>
                <p class="font-weight-bold" :class="{'headline': $vuetify.breakpoint.mdAndUp,'title': $vuetify.breakpoint.smAndDown}">Veuillez réessayer, si l'erreur persiste contactez nous au <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a>.</p>

                <RouterLink :to="{name: 'Etape5', params: {marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}" class="no_deco" >
                    <v-btn class="font-weight-bold">Retour à ma commande</v-btn>
                </RouterLink>
            </v-col>
        </v-content>

        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "../headers/HomepageHeaderEj";
import HomepageHeaderDj from "../headers/HomepageHeaderDj";
import HomepageFooterEj from "../footer/HomepageFooterEj";
import HomepageFooterDj from "../footer/HomepageFooterDj";
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {mapState} from "vuex";
// eslint-disable-next-line no-unused-vars
import CryptoJS from "crypto-js";
Vue.use(VueAxios, axios)

import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

export default {
name: "Etape6Success.vue",
    components: {
        HomepageHeaderEj,
        HomepageHeaderDj,
        HomepageFooterEj,
        HomepageFooterDj,
    },
    data:() =>({
        avisVerifCommande:{},
        formatedJanteAv : {},
        formatedJanteArr : {},
        formatedPneuAv : {},
        formatedPneuArr : {},
        paymentError: null,
        test: {},
        SelectedJanteAv: {},
        SelectedJanteArr: {},
        SelectedPneuAv: {},
        SelectedPneuArr: {},
        refChecking:null,
        VUE_APP_SITE_ID:null
    }),
    mounted() {
        this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
    },
    created() {
        this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID

        this.typeMonte = (this.$route.params.decale).toString() === '0' ? 'par4' : 'decale'
        this.typeMonte === 'decale' ? this.monteDecaJante = true : this.monteDecaJante = false
        this.typeMonte === 'decale' ? this.monteDecaPneu = true : this.monteDecaPneu = false
        this.SelectedJanteAv = this.getOnglet('jantesAv')
        this.SelectedJanteArr = this.getOnglet('jantesArr')
        this.SelectedPneuAv = this.getOnglet('pneusAv') ? this.getOnglet('pneusAv') : null
        this.SelectedPneuArr = this.getOnglet('pneusArr') ? this.getOnglet('pneusArr') : null
        this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
        this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
        this.isSelected = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
        this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0

        this.refChecking = this.getOnglet('reference') ? this.getOnglet('reference') : null

// this.refChecking = "EJ-52978"
// this.refChecking = "EJ-51184"
// this.refChecking = "DJ-52548"

        this.$store.commit('loader/setLoader', true)


            console.log('refChecking', this.refChecking)
            console.log('type_paiement', this.type_paiement)
            console.log('VUE_APP_SITE_ID', this.VUE_APP_SITE_ID)


            var product = []
            Vue.axios.post('https://api.espace-jantes.com/updatePreOrder', {
                params: {
                    numeroCommande: this.refChecking,
                    type_paiement: this.typePaiement,
                    websiteid: this.VUE_APP_SITE_ID
                },
            })
                .then((res) => {
                    console.log('updatePreOrder', res)
                    if (res.data === "OK") {
                        // console.log('res.data === "OK"')

                        // numeroCommande = this.refChecking

                        if (this.infoCommande.produits.jantes !== "undefined" || this.infoCommande.produits.jantes !== undefined) {
                            var jantes = {}

                            this.infoCommande.produits.jantes.forEach(element => {
                                jantes.name = element.modele
                                jantes.brand = element.marque
                                jantes.id = element.reference
                                jantes.category = 'jantes'
                                jantes.quantity = 2
                                jantes.price = element.public_price
                                jantes.variant = 'largeur : ' + element.largeur + ', diametre: ' + element.diametre + ', deport: ' + element.offset + ', entraxe: ' + element.entraxe + ', couleur: ' + element.couleur
                                product.push(jantes)
                            })
                        }

                        if (this.infoCommande.produits.pneus !== undefined) {
                            var pneu = {}
                            this.infoCommande.produits.pneus.forEach(element => {

                                pneu.name = element.modele
                                pneu.brand = element.marque
                                pneu.id = element.reference
                                pneu.category = 'pneus'
                                pneu.quantity = 2
                                pneu.price = element.prixNet
                                pneu.variant = 'Information : ' + element.HBV + '/' + element.width + ' R' + element.diametre + ' ' + element.indiceDeCharge + ' ' + element.indiceDeVitesse
                                product.push(pneu)
                            })
                        }
                        if (this.infoCommande.produits.accessoires !== undefined) {
                            var accessoire = {}
                            this.infoCommande.produits.accessoires.forEach(element => {
                                if (element != null) {
                                    accessoire.brand = element.data.marque
                                    accessoire.name = element.data.name
                                    accessoire.price = element.data.price
                                    accessoire.quantity = element.data.quantite
                                    accessoire.id = element.data.reference
                                    accessoire.category = 'accessoires'
                                    product.push(accessoire)
                                }
                            })
                        }
                        if (process.env.NODE_ENV.trim() !== "development") {
                            var montantDataLayer = parseFloat(this.infoCommande.montant) + parseFloat(9.90)
                            window.dataLayer.push({
                                'event': 'transaction',
                                'ecommerce': {
                                    'purchase': {
                                        'actionField': {
                                            'id': this.refChecking,                         // Transaction ID. Required for purchases and refunds.
                                            'affiliation': 'Espace Jantes',
                                            'revenue': montantDataLayer.toFixed(2),                     // Total transaction value (incl. tax and shipping)
                                            'tax': '0',
                                            'shipping': '0'
                                        },
                                        'products': product
                                    }
                                }
                            });
                        }

                    } else {
                        console.log('error Update')
                        this.$store.commit('loader/setLoader', false)

                    }
                }).catch((err) => {
                console.log(err)
            })

            setTimeout(() => {


                Vue.axios.get('https://api.espace-jantes.com/getOneUser', {
                    params: {id_user: this.getUserId},
                })
                    .then((response) => {

                        let infoUser = response.data[0]


                        if ((JSON.stringify(this.SelectedJanteAv) === JSON.stringify(this.SelectedJanteArr)) && (JSON.stringify(this.SelectedPneuAv) === JSON.stringify(this.SelectedPneuArr))) {

                            let counter
                            let products

                            if (this.SelectedPneuAv) {
                                counter = 2

                                products = {
                                    "0": {
                                        "id_product": this.SelectedJanteAv.id,
                                        "name_product": this.SelectedJanteAv.marque + ' ' + this.SelectedJanteAv.modele + ' ' + this.SelectedJanteAv.couleur + ' ' + this.SelectedJanteAv.largeur + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.SelectedJanteAv.offset,
                                    },
                                    "1": {
                                        "id_product": this.SelectedPneuAv.id,
                                        "name_product": this.SelectedPneuAv.marque + ' ' + this.SelectedPneuAv.modele + ' ' + this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' + this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' ' + this.SelectedPneuAv?.indiceDeVitesse + ' ' + (this.SelectedPneuAv.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuAv.hiver === 1 ? 'Hiver' : this.SelectedPneuAv.ete === 1 ? 'Eté' : ' 4 saisons'),
                                    },
                                }

                            } else {
                                counter = 1

                                products = {
                                    "0": {
                                        "id_product": this.SelectedJanteAv.id,
                                        "name_product": this.SelectedJanteAv.marque + ' ' + this.SelectedJanteAv.modele + ' ' + this.SelectedJanteAv.couleur + ' ' + this.SelectedJanteAv.largeur + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.SelectedJanteAv.offset,
                                    }
                                }
                            }

                            this.SelectedAccessoires.forEach((accessoire, index) => {
                                if (accessoire !== null) {
                                    Object.assign(products, {
                                        [counter]: {
                                            "id_product": index,
                                            "name_product": accessoire.data.name
                                        }
                                    });
                                    counter++
                                }
                            })

                            this.avisVerifCommande = {
                                "idWebsite": process.env.VUE_APP_AVIS_VERIFIE_CLIENT_ID,
                                "message": {
                                    "query": "pushCommandeSHA1",
                                    "order_ref": this.refChecking,
                                    "email": infoUser.mail,
                                    "lastname": infoUser.nom,
                                    "firstname": infoUser.prenom,
                                    "order_date": this.formatDate(),
                                    "PRODUCTS": products,
                                    "website_id" : process.env.VUE_APP_SITE_ID,
                                }
                            }


                        }
                        else if ((JSON.stringify(this.SelectedJanteAv) !== JSON.stringify(this.SelectedJanteArr)) && (JSON.stringify(this.SelectedPneuAv) === JSON.stringify(this.SelectedPneuArr))) {

                            let counter
                            let products

                            if (this.SelectedPneuAv) {
                                counter = 3

                                products = {
                                    "0": {
                                        "id_product": this.SelectedJanteAv.id,
                                        "name_product": this.SelectedJanteAv.marque + ' ' + this.SelectedJanteAv.modele + ' ' + this.SelectedJanteAv.couleur + ' ' + this.SelectedJanteAv.largeurMinAv + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.SelectedJanteAv.offsetFront,
                                    },
                                    "1": {
                                        "id_product": this.SelectedJanteArr.id,
                                        "name_product": this.SelectedJanteArr.marque + ' ' + this.SelectedJanteArr.modele + ' ' + this.SelectedJanteArr.couleur + ' ' + this.SelectedJanteArr.largeurMaxArr + 'X' + this.SelectedJanteArr.diametre + ' - ' + this.SelectedJanteArr.entraxe + ' - ET ' + this.SelectedJanteArr.offsetRear,
                                    },
                                    "2": {
                                        "id_product": this.SelectedPneuAv.id,
                                        "name_product": this.SelectedPneuAv.marque + ' ' + this.SelectedPneuAv.modele + ' ' + this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' + this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' ' + this.SelectedPneuAv?.indiceDeVitesse + ' ' + (this.SelectedPneuAv.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuAv.hiver === 1 ? 'Hiver' : this.SelectedPneuAv.ete === 1 ? 'Eté' : ' 4 saisons'),
                                    },
                                }

                            } else {
                                counter = 2

                                products = {
                                    "0": {
                                        "id_product": this.SelectedJanteAv.id,
                                        "name_product": this.SelectedJanteAv.marque + ' ' + this.SelectedJanteAv.modele + ' ' + this.SelectedJanteAv.couleur + ' ' + this.SelectedJanteAv.largeur + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.SelectedJanteAv.offset,
                                    },
                                    "1": {
                                        "id_product": this.SelectedJanteArr.id,
                                        "name_product": this.SelectedJanteArr.marque + ' ' + this.SelectedJanteArr.modele + ' ' + this.SelectedJanteArr.couleur + ' ' + this.SelectedJanteArr.largeur + 'X' + this.SelectedJanteArr.diametre + ' - ' + this.SelectedJanteArr.entraxe + ' - ET ' + this.SelectedJanteArr.offset,
                                    }
                                }
                            }


                            this.SelectedAccessoires.forEach((accessoire, index) => {
                                if (accessoire !== null) {
                                    Object.assign(products, {
                                        [counter]: {
                                            "id_product": index,
                                            "name_product": accessoire.data.name
                                        }
                                    });
                                    counter++
                                }
                            })

                            this.avisVerifCommande = {
                                "idWebsite": process.env.VUE_APP_AVIS_VERIFIE_CLIENT_ID,
                                "message": {
                                    "query": "pushCommandeSHA1",
                                    "order_ref": this.refChecking,
                                    "email": infoUser.mail,
                                    "lastname": infoUser.nom,
                                    "firstname": infoUser.prenom,
                                    "order_date": this.formatDate(),
                                    "PRODUCTS": products,
                                    "website_id" : process.env.VUE_APP_SITE_ID,
                                }
                            }
                        }
                        else if ((JSON.stringify(this.SelectedJanteAv) === JSON.stringify(this.SelectedJanteArr)) && (JSON.stringify(this.SelectedPneuAv) !== JSON.stringify(this.SelectedPneuArr))) {

                            let counter = 3

                            let products = {
                                "0": {
                                    "id_product": this.SelectedJanteAv.id,
                                    "name_product": this.SelectedJanteAv.marque + ' ' + this.SelectedJanteAv.modele + ' ' + this.SelectedJanteAv.couleur + ' ' + this.SelectedJanteAv.largeur + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.SelectedJanteAv.offset,
                                },
                                "1": {
                                    "id_product": this.SelectedPneuAv.id,
                                    "name_product": this.SelectedPneuAv.marque + ' ' + this.SelectedPneuAv.modele + ' ' + this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' + this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' ' + this.SelectedPneuAv?.indiceDeVitesse + ' ' + (this.SelectedPneuAv.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuAv.hiver === 1 ? 'Hiver' : this.SelectedPneuAv.ete === 1 ? 'Eté' : ' 4 saisons'),
                                },
                                "2": {
                                    "id_product": this.SelectedPneuArr.id,
                                    "name_product": this.SelectedPneuArr.marque + ' ' + this.SelectedPneuArr.modele + ' ' + this.SelectedPneuArr.HBVREAR + '/' + this.SelectedPneuArr.widthRear + 'R' + this.SelectedPneuArr.diametre + ' ' + this.SelectedPneuArr.indiceDeChargeRear + ' ' + this.SelectedPneuArr?.indiceDeVitesse + ' ' + (this.SelectedPneuArr.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuArr.hiver === 1 ? 'Hiver' : this.SelectedPneuArr.ete === 1 ? 'Eté' : ' 4 saisons'),
                                },
                            }

                            this.SelectedAccessoires.forEach((accessoire, index) => {
                                if (accessoire !== null) {
                                    Object.assign(products, {
                                        [counter]: {
                                            "id_product": index,
                                            "name_product": accessoire.data.name
                                        }
                                    });
                                    counter++
                                }
                            })

                            this.avisVerifCommande = {
                                "idWebsite": process.env.VUE_APP_AVIS_VERIFIE_CLIENT_ID,
                                "message": {
                                    "query": "pushCommandeSHA1",
                                    "order_ref": this.refChecking,
                                    "email": infoUser.mail,
                                    "lastname": infoUser.nom,
                                    "firstname": infoUser.prenom,
                                    "order_date": this.formatDate(),
                                    "PRODUCTS": products,
                                    "website_id" : process.env.VUE_APP_SITE_ID,
                                }
                            }
                        }
                        else if ((JSON.stringify(this.SelectedJanteAv) !== JSON.stringify(this.SelectedJanteArr)) && (JSON.stringify(this.SelectedPneuAv) !== JSON.stringify(this.SelectedPneuArr))) {

                            let counter = 4

                            let products = {
                                "0": {
                                    "id_product": this.SelectedJanteAv.id,
                                    "name_product": this.SelectedJanteAv.marque + ' ' + this.SelectedJanteAv.modele + ' ' + this.SelectedJanteAv.couleur + ' ' + this.SelectedJanteAv.largeurMinAv + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.SelectedJanteAv.offsetFront,
                                },
                                "1": {
                                    "id_product": this.SelectedJanteArr.id,
                                    "name_product": this.SelectedJanteArr.marque + ' ' + this.SelectedJanteArr.modele + ' ' + this.SelectedJanteArr.couleur + ' ' + this.SelectedJanteArr.largeurMaxArr + 'X' + this.SelectedJanteArr.diametre + ' - ' + this.SelectedJanteArr.entraxe + ' - ET ' + this.SelectedJanteArr.offsetRear,
                                },
                                "2": {
                                    "id_product": this.SelectedPneuAv.id,
                                    "name_product": this.SelectedPneuAv.marque + ' ' + this.SelectedPneuAv.modele + ' ' + this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' + this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' ' + this.SelectedPneuAv?.indiceDeVitesse + ' ' + (this.SelectedPneuAv.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuAv.hiver === 1 ? 'Hiver' : this.SelectedPneuAv.ete === 1 ? 'Eté' : ' 4 saisons'),
                                },
                                "3": {
                                    "id_product": this.SelectedPneuArr.id,
                                    "name_product": this.SelectedPneuArr.marque + ' ' + this.SelectedPneuArr.modele + ' ' + this.SelectedPneuArr.HBVREAR + '/' + this.SelectedPneuArr.widthRear + 'R' + this.SelectedPneuArr.diametre + ' ' + this.SelectedPneuArr.indiceDeChargeRear + ' ' + this.SelectedPneuArr?.indiceDeVitesse + ' ' + (this.SelectedPneuArr.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuArr.hiver === 1 ? 'Hiver' : this.SelectedPneuArr.ete === 1 ? 'Eté' : ' 4 saisons'),
                                },
                            }

                            this.SelectedAccessoires.forEach((accessoire, index) => {
                                if (accessoire !== null) {
                                    Object.assign(products, {
                                        [counter]: {
                                            "id_product": index,
                                            "name_product": accessoire.data.name
                                        }
                                    });
                                    counter++
                                }
                            })

                            this.avisVerifCommande = {
                                "idWebsite": process.env.VUE_APP_AVIS_VERIFIE_CLIENT_ID,
                                "message": {
                                    "query": "pushCommandeSHA1",
                                    "order_ref": this.refChecking,
                                    "email": infoUser.mail,
                                    "lastname": infoUser.nom,
                                    "firstname": infoUser.prenom,
                                    "order_date": this.formatDate(),
                                    "PRODUCTS": products,
                                    "website_id" : process.env.VUE_APP_SITE_ID,
                                }
                            }
                        }

                        let cryptedAvisVerifCommande = CryptoJS.AES.encrypt(JSON.stringify(this.avisVerifCommande), process.env.VUE_APP_SECRET_KEY).toString()


                        Vue.axios.post('https://api.espace-jantes.com/avisVerif', {
                            commande: cryptedAvisVerifCommande,
                        }).then(() => {
                            // console.log('resp', resp)
                            this.removeOngletData('all')
                            this.$store.commit('loader/setLoader', false)
                        }).catch((err) => {
                            console.log('err', err)
                        })


                    })
                    .catch(error => console.log(error))

            }, 2500);




    },
    methods:{
        encryptWithKey(plaintext, key){
            var encrypted = CryptoJS.AES.encrypt(plaintext, key, { mode: CryptoJS.mode.ECB });
            console.log("Ciphertext (Base64):\n" + encrypted.toString());        // Ciphertext
            var decrypted = CryptoJS.AES.decrypt(encrypted.toString(), key, { mode: CryptoJS.mode.ECB });
            console.log("Decrypted:\n" + decrypted.toString(CryptoJS.enc.Utf8)); // Plaintext
        },
        getOnglet(value){
            switch(value){
                case'jantesAv':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv
                case 'jantesArr':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr
                case 'pneusAv':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv
                case 'pneusArr':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr
                case 'accessoires':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').accessoires
                case 'totalPriceAccessoires':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').totalPrice
                case 'modelePossibleAuCarre':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre')
                case 'modelePossibleFront':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront')
                case 'modelePossibleRear':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear')
                case 'reference':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__reference')

            }
        },
        removeOngletData(value){

            var lastUpdated = ls.get('last_updated')

            switch(value){
                case"jantes":
                    ls.remove(lastUpdated + '__infosJantes')
                    ls.remove(lastUpdated + '__infosPneus')
                    ls.remove(lastUpdated + '__infosAccessoires')

                    this.triggerUpdate ++
                    break
                case"pneus":
                    ls.remove(lastUpdated + '__infosPneus')

                    this.triggerUpdate ++
                    break
                case"accessoires":
                    ls.remove(lastUpdated + '__infosAccessoires')
                    this.triggerUpdate ++
                    break
                case"all":
                    ls.remove(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes')
                    ls.remove(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus')
                    ls.remove(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires')
                    ls.remove(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre')
                    ls.remove(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront')
                    ls.remove(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear')
                    ls.remove(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__reference')
                    this.$store.commit('nbrCart/setNbrCart', 0)
                    break
            }
        },
        formatDate: function() {
            let d = new Date();
            let month = '' + (d.getMonth() + 1);
            let day = '' + d.getDate();
            let year = d.getFullYear();
            let hour = ' ' + d.getHours();
            let min = d.getMinutes();
            let sec = d.getSeconds();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            if (hour.length < 3) // hour.length < 3 because there is a space
                hour = '0' + hour;
            if (min < 10)
                min = '0' + min;
            if (sec < 10)
                sec = '0' + sec;

            return [year, month, day].join('-') + [hour, min, sec].join(':');
        },
    },
    computed:{
        ...mapState({
            getUserId: state => state.auth.userId,
            AddrFact : state => state.addr_commande.addr_commande_facturation,
            AddrLivr : state => state.addr_commande.addr_commande_livraison,
            notesCommande : state => state.addr_commande.notesCommande,
            infoValves : state => state.infoVehicule.infoValves,
            Vehicule : state => state.infoVehicule.Vehicule,
            isProfessional: state => state.auth.isRevendeur,
            // refChecking: state => state.refChecking.ref,
            typePaiement: state => state.typePayement.typePayement,
            loaderStatus: state => state.loader.isLoader,
            infoCommande : state => state.infoCommande.infoCommande,
        })
    }
}
</script>

<style scoped>

</style>
