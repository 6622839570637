<template>

    <v-app fluid >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>

        <v-main v-if="VUE_APP_SITE_ID === '1'">
            <v-col class="py-0" >
                <v-row>
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <p class="text-xl-h2 text-lg-h3 tc_blue font-weight-bold rotate">
                            Qui sommes nous ?
                        </p>
                    </v-col>
                    <v-col lg="5" >
                        <p v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5  tc_blue font-weight-bold ">
                            Qui sommes nous ?
                        </p>
                        <v-content class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                            <p>La société Espace-jantes est spécialisée dans la vente sur internet de jantes et pneumatiques.
                                Société 100% française basée à Pertuis dans le Vaucluse
                            </p>
                            <p class=" mb-3 pl-4 border_left tc_turquoise headline font-weight-bold">Nous vous proposons la livraison partout en Europe ainsi que dans les DOM TOM.</p>
                            <p>- Nous vous livrons à votre convenance soit à votre domicile, soit sur votre lieu de travail ou dans un centre de montage.</p>
                            <p>- Notre équipe commerciale est à votre disposition pour vous guider dans votre choix de pneumatiques à prix discount et jantes pas chères</p>
                            <p>- Notre site est actualisé quotidiennement</p>
                            <p>Espace-Jantes est distributeur de la plupart des grandes marques de jantes suivantes :</p>
                            <p>WSP ITALY / RACER WHEELS /  OZ RACING / MAK / INFINY / SPARCO WHEELS / GULF / 24 HEURES DU MANS
                                / ALUTEC / RONAL / ATS / AUTEC / ACR/ BEYERN/ BLACK RHINO / COVENTRY / CRAY / EVYCE/ F1R
                                / GMP ITALIA / JAPAN RACING / LUMARAI / MANDRUS / MSW / PETROL / RADI8 / REDBOURNE / REGEN5 / TSW / VICTOR EQUIPMENT / </p>
                        </v-content>
                </v-col>
                    <v-col lg="5" class="bc_blue" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-img src="@/assets/AboutUs.png"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phone.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold tc_blue align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="3" xl="3" class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2" offset-lg="1" xl="3" lg="3">
                        <v-img style="position: absolute; width: 20%;" src="@/assets/illustration-man.png"/>
                    </v-col>
                </v-row>
            </v-container>

            <v-col  class="bc_white tc_black" >
                <v-row style="margin-top: 5%">
                    <v-col v-if="$vuetify.breakpoint.lgAndUp" offset-lg="1" lg="4">
                        <v-img src="@/assets/img_pneus.png"/>
                    </v-col>
                    <v-col lg="5">
                        <v-content class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                            <span class="tc_darkBlue">N’hésitez pas à nous contacter</span>
                            <p>du lundi au samedi de 9h à 19h non-stop</p>
                            <p>La société Espace-jantes est dotée d’un service qualité qui contrôle chaque commande avant son expédition.</p>
                            <p class="border_left pl-3 tc_turquoise font-weight-bold headline">Professionnalisme, sérieux, fiabilité</p>
                            <p>Notre objectif est de gagner et garder la confiance de nos clients.
                                Notre professionnalisme est associé à la passion de notre métier.</p>
                            <p class="tc_darkBlue">Espace-jantes est votre société de vente en ligne de jantes pas chères et pneumatiques discount – haut de gamme et garantie.</p>
                        </v-content>
                    </v-col>
                </v-row>
            </v-col>

            <v-col cols="12" v-if="$vuetify.breakpoint.lgAndUp" class="pa-0 bc_blue tc_white">
                <v-row>
                    <v-col lg="9" class="bc_white"/>
                    <v-col lg="3" style="align-items: center">
                        <v-col offset-lg="3">
                            <span>SARL, capital social : 10 000 €</span>
                            <p>RCS d’ Avignon : 530 437 375</p>
                            <span>23 Rue Benjamin Franklin</span>
                            <br>
                            <span>84120 Pertuis</span>
                        </v-col>
                    </v-col>
                </v-row>
            </v-col>

            <v-col v-if="$vuetify.breakpoint.mdAndDown" class="pa-0 tc_white bc_blue">
                <v-col  style="align-items: center">
                    <v-col >
                        <span>SARL, capital social : 10 000 €</span>
                        <p>RCS d’ Avignon : 530 437 375</p>
                        <span>23 Rue Benjamin Franklin</span>
                        <br>
                        <span>84120 Pertuis</span>
                    </v-col>
                </v-col>
        </v-col>
        </v-main>

        <v-main v-if="VUE_APP_SITE_ID === '2'">
            <v-col class="py-0" >
                <v-row>
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <p class="text-xl-h2 text-lg-h3  font-weight-bold rotate">
                            Qui sommes nous ?
                        </p>
                    </v-col>
                    <v-col lg="5" >
                        <p v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5   font-weight-bold ">
                            Qui sommes nous ?
                        </p>
                        <v-content class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption
                                        pb-12">
                            <p>
                                Notre cœur de métier : le conseil et la vente en ligne de jantes de véhicules toutes marques.
                            </p>
                            <p>
                                Nous proposons, sur notre site internet, une gamme exhaustive de jantes et de pneus adaptés à l’ensemble des marques de véhicules du marché.
                            </p>
                            <p>
                                Une société made in France : le siège social de Distri Jantes est implanté à Aix-en-Provence dans les Bouches-du-Rhône.
                            </p>
                            <p>
                                Nous livrons partout en France ainsi qu’en Europe et dans les DOM TOM en laissant le choix de l’adresse de livraison : domicile, lieu de travail, centre de montage.
                            </p>
                            <p class="pb-10">
                                Distri Jantes travaille avec la quasi-totalité des marques de Jantes du marché mondial telles que :<b> Japan Racing, Mak, GMP, Axe, ACR, Dezent, Wheelworld, BBS, Monaco, Inter-Action, Acier, Motec, Platin, Rial, Tomason… </b> et bien d’autres !
                            </p>
                        </v-content>
                </v-col>
                    <v-col lg="5" class="pa-0" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-img src="@/assets/AboutUsDJ.png"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phoneDJ.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33974191801">09 74 19 18 01</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="5" offset-lg="1"  class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>

                </v-row>
            </v-container>

            <v-col class="bc_white tc_black" >
                <v-row class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col offset-lg="1" lg="10">
                        <br>
                        <br>
                        <p class="font-weight-bold
                                   text-xl-h5
                                   text-lg-h6
                                   text-md-subtitle-1
                                   text-sm-subtitle-1
                                   text-subtitle-2">Les engagements de Distri Jantes</p>
                        <br>
                        <p>
                            L’équipe Distri Jantes vous accompagne, de A à Z, dans le choix et l’achat de jantes et s’engage sur une qualité de services 0 défaut :

                        </p>
                        <p>
                            - Disponibilité et réactivité : <br>
                            Nous répondons à toutes vos questions au 09 74 19 18 01 ou par mail dans les 72 heures maximum (hors we et jours fériés).

                        </p>
                        <p>
                            - Conseils :<br>
                            Notre expertise, notre maîtrise technique de la jante et notre connaissance du marché automobile nous permettent de vous conseiller en tout point dans votre choix.
                        </p>
                        <p>
                            - Transport et livraison : <br>
                            Quelle que soit l’adresse de livraison (professionnelle, maison, centre de montage), nous confions le port et la livraison de vos jantes à des sociétés de transport réputées.
                        </p>
                        <p>
                            - Actualisation du site : <br>
                            Nous nous engageons à mettre à jour le site Distri Jantes pour vous offrir des informations valides et vous proposer les dernières innovations dans toutes les marques !

                        </p>
                        <p>
                            - Choix de jantes de qualité au meilleur prix : <br>
                            Nous mettons un point d’honneur à rechercher des jantes haut de gamme au meilleur prix et à vous faire bénéficier de vraies promotions exceptionnelles !

                        </p>
                        <p>
                            - Qualité et garantie : <br>
                            Les jantes que nous distribuons font l’objet d’un contrôle de qualité et sont garanties.

                        </p>

                        <p>
                           - Paiement sécurisé
                        </p>
                    </v-col>
                </v-row>
            </v-col>
        </v-main>

        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
    import HomepageHeaderEj from "../headers/HomepageHeaderEj";
    import HomepageHeaderDj from "../headers/HomepageHeaderDj";
    import HomepageFooterEj from "../footer/HomepageFooterEj";
    import HomepageFooterDj from "../footer/HomepageFooterDj";

    export default {
        name: "AboutUs",
        components:{
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,

        },
        data: () => ({
            VUE_APP_SITE_ID:null
        }),
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        },
        metaInfo() {
            return {
                title: `Qui sommes-nous ? - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + `  - Jantes, Pneus & Accessoires`,
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `Qui sommes-nous ? - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {name: 'twitter:description', content: `Qui sommes-nous ? ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes` : `Distri Jantes`) + `, le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.`},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `Qui sommes-nous ? -` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {property: 'og:site_name', content: this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`},
                    {property: 'og:type', content: 'Website'},
                    {property: 'og:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},
                    {property: 'og:description', content: `Qui sommes-nous ? ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    {name: 'robots', content: 'index,follow'}
                ]
            }
        },
    }
</script>

<style scoped>
    .border_left{
        border-left: 2px solid #1DE4AF
    }
    .rotate {
        transform: rotate(-90deg);
        margin-left: -4%;
        margin-top: 12%;
        position: absolute;
    }
</style>
