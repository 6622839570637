<template>
<!--    <div id="app" data-server-rendered="true">-->
    <v-app  fluid>

        <v-main class="" style="height: 25vh">
            <v-col offset-xl="2" offset-lg="2" offset-md="2" xl="8" lg="8" md="8" cols="12" class="py-0">
                <v-row>
                    <v-col class="py-0">
                        <HomepageHeader style="position: relative;z-index: 3"/>
                    </v-col>
                </v-row>
            </v-col>
            <v-carousel v-if="$vuetify.breakpoint.mdAndUp"
                    show-arrows-on-hover
                    hide-delimiters
                    class="carouselMedia"
                    style="position: absolute; top: 0;"
                    height=""
        >
            <v-carousel-item
                class="contain"
                v-for="(item,i) in sliderNew"
                :key="i"
                :src="item"
            ></v-carousel-item>
        </v-carousel>
            <v-carousel v-if="$vuetify.breakpoint.smAndDown"
                        height=""
                        cycle
                        style="background-color: white "
                        hide-delimiters
                        show-arrows-on-hover
            >
                <v-carousel-item
                    v-for="(item,i) in sliderNewSmall"
                    :key="i"
                    :src="item"
                ></v-carousel-item>
            </v-carousel>
        </v-main>

        <v-col>
            <v-row>

                <v-col offset-xl="2" offset-lg="2" offset-md="2" offset="1" xl="8" lg="8" md="8" cols="10" class="py-0" :style="[ $vuetify.breakpoint.smAndDown ? { 'margin-top': '60vw'}: '']">

                <ConfigurateurHomeDj style="margin-top: 30%"/>
                  <v-row>
                    <v-col cols="8">
                      <p style="opacity: 0.07;" class="font-weight-bold text-xl-h1 text-lg-h1 text-md-h2 text-sm-h2 text-h2 mt-12">NOS MARQUES</p>
                      <p class="tc_dark display-1 font-weight-bold">de jantes et pneus</p>
                    </v-col>
                    <v-col cols="4" style="position: relative" v-if="$vuetify.breakpoint.smAndUp">
                      <v-img src="@/assets/jante_home.png" style="max-width: 80%;position:absolute;margin-top: 10%;"/>
                    </v-col>
                  </v-row>
                </v-col>
        <!--        <v-col cols="2"></v-col>-->
            </v-row>
            <v-row>
                <v-col cols="12"  class="bc_orange pa-0">
                    <v-col offset-xl="2" offset-lg="2" offset-md="2" offset="1" xl="8" lg="8" md="8" cols="10">

                        <v-sheet
                            max-width="800"
                        >
                            <v-slide-group
                                v-model="model"
                                class="pa-4 bc_orange"
                                prev-icon="mdi-arrow-left-circle"
                                next-icon="mdi-arrow-right-circle"
                                show-arrows
                            >
                                <v-slide-item
                                    v-for="(logo,index) in carousel"
                                    :key="logo + index"
                                    v-slot="{ active, toggle }"
                                >
                                    <v-card
                                        class="ma-4"
                                        width="100"
                                        @click="toggle"
                                        style="height: 30%;box-shadow: none; background-color: #FC5C1F"
                                    >
        <!--                                <v-row-->
        <!--                                    class="fill-height"-->
        <!--                                    align="center"-->
        <!--                                    justify="center"-->
        <!--                                >-->
        <!--                                    {{n.name}}-->
    <!--                                        <v-img aspect-ratio="1" contain :src="logo" style="mix-blend-mode: lighten;" class="carousel"/>-->
                                            <v-img aspect-ratio="1" contain :src="logo" style="mix-blend-mode: lighten;" class="carousel"/>
        <!--                                </v-row>-->
                                    </v-card>
                                </v-slide-item>
                            </v-slide-group>
                        </v-sheet>
                    </v-col>

                </v-col>
            </v-row>
            <v-row class="" style="padding: 5% 0 0;">
                <v-col  offset="2" cols="8">
    <iframe v-if="$vuetify.breakpoint.mdAndUp" id='AV_widget_iframe' frameBorder="0" width="100%" height="100%" src="//cl.avis-verifies.com/fr/cache/f/8/9/f8939ceb-3d4d-8824-5de9-a47d7b7baeac/widget4/f8939ceb-3d4d-8824-5de9-a47d7b7baeachorizontal_index.html"></iframe>
    <iframe v-if="$vuetify.breakpoint.smAndDown" id='AV_widget_iframe' frameBorder="0" width="300" height="400" src="//cl.avis-verifies.com/fr/cache/f/8/9/f8939ceb-3d4d-8824-5de9-a47d7b7baeac/widget4/f8939ceb-3d4d-8824-5de9-a47d7b7baeacindex.html"></iframe>
                </v-col>
            </v-row>
            
            <v-row>
                <v-col offset-xl="2" offset-lg="2" offset-md="2" xl="8" lg="8" md="8" cols="12" style="margin-top: 5%" class="px-0">

                  <v-row v-if="$vuetify.breakpoint.mdAndUp">
                    <v-col cols="6" class="pa-0 bc_darker" style="z-index: 3;" >
                      <v-img src="@/assets/img-jante-home.png" style="height: 100%"/>
                    </v-col>
                    <v-col cols="6" class="pa-8 bc_darker"  :style="{ 'background': 'url(' + contactmontage + ') left center no-repeat' }"  >
                        <v-row style="margin-top: 10%; margin-bottom: 10%">
                            <v-col cols="12" class="tc_white text-end
                                                    text-xl-h4
                                                    text-lg-h5
                                                    text-md-h5
                                                    text-sm-h5
                                                    text-subtitle-1">
                                <a class="no_deco font-weight-bold text-lg-h2" href="tel:+33974191801">09 74 19 18 01</a>
                                <p class="mt-5 mb-0">Commandez par téléphone</p>
                                <p class="font-weight-bold">Nous vous accompagnons ! </p>
                            </v-col>
                        </v-row>
                        <v-row style="margin-top: 20%; margin-bottom: 10%">
                            <v-col cols="12" class="tc_white text-end
                                                    text-xl-h4
                                                    text-lg-h5
                                                    text-md-h5
                                                    text-sm-h5
                                                    text-subtitle-1">
                                <p class="font-weight-bold text-lg-h2">Montage offert</p>
                                <p class="mb-0">pack complet,</p>
                                <p class="font-weight-bold">prêt à rouler !</p>
                            </v-col>
                        </v-row>


                    </v-col>
                  </v-row>


                  <v-main v-if="$vuetify.breakpoint.smAndDown" class="pa-0">
                    <v-col cols="12" class="pa-0 bc_darker" style="z-index: 3">
                      <v-img src="@/assets/img-jante-home.png"/>
                    </v-col>
                    <v-col cols="12" class=" bc_darker" >
                        <v-row>
                            <v-col cols="12" class="pa-8 bc_darker"  :style="{ 'background': 'url(' + contactmontage + ') left center no-repeat' }"  >
                                <v-row style="margin-top: 10%; margin-bottom: 10%">
                                    <v-col cols="12" class="tc_white text-end
                                                    text-xl-h4
                                                    text-lg-h5
                                                    text-md-h5
                                                    text-sm-h5
                                                    text-subtitle-1">
                                        <a class="no_deco font-weight-bold text-h3" href="tel:+33974191801">09 74 19 18 01</a>
                                        <p class="mt-5 mb-0">Commandez par téléphone</p>
                                        <p class="font-weight-bold">Nous vous accompagnons ! </p>
                                    </v-col>
                                </v-row>
                                <v-row style="margin-top: 20%; margin-bottom: 10%">
                                    <v-col cols="12" class="tc_white text-end
                                                    text-xl-h4
                                                    text-lg-h5
                                                    text-md-h5
                                                    text-sm-h5
                                                    text-subtitle-1">
                                        <p class="font-weight-bold text-h3">Montage offert</p>
                                        <p class="mb-0">pack complet,</p>
                                        <p class="font-weight-bold">prêt à rouler !</p>
                                    </v-col>
                                </v-row>


                            </v-col>

                        </v-row>
                    </v-col>
                  </v-main>

    <!--                <v-row v-if="$vuetify.breakpoint.mdAndUp" class="mt-12 mb-6 align-center">-->
                    <v-col class="mt-12 mb-6 d-flex align-center pa-3">
                          <span style="opacity: 0.07; float: left" class="font-weight-bold  " :class="{'display-4' :$vuetify.breakpoint.mdAndUp, 'display-5': $vuetify.breakpoint.smAndDown}">LES +</span>
                        <v-col lg="6" sm="4" cols="7">
                          <v-img src="@/assets/logo/dj-logo.svg"/>
                        </v-col>
                    </v-col>

    <!--                <v-col v-if="$vuetify.breakpoint.smAndDown">-->
    <!--                      <span style="opacity: 0.07;" class="font-weight-bold display-4">LES +</span>-->
    <!--                    <v-col cols="4">-->
    <!--                      <v-img src="@/assets/logo/dj-logo.svg"/>-->
    <!--                    </v-col>-->
    <!--                </v-col>-->

    <!--              <v-col style=" width: 100vw; height: 100vh;" :style="[$vuetify.breakpoint.mdAndUp ? {'background': '0 no-repeat  url(' + background_les_plus.img + ') / cover '} :  {'background': '0 center no-repeat url(' + background_les_plus.img + ') / cover'}]" >-->
                  <v-col style=" width: 100vw; height: 100vh;" :style="{'background': ' center no-repeat  url(' + background_les_plus.img + ')', 'background-size' : 'cover'}" >
                    <v-row class="">

                      <v-col xl="3" lg="3" md="4" sm="4" cols="6" >
                        <v-card height="100%" class="py-8 text-center text-lg-left text-xl-left" style="opacity: 0.95">

                          <v-row class="px-3">
                            <v-col xl="2" lg="2" md="2" cols="12" class="">
                              <v-icon large>mdi mdi-card-bulleted-outline</v-icon>
                            </v-col>
                            <v-col class="pa-0" >
                              <v-card-title style="word-break: break-word;display: inherit;line-height: 1em" class="pa-3 font-weight-bold">Facilités de paiement </v-card-title>
                              <v-card-text style="word-break: break-word">En 4x par CB, <br> sans justificatifs <br> Accord immédiat!</v-card-text>
                            </v-col>
                          </v-row>

                        </v-card>
                      </v-col>

                        <v-col xl="3" lg="3" md="4" sm="4" cols="6" >
                        <v-card height="100%" class="py-8 text-center text-lg-left text-xl-left " style="opacity: 0.95">

                          <v-row class="px-3 ">
                              <v-col xl="2" lg="2" md="2" cols="12" class="">
                              <v-icon large>mdi mdi-truck-delivery-outline</v-icon>
                            </v-col>
                            <v-col class="pa-0 " >
                              <v-card-title style="display: inherit;line-height: 1em" class="font-weight-bold">Livraison <br> gratuite </v-card-title>
                              <v-card-text style="word-break: break-word" class="">Avec suivi des <br> commandes </v-card-text>
                            </v-col>
                          </v-row>

                        </v-card>
                      </v-col>

                    </v-row>

                    <v-row class="">

                    <v-col offset-xl="3" offset-lg="3" offset-md="4" offset-sm="4" xl="3" lg="3" md="4" sm="4" cols="6" >
                      <v-card height="100%" class="py-8 text-center text-lg-left text-xl-left" style="opacity: 0.95">

                        <v-row class="px-3">
                            <v-col xl="2" lg="2" md="2" cols="12" class="">
                            <v-icon large>mdi mdi-emoticon-happy-outline</v-icon>
                          </v-col>
                          <v-col class="pa-0" >
                            <v-card-title style="word-break: break-word;display: inherit;line-height: 1em" class="pa-3 font-weight-bold">Satisfait ou remboursé </v-card-title>
                            <v-card-text style="word-break: break-word">Délai de 14 jours de rétractation </v-card-text>
                          </v-col>
                        </v-row>

                      </v-card>
                    </v-col>

                        <v-col xl="3" lg="3" md="4" sm="4" cols="6" >
                      <v-card height="100%" class="py-8  text-center text-lg-left text-xl-left" style="opacity: 0.95">

                        <v-row class="px-3">
                            <v-col xl="2" lg="2" md="2" cols="12">
                            <v-icon large>mdi mdi-shield-check-outline</v-icon>
                          </v-col>
                          <v-col class="pa-0" >
                            <v-card-title style="word-break: break-word;display: inherit;line-height: 1em" class="pa-3 font-weight-bold">Jantes garanties et controlées</v-card-title>
                            <v-card-text style="word-break: break-word">2 ans</v-card-text>
                          </v-col>
                        </v-row>

                      </v-card>
                    </v-col>

                  </v-row>
                  </v-col>



                </v-col>
            </v-row>


        </v-col>
          <HomepageFooter/>

<!--      </v-row>-->

    </v-app>
<!--    </div>-->
</template>

<script>


// eslint-disable-next-line no-unused-vars
    import ConfigurateurHomeDj from '@/components/configurateur/ConfigurateurHomeDj'
    import HomepageHeader from '@/components/headers/HomepageHeaderDj'
    import HomepageFooter from '@/components/footer/HomepageFooterDj'


    import michelin from "@/assets/logo/michelin_white.svg"
    import pirelli from "@/assets/logo/pirelli_white.svg"
    import ozRacing from "@/assets/logo/ozRacing_white.svg"
    import hankook from "@/assets/logo/hankook_white.svg"
    import bridgestone from "@/assets/logo/bridgestone_white.svg"
    import continental from "@/assets/logo/continental_white.svg"
    import dunlop from "@/assets/logo/dunlop_white.svg"
    import falken from "@/assets/logo/falken_white.svg"
    import gmp from "@/assets/logo/gmp_white.svg"
    import goodyear from "@/assets/logo/goodyear_white.svg"
    import nokian from "@/assets/logo/nokiantyres_white.svg"
    import uniroyal from "@/assets/logo/uniroyal_white.svg"


    import background_les_plus from "@/assets/les-plus-dj-background.png"

    import slider_1 from '@/assets/caroussel/slider1.png'
    import slider_2 from '@/assets/caroussel/slider2.png'
    import slider_3 from '@/assets/caroussel/slider3.png'
    import slider_4 from '@/assets/caroussel/slider4.png'
    import slider_5 from '@/assets/caroussel/slider5.png'
    import slider_6 from '@/assets/caroussel/slider6.png'
    import slider_montagne from '@/assets/caroussel/Slider_montagne-DJ.png'


    import slide1Court from '@/assets/caroussel/slide1_court.png'
    import slide2Court from '@/assets/caroussel/slide2_court.png'
    import slide1Long from '@/assets/caroussel/slide1_long.png'
    import slide2Long from '@/assets/caroussel/slide2_long.png'
    import slide3Court from '@/assets/caroussel/slide3_court.png'
    import slide3Long from '@/assets/caroussel/slide3_long.jpg'
    import caroussel_montagne from '@/assets/caroussel/Slider_montagne_mobile-DJ.png'

    import contactmontage from '@/assets/contact-montage.svg'

    import $ from 'jquery'

    export default {
        name: "HomepageDj",

        components:{
            // eslint-disable-next-line vue/no-unused-components
            ConfigurateurHomeDj,
            // eslint-disable-next-line vue/no-unused-components
            HomepageHeader,
            // eslint-disable-next-line vue/no-unused-components
            HomepageFooter

        },
        data: () => ({
            model: null,
            carousel:[michelin, pirelli, ozRacing, hankook, continental, bridgestone, dunlop, falken, gmp, goodyear, nokian, uniroyal],
            background_les_plus:{img:background_les_plus},
            slider:[
                slider_1,
                slider_2,
                slider_3,
                slider_4,
                slider_5,
                slider_6,
            ],
            sliderNew:[
                slider_montagne,
                slide1Long,
                slide2Long,
                slide3Long,
            ],
            sliderNewSmall:[
                caroussel_montagne,
                slide1Court,
                slide2Court,
                slide3Court
            ],
            contactmontage : contactmontage
        }),
        mounted() {
            if( process.env.NODE_ENV.trim() !== "development") {
                window.dataLayer.push({
                    'event': 'Pageview',
                    'pagePath': '/',
                    'pageTitle': 'Homepage'
                });
            }
            let jqueryScript = document.createElement('script');
            jqueryScript.setAttribute('src', 'https://code.jquery.com/jquery-3.5.1.min.js');
            document.head.appendChild(jqueryScript)

            let widgetScript = document.createElement('script');
            widgetScript.setAttribute('src', '//cl.avis-verifies.com/fr/widget4/widget20_FB3_nojquery.min.js');
            document.head.appendChild(widgetScript)

            if(this.$route.name === 'Home'){
                this.nextSlide()
            }
        },
        methods: {
            nextSlide: function (){
                setInterval(function() {
                    $(".v-window__next").children().trigger("click");

                }, 8000);
            }
        }
    }
</script>

<style scoped>

    .card-xl{
        max-width:300px;
        min-height:350px
    }
    .card-lg{
        max-width:270px;
        min-height:320px
    }
</style>

<style>
    html {
        overflow-x: hidden !important;
    }
    /*.carousel div.v-image__image.v-image__image--cover {*/
    /*    background-blend-mode: lighten;*/
    /*    background-color: #FC5C1F;*/
    /*}*/

    /*.v-image .v-responsive .v-carousel__item .theme--dark{*/
    /*    height: 400px!important;*/
    /*}*/
</style>
