

const state = () =>({
    SelectedJanteAv:'',
    SelectedJanteArr:'',
    promoUsed:false,
    monteDecaJante:false,
    monteStateDeca:false
})



const mutations = {
    setJante(state, config){

            if (config.Av) {
                state.SelectedJanteAv = config.Av
            }
            if (config.Arr) {
                state.SelectedJanteArr = config.Arr
            }
            if (config.promoUsed) {
                state.promoUsed = config.promoUsed
            }

    },
    setMonteDeca(state, config){
        state.monteDecaJante = config
    },

    setMonteStateDeca(state, config){
        state.monteStateDeca = config
    },


    deleteJante: state => {
        state.SelectedJanteAv = ''
        state.SelectedJanteArr = ''
        state.promoUsed = false
        state.monteDecaJante = false
    },

    resetCodePromo: state =>{
        state.promoUsed = false
    }

}


const actions = {

}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}