<template>

    <v-app  fluid >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>

        <v-col class=" font-weight-regular
                        text-xl-h5
                        text-lg-body-1
                        text-md-caption
                        text-sm-caption
                        text-caption">
            <v-col offset-lg="1" lg="10" >
                <v-card @click="article1 = !article1" >
                    <v-row    >
                        <v-col offset-lg="1" lg="10" cols="10">
                            <v-card-text>
                                <span  class="font-weight-bold
                                            text-xl-h5
                                            text-lg-subtitle-1
                                            text-md-subtitle-1
                                            text-sm-subtitle-1
                                            text-subtitle-2" :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">PRIX ET PRODUITS</span>
                            </v-card-text>
                        </v-col>
                        <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                            <v-icon v-if="!article1" x-large class="pr-4 " >mdi mdi-chevron-down</v-icon>
                            <v-icon v-else x-large class="pr-4 " >mdi mdi-chevron-up</v-icon>
                        </v-col>
                    </v-row>
                    <v-col v-if="article1" class="border-top">
                        <v-row>
                            <v-col lg="11" class="tc_grey pt-0" v-for="(faq, index) in prixEtProduits" :key="index + '1'">
                                <p class="font-weight-bold subtitle-1">{{ faq.question }}</p>
                                <p>{{ faq.reponse}}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-card>
            </v-col>
            <v-col offset-lg="1" lg="10">
                <v-card >
                    <v-row  @click="article2 = !article2" >
                        <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                            <v-card-text>
                                <span  class="font-weight-bold
                                            text-xl-h5
                                            text-lg-subtitle-1
                                            text-md-subtitle-1
                                            text-sm-subtitle-1
                                            text-subtitle-2" :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">QUESTIONS TECHNIQUES</span>
                            </v-card-text>
                        </v-col>
                        <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                            <v-icon v-if="!article2" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                            <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                        </v-col>
                    </v-row>
                    <v-col v-if="article2" class="border-top">
                        <v-row>
                            <v-col lg="11" class="tc_grey pt-0" v-for="(faq, index) in questionTechniques" :key="index + '2'">
                                <p class="font-weight-bold subtitle-1">{{ faq.question }}</p>
                                <p>{{ faq.reponse}}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-card>
            </v-col>
            <v-col offset-lg="1" lg="10">
                <v-card >
                    <v-row  @click="article3 = !article3" >
                        <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                            <v-card-text>
                                <span  class="font-weight-bold
                                            text-xl-h5
                                            text-lg-subtitle-1
                                            text-md-subtitle-1
                                            text-sm-subtitle-1
                                            text-subtitle-2" :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">MODES DE PAIEMENT</span>
                            </v-card-text>
                        </v-col>
                        <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                            <v-icon v-if="!article3" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                            <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                        </v-col>
                    </v-row>
                    <v-col v-if="article3" class="border-top">
                        <v-row>
                            <v-col lg="11" class="tc_grey pt-0" v-for="(faq, index) in modesDePaiement" :key="index + '3'">
                                <p class="font-weight-bold subtitle-1">{{ faq.question }}</p>
                                <p>{{ faq.reponse}}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-card>
            </v-col>
            <v-col offset-lg="1" lg="10">
                <v-card >
                    <v-row  @click="article4 = !article4" >
                        <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                            <v-card-text>
                                <span  class="font-weight-bold
                                            text-xl-h5
                                            text-lg-subtitle-1
                                            text-md-subtitle-1
                                            text-sm-subtitle-1
                                            text-subtitle-2" :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">TRANSPORT ET LIVRAISON</span>
                            </v-card-text>
                        </v-col>
                        <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                            <v-icon v-if="!article4" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                            <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                        </v-col>
                    </v-row>
                    <v-col v-if="article4" class="border-top">
                        <v-row>
                            <v-col lg="11" class="tc_grey pt-0" v-for="(faq, index) in transportEtLivraison" :key="index + '4'">
                                <p class="font-weight-bold subtitle-1">{{ faq.question }}</p>
                                <p>{{ faq.reponse}}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-card>
            </v-col>
            <v-col offset-lg="1" lg="10">
                <v-card >
                    <v-row  @click="article5 = !article5" >
                        <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                            <v-card-text>
                                <span  class="font-weight-bold
                                            text-xl-h5
                                            text-lg-subtitle-1
                                            text-md-subtitle-1
                                            text-sm-subtitle-1
                                            text-subtitle-2" :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">DROIT DE RETOUR</span>
                            </v-card-text>
                        </v-col>
                        <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                            <v-icon v-if="!article5" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                            <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                        </v-col>
                    </v-row>
                    <v-col v-if="article5" class="border-top">
                        <v-row>
                            <v-col lg="11" class="tc_grey pt-0" v-for="(faq, index) in droitDeRetour" :key="index + '5'">
                                <p class="font-weight-bold subtitle-1">{{ faq.question }}</p>
                                <p>{{ faq.reponse}}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-card>
            </v-col>
            <v-col offset-lg="1" lg="10">
                <v-card >
                    <v-row  @click="article6 = !article6" >
                        <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                            <v-card-text>
                                <span  class="font-weight-bold
                                            text-xl-h5
                                            text-lg-subtitle-1
                                            text-md-subtitle-1
                                            text-sm-subtitle-1
                                            text-subtitle-2" :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">QUESTIONS TECHNIQUES</span>
                            </v-card-text>
                        </v-col>
                        <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                            <v-icon v-if="!article6" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                            <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                        </v-col>
                    </v-row>
                    <v-col v-if="article6" class="border-top">
                        <v-row>
                            <v-col lg="11" class="tc_grey pt-0" v-for="(faq, index) in QuestionsTechnique" :key="index + '6'">
                                <p class="font-weight-bold subtitle-1">{{ faq.question }}</p>
                                <p>{{ faq.reponse}}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-card>
            </v-col>


        </v-col>



        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>
<script>
import HomepageHeaderEj from "../headers/HomepageHeaderEj";
import HomepageHeaderDj from "../headers/HomepageHeaderDj";
import HomepageFooterEj from "../footer/HomepageFooterEj";
import HomepageFooterDj from "../footer/HomepageFooterDj";

    export default {
        name: "FAQ",
        components:{
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,

        },
        data: () => ({
            selectedFaq:[],
            prixEtProduits:
                [
                    {
                        "question":"Les prix affichés dans la boutique en ligne se réfèrent-ils à une roue ou à un jeu de 4 roues ?",
                        "reponse": "Tous les prix affichés dans notre boutique en ligne se rapportent à des jeux de 4 pièces (que ce soient des jantes ou des roues complètes). Pour les prix des roues complètes, tous les coûts (par exemples montage, équilibrage, accessoires) sont déjà inclus."
                    },
                    {
                        "question":"Quels types de pneus montez-vous sur les roues complètes ?",
                        "reponse": "Dans notre boutique en ligne, vous trouverez un grand nombre de marques et de tailles de pneus. Notre gamme s'étend des fabricants meilleur marché jusqu'aux plus renommés. Afin de sélectionner des roues complètes, vous n'avez qu'à cliquer sur le modèle de jante souhaité. Vous arrivez sur la description détaillée où vous pouvez configurer les pneus adaptés dans les catégories 'Prix discount', 'Best-seller' ou bien 'Top qualité'."
                    },
                    {
                        "question":"Quels accessoires sont-ils inclus dans la livraison ?",
                        "reponse": "Les accessoires pour le montage des roues (anneaux de centrage, boulons, écrous) sont compris dans le contenu de la livraison et dans le prix, à moins que l'utilisation des accessoires de séries soit imposée par le rapport d’expertise TÜV. Dans ce cas, il est indiqué explicitement sur la facture que les accessoires de montage d’origine doivent être utilisés. Vous avez la possibilité de commander d'autres accessoires tels que des masses d'équilibrage de couleur, des valves invisibles, des protections antivol ou différents produits d'entretien dans votre panier d'achat sous la rubrique « Accessoires »."
                    },
                    {
                        "question":"Quels sont les possibilités de rabais ?",
                        "reponse": "Nos prix sont fixes et non négociables. Des réductions supplémentaires ne sont pas accordées. En contrepartie, vous profitez d'un service optimal. Les pneus pour les roues complètes sont toujours récents. Le montage, l'équilibrage et le contrôle de qualité sont inclus dans le prix. Vous recevez ainsi vos roues complètes et prêtes à monter. De même, vous pouvez compter à 100 % sur notre service pour le traitement de votre commande et pour des réclamations éventuelles. Profitez des conditions avantageuses de nos offres spéciales printemps, été ou hiver."
                    },
                ],
            questionTechniques:
                [
                    {
                        "question":"Les jantes choisies sont-elles adaptées à mon véhicule ?",
                        "reponse": "Notre équipe de support clients vérifie pour toutes les commandes que les jantes ou roues complètes sont adaptées au véhicule indiqué. Si c'est le cas, vous recevrez la confirmation par e-mail. Une jante est convenable si votre véhicule est mentionné dans le rapport d'expertise TÜV d'homologation de cette jante. Le véhicule doit être en son état original de série. En cas de conditions d'utilisation à observer ou travaux de carrosserie à effectuer, vous serez informé par e-mail."
                    },
                    // {
                    //     "question":"Qu'est-ce qu'un rapport d'expertise TÜV?",
                    //     "reponse": "Une homologation est obligatoire pour pouvoir circuler avec des roues de seconde monte. Ces rapports d'expertise sont publiées par le TÜV. Ils confirment est sûr et techniquement compatible avec votre véhicule. Il existe 3 sortes de rapports d'expertise. " +
                    //         "ABE ohne Auflage A01 – Homologation allemande sans prérequis A01 " +
                    //         "Il s'agit d'une homologation générale ne nécessitant ni document ni autre démarche particulière. " +
                    //         "ABE mit Auflage A01 – Homologation allemande avec prérequis A01 " +
                    //         "En Allemagne, une présentation du véhicule aux services de contrôle technique est requise. L'expert délivre une attestation de la conformité (Anbaubestätigung) pour annexe aux papiers du véhicule. " +
                    //         "Teilegutachten – rapport d'expertise de pièces allemand " +
                    //         "En Allemagne, une présentation du véhicule aux services de contrôle technique est requise ainsi que l'inscription des jantes dans les papiers du véhicule. " +
                    //         "Vous recevez une information sur le type de certificat de vos jantes après le contrôle de votre commande par notre support clients. Veuillez noter que la législation dans votre pays peut être différente"
                    // },
                    {
                        "question":"Quels types de pneus montez-vous sur les roues complètes ?",
                        "reponse": "Dans notre boutique en ligne, vous trouverez un grand nombre de marques et de tailles de pneus. Notre gamme s'étend des fabricants meilleur marché jusqu'aux plus renommés. Afin de sélectionner des roues complètes, vous n'avez qu'à cliquer sur le modèle de jante souhaité. Vous arrivez sur la description détaillée où vous pouvez configurer les pneus adaptés dans les catégories 'Prix discount', 'Best-seller' ou bien 'Top qualité'."
                    },
                    {
                        "question":"Quels accessoires sont-ils inclus dans la livraison ?",
                        "reponse": "Les accessoires pour le montage des roues (anneaux de centrage, boulons, écrous) sont compris dans le contenu de la livraison et dans le prix, à moins que l'utilisation des accessoires de séries soit imposée par le rapport d’expertise TÜV. Dans ce cas, il est indiqué explicitement sur la facture que les accessoires de montage d’origine doivent être utilisés. Vous avez la possibilité de commander d'autres accessoires tels que des masses d'équilibrage de couleur, des valves invisibles, des protections antivol ou différents produits d'entretien dans votre panier d'achat sous la rubrique « Accessoires »."
                    },
                    {
                        "question":"Quels sont les possibilités de rabais ?",
                        "reponse": "Nos prix sont fixes et non négociables. Des réductions supplémentaires ne sont pas accordées. En contrepartie, vous profitez d'un service optimal. Les pneus pour les roues complètes sont toujours récents. Le montage, l'équilibrage et le contrôle de qualité sont inclus dans le prix. Vous recevez ainsi vos roues complètes et prêtes à monter. De même, vous pouvez compter à 100 % sur notre service pour le traitement de votre commande et pour des réclamations éventuelles. Profitez des conditions avantageuses de nos offres spéciales printemps, été ou hiver."
                    },
                ],
            modesDePaiement:
                [
                    {
                        "question":"Quels modes de paiement proposez-vous ?",
                        "reponse": "Nous pouvons proposer les modes de paiement suivants : Le paiement par virement bancaire, par PayPal, par carte de crédit ou carte bancaire ou 4XCB by cofidis."
                    },
                    // {
                    //     "question":"Comment fonctionne le financement ?",
                    //     "reponse": "Une demande de financement fonctionne très simplement. Vous commandez vos jantes normalement dans notre boutique en ligne, sélectionnez la méthode de paiement et envoyez votre commande. Ensuite, vous allez être connecté directement à la Commerzfinanz pour renseigner tous les détails concernant le contrat de financement. Vous serez informé immédiatement si votre demande de financement a été acceptée et sur les démarches nécessaires. Veuillez noter les conditions à remplir pour le financement : être en contrat de travail depuis au moins 3 mois, lieu de résidence et compte bancaire en Allemagne, pas d'entrée négative auprès des assurances de crédit. Pas de statut d'étudiant, apprentis, écolier ou retraité. Veuillez noter les conditions à remplir pour le financement: être en contrat de travail depuis au moins 3 mois, lieu de résidence et compte bancaire en Allemagne, pas d'entrée négative auprès des assurances de crédit. Pas de statut d'étudiant, apprentis, écolier ou retraité. Les versements sont prélevés par la banques. Nous sommes seulement les intermédiaires. Pour des informations plus détaillées, veuillez contacter Commerzfinanz au 0049203/34695301."
                    // },
                ],
            transportEtLivraison:
                [
                    {
                        "question":"Quel est le délai de livraison ?",
                        "reponse": "Étant donné que la plupart de nos produits sont en stock, une livraison est possible à partir de 2-4 jours ouvrables pour des jantes et de 4-7 jours ouvrables pour des roues complètes à compter de la date de paiement. Les indications sur les délais de livraison se rapportent à des livraisons en France."
                    },
                    {
                        "question":"Comment suivre l'acheminement de ma commande ?",
                        "reponse": "Vous recevrez un message d'information sur l'état d'avancement de votre commande que vous pouvez également consulter sur notre site en allant sous Mon compte. Dès l'expédition de votre commande, vous recevrez les numéros de suivi. À partir du moment où la réception de votre paiement aura été confirmée par notre comptabilité le délai de livraison est de 2-4 jours ouvrables jours ouvrables pour des jantes et de 4-7 jours ouvrables jours ouvrables pour des roues complètes."
                    },
                    // {
                    //     "question":"Livrez-vous dans d'autres pays ?",
                    //     "reponse": "Nous livrons dans tous les pays de la communauté européenne, la Suisse et la Norvège. Ici les liens vers nos sites locales:" +
                    //         "Allemagne: www.felgenoutlet.de" +
                    //         "Autriche: www.felgenoutlet.at" +
                    //         "Luxembourg: www.felgenoutlet.lu" +
                    //         "Royaume-Uni: www.wheeloutlet.co.uk" +
                    //         "Danemark: dk.wheeloutlet.com" +
                    //         "Suède: se.wheeloutlet.com" +
                    //         "Finlande: fi.wheeloutlet.com" +
                    //         "Belgique: www.foire-aux-jantes.be" +
                    //         "Espagne: www.tiendadellantas.es" +
                    //         "Tous les autres pays: www.felgenoutlet.com"
                    // },
                    {
                        "question":"Quel est le montant des frais de transport ?",
                        "reponse": "La livraison en France est gratuite."
                    },
                    {
                        "question":"Comment puis-je saisir une adresse de livraison différente ?",
                        "reponse": "Dans la dernière étape du processus de commande , vous pouvez saisir une adresse de livraison différente."
                    },
                    {
                        "question":"Est-ce que je peux retirer moi-même ma marchandise ?",
                        "reponse": "Pour des raisons logistiques, un retrait de marchandise dans nos locaux par les clients n'est pas possible. Nous sommes une société de ventes à distance sans magasin physique et nos produits sont stockés dans un centre logistique entièrement automatisé."
                    },
                ],
            droitDeRetour:
                [
                    {
                        "question":"Est-ce que je peux renvoyer les roues si elles ne me conviennent pas ?",
                        "reponse": "Si vous avez commandé des jantes sans pneus, vous pouvez les retourner dans un délai de 30 jours après réception de la marchandise. Conformément à nos conditions générales de vente, un retour de roues complètes est exclu."
                    },
                ],
            QuestionsTechnique:
                [
                    {
                        "question":"Est-ce que vous rachetez des jantes ?",
                        "reponse": "Nous n'achetons pas de produits d'occasion ou neufs de vendeurs privés. Nous achetons uniquement de la marchandise neuve directement en provenance de fabricants ou de distributeurs officiels."
                    },
                    {
                        "question":"Est-ce que vous vendez aussi des jantes d'occasion ?",
                        "reponse": "Vous ne trouverez dans notre gamme que des produits neufs que nous achetons directement des fabricants ou des distributeurs officiels. Nous ne vendons pas de produits de qualité inférieure ou présentant des défauts."
                    },
                    {
                        "question":"Les roues complètes sont-elles montées et équilibrées ?",
                        "reponse": "Si vous commandez des roues complètes, celles-ci vous sont livrées montées, gonflées et équilibrées. Nous sommes équipés pour cela de machines de montage, d'équilibrage et d'emballage à la pointe de la technologie."
                    },
                    {
                        "question":"Est-ce que je peux vous envoyer mes pneus pour que vous les montiez sur les jantes que j'ai commandées ?",
                        "reponse": "Nous n'utilisons lors du montage que des articles de notre gamme. Pour des raisons de garantie légale, d'autres produits ne sont pas utilisés."
                    },
                ],
            article1:false,
            article2:false,
            article3:false,
            article4:false,
            article5:false,
            article6:false,
            article7:false,
            article8:false,
            article9:false,
            article10:false,
            article11:false,
            article12:false,
            article13:false,
            article14:false,
            VUE_APP_SITE_ID:null
        }),
        metaInfo() {
            return {
                title: `FAQ - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + `  - Jantes, Pneus & Accessoires`,
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `FAQ - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {name: 'twitter:description', content: `FAQ ` + (this.VUE_APP_SITE_ID === '1' ? `d'Espace Jantes` : `de Distri Jantes`) + `, le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.`},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `FAQ -` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {property: 'og:site_name', content: this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`},
                    {property: 'og:type', content: 'Website'},
                    {property: 'og:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},
                    {property: 'og:description', content: `FAQ ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    {name: 'robots', content: 'index,follow'}
                ]
            }
        },
        methods: {
            switchFaq:function (index){
                if(this.selectedFaq[index] === true){
                    this.selectedFaq[index] = false
                }else if(this.selectedFaq[index] === false || this.selectedFaq[index] === undefined){
                    this.selectedFaq[index] = true
                }
            }
        },
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        }
    }

</script>

<style scoped>
    .border-top{
        border-top: 1px solid rgba(137, 158, 168, 0.15)
    }
</style>
