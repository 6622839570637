<template>
    <v-app class="background" >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>


        <v-main v-if="VUE_APP_SITE_ID === '1'">

            <ais-instant-search :search-client="searchClient" index-name="dev_EJ">

                <v-container v-if="$vuetify.breakpoint.lgAndUp" fluid class="pb-0 px-0  bc_white">

                    <ais-configure :hitsPerPage="20" :filters='filtreAlgolia' distinct="false" />
                    <v-col offset-lg="1" lg="11" class="pb-0">
                        <v-row>
                            <v-col lg="2" md="2" style="padding-left: 3%;padding-right: 3%; color: white" :class="{'bc_darkBlue': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2'}" >
                                <v-row class="underlined pt-4 pb-1 mb-2  font-weight-bold " :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">MON VÉHICULE</v-row>
                                <v-row class=""> {{ vehiculeUtilisateur }}</v-row>
                                <v-row>
                                    <RouterLink :to="{name: 'Home'}" class="no_deco">
                                        <v-btn class="font-weight-bold mt-2">Modifier</v-btn>
                                    </RouterLink>
                                </v-row>
                                <v-row class="underlined pt-4 pb-1 mb-2  font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">MODELES
                                </v-row>
                                <v-row>
                                    <v-col class="pa-0">
                                        <ais-menu-select class='font-weight-bold fontSize' attribute="modele" :transform-items="transformItems" :limit="400" style="padding-right: 0px;"  >
                                            <template slot="defaultOption" >
                                                Tous les modèles
                                            </template>
                                            <template slot="item" slot-scope="{ item }">
                                                {{ item.label }}
                                            </template>
                                        </ais-menu-select>
                                    </v-col>
                                </v-row>
                                <v-row class="underlined pt-4 pb-1 mb-2  font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">OPTION MONTAGE</v-row>
                                <v-row>
                                    <v-col lg="8" md="7" class="pa-0">
                                        <v-checkbox @change="checkboxUpdated('par4')" dark :class="{'white--text-lg':$vuetify.breakpoint.lg, 'white--text-xl':$vuetify.breakpoint.xl}"  color="white" v-model="checkboxPar4" :label="'Monte par 4'" />
                                    </v-col>
                                    <v-col lg="4" md="5">
                                        <v-img v-if="VUE_APP_SITE_ID === '1'" aspect-ratio="1" src="@/assets/icons/icon_montepar4EJ.svg" alt='Espace-Jantes montage par 4' title='Espace-Jantes montage par 4'/>
                                        <v-img v-else-if="VUE_APP_SITE_ID === '2'" aspect-ratio="1" src="@/assets/icons/icon_montepar4DJ.svg" alt='Distri-Jantes montage par 4' title='Distri-Jantes montage par 4' style="max-width: 50%"/>
                                    </v-col>

                                    <v-col lg="8" md="7" class="pa-0" v-if="monteTypeBool">
                                        <v-checkbox hide-details @change="checkboxUpdated('decale')" dark :class="{'white--text-lg':$vuetify.breakpoint.lg,'mt-0':$vuetify.breakpoint.lg, 'white--text-xl':$vuetify.breakpoint.xl, 'mt-3':$vuetify.breakpoint.xl}" color="white" v-model="checkboxDecalee" :label="'Monte décalée'" />
                                    </v-col>
                                    <v-col lg="4" md="5" class="" v-if="monteTypeBool">
                                        <v-img v-if="VUE_APP_SITE_ID === '1'" aspect-ratio="1" src="@/assets/icons/icon_montedecaleeEJ.svg" alt='Espace-Jantes montage décalé' title='Espace-Jantes montage décalé'/>
                                        <v-img v-else-if="VUE_APP_SITE_ID === '2'" aspect-ratio="1" src="@/assets/icons/icon_montedecaleeDJ.svg" alt='Distri-Jantes montage décalé' title='Distri-Jantes montage décalé' style="max-width: 50%"/>
                                    </v-col>

                                    <v-checkbox
                                        hide-details
                                        dark
                                        class="font-weight-bold"
                                        :class="{'green&#45;&#45;text-lg':$vuetify.breakpoint.lg, 'green&#45;&#45;text-xl':$vuetify.breakpoint.xl }"
                                        color="white"
                                        v-model="checkboxLookConstructeur"
                                        :label="'Voir look constructeur'"
                                        @click="LookConstructeur()"
                                    />
                                </v-row>
                                <v-row class="underlined pt-4 pb-1 mb-2 font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">TAILLES</v-row>
                                <v-row class="align-center rowConfigurateur">
                                    <v-col class="pa-0">
                                        <div v-for="dimension in dimensions"  :key="dimension.id" class="dimensionCss pointer"  @click="DimensionClick(dimension)" :class="{currentEJ : dimension === dimensionChoisi && VUE_APP_SITE_ID === '1', currentDJ : dimension === dimensionChoisi && VUE_APP_SITE_ID === '2' }">
                                            {{dimension}}
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-row class="underlined pt-4 pb-1 mb-2 font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">MARQUES</v-row>
                                <v-row>
                                    <v-col class="pa-0">
                                        <ais-menu-select attribute="marque" :transform-items="transformItems" :limit="100" class='fontSize font-weight-bold' style="padding-right: 0px;" >
                                            <template slot="defaultOption">
                                                Toutes les marques
                                            </template>
                                            <template slot="item" slot-scope="{ item }">
                                                {{ item.label }}
                                            </template>
                                        </ais-menu-select>
                                    </v-col>
                                </v-row>
                                <v-row class="underlined pt-4 pb-1 mb-2 font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">COULEURS</v-row>
                                <v-row>
                                    <v-col class="pa-0">
                                        <ais-menu-select attribute="couleur" :transform-items="transformItemsCouleur" :limit="100" class='fontSize font-weight-bold' style="padding-right: 0px;">
                                            <template slot="defaultOption">
                                                Toutes les couleurs
                                            </template>
                                            <template slot="item" slot-scope="{ item }">
                                                {{ item.label }}
                                            </template>
                                        </ais-menu-select>
                                    </v-col>
                                </v-row>

                            </v-col>

                            <v-col lg="9" class="pt-0">
                                <ais-stats class=" font-weight-bold">
                                    <h1 slot-scope="{nbHits}" class=" font-weight-bold "   :class="{'headline':$vuetify.breakpoint.lg, 'display-1':$vuetify.breakpoint.xl, 'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}" >
                                        JANTES POUR VOTRE VÉHICULE  - <span v-if="nbHitsReady"> {{ nbHits }} </span>  RÉSULTATS
                                    </h1>
                                </ais-stats>
                                <p class="body-2 mb-2 " :class="{'body-2':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Chez Espace jantes, nous proposons de nombreux modèles de jantes compatibles avec votre véhicule {{ marqueVehiculeUtilisateur }}.</p>
                                <p class=" body-2  font-weight-bold" :class="{'body-2':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl, 'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}"> Montage offert : pack complet, prêt à rouler !</p>

                                <ListingJante v-if="$vuetify.breakpoint.lgAndUp"/>

                            </v-col>

                        </v-row>


                    </v-col>

                </v-container>

                <v-navigation-drawer v-if="$vuetify.breakpoint.mdAndDown" v-model="drawer" temporary fixed width="70%" style=" top:0; left:0; overflow-y:scroll;">
                    <v-row :class="{'bc_darkBlue': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2'}">
                        <v-col cols="12" class="d-flex justify-end" >
                            <v-btn text>
                                <v-icon large color="white" @click="drawer = false">mdi mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                        <ais-configure :hitsPerPage="20" :filters='filtreAlgolia' distinct="false" />
                        <v-col offset="1" cols="10" class="pl-0 pt-0 " >

                            <v-content fluid class="pb-0 ">
                                <v-row>
                                    <v-col cols="12" class="pb-0 tc_white" :class="{'bc_darkBlue': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2'}">
                                        <v-row>
                                            <v-col cols="12" class=" pt-4 pb-1 mb-2  font-weight-bold " :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}"  >
                                                <p class="underlined mb-0">MON VÉHICULE</p>
                                            </v-col>
                                            <v-col cols="12" class="py-0">{{ vehiculeUtilisateur }}</v-col>
                                            <v-col cols="12">
                                                <RouterLink :to="{name: 'Home'}" class="no_deco">
                                                    <v-btn class="font-weight-bold  mt-2" light>Modifier</v-btn>
                                                </RouterLink>
                                            </v-col>
                                            <v-col cols="12" class=" pt-4 pb-1 mb-2  font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">
                                                <p class="underlined mb-0">MODÈLE</p>
                                            </v-col>
                                            <v-col cols="12">
                                                <ais-menu-select class=' font-weight-bold' attribute="modele" :transform-items="transformItems" :limit="400" style="padding-right: 0px;" >
                                                    <template slot="defaultOption" >
                                                        Tous les modèles
                                                    </template>
                                                    <template slot="item" slot-scope="{ item }">
                                                        {{ item.label }}
                                                    </template>
                                                </ais-menu-select>
                                            </v-col>
                                            <v-col cols="12" class="pt-4 pb-1 mb-2  font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">
                                                <p class="underlined mb-0">OPTION MONTAGE</p>
                                            </v-col>

                                            <v-col>
                                                <v-row v-if="$vuetify.breakpoint.md">
                                                    <v-col cols="4">
                                                        <v-checkbox hide-details @change="checkboxUpdated('par4')" dark :class="{'white--text-lg':$vuetify.breakpoint.lg, 'white--text-xl':$vuetify.breakpoint.xl}"  color="white" v-model="checkboxPar4" :label="'Monte par 4'" />
                                                    </v-col>
                                                    <v-col cols="2" class="align-self-center">
                                                        <v-img v-if="VUE_APP_SITE_ID === '1'" aspect-ratio="1" src="@/assets/icons/icon_montepar4EJ.svg" style="max-width: 70%"  alt='Espace-Jantes montage par 4' title='Espace-Jantes montage par 4'/>
                                                        <v-img v-else-if="VUE_APP_SITE_ID === '2'" aspect-ratio="1" src="@/assets/icons/icon_montepar4DJ.svg" style="max-width: 40%"  alt='Espace-Jantes montage par 4' title='Espace-Jantes montage par 4' />
                                                    </v-col>

                                                    <v-col cols="4" v-if="monteTypeBool">
                                                        <v-checkbox hide-details @change="checkboxUpdated('decale')" dark :class="{'white--text-lg':$vuetify.breakpoint.lg,'mt-0':$vuetify.breakpoint.lg, 'white--text-xl':$vuetify.breakpoint.xl, 'mt-3':$vuetify.breakpoint.xl}" color="white" v-model="checkboxDecalee" :label="'Monte décalée'" />
                                                    </v-col>
                                                    <v-col cols="2" class="align-self-center" v-if="monteTypeBool">
                                                        <v-img v-if="VUE_APP_SITE_ID === '1'" aspect-ratio="1" src="@/assets/icons/icon_montedecaleeEJ.svg" style="max-width: 70%" alt='Espace-Jantes montage décalé' title='Espace-Jantes montage décalé'/>
                                                        <v-img v-else-if="VUE_APP_SITE_ID === '2'" aspect-ratio="1" src="@/assets/icons/icon_montedecaleeDJ.svg" style="max-width: 50%" alt='Espace-Jantes montage décalé' title='Espace-Jantes montage décalé'/>
                                                    </v-col>
                                                </v-row>

                                                <v-col v-if="$vuetify.breakpoint.smAndDown">
                                                    <v-row>
                                                        <v-col sm="6" cols="8">
                                                            <v-checkbox @change="checkboxUpdated('par4')" dark :class="{'white--text-lg':$vuetify.breakpoint.lg, 'white--text-xl':$vuetify.breakpoint.xl}"  color="white" v-model="checkboxPar4" :label="'Monte par 4'" />
                                                        </v-col>
                                                        <v-col sm="2" cols="4" class="align-self-center">
                                                            <v-img v-if="VUE_APP_SITE_ID === '1'" aspect-ratio="1" src="@/assets/icons/icon_montepar4EJ.svg"  alt='Espace-Jantes montage par 4' title='Espace-Jantes montage par 4'/>
                                                            <v-img v-else-if="VUE_APP_SITE_ID === '2'" aspect-ratio="1" src="@/assets/icons/icon_montepar4DJ.svg"  alt='Espace-Jantes montage par 4' title='Espace-Jantes montage par 4' style="max-width: 50%"/>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col sm="6" cols="8" v-if="monteTypeBool">
                                                            <v-checkbox @change="checkboxUpdated('decale')" dark :class="{'white--text-lg':$vuetify.breakpoint.lg,'mt-0':$vuetify.breakpoint.lg, 'white--text-xl':$vuetify.breakpoint.xl, 'mt-3':$vuetify.breakpoint.xl}" color="white" v-model="checkboxDecalee" :label="'Monte décalée'" />
                                                        </v-col>
                                                        <v-col sm="2" cols="4" class="align-self-center" v-if="monteTypeBool">
                                                            <v-img v-if="VUE_APP_SITE_ID === '1'" aspect-ratio="1" src="@/assets/icons/icon_montedecaleeEJ.svg"  alt='Espace-Jantes montage décalé' title='Espace-Jantes montage décalé'/>
                                                            <v-img v-else-if="VUE_APP_SITE_ID === '2'" aspect-ratio="1" src="@/assets/icons/icon_montedecaleeDJ.svg"  alt='Espace-Jantes montage décalé' title='Espace-Jantes montage décalé' style="max-width: 50%"/>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-col>

                                            <v-col cols="12" class="py-0 px-6 px-md-3">
                                                <v-checkbox
                                                    dark
                                                    class="font-weight-bold green&#45;&#45;text"
                                                    :class="{'green&#45;&#45;text-lg':$vuetify.breakpoint.lg, 'green&#45;&#45;text-xl':$vuetify.breakpoint.xl }"
                                                    color="white"
                                                    v-model="checkboxLookConstructeur"
                                                    :label="'Voir look constructeur'"
                                                    @click="LookConstructeur()"
                                                />
                                            </v-col>
                                            <v-col cols="12" class=" pt-4 pb-1 mb-2 font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">
                                                <p class="underlined mb-0">TAILLES</p>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-col class="pa-0">
                                                    <div v-for="dimension in dimensions"  :key="dimension.id" class="dimensionCss pointer"  @click="DimensionClick(dimension)"  :class="{currentEJ : dimension === dimensionChoisi && VUE_APP_SITE_ID === '1', currentDJ : dimension === dimensionChoisi && VUE_APP_SITE_ID === '2' }">
                                                        {{dimension}}
                                                    </div>
                                                </v-col>
                                            </v-col>
                                            <v-col cols="12" class="pt-4 pb-1 mb-2 font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">
                                                <p class="underlined mb-0">MARQUES</p>
                                            </v-col>
                                            <v-col cols="12">
                                                <ais-menu-select attribute="marque" :transform-items="transformItems" :limit="100" class=' font-weight-bold' style="padding-right: 0px;" >
                                                    <template slot="defaultOption">
                                                        Toutes les marques
                                                    </template>
                                                    <template slot="item" slot-scope="{ item }">
                                                        {{ item.label }}
                                                    </template>
                                                </ais-menu-select>
                                            </v-col>
                                            <v-col cols="12" class="pt-4 pb-1 mb-2 font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">
                                                <p class="underlined mb-0">COULEURS</p>
                                            </v-col>
                                            <v-col cols="12">
                                                <ais-menu-select attribute="couleur" :transform-items="transformItemsCouleur" :limit="100" class=' font-weight-bold' style="padding-right: 0px;">
                                                    <template slot="defaultOption">
                                                        Toutes les couleurs
                                                    </template>
                                                    <template slot="item" slot-scope="{ item }">
                                                        {{ item.label }}
                                                    </template>
                                                </ais-menu-select>
                                            </v-col>

                                            <v-col>
                                                <v-btn @click="drawer = false">Confirmer</v-btn>
                                            </v-col>

                                        </v-row>

                                    </v-col>

                                </v-row>

                            </v-content>

                        </v-col>

                    </v-row>
                </v-navigation-drawer>

                <v-container v-if="$vuetify.breakpoint.mdAndDown" fluid class="pb-0 px-0  bc_white">
                    <ais-configure :hitsPerPage="20" :filters='filtreAlgolia' distinct="false" />
                    <ais-stats class=" font-weight-bold">
                        <h1 slot-scope="{nbHits}" class=" font-weight-bold px-3 "  :class="{'headline':$vuetify.breakpoint.mdAndDown,  'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">
                            JANTES POUR VOTRE VÉHICULE <br v-if="$vuetify.breakpoint.xs"> - <span v-if="nbHitsReady"> {{ nbHits }} </span> RÉSULTATS
                        </h1>
                    </ais-stats>
                    <ListingJante v-if="$vuetify.breakpoint.mdAndDown" @drawerTrue="drawer = true" />
                </v-container>

            </ais-instant-search>

        </v-main>


        <v-main v-if="VUE_APP_SITE_ID === '2'">

            <ais-instant-search :search-client="searchClient" index-name="dev_EJ">

                <v-container v-if="$vuetify.breakpoint.lgAndUp" fluid class="pa-0  bc_white">

                    <ais-configure :hitsPerPage="20" :filters='filtreAlgolia' distinct="false" />
<!--                    <v-col offset-lg="1" lg="10" class="py-0">-->
                    <v-col  cols="12" class="py-0">

                        <v-row class="px-3 ">
<!--                            <v-card class="bc_lightGrey">-->
<!--                                <v-col cols="12">-->
                                <v-row class="pa-3 pb-8 bc_lightGrey " >

                                    <v-col offset="1" cols="2" class=" pa-3">
                                        <v-row class="half-underlined pt-4 pb-1 ml-1 mb-2  font-weight-bold " :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">MON VÉHICULE</v-row>
                                        <v-row class="px-3"> <span>{{ vehiculeUtilisateur }}</span></v-row>
                                        <v-row class="px-3">
                                            <RouterLink :to="{name: 'Home'}" class="no_deco">
                                                <v-btn class="font-weight-bold mt-2">Modifier</v-btn>
                                            </RouterLink>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="2" class="">
                                        <v-row class="half-underlined pt-4 pb-1 mb-2 font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">MARQUES</v-row>
                                        <v-row>
                                            <v-col class="pa-0">
                                                <ais-menu-select attribute="marque" :transform-items="transformItems" :limit="100" class='fontSize font-weight-bold' style="padding-right: 0px;" >
                                                    <template slot="defaultOption">
                                                        Toutes les marques
                                                    </template>
                                                    <template slot="item" slot-scope="{ item }">
                                                        {{ item.label }}
                                                    </template>
                                                </ais-menu-select>
                                            </v-col>
                                        </v-row>
                                        <v-row class="half-underlined pt-4 pb-1 mb-2  font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">MODÈLES
                                        </v-row>
                                        <v-row>
                                            <v-col class="pa-0">
                                                <ais-menu-select class='font-weight-bold fontSize' attribute="modele" :transform-items="transformItems" :limit="400" style="padding-right: 0px;"  >
                                                    <template slot="defaultOption" >
                                                        Tous les modèles
                                                    </template>
                                                    <template slot="item" slot-scope="{ item }">
                                                        {{ item.label }}
                                                    </template>
                                                </ais-menu-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="2" class="">
                                        <v-row class="half-underlined pt-4 pb-1 mb-2  font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">OPTION MONTAGE</v-row>
                                        <v-row>
                                            <v-col lg="8" md="7" class="pa-0">
                                                <v-checkbox v-if="VUE_APP_SITE_ID === '1'" @change="checkboxUpdated('par4')" dark :class="{'white--text-lg':$vuetify.breakpoint.lg, 'white--text-xl':$vuetify.breakpoint.xl}"  color="white" v-model="checkboxPar4" :label="'Monte par 4'" />
                                                <v-checkbox v-if="VUE_APP_SITE_ID === '2'" @change="checkboxUpdated('par4')" :class="{'black--text-lg':$vuetify.breakpoint.lg, 'black--text-xl':$vuetify.breakpoint.xl}"   color="black" v-model="checkboxPar4" :label="'Monte par 4'" />
                                            </v-col>
                                            <v-col lg="4" md="5">
                                                <v-img v-if="VUE_APP_SITE_ID === '1'" aspect-ratio="1" src="@/assets/icons/icon_montepar4EJ.svg" alt='Espace-Jantes montage par 4' title='Espace-Jantes montage par 4'/>
                                                <v-img v-else-if="VUE_APP_SITE_ID === '2'" aspect-ratio="1" src="@/assets/icons/icon_montepar4DJ.svg" alt='Distri-Jantes montage par 4' title='Distri-Jantes montage par 4' style="max-width: 50%"/>
                                            </v-col>

                                            <v-col lg="8" md="7" class="pa-0" v-if="monteTypeBool">
                                                <v-checkbox v-if="VUE_APP_SITE_ID === '1'" hide-details @change="checkboxUpdated('decale')" dark :class="{'white--text-lg':$vuetify.breakpoint.lg,'mt-0':$vuetify.breakpoint.lg, 'white--text-xl':$vuetify.breakpoint.xl, 'mt-3':$vuetify.breakpoint.xl}" color="white" v-model="checkboxDecalee" :label="'Monte décalée'" />
                                                <v-checkbox v-if="VUE_APP_SITE_ID === '2'" hide-details @change="checkboxUpdated('decale')"  :class="{'black--text-lg':$vuetify.breakpoint.lg,'mt-0':$vuetify.breakpoint.lg, 'black--text-xl':$vuetify.breakpoint.xl, 'mt-3':$vuetify.breakpoint.xl}" color="black" v-model="checkboxDecalee" :label="'Monte décalée'" />
                                            </v-col>
                                            <v-col lg="4" md="5" class="" v-if="monteTypeBool">
                                                <v-img v-if="VUE_APP_SITE_ID === '1'" aspect-ratio="1" src="@/assets/icons/icon_montedecaleeEJ.svg" alt='Espace-Jantes montage décalé' title='Espace-Jantes montage décalé'/>
                                                <v-img v-else-if="VUE_APP_SITE_ID === '2'" aspect-ratio="1" src="@/assets/icons/icon_montedecaleeDJ.svg" alt='Distri-Jantes montage décalé' title='Distri-Jantes montage décalé' style="max-width: 50%"/>
                                            </v-col>

                                            <v-checkbox
                                                v-if="VUE_APP_SITE_ID === '1'"
                                                hide-details
                                                dark
                                                class="font-weight-bold"
                                                :class="{'green--text-lg':$vuetify.breakpoint.lg, 'green--text-xl':$vuetify.breakpoint.xl }"
                                                color="black"
                                                v-model="checkboxLookConstructeur"
                                                :label="'Voir look constructeur'"
                                                @click="LookConstructeur()"
                                            />
                                            <v-checkbox
                                                v-if="VUE_APP_SITE_ID === '2'"
                                                hide-details
                                                class="font-weight-bold"
                                                :class="{'black--text-lg':$vuetify.breakpoint.lg, 'black--text-xl':$vuetify.breakpoint.xl }"
                                                color="white"
                                                v-model="checkboxLookConstructeur"
                                                :label="'Voir look constructeur'"
                                                @click="LookConstructeur()"
                                            />
                                        </v-row>
                                    </v-col>
                                    <v-col cols="2" class="">
                                        <v-row class="half-underlined pt-4 pb-1 mb-2 font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">TAILLES</v-row>
                                        <v-row class="align-center rowConfigurateur">
                                            <v-col class="pa-0">
                                                <div v-for="dimension in dimensions"  :key="dimension.id" class="dimensionCss pointer"  @click="DimensionClick(dimension)" :class="{currentEJ : dimension === dimensionChoisi && VUE_APP_SITE_ID === '1', currentDJ : dimension === dimensionChoisi && VUE_APP_SITE_ID === '2' }">
                                                    {{dimension}}
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                    <v-col cols="2" class="">
                                        <v-row class="half-underlined pt-4 pb-1 mb-2 font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">COULEURS</v-row>
                                        <v-row>
                                            <v-col class="pa-0">
                                                <ais-menu-select attribute="couleur" :transform-items="transformItemsCouleur" :limit="100" class='fontSize font-weight-bold' style="padding-right: 0px;">
                                                    <template slot="defaultOption">
                                                        Toutes les couleurs
                                                    </template>
                                                    <template slot="item" slot-scope="{ item }">
                                                        {{ item.label }}
                                                    </template>
                                                </ais-menu-select>
                                            </v-col>
                                        </v-row>
                                    </v-col>

                                </v-row>
<!--                                </v-col>-->
<!--                            </v-card>-->

                            <v-col cols="12" class="pt-0 mt-6 mb-3">
                                <ais-stats class=" font-weight-bold">
                                    <h1 slot-scope="{nbHits}" class=" font-weight-bold "   :class="{'headline':$vuetify.breakpoint.lg, 'display-1':$vuetify.breakpoint.xl, 'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}" >
                                        JANTES POUR VOTRE VÉHICULE  - <span v-if="nbHitsReady"> {{ nbHits }} </span>  RÉSULTATS
                                    </h1>
                                </ais-stats>
                                <p class="body-2 mb-2 " :class="{'body-2':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Chez Distri jantes, nous proposons de nombreux modèles de jantes compatibles avec votre véhicule {{ marqueVehiculeUtilisateur }}.</p>
                                <span class=" body-2  font-weight-bold" :class="{'body-2':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl, 'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}"> Montage offert : pack complet, prêt à rouler !</span>

                                <ListingJante v-if="$vuetify.breakpoint.lgAndUp"/>

                            </v-col>

                        </v-row>


                    </v-col>

                </v-container>

                <v-navigation-drawer v-if="$vuetify.breakpoint.mdAndDown" v-model="drawer" temporary fixed width="70%" style=" top:0; left:0; overflow-y:scroll;">
                    <v-row :class="{'bc_darkBlue': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2'}">
                        <v-col cols="12" class="d-flex justify-end" >
                            <v-btn text>
                                <v-icon large color="white" @click="drawer = false">mdi mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                        <ais-configure :hitsPerPage="20" :filters='filtreAlgolia' distinct="false" />
                        <v-col offset="1" cols="10" class="pl-0 pt-0 " >

                            <v-content fluid class="pb-0 ">
                                <v-row>
                                    <v-col cols="12" class="pb-0 tc_white" :class="{'bc_darkBlue': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2'}">
                                        <v-row>
                                            <v-col cols="12" class=" pt-4 pb-1 mb-2  font-weight-bold " :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}"  >
                                                <p class="half-underlined mb-0">MON VÉHICULE</p>
                                            </v-col>
                                            <v-col cols="12" class="py-0">{{ vehiculeUtilisateur }}</v-col>
                                            <v-col cols="12">
                                                <RouterLink :to="{name: 'Home'}" class="no_deco">
                                                    <v-btn class="font-weight-bold  mt-2" light>Modifier</v-btn>
                                                </RouterLink>
                                            </v-col>
                                            <v-col cols="12" class=" pt-4 pb-1 mb-2  font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">
                                                <p class="half-underlined mb-0">MODÈLE</p>
                                            </v-col>
                                            <v-col cols="12">
                                                <ais-menu-select class=' font-weight-bold' attribute="modele" :transform-items="transformItems" :limit="400" style="padding-right: 0px;" >
                                                    <template slot="defaultOption" >
                                                        Tous les modèles
                                                    </template>
                                                    <template slot="item" slot-scope="{ item }">
                                                        {{ item.label }}
                                                    </template>
                                                </ais-menu-select>
                                            </v-col>
                                            <v-col cols="12" class="pt-4 pb-1 mb-2  font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">
                                                <p class="half-underlined mb-0">OPTION MONTAGE</p>
                                            </v-col>

                                            <v-col>
                                                <v-row v-if="$vuetify.breakpoint.md">
                                                    <v-col cols="4">
                                                        <v-checkbox hide-details @change="checkboxUpdated('par4')" dark :class="{'white--text-lg':$vuetify.breakpoint.lg, 'white--text-xl':$vuetify.breakpoint.xl}"  color="white" v-model="checkboxPar4" :label="'Monte par 4'" />
                                                    </v-col>
                                                    <v-col cols="2" class="align-self-center">
                                                        <v-img v-if="VUE_APP_SITE_ID === '1'" aspect-ratio="1"  src="@/assets/icons/icon_montepar4EJ.svg" style="max-width: 70%"  alt='Espace-Jantes montage par 4' title='Espace-Jantes montage par 4'/>
                                                        <v-img v-else-if="VUE_APP_SITE_ID === '2'" aspect-ratio="1" src="@/assets/icons/icon_montepar4DJ.svg" style="max-width: 40%"  alt='Espace-Jantes montage par 4' title='Espace-Jantes montage par 4' />
                                                    </v-col>

                                                    <v-col cols="4" v-if="monteTypeBool">
                                                        <v-checkbox hide-details @change="checkboxUpdated('decale')" dark :class="{'white--text-lg':$vuetify.breakpoint.lg,'mt-0':$vuetify.breakpoint.lg, 'white--text-xl':$vuetify.breakpoint.xl, 'mt-3':$vuetify.breakpoint.xl}" color="white" v-model="checkboxDecalee" :label="'Monte décalée'" />
                                                    </v-col>
                                                    <v-col cols="2" class="align-self-center" v-if="monteTypeBool">
                                                        <v-img v-if="VUE_APP_SITE_ID === '1'" aspect-ratio="1" src="@/assets/icons/icon_montedecaleeEJ.svg" style="max-width: 70%" alt='Espace-Jantes montage décalé' title='Espace-Jantes montage décalé'/>
                                                        <v-img v-else-if="VUE_APP_SITE_ID === '2'" aspect-ratio="1" src="@/assets/icons/icon_montedecaleeDJ.svg" style="max-width: 50%" alt='Espace-Jantes montage décalé' title='Espace-Jantes montage décalé'/>
                                                    </v-col>
                                                </v-row>

                                                <v-col v-if="$vuetify.breakpoint.smAndDown">
                                                    <v-row>
                                                        <v-col sm="6" cols="8">
                                                            <v-checkbox @change="checkboxUpdated('par4')" dark :class="{'white--text-lg':$vuetify.breakpoint.lg, 'white--text-xl':$vuetify.breakpoint.xl}"  color="white" v-model="checkboxPar4" :label="'Monte par 4'" />
                                                        </v-col>
                                                        <v-col sm="2" cols="4" class="align-self-center">
                                                            <v-img v-if="VUE_APP_SITE_ID === '1'" aspect-ratio="1" src="@/assets/icons/icon_montepar4EJ.svg"  alt='Espace-Jantes montage par 4' title='Espace-Jantes montage par 4'/>
                                                            <v-img v-else-if="VUE_APP_SITE_ID === '2'" aspect-ratio="1" src="@/assets/icons/icon_montepar4DJ.svg"  alt='Espace-Jantes montage par 4' title='Espace-Jantes montage par 4' style="max-width: 50%"/>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col sm="6" cols="8" v-if="monteTypeBool">
                                                            <v-checkbox @change="checkboxUpdated('decale')" dark :class="{'white--text-lg':$vuetify.breakpoint.lg,'mt-0':$vuetify.breakpoint.lg, 'white--text-xl':$vuetify.breakpoint.xl, 'mt-3':$vuetify.breakpoint.xl}" color="white" v-model="checkboxDecalee" :label="'Monte décalée'" />
                                                        </v-col>
                                                        <v-col sm="2" cols="4" class="align-self-center" v-if="monteTypeBool">
                                                            <v-img v-if="VUE_APP_SITE_ID === '1'" aspect-ratio="1" src="@/assets/icons/icon_montedecaleeEJ.svg"  alt='Espace-Jantes montage décalé' title='Espace-Jantes montage décalé'/>
                                                            <v-img v-else-if="VUE_APP_SITE_ID === '2'" aspect-ratio="1" src="@/assets/icons/icon_montedecaleeDJ.svg"  alt='Espace-Jantes montage décalé' title='Espace-Jantes montage décalé' style="max-width: 50%"/>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-col>

                                            <v-col cols="12" class="py-0 px-6 px-md-3">
                                                <v-checkbox
                                                    dark
                                                    class="font-weight-bold green&#45;&#45;text"
                                                    :class="{'green&#45;&#45;text-lg':$vuetify.breakpoint.lg, 'green&#45;&#45;text-xl':$vuetify.breakpoint.xl }"
                                                    color="white"
                                                    v-model="checkboxLookConstructeur"
                                                    :label="'Voir look constructeur'"
                                                    @click="LookConstructeur()"
                                                />
                                            </v-col>
                                            <v-col cols="12" class=" pt-4 pb-1 mb-2 font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">
                                                <p class="half-underlined mb-0">TAILLES</p>
                                            </v-col>
                                            <v-col cols="12">
                                                <v-col class="pa-0">
                                                    <div v-for="dimension in dimensions"  :key="dimension.id" class="dimensionCss pointer"  @click="DimensionClick(dimension)"  :class="{currentEJ : dimension === dimensionChoisi && VUE_APP_SITE_ID === '1', currentDJ : dimension === dimensionChoisi && VUE_APP_SITE_ID === '2' }">
                                                        {{dimension}}
                                                    </div>
                                                </v-col>
                                            </v-col>
                                            <v-col cols="12" class="pt-4 pb-1 mb-2 font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">
                                                <p class="half-underlined mb-0">MARQUES</p>
                                            </v-col>
                                            <v-col cols="12">
                                                <ais-menu-select attribute="marque" :transform-items="transformItems" :limit="100" class=' font-weight-bold' style="padding-right: 0px;" >
                                                    <template slot="defaultOption">
                                                        Toutes les marques
                                                    </template>
                                                    <template slot="item" slot-scope="{ item }">
                                                        {{ item.label }}
                                                    </template>
                                                </ais-menu-select>
                                            </v-col>
                                            <v-col cols="12" class="pt-4 pb-1 mb-2 font-weight-bold "  :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}">
                                                <p class="half-underlined mb-0">COULEURS</p>
                                            </v-col>
                                            <v-col cols="12">
                                                <ais-menu-select attribute="couleur" :transform-items="transformItemsCouleur" :limit="100" class=' font-weight-bold' style="padding-right: 0px;">
                                                    <template slot="defaultOption">
                                                        Toutes les couleurs
                                                    </template>
                                                    <template slot="item" slot-scope="{ item }">
                                                        {{ item.label }}
                                                    </template>
                                                </ais-menu-select>
                                            </v-col>

                                            <v-col>
                                                <v-btn @click="drawer = false">Confirmer</v-btn>
                                            </v-col>

                                        </v-row>

                                    </v-col>

                                </v-row>

                            </v-content>

                        </v-col>

                    </v-row>
                </v-navigation-drawer>

                <v-container v-if="$vuetify.breakpoint.mdAndDown" fluid class="pb-0 px-0  bc_white">
                    <ais-configure :hitsPerPage="20" :filters='filtreAlgolia' distinct="false" />
                    <ais-stats class=" font-weight-bold">
                        <h1 slot-scope="{nbHits}" class=" font-weight-bold px-3 "  :class="{'headline':$vuetify.breakpoint.mdAndDown,  'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">
                            JANTES POUR VOTRE VÉHICULE <br v-if="$vuetify.breakpoint.xs"> - <span v-if="nbHitsReady"> {{ nbHits }} </span> RÉSULTATS
                        </h1>
                    </ais-stats>
                    <ListingJante v-if="$vuetify.breakpoint.mdAndDown" @drawerTrue="drawer = true" />
                </v-container>

            </ais-instant-search>

        </v-main>


        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "./headers/HomepageHeaderEj";
import HomepageHeaderDj from "./headers/HomepageHeaderDj";
import HomepageFooterEj from "./footer/HomepageFooterEj";
import HomepageFooterDj from "./footer/HomepageFooterDj";
import ListingJante from "./ListingJante";
import backgroundUrl from '@/assets/testBackground.png'
import greybackgroundUrl from '@/assets/greyBackground.jpg'

import algoliasearch from 'algoliasearch';
import 'instantsearch.css/themes/algolia-min.css';

import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {mapState} from "vuex";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(VueAxios, axios)


export default {
    name: "Listing.vue",
    components:{
        HomepageHeaderEj,
        HomepageHeaderDj,
        HomepageFooterEj,
        HomepageFooterDj,
        ListingJante
    },
    data: () =>({
        searchClient: algoliasearch(
            'IXJF8AJTH7',
            '27829f3918903605b2f7665097a13737'
        ),
        optionFront:[],
        optionRear:[],
        filtreAlgolia : '',
        greybackgroundUrl : greybackgroundUrl,
        backgroundUrl:backgroundUrl,
        checkboxPar4: true,
        checkboxDecalee: '',
        checkboxLookConstructeur: false,
        monteType: '',
        vehiculeUtilisateur : '',
        overlayMenu:false,
        drawer:false,
        nbHitsReady:false,
        dimensions: [],
        dimensionChoisi:"",
        monteTypeBool:false,
        counter:0,
        MontageSave: null,
        marqueVehiculeUtilisateur: '',
        VUE_APP_SITE_ID:null
    }),
    methods:{
        LookConstructeur() {
          var newValue = ls.get('monteType')
          // console.log(newValue)
            this.$store.commit('loader/setLoader', true)

            // var chassisVehiculeUtilisateur = JSON.parse(this.$localStorage.get('chassisVehiculeUtilisateur'))
            var chassisVehiculeUtilisateur
            if( typeof ls.get('chassisVehiculeUtilisateur') == "string" ){
                chassisVehiculeUtilisateur = JSON.parse(ls.get('chassisVehiculeUtilisateur'))
            }
            else if(typeof ls.get('chassisVehiculeUtilisateur') == "object"){
                chassisVehiculeUtilisateur = ls.get('chassisVehiculeUtilisateur')
            }
            var id_chassis = chassisVehiculeUtilisateur.id_chassis
            // this.optionFront = []
            // this.optionRear = []

            var OptionFront = []
            var OptionRear = []
            var OptionCommune = []
            var tamponAlgolia = ''
            var  _this = this

            Vue.axios.get('https://api.espace-jantes.com/getOptionsChassis', {params: {idChassisVehiculeUtilisateur : id_chassis}})
                .then((response) => {
                    // var diametreAxios =  this.$localStorage.get('diametre')
                    var diametreAxios =  ls.get('diametre')
                    response.data.forEach(element => {

                        var wheelSizeSplit = element.wheelSize.split('x')
                        if(element.upsetType == "RearUpstep") {

                            if(wheelSizeSplit[1].trim() == diametreAxios.trim()) {
                                if(newValue == 'decale'){
                                OptionRear.push(element)
                                    OptionCommune.push(element)
                            }
                            }
                        }else{
                            if(wheelSizeSplit[1].trim() == diametreAxios.trim()) {
                                OptionFront.push(element)
                                OptionCommune.push(element)
                            }
                        }
                    })
                    this.optionFront = OptionFront
                    this.optionRear = OptionRear

                     var MaxOffsetOption =  Math.max.apply(Math, OptionCommune.map(function(o) { return o.maxOffset; }))
                     var MinOffsetOption = Math.min.apply(Math, OptionCommune.map(function(o) { return o.minOffset; }))

                    //var chassisVehiculeUtilisateur = JSON.parse(this.$localStorage.get('chassisVehiculeUtilisateur'))

                    var modelePossibleFront = []
                    var modelePossibleRear = []
                    var modelePossibleFinal = []

                    // var diametre = 'diametre:' + this.$localStorage.get('diametre')
                    var diametre = 'diametre:' + ls.get('diametre')
                    var alesage = ' alesage >= '+ chassisVehiculeUtilisateur.alesage + ' '
                    var doubleEntraxe = (chassisVehiculeUtilisateur.entraxe.indexOf('.') != -1)? ' OR entraxe:' +  chassisVehiculeUtilisateur.entraxe.split('.')[0]+' ' :' '
                    var entraxe = ' entraxe:'+ chassisVehiculeUtilisateur.entraxe.replace('.',',') + ' ' + doubleEntraxe
                    var offset = ' offset: '+ MinOffsetOption +' TO '+ MaxOffsetOption +' '// Dabord relancer les cron car les maxEtRear ne sont pas bon
                    var stock = ' ( NOT stock:0 AND NOT stock:1 AND NOT stock:2 AND NOT stock:3 ) '
                    var ldpFilter = ' NOT marque:' + (this.VUE_APP_SITE_ID === '1' ? 'DJ-WHEELS' : 'EJ-WHEELS')
                    var filterExclude = " NOT modele:'JR23' AND NOT modele:'EJX2067' AND NOT modele:'DJX2067'"

                    var marqueConstructeur = " ( marque:'Evyce dedicated' OR  marque:'GMP' OR marque:'EJ-WHEELS' OR marque:'MAM' OR marque:'WSP' OR marque:'ACR' ) "

                    var hits = []

                    if(newValue === 'par4')
                    {
                        this.checkboxDecalee = false
                        this.checkboxPar4 = true

                        this.$store.commit('infoJantes/setMonteStateDeca', false)
                        this.$store.commit('filtreListingSave/saveFilter', {Montage: 'par4'})
                        // localStorage.setItem('MontageSave', 'par4')
                        ls.set('MontageSave', 'par4')

                        const client = algoliasearch('IXJF8AJTH7', 'c4c7c9439e003674579cb68a3352acc5');
                        const index = client.initIndex('dev_EJ');

                        index.browseObjects({
                            query: '', // Empty query will match all records
                            filters: diametre + " AND " + alesage + " AND " + entraxe  + " AND " + stock + " AND " + marqueConstructeur + " AND " + ldpFilter + " AND " + filterExclude + " AND visible: 1",
                            batch: batch => {
                            hits = hits.concat(batch);
                            }})
                        .then(() => {
                            hits.forEach(element => {
                                    this.optionFront.forEach(elementFront => {
                                        if(parseFloat(elementFront.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                            if(element.offset >= elementFront.minOffset && element.offset <= elementFront.maxOffset){
                                               var resultatFind = modelePossibleFront.find(modele => modele.objectID == element.objectID)
                                                if(resultatFind == undefined || resultatFind == "undefined"){
                                                    if(element.marque === 'ACR'){
                                                        if(element.acrType !== (this.VUE_APP_SITE_ID === '1' ? 'DJ' : 'EJ')){
                                                            modelePossibleFront.push(element)
                                                        }
                                                    }else{
                                                        modelePossibleFront.push(element)
                                                    }
                                                }
                                            }
                                        }
                                    })
                                })
                                var modeleAlgolia = " AND"
                                modelePossibleFront.forEach(element => {
                                     if(modeleAlgolia != " AND"){
                                        if(element.objectID.length > 0 && element.objectID !== " "){
                                            modeleAlgolia += " OR "
                                        }
                                    }
                                    if(element.objectID.length > 0 && element.objectID !== " "){
                                        modeleAlgolia += " objectID: '"+ element.objectID + "' "
                                    }
                                })
                                if(modelePossibleFront.length > 0){
                                    // this.filtreAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset + " AND " + stock +  modeleAlgolia
                                    tamponAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset + " AND " + stock +  modeleAlgolia
                                }else{
                                    var diametreZero = 'diametre:0'
                                    this.filtreAlgolia = diametreZero
                                    tamponAlgolia = diametreZero

                                }
                            })
                            setTimeout(function() {
                            var hitsSecond = []
                            var filterAlgoliaWithObjectId = ""
                            index.browseObjects({
                            query: '', // Empty query will match all records
                            filters: tamponAlgolia + " AND " + ldpFilter +  " AND " + filterExclude + " AND visible: 1",
                            batch: batch => {
                            hitsSecond = hitsSecond.concat(batch);
                            }})
                            .then(() => {
                                var arrayDistinct = []
                                hitsSecond.forEach(item => {
                                    if(arrayDistinct.length == 0){

                                    arrayDistinct.push(item)
                                    }
                                    var information = arrayDistinct.filter(elementDistinct => { return elementDistinct.couleur.toString() === item.couleur.toString() && elementDistinct.modele.toString() === item.modele.toString()  })
                                    if(information == 0){
                                    arrayDistinct.push(item)
                                    }
                                })
                                arrayDistinct.forEach(element => {
                                    if(filterAlgoliaWithObjectId == ""){
                                        filterAlgoliaWithObjectId = " objectID : '"+ element.objectID +"' "
                                    }else{
                                        filterAlgoliaWithObjectId += "OR  objectID : '"+ element.objectID +"' "
                                    }
                                })
                                setTimeout(function() {
                                    _this.UpdateAlgoliaFilter(filterAlgoliaWithObjectId)
                                }, 500);
                            })
                        }, 5000);
                        // this.filtreAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset + "AND " + stock
                        // this.$store.commit('loader/setLoader', false)

                    }

                    if(newValue === 'decale')
                    {
                        this.checkboxPar4 = false
                        this.checkboxDecalee = true

                        this.$store.commit('filtreListingSave/saveFilter', {Montage: 'decale'})

                        this.$store.commit('infoJantes/setMonteStateDeca', true)

                        // localStorage.setItem('MontageSave', 'decale')
                        ls.set('MontageSave', 'decale')


                        // diametre = 'diametre:' + this.$localStorage.get('diametre')
                        diametre = 'diametre:' + ls.get('diametre')
                        const client = algoliasearch('IXJF8AJTH7', 'c4c7c9439e003674579cb68a3352acc5');
                        const index = client.initIndex('dev_EJ');
                        index.browseObjects({
                            query: '', // Empty query will match all records
                            filters: diametre + " AND " + alesage + " AND " + entraxe  + " AND " + stock +  " AND " + marqueConstructeur + " AND " + ldpFilter + " AND " + filterExclude + " AND visible: 1",
                            batch: batch => {
                            hits = hits.concat(batch);
                            }})
                        .then(() => {
                                hits.forEach(element => {
                                    this.optionFront.forEach(elementFront => {
                                        if(parseFloat(elementFront.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                            if(element.offset >= elementFront.minOffset && element.offset <= elementFront.maxOffset){
                                                var resultatFindFront = modelePossibleRear.find(modeleRear => modeleRear.objectID == element.objectID)
                                                if(resultatFindFront == undefined || resultatFindFront == "undefined"){
                                                    if(element.marque === 'ACR'){
                                                        if(element.acrType !== (this.VUE_APP_SITE_ID === '1' ? 'DJ' : 'EJ')){
                                                            modelePossibleFront.push(element)
                                                        }
                                                    }else{
                                                        modelePossibleFront.push(element)
                                                    }
                                                }
                                            }
                                        }
                                    })
                                    this.optionRear.forEach(elementRear => {
                                        if(parseFloat(elementRear.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                            if(element.offset >= elementRear.minOffset && element.offset <= elementRear.maxOffset){
                                                var resultatFindRear = modelePossibleRear.find(modeleRear => modeleRear.objectID == element.objectID)
                                                if(resultatFindRear == undefined || resultatFindRear == "undefined"){
                                                    if(element.marque === 'ACR'){
                                                        if(element.acrType !== (this.VUE_APP_SITE_ID === '1' ? 'DJ' : 'EJ')){
                                                            modelePossibleRear.push(element)
                                                        }
                                                    }else{
                                                        modelePossibleRear.push(element)
                                                    }
                                                }
                                            }
                                        }
                                    })
                                })
                                var self = this
                                setTimeout(function(){
                                modelePossibleRear.forEach(element => {
                                    modelePossibleFront.forEach(elementRear => {

                                        if(element.modele === elementRear.modele && elementRear.marque == element.marque &&  modelePossibleFinal.indexOf(element) == -1 && element.couleur == elementRear.couleur){
                                            var resultatFindRear = modelePossibleFinal.find(modeleRear => modeleRear.objectID == element.objectID)
                                            if(resultatFindRear == undefined || resultatFindRear == "undefined"){
                                                if(element.marque === 'ACR'){
                                                    if(element.acrType !== (this.VUE_APP_SITE_ID === '1' ? 'DJ' : 'EJ')){
                                                        modelePossibleFinal.push(element)
                                                    }
                                                }else{
                                                    modelePossibleFinal.push(element)
                                                }
                                            }
                                        }
                                    })
                                })
                                var modeleAlgolia = " AND"
                                modelePossibleFinal.forEach(element => {
                                     if(modeleAlgolia != " AND"){
                                        if(element.objectID.length > 0 && element.objectID !== " "){
                                            modeleAlgolia += " OR "
                                        }
                                    }
                                    if(element.objectID.length > 0 && element.objectID !== " "){
                                        modeleAlgolia += " objectID: '"+ element.objectID + "' "
                                    }
                                })
                                if(modelePossibleFinal.length > 0) {
                                    // self.filtreAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset + " AND " + stock +  modeleAlgolia
                                    tamponAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset+ " AND " + stock +  modeleAlgolia
                                    //console.log(tamponAlgolia)
                                }else{
                                    var diametreZero = 'diametre:0'
                                    self.filtreAlgolia = diametreZero
                                }
                            }, 1500);
                        })
                        setTimeout(function() {
                                var hitsSecond = []
                                var filterAlgoliaWithObjectId = ""
                                index.browseObjects({
                                query: '', // Empty query will match all records
                                filters: tamponAlgolia + " AND " + ldpFilter + " AND " + filterExclude + " AND visible: 1",
                                batch: batch => {
                                hitsSecond = hitsSecond.concat(batch);
                                }})
                                .then(() => {
                                    var arrayDistinct = []
                                    hitsSecond.forEach(item => {
                                        if(arrayDistinct.length == 0){

                                        arrayDistinct.push(item)
                                        }
                                        var information = arrayDistinct.filter(elementDistinct => { return elementDistinct.couleur.toString() === item.couleur.toString() && elementDistinct.modele.toString() === item.modele.toString()  })
                                        if(information == 0){

                                        arrayDistinct.push(item)
                                        }
                                    })
                                    arrayDistinct.forEach(element => {
                                        if(filterAlgoliaWithObjectId == ""){
                                            filterAlgoliaWithObjectId = " objectID : '"+ element.objectID +"' "
                                        }else{
                                            filterAlgoliaWithObjectId += "OR  objectID : '"+ element.objectID +"' "
                                        }
                                    })
                                   setTimeout(function() {
                                    _this.UpdateAlgoliaFilter(filterAlgoliaWithObjectId)
                                }, 500);
                                })
                         }, 2500);
                    }
                })

            this.monteType = newValue
            // this.$localStorage.set('monteType', this.monteType)
            ls.set('monteType', this.monteType)
        },
        transformItems(items) {
            this.counter += 1
            return items.map(item => ({
                ...item,
                label: item.label.toUpperCase(),
            }));
        },
        transformItemsCouleur(items) {
            return items.map(item => ({
                ...item,
                label: item.label.toUpperCase(),
            }));
        },
        checkboxUpdated(newValue) {
            this.$store.commit('loader/setLoader', true)
            this.checkboxLookConstructeur = false

            // var chassisVehiculeUtilisateur = JSON.parse(this.$localStorage.get('chassisVehiculeUtilisateur'))
            var chassisVehiculeUtilisateur
            if( typeof ls.get('chassisVehiculeUtilisateur') == "string" ){
                chassisVehiculeUtilisateur = JSON.parse(ls.get('chassisVehiculeUtilisateur'))
            }
            else if(typeof ls.get('chassisVehiculeUtilisateur') == "object"){
                chassisVehiculeUtilisateur = ls.get('chassisVehiculeUtilisateur')
            }
            var id_chassis = chassisVehiculeUtilisateur.id_chassis
            // this.optionFront = []
            // this.optionRear = []

            var OptionFront = []
            var OptionRear = []
            var OptionCommune = []
            var tamponAlgolia = ''
            var  _this = this

            Vue.axios.get('https://api.espace-jantes.com/getOptionsChassis', {params: {idChassisVehiculeUtilisateur : id_chassis}})
                .then((response) => {
                    // var diametreAxios =  this.$localStorage.get('diametre')
                    var diametreAxios =  ls.get('diametre')
                    response.data.forEach(element => {

                        var wheelSizeSplit = element.wheelSize.split('x')
                        if(element.upsetType == "RearUpstep") {

                            if(wheelSizeSplit[1].trim() == diametreAxios.trim()) {
                                if(newValue == 'decale'){
                                OptionRear.push(element)
                                    OptionCommune.push(element)
                            }
                            }
                        }else{
                            if(wheelSizeSplit[1].trim() == diametreAxios.trim()) {
                                OptionFront.push(element)
                                OptionCommune.push(element)
                            }
                        }
                    })
                    this.optionFront = OptionFront
                    this.optionRear = OptionRear

                     var MaxOffsetOption =  Math.max.apply(Math, OptionCommune.map(function(o) { return o.maxOffset; }))
                     var MinOffsetOption = Math.min.apply(Math, OptionCommune.map(function(o) { return o.minOffset; }))

                    //var chassisVehiculeUtilisateur = JSON.parse(this.$localStorage.get('chassisVehiculeUtilisateur'))

                    var modelePossibleFront = []
                    var modelePossibleRear = []
                    var modelePossibleFinal = []

                    // var diametre = 'diametre:' + this.$localStorage.get('diametre')
                    var diametre = 'diametre:' + ls.get('diametre')
                    var alesage = ' alesage >= '+ chassisVehiculeUtilisateur.alesage + ' '
                    var doubleEntraxe = (chassisVehiculeUtilisateur.entraxe.indexOf('.') != -1)? ' OR entraxe:' +  chassisVehiculeUtilisateur.entraxe.split('.')[0]+' ' :' '
                    var entraxe = ' entraxe:'+ chassisVehiculeUtilisateur.entraxe.replace('.',',') + ' ' + doubleEntraxe
                    var offset = ' offset: '+ MinOffsetOption +' TO '+ MaxOffsetOption +' '// Dabord relancer les cron car les maxEtRear ne sont pas bon
                    var stock = ' ( NOT stock:0 AND NOT stock:1 AND NOT stock:2 AND NOT stock:3 ) '
                    var ldpFilter = ' NOT marque:' + (this.VUE_APP_SITE_ID === '1' ? 'DJ-WHEELS' : 'EJ-WHEELS')
                    var filterExclude = " NOT modele:'JR23' AND NOT modele:'EJX2067' AND NOT modele:'DJX2067'"

                    var hits = []

                    if(newValue === 'par4')
                    {
                        this.checkboxDecalee = false
                        this.checkboxPar4 = true

                        this.$store.commit('infoJantes/setMonteStateDeca', false)
                        this.$store.commit('filtreListingSave/saveFilter', {Montage: 'par4'})
                        // localStorage.setItem('MontageSave', 'par4')
                        ls.set('MontageSave', 'par4')

                        const client = algoliasearch('IXJF8AJTH7', 'c4c7c9439e003674579cb68a3352acc5');
                        const index = client.initIndex('dev_EJ');

                        index.browseObjects({
                            query: '', // Empty query will match all records
                            filters: diametre + " AND " + alesage + " AND " + entraxe  + " AND " + stock + " AND " + ldpFilter + " AND " + filterExclude + " AND visible: 1",
                            batch: batch => {
                            hits = hits.concat(batch);
                            }})
                        .then(() => {
                                hits.forEach(element => {
                                    this.optionFront.forEach(elementFront => {
                                        if(parseFloat(elementFront.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                            if(element.offset >= elementFront.minOffset && element.offset <= elementFront.maxOffset){
                                               var resultatFind = modelePossibleFront.find(modele => modele.objectID == element.objectID)
                                                if(resultatFind == undefined || resultatFind == "undefined"){
                                                    if(element.marque === 'ACR'){
                                                        if(element.acrType !== (this.VUE_APP_SITE_ID === '1' ? 'DJ' : 'EJ')){
                                                            modelePossibleFront.push(element)
                                                        }
                                                    }else{
                                                        modelePossibleFront.push(element)
                                                    }
                                                }
                                            }
                                        }
                                    })
                                })
                                var modeleAlgolia = " AND"
                                modelePossibleFront.forEach(element => {
                                     if(modeleAlgolia != " AND"){
                                        if(element.objectID.length > 0 && element.objectID !== " "){
                                            modeleAlgolia += " OR "
                                        }
                                    }
                                    if(element.objectID.length > 0 && element.objectID !== " "){
                                        modeleAlgolia += " objectID: '"+ element.objectID + "' "
                                    }
                                })
                                if(modelePossibleFront.length > 0){
                                    // this.filtreAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset + " AND " + stock +  modeleAlgolia
                                    tamponAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset + " AND " + stock +  modeleAlgolia
                                }else{
                                    var diametreZero = 'diametre:0'
                                    this.filtreAlgolia = diametreZero
                                    tamponAlgolia = diametreZero

                                }
                            })
                            setTimeout(function() {
                            var hitsSecond = []
                            var filterAlgoliaWithObjectId = ""
                            index.browseObjects({
                            query: '', // Empty query will match all records
                            filters: tamponAlgolia + " AND " + ldpFilter + " AND " + filterExclude + " AND visible: 1",
                            batch: batch => {
                            hitsSecond = hitsSecond.concat(batch);
                            }})
                            .then(() => {
                                var arrayDistinct = []
                                hitsSecond.forEach(item => {
                                    if(arrayDistinct.length == 0){

                                    arrayDistinct.push(item)
                                    }
                                    var information = arrayDistinct.filter(elementDistinct => { return elementDistinct.couleur.toString() === item.couleur.toString() && elementDistinct.modele.toString() === item.modele.toString()  })
                                    if(information == 0){
                                    arrayDistinct.push(item)
                                    }
                                })
                                arrayDistinct.forEach(element => {
                                    if(filterAlgoliaWithObjectId == ""){
                                        filterAlgoliaWithObjectId = " objectID : '"+ element.objectID +"' "
                                    }else{
                                        filterAlgoliaWithObjectId += "OR  objectID : '"+ element.objectID +"' "
                                    }
                                })
                                setTimeout(function() {
                                    _this.UpdateAlgoliaFilter(filterAlgoliaWithObjectId)
                                }, 500);
                            })
                        }, 2000);
                        // this.filtreAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset + "AND " + stock
                        // this.$store.commit('loader/setLoader', false)

                    }

                    if(newValue === 'decale')
                    {
                        this.checkboxPar4 = false
                        this.checkboxDecalee = true

                        this.$store.commit('filtreListingSave/saveFilter', {Montage: 'decale'})

                        this.$store.commit('infoJantes/setMonteStateDeca', true)

                        // localStorage.setItem('MontageSave', 'decale')
                        ls.set('MontageSave', 'decale')


                        // diametre = 'diametre:' + this.$localStorage.get('diametre')
                        diametre = 'diametre:' + ls.get('diametre')
                        const client = algoliasearch('IXJF8AJTH7', 'c4c7c9439e003674579cb68a3352acc5');
                        const index = client.initIndex('dev_EJ');
                        index.browseObjects({
                            query: '', // Empty query will match all records
                            filters: diametre + " AND " + alesage + " AND " + entraxe  + " AND " + stock + " AND " + ldpFilter + " AND " + filterExclude + " AND visible: 1",
                            batch: batch => {
                            hits = hits.concat(batch);
                            }})
                        .then(() => {
                                hits.forEach(element => {
                                    this.optionFront.forEach(elementFront => {
                                        if(parseFloat(elementFront.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                            if(element.offset >= elementFront.minOffset && element.offset <= elementFront.maxOffset){
                                                var resultatFindFront = modelePossibleRear.find(modeleRear => modeleRear.objectID == element.objectID)
                                                if(resultatFindFront == undefined || resultatFindFront == "undefined"){
                                                    if(element.marque === 'ACR'){
                                                        if(element.acrType !== (this.VUE_APP_SITE_ID === '1' ? 'DJ' : 'EJ')){
                                                            modelePossibleFront.push(element)
                                                        }
                                                    }else{
                                                        modelePossibleFront.push(element)
                                                    }
                                                }
                                            }
                                        }
                                    })
                                    this.optionRear.forEach(elementRear => {
                                        if(parseFloat(elementRear.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                            if(element.offset >= elementRear.minOffset && element.offset <= elementRear.maxOffset){
                                                var resultatFindRear = modelePossibleRear.find(modeleRear => modeleRear.objectID == element.objectID)
                                                if(resultatFindRear == undefined || resultatFindRear == "undefined"){
                                                    if(element.marque === 'ACR'){
                                                        if(element.acrType !== (this.VUE_APP_SITE_ID === '1' ? 'DJ' : 'EJ')){
                                                            modelePossibleRear.push(element)
                                                        }
                                                    }else{
                                                        modelePossibleRear.push(element)
                                                    }
                                                }
                                            }
                                        }
                                    })
                                })
                                var self = this
                                setTimeout(function(){
                                modelePossibleRear.forEach(element => {
                                    modelePossibleFront.forEach(elementRear => {

                                        if(element.modele === elementRear.modele && elementRear.marque == element.marque &&  modelePossibleFinal.indexOf(element) == -1 && element.couleur == elementRear.couleur){
                                            var resultatFindRear = modelePossibleFinal.find(modeleRear => modeleRear.objectID == element.objectID)
                                            if(resultatFindRear == undefined || resultatFindRear == "undefined"){
                                                if(element.marque === 'ACR'){
                                                    if(element.acrType !== (this.VUE_APP_SITE_ID === '1' ? 'DJ' : 'EJ')){
                                                        modelePossibleFinal.push(element)
                                                    }
                                                }else{
                                                    modelePossibleFinal.push(element)
                                                }
                                            }
                                        }
                                    })
                                })
                                var modeleAlgolia = " AND"
                                modelePossibleFinal.forEach(element => {
                                     if(modeleAlgolia != " AND"){
                                        if(element.objectID.length > 0 && element.objectID !== " "){
                                            modeleAlgolia += " OR "
                                        }
                                    }
                                    if(element.objectID.length > 0 && element.objectID !== " "){
                                        modeleAlgolia += " objectID: '"+ element.objectID + "' "
                                    }
                                })
                                if(modelePossibleFinal.length > 0) {
                                    // self.filtreAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset + " AND " + stock +  modeleAlgolia
                                    tamponAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset+ " AND " + stock +  modeleAlgolia
                                    //console.log(tamponAlgolia)
                                }else{
                                    var diametreZero = 'diametre:0'
                                    self.filtreAlgolia = diametreZero
                                }
                            }, 1500);
                        })
                        setTimeout(function() {
                                var hitsSecond = []
                                var filterAlgoliaWithObjectId = ""
                                index.browseObjects({
                                query: '', // Empty query will match all records
                                filters: tamponAlgolia + " AND " + ldpFilter + " AND " + filterExclude + " AND visible: 1",
                                batch: batch => {
                                hitsSecond = hitsSecond.concat(batch);
                                }})
                                .then(() => {
                                    var arrayDistinct = []
                                    hitsSecond.forEach(item => {
                                        if(arrayDistinct.length == 0){

                                        arrayDistinct.push(item)
                                        }
                                        var information = arrayDistinct.filter(elementDistinct => { return elementDistinct.couleur.toString() === item.couleur.toString() && elementDistinct.modele.toString() === item.modele.toString()  })
                                        if(information == 0){

                                        arrayDistinct.push(item)
                                        }
                                    })
                                    arrayDistinct.forEach(element => {
                                        if(filterAlgoliaWithObjectId == ""){
                                            filterAlgoliaWithObjectId = " objectID : '"+ element.objectID +"' "
                                        }else{
                                            filterAlgoliaWithObjectId += "OR  objectID : '"+ element.objectID +"' "
                                        }
                                    })
                                   setTimeout(function() {
                                    _this.UpdateAlgoliaFilter(filterAlgoliaWithObjectId)
                                }, 500);
                                })
                         }, 4500);
                    }
                })

            this.monteType = newValue
            // this.$localStorage.set('monteType', this.monteType)
            ls.set('monteType', this.monteType)
        },
        DimensionClick: function(dimension) {

            this.$store.commit('loader/setLoader', true)
            this.$store.commit('filtreListingSave/saveFilter', {Taille: dimension})
                    this.monteType = ls.get('monteType')

            // var chassisVehiculeUtilisateur = JSON.parse(this.$localStorage.get('chassisVehiculeUtilisateur'))
            var chassisVehiculeUtilisateur
            if( typeof ls.get('chassisVehiculeUtilisateur') == "string" ){
                chassisVehiculeUtilisateur = JSON.parse(ls.get('chassisVehiculeUtilisateur'))
            }
            else if(typeof ls.get('chassisVehiculeUtilisateur') == "object"){
                chassisVehiculeUtilisateur = ls.get('chassisVehiculeUtilisateur')
            }
            var id_chassis = chassisVehiculeUtilisateur.id_chassis
            // this.optionFront = []
            // this.optionRear = []

            var OptionFront = []
            var OptionRear = []
            var OptionCommune = []
            var tamponAlgolia= ""

            Vue.axios.get('https://api.espace-jantes.com/getOptionsChassis', {params: {idChassisVehiculeUtilisateur : id_chassis}})
                .then((response) => {
                    // var diametreAxios = (dimension == 0 )? this.$localStorage.get('diametre'): dimension
                    var diametreAxios = (dimension == 0 )? ls.get('diametre'): dimension
                    response.data.forEach(element => {

                        var wheelSizeSplit = element.wheelSize.split('x')
                        if(element.upsetType == "RearUpstep") {

                            if(wheelSizeSplit[1].trim() == diametreAxios.trim()) {
                                if(this.monteType == 'decale'){
                                OptionRear.push(element)
                                    OptionCommune.push(element)
                                }
                            }
                        }else{
                            if(wheelSizeSplit[1].trim() == diametreAxios.trim()) {
                                OptionFront.push(element)
                                OptionCommune.push(element)
                            }
                        }
                    })
                    this.optionFront = OptionFront
                    this.optionRear = OptionRear

                    var MaxOffsetOption =  Math.max.apply(Math, OptionCommune.map(function(o) { return o.maxOffset; }))
                    var MinOffsetOption = Math.min.apply(Math, OptionCommune.map(function(o) { return o.minOffset; }))



                    // var chassisVehiculeUtilisateur = JSON.parse(this.$localStorage.get('chassisVehiculeUtilisateur'))

                    var diametre = 'diametre:' + dimension
                    var alesage = ' alesage >= '+ chassisVehiculeUtilisateur.alesage + ' '
                    var doubleEntraxe = (chassisVehiculeUtilisateur.entraxe.indexOf('.') != -1)? '  OR entraxe: ' +  chassisVehiculeUtilisateur.entraxe.split('.')[0] :' '
                    var entraxe = ' entraxe:'+ chassisVehiculeUtilisateur.entraxe.replace('.',',') + ' ' + doubleEntraxe
                    var offset = ' offset: '+ MinOffsetOption +' TO '+ MaxOffsetOption +' '// Dabord relancer les cron car les maxEtRear ne sont pas bon
                    var stock = ' ( NOT stock:0 AND NOT stock:1 AND NOT stock:2 AND NOT stock:3 ) '
                    var ldpFilter = ' NOT marque:' + (this.VUE_APP_SITE_ID === '1' ? 'DJ-WHEELS' : 'EJ-WHEELS')
                    var filterExclude = " NOT modele:'JR23' AND NOT modele:'EJX2067' AND NOT modele:'DJX2067'"

                    var hits = []
                    var modelePossibleFront = []
                    var modelePossibleRear = []
                    this.monteType = ls.get('monteType')

                    if(this.monteType == "par4"){

                        const client = algoliasearch('IXJF8AJTH7', 'c4c7c9439e003674579cb68a3352acc5');
                        const index = client.initIndex('dev_EJ');

                        index.browseObjects({
                            query: '', // Empty query will match all records
                            filters: diametre + " AND " + alesage + " AND " + entraxe  + " AND " + stock + " AND " + ldpFilter + " AND " + filterExclude + " AND visible: 1",
                            batch: batch => {
                            hits = hits.concat(batch);
                            }})
                        .then(() => {
                                hits.forEach(element => {
                                    // console.log(element)
                                    this.optionFront.forEach(elementFront => {
                                        if(parseFloat(elementFront.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){

                                            if(element.offset >= elementFront.minOffset && element.offset <= elementFront.maxOffset){
                                                var resultatFind = modelePossibleFront.find(modele => modele.objectID == element.objectID)
                                                if(resultatFind == undefined || resultatFind == "undefined"){
                                                    if(element.marque === 'ACR'){
                                                        if(element.acrType !== (this.VUE_APP_SITE_ID === '1' ? 'DJ' : 'EJ')){
                                                            modelePossibleFront.push(element)
                                                        }
                                                    }else{
                                                        modelePossibleFront.push(element)
                                                    }
                                                }
                                            }
                                        }
                                    })
                                })
                                var modeleAlgolia = " AND"
                                modelePossibleFront.forEach(element => {
                                    if(modeleAlgolia != " AND"){
                                        if(element.objectID.length > 0 && element.objectID !== " "){
                                            modeleAlgolia += " OR "
                                        }
                                    }
                                    if(element.objectID.length > 0 && element.objectID !== " "){
                                        modeleAlgolia += " objectID: '"+ element.objectID + "' "
                                    }
                                })
                                if(modelePossibleFront.length > 0){
                                    // this.filtreAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset + " AND " + stock +  modeleAlgolia
                                    tamponAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset + " AND " + stock +  modeleAlgolia
                                }else{
                                    var diametreZero = 'diametre:0'
                                    this.filtreAlgolia = diametreZero
                                    tamponAlgolia = diametreZero

                                }
                                var hitsSecond = []
                                var filterAlgoliaWithObjectId = ""
                                index.browseObjects({
                                query: '', // Empty query will match all records
                                filters: tamponAlgolia + " AND " + ldpFilter + " AND " + filterExclude + " AND visible: 1",
                                batch: batch => {
                                hitsSecond = hitsSecond.concat(batch);
                                }})
                                .then(() => {
                                    var arrayDistinct = []
                                    hitsSecond.forEach(item => {
                                        if(arrayDistinct.length == 0){

                                        arrayDistinct.push(item)
                                        }
                                        var information = arrayDistinct.filter(elementDistinct => { return elementDistinct.couleur.toString() === item.couleur.toString() && elementDistinct.modele.toString() === item.modele.toString()  })
                                        if(information == 0){
                                        arrayDistinct.push(item)
                                        }
                                    })
                                    arrayDistinct.forEach(element => {
                                        if(filterAlgoliaWithObjectId == ""){
                                            filterAlgoliaWithObjectId = " objectID : '"+ element.objectID +"' "
                                        }else{
                                            filterAlgoliaWithObjectId += "OR  objectID : '"+ element.objectID +"' "
                                        }
                                    })
                                    // console.log(filterAlgoliaWithObjectId.length)
                                    this.filtreAlgolia = filterAlgoliaWithObjectId
                                })
                            this.$store.commit('loader/setLoader', false)

                            })
                    }else if(this.monteType == "decale"){

                        diametre = 'diametre:' + dimension
                        const client = algoliasearch('IXJF8AJTH7', 'c4c7c9439e003674579cb68a3352acc5');
                        const index = client.initIndex('dev_EJ');

                        index.browseObjects({
                            query: '', // Empty query will match all records
                            filters: diametre + " AND " + alesage + " AND " + entraxe  + " AND " + stock + " AND " + ldpFilter + " AND " + filterExclude + " AND visible: 1",
                            batch: batch => {
                            hits = hits.concat(batch);
                            }})
                        .then(() => {
                                modelePossibleFront = []
                                modelePossibleRear =[]
                                hits.forEach(element => {

                                    this.optionFront.forEach(elementFront => {
                                        if(parseFloat(elementFront.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                            if(element.offset >= elementFront.minOffset && element.offset <= elementFront.maxOffset){
                                                var resultatFindFront = modelePossibleRear.find(modeleRear => modeleRear.objectID == element.objectID)
                                                if(resultatFindFront == undefined || resultatFindFront == "undefined"){
                                                    if(element.marque === 'ACR'){
                                                        if(element.acrType !== (this.VUE_APP_SITE_ID === '1' ? 'DJ' : 'EJ')){
                                                            modelePossibleFront.push(element)
                                                        }
                                                    }else{
                                                        modelePossibleFront.push(element)
                                                    }
                                                }
                                            }
                                        }
                                    })
                                    this.optionRear.forEach(elementRear => {
                                        if(parseFloat(elementRear.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                            if(element.offset >= elementRear.minOffset && element.offset <= elementRear.maxOffset){
                                                var resultatFindRear = modelePossibleRear.find(modeleRear => modeleRear.objectID == element.objectID)
                                                if(resultatFindRear == undefined || resultatFindRear == "undefined"){
                                                    if(element.marque === 'ACR'){
                                                        if(element.acrType !== (this.VUE_APP_SITE_ID === '1' ? 'DJ' : 'EJ')){
                                                            modelePossibleRear.push(element)
                                                        }
                                                    }else{
                                                        modelePossibleRear.push(element)
                                                    }
                                                }
                                            }
                                        }
                                    })
                                })
                                var self = this
                                setTimeout(function(){

                                    var modelePossibleFinal = []
                                    modelePossibleRear.forEach(element => {
                                        modelePossibleFront.forEach(elementRear => {
                                            if(element.modele === elementRear.modele && elementRear.marque == element.marque &&  modelePossibleFinal.indexOf(element) == -1 && element.couleur == elementRear.couleur){
                                                var resultatFindRear = modelePossibleFinal.find(modeleRear => modeleRear.objectID == element.objectID)
                                                if(resultatFindRear == undefined || resultatFindRear == "undefined"){
                                                        modelePossibleFinal.push(element)
                                                    if(element.marque === 'ACR'){
                                                        if(element.acrType !== (this.VUE_APP_SITE_ID === '1' ? 'DJ' : 'EJ')){
                                                            modelePossibleFinal.push(element)
                                                        }
                                                    }else{
                                                        modelePossibleFinal.push(element)
                                                    }
                                                }
                                            }
                                        })
                                    })
                                    var modeleAlgolia = " AND"
                                    modelePossibleFinal.forEach(element => {
                                        if(modeleAlgolia != " AND"){
                                        if(element.objectID.length > 0 && element.objectID !== " "){
                                            modeleAlgolia += " OR "
                                        }
                                    }
                                    if(element.objectID.length > 0 && element.objectID !== " "){
                                        modeleAlgolia += " objectID: '"+ element.objectID + "' "
                                    }
                                    })
                                    if(modelePossibleFinal.length > 0){
                                        //self.filtreAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset + " AND " + stock +  modeleAlgolia
                                        tamponAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset + " AND " + stock +  modeleAlgolia

                                    }else{
                                        var diametreZero = 'diametre:0'
                                        self.filtreAlgolia = diametreZero
                                    }
                                }, 1500);
                            })
                           var  _this = this
                            setTimeout(function() {
                                var hitsSecond = []
                                var filterAlgoliaWithObjectId = ""
                                index.browseObjects({
                                query: '', // Empty query will match all records
                                filters: tamponAlgolia + " AND " + ldpFilter + " AND " + filterExclude + " AND visible: 1",
                                batch: batch => {
                                hitsSecond = hitsSecond.concat(batch);
                                }})
                                .then(() => {
                                    var arrayDistinct = []
                                    hitsSecond.forEach(item => {
                                        if(arrayDistinct.length == 0){

                                        arrayDistinct.push(item)
                                        }
                                        var information = arrayDistinct.filter(elementDistinct => { return elementDistinct.couleur.toString() === item.couleur.toString() && elementDistinct.modele.toString() === item.modele.toString()  })
                                        if(information == 0){
                                        arrayDistinct.push(item)
                                        }
                                    })
                                    arrayDistinct.forEach(element => {
                                        if(filterAlgoliaWithObjectId == ""){
                                            filterAlgoliaWithObjectId = " objectID : '"+ element.objectID +"' "
                                        }else{
                                            filterAlgoliaWithObjectId += "OR  objectID : '"+ element.objectID +"' "
                                        }
                                    })
                                setTimeout(function() {
                                    _this.UpdateAlgoliaFilter(filterAlgoliaWithObjectId)
                                }, 500);
                                })
                         }, 2500);

                    }
                })
            this.dimensionChoisi = dimension
            // this.$localStorage.set('diametre', dimension)
            ls.set('diametre', dimension)
        },
        UpdateAlgoliaFilter(filterAlgolia){

            if(filterAlgolia.length == 0 ){
                var diametreZero = 'diametre:0'
                this.filtreAlgolia = diametreZero
            }else{
                this.filtreAlgolia = filterAlgolia
            }
            this.$store.commit('loader/setLoader', false)
            this.nbHitsReady = true
        },
    },
    beforeCreate() {

        this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID

        this.$store.commit('loader/setLoader', true)
        // setTimeout(()=>{
        //     this.$store.commit('loader/setLoader', true)
        //
        // },1000)
        // this.$store.commit('infoJantes/setMonteStateDeca', false)
        var chassisVehiculeUtilisateur
        if( typeof ls.get('chassisVehiculeUtilisateur') == "string" ){
            chassisVehiculeUtilisateur = JSON.parse(ls.get('chassisVehiculeUtilisateur'))
        }
        else if(typeof ls.get('chassisVehiculeUtilisateur') == "object"){
            chassisVehiculeUtilisateur = ls.get('chassisVehiculeUtilisateur')
        }

        var id_chassis = chassisVehiculeUtilisateur.id_chassis

        var OptionFront = []
        var OptionRear = []
        var OptionCommune = []
        var tamponAlgolia = ""
        var  _this = this


        Vue.axios.get('https://api.espace-jantes.com/getOptionsChassis', {params: {idChassisVehiculeUtilisateur : id_chassis}})
            .then((response) => {
                // var diametreAxios =  this.$localStorage.get('diametre')
                this.MontageSave = ls.get('MontageSave')

                var diametreAxios =  ls.get('diametre')
                response.data.forEach(element => {

                    var wheelSizeSplit = element.wheelSize.split('x')
                    if(element.upsetType == "RearUpstep") {
                        this.monteTypeBool = true

                        if(wheelSizeSplit[1].trim() == diametreAxios.trim()) {

                            if(this.MontageSave == 'decale'){
                                OptionRear.push(element)
                                OptionCommune.push(element)
                            }
                        }
                    }else{
                        if(wheelSizeSplit[1].trim() == diametreAxios.trim()) {
                            OptionFront.push(element)
                            OptionCommune.push(element)
                        }
                    }
                })
                setTimeout(()=>{
                    this.optionFront = OptionFront
                    this.optionRear = OptionRear

                    var MaxOffsetOption =  Math.max.apply(Math, OptionCommune.map(function(o) { return o.maxOffset; }))
                    var MinOffsetOption = Math.min.apply(Math, OptionCommune.map(function(o) { return o.minOffset; }))

                // this.vehiculeUtilisateur = this.$localStorage.get('marque') + ' ' + this.$localStorage.get('type') + ' ' + this.$localStorage.get('motorisation')
                this.vehiculeUtilisateur = ls.get('marque') + ' ' + ls.get('type') + ' ' + ls.get('motorisation')
                this.marqueVehiculeUtilisateur = ls.get('marque')


                    var modelePossibleFront = []
                    var modelePossibleRear = []
                    var modelePossibleFinal = []

                    // var diametre = 'diametre:' + this.$localStorage.get('diametre')
                    var diametre = 'diametre:' + ls.get('diametre')
                    var alesage = ' alesage >= '+ chassisVehiculeUtilisateur.alesage + ' '
                    var doubleEntraxe = (chassisVehiculeUtilisateur.entraxe.indexOf('.') != -1)? ' OR entraxe:' +  chassisVehiculeUtilisateur.entraxe.split('.')[0]+' ' :' '
                    var entraxe = ' entraxe:'+ chassisVehiculeUtilisateur.entraxe.replace('.',',') + ' ' + doubleEntraxe
                    var offset = ' offset: '+ MinOffsetOption +' TO '+ MaxOffsetOption +' '// Dabord relancer les cron car les maxEtRear ne sont pas bon
                    var stock = ' ( NOT stock:0 AND NOT stock:1 AND NOT stock:2 AND NOT stock:3 ) '
                    var hits = []
                    var ldpFilter = ' NOT marque:' + (this.VUE_APP_SITE_ID === '1' ? 'DJ-WHEELS' : 'EJ-WHEELS')
                    var filterExclude = " NOT modele:'JR23' AND NOT modele:'EJX2067' AND NOT modele:'DJX2067'"

                    if(this.MontageSave !== 'decale')
                    {
                        this.checkboxDecalee = false
                        this.checkboxPar4 = true

                        this.$store.commit('infoJantes/setMonteStateDeca', false)
                        this.$store.commit('filtreListingSave/saveFilter', {Montage: 'par4'})
                        // localStorage.setItem('MontageSave', 'par4')
                        ls.set('MontageSave', 'par4')

                        const client = algoliasearch('IXJF8AJTH7', 'c4c7c9439e003674579cb68a3352acc5');
                        const index = client.initIndex('dev_EJ');

                        index.browseObjects({
                            query: '', // Empty query will match all records
                            filters: diametre + " AND " + alesage + " AND " + entraxe  + " AND " + stock + " AND " + ldpFilter + " AND visible: 1" + " AND " + filterExclude,
                            // filters: diametre + " AND " + alesage + " AND " + entraxe  + " AND " + stock + " AND " + ldpFilter + " AND visible: 1",
                            batch: batch => {
                                hits = hits.concat(batch);
                            }})
                            .then(() => {
                                // console.log('HITS', hits)
                                hits.forEach(element => {
                                    this.optionFront.forEach(elementFront => {
                                        if(parseFloat(elementFront.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                            if(element.offset >= elementFront.minOffset && element.offset <= elementFront.maxOffset){
                                                var resultatFind = modelePossibleFront.find(modele => modele.objectID == element.objectID)
                                                if(resultatFind == undefined || resultatFind == "undefined"){
                                                    if(element.marque === 'ACR'){
                                                        if(element.acrType !== (this.VUE_APP_SITE_ID === '1' ? 'DJ' : 'EJ')){
                                                            modelePossibleFront.push(element)
                                                        }
                                                    }else{
                                                        modelePossibleFront.push(element)
                                                    }
                                                }
                                            }
                                        }
                                    })
                                })
                                var modeleAlgolia = " AND"
                                modelePossibleFront.forEach(element => {
                                    if(modeleAlgolia != " AND"){
                                        if(element.objectID.length > 0 && element.objectID !== " "){
                                            modeleAlgolia += " OR "
                                        }
                                    }
                                    if(element.objectID.length > 0 && element.objectID !== " "){
                                        modeleAlgolia += " objectID: '"+ element.objectID + "' "
                                    }
                                })
                                if(modelePossibleFront.length > 0){
                                    // this.filtreAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset + " AND " + stock +  modeleAlgolia
                                    tamponAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset + " AND " + stock +  modeleAlgolia
                                }else{
                                    var diametreZero = 'diametre:0'
                                    this.filtreAlgolia = diametreZero
                                    tamponAlgolia = diametreZero

                                }
                            })
                        setTimeout(function() {
                            var hitsSecond = []
                            var filterAlgoliaWithObjectId = ""
                            index.browseObjects({
                                query: '', // Empty query will match all records
                                filters: tamponAlgolia+ " AND " + ldpFilter + " AND " + filterExclude + " AND visible: 1",
                                batch: batch => {
                                    hitsSecond = hitsSecond.concat(batch);
                                }})
                                .then(() => {
                                    var arrayDistinct = []
                                    hitsSecond.forEach(item => {
                                        if(arrayDistinct.length == 0){
                                            arrayDistinct.push(item)
                                        }
                                        var information = arrayDistinct.filter(elementDistinct => { return elementDistinct.couleur.toString() === item.couleur.toString() && elementDistinct.modele.toString() === item.modele.toString()  })
                                        if(information == 0){
                                            arrayDistinct.push(item)
                                        }
                                    })
                                    arrayDistinct.forEach(element => {
                                        if(filterAlgoliaWithObjectId == ""){
                                            filterAlgoliaWithObjectId = " objectID : '"+ element.objectID +"' "
                                        }else{
                                            filterAlgoliaWithObjectId += "OR  objectID : '"+ element.objectID +"' "
                                        }
                                    })
                                    setTimeout(function() {
                                        _this.UpdateAlgoliaFilter(filterAlgoliaWithObjectId)
                                    }, 500);
                                })
                        }, 2000);
                        // this.filtreAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset + "AND " + stock
                        // this.$store.commit('loader/setLoader', false)

                    }

                    else
                    {
                        this.checkboxPar4 = false
                        this.checkboxDecalee = true

                        this.$store.commit('filtreListingSave/saveFilter', {Montage: 'decale'})

                        this.$store.commit('infoJantes/setMonteStateDeca', true)

                        // localStorage.setItem('MontageSave', 'decale')
                        ls.set('MontageSave', 'decale')


                        // diametre = 'diametre:' + this.$localStorage.get('diametre')
                        diametre = 'diametre:' + ls.get('diametre')
                        const client = algoliasearch('IXJF8AJTH7', 'c4c7c9439e003674579cb68a3352acc5');
                        const index = client.initIndex('dev_EJ');
                        index.browseObjects({
                            query: '', // Empty query will match all records
                            filters: diametre + " AND " + alesage + " AND " + entraxe  + " AND " + stock + " AND " + ldpFilter + " AND " + filterExclude + " AND visible: 1",
                            batch: batch => {
                                hits = hits.concat(batch);
                            }})
                            .then(() => {
                                hits.forEach(element => {
                                    this.optionFront.forEach(elementFront => {
                                        if(parseFloat(elementFront.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                            if(element.offset >= elementFront.minOffset && element.offset <= elementFront.maxOffset){
                                                var resultatFindFront = modelePossibleRear.find(modeleRear => modeleRear.objectID == element.objectID)
                                                if(resultatFindFront == undefined || resultatFindFront == "undefined"){
                                                    if(element.marque === 'ACR'){
                                                        if(element.acrType !== (this.VUE_APP_SITE_ID === '1' ? 'DJ' : 'EJ')){
                                                            modelePossibleFront.push(element)
                                                        }
                                                    }else{
                                                        modelePossibleFront.push(element)
                                                    }
                                                }
                                            }
                                        }
                                    })
                                    this.optionRear.forEach(elementRear => {
                                        if(parseFloat(elementRear.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                            if(element.offset >= elementRear.minOffset && element.offset <= elementRear.maxOffset){
                                                var resultatFindRear = modelePossibleRear.find(modeleRear => modeleRear.objectID == element.objectID)
                                                if(resultatFindRear == undefined || resultatFindRear == "undefined"){
                                                    if(element.marque === 'ACR'){
                                                        if(element.acrType !== (this.VUE_APP_SITE_ID === '1' ? 'DJ' : 'EJ')){
                                                            modelePossibleRear.push(element)
                                                        }
                                                    }else{
                                                        modelePossibleRear.push(element)
                                                    }
                                                }
                                            }
                                        }
                                    })
                                })
                                var self = this
                                setTimeout(function(){
                                    modelePossibleRear.forEach(element => {
                                        modelePossibleFront.forEach(elementRear => {

                                            if(element.modele === elementRear.modele && elementRear.marque == element.marque &&  modelePossibleFinal.indexOf(element) == -1 && element.couleur == elementRear.couleur){
                                                var resultatFindRear = modelePossibleFinal.find(modeleRear => modeleRear.objectID == element.objectID)
                                                if(resultatFindRear == undefined || resultatFindRear == "undefined"){
                                                    if(element.marque === 'ACR'){
                                                        if(element.acrType !== (this.VUE_APP_SITE_ID === '1' ? 'DJ' : 'EJ')){
                                                            modelePossibleFinal.push(element)
                                                        }
                                                    }else{
                                                        modelePossibleFinal.push(element)
                                                    }
                                                }
                                            }
                                        })
                                    })
                                    var modeleAlgolia = " AND"
                                    modelePossibleFinal.forEach(element => {
                                        if(modeleAlgolia != " AND"){
                                            if(element.objectID.length > 0 && element.objectID !== " "){
                                                modeleAlgolia += " OR "
                                            }
                                        }
                                        if(element.objectID.length > 0 && element.objectID !== " "){
                                            modeleAlgolia += " objectID: '"+ element.objectID + "' "
                                        }
                                    })
                                    if(modelePossibleFinal.length > 0) {
                                        // self.filtreAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset + " AND " + stock +  modeleAlgolia
                                        tamponAlgolia = diametre + " AND " + alesage + " AND " + entraxe + "AND" + offset+ " AND " + stock +  modeleAlgolia
                                        //console.log(tamponAlgolia)
                                    }else{
                                        var diametreZero = 'diametre:0'
                                        self.filtreAlgolia = diametreZero
                                    }
                                }, 1500);
                            })
                        setTimeout(function() {
                            var hitsSecond = []
                            var filterAlgoliaWithObjectId = ""
                            index.browseObjects({
                                query: '', // Empty query will match all records
                                filters: tamponAlgolia + " AND " + ldpFilter + " AND " + filterExclude + " AND visible: 1",
                                batch: batch => {
                                    hitsSecond = hitsSecond.concat(batch);
                                }})
                                .then(() => {
                                    var arrayDistinct = []
                                    hitsSecond.forEach(item => {
                                        if(arrayDistinct.length == 0){

                                            arrayDistinct.push(item)
                                        }
                                        var information = arrayDistinct.filter(elementDistinct => { return elementDistinct.couleur.toString() === item.couleur.toString() && elementDistinct.modele.toString() === item.modele.toString()  })
                                        if(information == 0){

                                            arrayDistinct.push(item)
                                        }
                                    })
                                    arrayDistinct.forEach(element => {
                                        if(filterAlgoliaWithObjectId == ""){
                                            filterAlgoliaWithObjectId = " objectID : '"+ element.objectID +"' "
                                        }else{
                                            filterAlgoliaWithObjectId += "OR  objectID : '"+ element.objectID +"' "
                                        }
                                    })
                                    setTimeout(function() {
                                        _this.UpdateAlgoliaFilter(filterAlgoliaWithObjectId)
                                    }, 500);
                                })
                        }, 4500);
                    }
                },100)

            })

        // var listKtype = (this.$localStorage.get('listKtype')).split(',')
        var listKtype = ls.get('listKtype')
        Vue.axios.get('https://api.espace-jantes.com/infoChassis', {params: {listKtype : listKtype}})
            .then((response) => {
                const _this_dimension = this.dimensions

                response.data.forEach(function(element) {
                    var wheelSizeSplit = element.wheelSize.split('x')

                    if(_this_dimension.indexOf(wheelSizeSplit[1]) == -1 && element.id_chassis == chassisVehiculeUtilisateur.id_chassis){
                        _this_dimension.push(wheelSizeSplit[1])
                    }
                })

                const byValue  = (a,b) => a - b;
                const sorted = [... _this_dimension].sort(byValue)
                this.dimensions = sorted
            })



    },
    created() {
        this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID

    },
    mounted() {

        if(this.$vuetify.breakpoint.mdAndDown)
        {
            this.overlayMenu = true
        }
        this.dimensionChoisi = ls.get('diametre')
    },
    computed:{
        ...mapState({

            SaveMontage: state => state.filtreListingSave.SaveMontage

        })
    }

}

</script>

<style scoped>

.background{
    background-size: contain;
    background-repeat: repeat;
}
.underlined{
    border-bottom: solid
}

.half-underlined {
    /*border-bottom:3px solid #FC5C1F;*/
    position: relative;
}
.half-underlined:after {
    padding: 0;
    margin: 0;
    display: block;
    content: "";
    width: 60%;
    height: 3px;
    background-color: black;
    position: absolute;
    left: 0;
    bottom: 0;
}

.white--text-lg /deep/ label {
    color: white;
    font-size: 12px;
}
.white--text-xl /deep/ label {
    color: white;
    font-size: 14px;
}

.black--text-lg /deep/ label {
    color: black;
    font-size: 12px;
}
.black--text-xl /deep/ label {
    color: black;
    font-size: 14px;
}
.green--text-lg /deep/ label {
    color: #1DE4AF;
    font-size: 12px;
}
.green--text-xl /deep/ label {
    color: #1DE4AF;
    font-size: 14px;
}
.green--text /deep/ label {
    color: #1DE4AF;
    font-size: 14px;
}
.dimensionCss{
    float:left;
    margin-right:5px;
}
.currentEJ{
    color: #1DE4AF!important;
}
.currentDJ{
    color: #000000!important;
    font-weight: bold;
}

@media (min-width: 1264px) and (max-width: 1904px) {
    .fontSize >>> select.ais-MenuSelect-select {
        font-size: 12px!important;
    }
}

</style>
