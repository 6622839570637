<template>

    <v-app fluid >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>


        <v-main v-if="VUE_APP_SITE_ID === '1'">
            <v-col cols="12" class="py-0" v-if="$vuetify.breakpoint.lgAndUp">
                <v-row>
                    <v-col lg="2" class="mt-10" >
                        <p class="text-xl-h2 text-lg-h3 tc_blue font-weight-bold rotate">
                            Contactez-nous
                        </p>
                    </v-col>
                    <v-col lg="5" style="background-color: #FBFBFB">
                        <v-col class="
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">
                            <span v-if="messageSend !== true" class="">Contactez-nous au <span class=" font-weight-bold " ><a class="no_deco tc_turquoise" href="tel:+33970721916">09 70 72 19 16</a> </span> ou via le formulaire suivant :</span>
                            <p v-if="messageSend === true" class="font-weight-bold">Merci de nous avoir contacté, nous prenons en compte votre demande et vous recontacterons dans les plus brefs délais </p>
                            <RouterLink v-if="messageSend === true"  :to="{name: 'Home'}" class="no_deco">
                                <v-btn class="font-weight-bold">Retour a la page d'accueil</v-btn>
                            </RouterLink>
                        </v-col>

                        <v-form v-if="messageSend !== true" style="position: relative;">
                            <v-col>

                                <v-row>
                                    <v-col class="py-0">
                                        <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Prénom <span class="tc_turquoise">*</span></p>
                                        <v-text-field
                                            dense
                                            outlined
                                            background-color="white"
                                            v-model="contact.firstname"
                                            :error-messages="contactFirstnameErrors"
                                            required
                                            @input="$v.contact.firstname.$touch()"
                                            @blur="$v.contact.firstname.$touch()"
                                        />
                                    </v-col>
                                    <v-col class="py-0">
                                        <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Nom <span class="tc_turquoise">*</span></p>
                                        <v-text-field
                                            dense
                                            outlined
                                            background-color="white"
                                            v-model="contact.lastname"
                                            :error-messages="contactLastnameErrors"
                                            required
                                            @input="$v.contact.lastname.$touch()"
                                            @blur="$v.contact.lastname.$touch()"
                                        />
                                    </v-col>
                                </v-row>



                                <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_turquoise">*</span></p>
                                <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="contact.email"
                                    :error-messages="contactEmailErrors"
                                    required
                                    @input="$v.contact.email.$touch()"
                                    @blur="$v.contact.email.$touch()"
                                />
                                <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Téléphone</p>
                                <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="contact.phone"
                                    :error-messages="contactPhoneErrors"
                                    required
                                    @input="$v.contact.phone.$touch()"
                                    @blur="$v.contact.phone.$touch()"
                                />

                                <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Objet de la demande <span class="tc_turquoise">*</span></p>
                                <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="contact.object"
                                    :error-messages="contactObjectErrors"
                                    required
                                    @input="$v.contact.object.$touch()"
                                    @blur="$v.contact.object.$touch()"
                                />

                                <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Message<span class="tc_turquoise">*</span></p>
                                <v-textarea
                                    outlined
                                    no-resize
                                    background-color="white"
                                    v-model="contact.message"
                                    :error-messages="contactMessageErrors"
                                    required
                                    @input="$v.contact.message.$touch()"
                                    @blur="$v.contact.message.$touch()"
                                />




                                <v-btn v-if="!submitted" class="font-weight-bold " dark color="#1840DA" @click="contactSubmit">Envoyer le message</v-btn>
                                <v-btn v-else class="font-weight-bold " dark color="#1840DA" loading>Envoyer le message</v-btn>


                            </v-col>
                        </v-form>
                    </v-col>
                    <v-col class="bc_blue" lg="5">
                        <v-img src="@/assets/AboutUs.png"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-col class="pa-0" v-if="$vuetify.breakpoint.mdAndDown">

                <v-col lg="5" style="background-color: #FBFBFB">
                    <p  class="text-md-h4 text-sm-h5 text-h5 tc_blue font-weight-bold ">
                        Contactez-nous
                    </p>
                    <v-col class="
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">
                        <span v-if="messageSend !== true" class="">Contactez-nous au <span class=" font-weight-bold " ><a class="no_deco tc_turquoise" href="tel:+33970721916">09 70 72 19 16</a> </span> ou via le formulaire suivant :</span>
                        <p v-if="messageSend === true" class="font-weight-bold">Merci de nous avoir contacté, nous prenons en compte votre demande et vous recontacterons dans les plus brefs délais </p>
                        <RouterLink v-if="messageSend === true"  :to="{name: 'Home'}" class="no_deco">
                            <v-btn class="font-weight-bold">Retour a la page d'accueil</v-btn>
                        </RouterLink>
                    </v-col>

                    <v-form v-if="messageSend !== true" style="position: relative;">
                        <v-col>

                            <v-row>
                                <v-col class="py-0">
                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Prénom <span class="tc_turquoise">*</span></p>
                                    <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="contact.firstname"
                                        :error-messages="contactFirstnameErrors"
                                        required
                                        @input="$v.contact.firstname.$touch()"
                                        @blur="$v.contact.firstname.$touch()"
                                    />
                                </v-col>
                                <v-col class="py-0">
                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Nom <span class="tc_turquoise">*</span></p>
                                    <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="contact.lastname"
                                        :error-messages="contactLastnameErrors"
                                        required
                                        @input="$v.contact.lastname.$touch()"
                                        @blur="$v.contact.lastname.$touch()"
                                    />
                                </v-col>
                            </v-row>



                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                dense
                                outlined
                                background-color="white"
                                v-model="contact.email"
                                :error-messages="contactEmailErrors"
                                required
                                @input="$v.contact.email.$touch()"
                                @blur="$v.contact.email.$touch()"
                            />
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Téléphone</p>
                            <v-text-field
                                dense
                                outlined
                                background-color="white"
                                v-model="phone"
                            />

                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Objet de la demande <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                dense
                                outlined
                                background-color="white"
                                v-model="contact.object"
                                :error-messages="contactObjectErrors"
                                required
                                @input="$v.contact.object.$touch()"
                                @blur="$v.contact.object.$touch()"
                            />

                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Message<span class="tc_turquoise">*</span></p>
                            <v-textarea
                                outlined
                                no-resize
                                background-color="white"
                                v-model="contact.message"
                                :error-messages="contactMessageErrors"
                                required
                                @input="$v.contact.message.$touch()"
                                @blur="$v.contact.message.$touch()"
                            />


                            <v-btn v-if="!submitted" class="font-weight-bold " dark color="#1840DA" @click="contactSubmit">Envoyer le message</v-btn>
                            <v-btn v-else class="font-weight-bold " dark color="#1840DA" loading>Envoyer le message</v-btn>

                        </v-col>
                    </v-form>
                </v-col>

            </v-col>
        </v-main>


        <v-main v-else-if="VUE_APP_SITE_ID === '2'">
            <v-col cols="12" class="py-0" v-if="$vuetify.breakpoint.lgAndUp">
                <v-row>
                    <v-col lg="1" class="mt-10" >
                        <p class="text-xl-h2 text-lg-h3 font-weight-bold rotateDJ">
                            Contactez-nous
                        </p>
                    </v-col>
                    <v-col lg="6" style="background-color: #FBFBFB">
                        <v-col class="
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">
                            <span v-if="messageSend !== true" class="">Contactez-nous au <span class=" font-weight-bold " ><a class="no_deco tc_orange" href="tel:+33974191801">09 74 19 18 01</a> </span> ou via le formulaire suivant :</span>
                            <p v-if="messageSend === true" class="font-weight-bold">Merci de nous avoir contacté, nous prenons en compte votre demande et vous recontacterons dans les plus brefs délais </p>
                            <RouterLink v-if="messageSend === true"  :to="{name: 'Home'}" class="no_deco">
                                <v-btn class="font-weight-bold">Retour a la page d'accueil</v-btn>
                            </RouterLink>
                        </v-col>

                        <v-form v-if="messageSend !== true" style="position: relative;">
                            <v-col>

                                <v-row>
                                    <v-col class="py-0">
                                        <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Prénom <span class="tc_orange">*</span></p>
                                        <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="contact.firstname"
                                                :error-messages="contactFirstnameErrors"
                                                required
                                                @input="$v.contact.firstname.$touch()"
                                                @blur="$v.contact.firstname.$touch()"
                                        />
                                    </v-col>
                                    <v-col class="py-0">
                                        <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Nom <span class="tc_orange">*</span></p>
                                        <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="contact.lastname"
                                                :error-messages="contactLastnameErrors"
                                                required
                                                @input="$v.contact.lastname.$touch()"
                                                @blur="$v.contact.lastname.$touch()"
                                        />
                                    </v-col>
                                </v-row>



                                <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_orange">*</span></p>
                                <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="contact.email"
                                        :error-messages="contactEmailErrors"
                                        required
                                        @input="$v.contact.email.$touch()"
                                        @blur="$v.contact.email.$touch()"
                                />
                                <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Téléphone</p>
                                <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="contact.phone"
                                    :error-messages="contactPhoneErrors"
                                    required
                                    @input="$v.contact.phone.$touch()"
                                    @blur="$v.contact.phone.$touch()"
                                />

                                <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Objet de la demande <span class="tc_orange">*</span></p>
                                <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="contact.object"
                                        :error-messages="contactObjectErrors"
                                        required
                                        @input="$v.contact.object.$touch()"
                                        @blur="$v.contact.object.$touch()"
                                />

                                <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Message<span class="tc_orange">*</span></p>
                                <v-textarea
                                        outlined
                                        no-resize
                                        background-color="white"
                                        v-model="contact.message"
                                        :error-messages="contactMessageErrors"
                                        required
                                        @input="$v.contact.message.$touch()"
                                        @blur="$v.contact.message.$touch()"
                                />




                                <v-btn v-if="!submitted" class="font-weight-bold " dark color="#FC5C1F" @click="contactSubmit">Envoyer le message</v-btn>
                                <v-btn v-else class="font-weight-bold " dark color="#FC5C1F" loading>Envoyer le message</v-btn>


                            </v-col>
                        </v-form>
                    </v-col>
                    <v-col class="pa-0" lg="5">
                        <v-img src="@/assets/AboutUsDJ.png"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-col class="pa-0" v-if="$vuetify.breakpoint.mdAndDown">

                <v-col lg="5" style="background-color: #FBFBFB">
                    <p  class="text-md-h4 text-sm-h5 text-h5 tc_darkOrange font-weight-bold ">
                        Contactez-nous
                    </p>
                    <v-col class="
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">
                        <span v-if="messageSend !== true" class="">Contactez-nous au <span class=" font-weight-bold " ><a class="no_deco tc_orange" href="tel:+33974191801">09 74 19 18 01</a> </span> ou via le formulaire suivant :</span>
                        <p v-if="messageSend === true" class="font-weight-bold">Merci de nous avoir contacté, nous prenons en compte votre demande et vous recontacterons dans les plus brefs délais </p>
                        <RouterLink v-if="messageSend === true"  :to="{name: 'Home'}" class="no_deco">
                            <v-btn class="font-weight-bold">Retour a la page d'accueil</v-btn>
                        </RouterLink>
                    </v-col>

                    <v-form v-if="messageSend !== true" style="position: relative;">
                        <v-col>

                            <v-row>
                                <v-col class="py-0">
                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Prénom <span class="tc_orange">*</span></p>
                                    <v-text-field
                                            dense
                                            outlined
                                            background-color="white"
                                            v-model="contact.firstname"
                                            :error-messages="contactFirstnameErrors"
                                            required
                                            @input="$v.contact.firstname.$touch()"
                                            @blur="$v.contact.firstname.$touch()"
                                    />
                                </v-col>
                                <v-col class="py-0">
                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Nom <span class="tc_orange">*</span></p>
                                    <v-text-field
                                            dense
                                            outlined
                                            background-color="white"
                                            v-model="contact.lastname"
                                            :error-messages="contactLastnameErrors"
                                            required
                                            @input="$v.contact.lastname.$touch()"
                                            @blur="$v.contact.lastname.$touch()"
                                    />
                                </v-col>
                            </v-row>



                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_orange">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="contact.email"
                                    :error-messages="contactEmailErrors"
                                    required
                                    @input="$v.contact.email.$touch()"
                                    @blur="$v.contact.email.$touch()"
                            />
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Téléphone</p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="phone"
                            />

                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Objet de la demande <span class="tc_orange">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="contact.object"
                                    :error-messages="contactObjectErrors"
                                    required
                                    @input="$v.contact.object.$touch()"
                                    @blur="$v.contact.object.$touch()"
                            />

                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Message<span class="tc_orange">*</span></p>
                            <v-textarea
                                    outlined
                                    no-resize
                                    background-color="white"
                                    v-model="contact.message"
                                    :error-messages="contactMessageErrors"
                                    required
                                    @input="$v.contact.message.$touch()"
                                    @blur="$v.contact.message.$touch()"
                            />



                            <v-btn v-if="!submitted" class="font-weight-bold " dark color="#FC5C1F" @click="contactSubmit">Envoyer le message</v-btn>
                            <v-btn v-else class="font-weight-bold " dark color="#FC5C1F" loading>Envoyer le message</v-btn>

                        </v-col>
                    </v-form>
                </v-col>

        </v-col>
        </v-main>

        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>

    import HomepageHeaderEj from "../headers/HomepageHeaderEj";
    import HomepageHeaderDj from "../headers/HomepageHeaderDj";
    import HomepageFooterEj from "../footer/HomepageFooterEj";
    import HomepageFooterDj from "../footer/HomepageFooterDj";

    const { validationMixin } = require('vuelidate')
    const { required, email, numeric, minLength, maxLength } = require('vuelidate/lib/validators')

    import Vue from 'vue'
    import axios from 'axios'
    import VueAxios from 'vue-axios'

    Vue.use(VueAxios, axios)

    export default {
        name: "AboutUs",
        components:{
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,
        },
        mixins: [validationMixin],

        validations: {
            contact: {
                firstname: {required},
                lastname: {required},
                phone: {required, numeric, minLength: minLength(10), maxLength: maxLength(10)},
                email: {required, email},
                object: {required},
                message : {required}
            },

        },
        data: () => ({
            contact:{},
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            object:'',
            message:'',
            contactForm: {},
            submitted:false,
            messageSend:false,
            VUE_APP_SITE_ID:null,
        }),
        metaInfo() {
            return {
                title: `Contactez-nous - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + `  - Jantes, Pneus & Accessoires`,
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `Contactez-nous - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {name: 'twitter:description', content: `Découvrez les engagements ` + (this.VUE_APP_SITE_ID === '1' ? `d'Espace Jantes` : `de Distri Jantes`) + `, le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.`},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `Contactez-nous -` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {property: 'og:site_name', content: (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`)},
                    {property: 'og:type', content: 'Website'},
                    {property: 'og:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},
                    {property: 'og:description', content: `Contactez-nous ! ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    {name: 'robots', content: 'index,follow'}
                ]
            }
    },

        computed: {

            contactFirstnameErrors () {
                const errors = [];
                if (!this.$v.contact.firstname.$dirty) return errors
                // !this.$v.contact.firstname.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.contact.firstname.required && errors.push('Le prénom est obligatoire')
               return errors

            },
            contactLastnameErrors () {
                const errors = [];
                if (!this.$v.contact.lastname.$dirty) return errors
                    // !this.$v.contact.lastname.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.contact.lastname.required && errors.push('Le nom est obligatoire')
                return errors

            },
            contactEmailErrors () {
                const errors = [];
                if (!this.$v.contact.email.$dirty) return errors
                !this.$v.contact.email.email && errors.push('Format d\'email invalide')
                !this.$v.contact.email.required && errors.push('E-mail obligatoire')
                return errors
            },
            contactObjectErrors () {
                const errors = [];
                if (!this.$v.contact.object.$dirty) return errors
                !this.$v.contact.object.required && errors.push('Objet obligatoire')
                return errors
            },
            contactMessageErrors () {
                const errors = [];
                if (!this.$v.contact.message.$dirty) return errors
                !this.$v.contact.message.required && errors.push('Message obligatoire')
                return errors
            },
            contactPhoneErrors () {
                const errors = [];
                if (!this.$v.contact.phone.$dirty) return errors
                // !this.$v.contact.phone.required && errors.push('Téléphone obligatoire')
                !this.$v.contact.phone.minLength && errors.push('Téléphone invalide')
                !this.$v.contact.phone.maxLength && errors.push('Téléphone invalide')
                return errors
            },


        },

        methods: {

            contactSubmit () {
                this.$v.contact.$touch()
                this.submitted = true
                if(this.$v.contact.firstname.$invalid || this.$v.contact.lastname.$invalid || this.$v.contact.email.$invalid || this.$v.contact.object.$invalid || this.$v.contact.message.$invalid)
                {
                    this.submitted = false
                    return false
                }
                this.contactForm.firstname = this.$v.contact.firstname.$model
                this.contactForm.lastname = this.$v.contact.lastname.$model
                this.contactForm.email = this.$v.contact.email.$model
                this.contactForm.object = this.$v.contact.object.$model
                this.contactForm.message = this.$v.contact.message.$model

                if(this.$v.contact.phone.$model !== "" && this.$v.contact.phone.$model !== undefined) {
                    this.contactForm.phone = this.$v.contact.phone.$model
                }else{
                    this.contactForm.phone = ""
                }


                Vue.axios.post('https://api.espace-jantes.com/demande_form_contact', {
                    params: {
                        nom: this.contactForm.lastname,
                        prenom: this.contactForm.firstname,
                        mail: this.contactForm.email,
                        telephone: this.contactForm.phone,
                        message: this.contactForm.message,
                        objet: this.contactForm.object,
                        id_website:this.VUE_APP_SITE_ID,
                    }
                })
                .then((response) => {
                    if(response.data.etat === true){
                        this.submitted = false
                        //Redirection vers page accueil
                        this.messageSend = true
                    }else{
                        this.submitted = false
                    }
                })
            },
        },
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        }
    }
</script>

<style scoped>
    .border_left{
        border-left: 2px solid #1DE4AF
    }
    .rotate {
        transform: rotate(-90deg);
        margin-left: -5%;
        margin-top: 12%;
        position: absolute;
    }
    .rotateDJ {
        transform: rotate(-90deg);
        margin-left: -9%;
        margin-top: 12%;
        position: absolute;
    }
</style>
