import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import Vuelidate from 'vuelidate';
import InstantSearch from 'vue-instantsearch';
import VueLocalStorage from 'vue-localstorage';
// import qs from 'qs';
import store from './store'
import Meta from 'vue-meta';
import PicZoom from 'vue-piczoom'
// eslint-disable-next-line no-unused-vars
import VueGtm from '@gtm-support/vue2-gtm';

import browserDetect from "vue-browser-detect-plugin";

Vue.use(browserDetect);
Vue.use(PicZoom)
Vue.use(Meta);
Vue.use(Vuelidate);
Vue.use(VueRouter);
Vue.use(InstantSearch);
Vue.use(VueLocalStorage);
Vue.use(VueGtm, { id: process.env.VUE_APP_GTM_ID, defer: false, enabled: true, debug: true, loadScript: true, trackOnNextTick: false, })


import HomepageEj from "./components/HomepageEj";
import HomepageDj from "./components/HomepageDj";
import Listing from "./components/Listing";
import MyAccount from "./components/account/MyAccount";
import Forgot from "./components/Listing"
import Etape1 from "./components/configurateur/Etape1";
import Etape2 from "./components/configurateur/Etape2";
import Etape3 from "./components/configurateur/Etape3";
import Etape4 from "./components/configurateur/Etape4";
import Etape5 from "./components/configurateur/Etape5";
import AboutUs from "./components/static/AboutUs";
import NosEngagements from "./components/static/NosEngagements";
import Livraison from "./components/static/Livraison";
import ContactUs from "./components/static/ContactUs";
import JantesDescription from "./components/static/JantesDescription";
import JantesFabrication from "./components/static/JantesFabrication";
import JantesMonobloc from "./components/static/JantesMonobloc";
import JantesMateriaux from "./components/static/JantesMateriaux";
import JantesOptions from "./components/static/JantesOptions";
import JantesEntretien from "./components/static/JantesEntretien";
import JantesConseils from "./components/static/JantesConseils";
import JantesPermutation from "./components/static/JantesPermutation";
import NotFound from "./components/static/404";
import CatalogueJantes from "./components/CatalogueJantes";
import MentionsLegales from "./components/static/MentionsLegales";
import CGV from "./components/static/CGV";
import Cart from "@/components/account/Cart";
import MarquesJantesEtPneus from "@/components/static/MarquesJantesEtPneus";
// import Etape6 from "@/components/configurateur/Etape6";
import AccesRevendeurs from "@/components/account/AccesRevendeurs";
import DetailsJante from "@/components/DetailsJante";
import Etape6_bis from "@/components/configurateur/Etape6_bis";
import FAQ from "@/components/static/FAQ";
import Etape6_success from "@/components/configurateur/Etape6_success";
import Etape6_error from "@/components/configurateur/Etape6_error";

const routes = [

    {path:'/', name:'Home', component: process.env.VUE_APP_SITE_ID === "1" ? HomepageEj : HomepageDj},
    // {path:'/', name:'Home', component: Homepage,meta: { showChat: true }},
    {path: '/account', name:'Account', component:MyAccount},
    {path: '/forgot', name:'Forgot', component:Forgot},

    {path: '/catalogue-jantes/:marque', name:'catalogue', component:CatalogueJantes},
    {path: '/catalogue-jantes/:marque/:modele', name:'catalogueModele', component:CatalogueJantes},
    {path: '/catalogue-jantes/:marque/:modele/:type', name:'catalogueType', component:CatalogueJantes},
    // {path: '/catalogue-jantes/:marque/:modele/:type/:motorisation', name:'catalogueMotorisation', component:CatalogueJantes},
    // {path: '/catalogue-jantes/:marque/:modele/:type/:motorisation/:dimension', name:'catalogueDimension', component:CatalogueJantes},
    {path: '/catalogue-jantes/:marque/:modele/:type/:dimension', name:'catalogueDimension', component:CatalogueJantes},

    {path: '/listing', name:'Listing', component:Listing},

    // {path: '/produit/:jante', name:'DetailsJante', component:DetailsJante},
    {path: '/produit/:marque/:modele/:diametre/:couleur/:decale', name:'DetailsJante', component:DetailsJante},

    {path: '/panier', name:'Panier', component: Cart},
    {path: '/acces-revendeurs', name:'Revendeurs', component: AccesRevendeurs},

    {path: '/etape1/:marque/:modele/:diametre/:couleur/:decale', name:'Etape1', component:Etape1},
    {path: '/etape2/:marque/:modele/:diametre/:couleur/:decale', name:'Etape2', component:Etape2},
    {path: '/etape3/:marque/:modele/:diametre/:couleur/:decale', name:'Etape3', component:Etape3},
    {path: '/etape4/:marque/:modele/:diametre/:couleur/:decale', name:'Etape4', component:Etape4},
    {path: '/etape5/:marque/:modele/:diametre/:couleur/:decale', name:'Etape5', component:Etape5},
    // {path: '/etape6/:marque/:modele/:diametre/:couleur/:decale', name:'Etape6', component:Etape6},
    {path: '/etape6-mE62-9EJ-iAs-gQ-Bm98/:marque/:modele/:diametre/:couleur/:decale', name:'Etape6Success', component:Etape6_success},  //etape6-mE62-9EJ-iAs-gQ-Bm98 is to avoid people who are on etape6_ko to just delete "_ko" in url and validate order
    {path: '/etape6_ko/:marque/:modele/:diametre/:couleur/:decale', name:'Etape6Error', component:Etape6_error},
    {path: '/etape6-mE62-9EJ-iAs-gQ-Bm98/:marque/:modele/:diametre/:couleur/:decale', name:'Etape6Bis', component:Etape6_bis},


    {path:'/qui-sommes-nous', name:'AboutUs', component: AboutUs},
    {path:'/nos-engagements', name:'NosEngagements', component: NosEngagements},
    {path:'/livraison', name:'Livraison', component: Livraison},
    {path:'/contact', name:'ContactUs', component: ContactUs},
    {path:'/jantes-description', name:'JantesDescription', component: JantesDescription},
    {path:'/jantes-fabrication', name:'JantesFabrication', component: JantesFabrication},
    {path:'/jantes-monobloc', name:'JantesMonobloc', component: JantesMonobloc},
    {path:'/jantes-materiaux', name:'JantesMateriaux', component: JantesMateriaux},
    {path:'/jantes-options', name:'JantesOptions', component: JantesOptions},
    {path:'/jantes-entretien', name:'JantesEntretien', component: JantesEntretien},
    {path:'/jantes-conseils', name:'JantesConseils', component: JantesConseils},
    {path:'/jantes-permutation', name:'JantesPermutation', component: JantesPermutation},
    {path:'/mentions-legales-cgu', name:'MentionsLegales', component: MentionsLegales},
    {path:'/conditions-generales-de-vente', name:'CGV', component: CGV},
    {path:'/foire-aux-questions', name:'FAQ', component: FAQ},
    {path:'/nos-marques-de-jantes-et-pneus', name:'JantesEtPneus', component: MarquesJantesEtPneus},

    { path: '/404', name:'404', component: NotFound },
    { path: '*', redirect: '/404' },
];


const router = new VueRouter({
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    mode:'history',
  routes
});

router.afterEach((to) => {
    Vue.prototype.$tidioChatApi &&
    Vue.prototype.$tidioChatApi.display(to.meta.showChat);
});


Vue.config.productionTip = false;

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
}).$mount('#app')
