<template>
    <div id="app" data-server-rendered="true">
        <v-app  fluid>

<!--        <v-col class="py-0">-->
        <HomepageHeader />
<!--        </v-col>-->


        <TopHomePage :class="{'bc_white' : $vuetify.breakpoint.smAndDown}" />



        <v-col class="bc_white">

            <h2 style="font-size: x-large; font-weight: bold;padding-left: 8%;padding-bottom: 2%;padding-top: 3%"  class="font-weight-bold
                                    text-xl-h4
                                    text-lg-h6
                                    text-md-h6
                                    text-sm-h6
                                    text-subtitle-1">Nos marques de jantes et pneus</h2>

            <!--                    <v-row style="padding-left: 8%; align-items: center">-->
            <v-row :style="[$vuetify.breakpoint.mdAndUp ? {'padding-left': '8%'} : '']" style="align-items: center">

                <v-col cols="3" sm="2" lg="1" v-for="brand in brands" :key="brand.img" class="align-self-center">
                    <v-hover>
                        <template v-slot="{hover}">
                            <v-card :elevation=" hover ? 0 : 0"   class="py-6">
                                <v-img :src="brand.img" :alt="brand.alt" :title="brand.alt" >
                                    <div v-if="hover" style="height: 100%;" >
                                        <v-img :src="brand.imgColor" :alt="brand.alt" :title="brand.alt"  height="100%"/>
                                    </div>
                                </v-img>
                            </v-card>
                        </template>
                    </v-hover>
                </v-col>

                <v-col v-if="$vuetify.breakpoint.mdAndUp" offset="2">
                    <RouterLink :to="{name: 'JantesEtPneus'}" class="no_deco">
                        <v-btn v-if="$vuetify.breakpoint.xl" outlined style="font-weight: bold" >En voir plus</v-btn>
                        <v-btn v-if="$vuetify.breakpoint.lgAndDown" small outlined style="font-weight: bold" >En voir plus</v-btn>
                    </RouterLink>
                </v-col>

                <v-col v-if="$vuetify.breakpoint.smAndDown" class="text-center" >
                    <RouterLink :to="{name: 'JantesEtPneus'}" class="no_deco">
                        <v-btn small outlined style="font-weight: bold; margin-left: auto; margin-right: auto" >En voir plus</v-btn>
                    </RouterLink>
                </v-col>

            </v-row>

        </v-col>

        <v-content class="bc_white">
            <v-col offset-xl="3" offset-lg="3" offset-md="2" offset-sm="1" xl="7" lg="7" md="7" sm="10" class="tc_turquoise font-weight-bold pr-0
                                            text-xl-h3
                                            text-lg-h5
                                            text-md-h6
                                            text-sm-h6
                                            text-subtitle-1" style=" padding-bottom: 3%;padding-top: 3%"
                   >
                "À vos côtés depuis plus de 10 ans !"
            </v-col>
        </v-content>

        <v-container fluid class="bc_lightGrey">
            <v-row style="padding-top: 1%;padding-bottom: 1%">

                <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                    <v-img src="@/assets/phone.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                     $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                     $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                     $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                     $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                </v-col>
                <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold tc_blue align-self-center
                                            text-xl-h3
                                            text-lg-h5
                                            text-md-h5
                                            text-sm-h5
                                            text-subtitle-1">
                    <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a>
                </v-col>
                <v-col cols="7" sm="6" md="4" lg="3" xl="3" class="px-1 font-weight-bold align-self-center">
                    <v-col class="font-weight-bold pa-0
                                text-xl-h5
                                text-lg-subtitle-1
                                text-md-subtitle-2
                                text-sm-subtitle-2
                                text-caption">
                        <h2>
                            Commandez par téléphone
                        </h2>
                    </v-col>
                    <v-col class="font-weight-bold pa-0
                                text-xl-h5
                                text-lg-subtitle-1
                                text-md-subtitle-2
                                text-sm-subtitle-2
                                text-caption">
                        <h2>
                            Nous vous accompagnons !
                        </h2>
                    </v-col>
                </v-col>
                <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2" offset-lg="1" xl="3" lg="3">
                    <v-img style="position: absolute; width: 20%;" src="@/assets/illustration-man.png"/>
                </v-col>
            </v-row>
        </v-container>



        <v-content  class="bc_white" style="padding-bottom: 5%;">
            <v-col offset-xl="2" offset-lg="3" offset-md="2" offset-sm="1" xl="7" lg="7" md="7" sm="10" class="tc_turquoise font-weight-bold pr-0
                                            text-xl-h3
                                            text-lg-h5
                                            text-md-h6
                                            text-sm-h6
                                            text-subtitle-1" style=" padding-bottom: 3%;padding-top: 3%"
            >
                Montage offert : pack complet, prêt à rouler !
            </v-col>
        </v-content>


        <v-content class="bc_white">

                <v-col offset-lg="1" offset-xl="2" lg="10" xl="8">
                    <v-row >
                        <v-col v-for="card in cardsEJ" :key="card.title" lg="3" xl="3" md="3" sm="3" cols="6" class="px-2" >
                            <v-card style=" height: 100%" hover >
                                <v-card-text class="text-center align-self-center justify-center">
                                    <v-content class="px-5">
                                        <v-img  :src="card.img"></v-img>
                                    </v-content>
                                    <div class="tc_turquoise
                                        font-weight-bold
                                        text-xl-h5
                                        text-lg-h6
                                        text-md-subtitle-1
                                        text-sm-body-2
                                        text-caption" style="margin-top: 15%">
                                        {{card.title}}
                                    </div>
                                    <p class="text--primary
                                        font-weight-regular
                                        text-xl-h5
                                        text-lg-subtitle-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption" style="color:black;padding-top:4%">
                                        {{card.text}}
                                    </p>
                                </v-card-text>
                            </v-card>

                        </v-col>

                    </v-row>
                </v-col>
        </v-content>


        <v-container fluid  class="bc_white" style="padding-top: 3%; padding-bottom: 3%">

            <v-row>

                <v-col offset-xl="2" offset-lg="1"  xl="4" lg="5" md="6" sm="6" cols="12" class="text-center" >

                    <iframe v-if="$vuetify.breakpoint.xl" id='AV_widget_iframe' frameBorder="0" width="600" height="715" src="//cl.avis-verifies.com/fr/cache/f/e/3/fe3802be-cb49-e394-4528-a856288623cb/widget4/fe3802be-cb49-e394-4528-a856288623cbindex.html"></iframe>
                    <iframe v-if="$vuetify.breakpoint.lg" id='AV_widget_iframe' frameBorder="0" width="500" height="715" src="//cl.avis-verifies.com/fr/cache/f/e/3/fe3802be-cb49-e394-4528-a856288623cb/widget4/fe3802be-cb49-e394-4528-a856288623cbindex.html"></iframe>
                    <iframe v-if="$vuetify.breakpoint.md" id='AV_widget_iframe' frameBorder="0" width="500" height="715" src="//cl.avis-verifies.com/fr/cache/f/e/3/fe3802be-cb49-e394-4528-a856288623cb/widget4/fe3802be-cb49-e394-4528-a856288623cbindex.html"></iframe>
                    <iframe v-if="$vuetify.breakpoint.smAndDown" id='AV_widget_iframe' frameBorder="0" width="300" height="715" src="//cl.avis-verifies.com/fr/cache/f/e/3/fe3802be-cb49-e394-4528-a856288623cb/widget4/fe3802be-cb49-e394-4528-a856288623cbindex.html"></iframe>
                </v-col>

                <v-col offset-xl="1" offset-lg="1" xl="3" lg="4" md="6" sm="6">
                    <v-col class="text__img" style="color:red">
                        <v-img class="mx-auto mb-9" style="max-width: 15%;" src="@/assets/logo/auto-plus.png"/>
                    </v-col>
                    <p class="headline" >AUTO PLUS parle de nous...</p>

                    <v-card class="mx-auto">
                        <v-img src="@/assets/auto-plus_article.jpeg"/>
                        <v-card-title style="word-break: break-word;">Espace Jantes, plus de 30 000 références de jantes</v-card-title>
                        <v-card-text style="text-align: initial;">
                            Le site Espace Jantes est spécialisé depuis 10 ans dans la vente de jantes par correspondance. Ce pure player - entreprise présente uniquement sur le web - livre ses produits à l'adresse souhaitée...
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="deep-purple accent-4"
                                href="https://www.autoplus.fr/actualite/jantes-jantes-alu-jantes-audi-pneus-roue-1545700.html"
                                target="_blank"
                            >
                                Consulter
                            </v-btn>
                        </v-card-actions>
                    </v-card>

                </v-col>
            </v-row>
        </v-container>



        <HomepageFooter/>

    </v-app>
    </div>
</template>

<script>
    import HomepageHeader from "./headers/HomepageHeaderEj";
    import TopHomePage from "./TopHomePage";
    import HomepageFooter from "./footer/HomepageFooterEj";
    import cardCbEJ from "@/assets/card-cbEJ.svg";
    import cardDeliveryEJ from "@/assets/card-deliveryEJ.svg";
    import cardGuaranteeEJ from "@/assets/card-guaranteeEJ.svg";
    import cardRefundedEJ from "@/assets/card-refundedEJ.svg";
    import bridgestone_nb from '@/assets/logo/bridgestone-NB.png'
    import bridgestone_color from '@/assets/logo/bridgestone.png'
    import gmp_nb from '@/assets/logo/gmp-NB.jpg'
    import gmp_color from '@/assets/logo/gmp.jpg'
    import hankook_nb from '@/assets/logo/Logos-EJ-hancook-NB.jpg'
    import hankook_color from '@/assets/logo/Logos-EJ-hancook.jpg'
    import pirelli_nb from '@/assets/logo/Logos-EJ-pirelli-NB.jpg'
    import pirelli_color from '@/assets/logo/Logos-EJ-pirelli.jpg'
    import continental_nb from '@/assets/logo/Logos-EJ-continental-NB.jpg'
    import continental_color from '@/assets/logo/Logos-EJ-continental.jpg'
    import michelin_nb from '@/assets/logo/Logos-EJ-michelin-NB.jpg'
    import michelin_color from '@/assets/logo/Logos-EJ-michelin.jpg'
    import japanRacing_nb from '@/assets/logo/Japan-Racing-Wheels-NB.jpg'
    import japanRacing_color from '@/assets/logo/Japan-Racing-Wheels.jpg'
    import ozRacing from '@/assets/logo/oz racing.png'



    export default {
        name: "HomepageEj",

        components:{
            HomepageHeader,
            HomepageFooter,
            TopHomePage,
        },
        data: () => ({
            brands:[
                {img:michelin_nb, imgColor:michelin_color, alt:'Michelin'},
                {img:japanRacing_nb, imgColor:japanRacing_color, alt:'JapanRacing'},
                {img:bridgestone_nb, imgColor:bridgestone_color, alt:'Bridgestone'},
                {img:hankook_nb, imgColor: hankook_color, alt:'Hankook'},
                {img:gmp_nb, imgColor:gmp_color, alt:'GMP'},
                {img:continental_nb, imgColor: continental_color, alt:'Continental'},
                {img:ozRacing, alt:'OzRacing'},
                {img:pirelli_nb, imgColor: pirelli_color, alt:'Pirelli'},

            ],
            cardsEJ: [
                {title:'Facilités de paiement',text:'En 4x par CB, sans justificatifs\n Accord immédiat!',img:cardCbEJ},
                {title:'Livraison gratuite', text:'Avec suivi des commandes', img:cardDeliveryEJ},
                {title:'Satisfait ou remboursé', text:'Délai de 14 jours de rétractation', img:cardRefundedEJ},
                {title:'Jantes garanties et controlées', text:'2 ans', img:cardGuaranteeEJ}
            ],
            colors: [
                'indigo',
                'warning',
                'pink darken-2',
                'red lighten-1',
                'deep-purple accent-4',
            ],
            slides: [
                'First',
                'Second',
                'Third',
                'Fourth',
                'Fifth',
            ],
        }),
        mounted() {
            if( process.env.NODE_ENV.trim() !== "development") {
                window.dataLayer.push({
                    'event': 'Pageview',
                    'pagePath': '/',
                    'pageTitle': 'Homepage'
                });
            }
            let jqueryScript = document.createElement('script');
            jqueryScript.setAttribute('src', 'https://code.jquery.com/jquery-3.5.1.min.js');
            document.head.appendChild(jqueryScript)

            let widgetScript = document.createElement('script');
            widgetScript.setAttribute('src', '//cl.avis-verifies.com/fr/widget4/widget20_FB3_nojquery.min.js');
            document.head.appendChild(widgetScript)
        },
    }
</script>

<style scoped>
    .card-xl{
        max-width:300px;
        min-height:350px
    }
    .card-lg{
        max-width:270px;
        min-height:320px
    }
</style>

<style>
    html {
        overflow-x: hidden !important;
    }
</style>
