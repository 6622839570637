<template>
<v-content >
    <v-col >
        <v-col>
            <v-card>
                <v-col class="border-top">
                    <v-row class="
                       text-xl-h6
                       text-lg-subtitle-1
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">
                        <v-col lg="11" class="tc_grey pt-0">
                            <v-row>
                                <v-col offset-lg="1" lg="10" cols="12" class="pb-0">
                                    <v-row>
                                        <v-col class="py-0">
                                            <div class="text__img">
                                                <span class="font-weight-bold ">Votre véhicule est-il équipé de valves électroniques ?<span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}"> *</span></span>
                                                <v-img v-if="VUE_APP_SITE_ID === '1'" @click="emitValves" src="@/assets/icons/icon_infos.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '6%'} :
                                                                                                                         $vuetify.breakpoint.lg ? {'max-width' : '6%'} :
                                                                                                                         $vuetify.breakpoint.md ? {'max-width' : '6%'} :
                                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '6%'} :
                                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '40%'} : '']"
                                                class="pointer"/>
                                                <v-img v-if="VUE_APP_SITE_ID === '2'" @click="emitValves" src="@/assets/icons/icon_infos_orange.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '6%'} :
                                                                                                                         $vuetify.breakpoint.lg ? {'max-width' : '6%'} :
                                                                                                                         $vuetify.breakpoint.md ? {'max-width' : '6%'} :
                                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '6%'} :
                                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '10%'} : '']"
                                                class="pointer"/>
                                            </div>
                                            <v-radio-group
                                                v-model="infos.valves"
                                                :error-messages="valvesErrors"
                                                required
                                                @change="testChange"
                                                @blur="$v.infos.valves.$touch()"
                                                row>
                                                <v-radio label="Oui" value="1"/>
                                                <v-radio label="Non" value="0"/>
                                            </v-radio-group>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-0 pr-0">
                                            <span class="font-weight-bold ">Veuillez préciser la date de mise en circulation <span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">*</span></span>
                                            <v-row>
                                                <v-col cols="5" lg="5">
                                                    <v-select
                                                        solo
                                                        v-model="infos.mois"
                                                        :items="mois"
                                                        :error-messages="moisErrors"
                                                        label="Mois"
                                                        required
                                                        @change="$v.infos.mois.$touch()"
                                                        @blur="$v.infos.mois.$touch()"
                                                    ></v-select>
                                                </v-col>
                                                <v-col cols="6" lg="5">
                                                    <v-select
                                                        solo
                                                        v-model="infos.annee"
                                                        :items="annees"
                                                        :error-messages="anneeErrors"
                                                        label="Année"
                                                        required
                                                        @change="$v.infos.annee.$touch()"
                                                        @blur="$v.infos.annee.$touch()"
                                                    ></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                    <v-row>

                                    </v-row>
                                </v-col>

                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-card>
        </v-col>


        <v-col>
            <v-card >
                <v-row v-if="$vuetify.breakpoint.smAndUp">
                    <v-col offset-lg="1" lg="4" cols="5" class="border-right">
                        <v-card-text>
                            <span  class="font-weight-bold "  :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">Détails de facturation </span><span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">*</span>
                        </v-card-text>
                    </v-col>
                    <v-col offset-lg="1" lg="5" cols="5" align-self="center" class="pa-0">
                        <v-card-text>Pour émission de votre facture</v-card-text>
                    </v-col>
                </v-row>
                <v-col v-if="$vuetify.breakpoint.xs">
                        <v-card-text>
                            <span  class="font-weight-bold "  :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">Détails de facturation </span><span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">*</span>
                            <br>
                            <span>Pour émission de votre facture</span>
                        </v-card-text>
                </v-col>

                <v-col class="border-top">
                    <v-row>
                        <v-col lg="11" class="tc_grey pt-0">
                            <v-row>
                                <v-col offset-lg="1" lg="5" cols="12" class="pb-0">

                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Nom <span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">*</span></p>
                                            <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="facturation.nom"
                                                :error-messages="facturationLastnameErrors"
                                                required
                                                @input="$v.facturation.nom.$touch()"
                                                @blur="$v.facturation.nom.$touch()"
                                                class="textboxFormulaire"
                                            />
                                        </v-col>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Prénom <span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">*</span></p>
                                            <v-text-field
                                                    dense
                                                    outlined
                                                    background-color="white"
                                                    v-model="facturation.prenom"
                                                    :error-messages="facturationFirstnameErrors"
                                                    required
                                                    @input="$v.facturation.prenom.$touch()"
                                                    @blur="$v.facturation.prenom.$touch()"
                                                    class="textboxFormulaire"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Numéro de voie et nom de la rue <span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">*</span></p>
                                            <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="facturation.adresse"
                                                :error-messages="facturationAddressErrors"
                                                required
                                                @input="$v.facturation.adresse.$touch()"
                                                @blur="$v.facturation.adresse.$touch()"
                                                class="textboxFormulaire"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Bâtiment, appartement, lot,... <span class="font-weight-regular">(facultatif)</span></p>
                                            <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="facturation.complement_adresse"
                                                class="textboxFormulaire"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Code postal <span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">*</span></p>
                                            <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="facturation.code_postal"
                                                :error-messages="facturationPostalCodeErrors"
                                                required
                                                @input="$v.facturation.code_postal.$touch()"
                                                @blur="$v.facturation.code_postal.$touch()"
                                                class="textboxFormulaire"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Ville <span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">*</span></p>
                                            <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="facturation.ville"
                                                :error-messages="facturationCityErrors"
                                                required
                                                @input="$v.facturation.ville.$touch()"
                                                @blur="$v.facturation.ville.$touch()"
                                                class="textboxFormulaire"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col lg="6" class="py-0">
                                            <p class="mb-0 font-weight-bold " >Téléphone <span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">*</span></p>
                                            <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="facturation.telephone"
                                                :error-messages="facturationPhoneErrors"
                                                required
                                                @input="$v.facturation.telephone.$touch()"
                                                @blur="$v.facturation.telephone.$touch()"
                                                class="textboxFormulaire"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col offset-lg="1" lg="5" cols="12" :class="{'pt-0': $vuetify.breakpoint.smAndDown}">
                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Nom de l'entreprise <span class="font-weight-regular">(facultatif)</span></p>
                                            <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="facturation.societe"
                                                class="textboxFormulaire"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Pays <span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">*</span></p>

                                            <v-select
                                                outlined
                                                dense
                                                v-model="facturation.pays"
                                                :items="countries"
                                                :error-messages="facturationCountryErrors"
                                                required
                                                @change="$v.facturation.pays.$touch()"
                                                @blur="$v.facturation.pays.$touch()"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-checkbox v-model="adresseLivr"   :class="{'coloredEJ': VUE_APP_SITE_ID === '1', 'coloredDJ': VUE_APP_SITE_ID === '2'}" label="EXPÉDIER À UNE ADRESSE DIFFÉRENTE ?" value="true"  @click.native="anchor"/>

                                    <v-row  v-if="!adresseLivr">
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Notes de commande <span class="font-weight-regular">(facultatif)</span></p>
                                            <v-textarea
                                                outlined
                                                no-resize
                                                v-model="facturationNotes"
                                                label="Commentaires concernant votre commande,ex. : consignes de livraisons"
                                            />
                                        </v-col>
                                    </v-row>

                                </v-col>
                            </v-row>
                            <v-row class="justify-end">
                                <v-btn v-if="!adresseLivr && !isSubmitted" dark  :class="{'bc_blue': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2'}" class="mr-4 buttonPaiement" @click="facturationSubmit">Passer au paiement</v-btn>
                                <v-btn v-if="isSubmitted && !adresseLivr" dark :class="{'bc_blue': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2'}" loading class="mr-4 text-right buttonPaiement">Passer au paiement</v-btn>
                            </v-row>
                            <v-row class="justify-end">
                                <span v-if="isError && !adresseLivr" class="mt-5" style="color: red">Veuillez remplir tout les champs obligatoires correctement.</span>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-card>
        </v-col>

        <v-col id="anchor" v-if="adresseLivr">
            <v-card>
                <v-row v-if="$vuetify.breakpoint.smAndUp"  @click="switchLivraison" id="livraison_div">
                    <v-col offset-lg="1" lg="4" cols="5" class="border-right">
                        <v-card-text>
                            <span class="font-weight-bold "  :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">Adresse de livraison</span>
                        </v-card-text>
                    </v-col>
                    <v-col offset-lg="1" lg="5" cols="5" align-self="center" class="pa-0">
                        <v-card-text>Expédier à une adresse différente ?</v-card-text>
                    </v-col>
                </v-row>

                <v-col v-if="$vuetify.breakpoint.xs"  @click="switchLivraison" id="livraison_div">
                        <v-card-text>
                            <span class="font-weight-bold "  :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">Adresse de livraison</span>
                            <br>
                            <span>Expédier à une adresse différente ?</span>
                        </v-card-text>
                </v-col>
                <v-col class="border-top">
                    <v-row>
                        <v-col lg="11" class="tc_grey pt-0">
                            <v-row>
                                <v-col offset-lg="1" lg="5" cols="12" class="pb-0">

                                    <v-row>

                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Nom <span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">*</span></p>
                                            <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="livraison.nom"
                                                :error-messages="livraisonLastnameErrors"
                                                required
                                                @input="$v.livraison.nom.$touch()"
                                                @blur="$v.livraison.nom.$touch()"
                                                class="textboxFormulaire"
                                            />
                                        </v-col>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Prénom <span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">*</span></p>
                                            <v-text-field
                                                    dense
                                                    outlined
                                                    background-color="white"
                                                    v-model="livraison.prenom"
                                                    :error-messages="livraisonFirstnameErrors"
                                                    required
                                                    @input="$v.livraison.prenom.$touch()"
                                                    @blur="$v.livraison.prenom.$touch()"
                                                    class="textboxFormulaire"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Numéro de voie et nom de la rue <span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">*</span></p>
                                            <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="livraison.adresse"
                                                :error-messages="livraisonAddressErrors"
                                                required
                                                @input="$v.livraison.adresse.$touch()"
                                                @blur="$v.livraison.adresse.$touch()"
                                                class="textboxFormulaire"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Bâtiment, appartement, lot,... <span class="font-weight-regular">(facultatif)</span></p>
                                            <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="livraison.complement_adresse"
                                                class="textboxFormulaire"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Code postal <span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">*</span></p>
                                            <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="livraison.code_postal"
                                                :error-messages="livraisonPostalCodeErrors"
                                                required
                                                @input="$v.livraison.code_postal.$touch()"
                                                @blur="$v.livraison.code_postal.$touch()"
                                                class="textboxFormulaire"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Ville <span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">*</span></p>
                                            <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="livraison.ville"
                                                :error-messages="livraisonCityErrors"
                                                required
                                                @input="$v.livraison.ville.$touch()"
                                                @blur="$v.livraison.ville.$touch()"
                                                class="textboxFormulaire"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col lg="6" class="py-0">
                                            <p class="mb-0 font-weight-bold " >Téléphone <span class="font-weight-regular">(facultatif)</span></p>
                                            <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="livraison.telephone"
                                                :error-messages="livraisonPhoneErrors"
                                                required
                                                @input="$v.livraison.telephone.$touch()"
                                                @blur="$v.livraison.telephone.$touch()"
                                                class="textboxFormulaire"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col offset-lg="1" lg="5" cols="12" :class="{'pt-0': $vuetify.breakpoint.smAndDown}">

                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Nom de l'entreprise <span class="font-weight-regular">(facultatif)</span></p>
                                            <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="livraison.societe"
                                                class="textboxFormulaire"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " >Pays <span class=""  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">*</span></p>

                                            <v-select
                                                outlined
                                                dense
                                                v-model="livraison.pays"
                                                :items="countries"
                                                :error-messages="livraisonCountryErrors"
                                                required
                                                @change="$v.livraison.pays.$touch()"
                                                @blur="$v.livraison.pays.$touch()"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold">Notes de commande <span class="font-weight-regular">(facultatif)</span></p>
                                            <v-textarea
                                                outlined
                                                no-resize
                                                v-model="livraisonNotes"
                                                label="Commentaires concernant votre commande,ex. : consignes de livraisons"
                                            />
                                        </v-col>
                                    </v-row>

                                </v-col>
                            </v-row>
                            <v-row class="justify-end">
                                <v-btn v-if="!isSubmitted" class="mr-4 text-right" dark :class="{'bc_blue': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2'}" @click="livraisonSubmit">Passer au paiement</v-btn>
                                <v-btn v-if="isSubmitted" loading class="mr-4 text-right" dark :class="{'bc_blue': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2'}">Passer au paiement</v-btn>
                            </v-row>

                            <v-row class="justify-end">
                                <span v-if="isError" class="mt-5" style="color: red">Veuillez remplir tout les champs obligatoires correctement.</span>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-card>
        </v-col>






    </v-col>
</v-content>
</template>

<script>

    import {mapState} from "vuex";

    const { validationMixin } = require('vuelidate')
    const { required, minLength, maxLength, alpha, numeric } = require('vuelidate/lib/validators')

    import $ from 'jquery'

    import Vue from 'vue'
    import axios from 'axios'
    import VueAxios from 'vue-axios'
    import cacheEcrou from "@/assets/accessoires/cache-ecrou.jpg";
    import antivols from "@/assets/accessoires/antivols_de_roues.jpg";
    import valvesAlu from "@/assets/accessoires/valve-alu.jpg";
    import valvesElectro from "@/assets/accessoires/valve_electro.jpg";
    import BagueCentrage from "@/assets/accessoires/bague-de-centrage.jpg";
    import valvesCoudees from "@/assets/accessoires/valves-coudees.jpg";
    import antiInsect from "@/assets/accessoires/nettoyant-insecte.jpg";
    import changementEntraxe from "@/assets/accessoires/g_elargisseur_ch_entraxe.jpg";
    import PeintureBleu from "@/assets/accessoires/peinture-etrier-blue.jpg";
    import PeintureJaune from "@/assets/accessoires/peinture-etrier-yellow.jpg";
    import PeintureRouge from "@/assets/accessoires/peinture-etrier-red.jpg";
    import brillantPneus from "@/assets/accessoires/brillant_pneus.jpg";



    import SecureLS from "secure-ls";
    var ls = new SecureLS({ isCompression: false });

    Vue.use(VueAxios, axios)

    export default {
        name: "Etape4_formulaire",
        components:{


        },
        mixins: [validationMixin],

        validations: {
            facturation: {
                prenom: {required},
                nom: {required},
                pays: {required, alpha},
                adresse: {required},
                code_postal: {required, numeric, minLength: minLength(5), maxLength: maxLength(5)},
                ville: {required},
                telephone: {required, numeric, minLength: minLength(10), maxLength: maxLength(10)},

            },
            livraison: {
                prenom: {required},
                nom: {required},
                pays: {required, alpha},
                adresse: {required},
                code_postal: {required, numeric, minLength: minLength(5), maxLength: maxLength(5)},
                ville: {required},
                telephone: {required, numeric, minLength: minLength(10), maxLength: maxLength(10)},

            },
            infos:{
                mois: {required},
                annee: {required},
                valves: {required},
            }

        },
        data: () => ({
            facturation:{},
            livraison:{},
            infos:{},
            headerTitle: 7,
            prenom:'',
            nom:'',
            pays:'France',
            // societe:'',
            adresse:'',
            // complement_adresse:'',
            code_postal:'',
            ville:'',
            telephone:'',
            facturationSelected:false,
            livraisonSelected:false,
            infoSelected:false,
            // isSelected:{},
            countries: [
                'Allemagne',
                'Autriche',
                'Espagne',
                'France',
                'Suisse',
            ],
            orderFacturation: {},
            orderLivraison: {},
            selected:'',
            mois:[
                'Janvier',
                'Février',
                'Mars',
                'Avril',
                'Mai',
                'Juin',
                'Juillet',
                'Août',
                'Septembre',
                'Octobre',
                'Novembre',
                'Décembre',
            ],
            annees:[],

            adresseLivr:0,
            facturationNotes:'',
            livraisonNotes:'',
            id_facturation:'',
            id_livraison:'',
            isSubmitted:false,
            isError:false,

            accessoires:[
                {name:'20 Caches boulon', price:'20,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:cacheEcrou, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'4 Antivols de roues', price:'30,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:antivols, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'4 Valves de roues et bouchons alu', price:'10,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:valvesAlu, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'4 Valves electroniques', price:'200,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:valvesElectro, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'4 Bagues de centrage', price:'30,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:BagueCentrage, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'Valves coudées', price:'150,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img: valvesCoudees, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'Anti insect', price:'12,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:antiInsect, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'Changement entraxe Audi/VW', price:'380,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:changementEntraxe, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'Kit peinture étrier bleu', price:'50,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:PeintureBleu, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'Kit peinture étrier jaune', price:'50,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:PeintureJaune, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'Kit peinture étrier rouge', price:'50,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:PeintureRouge, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'Brillant pour pneus', price:'12,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:brillantPneus, quantite: 1, type:3, flux:null, buying_price: null},
            ],

            VUE_APP_SITE_ID:null
        }),
        computed: {

            facturationFirstnameErrors () {
                const errors = [];
                if (!this.$v.facturation.prenom.$dirty) return errors
                // !this.$v.facturation.prenom.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.facturation.prenom.required && errors.push('Le prénom est obligatoire')

                return errors

            },
            facturationLastnameErrors () {
                const errors = [];

                if (!this.$v.facturation.nom.$dirty) return errors
                // !this.$v.facturation.nom.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.facturation.nom.required && errors.push('Le nom est obligatoire')
                return errors

            },
            facturationCountryErrors () {
                const errors = [];
                if (!this.$v.facturation.pays.$dirty) return errors
                !this.$v.facturation.pays.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.facturation.pays.required && errors.push('Le pays est obligatoire')
                return errors
            },
            facturationAddressErrors () {
                const errors = [];
                if (!this.$v.facturation.adresse.$dirty) return errors
                !this.$v.facturation.adresse.required && errors.push('L\'adresse est obligatoire')
                return errors
            },
            facturationPostalCodeErrors () {
                const errors = [];
                if (!this.$v.facturation.code_postal.$dirty) return errors
                !this.$v.facturation.code_postal.numeric && errors.push('Le code postal est incorrect')
                !this.$v.facturation.code_postal.required && errors.push('Le code postal est obligatoire')
                !this.$v.facturation.code_postal.minLength && errors.push('Le code postal est incorrect')
                !this.$v.facturation.code_postal.maxLength && errors.push('Le code postal est incorrect')
                return errors
            },
            facturationCityErrors () {
                const errors = [];
                if (!this.$v.facturation.ville.$dirty) return errors
                // !this.$v.facturation.ville.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.facturation.ville.required && errors.push('La ville est obligatoire')
                return errors
            },
            facturationPhoneErrors () {
                const errors = [];
                if (!this.$v.facturation.telephone.$dirty) return errors
                !this.$v.facturation.telephone.numeric && errors.push('Téléphone invalide')
                !this.$v.facturation.telephone.minLength && errors.push('Téléphone invalide')
                !this.$v.facturation.telephone.maxLength && errors.push('Téléphone invalide')
                !this.$v.facturation.telephone.required && errors.push('Téléphone invalide')
                return errors
            },
            livraisonFirstnameErrors () {
                const errors = [];
                if (!this.$v.livraison.prenom.$dirty) return errors
                // !this.$v.livraison.prenom.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.livraison.prenom.required && errors.push('Le prénom est obligatoire')
                return errors

            },
            livraisonLastnameErrors () {
                const errors = [];

                if (!this.$v.livraison.nom.$dirty) return errors
                // !this.$v.livraison.nom.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.livraison.nom.required && errors.push('Le nom est obligatoire')
                return errors

            },
            livraisonCountryErrors () {
                const errors = [];
                if (!this.$v.livraison.pays.$dirty) return errors
                !this.$v.livraison.pays.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.livraison.pays.required && errors.push('Le pays est obligatoire')
                return errors
            },
            livraisonAddressErrors () {
                const errors = [];
                if (!this.$v.livraison.adresse.$dirty) return errors
                !this.$v.livraison.adresse.required && errors.push('L\'adresse est obligatoire')
                return errors
            },
            livraisonPostalCodeErrors () {
                const errors = [];
                if (!this.$v.livraison.code_postal.$dirty) return errors
                !this.$v.livraison.code_postal.numeric && errors.push('Le code postal est incorrect')
                !this.$v.livraison.code_postal.required && errors.push('Le code postal est obligatoire')
                !this.$v.livraison.code_postal.minLength && errors.push('Le code postal est incorrect')
                !this.$v.livraison.code_postal.maxLength && errors.push('Le code postal est incorrect')
                return errors
            },
            livraisonCityErrors () {
                const errors = [];
                if (!this.$v.livraison.ville.$dirty) return errors
                // !this.$v.livraison.ville.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.livraison.ville.required && errors.push('La ville est obligatoire')
                return errors
            },
            livraisonPhoneErrors () {
                const errors = [];
                if (!this.$v.livraison.telephone.$dirty) return errors
                !this.$v.livraison.telephone.numeric && errors.push('Téléphone invalide')
                !this.$v.livraison.telephone.minLength && errors.push('Téléphone invalide')
                !this.$v.livraison.telephone.maxLength && errors.push('Téléphone invalide')
                return errors
            },

            moisErrors () {
                const errors = []
                if (!this.$v.infos.mois.$dirty) return errors
                !this.$v.infos.mois.required && errors.push('Ce champ est obligatoire')
                return errors
            },
            anneeErrors () {
                const errors = []
                if (!this.$v.infos.annee.$dirty) return errors
                !this.$v.infos.annee.required && errors.push('Ce champ est obligatoire')
                return errors
            },
            valvesErrors () {
                const errors = []
                if (!this.$v.infos.valves.$dirty) return errors
                !this.$v.infos.valves.required && errors.push('Ce champ est obligatoire')
                return errors
            },


            ...mapState({
                userId : state => state.auth.userId,
            })
        },
        methods: {
            setOnglet(item, value){
                // console.log('setOnglet',value, item)

                switch(value){
                    case"4jantes":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : item, jantesArr : item})
                        this.infoJante = item
                        this.currentColor = this.colors[0].name;
                        this.photoPrincipal = this.infoJante.photo_1
                        this.triggerUpdate ++
                        break
                    case"jantesAv":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : item, jantesArr : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr})
                        this.triggerUpdate ++
                        break
                    case"jantesArr":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv, jantesArr : item})
                        this.triggerUpdate ++
                        break
                    case"4pneus":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : item, pneusArr : item})
                        this.triggerUpdate ++
                        break
                    case"pneusAv":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : item, pneusArr : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr})
                        this.triggerUpdate ++
                        break
                    case"pneusArr":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv, pneusArr : item})
                        this.triggerUpdate ++
                        break
                    case"accessoires":
                        this.TotalPriceAccessoires = 0
                        Object.values(item).forEach((e) => {
                            if(e != null) {
                                this.TotalPriceAccessoires += parseInt(e.data.price)
                            }
                        })
                        // console.log('this.TotalPriceAccessoires', this.TotalPriceAccessoires)
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires', {accessoires: item, totalPrice: this.TotalPriceAccessoires})

                        this.triggerUpdate ++
                        break
                    case"indexAccessoires":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires', {accessoires: ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').accessoires,totalPrice: ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').totalPrice , selected: item })
                        this.triggerUpdate ++
                        break
                    case"modelePossibleAuCarre":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre', item)
                        break
                    case"modelePossibleFront":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront', item)
                        break
                    case"modelePossibleRear":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear', item)
                        break
                }

                ls.set('last_updated', this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale)

            },
            getOnglet(value){
// console.log('access', ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires'))
                switch(value){
                    case'jantesAv':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv
                    case 'jantesArr':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr
                    case 'pneusAv':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv
                    case 'pneusArr':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr
                    case 'accessoires':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').accessoires
                    case 'totalPriceAccessoires':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').totalPrice
                    case 'indexAccessoires':
                        // console.log('getIndexAccessoires', ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').selected)
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').selected
                    case 'modelePossibleAuCarre':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre')
                    case 'modelePossibleFront':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront')
                    case 'modelePossibleRear':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear')
                }
            },
            testChange (){

                // console.log('SelectedAccessoires',this.SelectedAccessoires)
                // console.log('isSelected',this.isSelected)

                if(this.infos.valves === "1"){

                    this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
                    this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
                    this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0


                    this.isAccessoire = {}
                    this.isAccessoire.index = 3
                    this.isAccessoire.data = {name:'4 Valves electroniques', price:'200.00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:valvesElectro, quantite: 1, type:3, flux:null, buying_price: null},
                    this.isAccessoire.selected = true

                    this.SelectedAccessoires[3] = this.isAccessoire
                    this.isSelectedIndex[3] = true

                    this.isSelectedIndex = Object.assign({}, this.isSelectedIndex)

                    this.setOnglet(this.SelectedAccessoires, 'accessoires')
                    this.setOnglet(this.isSelectedIndex, 'indexAccessoires')


                    this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
                    this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
                    this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0


                    this.TotalPriceAccessoires = 0
                    Object.values(this.SelectedAccessoires).forEach((e) => {
                        if(e != null) {
                            this.TotalPriceAccessoires += parseInt(e.data.price)
                        }
                    })

                    this.$emit('updated', 'TriggerUpdate' )
                }
            },
            anchor:function(){
                setTimeout(()=>{
                    var element = document.getElementById('anchor');

                    if (this.$vuetify.breakpoint.lgAndUp){
                        element.scrollTo({
                            top: 1000,
                            behavior: 'smooth'
                        });
                    }else{
                        window.scrollTo({
                            top: 1500,
                            behavior: 'smooth'
                        });
                    }


                }, 100)

            },
            emitValves:function(){
                this.$emit('infoClicked')
            },
            switchLivraison:function(){
                this.livraisonSelected = !this.livraisonSelected
                this.facturationSelected = false
                this.infoSelected = false
                $(window).scrollTop($('#livraison_div').offset().top);

                this.selected = 'livraison'
            },
            facturationSubmit () {
                this.$v.facturation.$touch()
                this.$v.infos.$touch()
                this.isSubmitted = true

                if(!this.$v.infos.valves.$invalid
                    && !this.$v.infos.mois.$invalid
                    && !this.$v.infos.annee.$invalid
                    && !this.$v.facturation.prenom.$invalid
                    && !this.$v.facturation.nom.$invalid
                    && !this.$v.facturation.pays.$invalid
                    && !this.$v.facturation.adresse.$invalid
                    && !this.$v.facturation.code_postal.$invalid
                    && !this.$v.facturation.ville.$invalid)
                {
                    this.isError = false
                    this.orderFacturation.prenom = this.$v.facturation.prenom.$model
                    this.orderFacturation.nom = this.$v.facturation.nom.$model
                    this.orderFacturation.pays = this.$v.facturation.pays.$model
                    this.orderFacturation.adresse = this.$v.facturation.adresse.$model
                    this.orderFacturation.code_postal = this.$v.facturation.code_postal.$model
                    this.orderFacturation.ville = this.$v.facturation.ville.$model
                    this.$v.facturation.telephone.$model ? this.orderFacturation.telephone = this.$v.facturation.telephone.$model : this.orderFacturation.telephone = ''
                    this.orderFacturation.complement_adresse = this.facturation.complement_adresse
                    // this.orderFacturation.facturationNotes = this.facturation.facturationNotes
                    this.orderFacturation.societe = this.facturation.societe

                    this.$store.commit('infoVehicule/setValve', {'hadValves': this.$v.infos.valves.$model, 'circulation': this.$v.infos.mois.$model + '/' + this.$v.infos.annee.$model})
                    this.$store.commit('addr_commande/setNotes', this.facturationNotes)

                    this.$emit('hideDelete')

                    Vue.axios.get('https://api.espace-jantes.com/getAdresseUser', {
                        params: { id_user: this.userId },
                    })
                    .then((responseAdresseUser) => {
                        if(responseAdresseUser.data){
                            if(responseAdresseUser.data.length === 0){
                                Vue.axios.post('https://api.espace-jantes.com/addAdresse', {
                                    params: {
                                        'id_user': this.userId,
                                        'addr_facturation' : this.orderFacturation,
                                        'addr_livraison' : this.orderFacturation,
                                    },
                                })
                                .then((responseaddAdresse) => {

                                    if(responseaddAdresse.data.etat === true){
                                        this.$store.commit('addr_commande/setaddr', {'addr_facturation' :this.orderFacturation, 'addr_livraison': this.orderFacturation  })
                                        this.$router.push({name:'Etape5', params: {marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }})
                                    }
                                }).catch((err) =>{
                                    console.log(err)
                                })
                            }else{
                                    if(responseAdresseUser.data[0].type_adresse === 1)
                                    {
                                        this.orderFacturation.id =  responseAdresseUser.data[0].id
                                        this.orderLivraison.id =  responseAdresseUser.data[1].id
                                    }else{
                                        this.orderFacturation.id =  responseAdresseUser.data[1].id
                                        this.orderLivraison.id =  responseAdresseUser.data[0].id
                                    }


                                Vue.axios.post('https://api.espace-jantes.com/addAdresse', {
                                    params: {
                                        'id_user': this.userId,
                                        'addr_facturation' : this.orderFacturation,
                                        'addr_livraison' : this.orderFacturation,
                                    },
                                })
                                .then((responseAdd) => {

                                    if(responseAdd.data.etat === true){

                                        this.$store.commit('addr_commande/setaddr', {'addr_facturation' :this.orderFacturation, 'addr_livraison': this.orderFacturation  })
                                        this.$router.push({name:'Etape5', params: {marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }})
                                    }
                                }).catch((errAdd) =>{
                                    console.log(errAdd)
                                })
                            }
                        }
                    }).catch((err) =>{
                        console.log(err)
                    })

                }else{
                    this.isSubmitted = false
                    this.isError = true
                }

            },
            livraisonSubmit () {
                this.$v.infos.$touch()
                this.$v.facturation.$touch()
                this.$v.livraison.$touch()
                this.isSubmitted = true

                if(!this.$v.infos.valves.$invalid
                    && !this.$v.infos.mois.$invalid
                    && !this.$v.infos.annee.$invalid
                    && !this.$v.facturation.prenom.$invalid
                    && !this.$v.facturation.nom.$invalid
                    && !this.$v.facturation.pays.$invalid
                    && !this.$v.facturation.adresse.$invalid
                    && !this.$v.facturation.code_postal.$invalid
                    && !this.$v.facturation.ville.$invalid
                    && !this.$v.livraison.prenom.$invalid
                    && !this.$v.livraison.nom.$invalid
                    && !this.$v.livraison.pays.$invalid
                    && !this.$v.livraison.adresse.$invalid
                    && !this.$v.livraison.code_postal.$invalid
                    && !this.$v.livraison.ville.$invalid
                )
                {
                    this.isError = false

                    this.orderFacturation.prenom = this.$v.facturation.prenom.$model
                    this.orderFacturation.nom = this.$v.facturation.nom.$model
                    this.orderFacturation.pays = this.$v.facturation.pays.$model
                    this.orderFacturation.adresse = this.$v.facturation.adresse.$model
                    this.orderFacturation.code_postal = this.$v.facturation.code_postal.$model
                    this.orderFacturation.ville = this.$v.facturation.ville.$model
                    this.$v.facturation.telephone.$model ? this.orderFacturation.telephone = this.$v.facturation.telephone.$model : this.orderFacturation.telephone = ''
                    this.orderFacturation.complement_adresse = this.facturation.complement_adresse
                    // this.orderFacturation.facturationNotes = this.facturation.facturationNotes
                    this.orderFacturation.societe = this.facturation.societe


                    this.orderLivraison.prenom = this.$v.livraison.prenom.$model
                    this.orderLivraison.nom = this.$v.livraison.nom.$model
                    this.orderLivraison.pays = this.$v.livraison.pays.$model
                    this.orderLivraison.adresse = this.$v.livraison.adresse.$model
                    this.orderLivraison.code_postal = this.$v.livraison.code_postal.$model
                    this.orderLivraison.ville = this.$v.livraison.ville.$model
                    this.$v.livraison.telephone.$model ? this.orderLivraison.telephone = this.$v.livraison.telephone.$model : this.orderLivraison.telephone = ''
                    this.orderLivraison.complement_adresse = this.livraison.complement_adresse
                    // this.orderLivraison.livraisonNotes = this.livraison.livraisonNotes
                    this.orderLivraison.societe = this.livraison.societe

                    this.$store.commit('infoVehicule/setValve', {'hadValves': this.$v.infos.valves.$model, 'circulation': this.$v.infos.mois.$model + '/' + this.$v.infos.annee.$model})
                    this.$store.commit('addr_commande/setNotes', this.livraisonNotes)



                    Vue.axios.get('https://api.espace-jantes.com/getAdresseUser', {
                        params: { id_user: this.userId },
                    })
                        .then((responseAdresseUser) => {
                            if(responseAdresseUser.data){
                                if(responseAdresseUser.data.length === 0){
                                    Vue.axios.post('https://api.espace-jantes.com/addAdresse', {
                                        params: {
                                            'id_user': this.userId,
                                            'addr_facturation' : this.orderFacturation,
                                            'addr_livraison' : this.orderLivraison,
                                        },
                                    })
                                        .then((responseaddAdresse) => {
                                            if(responseaddAdresse.data.etat === true){
                                                this.$store.commit('addr_commande/setaddr', {'addr_facturation' :this.orderFacturation, 'addr_livraison': this.orderLivraison  })
                                                this.$router.push({name:'Etape5', params: {marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }})
                                            }
                                        }).catch((err) =>{
                                        console.log(err)
                                    })
                                }else{
                                    if(responseAdresseUser.data[0].type_adresse === 1)
                                    {
                                        this.orderFacturation.id =  responseAdresseUser.data[0].id
                                        this.orderLivraison.id =  responseAdresseUser.data[1].id
                                    }else{
                                        this.orderFacturation.id =  responseAdresseUser.data[1].id
                                        this.orderLivraison.id =  responseAdresseUser.data[0].id
                                    }


                                    Vue.axios.post('https://api.espace-jantes.com/addAdresse', {
                                        params: {
                                            'id_user': this.userId,
                                            'addr_facturation' : this.orderFacturation,
                                            'addr_livraison' : this.orderLivraison,
                                        },
                                    })
                                        .then((responseAdd) => {
                                            if(responseAdd.data.etat === true){
                                                this.$store.commit('addr_commande/setaddr', {'addr_facturation' :this.orderFacturation, 'addr_livraison': this.orderLivraison  })
                                                this.$router.push({name:'Etape5', params: {marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }})
                                            }
                                        }).catch((errAdd) =>{
                                        console.log(errAdd)
                                    })
                                }
                            }
                        }).catch((err) =>{
                        console.log(err)
                    })

                }else{
                    this.isSubmitted = false
                    this.isError = true
                }
            },
        },
        created() {



            this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
            this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
            this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0
// console.log('SelectedAccessoires', this.SelectedAccessoires)

            this.facturation.pays = 'France'
            this.infos.valves = "0"
            this.facturation = Object.assign({}, this.facturation)

            Vue.axios.get('https://api.espace-jantes.com/getAdresseUser', {
                params: { id_user: this.userId },
            })
                .then((res) => {
                    if(res.data.length > 0){
                        // console.log('resdata', res.data)
                        res.data.forEach(element =>{
                            // console.log('element', element)
                            if(element.type_adresse == 1 ){
                                element.telephone === 0 ? element.telephone = '' : element.telephone
                                this.facturation = element
                            }
                            if(element.type_adresse == 2 ){
                                element.telephone === 0 ? element.telephone = '' : element.telephone
                                this.livraison = element
                            }

                        })
                    }
                    Vue.axios.get("https://api.espace-jantes.com/getOneUser", {
                        params: { id_user: this.userId },
                        }).then((resp) => {
                            // console.log('resp', resp.data)
                            var response = resp.data[0]
                            this.facturation.nom = response.nom
                            this.facturation.prenom = response.prenom
                            this.facturation.telephone = response.telephone
                            this.facturation.pays = 'France'

                            this.facturation = Object.assign({}, this.facturation)
                        })


                }).catch((err) => {
                    console.log(err)
                })

            for(let i = new Date().getFullYear(); i >= 1980; i-- ){
                this.annees.push(i)
            }
        },
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID

            setTimeout(()=>{
                $(".textboxFormulaire").keyup(function(event) {
                    if (event.keyCode === 13) {
                        $(".buttonPaiement").click();
                    }
                });
            }, 2000)


        },

    }
</script>

<style scoped>

    .border-right{
        border-right: 1px solid rgba(137, 158, 168, 0.15)
    }
    .border-top{
        border-top: 1px solid rgba(137, 158, 168, 0.15)
    }
    .coloredEJ >>> label{
        font-weight: bold;
        color: #1840DA;
    }
    .coloredDJ >>> label{
        font-weight: bold;
        color: #FC5C1F;
    }

</style>
