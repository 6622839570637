<template>
    <v-content style="height: 100%">
        <v-col v-if="$vuetify.breakpoint.lgAndUp" fluid :class="{'bc_darkBlue': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2'}" style="height: 100%">
            <v-row>
                <v-col cols="6">
<!--                    <router-link v-if="(prevStep > 0)" :to="{name: 'Etape'+ prevStep}"  style="text-decoration: none">-->
                        <v-btn v-if="(prevStep > 0)" text class="pa-0" @click="$router.go(-1)" >
                            <v-icon large color="white">mdi mdi-arrow-left</v-icon>
                        </v-btn>
<!--                    </router-link>-->
<!--                    <router-link v-else :to="{name: 'Listing'}"  style="text-decoration: none">-->
                        <v-btn text class="pa-0" v-else  @click="removePneu()">
                            <v-icon large color="white">mdi mdi-arrow-left</v-icon>
                        </v-btn>
<!--                    </router-link>-->
                </v-col>
                <v-col cols="6"  class="d-flex justify-end " :class="{'pb-0': currentItem >=3}">
<!--                    <router-link :to="{name: 'Listing'}"  style="text-decoration: none">-->
                        <v-btn text  @click="removePneu()">
                            <v-icon large color="white">mdi mdi-close</v-icon>
                        </v-btn>
<!--                    </router-link>-->
                </v-col>
            </v-row>

            <v-content v-if="currentItem < 3">

                <v-col cols="12" v-for="(menu, index) in menus" :key="menu.id" class="mb-4 align-center" :class="{'currentEJ' : index === currentItem && VUE_APP_SITE_ID === '1', 'currentDJ' : index === currentItem && VUE_APP_SITE_ID === '2', 'tc_lightGrey': VUE_APP_SITE_ID === '1', 'tc_white': VUE_APP_SITE_ID === '2' }" >

<!--                    <router-link v-if="index < currentItem "  :to="{ name: 'Etape'+menu.id}" style="text-decoration: none; color: inherit">-->
                    <div v-if="index < currentItem " style="text-decoration: none; color: inherit; position: relative">
                      <v-row  class=" font-weight-bold subtitle-1 pl-12">{{menu.title}}</v-row>
                      <v-img style="position: absolute;top: 0" aspect-ratio="1" v-if="currentItem === index && VUE_APP_SITE_ID === '1'" class="img_placement " :src="menu.imgBlue"/>
                      <v-img style="position: absolute;top: 0" aspect-ratio="1" v-else-if="currentItem === index && VUE_APP_SITE_ID === '2'" class="img_placement " :src="menu.imgOrange "/>
                      <v-img style="position: absolute;top: 0" aspect-ratio="1" v-else-if="VUE_APP_SITE_ID === '1'" class="img_placement" :src="menu.imgGreyEJ"/>
                      <v-img style="position: absolute;top: 0" aspect-ratio="1" v-else-if="VUE_APP_SITE_ID === '2'" class="img_placement" :src="menu.imgGreyDJ"/>

                          <v-row class=" body-1 pl-12">
                              <span v-if="index === 1" style="font-weight: 600;">Roues avants</span>
                              <span v-if="index === 1 && currentItem >= 1" style="font-weight: bold;white-space: pre-wrap;"> : </span>
                              <span>{{menu.desc1}}</span>
                          </v-row>
                      <v-row class=" body-1 pl-12">
                          <span v-if="index === 1" style="font-weight: 600;">Roues arrières</span>
                          <span v-if="index === 1 && currentItem >= 1" style="font-weight: bold;white-space: pre-wrap;"> : </span>
                          <span>{{menu.desc2}}</span>
                      </v-row>
                    </div>
<!--                    </router-link>-->

                    <div v-else style="position: relative; min-height: 20%">
                        <v-row  class=" font-weight-bold subtitle-1 pl-12">{{menu.title}}</v-row>

                        <v-img style="position: absolute;top: 0" aspect-ratio="1" v-if="currentItem === index && VUE_APP_SITE_ID === '1'" class="img_placement " :src="menu.imgBlue"/>
                        <v-img style="position: absolute;top: 0" aspect-ratio="1" v-else-if="currentItem === index && VUE_APP_SITE_ID === '2'" class="img_placement " :src="menu.imgOrange "/>
                        <v-img style="position: absolute;top: 0" aspect-ratio="1" v-else-if="VUE_APP_SITE_ID === '1'" class="img_placement" :src="menu.imgGreyEJ"/>
                        <v-img style="position: absolute;top: 0" aspect-ratio="1" v-else-if="VUE_APP_SITE_ID === '2'" class="img_placement" :src="menu.imgGreyDJ"/>

                        <v-row class=" body-1 pl-12">
                            <span v-if="index === 1" style="font-weight: 600;">Roues avants</span>
    <!--                        <span v-if="index >= currentItem && index !== 1" >{{menu.desc1}}</span>-->
    <!--                        <span v-if="index >= currentItem" >{{menu.desc1}}</span>-->
                            <span  >{{menu.desc1}}</span>
                        </v-row>
                        <v-row class=" body-1 pl-12">
                            <span v-if="index === 1" style="font-weight: 600;">Roues arrières</span>
    <!--                        <span v-if="index >= currentItem && index !== 1 " >{{menu.desc2}}</span>-->
    <!--                        <span v-if="index >= currentItem " >{{menu.desc2}}</span>-->
                            <span>{{menu.desc2}}</span>
                        </v-row>
                    </div>
                </v-col>



            </v-content>

            <v-content v-if="currentItem >= 3">
                <v-col offset-lg="1" lg="10" class="pa-0 tc_white">
                        <v-col class="border-bot">
                            <span class="font-weight-bold">Votre commande</span>
                        </v-col>
                        <v-col class="caption pt-0">
                            <v-row class="border-bot">
                                <v-col class="py-0 " lg="7" >
                                    <span>Produit</span>
                                </v-col>
                                <v-col class="py-0" lg="4" >
                                    <span>Total</span>
                                </v-col>
                            </v-row>
                            <v-col class="pa-0" v-if="!productSelected">
                                <v-content v-if="(JSON.stringify(this.SelectedJanteAv) === JSON.stringify(this.SelectedJanteArr) && this.SelectedJanteAv )">

                                    <v-content v-if="this.SelectedJanteAv.largeurMinAv === this.SelectedJanteAv.largeurMaxArr && this.SelectedJanteAv.offsetFront === this.SelectedJanteAv.offsetRear">
                                        <v-row>
                                            <v-col class="" lg="7" >
                                                <span class="font-weight-bold">{{ SelectedJanteAv.marque }} {{ SelectedJanteAv.modele }} {{ SelectedJanteAv.couleur }} x4</span>
                                                <br>
                                                <span>{{ SelectedJanteAv.largeur ? SelectedJanteAv.largeur : SelectedJanteAv.largeurMinAv}}X{{SelectedJanteAv.diametre + ' - ' +SelectedJanteAv.entraxe + ' - ET ' }}{{ SelectedJanteAv.offset ? SelectedJanteAv.offset : SelectedJanteAv.offsetRear }}</span>
                                                <br>
                                            </v-col>
                                            <v-col class="px-0" cols="4">
                                                <span>{{getRoundPrice(SelectedJanteAv.public_price,null, '4jantes')}} €</span>
                                            </v-col>
                                            <v-col class="px-0" cols="1">
                                                <v-icon v-if="!hideDelete"
                                                    small
                                                    @click="deleteItem('Jantes')"
                                                    color="white"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-content>
                                    <v-content v-else-if="this.SelectedJanteAv.largeurMinAv !== this.SelectedJanteAv.largeurMaxArr || this.SelectedJanteAv.offsetFront !== this.SelectedJanteAv.offsetRear">
                                        <v-row>
                                            <v-col class="" lg="7" >
                                                <span class="font-weight-bold">{{ SelectedJanteAv.marque }} {{ SelectedJanteAv.modele }} {{ SelectedJanteAv.couleur }} x2</span>
                                                <br>
                                                <span>{{ SelectedJanteAv.largeurMinAv +'X'+SelectedJanteAv.diametre + ' - ' +SelectedJanteAv.entraxe + ' - ET ' + SelectedJanteAv.offsetFront }}</span>
                                                <br>
                                            </v-col>
                                            <v-col class="px-0" cols="4">
                                                <span>{{getRoundPriceMonteDecale(SelectedJanteAv.public_price, null, null, null,'2jantesAv')}} €</span>
                                            </v-col>
                                            <v-col class="px-0" cols="1">
                                                <v-icon v-if="!hideDelete"
                                                    small
                                                    @click="deleteItem('Jantes')"
                                                    color="white"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <span class="font-weight-bold">{{ SelectedJanteArr.marque }} {{ SelectedJanteArr.modele }} {{ SelectedJanteArr.couleur }} x2</span>
                                                <br>
                                                <span>{{ SelectedJanteArr.largeurMaxArr +'X'+SelectedJanteArr.diametre + ' - ' +SelectedJanteArr.entraxe + ' - ET ' + SelectedJanteArr.offsetRear }}</span>
                                                <br>
                                            </v-col>
                                            <v-col class="px-0" lg="4" >
                                                <span>{{getRoundPriceMonteDecale( null, SelectedJanteArr.public_price_rear,null, null,'2jantesArr')}} €</span>
                                            </v-col>
                                            <v-col lg="1" class="px-0" >
                                                <v-icon v-if="!hideDelete"
                                                    small
                                                    @click="deleteItem('Jantes')"
                                                    color="white"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-content>
                                    <v-content v-else>
                                        <v-row>
                                            <v-col class="" lg="7" >
                                                <span class="font-weight-bold">{{ SelectedJanteAv.marque }} {{ SelectedJanteAv.modele }} {{ SelectedJanteAv.couleur }} x2</span>
                                                <br>
                                                <span>{{ SelectedJanteAv.largeur +'X'+SelectedJanteAv.diametre + ' - ' +SelectedJanteAv.entraxe + ' - ET ' + SelectedJanteAv.offset }}</span>
                                                <br>
                                            </v-col>
                                            <v-col class="px-0" cols="4">
                                                <span>{{getRoundPrice(SelectedJanteAv.public_price,null,'2jantes')}} €</span>
                                            </v-col>
                                            <v-col class="px-0" cols="1">
                                                <v-icon v-if="!hideDelete"
                                                    small
                                                    @click="deleteItem('Jantes')"
                                                    color="white"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <span class="font-weight-bold">{{ SelectedJanteArr.marque }} {{ SelectedJanteArr.modele }} {{ SelectedJanteArr.couleur }} x2</span>
                                                <br>
                                                <span>{{ SelectedJanteArr.largeur +'X'+SelectedJanteArr.diametre + ' - ' +SelectedJanteArr.entraxe + ' - ET ' + SelectedJanteArr.offset }}</span>
                                                <br>
                                            </v-col>
                                            <v-col class="px-0" lg="4" >
                                                <span>{{getRoundPrice(SelectedJanteArr.public_price,null,'2jantes')}} €</span>
                                            </v-col>
                                            <v-col lg="1" class="px-0" >
                                                <v-icon v-if="!hideDelete"
                                                    small
                                                    @click="deleteItem('Jantes')"
                                                    color="white"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-content>
                                </v-content>

                                <v-content v-else>
                                    <v-row>
                                        <v-col class="" lg="7" >
                                            <span class="font-weight-bold">{{ SelectedJanteAv.marque }} {{ SelectedJanteAv.modele }} {{ SelectedJanteAv.couleur }} x2</span>
                                            <br>
                                            <span>{{ SelectedJanteAv.largeur +'X'+SelectedJanteAv.diametre + ' - ' +SelectedJanteAv.entraxe + ' - ET ' + SelectedJanteAv.offsetFront }}</span>
                                            <br>
                                        </v-col>
                                        <v-col class="px-0" cols="4">
                                                <span>{{getRoundPriceMonteDecale(SelectedJanteAv.public_price,null,null, null,'2jantesAv')}} €</span>
                                        </v-col>
                                        <v-col class="px-0" cols="1">
                                            <v-icon
                                               v-if="!hideDelete"
                                               small
                                                @click="deleteItem('Jantes')"
                                                color="white"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <span class="font-weight-bold">{{ SelectedJanteArr.marque }} {{ SelectedJanteArr.modele }} {{ SelectedJanteArr.couleur }} x2</span>
                                            <br>
                                            <span>{{ SelectedJanteArr.largeur +'X'+SelectedJanteArr.diametre + ' - ' +SelectedJanteArr.entraxe + ' - ET ' + SelectedJanteArr.offsetRear }}</span>
                                            <br>
                                        </v-col>
                                        <v-col class="px-0" lg="4" >
                                                <span>{{getRoundPriceMonteDecale( null, SelectedJanteArr.public_price_rear,null, null,'2jantesArr')}} €</span>
                                        </v-col>
                                        <v-col lg="1" class="px-0" >
                                            <v-icon
                                                v-if="!hideDelete"
                                                small
                                                @click="deleteItem('Jantes')"
                                                color="white"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                </v-content>


                                <v-content  v-if='(JSON.stringify(this.SelectedPneuAv) === JSON.stringify(this.SelectedPneuArr) && this.SelectedPneuAv)'>
                                    <v-content v-if="this.monteDecaPneu === true">
                                        <v-content v-if="this.SelectedPneuAv.HBV === this.SelectedPneuAv.HBVREAR">
                                            <v-row>
                                                <v-col class="" lg="7" >
                                                    <span class="font-weight-bold">{{ SelectedPneuAv.marque }} {{ SelectedPneuAv.modele }} x4</span>
                                                    <br>
                                                    <span>{{SelectedPneuAv.HBV}}/{{SelectedPneuAv.width}}R{{SelectedPneuAv.diametre}} {{SelectedPneuAv.indiceDeCharge}} {{SelectedPneuAv.indiceDeVitesse}}</span>
                                                    <br>
                                                    <span v-if="SelectedPneuAv.runFlat === 1">
                                                    Runflat
                                                </span>
                                                    <span v-else>
                                                    Non Runflat
                                                </span>
                                                    <span v-if="SelectedPneuAv.hiver === 1">
                                                    Hiver
                                                    <v-icon color="light-blue">mdi mdi-snowflake</v-icon>
                                                </span>
                                                    <span v-else-if="SelectedPneuAv.ete === 1">
                                                    Eté
                                                    <v-icon color="yellow">mdi mdi-brightness-7</v-icon>
                                                </span>
                                                    <span v-else-if="SelectedPneuAv.allsaison === 1"  class="d-flex" style="align-items: center">
                                                    4 saisons
                                                    <v-img src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                </span>
                                                </v-col>
                                                <v-col class="px-0" cols="4">
                                                    <span>{{getRoundPrice(null, SelectedPneuAv.prixNet, '4pneus')}} €</span>
                                                </v-col>
                                                <v-col class="px-0" cols="1">
                                                    <v-icon v-if="!hideDelete"
                                                        small
                                                        @click="deleteItem('Pneus')"
                                                        color="white"
                                                    >
                                                        mdi-delete
                                                    </v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-content>
                                        <v-content v-else-if="this.SelectedPneuAv.HBV !== this.SelectedPneuAv.HBVREAR">
                                            <v-row>
                                                <v-col class="" lg="7" >
                                                    <span class="font-weight-bold">{{ SelectedPneuAv.marque }} {{ SelectedPneuAv.modele }} x2</span>
                                                    <br>
                                                    <span>{{SelectedPneuAv.HBV}}/{{SelectedPneuAv.width}}R{{SelectedPneuAv.diametre}} {{SelectedPneuAv.indiceDeCharge}} {{SelectedPneuAv.indiceDeVitesse}}</span>
                                                    <br>
                                                    <span v-if="SelectedPneuAv.runFlat === 1">
                                                    Runflat
                                                </span>
                                                    <span v-else>
                                                    Non Runflat
                                                </span>
                                                    <span v-if="SelectedPneuAv.hiver === 1">
                                                    Hiver
                                                    <v-icon color="light-blue">mdi mdi-snowflake</v-icon>
                                                </span>
                                                    <span v-else-if="SelectedPneuAv.ete === 1">
                                                    Eté
                                                    <v-icon color="yellow">mdi mdi-brightness-7</v-icon>
                                                </span>
                                                    <span v-else-if="SelectedPneuAv.allsaison === 1"  class="d-flex" style="align-items: center">
                                                    4 saisons
                                                    <v-img src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                </span>
                                                </v-col>
                                                <v-col class="px-0" cols="4">
                                                <span>{{getRoundPriceMonteDecale(null, null, SelectedPneuAv.prixNet, null,'2pneusAv')}} €</span>
                                                </v-col>
                                                <v-col class="px-0" cols="1">
                                                    <v-icon v-if="!hideDelete"
                                                        small
                                                        @click="deleteItem('Pneus')"
                                                        color="white"
                                                    >
                                                        mdi-delete
                                                    </v-icon>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col class="" lg="7" >
                                                    <span class="font-weight-bold">{{ SelectedPneuArr.marque }} {{ SelectedPneuArr.modele }} x2</span>
                                                    <br>
                                                    <span>{{SelectedPneuArr.HBVREAR}}/{{SelectedPneuArr.widthRear}}R{{SelectedPneuArr.diametre}} {{SelectedPneuArr.indiceDeChargeRear}} {{SelectedPneuArr.indiceDeVitesse}} </span>
                                                    <br>
                                                    <span v-if="SelectedPneuArr.runFlat === 1">
                                                    Runflat
                                                </span>
                                                    <span v-else>
                                                    Non Runflat
                                                </span>
                                                    <span v-if="SelectedPneuArr.hiver === 1">
                                                    Hiver
                                                    <v-icon color="light-blue">mdi mdi-snowflake</v-icon>
                                                </span>
                                                    <span v-else-if="SelectedPneuArr.ete === 1">
                                                    Eté
                                                    <v-icon color="yellow">mdi mdi-brightness-7</v-icon>
                                                </span>
                                                    <span v-else-if="SelectedPneuArr.allsaison === 1"  class="d-flex" style="align-items: center">
                                                    4 saisons
                                                    <v-img src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                </span>
                                                </v-col>
                                                <v-col class="px-0" cols="4">
                                                <span>{{getRoundPriceMonteDecale(null, null, null, SelectedPneuArr.prixNetRear,'2pneusArr')}} €</span>
                                                </v-col>
                                                <v-col class="px-0" cols="1">
                                                    <v-icon v-if="!hideDelete"
                                                        small
                                                        @click="deleteItem('Pneus')"
                                                        color="white"
                                                    >
                                                        mdi-delete
                                                    </v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-content>
                                    </v-content>
                                    <v-content v-else>
                                        <v-row>
                                            <v-col class="" lg="7" >
                                                <span class="font-weight-bold">{{ SelectedPneuAv.marque }} {{ SelectedPneuAv.modele }} x4</span>
                                                <br>
                                                <span>{{SelectedPneuAv.HBV}}/{{SelectedPneuAv.width}}R{{SelectedPneuAv.diametre}} {{SelectedPneuAv.indiceDeCharge}} {{SelectedPneuAv.indiceDeVitesse}}</span>
                                                <br>
                                                <span v-if="SelectedPneuAv.runFlat === 1">
                                                    Runflat
                                                </span>
                                                <span v-else>
                                                    Non Runflat
                                                </span>
                                                <span v-if="SelectedPneuAv.hiver === 1">
                                                    Hiver
                                                    <v-icon color="light-blue">mdi mdi-snowflake</v-icon>
                                                </span>
                                                <span v-else-if="SelectedPneuAv.ete === 1">
                                                    Eté
                                                    <v-icon color="yellow">mdi mdi-brightness-7</v-icon>
                                                </span>
                                                <span v-else-if="SelectedPneuAv.allsaison === 1"  class="d-flex" style="align-items: center">
                                                    4 saisons
                                                    <v-img src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                </span>
                                            </v-col>
                                            <v-col class="px-0" cols="4">
                                                <span>{{getRoundPrice(null, SelectedPneuAv.prixNet, '4pneus')}} €</span>
                                            </v-col>
                                            <v-col class="px-0" cols="1">
                                                <v-icon
                                                    v-if="!hideDelete"
                                                    small
                                                    @click="deleteItem('Pneus')"
                                                    color="white"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-content>

                                </v-content>

                                <v-content v-else-if="this.SelectedPneuAv">
                                    <v-row>
                                        <v-col class="" lg="7" >
                                            <span class="font-weight-bold">{{ SelectedPneuAv.marque }} {{ SelectedPneuAv.modele }} x2</span>
                                            <br>
                                            <span>{{SelectedPneuAv.HBV}}/{{SelectedPneuAv.width}}R{{SelectedPneuAv.diametre}} {{SelectedPneuAv.indiceDeCharge}} {{SelectedPneuAv.indiceDeVitesse}}</span>
                                            <br>
                                            <span v-if="SelectedPneuAv.runFlat === 1">
                                                    Runflat
                                                </span>
                                            <span v-else>
                                                    Non Runflat
                                                </span>
                                            <span v-if="SelectedPneuAv.hiver === 1">
                                                    Hiver
                                                    <v-icon color="light-blue">mdi mdi-snowflake</v-icon>
                                                </span>
                                            <span v-else-if="SelectedPneuAv.ete === 1">
                                                    Eté
                                                    <v-icon color="yellow">mdi mdi-brightness-7</v-icon>
                                                </span>
                                            <span v-else-if="SelectedPneuAv.allsaison === 1"  class="d-flex" style="align-items: center">
                                                    4 saisons
                                                    <v-img src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                </span>
                                        </v-col>
                                        <v-col class="px-0" cols="4">
                                            <span>{{getRoundPrice(null, SelectedPneuAv.prixNet, '2pneus')}} €</span>
                                        </v-col>
                                        <v-col class="px-0" cols="1">
                                            <v-icon v-if="!hideDelete"
                                                        small
                                                        @click="deleteItem('Pneus')"
                                                        color="white"
                                                    >
                                                        mdi-delete
                                                    </v-icon>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="" lg="7" >
                                            <span class="font-weight-bold">{{ SelectedPneuArr.marque }} {{ SelectedPneuArr.modele }} x2</span>
                                            <br>
                                            <span>{{SelectedPneuArr.HBV}}/{{SelectedPneuArr.width}}R{{SelectedPneuArr.diametre}} {{SelectedPneuArr.indiceDeCharge}} {{SelectedPneuArr.indiceDeVitesse}} </span>
                                            <br>
                                            <span v-if="SelectedPneuArr.runFlat === 1">
                                                    Runflat
                                                </span>
                                            <span v-else>
                                                    Non Runflat
                                                </span>
                                            <span v-if="SelectedPneuArr.hiver === 1">
                                                    Hiver
                                                    <v-icon color="light-blue">mdi mdi-snowflake</v-icon>
                                                </span>
                                            <span v-else-if="SelectedPneuArr.ete === 1">
                                                    Eté
                                                    <v-icon color="yellow">mdi mdi-brightness-7</v-icon>
                                                </span>
                                            <span v-else-if="SelectedPneuArr.allsaison === 1"  class="d-flex" style="align-items: center">
                                                    4 saisons
                                                    <v-img src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                </span>
                                        </v-col>
                                        <v-col class="px-0" cols="4">
                                            <span>{{getRoundPrice(null, SelectedPneuArr.prixNet, '2pneus')}} €</span>
                                        </v-col>
                                        <v-col class="px-0" cols="1">
                                            <v-icon v-if="!hideDelete"
                                                        small
                                                        @click="deleteItem('Pneus')"
                                                        color="white"
                                                    >
                                                        mdi-delete
                                                    </v-icon>
                                        </v-col>
                                    </v-row>
                                </v-content>

                                <v-content v-if="SelectedAccessoires" >
                                    <div v-for="(accessoire , index) in SelectedAccessoires" :key="index">
                                        <v-row v-if="accessoire != null">
                                            <v-col class="" lg="7"  >
    <!--                                            <span class="font-weight-bold">{{ accessoire.data }}</span>-->
                                                <span class="font-weight-bold">{{ accessoire.data.name }}</span>

                                            </v-col>
                                            <v-col class="px-0" cols="4">
                                                <span>{{ accessoire.data.price }} €</span>
                                            </v-col>
                                            <v-col class="px-0" cols="1">
                                                <v-icon
                                                    v-if="!hideDelete"
                                                    small
                                                    @click="deleteItem('Accessoire', accessoire.index)"
                                                    color="white"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-content>

                            </v-col>

                            <v-overlay
                                :absolute="false"
                                :value="modalJante"
                                :z-index="5"
                            >
                                <v-col >

                                    <v-card light >

                                        <v-card-text  class="text-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">
                                            <span class="font-weight-bold title">Supprimer les jantes ?</span>
                                            <br>
                                            <span>Vous allez supprimer toutes les jantes de votre panier, et être redirigés au choix de jantes.</span>
                                            <br>
                                            <span v-if="SelectedPneuAv">La suppression des jantes entrainera la suppression des pneus.</span>
                                            <v-row>
                                                <v-col lg="6" >
                                                    <v-btn @click="deleteJante">
                                                        Confirmer
                                                    </v-btn>
                                                </v-col>
                                                <v-col lg="6">
                                                    <v-btn @click="modalJante = false">
                                                        Annuler
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>

                                    </v-card>
                                </v-col>
                            </v-overlay>

                            <v-overlay
                                    :absolute="false"
                                    :value="modalPneu"
                                    :z-index="5"
                                >
                                    <v-col >

                                        <v-card light >

                                            <v-card-text  class="text-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">
                                                <span class="font-weight-bold title">Supprimer les pneus ?</span>
                                                <br>
                                                <span>Vous allez supprimer les pneus sélectionnées de votre panier.</span>
                                                <v-row>
                                                    <v-col lg="6" >
                                                        <v-btn @click="deletePneu">
                                                            Confirmer
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col lg="6">
                                                        <v-btn @click="modalPneu = false">
                                                            Annuler
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>

                                        </v-card>
                                    </v-col>
                                </v-overlay>

                            <v-overlay
                                    :absolute="false"
                                    :value="modalAccessoire"
                                    :z-index="5"
                                >
                                    <v-col >

                                        <v-card light >

                                            <v-card-text  class=" text-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">
                                                <span class="font-weight-bold title">Supprimer cet accessoire ?</span>
                                                <br>
                                                <span>Vous allez supprimer l'accessoire sélectionné de votre panier.</span>
                                                <v-row>
                                                    <v-col lg="6" >
                                                        <v-btn @click="deleteAccessoire">
                                                            Confirmer
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col lg="6">
                                                        <v-btn @click="modalAccessoire = false">
                                                            Annuler
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>

                                        </v-card>
                                    </v-col>
                                </v-overlay>

                            <v-row class="border-top">
                                <v-col class="py-1 border-bot" lg="8">Sous-total</v-col>
                                <v-col v-if="this.typeMonte === 'par4'" class="py-1 border-bot" lg="4">{{ getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, 'SOUS TOTAL') }} €</v-col>
                                <v-col v-else class="py-1 border-bot" lg="4">{{ getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv ? SelectedPneuAv.prixNet : 0, SelectedPneuArr ? SelectedPneuArr.prixNetRear : 0 , 'SOUS TOTAL DECALE') }} €</v-col>
                            </v-row>
                            <v-row v-if="this.SelectedJanteAv && this.SelectedPneuAv">
                                <v-col class="py-1 border-bot" lg="8">Forfait montage 4 jantes + 4 pneus (monté, valvé et équilibré)</v-col>
                                <v-col class="py-1 border-bot" lg="4">Offert</v-col>
                            </v-row>
                            <v-row>
                                <v-col class="py-1 border-bot" lg="8">Expédition</v-col>
                                <v-col class="py-1 border-bot" lg="4">Gratuit</v-col>
                            </v-row>
                            <v-row>
                                <v-col class="py-1 border-bot" lg="8">Frais d’emballage</v-col>
                                <v-col class="py-1 border-bot" lg="4">9,90 €</v-col>
                            </v-row>

                            <v-content v-if="currentItem !== 4">
<!--                            <v-content :class="{'collapse':currentItem === 4}">-->

                                <v-row v-for="(accessoire, index) in accessoiresComputed" :key="index">
                                    <v-col  :class="{'whiteColor' : (isSelectedIndex[index] === true) }" class="pl-0 pb-0 pt-1" lg="7"  >
<!--                                            <v-checkbox @change="addCart(index, accessoire)" class="ma-0" hide-details dense dark v-model="isSelectedIndex[index]"  :label="accessoire.name"/>-->
                                            <v-checkbox @change="addCart1(index, accessoire)" class="ma-0" hide-details dense dark v-model="isSelectedIndex[index]"  :label="accessoire.name"/>
<!--                                            <v-checkbox @change="addCart0(index, accessoire)" class="ma-0" hide-details dense dark  :label="accessoire.name"/>-->

                                    </v-col>
                                    <v-col class="px-0" cols="1" :class="{'whiteColor' : (isSelectedIndex[index] === true) }">
                                        <v-img v-if="VUE_APP_SITE_ID === '1'" @mouseover="switchHover(index)" @mouseleave="switchHover(index)" src="@/assets/icons/icon_infos3.svg" style="cursor: pointer"/>
                                        <v-img v-if="VUE_APP_SITE_ID === '2'" @mouseover="switchHover(index)" @mouseleave="switchHover(index)" src="@/assets/icons/icon_infos_blanc.svg" style="cursor: pointer"/>
                                        <v-img v-if="hover && hoveredIndex === index " :src="accessoire.img" style="position: absolute;max-width: 60%; z-index: 3"/>
                                    </v-col>
                                    <v-col :class="{'whiteColor' : (isSelectedIndex[index] === true), 'tc_lightGrey': VUE_APP_SITE_ID === '1', 'tc_white': VUE_APP_SITE_ID === '2' }" cols="3" class="align-self-center pb-0 pt-1 text-right">
                                        <span>{{accessoire.price}} €</span>
                                    </v-col>

                                </v-row>


                                <v-row>
                                    <v-col @click="showAccessoires" class="font-weight-bold" style="text-decoration: underline;cursor: pointer ">Voir tous les accessoires</v-col>
                                </v-row>

                                <v-row>
                                    <v-col lg="7">
                                        <v-text-field hide-details solo color="#232D4D" label="Code promo" v-model="promo"></v-text-field>
                                    </v-col>
                                    <v-col lg="5" class="align-self-center">
                                        <v-btn v-if="!promoUsed" class="caption font-weight-bold" @click="submitPromo(promo)" >Appliquer le <br> code promo</v-btn>
                                        <v-btn v-if="promoUsed" disabled class="caption" >Appliquer le <br> code promo</v-btn>
                                    </v-col>
                                    <p v-if="promoUsed" class="tc_white"> Le code promo a été appliqué</p>
                                </v-row>
                            </v-content>



<!--                            <v-row>-->
<!--                                <v-col :class="{'border-top':currentItem !== 4}">Total</v-col>-->
<!--                                <v-col v-if="this.typeMonte === 'par4'" :class="{'border-top':currentItem !== 4}">-->
<!--                                    <v-col  class="pa-0 font-weight-bold body-1 tc_turquoise" >{{ getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv.prixNet, 'TOTAL') }} €</v-col>-->
<!--                                    <v-col class="pa-0">dont {{ getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv.prixNet, 'TVA') }} €</v-col>-->
<!--                                    <v-col class="pa-0">de TVA</v-col>-->
<!--                                </v-col>-->
<!--                                <v-col v-else class="border-top">-->
<!--                                    <v-col class="pa-0 font-weight-bold body-1 tc_turquoise" >{{ getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv.prixNet, SelectedPneuArr.prixNetRear, 'TOTAL') }}  €</v-col>-->
<!--                                    <v-col class="pa-0">dont {{ getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv.prixNet, SelectedPneuArr.prixNetRear, 'TVA') }} €</v-col>-->
<!--                                    <v-col class="pa-0">de TVA</v-col>-->
<!--                                </v-col>-->
<!--                            </v-row>-->

                        </v-col>
                </v-col>
            </v-content>
        </v-col>

        <v-overlay
            :value="showDetailCommande"
            :z-index="7"
        >
            <v-col>

                <v-card v-if="$vuetify.breakpoint.mdAndDown" v-click-outside="onClickOutsideDetailCommande" light style="border-radius:20px; overflow-y: scroll;max-height: 80vh">
                    <v-col offset-lg="1" lg="10" class="pa-0 ">
                        <v-col class="pa-0 text-end">
                            <v-btn text @click="closeShowDetailCommande">
                                <v-icon  color="black">mdi mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="border-bot-black pt-0">
                            <span class="font-weight-bold">Votre commande</span>
                        </v-col>
                        <v-col class="caption pt-0">
                            <v-row class="border-bot-black">
                                <v-col class="py-0 " lg="7">
                                    <span>Produit</span>
                                </v-col>
                                <v-col class="py-0" lg="4">
                                    <span>Total</span>
                                </v-col>
                            </v-row>
                            <v-col class="pa-0" v-if="!productSelected">
                                <v-content v-if="(JSON.stringify(this.SelectedJanteAv) === JSON.stringify(this.SelectedJanteArr) && this.SelectedJanteAv)">

                                    <v-content v-if="this.SelectedJanteAv.largeurMinAv === this.SelectedJanteAv.largeurMaxArr && this.SelectedJanteAv.offsetFront === this.SelectedJanteAv.offsetRear">                                        <v-row>
                                            <v-col class="" lg="7" >
                                                <span class="font-weight-bold">{{ SelectedJanteAv.marque }} {{ SelectedJanteAv.modele }} {{ SelectedJanteAv.couleur }} x4</span>
                                                <br>
                                                <span>{{ SelectedJanteAv.largeur +'X'+SelectedJanteAv.diametre + ' - ' +SelectedJanteAv.entraxe + ' - ET ' + SelectedJanteAv.offset }}</span>
                                                <br>
                                            </v-col>
                                            <v-col class="px-0" cols="4">
                                                <span>{{getRoundPrice(SelectedJanteAv.public_price,null, '4jantes')}} €</span>
                                            </v-col>
                                            <v-col class="px-0" cols="1">
                                                <v-icon v-if="!hideDelete"
                                                        small
                                                        @click="deleteItem('Jantes')"
                                                    >
                                                        mdi-delete
                                                    </v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-content>
                                    <v-content v-else-if="this.SelectedJanteAv.largeurMinAv !== this.SelectedJanteAv.largeurMaxArr || this.SelectedJanteAv.offsetFront !== this.SelectedJanteAv.offsetRear">                                        <v-row>
                                            <v-col class="" lg="7" >
                                                <span class="font-weight-bold">{{ SelectedJanteAv.marque }} {{ SelectedJanteAv.modele }} {{ SelectedJanteAv.couleur }} x2</span>
                                                <br>
                                                <span>{{ SelectedJanteAv.largeurMinAv +'X'+SelectedJanteAv.diametre + ' - ' +SelectedJanteAv.entraxe + ' - ET ' + SelectedJanteAv.offsetFront }}</span>
                                                <br>
                                            </v-col>
                                            <v-col class="px-0" cols="4">
                                                <span>{{getRoundPriceMonteDecale(SelectedJanteAv.public_price, null, null, null,'2jantesAv')}} €</span>
                                            </v-col>
                                            <v-col class="px-0" cols="1">
                                                <v-icon v-if="!hideDelete"
                                                        small
                                                        @click="deleteItem('Jantes')"
                                                    >
                                                        mdi-delete
                                                    </v-icon>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <span class="font-weight-bold">{{ SelectedJanteArr.marque }} {{ SelectedJanteArr.modele }} {{ SelectedJanteArr.couleur }} x2</span>
                                                <br>
                                                <span>{{ SelectedJanteArr.largeurMaxArr +'X'+SelectedJanteArr.diametre + ' - ' +SelectedJanteArr.entraxe + ' - ET ' + SelectedJanteArr.offsetRear }}</span>
                                                <br>
                                            </v-col>
                                            <v-col class="px-0" lg="4" >
                                                <span>{{getRoundPriceMonteDecale( null, SelectedJanteArr.public_price_rear,null, null,'2jantesArr')}} €</span>
                                            </v-col>
                                            <v-col lg="1" class="px-0" >
                                                <v-icon v-if="!hideDelete"
                                                        small
                                                        @click="deleteItem('Jantes')"
                                                    >
                                                        mdi-delete
                                                    </v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-content>
                                    <v-content v-else>
                                        <v-row>
                                            <v-col class="" lg="7" >
                                                <span class="font-weight-bold">{{ SelectedJanteAv.marque }} {{ SelectedJanteAv.modele }} {{ SelectedJanteAv.couleur }} x2</span>
                                                <br>
                                                <span>{{ SelectedJanteAv.largeur +'X'+SelectedJanteAv.diametre + ' - ' +SelectedJanteAv.entraxe + ' - ET ' + SelectedJanteAv.offset }}</span>
                                                <br>
                                            </v-col>
                                            <v-col class="px-0" cols="4">
                                                <span>{{getRoundPrice(SelectedJanteAv.public_price,null,'2jantes')}} €</span>
                                            </v-col>
                                            <v-col class="px-0" cols="1">
                                                <v-icon v-if="!hideDelete"
                                                        small
                                                        @click="deleteItem('Jantes')"
                                                    >
                                                        mdi-delete
                                                    </v-icon>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col>
                                                <span class="font-weight-bold">{{ SelectedJanteArr.marque }} {{ SelectedJanteArr.modele }} {{ SelectedJanteArr.couleur }} x2</span>
                                                <br>
                                                <span>{{ SelectedJanteArr.largeur +'X'+SelectedJanteArr.diametre + ' - ' +SelectedJanteArr.entraxe + ' - ET ' + SelectedJanteArr.offset }}</span>
                                                <br>
                                            </v-col>
                                            <v-col class="px-0" lg="4" >
                                                <span>{{getRoundPrice(SelectedJanteArr.public_price,null,'2jantes')}} €</span>
                                            </v-col>
                                            <v-col lg="1" class="px-0" >
                                                <v-icon v-if="!hideDelete"
                                                        small
                                                        @click="deleteItem('Jantes')"
                                                    >
                                                        mdi-delete
                                                    </v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-content>
                                </v-content>

                                <v-content v-else>
                                    <v-row>
                                        <v-col class="" lg="7" >
                                            <span class="font-weight-bold">{{ SelectedJanteAv.marque }} {{ SelectedJanteAv.modele }} {{ SelectedJanteAv.couleur }} x2</span>
                                            <br>
                                            <span>{{ SelectedJanteAv.largeur +'X'+SelectedJanteAv.diametre + ' - ' +SelectedJanteAv.entraxe + ' - ET ' + SelectedJanteAv.offsetFront }}</span>
                                            <br>
                                        </v-col>
                                        <v-col class="px-0" cols="4">
                                            <span>{{getRoundPriceMonteDecale(SelectedJanteAv.public_price,null,null, null,'2jantesAv')}} €</span>
                                        </v-col>
                                        <v-col class="px-0" cols="1">
                                            <v-icon
                                                v-if="!hideDelete"
                                                small
                                                @click="deleteItem('Jantes')"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <span class="font-weight-bold">{{ SelectedJanteArr.marque }} {{ SelectedJanteArr.modele }} {{ SelectedJanteArr.couleur }} x2</span>
                                            <br>
                                            <span>{{ SelectedJanteArr.largeur +'X'+SelectedJanteArr.diametre + ' - ' +SelectedJanteArr.entraxe + ' - ET ' + SelectedJanteArr.offsetRear }}</span>
                                            <br>
                                        </v-col>
                                        <v-col class="px-0" lg="4" >
                                            <span>{{getRoundPriceMonteDecale( null, SelectedJanteArr.public_price_rear,null, null,'2jantesArr')}} €</span>
                                        </v-col>
                                        <v-col lg="1" class="px-0" >
                                            <v-icon
                                                v-if="!hideDelete"
                                                small
                                                @click="deleteItem('Jantes')"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                </v-content>


                                <v-content  v-if='(JSON.stringify(this.SelectedPneuAv) === JSON.stringify(this.SelectedPneuArr) && this.SelectedPneuAv)'>
                                    <v-content v-if="this.monteDecaPneu === true">
                                        <v-content v-if="this.SelectedPneuAv.HBV === this.SelectedPneuAv.HBVREAR">
                                            <v-row>
                                                <v-col class="" lg="7" >
                                                    <span class="font-weight-bold">{{ SelectedPneuAv.marque }} {{ SelectedPneuAv.modele }} x4</span>
                                                    <br>
                                                    <span>{{SelectedPneuAv.HBV}}/{{SelectedPneuAv.width}}R{{SelectedPneuAv.diametre}} {{SelectedPneuAv.indiceDeCharge}} {{SelectedPneuAv.indiceDeVitesse}}</span>
                                                    <br>
                                                    <span v-if="SelectedPneuAv.runFlat === 1">
                                                    Runflat
                                                </span>
                                                    <span v-else>
                                                    Non Runflat
                                                </span>
                                                    <span v-if="SelectedPneuAv.hiver === 1">
                                                    Hiver
                                                    <v-icon color="light-blue">mdi mdi-snowflake</v-icon>
                                                </span>
                                                    <span v-else-if="SelectedPneuAv.ete === 1">
                                                    Eté
                                                    <v-icon color="yellow">mdi mdi-brightness-7</v-icon>
                                                </span>
                                                    <span v-else-if="SelectedPneuAv.allsaison === 1"  class="d-flex" style="align-items: center">
                                                    4 saisons
                                                    <v-img src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                </span>
                                                </v-col>
                                                <v-col class="px-0" cols="4">
                                                    <span>{{getRoundPrice(null, SelectedPneuAv.prixNet, '4pneus')}} €</span>
                                                </v-col>
                                                <v-col class="px-0" cols="1">
                                                    <v-icon
                                                        v-if="!hideDelete"
                                                        small
                                                        @click="deleteItem('Pneus')"
                                                    >
                                                        mdi-delete
                                                    </v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-content>
                                        <v-content v-else-if="this.SelectedPneuAv.HBV !== this.SelectedPneuAv.HBVREAR">
                                            <v-row>
                                                <v-col class="" lg="7" >
                                                    <span class="font-weight-bold">{{ SelectedPneuAv.marque }} {{ SelectedPneuAv.modele }} x2</span>
                                                    <br>
                                                    <span>{{SelectedPneuAv.HBV}}/{{SelectedPneuAv.width}}R{{SelectedPneuAv.diametre}} {{SelectedPneuAv.indiceDeCharge}} {{SelectedPneuAv.indiceDeVitesse}}</span>
                                                    <br>
                                                    <span v-if="SelectedPneuAv.runFlat === 1">
                                                    Runflat
                                                </span>
                                                    <span v-else>
                                                    Non Runflat
                                                </span>
                                                    <span v-if="SelectedPneuAv.hiver === 1">
                                                    Hiver
                                                    <v-icon color="light-blue">mdi mdi-snowflake</v-icon>
                                                </span>
                                                    <span v-else-if="SelectedPneuAv.ete === 1">
                                                    Eté
                                                    <v-icon color="yellow">mdi mdi-brightness-7</v-icon>
                                                </span>
                                                    <span v-else-if="SelectedPneuAv.allsaison === 1"  class="d-flex" style="align-items: center">
                                                    4 saisons
                                                    <v-img src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                </span>
                                                </v-col>
                                                <v-col class="px-0" cols="4">
                                                    <span>{{getRoundPriceMonteDecale(null, null, SelectedPneuAv.prixNet, null,'2pneusAv')}} €</span>
                                                </v-col>
                                                <v-col class="px-0" cols="1">
                                                    <v-icon
                                                        v-if="!hideDelete"
                                                        small
                                                        @click="deleteItem('Pneus')"
                                                    >
                                                        mdi-delete
                                                    </v-icon>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col class="" lg="7" >
                                                    <span class="font-weight-bold">{{ SelectedPneuArr.marque }} {{ SelectedPneuArr.modele }} x2</span>
                                                    <br>
                                                    <span>{{SelectedPneuArr.HBVREAR}}/{{SelectedPneuArr.widthRear}}R{{SelectedPneuArr.diametre}} {{SelectedPneuArr.indiceDeChargeRear}} {{SelectedPneuArr.indiceDeVitesse}} </span>
                                                    <br>
                                                    <span v-if="SelectedPneuArr.runFlat === 1">
                                                    Runflat
                                                </span>
                                                    <span v-else>
                                                    Non Runflat
                                                </span>
                                                    <span v-if="SelectedPneuArr.hiver === 1">
                                                    Hiver
                                                    <v-icon color="light-blue">mdi mdi-snowflake</v-icon>
                                                </span>
                                                    <span v-else-if="SelectedPneuArr.ete === 1">
                                                    Eté
                                                    <v-icon color="yellow">mdi mdi-brightness-7</v-icon>
                                                </span>
                                                    <span v-else-if="SelectedPneuArr.allsaison === 1"  class="d-flex" style="align-items: center">
                                                    4 saisons
                                                    <v-img src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                </span>
                                                </v-col>
                                                <v-col class="px-0" cols="4">
                                                    <span>{{getRoundPriceMonteDecale(null, null, null, SelectedPneuArr.prixNetRear,'2pneusArr')}} €</span>
                                                </v-col>
                                                <v-col class="px-0" cols="1">
                                                    <v-icon
                                                        v-if="!hideDelete"
                                                        small
                                                        @click="deleteItem('Pneus')"
                                                    >
                                                        mdi-delete
                                                    </v-icon>
                                                </v-col>
                                            </v-row>
                                        </v-content>
                                    </v-content>
                                    <v-content v-else>
                                        <v-row>
                                            <v-col class="" lg="7" >
                                                <span class="font-weight-bold">{{ SelectedPneuAv.marque }} {{ SelectedPneuAv.modele }} x4</span>
                                                <br>
                                                <span>{{SelectedPneuAv.HBV}}/{{SelectedPneuAv.width}}R{{SelectedPneuAv.diametre}} {{SelectedPneuAv.indiceDeCharge}} {{SelectedPneuAv.indiceDeVitesse}}</span>
                                                <br>
                                                <span v-if="SelectedPneuAv.runFlat === 1">
                                                    Runflat
                                                </span>
                                                <span v-else>
                                                    Non Runflat
                                                </span>
                                                <span v-if="SelectedPneuAv.hiver === 1">
                                                    Hiver
                                                    <v-icon color="light-blue">mdi mdi-snowflake</v-icon>
                                                </span>
                                                <span v-else-if="SelectedPneuAv.ete === 1">
                                                    Eté
                                                    <v-icon color="yellow">mdi mdi-brightness-7</v-icon>
                                                </span>
                                                <span v-else-if="SelectedPneuAv.allsaison === 1"  class="d-flex" style="align-items: center">
                                                    4 saisons
                                                    <v-img src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                </span>
                                            </v-col>
                                            <v-col class="px-0" cols="4">
                                                <span>{{getRoundPrice(null, SelectedPneuAv.prixNet, '4pneus')}} €</span>
                                            </v-col>
                                            <v-col class="px-0" cols="1">
                                                <v-icon
                                                    v-if="!hideDelete"
                                                    small
                                                    @click="deleteItem('Pneus')"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </v-content>

                                </v-content>

                                <v-content v-else-if="this.SelectedPneuAv">
                                    <v-row>
                                        <v-col class="" lg="7" >
                                            <span class="font-weight-bold">{{ SelectedPneuAv.marque }} {{ SelectedPneuAv.modele }} x2</span>
                                            <br>
                                            <span>{{SelectedPneuAv.HBV}}/{{SelectedPneuAv.width}}R{{SelectedPneuAv.diametre}} {{SelectedPneuAv.indiceDeCharge}} {{SelectedPneuAv.indiceDeVitesse}}</span>
                                            <br>
                                            <span v-if="SelectedPneuAv.runFlat === 1">
                                                    Runflat
                                                </span>
                                            <span v-else>
                                                    Non Runflat
                                                </span>
                                            <span v-if="SelectedPneuAv.hiver === 1">
                                                    Hiver
                                                    <v-icon color="light-blue">mdi mdi-snowflake</v-icon>
                                                </span>
                                            <span v-else-if="SelectedPneuAv.ete === 1">
                                                    Eté
                                                    <v-icon color="yellow">mdi mdi-brightness-7</v-icon>
                                                </span>
                                            <span v-else-if="SelectedPneuAv.allsaison === 1"  class="d-flex" style="align-items: center">
                                                    4 saisons
                                                    <v-img src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                </span>
                                        </v-col>
                                        <v-col class="px-0" cols="4">
                                            <span>{{getRoundPrice(null, SelectedPneuAv.prixNet, '2pneus')}} €</span>
                                        </v-col>
                                        <v-col class="px-0" cols="1">
                                            <v-icon
                                                v-if="!hideDelete"
                                                small
                                                @click="deleteItem('Pneus')"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="" lg="7" >
                                            <span class="font-weight-bold">{{ SelectedPneuArr.marque }} {{ SelectedPneuArr.modele }} x2</span>
                                            <br>
                                            <span>{{SelectedPneuArr.HBV}}/{{SelectedPneuArr.width}}R{{SelectedPneuArr.diametre}} {{SelectedPneuArr.indiceDeCharge}} {{SelectedPneuArr.indiceDeVitesse}} </span>
                                            <br>
                                            <span v-if="SelectedPneuArr.runFlat === 1">
                                                    Runflat
                                                </span>
                                            <span v-else>
                                                    Non Runflat
                                                </span>
                                            <span v-if="SelectedPneuArr.hiver === 1">
                                                    Hiver
                                                    <v-icon color="light-blue">mdi mdi-snowflake</v-icon>
                                                </span>
                                            <span v-else-if="SelectedPneuArr.ete === 1">
                                                    Eté
                                                    <v-icon color="yellow">mdi mdi-brightness-7</v-icon>
                                                </span>
                                            <span v-else-if="SelectedPneuArr.allsaison === 1"  class="d-flex" style="align-items: center">
                                                    4 saisons
                                                    <v-img src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                </span>
                                        </v-col>
                                        <v-col class="px-0" cols="4">
                                            <span>{{getRoundPrice(null, SelectedPneuArr.prixNet, '2pneus')}} €</span>
                                        </v-col>
                                        <v-col class="px-0" cols="1">
                                            <v-icon
                                                v-if="!hideDelete"
                                                small
                                                @click="deleteItem('Pneus')"
                                            >
                                                mdi-delete
                                            </v-icon>
                                        </v-col>
                                    </v-row>
                                </v-content>

                                <v-content v-if="SelectedAccessoires" >
                                    <div v-for="(accessoire , index) in SelectedAccessoires" :key="index">
                                        <v-row v-if="accessoire != null">
                                            <v-col class="" lg="7"  >
                                                <!--                                            <span class="font-weight-bold">{{ accessoire.data }}</span>-->
                                                <span class="font-weight-bold">{{ accessoire.data.name }}</span>

                                            </v-col>
                                            <v-col class="px-0" cols="4">
                                                <span>{{ accessoire.data.price }} €</span>
                                            </v-col>
                                            <v-col class="px-0" cols="1">
                                                <v-icon
                                                    v-if="!hideDelete"
                                                    small
                                                    @click="deleteItem('Accessoire', accessoire.index)"
                                                >
                                                    mdi-delete
                                                </v-icon>
                                            </v-col>
                                        </v-row>
                                    </div>
                                </v-content>

                            </v-col>

                            <v-overlay
                                :absolute="false"
                                :value="modalJante"
                                :z-index="5"
                            >
                                <v-col >

                                    <v-card light >

                                        <v-card-text  class="text-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">
                                            <span class="font-weight-bold title">Supprimer les jantes ?</span>
                                            <br>
                                            <span>Vous allez supprimer toutes les jantes de votre panier, et être redirigés au choix de jantes.</span>
                                            <br>
                                            <span v-if="SelectedPneuAv">La suppression des jantes entrainera la suppression des pneus.</span>
                                            <v-row>
                                                <v-col lg="6" >
                                                    <v-btn @click="deleteJante">
                                                        Confirmer
                                                    </v-btn>
                                                </v-col>
                                                <v-col lg="6">
                                                    <v-btn @click="modalJante = false">
                                                        Annuler
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>

                                    </v-card>
                                </v-col>
                            </v-overlay>

                            <v-overlay
                                :absolute="false"
                                :value="modalPneu"
                                :z-index="5"
                            >
                                <v-col >

                                    <v-card light >

                                        <v-card-text  class=" text-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">
                                            <span class="font-weight-bold title">Supprimer les pneus ?</span>
                                            <br>
                                            <span>Vous allez supprimer les pneus sélectionnées de votre panier.</span>
                                            <v-row>
                                                <v-col lg="6" >
                                                    <v-btn @click="deletePneu">
                                                        Confirmer
                                                    </v-btn>
                                                </v-col>
                                                <v-col lg="6">
                                                    <v-btn @click="modalPneu = false">
                                                        Annuler
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>

                                    </v-card>
                                </v-col>
                            </v-overlay>

                            <v-overlay
                                :absolute="false"
                                :value="modalAccessoire"
                                :z-index="5"
                            >
                                <v-col >

                                    <v-card light >

                                        <v-card-text  class=" text-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">
                                            <span class="font-weight-bold title">Supprimer cet accessoire ?</span>
                                            <br>
                                            <span>Vous allez supprimer l'accessoire sélectionné de votre panier.</span>
                                            <v-row>
                                                <v-col lg="6" >
                                                    <v-btn @click="deleteAccessoire">
                                                        Confirmer
                                                    </v-btn>
                                                </v-col>
                                                <v-col lg="6">
                                                    <v-btn @click="modalAccessoire = false">
                                                        Annuler
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-text>

                                    </v-card>
                                </v-col>
                            </v-overlay>

                            <v-row class="border-top">
                                <v-col class="py-1 border-bot-black" cols="6">Sous-total</v-col>
                                <v-col v-if="this.typeMonte === 'par4'" class="py-1 pl-6 border-bot-black" lg="4">{{ getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv ? SelectedPneuAv.prixNet : null  , 'SOUS TOTAL') }} €</v-col>
                                <v-col v-else class="py-1 border-bot-black" lg="4">{{ getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv ? SelectedPneuAv.prixNet : null, SelectedPneuArr ? SelectedPneuArr.prixNetRear : null , 'SOUS TOTAL DECALE') }} €</v-col>
                            </v-row>
                            <v-row v-if="this.SelectedJanteAv && this.SelectedPneuAv">
                                <v-col class="py-1 border-bot-black" cols="6">Forfait montage 4 jantes + 4 pneus (monté, valvé et équilibré)</v-col>
                                <v-col class="py-1 border-bot-black pl-6" lg="4">Offert</v-col>
                            </v-row>
                            <v-row>
                                <v-col class="py-1 border-bot-black" cols="6">Expédition</v-col>
                                <v-col class="py-1 border-bot-black pl-6" lg="4">Gratuit</v-col>
                            </v-row>
                            <v-row>
                                <v-col class="py-1 border-bot-black" cols="6">Frais d’emballage</v-col>
                                <v-col class="py-1 border-bot-black pl-6" lg="4">9,90 €</v-col>
                            </v-row>

                            <v-content v-if="currentItem !== 4">
                                <!--                            <v-content :class="{'collapse':currentItem === 4}">-->

                                <v-row v-for="(accessoire, index) in accessoiresComputed" :key="index">
                                    <v-col  :class="{'blackColor' : (isSelectedIndex[index] === true) }" class="pl-0 pb-0 pt-0 d-flex my-auto"  lg="7"  >
                                        <v-checkbox @change="addCart1(index, accessoire)" class="ma-0" hide-details dense light v-model="isSelectedIndex[index]"  :label="accessoire.name"/>
                                    </v-col>
                                    <v-col class="px-0" cols="1" :class="{'blackColor' : (isSelectedIndex[index] === true) }">
                                        <v-img v-if="VUE_APP_SITE_ID === '1'" @mouseover="switchHover(index)" @mouseleave="switchHover(index)" src="@/assets/icons/icon_infos3.svg" style="cursor: pointer" :style="[ $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                                                                                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '60%'} :
                                                                                                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '80%'} : '']"/>
                                        <v-img v-if="VUE_APP_SITE_ID === '2'" @mouseover="switchHover(index)" @mouseleave="switchHover(index)" src="@/assets/icons/icon_infos_blanc.svg" style="cursor: pointer" :style="[ $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                                                                                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '60%'} :
                                                                                                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '80%'} : '']"/>
                                        <v-img v-if="hover && hoveredIndex === index " :src="accessoire.img" style="position: absolute;max-width: 60%; z-index: 3; right: 0" />
                                    </v-col>
                                    <v-col :class="{'blackColor' : (isSelectedIndex[index] === true) }" cols="3" class="align-self-center py-0 text-right">
                                        <span :class="[ (isSelectedIndex[index] === true) ? 'blackColor' : 'tc_lightGrey' ]" >{{accessoire.price}} €</span>
                                    </v-col>

                                </v-row>


                                <v-row>
                                    <v-col @click="openShowDetailAccessoires" class="font-weight-bold" style="text-decoration: underline;cursor: pointer ">Voir tous les accessoires</v-col>
                                </v-row>

                                <v-row>
                                    <v-col lg="7">
                                        <v-text-field hide-details solo color="#232D4D" label="Code promo" v-model="promo"></v-text-field>
                                    </v-col>
                                    <v-col lg="5" class="align-self-center">
                                        <v-btn v-if="!promoUsed" class="caption font-weight-bold" @click="submitPromo(promo)" >Appliquer le <br> code promo</v-btn>
                                        <v-btn v-if="promoUsed" disabled class="caption" >Appliquer le <br> code promo</v-btn>
                                    </v-col>
                                    <p v-if="promoUsed" class="tc_white"> Le code promo a été appliqué</p>
                                </v-row>
                            </v-content>



                            <!--                            <v-row>-->
                            <!--                                <v-col :class="{'border-top':currentItem !== 4}">Total</v-col>-->
                            <!--                                <v-col v-if="this.typeMonte === 'par4'" :class="{'border-top':currentItem !== 4}">-->
                            <!--                                    <v-col  class="pa-0 font-weight-bold body-1 tc_turquoise" >{{ getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv.prixNet, 'TOTAL') }} €</v-col>-->
                            <!--                                    <v-col class="pa-0">dont {{ getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv.prixNet, 'TVA') }} €</v-col>-->
                            <!--                                    <v-col class="pa-0">de TVA</v-col>-->
                            <!--                                </v-col>-->
                            <!--                                <v-col v-else class="border-top">-->
                            <!--                                    <v-col class="pa-0 font-weight-bold body-1 tc_turquoise" >{{ getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv.prixNet, SelectedPneuArr.prixNetRear, 'TOTAL') }}  €</v-col>-->
                            <!--                                    <v-col class="pa-0">dont {{ getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv.prixNet, SelectedPneuArr.prixNetRear, 'TVA') }} €</v-col>-->
                            <!--                                    <v-col class="pa-0">de TVA</v-col>-->
                            <!--                                </v-col>-->
                            <!--                            </v-row>-->

                        </v-col>
                    </v-col>

                </v-card>


            </v-col>
        </v-overlay>


        <v-overlay
            :value="showDetailAccessoires"
            :z-index="9"
        >
            <!--                            <v-col offset-lg="2" lg="8" style="overflow-y: scroll;max-height: 80vh">-->
            <v-card v-click-outside="onClickOutsideAccessoires" light style="border-radius:20px; overflow-y: scroll;max-height: 80vh" >
                <v-col offset-lg="2" lg="8" >
                <v-content class="resizeHeight">
                    <v-card light hover >
                        <v-col class="pb-0 baseline">
                            <v-btn text @click="closeShowDetailAccessoires">
                                <v-icon large color="black">mdi mdi-close</v-icon>
                            </v-btn>
                        </v-col>
                        <v-col class="pa-3">
                            <v-row>
                                <v-col class=" font-weight-bold text-center">
                                    Choisissez vos accessoires
                                </v-col>
                            </v-row>
                            <v-row>

                                <v-col cols="6" v-for="(accessoire, index) in accessoires" :key="index" class="pa-1" >
                                    <!--                                                    <v-card @click="addCart(index, accessoire.name)" hover height="100%">-->
                                    <v-card @click="addCart(index, accessoire)" hover height="100%">
                                        <v-img :src="accessoire.img"/>
                                        <v-checkbox @click="addCart(index, accessoire)" class="ma-0 size-font" hide-details dense v-model="isSelectedIndex[index]" :label="accessoire.name"/>
                                        <v-col class=" pb-0 text-center " >{{accessoire.price}} €</v-col>
                                    </v-card>
                                </v-col>

                                <!--                                                <v-col lg="3" v-for="(accessoire, index) in accessoires" :key="index" >-->

                                <!--                                                    <v-img src="@/assets/cache-ecrou.jpg" style="max-width: 60%"/>-->
                                <!--                                                    <v-checkbox @change="addCart($event,index, accessoire.name)" class="ma-0" hide-details dense v-model="isSelectedIndex[index]" :label="accessoire.name"/>-->
                                <!--                                                    <v-col class=" pb-0" >{{accessoire.price}}</v-col>-->

                                <!--                                                </v-col>-->
                            </v-row>
                        </v-col>
                        <v-col  class="pb-6 d-flex justify-center" >
                            <v-btn  @click="closeShowDetailAccessoires">
                                Confirmer
                            </v-btn>
                        </v-col>
                    </v-card>
                </v-content>
            </v-col>
            </v-card>



        </v-overlay>

    </v-content>
</template>

<script>
    import bleu1 from '@/assets/menu/1_bleu.svg'
    import bleu2 from '@/assets/menu/2_bleu.svg'
    import bleu3 from '@/assets/menu/3_bleu.svg'
    import gris1EJ from '@/assets/menu/1_grisEJ.svg'
    import gris2EJ from '@/assets/menu/2_grisEJ.svg'
    import gris3EJ from '@/assets/menu/3_grisEJ.svg'
    import gris4EJ from '@/assets/menu/4_grisEJ.svg'

    import orange1 from '@/assets/menu/1_orange.svg'
    import orange2 from '@/assets/menu/2_orange.svg'
    import orange3 from '@/assets/menu/3_orange.svg'

    import gris1DJ from '@/assets/menu/1_grisDJ.svg'
    import gris2DJ from '@/assets/menu/2_grisDJ.svg'
    import gris3DJ from '@/assets/menu/3_grisDJ.svg'
    import gris4DJ from '@/assets/menu/4_grisDJ.svg'




    import cacheEcrou from "@/assets/accessoires/cache-ecrou.jpg"
    import valvesCoudees from "@/assets/accessoires/valves-coudees.jpg"
    import brillantPneus from "@/assets/accessoires/brillant_pneus.jpg"
    import {mapState} from "vuex";
    import Vue from 'vue'
    import axios from 'axios'
    import VueAxios from 'vue-axios'
    import antivols from "@/assets/accessoires/antivols_de_roues.jpg";
    import valvesAlu from "@/assets/accessoires/valve-alu.jpg";
    import valvesElectro from "@/assets/accessoires/valve_electro.jpg";
    import BagueCentrage from "@/assets/accessoires/bague-de-centrage.jpg";
    import antiInsect from "@/assets/accessoires/nettoyant-insecte.jpg";
    import changementEntraxe from "@/assets/accessoires/g_elargisseur_ch_entraxe.jpg";
    import PeintureBleu from "@/assets/accessoires/peinture-etrier-blue.jpg";
    import PeintureJaune from "@/assets/accessoires/peinture-etrier-yellow.jpg";
    import PeintureRouge from "@/assets/accessoires/peinture-etrier-red.jpg";

    import SecureLS from "secure-ls";
    var ls = new SecureLS({ isCompression: false });

    Vue.use(VueAxios, axios)

    export default {
        name: "Menu.vue",

        data : ()=> ({
            // isProfessional: false,
            hideDelete:false,
            menus: [
                {id: '1', title:'Mes jantes', desc1: '4 JANTES', desc2: '', imgBlue: bleu1, imgOrange: orange1 ,imgGreyEJ: gris1EJ, imgGreyDJ: gris1DJ},
                {id: '2', title:'Mon montage', desc1: '', desc2: '', imgBlue: bleu2 ,imgOrange: orange2,  imgGreyEJ: gris2EJ, imgGreyDJ: gris2DJ},
                {id: '3', title:'Mes pneus (facultatif)', desc1: 'Pneus avants', desc2: 'Pneus arrières', imgBlue: bleu3 ,imgOrange: orange3, imgGreyEJ: gris3EJ, imgGreyDJ: gris3DJ},
                {id: '4', title:'Règlement', imgGreyEJ: gris4EJ, imgGreyDJ: gris4DJ},
            ],
            currentItem: "1",
            accesoire:'',
            accessoires:[
                {name:'20 Caches boulon', price:'20,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:cacheEcrou, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'4 Antivols de roues', price:'30,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:antivols, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'4 Valves de roues et bouchons alu', price:'10,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:valvesAlu, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'4 Valves electroniques', price:'200,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:valvesElectro, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'4 Bagues de centrage', price:'30,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:BagueCentrage, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'Valves coudées', price:'150,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img: valvesCoudees, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'Anti insect', price:'12,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:antiInsect, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'Changement entraxe Audi/VW', price:'380,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:changementEntraxe, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'Kit peinture étrier bleu', price:'50,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:PeintureBleu, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'Kit peinture étrier jaune', price:'50,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:PeintureJaune, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'Kit peinture étrier rouge', price:'50,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:PeintureRouge, quantite: 1, type:3, flux:null, buying_price: null},
                {name:'Brillant pour pneus', price:'12,00', reference:'EJ-accessoires', marque:"EJ-accessoires", img:brillantPneus, quantite: 1, type:3, flux:null, buying_price: null},
            ],
            accessoiresLimit:3,
            productSelected:false,
            hover:false,
            hoveredIndex: null,
            drawer:false,
            promo:'',
            modalJante: false,
            modalPneu: false,
            modalAccessoire: false,
            accessoireToDelete:null,
            isSelectedIndex:[],
            prevStep:'',
            typeMonte: null,
            overlayAccessoires:false,
            SelectedJanteAv: {},
            SelectedJanteArr: {},
            SelectedPneuAv: {},
            SelectedPneuArr: {},
            VUE_APP_SITE_ID:null,
        }),
        computed:{
            accessoiresComputed(){
                return this.accessoiresLimit ? this.accessoires.slice(0,this.accessoiresLimit) : this.accessoires
            },
            ...mapState({
                promoUsed: state => state.infoJantes.promoUsed,
                idUser: state => state.auth.userId,
                isProfessional: state => state.auth.isRevendeur,
                nbrCart: state => state.nbrCart.nbrCart,
                showDetailCommande: state => state.detailCommande.detailCommande,
                showDetailAccessoires: state => state.detailCommande.detailAccessoires,
                tauxRemise: state => state.auth.tauxRemise,

            }),
        },
        mounted () {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID

            this.currentItem = ((this.$route.name).slice(-1) -1);
            this.prevStep = (parseInt(this.$route.name.substring(5)) - 1);
            this.typeMonte = (this.$route.params.decale).toString() === '0' ? 'par4' : 'decale'
            this.typeMonte === 'decale' ? this.monteDecaJante = true : this.monteDecaJante = false
            this.typeMonte === 'decale' ? this.monteDecaPneu = true : this.monteDecaPneu = false

            if(this.$route.name === "Etape5"){
                this.hideDelete = true
            }

        },
        created() {

            this.SelectedJanteAv = this.getOnglet('jantesAv')
            this.SelectedJanteArr = this.getOnglet('jantesArr')
            this.SelectedPneuAv = this.getOnglet('pneusAv') ? this.getOnglet('pneusAv') : null
            this.SelectedPneuArr = this.getOnglet('pneusArr') ? this.getOnglet('pneusArr') : null
            this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
            this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
            this.isSelected = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
            this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0

        },
        updated() {
            // console.log('Menu Updated', this.typeMonte, this.currentItem)

            if(this.$route.name === 'Etape1') {
                this.menus[0].desc1 = '4 JANTES ' + this.SelectedJanteAv?.marque + ' ' + this.SelectedJanteAv?.modele
            }else{
                this.menus[0].desc1 = '4 JANTES ' + this.SelectedJanteAv?.modele + ' ' + this.SelectedJanteAv?.couleur
            }
            this.menus[0].desc2 = this.SelectedJanteAv?.diametre + ' POUCES'

            if(this.currentItem === 2){


                if(this.typeMonte === 'par4'){
                    this.menus[1].desc1 = this.SelectedJanteAv.largeur +'X'+this.SelectedJanteAv.diametre + ' - ' +this.SelectedJanteAv.entraxe + ' - et ' + this.SelectedJanteAv.offset
                    this.menus[1].desc2 = this.SelectedJanteArr.largeur +'X'+this.SelectedJanteArr.diametre + ' - ' +this.SelectedJanteArr.entraxe + ' - et ' + this.SelectedJanteArr.offset

                }else{
                    this.menus[1].desc1 = this.SelectedJanteAv.largeurMinAv +'X'+this.SelectedJanteAv.diametre + ' - ' +this.SelectedJanteAv.entraxe + ' - et ' + this.SelectedJanteAv.offsetFront
                    this.menus[1].desc2 = this.SelectedJanteArr.largeurMaxArr +'X'+this.SelectedJanteArr.diametre + ' - ' +this.SelectedJanteArr.entraxe + ' - et ' + this.SelectedJanteArr.offsetRear
                }

                // this.SelectedJanteAv.largeurMinAv ? this.menus[1].desc1 = this.SelectedJanteAv.largeurMinAv +'X'+this.SelectedJanteAv.diametre + ' - ' +this.SelectedJanteAv.entraxe + ' - et ' + this.SelectedJanteAv.offsetFront : this.menus[1].desc1 = this.SelectedJanteAv.largeur +'X'+this.SelectedJanteAv.diametre + ' - ' +this.SelectedJanteAv.entraxe + ' - et ' + this.SelectedJanteAv.offset
                // this.SelectedJanteArr.largeurMaxArr ? this.menus[1].desc2 = this.SelectedJanteArr.largeurMaxArr +'X'+this.SelectedJanteArr.diametre + ' - ' +this.SelectedJanteArr.entraxe + ' - et ' + this.SelectedJanteArr.offsetRear : this.menus[1].desc2 = this.SelectedJanteArr.largeur +'X'+this.SelectedJanteArr.diametre + ' - ' +this.SelectedJanteArr.entraxe + ' - et ' + this.SelectedJanteArr.offset



                this.SelectedPneuAv ? this.menus[2].desc1 = 'Pneus avants : ' + this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' +this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' '+ this.SelectedPneuAv.indiceDeVitesse : 'Pneus avants'

                if( this.monteDecaJante && this.typeMonte !== "par4"){
                  this.SelectedPneuArr ? this.menus[2].desc2 = 'Pneus arrières : ' + this.SelectedPneuArr.HBVREAR + '/' + this.SelectedPneuArr.widthRear + 'R' +this.SelectedPneuArr.diametre + ' ' + this.SelectedPneuArr.indiceDeChargeRear + ' '+ this.SelectedPneuArr.indiceDeVitesse : 'Pneus arrières'
                }else{
                  this.SelectedPneuArr ? this.menus[2].desc2 = 'Pneus arrières : ' + this.SelectedPneuArr.HBV + '/' + this.SelectedPneuArr.width + 'R' +this.SelectedPneuArr.diametre + ' ' + this.SelectedPneuArr.indiceDeCharge + ' '+ this.SelectedPneuArr.indiceDeVitesse : 'Pneus arrières'
                }

            }


        },
        methods:{
            openShowDetailAccessoires:function () {
                this.$store.commit('detailCommande/setDetailAccessoires', true)
            },
            closeShowDetailAccessoires:function () {
              this.$store.commit('detailCommande/setDetailAccessoires', false)
                setTimeout(()=>{
                    this.showOverlayMenu()
                },1 )
            },
            onClickOutsideAccessoires:function () {
                console.log('outside accessoires')
              this.$store.commit('detailCommande/setDetailAccessoires', false)
                this.showOverlayMenu()

            },
            closeShowDetailCommande:function () {
              this.$store.commit('detailCommande/setDetailCommande', false)
            },
            onClickOutsideDetailCommande:function () {
                console.log('outside commande')

            // if(this.showDetailAccessoires === false){
                this.$store.commit('detailCommande/setDetailCommande', false)
            // }

            },
            showOverlayMenu:function(){
                console.log('showOverlayMenu');
                this.$store.commit('detailCommande/setDetailCommande', true)
            },
            switchHover:function (index) {
                this.hover = !this.hover
                this.hoveredIndex = index
                // console.log(index)
                // console.log(this.hover)
            },
            addCart0:function (index,  accessoire) {
console.log('addcart',index, accessoire, this.isSelectedIndex)
console.log('addcart2', this.isSelectedIndex[index])
                if(this.isSelectedIndex[index] === undefined || this.isSelectedIndex[index] === false){
                    console.log('if undef or false -> add')

                    this.isSelectedIndex[index] = true

                    this.isAccessoire = {}
                    this.isAccessoire.index = index
                    this.isAccessoire.data = accessoire
                    this.isAccessoire.selected = true

                    this.SelectedAccessoires[index] = this.isAccessoire


                    // this.isSelected = Object.assign({}, this.isSelected)
                    // this.$store.commit('infoAccessoires/setAccessoire', this.SelectedAccessoire)
                    // this.$store.commit('infoAccessoires/setSelected', this.isSelectedIndex)

                    this.setOnglet(this.SelectedAccessoires, 'accessoires')
                    this.setOnglet(this.isSelectedIndex, 'indexAccessoires')


                    this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
                    this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
                    this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0

                    return
                }
                if(this.isSelectedIndex[index] === true){
                    console.log('if true -> delete')
                    this.isSelectedIndex[index] = false


                    // this.isSelected = Object.assign({}, this.isSelected)
                    // this.$store.commit('infoAccessoires/deleteOneAccessoire', index)
                    // this.$store.commit('infoAccessoires/setSelected', this.isSelected)
                    // this.$store.commit('infoAccessoires/setSelected', this.isSelectedIndex)

                    var newCart = this.getOnglet('accessoires')

                    // delete newCart[index]

                    // console.log('newcart', newCart)

                    this.setOnglet(newCart, 'accessoires')

                    this.setOnglet(this.isSelectedIndex, 'indexAccessoires')

                    return
                }
            },
            addCart1:function (index,  accessoire) {
                console.log('addCart1')

                if(this.isSelectedIndex[index] === true){
                // if(this.isSelectedIndex[index] === undefined || this.isSelectedIndex[index] === false){
                    console.log('now true')

                    // this.isSelectedIndex[index] = true

                    this.isAccessoire = {}
                    this.isAccessoire.index = index
                    this.isAccessoire.data = accessoire
                    this.isAccessoire.selected = true

                    this.SelectedAccessoires[index] = this.isAccessoire

                    // console.log('this.SelectedAccessoires', this.SelectedAccessoires)
                    // this.isSelected = Object.assign({}, this.isSelected)
                    // this.$store.commit('infoAccessoires/setAccessoire', this.SelectedAccessoire)
                    // this.$store.commit('infoAccessoires/setSelected', this.isSelectedIndex)

                    this.setOnglet(this.SelectedAccessoires, 'accessoires')
                    this.setOnglet(this.isSelectedIndex, 'indexAccessoires')


                    this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
                    this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
                    this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0

                    this.$emit('updated', 'TriggerUpdate' )

                    return
                }
                if(this.isSelectedIndex[index] === false || this.isSelectedIndex[index] === undefined ){
                    console.log('now false')
                    // this.isSelectedIndex[index] = false


                    // this.isSelected = Object.assign({}, this.isSelected)
                    // this.$store.commit('infoAccessoires/deleteOneAccessoire', index)
                    // this.$store.commit('infoAccessoires/setSelected', this.isSelected)
                    // this.$store.commit('infoAccessoires/setSelected', this.isSelectedIndex)

                    var newCart = this.getOnglet('accessoires')

                    // console.log('oldCart', newCart)

                    delete newCart[index]

                    // console.log('newcart', newCart)

                    this.setOnglet(newCart, 'accessoires')
                    this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []

                    this.TotalPriceAccessoires = 0
                    Object.values(this.SelectedAccessoires).forEach((e) => {
                        if(e != null) {
                            this.TotalPriceAccessoires += parseInt(e.data.price)
                        }
                    })

                    this.setOnglet(this.isSelectedIndex, 'indexAccessoires')
                    this.$emit('updated', 'TriggerUpdate' )

                    return
                }
            },
            addCart:function (index,  accessoire) {
console.log('addCart')
                // this.isSelected = this.isSelectedIndex
                if(this.isSelectedIndex[index] === undefined || this.isSelectedIndex[index] === false){
                    console.log('now true')


                    this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
                    this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
                    this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0



                    this.isSelectedIndex[index] = true

                    this.isAccessoire = {}
                    this.isAccessoire.index = index
                    this.isAccessoire.data = accessoire
                    this.isAccessoire.selected = true

                    this.SelectedAccessoires[index] = this.isAccessoire

                    this.isSelectedIndex = Object.assign({}, this.isSelectedIndex)

                    this.setOnglet(this.SelectedAccessoires, 'accessoires')
                    this.setOnglet(this.isSelectedIndex, 'indexAccessoires')



                    this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
                    this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
                    this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0


                    this.TotalPriceAccessoires = 0
                    Object.values(this.SelectedAccessoires).forEach((e) => {
                        if(e != null) {
                            this.TotalPriceAccessoires += parseInt(e.data.price)
                        }
                    })

                    return
                }
                if(this.isSelectedIndex[index] === true){
                    console.log('now false')


                    this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
                    this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
                    this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0


                    this.isSelectedIndex[index] = false


                    this.isSelectedIndex = Object.assign({}, this.isSelectedIndex)
                    var newCart = this.getOnglet('accessoires')

                    // console.log('oldCart', newCart)

                    delete newCart[index]

                    // console.log('newcart', newCart)

                    this.setOnglet(newCart, 'accessoires')
                    this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []

                    this.TotalPriceAccessoires = 0
                    Object.values(this.SelectedAccessoires).forEach((e) => {
                        if(e != null) {
                            this.TotalPriceAccessoires += parseInt(e.data.price)
                        }
                    })

                    this.setOnglet(this.isSelectedIndex, 'indexAccessoires')


                    return
                }
            },
            deleteItem (item, index) {
                // const index = this.cart.indexOf(item)
                // confirm('Are you sure you want to delete this item?') && this.cart.splice(index, 1)
                // console.log(item)

                if(item === 'Jantes'){
                    this.modalJante = true
                }
                if(item === 'Pneus'){
                    this.modalPneu = true
                }
                if(item === 'Accessoire'){
                    // console.log('deleteItemAccessoire', index)
                    this.accessoireToDelete = index
                    this.modalAccessoire = true
                }
            },
            deleteJante:function () {
                this.$store.commit('infoJantes/deleteJante')
                this.$store.commit('infoPneus/deletePneu')
                this.$store.commit('infoAccessoires/deleteAllAccessoires')
                this.$store.commit('nbrCart/setNbrCart', 0)

                this.modalJante = false
                // this.cartJanteAv = this.SelectedJanteAv
                // this.cartJanteArr = this.SelectedJanteArr
                // this.cartPneu = this.SelectedPneuAv
                this.cart = []
                this.cartJanteAv = null
                this.cartJanteArr = null
                // this.cartPneu = null
                this.$emit('updated', 'TriggerUpdate' )
                this.$router.push({name:'Listing'})
            },
            deletePneu:function () {

                this.removeOngletData('pneus')
                this.modalPneu = false

                if(this.monteDecaPneu === true){
                    this.$store.commit('nbrCart/setNbrCart', this.nbrCart - 2)
                }else{
                    this.$store.commit('nbrCart/setNbrCart', this.nbrCart - 1)
                }
                this.$emit('updated', 'TriggerUpdate' )
                // this.cartPneu = this.SelectedPneuAv
            },
            deleteAccessoire:function () {
                // this.isSelected = this.isSelectedIndex
                this.isSelectedIndex[this.accessoireToDelete] = false


                var newCart = this.getOnglet('accessoires')

                delete newCart[this.accessoireToDelete]

                // console.log('newcart', newCart)

                this.setOnglet(newCart, 'accessoires')
                this.setOnglet(this.isSelectedIndex, 'indexAccessoires')


                this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
                this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []


                this.TotalPriceAccessoires = 0
                Object.values(this.SelectedAccessoires).forEach((e) => {
                    if(e != null) {
                        this.TotalPriceAccessoires += parseInt(e.data.price)
                    }
                })
                // this.$store.commit('infoAccessoires/deleteOneAccessoire', this.accessoireToDelete)
                // this.$store.commit('infoAccessoires/setSelected', this.isSelected)

                this.$store.commit('nbrCart/setNbrCart', this.nbrCart - 1)
                this.$emit('updated', 'TriggerUpdate' )
                this.modalAccessoire = false
            },
            showAccessoires: function(){
                // this.overlayAccessoires = true
                this.$emit('clicked', this.isSelectedIndex )
            },
            getRoundPrice: function (Prixjante, PrixPneu, type){
                let price
                var tmp = Math.pow(10, 2);

                if(this.isProfessional){
                    let taux = (1 - parseFloat('0.' + this.tauxRemise))
                    taux = (Math.round( taux * tmp )/tmp).toFixed(2)

                    Prixjante = Prixjante * taux
                }

                Prixjante = (Math.round( Prixjante * tmp )/tmp).toFixed(2)
                PrixPneu = Math.round(PrixPneu * tmp )/tmp.toFixed(2)

                switch(type){
                    case('4jantes'):
                        if(Prixjante){
                            price  = (Prixjante * 4)
                        }
                        break;
                    case('1jante'):
                        if(Prixjante){
                            price  = Prixjante
                        }
                        break;
                    case('2jantes'):
                        if(Prixjante){
                            price  = (Prixjante * 2)
                        }
                        break;
                    case('4pneus'):
                        if(PrixPneu){
                            price  = ((PrixPneu + 10) * 4)
                        }
                        break;
                    case('2pneus'):
                        if(PrixPneu){
                            price  = ((PrixPneu + 10) * 2)
                        }
                        break;
                    case('1pneu'):
                        if(PrixPneu){
                            price  = (PrixPneu + 10)
                        }
                        break;
                    case('SOUS TOTAL'):
                        if(Prixjante && PrixPneu){
                            price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires)
                        }else if(Prixjante){
                            price  = ((Prixjante * 4) + this.TotalPriceAccessoires)
                        }
                        break;
                    case('TOTAL'):
                        if(Prixjante && PrixPneu){
                            price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90)
                        }else if(Prixjante){
                            price  = ((Prixjante * 4) + this.TotalPriceAccessoires + 9.90)
                        }
                        break;
                    case('TOTAL COFIDIS'):
                        if(Prixjante && PrixPneu){
                            price  = (((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90) * 1.022)
                        }else if(Prixjante){
                            price  = (((Prixjante * 4) + this.TotalPriceAccessoires + 9.90) * 1.022)
                        }
                        break;
                    case('COFIDIS'):
                        if(Prixjante && PrixPneu){
                            price  = (((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires) * 1.022) /4
                        }else if(Prixjante){
                            price  = (((Prixjante * 4) + this.TotalPriceAccessoires) * 1.022) /4
                        }
                        break;
                    case('TVA'):
                        if(Prixjante && PrixPneu){
                            price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90 ) * 0.2
                        }else if(Prixjante){
                            price  = ((Prixjante * 4) + this.TotalPriceAccessoires + 9.90) * 0.2
                        }
                        break;
                }

                return (Math.round( price * tmp )/tmp).toFixed(2)
            },
            getRoundPriceMonteDecale: function (PrixjanteAv, PrixjanteArr, PrixPneuAv, PrixPneuArr, type){
                let price
                var tmp = Math.pow(10, 2);

                if(this.isProfessional){
                    let taux = (1 - parseFloat('0.' + this.tauxRemise))
                    taux = (Math.round( taux * tmp )/tmp).toFixed(2)

                    PrixjanteAv = PrixjanteAv * taux
                    PrixjanteArr = PrixjanteArr * taux
                }

                PrixjanteAv = Math.round(PrixjanteAv * tmp )/tmp.toFixed(2)
                PrixjanteArr = Math.round(PrixjanteArr * tmp )/tmp.toFixed(2)
                PrixPneuAv = Math.round(PrixPneuAv * tmp )/tmp.toFixed(2)
                PrixPneuArr = Math.round(PrixPneuArr * tmp )/tmp.toFixed(2)

                switch(type){
                    case('4jantes decale'):
                        if(PrixjanteAv && PrixjanteArr){
                            price  = (PrixjanteAv * 2) + (PrixjanteArr * 2)
                        }
                        break;
                    case('4pneus decale'):
                        if(PrixPneuAv && PrixPneuArr){
                            price  = (((PrixPneuAv + 10) * 2) + ((PrixPneuArr + 10) * 2))
                        }
                        break;
                    case('2jantesAv'):
                        if(PrixjanteAv){
                            price  = (PrixjanteAv * 2)
                        }
                        break;
                    case('2jantesArr'):
                        if(PrixjanteArr){
                            price  = (PrixjanteArr * 2)
                        }
                        break;
                    case('2pneusAv'):
                        if(PrixPneuAv){
                            price  = ((PrixPneuAv + 10) * 2)
                        }
                        break;
                    case('2pneusArr'):
                        if(PrixPneuArr){
                            price  = ((PrixPneuArr + 10) * 2)
                        }
                        break;
                    case('1jante'):
                        if(PrixjanteAv){
                            price  = PrixjanteAv
                        }
                        if(PrixjanteArr){
                            price  = PrixjanteArr
                        }
                        break;
                    case('1pneu'):
                        if(PrixPneuAv){
                            price  = (PrixPneuAv + 10)
                        }
                        if(PrixPneuArr){
                            price  = (PrixPneuArr + 10)
                        }
                        break;
                    case('SOUS TOTAL DECALE'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires)
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires)
                        }
                        break;
                    case('TOTAL'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires + 9.90)
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires + 9.90)
                        }
                        break;
                    case('TOTAL COFIDIS'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = (((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires + 9.90) * 1.022)
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = (((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires + 9.90) * 1.022)
                        }
                        break;
                    case('TVA'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires + 9.90) * 0.2
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires + 9.90) * 0.2
                        }
                        break;
                    case('COFIDIS DECALE'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)  +  (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires) /4
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)  + this.TotalPriceAccessoires) /4
                        }
                        break;
                }
                return (Math.round( price * tmp )/tmp).toFixed(2)
            },
            submitPromo:function (data){

                axios.get('https://api.espace-jantes.com/getOnePromotionForCart', {params: {nomPromotion: data, websiteid: this.VUE_APP_SITE_ID}})
                    .then((resp)=>{
                        if(resp.data.length){
                            // console.log('montant',resp.data[0].montant)
                            // console.log('montant /4',(resp.data[0].montant / 4))
                            // console.log('previous price',parseFloat(this.SelectedJanteAv.public_price))
                            //
                            // console.log(resp)

                            // console.log('new price', parseFloat(this.SelectedJanteAv.public_price) - (resp.data[0].montant / 4))
                            this.SelectedJanteAv.public_price = parseFloat(this.SelectedJanteAv.public_price) - (resp.data[0].montant / 4)
                            this.SelectedJanteArr.public_price = parseFloat(this.SelectedJanteArr.public_price) - (resp.data[0].montant / 4)
                            if(this.SelectedJanteArr.public_price_rear) {
                                this.SelectedJanteArr.public_price_rear = parseFloat(this.SelectedJanteArr.public_price_rear) - (resp.data[0].montant / 4)
                            }
                            // this.setOnglet(resp.data[0].montant, 'codePromo')
                            this.setOnglet(this.SelectedJanteAv, 'jantesAv')
                            this.setOnglet(this.SelectedJanteArr, 'jantesArr')


                            // this.$store.commit('infoJantes/setJante', {Av:this.SelectedJanteAv})
                            this.$store.commit('infoJantes/setJante', {promoUsed:true})
                            this.$emit('updated', 'TriggerUpdate' )

                        }else{
                            console.log('code invalide')
                        }

                    })

            },

            removeOngletData(value){
                console.log('removeOngletData',value)
                var lastUpdated = ls.get('last_updated')

                switch(value){
                    case"jantes":
                        ls.remove(lastUpdated + '__infosJantes')
                        ls.remove(lastUpdated + '__infosPneus')
                        ls.remove(lastUpdated + '__infosAccessoires')

                        this.triggerUpdate ++
                        break
                    case"pneus":
                        ls.remove(lastUpdated + '__infosPneus')

                        this.triggerUpdate ++
                        break
                    case"accessoires":
                        ls.remove(lastUpdated + '__infosAccessoires')
                        this.triggerUpdate ++
                        break

                }
                this.SelectedJanteAv = this.getOnglet('jantesAv')
                this.SelectedJanteArr = this.getOnglet('jantesArr')
                this.SelectedPneuAv = this.getOnglet('pneusAv') ? this.getOnglet('pneusAv') : null
                this.SelectedPneuArr = this.getOnglet('pneusArr') ? this.getOnglet('pneusArr') : null
                this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
                this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
                this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0
            },
            setOnglet(item, value){
                // console.log('setOnglet',value, item)

                switch(value){
                    case"4jantes":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : item, jantesArr : item})
                        this.infoJante = item
                        this.currentColor = this.colors[0].name;
                        this.photoPrincipal = this.infoJante.photo_1
                        this.triggerUpdate ++
                        break
                    case"jantesAv":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : item, jantesArr : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr})
                        this.triggerUpdate ++
                        break
                    case"jantesArr":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv, jantesArr : item})
                        this.triggerUpdate ++
                        break
                    case"4pneus":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : item, pneusArr : item})
                        this.triggerUpdate ++
                        break
                    case"pneusAv":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : item, pneusArr : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr})
                        this.triggerUpdate ++
                        break
                    case"pneusArr":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv, pneusArr : item})
                        this.triggerUpdate ++
                        break
                    case"accessoires":
                        this.TotalPriceAccessoires = 0
                        Object.values(item).forEach((e) => {
                            if(e != null) {
                                this.TotalPriceAccessoires += parseInt(e.data.price)
                            }
                        })
                        // console.log('this.TotalPriceAccessoires', this.TotalPriceAccessoires)
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires', {accessoires: item, totalPrice: this.TotalPriceAccessoires})

                        this.triggerUpdate ++
                        break
                    case"indexAccessoires":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires', {accessoires: ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').accessoires,totalPrice: ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').totalPrice , selected: item })
                        this.triggerUpdate ++
                        break
                    case"modelePossibleAuCarre":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre', item)
                        break
                    case"modelePossibleFront":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront', item)
                        break
                    case"modelePossibleRear":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear', item)
                        break
                }

                ls.set('last_updated', this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale)

            },
            getOnglet(value){
// console.log('access', value)
                switch(value){
                    case'jantesAv':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv
                    case 'jantesArr':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr
                    case 'pneusAv':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv
                    case 'pneusArr':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr
                    case 'accessoires':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').accessoires
                    case 'totalPriceAccessoires':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').totalPrice
                    case 'indexAccessoires':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').selected
                    case 'modelePossibleAuCarre':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre')
                    case 'modelePossibleFront':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront')
                    case 'modelePossibleRear':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear')
                }
            },
            removePneu(){
                ls.remove(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus')
                this.$router.push({name:'Listing'})
            }
        },
        watch:{
            '$props':{
                handler: function (val) {
                    // console.log('triggerUpdateMenu', val)

                    if(val.hide){
                        this.hideDelete = val.hide
                    }


                    this.SelectedJanteAv = this.getOnglet('jantesAv')
                    this.SelectedJanteArr = this.getOnglet('jantesArr')
                    this.SelectedPneuAv = this.getOnglet('pneusAv') ? this.getOnglet('pneusAv') : null
                    this.SelectedPneuArr = this.getOnglet('pneusArr') ? this.getOnglet('pneusArr') : null
                    this.SelectedAccessoires = this.getOnglet('accessoires') ? this.getOnglet('accessoires') : []
                    this.isSelectedIndex = this.getOnglet('indexAccessoires') ? this.getOnglet('indexAccessoires') : []
                    // this.TotalPriceAccessoires = this.getOnglet('totalPriceAccessoires') ? this.getOnglet('totalPriceAccessoires') : 0
                    // this.SelectedJanteAv = Object.assign({}, this.getOnglet('jantesAv'))

                    this.TotalPriceAccessoires = 0
                    Object.values(this.SelectedAccessoires).forEach((e) => {
                        if(e != null) {
                            this.TotalPriceAccessoires += parseInt(e.data.price)
                        }
                    })

                    if(this.$route.name === 'Etape1') {
                        this.menus[0].desc1 = '4 JANTES ' + this.SelectedJanteAv?.marque + ' ' + this.SelectedJanteAv?.modele
                    }else{
                        this.menus[0].desc1 = '4 JANTES ' + this.SelectedJanteAv?.modele + ' ' + this.SelectedJanteAv?.couleur
                    }
                    this.menus[0].desc2 = this.SelectedJanteAv.diametre + ' POUCES'

                    if(this.currentItem === 2){

                        this.SelectedJanteAv.largeurMinAv ? this.menus[1].desc1 = this.SelectedJanteAv.largeurMinAv +'X'+this.SelectedJanteAv.diametre + ' - ' +this.SelectedJanteAv.entraxe + ' - et ' + this.SelectedJanteAv.offsetFront : this.menus[1].desc1 = this.SelectedJanteAv.largeur +'X'+this.SelectedJanteAv.diametre + ' - ' +this.SelectedJanteAv.entraxe + ' - et ' + this.SelectedJanteAv.offset
                        this.SelectedJanteArr.largeurMaxArr ? this.menus[1].desc2 = this.SelectedJanteArr.largeurMaxArr +'X'+this.SelectedJanteArr.diametre + ' - ' +this.SelectedJanteArr.entraxe + ' - et ' + this.SelectedJanteArr.offsetRear : this.menus[1].desc2 = this.SelectedJanteArr.largeur +'X'+this.SelectedJanteArr.diametre + ' - ' +this.SelectedJanteArr.entraxe + ' - et ' + this.SelectedJanteArr.offset


                        // this.SelectedPneuAv ? this.menus[2].desc1 = 'Pneus avants' + this.SelectedPneuAv.width + '/' + this.SelectedPneuAv.HBV + ' ' +this.SelectedPneuAv.marque + ' ' + this.SelectedPneuAv.diametre : 'Pneus avants'
                        JSON.stringify(this.SelectedPneuAv) !== '""' ? this.menus[2].desc1 = 'Pneus avants : ' + this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' +this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' '+ this.SelectedPneuAv.indiceDeVitesse : 'Pneus avants'
                        if( this.monteDecaJante && this.typeMonte !== "par4"){
                            JSON.stringify(this.SelectedPneuArr) !== '""' ? this.menus[2].desc2 = 'Pneus arrières : ' + this.SelectedPneuArr.HBVREAR + '/' + this.SelectedPneuArr.widthRear + 'R' +this.SelectedPneuArr.diametre + ' ' + this.SelectedPneuArr.indiceDeChargeRear + ' '+ this.SelectedPneuArr.indiceDeVitesse : 'Pneus arrières'
                        }else{
                            JSON.stringify(this.SelectedPneuArr) !== '""' ? this.menus[2].desc2 = 'Pneus arrières : ' + this.SelectedPneuArr.HBV + '/' + this.SelectedPneuArr.width + 'R' +this.SelectedPneuArr.diametre + ' ' + this.SelectedPneuArr.indiceDeCharge + ' '+ this.SelectedPneuArr.indiceDeVitesse : 'Pneus arrières'
                        }

                    }
                },
                deep: true
            }
        },
        props:['update', 'hide']

    }
</script>

<style scoped>
    .currentEJ{
        color:white!important;
    }
    .currentDJ{
        color:black!important;
    }
    .v-input /deep/ label{
        font-size: 13px;
    }
    .whiteColor /deep/ label{
        font-size: 13px;
        color: white!important;
    }
    .blackColor /deep/ label{
        font-size: 13px;
        color: black!important;
    }
    .collapse{
        visibility: collapse;
    }
    .border-bot{
        border-bottom: 1px solid white
    }
    .border-top{
        border-top: 1px solid white
    }
    .border-bot-black{
        border-bottom: 1px solid black
    }
    .border-top-black{
        border-top: 1px solid black
    }
    .img_placement {
        width: 15%;
        margin-left: -15%
    }
    .img_placement_mobile {
        max-width: 20%;
        margin-left: -7%
    }

</style>
