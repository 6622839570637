<template>

    <v-app  fluid >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>



        <v-main v-if="VUE_APP_SITE_ID === '1'">

            <v-col class=" font-weight-regular
                            text-xl-h5
                            text-lg-body-1
                            text-md-caption
                            text-sm-caption
                            text-caption">
                <v-col offset-lg="1" lg="10" >
                    <v-card @click="article1 = !article1" >
                        <v-row    >
                            <v-col offset-lg="1" lg="10" cols="10">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_blue
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 1. MENTIONS LEGALES</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article1" x-large class="pr-4 " >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4 " >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article1" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">1.1 Editeur</p>
                                    <span>
                                        Vous êtes actuellement connecté au site internet www.espace-jantes.com, édité par Espace-Jantes, SARL, capital social 10 000 €, RCS d’ Avignon : 530 437 375, siège social : 23 avenue Benjamin Franklin 84120 Pertuis, France, téléphone : 0970721916, email : contact@espace-jantes.com, n° de TVA intracommunautaire : FR 74 530437375, directeur de la publication et responsable de la rédaction : Arnaud TREILLON.
                                    </span>
                                    <br>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">1.2 Hébergeur</p>
                                    <span>Publicom</span>
                                    <br>
                                    <span>10 route de Galice – 13100 Aix-en-Provence</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article2 = !article2" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_blue
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 2. DEFINITIONS</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article2" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article2" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p>
                                        « <b>Client</b> » : toute personne, physique ou morale, de droit privé ou de droit public, inscrite sur le Site.
                                    </p>
                                    <p>« <b>Espace-Jantes.com</b> » : Espace-Jantes, SARL, pris en sa qualité d’éditeur du Site.</p>
                                    <p>« <b>Internaute</b> » : toute personne, physique ou morale, de droit privé ou de droit public, se connectant au Site.</p>
                                    <p>« <b>Produit</b> » : bien proposé à la vente par Espace-Jantes.com sur le Site et consistant notamment en différents modèles de jantes et de pneus.</p>
                                    <p>« <b>Site</b> » : site internet accessible à l’URL www.espace-jantes.com, ainsi que les sous-sites, sites miroirs, portails et variations d’URL y afférant.</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article3 = !article3" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_blue
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 3. CHAMP D’APPLICATION</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article3" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article3" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p>
                                        Le Site est d’accès libre et gratuit à tout Internaute. La navigation sur le Site suppose l’acceptation par tout Internaute des présentes conditions générales. La simple connexion au Site emportera acceptation pleine et entière des présentes conditions générales.
                                    </p>
                                    <p>Lors de l’inscription sur le Site, cette acceptation sera confirmée par le fait de cocher la case correspondant à la phrase suivante : « Je reconnais avoir lu et accepté les conditions générales de vente et d’utilisation ». L’Internaute reconnaît du même fait en avoir pris pleinement connaissance et les accepter sans restriction.</p>
                                    <p>Le fait de cocher la case susvisée sera réputé avoir la même valeur qu’une signature manuscrite de la part du Client. L’Internaute reconnaît la valeur de preuve des systèmes d’enregistrement automatique de Espace-Jantes.com et, sauf pour lui d’apporter preuve contraire, il renonce à les contester en cas de litige.</p>
                                    <p>Les présentes conditions générales sont applicables aux relations entre les parties à l’exclusion de toutes autres conditions, et notamment celles du Client.</p>
                                    <p>L’acceptation des présentes conditions générales suppose de la part des Internautes qu’ils jouissent de la capacité juridique nécessaire pour cela, ou à défaut qu’ils en aient l’autorisation d’un tuteur ou d’un curateur s’ils sont incapables, de leur représentant légal s’ils sont mineurs, ou encore qu’ils soient titulaires d’un mandat s’ils agissent pour le compte d’une personne morale.</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article4 = !article4" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_blue
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 4. OBJET DU SITE</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article4" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article4" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p>Le Site a pour objet la vente de Produits aux Clients particuliers et professionnels.</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article5 = !article5" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_blue
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 5. ETAPES DE LA COMMANDE</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article5" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article5" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">5.1 Commande</p>
                                    <span>
                                        Afin de passer commande, les Internautes pourront sélectionner un ou plusieurs Produits et les ajouter à leur panier.
                                        La disponibilité des produits est indiquée sur le Site, dans la fiche descriptive de chaque article.
                                        Le cas échéant, le Client devra préciser sa commande en indiquant notamment la marque ou la dimension souhaitée.
                                        Lorsque leur commande sera complète, ils pourront accéder à leur panier en cliquant sur le bouton prévu à cet effet.
                                    </span>
                                    <br>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">5.2 Validation de la commande par l’Internaute</p>
                                    <p>En consultant leur panier, les Internautes auront la faculté de vérifier le nombre ainsi que la nature des Produits qu’ils auront choisis et pourront vérifier leur prix unitaire, ainsi que leur prix global. Ils auront la possibilité de retirer un ou plusieurs Produits de leur panier.</p>
                                    <p>Si leur commande leur convient, les Internautes pourront la valider. Ils accèderont alors à un formulaire sur lequel ils pourront soit saisir leurs identifiants de connexion s’ils en possèdent déjà, soit s’inscrire sur le Site en complétant le formulaire d’inscription au moyen des informations personnelles les concernant.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">5.3 Paiement par le Client</p>
                                    <p>Dès lors qu’ils seront connectés ou après qu’ils auront parfaitement complété le formulaire d’inscription, les Clients seront invités à contrôler ou modifier leurs coordonnées de livraison et de facturation, puis seront invités à effectuer leur paiement en étant redirigés à cet effet sur l’interface de paiement sécurisée.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">5.4 Confirmation de la commande par Espace-Jantes.com</p>
                                    <p>Une fois le paiement effectivement reçu par Espace-Jantes.com, ce dernier s’engage à en accuser réception au Client par voie électronique, dans un délai maximal de 24 heures. Dans le même délai, Espace-Jantes.com s’engage à adresser au Client un courrier électronique récapitulatif de la commande et lui en confirmant le traitement, reprenant toutes les informations y afférant.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">5.5 Système de prévention des fraudes</p>
                                    <p>Afin d’assurer la sécurité des transactions et pour répondre à la demande de prévention des fraudes en matière de vente à distance, Espace-Jantes.com effectue des contrôles aléatoires sur la passation des commandes. Ainsi, Espace-Jantes.com est susceptible d’adresser aux Clients un courrier électronique émanant de l’adresse suivante :  <a href="mailto:contact@espace-jantes.com" class="no_deco tc_black font-weight-bold">contact@espace-jantes.com</a>. Ce courrier électronique invitera les Clients à transmettre des justificatifs de domicile et/ou d’identité afin de s’assurer de la réalité de l’identité et de la domiciliation du Client pour la réalisation de la commande. A défaut de justificatif ou si les pièces adressées par le Client ne permettent pas de s’assurer de l’identité du Client, Espace-Jantes.com se réserve le droit d’annuler la commande pour garantir la sûreté des transactions en ligne, sans que cela constitue une faute d’Espace-Jantes.com ou un dommage pour le Client, qui ne pourra prétendre à aucune indemnité de ce fait.</p>
                                    <br>

                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article6 = !article6" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_blue
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 6. PRIX - PAIEMENT</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article6" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article6" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">6.1 Prix</p>
                                    <p>
                                        Les prix applicables sont ceux affichés sur le Site au jour de la commande. Ces prix peuvent être modifiés à tout moment par Espace-Jantes.com. Les prix affichés ne sont valables qu’au jour de la commande et ne portent pas effet pour l’avenir.
                                    </p>
                                    <p>Les prix indiqués sur le Site sont entendus en euros, toutes taxes comprises, hors frais de livraison.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">6.2 Modalité de paiement</p>
                                    <p>Le Client peut effectuer son règlement par Paypal, carte bancaire, chèque ou virement bancaire.</p>
                                    <p>Les paiements par carte bancaire se font au moyen de transactions sécurisées fournies par le crédit mutuel (3Dsecure)</p>
                                    <p>paiements 4x cofidis  : Il s’agit d’une solution de paiement en 4 fois par carte bancaire Visa ou Mastercard* qui permet d’échelonner le paiement de la commande en 4 mensualités</p>
                                    <p>-Facilité de paiement réservée aux porteurs d’une carte bancaire pour un achat compris entre 100€ et 2000€, les frais de dossier correspondent à 2,2% du montant total</p>
                                    <br>
                                    <p>Exemple : Pour un achat de 500€ effectué, les frais sont de 2,2% soit un total de 511€ , les mensualités seront de 127,75€/mois en 4 fois</p>
                                    <p>Dans le cadre des paiements par carte bancaire, Espace-Jantes.com n’a accès à aucune donnée relative aux moyens de paiement du Client. Le paiement est effectué directement entre les mains de l’établissement bancaire.</p>
                                    <p>En cas de paiement par chèque ou virement bancaire, les délais de livraison ne commencent à courir qu’à compter de la date de l’encaissement du paiement par Espace-Jantes.com.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">6.3 Facturation</p>
                                    <p>Espace-Jantes.com adressera ou mettra à disposition du Client une facture par voie électronique après chaque paiement. Le Client accepte expressément de recevoir les factures par voie électronique.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">6.4 Défaut de paiement</p>
                                    <p>Les dates de paiement convenues ne peuvent être retardées sous quelque prétexte que ce soit, y compris en cas de litige.</p>
                                    <p>Toute somme non payée à l’échéance donnera lieu, de plein droit et sans mise en demeure, à l’application de pénalités de retard calculées sur la base d’un taux égal à 3 fois le taux d’intérêt légal, sans que cette pénalité nuise à l’exigibilité des sommes dues en principal.</p>
                                    <p>En outre, tout retard de paiement aura pour conséquence la facturation au Client défaillant de frais de recouvrement d’un montant de 40 euros, l’exigibilité immédiate de toutes les sommes restant dues quels que soient les délais convenus, majorées d’une indemnité de 20 % du montant à titre de clause pénale, ainsi que la possibilité de résilier le contrat unilatéralement au tort du Client.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">6.5 Réserve de propriété</p>
                                    <p>Les Produits vendus restent la propriété d’Espace-Jantes.com jusqu’à complet paiement de leur prix, conformément à la présente clause de réserve de propriété.</p>

                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article7 = !article7" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_blue
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 7. RECLAMATION - RETRACTATION – GARANTIE</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article7" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article7" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">7.1 Service clientèle</p>
                                    <p>
                                        Le service clientèle du Site est accessible du lundi au vendredi de 9h à 19h au numéro de téléphone non surtaxé suivant : 0811484100, par courrier électronique à : <a href="mailto:contact@espace-jantes.com" class="no_deco tc_black font-weight-bold">contact@espace-jantes.com</a> ou par courrier postal à l’adresse indiquée à l’article 1 des présentes conditions générales. Dans ces deux derniers cas, Espace-Jantes.com s’engage à apporter une réponse sous 48h ouvrées.
                                    </p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">7.2 Droit de rétractation – Vente à distance</p>
                                    <p>Conformément à la législation en vigueur en matière de vente à distance, le Client ayant la qualité de consommateur dispose d’un délai de 14 jours pour exercer son droit de rétractation sans avoir à justifier de motifs ni à payer de pénalités, à l’exception,  des frais de retour.</p>
                                    <p>Le délai mentionné à l’alinéa précédent court à compter de la réception pour les biens ou de l’acceptation de l’offre pour les prestations de services. Lorsque le délai d’expire un samedi, un dimanche ou un jour férié ou chômé, il est prorogé jusqu’au premier jour ouvrable suivant.</p>
                                    <p>Lorsque le droit de rétractation est exercé, le professionnel est tenu de rembourser le consommateur de la totalité des sommes versées, dans les meilleurs délais et au plus tard dans les trente jours suivant la date à laquelle ce droit a été exercé. Au-delà, la somme due est, de plein droit, productive d’intérêts au taux légal en vigueur. Ce remboursement s’effectue par tout moyen de paiement. Sur proposition du professionnel, le consommateur ayant exercé son droit de rétractation peut toutefois opter pour une autre modalité de remboursement.</p>
                                    <p>Le droit de rétractation ne peut être exercé pour les contrats de fourniture de services dont l’exécution a commencé avant la fin du délai de 14 jours francs.</p>
                                    <p>De même, conformément à l’article L. 121-20-2 du Code de la consommation, le droit de rétractation ne pourra être exercé pour les Produits personnalisés ou spécifiquement traités, tels que les packs jantes, les pneus montés, équilibrés et valvés.</p>
                                    <p>Les pneumatiques retournés ne pourront être acceptés par l’éditeur et ne donneront lieu a aucun remboursement dès lors qu’ils autont été montés sur des jantes, cette opération de montage en empêchant la revente en tant que pneumatiques neufs.</p>
                                    <p>Il en sera de même si les produits (jantes pneus accessoires) renvoyés sont incomplets ou endommagés impropres à la revente.</p>
                                    <p>Tout risque lié au retour est à la charge du client.</p>
                                    <p>Après réception et vérification des produits l’éditeur remboursera le client dans un délai de 14 jours selon la date à laquelle le droit de rétractation du client a été exercé.</p>
                                    <br>
                                    <p>7.3 Résolution du contrat sur l’initiative du Client</p>
                                    <p>Le Client ayant la qualité de consommateur peut dénoncer le contrat par lettre recommandée avec demande d’avis de réception en cas de dépassement de la date de livraison du bien excédant 30 jours. Le Client sera alors remboursé des sommes engagées par lui lors de la commande.</p>
                                    <p>La présente clause n’a pas vocation à s’appliquer si le retard de livraison est dû à un cas de force majeure. En pareil cas, le Client s’engage à ne pas exercer de poursuites à l’encontre d’Espace-Jantes.com et renonce à se prévaloir de la résolution de la vente prévue au présent article.</p>
                                    <br>
                                    <p>7.4 Garanties</p>
                                    <p>7.4.1 Garantie des vices et défaut apparents</p>
                                    <p>Il appartient au Client de vérifier le bon état des Produits au moment de la livraison. Cette vérification doit notamment porter sur la qualité, les quantités et les références des Produits ainsi que leur conformité à la commande. Aucune réclamation ne sera prise en compte après un délai de trois jours à compter de la livraison. En tout état de cause, toute réclamation concernant les colis livrés ne sera prise en compte que si le Client ayant la qualité de commerçant a émis des réserves auprès du transporteur conformément aux articles L. 133-3 et suivants du Code de commerce.</p>
                                    <p>7.4.2 Garantie des vices et défauts cachés</p>
                                    <p>Les Clients disposent d’une garantie légale de délivrance conforme (article 1604 du Code civil), d’une garantie légale contre les vices cachés (article 1604 et s. du Code civil) et d’une garantie de sécurité (article 1386-1 et s. du Code civil).</p>
                                    <p>Les Clients ayant la qualité de consommateurs disposent en outre d’une garantie légale de conformité (article L. 211-1 et s. Code de la consommation).</p>
                                    <p>7.4.3 garantie</p>
                                    <p>Toutes les jantes vendues par Espace-Jantes bénéficient d’une garantie commerciale de deux ans.</p>
                                    <p>La garantie n’est pas cessible et n’est valable que pour l’acheteur principal.</p>
                                    <p>Aucune garantie ne sera possible si la jante en question porte des traces de coups ou de frottements dus aux trottoirs ou autres.</p>
                                    <p>La garantie ne comprend pas les accessoires en plastique qui peuvent être sur la jante (caches centraux-visserie plastique dans le contour des jantes etc…).</p>
                                    <p>La garantie du vernis est de 1 an. (Attention toutes les jantes polies sont à nettoyer exclusivement avec des produits à PH Neutre – Comme tous constructeurs, tous problèmes avec le poli ne peuvent être pris en charge et aucune garantie ne sera applicable)</p>
                                    <p>Toutes les jantes polies sont à nettoyer réguliérement surtout en temps hivernal afin de ne pas laisser le sel agir.</p>
                                    <p>La garantie de la structure de jante est de 2 ans. Nous insistons sur le fait que cela ne rentre pas en ligne de compte si la jante comporte un plat, un coup ou un choc visible.</p>
                                    <p>La fabrication d’une jante étant industrielle celle-ci peut contenir de légers grains de poussière sous la peinture ou encore de petits points noirs autre que sur la face visible de celle-ci (Comme sur les jantes constructeurs). Aucun remplacement de jante ne peut être demandé sur cette observation.</p>
                                    <p>Pour bénéficier du SAV, la jante ne doit avoir subi aucun choc.</p>
                                    <p>Les frais de ports pour le retour des jantes chez Espace-Jantes sont à la charge du client. L’envoi de produit remplacé (si la garantie est accordée) est quant à lui gratuit.</p>
                                    <p>7.5.1 Modalités de retours des Produits</p>
                                    <p>7.5.1.1 Retours dans le cadre de la mise en œuvre du droit de rétractation</p>
                                    <p>Les Produits ne pourront être acceptés par Espace-Jantes.com qu’à condition d’être retournés en parfait état, dans son emballage d’origine et n’ait pas été utilisé avant le retour.</p>
                                    <p>7.5.1.2 Retours dans le cadre de la mise en œuvre de la garantie</p>
                                    <p>Afin de mettre en œuvre la garantie, il appartient au Client de retourner le Produit à l’adresse indiquée par espace-jantes.com, accompagné d’une lettre explicative en demandant soit l’échange, soit le remboursement.</p>
                                    <p>7.5.1.3 Transporteur</p>
                                    <p>Le Client devra aviser Espace-Jantes.com de son intention de retourner un ou plusieurs Produits. A ce titre le Client pourra soit :</p>
                                    <ul>
                                        <li>demander à ce que les Produits soient repris par un transporteur désigné par Espace-Jantes.com à une adresse convenue préalablement entre le Client et Espace-Jantes.com ;</li>
                                        <li>procéder lui-même au retour des Produits à l’adresse indiquée par Espace-Jantes.com.</li>
                                    </ul>
                                    <p>7.5.2 Frais de retour</p>
                                    <p>Lorsqu’un Client souhaite effectuer un retour, il appartient au Client de retourner le Produit à l’adresse indiquée par espace-jantes.com par ces propres moyens et à ses frais, accompagné d’une lettre explicative en demandant soit l’échange, soit le remboursement.</p>
                                    <p>Les frais de retour en cas de refus de marchandise auprès de notre transporteur sont de 25 €HT par jante et sont, en tout état de cause, à la charge du Client.</p>
                                    <p>En cas de remboursement de marchandise, un abattement de 20% sera imputé sur le remboursement, sauf en cas de retour de marchandise non dans son état et emballage d’origine, non propre à la revente, percée ou découpée ou présentant quelconque trace de “bricolage” par le client, un abattement de 50% sera imputé sur le remboursement.</p>
                                    <p>7.5.3 Cas particuliers</p>
                                    <p>Dans l’hypothèse d’un retour de « packs montés équilibres valves » tels que définis sur le Site, les frais de montage « équilibrage valvage » et retour seront supportés par le Client.</p>
                                    <p>Les pneus et jantes retournés ne pourront être acceptés par Espace-Jantes.com dès lors qu’ils auront été montés, cette opération de montage en empêchant la revente en tant que Produits neufs.</p>
                                    <p>Les pneumatiques retournés ne pourront être acceptés par l’éditeur et ne donneront lieu a aucun remboursement dès lors qu’ils auront été montés sur des jantes,cette opération de montage en empêchant la revente en tant que pneumatiques neufs.</p>
                                    <p>Il en sera de même pour les jantes sur lesquelles auront été montés des pneus ou si les produits (jantes pneus , accessoires) sont renvoyés incomplets, endommagés impropres à la revente.</p>
                                    <p>Tout risque lié au retour est à la charge du client.</p>
                                    <p> Nous portons votre attention sur le fait que pour les véhicules de gamme "performance S-RS-AMG-R etc..." ainsi que les jantes à déport, il peut etre nécéssaire de mettre des spacers ou élargisseurs de voies sur l'essieu avant et/ou arrière </p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article8 = !article8" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_blue
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 8. ESPACE CLIENT</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article8" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article8" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">8.1 Création de l’espace client</p>
                                    <p>
                                        La création d’un espace client est un préalable indispensable à toute commande d’un Internaute sur le Site. A cette fin, l’Internaute sera invité à fournir un certain nombre d’informations personnelles. Certaines de ces informations sont réputées indispensables à la création de l’espace client. Le refus par un Internaute de fournir lesdites informations aura pour effet d’empêcher la création de l’espace client ainsi que, incidemment, la validation de la commande.
                                    </p>
                                    <p>Lors de la création de l’espace client, l’Internaute est invité à choisir un mot de passe. Ce mot de passe constitue la garantie de la confidentialité des informations contenues dans l’espace client. L’Internaute s’interdit donc de le transmettre ou de le communiquer à un tiers. A défaut, Espace-Jantes.com ne pourra être tenu pour responsable des accès non autorisés à l’espace client d’un Internaute.</p>
                                    <p>Le Client s’engage à procéder à une vérification régulière des données qui le concernent et à procéder en ligne, depuis son espace personnel, aux actualisations et modifications nécessaires.</p>
                                    <p>Espace-Jantes.com s’engage à conserver de façon sécurisée tous les éléments contractuels dont la conservation est requise par la loi ou la réglementation en vigueur.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">8.2 Contenu de l’espace client</p>
                                    <p>L’espace client permet au Client de consulter et de suivre toutes ses commandes effectuées sur le Site.</p>
                                    <p>Les pages relatives aux espaces personnels sont librement imprimables par le titulaire du compte en question, mais ne constituent nullement une preuve admissible par un tribunal. Elles n’ont qu’un caractère informatif destiné à assurer une gestion efficace de ses commandes par le Client.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">8.3 Suppression de l’espace client</p>
                                    <p>Espace-Jantes.com se réserve le droit de supprimer le compte de tout Client qui contrevient aux présentes conditions générales, notamment lorsque le Client fournit des informations inexactes, incomplètes, mensongères ou frauduleuses, ainsi que lorsque l’espace client d’un Client sera resté inactif depuis au moins une année. Ladite suppression ne sera pas susceptible de constituer une faute d’Espace-Jantes.com ou un dommage pour le Client exclu, qui ne pourra prétendre à aucune indemnité de ce fait.</p>
                                    <p>Cette exclusion est sans préjudice de la possibilité, pour Espace-Jantes.com, d’entreprendre des poursuites d’ordre judiciaire à l’encontre du Client, lorsque les faits l’auront justifié.</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article9 = !article9" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_blue
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 9. LIVRAISON</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article9" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article9" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">9.1 Frais de livraison</p>
                                    <p>
                                        Les frais de livraison ou de mise à disposition seront, en toute hypothèse, indiqués au Client avant tout règlement et ne concernent que les livraisons effectuées en France métropolitaine, Corse incluse. Pour tout autre lieu de livraison, il appartiendra au Client de prendre contact avec le service clientèle.
                                    </p>
                                    <p>Les frais de livraison indiqués sur le Site sont entendus en euros, toutes taxes comprises.</p>
                                   <br>
                                    <p class="font-weight-bold subtitle-1">9.2 Délai de livraison</p>
                                    <p>Les commandes sont livrées par Mazet ou tout autre transporteur choisi par Espace-Jantes.com dans un délai maximum de 10 jours ouvrés à compter du parfait encaissement du prix par Espace-Jantes.com.</p>
                                    <p>Certains produits ou certains volumes de commande peuvent néanmoins justifier un délai de livraison supérieur à 10 jours ouvrés. Il en sera fait mention expresse à l’attention du Client lors de la validation de la commande.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">9.3 Colis détérioré</p>
                                    <p>En cas de livraison d’un colis manifestement et visiblement détérioré, il appartient au Client de le refuser afin de jouir de la garantie offerte par le transporteur. Le Client devra par ailleurs en informer le vendeur sans délai, afin qu’un nouveau colis lui soit préparé, puis expédié dès réception du colis détérioré en retour. Dans pareil cas, les délais de livraison indiqués plus haut dans les présentes conditions générales ne seront plus applicables.</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article10 = !article10" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_blue
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 10. INFORMATIQUE ET LIBERTES</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article10" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article10" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">10.1 Déclaration CNIL – Responsable du traitement</p>
                                    <p>
                                        Le fichier d’Espace-Jantes.com comportant les données personnelles des Internautes et des Clients a fait l’objet d’une déclaration auprès de la CNIL enregistrée sous le n° 1636586 V 04791B.
                                    </p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">10.2 Caractère facultatif de la fourniture de données</p>
                                    <p>Les Internautes disposent de la libre faculté de fournir des informations personnelles les concernant. La fourniture d’informations personnelles n’est pas indispensable pour la navigation sur le Site.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">10.3 Fourniture impérative de données pour l’inscription</p>
                                    <p>En revanche, l’inscription sur le présent Site suppose la collecte par Espace-Jantes.com d’un certain nombre d’informations personnelles concernant les Internautes. Les Internautes ne souhaitant pas fournir les informations nécessaires à l’inscription ne pourront pas passer commande sur le présent Site.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">10.4 Respect de la finalité de la collecte de données personnelles</p>
                                    <p>Les données personnelles collectées font l’objet d’un traitement informatique et sont exclusivement réservées à Espace-Jantes.com. Les données récoltées sont nécessaires à la bonne administration du Site, ainsi qu’au respect de ses obligations contractuelles par Espace-Jantes.com. Ces données sont conservées par Espace-Jantes.com en cette unique qualité. Espace-Jantes.com s’engage à ne pas les utiliser dans un autre cadre, ni à les transmettre à des tiers, hors accord express des Clients ou cas prévus par la loi. Les données personnelles récoltées ne font l’objet d’aucun transfert vers l’étranger.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">10.5 Droit d’accès, de rectification et d’opposition</p>
                                    <p>Les coordonnées de tous les Clients inscrits sur le présent Site sont sauvegardées pour une durée de 6 mois, durée raisonnable nécessaire à la bonne administration du Site et à une utilisation normale des données. Ces données sont conservées dans des conditions sécurisées, selon les moyens actuels de la technique, dans le respect des dispositions de la Loi Informatique et Libertés du 6 janvier 1978.</p>
                                    <p>Conformément à cette dernière, les Clients disposent d’un droit d’opposition, d’interrogation, d’accès et de rectification des données qu’ils ont fournies. Pour cela, il leur suffit d’en faire la demande à Espace-Jantes.com, en la formulant à l’adresse électronique suivante : {#email_cnil}, ou encore par courrier postal à l’adresse du siège de Espace-Jantes.com mentionnée à l’article 1 des présentes conditions générales.</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article11 = !article11" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_blue
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 11. COOKIES ET ADRESSES IP DES INTERNAUTES</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article11" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article11" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">11.1 Cookies</p>
                                    <p>11.1.1 Objet de l’implantation des cookies</p>
                                    <p>Afin de permettre à tous les Internautes une navigation optimale sur le présent Site ainsi qu’un meilleur fonctionnement des différentes interfaces et applications, Espace-Jantes.com pourra procéder à l’implantation d’un cookie sur le poste informatique de l’Internaute.</p>
                                    <p>11.1.2 Finalité des cookies</p>
                                    <p>Les cookies permettent de stocker des informations relatives à la navigation sur le Site (date, page, heures), ainsi qu’aux éventuelles données saisies par les Internautes au cours de leur visite (recherches, login, email, mot de passe). Ces cookies ont vocation à être conservés sur le poste informatique de l’Internaute pour une durée variable allant jusqu’à 1 an, et pourront être lus et utilisés par Espace-Jantes.com lors d’une visite ultérieure de l’Internaute sur le présent Site.</p>
                                    <p>11.1.3 Faculté d’opposition de l’Internaute à l’implantation des cookies</p>
                                    <p>L’Internaute dispose de la possibilité de bloquer, modifier la durée de conservation, ou supprimer ces cookies via l’interface de son navigateur (généralement : outils ou options / vie privée ou confidentialité). Dans un tel cas, la navigation sur le présent Site ne sera pas optimisée. Si la désactivation systématique des cookies sur le navigateur de l’Internaute l’empêche d’utiliser certains Services, ce dysfonctionnement ne saurait en aucun cas constituer un dommage pour le Client qui ne pourra prétendre à aucune indemnité de ce fait.</p>
                                    <p>11.1.4 Suppression des cookies implantés</p>
                                    <p>Les Internautes ont aussi la possibilité de supprimer les cookies implantés sur leur ordinateur, en se rendant dans le menu de leur navigateur prévu à cet effet (généralement, outils ou options / vie privée ou confidentialité). Une telle action fait perdre aux Internautes le bénéfice apporté par les cookies.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">11.2 Adresses IP</p>
                                    <p>11.2.1 Définition et collecte des adresses IP</p>
                                    <p>L’adresse IP correspond à une série de chiffres séparés par des points permettant l’identification unique d’un ordinateur sur le réseau Internet. Espace-Jantes.com se réserve le droit de collecter l’adresse IP publique de tous les Internautes. La collecte de cette adresse IP sera effectuée de façon anonyme. L’adresse IP des Internautes sera conservée pendant la durée requise par la loi.</p>
                                    <p>11.2.2 Communication des adresses IP</p>
                                    <p>Espace-Jantes.com devra communiquer toutes les données personnelles relatives à un Internaute à la Police sur réquisition judiciaire ou à toute personne sur décision judiciaire. L’adresse IP pourra faire l’objet d’un rapprochement avec l’identité effective de l’Internaute en cas de communication de cette information par un fournisseur d’accès à internet.</p>
                                    <br>

                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article12 = !article12" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_blue
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 12. RESPONSABILITE D’ESPACE-JANTES.COM</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article12" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article12" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">12.1 Nature des obligations d’Espace-Jantes.com</p>
                                    <p>Espace-Jantes.com s’engage à apporter le soin et la diligence nécessaire à la fourniture de Produits de qualité conforme aux spécifications des présentes conditions générales. Espace-Jantes.com ne répond que d’une obligation de moyen concernant les services objet des présentes.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">12.2 Force majeure – Faute du Client</p>
                                    <p>Espace-Jantes.com n’engagera pas sa responsabilité en cas de force majeure ou de faute du Client, telles que définies au présent article :</p>
                                    <p>12.2.1 Force majeure</p>
                                    <p>Au sens des présentes conditions générales, sera considéré comme un cas de force majeure opposable au Client tout empêchement, limitation ou dérangement du Service du fait d’incendie, d’épidémie, d’explosion, de tremblement de terre, de fluctuations de la bande passante, de manquement imputable au fournisseur d’accès, de défaillance des réseaux de transmission, d’effondrement des installations, d’utilisation illicite ou frauduleuse des mots de passe, codes ou références fournis au Client, de piratage informatique, d’une faille de sécurité imputable à l’hébergeur du Site ou aux développeurs, d’inondation, de panne d’électricité, de guerre, d’embargo, de loi, d’injonction, de demande ou d’exigence de tout gouvernement, de réquisition, de grève, de boycott, ou autres circonstances hors du contrôle raisonnable de Espace-Jantes.com. Dans de telles circonstances, Espace-Jantes.com sera dispensé de l’exécution de ses obligations dans la limite de cet empêchement, de cette limitation ou de ce dérangement.</p>
                                    <p>12.2.2 Faute du Client</p>
                                    <p>Au sens des présentes Conditions générales, sera considéré comme une faute du Client opposable à ce dernier toute mauvaise utilisation du Service, faute, négligence, omission ou défaillance de sa part ou de celle de ses préposés, non-respect des conseils donnés par Espace-Jantes.com sur son Site, toute divulgation ou utilisation illicite du mot de passe, des codes et références du Client, ainsi que le renseignement d’informations erronées ou l’absence de mise à jour de telles informations dans son espace personnel. Sera également considérée comme une faute du Client la mis en œuvre de tout procédé technique, tels que des robots, ou des requêtes automatiques, dont la mise en œuvre contreviendrait à la lettre ou à l’esprit des présentes conditions générales de vente.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">12.3 Problèmes techniques – Liens hypertextes</p>
                                    <p>En cas d’impossibilité d’accès au Site, en raison de problèmes techniques de toutes natures, le Client ne pourra se prévaloir d’un dommage et ne pourra prétendre à aucune indemnité. L’indisponibilité, même prolongée et sans aucune durée limitative, d’un ou plusieurs services en ligne, ne peut être constitutive d’un préjudice pour les Clients et ne peut aucunement donner lieu à l’octroi de dommages et intérêts de la part de Espace-Jantes.com.</p>
                                    <p>Les liens hypertextes présents sur le Site peuvent renvoyer sur d’autres sites internet. La responsabilité d’Espace-Jantes.com ne saurait être engagée si le contenu de ces sites contrevient aux législations en vigueur. De même la responsabilité d’Espace-Jantes.com ne saurait être engagée si la visite, par l’Internaute, de l’un de ces sites, lui causait un préjudice.</p>
                                    <p>En l’état actuel de la technique, le rendu des représentations des Produits proposés à la vente sur le présent Site, notamment en termes de couleurs ou de formes, peut sensiblement varier d’un poste informatique à un autre ou différer de la réalité selon la qualité des accessoires graphiques et de l’écran ou selon la résolution de l’affichage. Ces variations et différences ne pourront en nulle hypothèse être imputées à Espace-Jantes.com qui ne pourra en aucun cas voir sa responsabilité engagée de ce fait.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">12.4 Dommages-intérêts à la charge d’Espace-Jantes.com</p>
                                    <p>A défaut de dispositions légales ou réglementaires contraires, la responsabilité d’Espace-Jantes.com est limitée au préjudice direct, personnel et certain subi par le Client et lié à la défaillance en cause. Espace-Jantes.com ne pourra en aucun cas être tenu responsable des dommages indirects tels que, notamment les pertes de données, les préjudices commerciaux, les pertes de commandes, les atteintes à l’image de marque, les troubles commerciaux et les pertes de bénéfices ou de clients. De même et dans les mêmes limites, le montant des dommages et intérêts mis à la charge de Espace-Jantes.com ne pourra en tout état de cause excéder le prix du Produit commandé.</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article13 = !article13" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_blue
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 13. PROPRIETE INTELLECTUELLE</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article13" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article13" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p>Le contenu (textes, images, schémas…), la structure et le logiciel mis en œuvre pour le fonctionnement du Site sont protégés par le droit d’auteur et le droit des bases de données. Toute représentation ou reproduction intégrale ou partielle faite sans le consentement de Espace-Jantes.com ou de ses ayants droit ou ayants cause constitue une violation des Livres I et III du Code de la propriété intellectuelle et sera susceptible de donner lieu à des poursuites judiciaires. Il en est de même pour la traduction, l’adaptation ou la transformation, l’arrangement ou la reproduction par un art ou un procédé quelconque.</p>
                                    <p>Les informations publiées sur le présent Site le sont à titre indicatif, sans garantie d’exactitude. Espace-Jantes.com ne peut en aucun cas être tenue responsable du fait d’une omission, d’une inexactitude ou de toute erreur contenue dans ces informations et qui serait à l’origine d’un dommage direct ou indirect causé à l’Internaute.</p>
                                    <br>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article14 = !article14" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_blue
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 14. DISPOSITIONS GENERALES</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article14" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article14" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">14.1 Droit applicable</p>
                                    <p>Les présentes conditions générales sont soumises à l’application du droit français.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">14.2 Modifications des présentes conditions générales</p>
                                    <p>Les présentes conditions générales peuvent être modifiées à tout moment par Espace-Jantes.com. Les conditions générales applicables au Client sont celles en vigueur au jour de sa commande ou de sa connexion sur le présent Site, toute nouvelle connexion à l’espace personnel emportant acceptation le cas échéant des nouvelles conditions générales.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">14.3 Règlement amiable des litiges</p>
                                    <p>Sauf dispositions d’ordre public, tous litiges qui pourraient survenir dans le cadre de l’exécution des présentes conditions générales devront avant toute action judiciaire être soumis à l’appréciation d’Espace-Jantes.com en vue d’un règlement amiable.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">14.4 Entièreté</p>
                                    <p>La nullité d’une des clauses du présent contrat n’entraînera pas la nullité des autres clauses du contrat ou du contrat dans sa globalité, qui garderont leur plein effet et portée. Dans une telle hypothèse, les parties devront dans la mesure du possible remplacer la stipulation annulée par une stipulation valable correspondant à l’esprit et à l’objet des présentes.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">14.5 Non-renonciation</p>
                                    <p>L’absence d’exercice par Espace-Jantes.com des droits qui lui sont reconnus par les présentes ne pourra en aucun cas être interprétée comme une renonciation à faire valoir lesdits droits.</p>
                                    <br>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>

            </v-col>

        </v-main>



        <v-main v-if="VUE_APP_SITE_ID === '2'">

            <v-col class=" font-weight-regular
                            text-xl-h5
                            text-lg-body-1
                            text-md-caption
                            text-sm-caption
                            text-caption">
                <v-col offset-lg="1" lg="10" >
                    <v-card @click="article1 = !article1" >
                        <v-row    >
                            <v-col offset-lg="1" lg="10" cols="10">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_orange
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 1. MENTIONS LEGALES</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article1" x-large class="pr-4 " >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4 " >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article1" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">1.1 Editeur</p>
                                    <span>
                                        Vous êtes actuellement connecté au site internet www.distri-jantes.com, édité par Distri-Jantes, SAS, capital social 1 000 €, RCS aix en provence 878 147 115, siège social : 1175 Route d'Avignon 13090 Aix-en-Provence, France, téléphone : 0974191801, email : contact@distri-jantes.com, directeur de la publication et responsable de la rédaction : Arnaud TREILLON.
                                    </span>
                                    <br>
                                    <span>Siret : 87814711500017</span>
                                    <br>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">1.2 Hébergeur</p>
                                    <span>Publicom</span>
                                    <br>
                                    <span>10 route de Galice – 13100 Aix-en-Provence</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article2 = !article2" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_orange
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 2. DEFINITIONS</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article2" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article2" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p>
                                        « <b>Client</b> » : toute personne, physique ou morale, de droit privé ou de droit public, inscrite sur le Site.
                                    </p>
                                    <p>« <b>distri-Jantes.com</b> » : Distri-Jantes, SAS, pris en sa qualité d’éditeur du Site.</p>
                                    <p>« <b>Internaute</b> » : toute personne, physique ou morale, de droit privé ou de droit public, se connectant au Site.</p>
                                    <p>« <b>Produit</b> » : bien proposé à la vente par Distri-Jantes.com sur le Site et consistant notamment en différents modèles de jantes et de pneus.</p>
                                    <p>« <b>Site</b> » : site internet accessible à l’URL www.distri-jantes.com, ainsi que les sous-sites, sites miroirs, portails et variations d’URL y afférant.</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article3 = !article3" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_orange
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 3. CHAMP D’APPLICATION</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article3" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article3" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p>
                                        Le Site est d’accès libre et gratuit à tout Internaute. La navigation sur le Site suppose l’acceptation par tout Internaute des présentes conditions générales. La simple connexion au Site emportera acceptation pleine et entière des présentes conditions générales.
                                    </p>
                                    <p>Lors de l’inscription sur le Site, cette acceptation sera confirmée par le fait de cocher la case correspondant à la phrase suivante : « Je reconnais avoir lu et accepté les conditions générales de vente et d’utilisation ». L’Internaute reconnaît du même fait en avoir pris pleinement connaissance et les accepter sans restriction.</p>
                                    <p>Le fait de cocher la case susvisée sera réputé avoir la même valeur qu’une signature manuscrite de la part du Client. L’Internaute reconnaît la valeur de preuve des systèmes d’enregistrement automatique de Distri-Jantes.com et, sauf pour lui d’apporter preuve contraire, il renonce à les contester en cas de litige.</p>
                                    <p>Les présentes conditions générales sont applicables aux relations entre les parties à l’exclusion de toutes autres conditions, et notamment celles du Client.</p>
                                    <p>L’acceptation des présentes conditions générales suppose de la part des Internautes qu’ils jouissent de la capacité juridique nécessaire pour cela, ou à défaut qu’ils en aient l’autorisation d’un tuteur ou d’un curateur s’ils sont incapables, de leur représentant légal s’ils sont mineurs, ou encore qu’ils soient titulaires d’un mandat s’ils agissent pour le compte d’une personne morale.</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article4 = !article4" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_orange
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 4. OBJET DU SITE</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article4" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article4" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p>Le Site a pour objet la vente de Produits aux Clients particuliers et professionnels.</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article5 = !article5" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_orange
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 5. ETAPES DE LA COMMANDE</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article5" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article5" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">5.1 Commande</p>
                                    <span>
                                        Afin de passer commande, les Internautes pourront sélectionner un ou plusieurs Produits et les ajouter à leur panier.
                                        La disponibilité des produits est indiquée sur le Site, dans la fiche descriptive de chaque article.
                                        Le cas échéant, le Client devra préciser sa commande en indiquant notamment la marque ou la dimension souhaitée.
                                        Lorsque leur commande sera complète, ils pourront accéder à leur panier en cliquant sur le bouton prévu à cet effet.
                                    </span>
                                    <br>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">5.2 Validation de la commande par l’Internaute</p>
                                    <p>En consultant leur panier, les Internautes auront la faculté de vérifier le nombre ainsi que la nature des Produits qu’ils auront choisis et pourront vérifier leur prix unitaire, ainsi que leur prix global. Ils auront la possibilité de retirer un ou plusieurs Produits de leur panier.</p>
                                    <p>Si leur commande leur convient, les Internautes pourront la valider. Ils accèderont alors à un formulaire sur lequel ils pourront soit saisir leurs identifiants de connexion s’ils en possèdent déjà, soit s’inscrire sur le Site en complétant le formulaire d’inscription au moyen des informations personnelles les concernant.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">5.3 Paiement par le Client</p>
                                    <p>Dès lors qu’ils seront connectés ou après qu’ils auront parfaitement complété le formulaire d’inscription, les Clients seront invités à contrôler ou modifier leurs coordonnées de livraison et de facturation, puis seront invités à effectuer leur paiement en étant redirigés à cet effet sur l’interface de paiement sécurisée.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">5.4 Confirmation de la commande par Distri-Jantes.com</p>
                                    <p>Une fois le paiement effectivement reçu par Distri-Jantes.com, ce dernier s’engage à en accuser réception au Client par voie électronique, dans un délai maximal de 24 heures. Dans le même délai, Distri-Jantes.com s’engage à adresser au Client un courrier électronique récapitulatif de la commande et lui en confirmant le traitement, reprenant toutes les informations y afférant.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">5.5 Système de prévention des fraudes</p>
                                    <p>Afin d’assurer la sécurité des transactions et pour répondre à la demande de prévention des fraudes en matière de vente à distance, Distri-Jantes.com effectue des contrôles aléatoires sur la passation des commandes. Ainsi, Distri-Jantes.com est susceptible d’adresser aux Clients un courrier électronique émanant de l’adresse suivante :  <a href="mailto:contact@distri-jantes.com" class="no_deco tc_black font-weight-bold">contact@distri-jantes.com</a>. Ce courrier électronique invitera les Clients à transmettre des justificatifs de domicile et/ou d’identité afin de s’assurer de la réalité de l’identité et de la domiciliation du Client pour la réalisation de la commande. A défaut de justificatif ou si les pièces adressées par le Client ne permettent pas de s’assurer de l’identité du Client, Distri-Jantes.com se réserve le droit d’annuler la commande pour garantir la sûreté des transactions en ligne, sans que cela constitue une faute de Distri-Jantes.com ou un dommage pour le Client, qui ne pourra prétendre à aucune indemnité de ce fait.</p>
                                    <br>

                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article6 = !article6" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_orange
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 6. PRIX - PAIEMENT</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article6" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article6" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">6.1 Prix</p>
                                    <p>
                                        Les prix applicables sont ceux affichés sur le Site au jour de la commande. Ces prix peuvent être modifiés à tout moment par Distri-Jantes.com. Les prix affichés ne sont valables qu’au jour de la commande et ne portent pas effet pour l’avenir.
                                    </p>
                                    <p>Les prix indiqués sur le Site sont entendus en euros, toutes taxes comprises, hors frais de livraison.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">6.2 Modalité de paiement</p>
                                    <p>Le Client peut effectuer son règlement par Paypal, carte bancaire, chèque ou virement bancaire.</p>
                                    <p>Les paiements par carte bancaire se font au moyen de transactions sécurisées fournies par le crédit mutuel (3Dsecure)</p>
                                    <p>paiements 4x cofidis  : Il s’agit d’une solution de paiement en 4 fois par carte bancaire Visa ou Mastercard* qui permet d’échelonner le paiement de la commande en 4 mensualités</p>
                                    <p>-Facilité de paiement réservée aux porteurs d’une carte bancaire pour un achat compris entre 100€ et 2000€, les frais de dossier correspondent à 2,2% du montant total</p>
                                    <br>
                                    <p>Exemple : Pour un achat de 500€ effectué, les frais sont de 2,2% soit un total de 511€ , les mensualités seront de 127,75€/mois en 4 fois</p>
                                    <p>Dans le cadre des paiements par carte bancaire, Distri-Jantes.com n’a accès à aucune donnée relative aux moyens de paiement du Client. Le paiement est effectué directement entre les mains de l’établissement bancaire.</p>
                                    <p>En cas de paiement par chèque ou virement bancaire, les délais de livraison ne commencent à courir qu’à compter de la date de l’encaissement du paiement par Distri-Jantes.com.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">6.3 Facturation</p>
                                    <p>Distri-Jantes.com adressera ou mettra à disposition du Client une facture par voie électronique après chaque paiement. Le Client accepte expressément de recevoir les factures par voie électronique.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">6.4 Défaut de paiement</p>
                                    <p>Les dates de paiement convenues ne peuvent être retardées sous quelque prétexte que ce soit, y compris en cas de litige.</p>
                                    <p>Toute somme non payée à l’échéance donnera lieu, de plein droit et sans mise en demeure, à l’application de pénalités de retard calculées sur la base d’un taux égal à 3 fois le taux d’intérêt légal, sans que cette pénalité nuise à l’exigibilité des sommes dues en principal.</p>
                                    <p>En outre, tout retard de paiement aura pour conséquence la facturation au Client défaillant de frais de recouvrement d’un montant de 40 euros, l’exigibilité immédiate de toutes les sommes restant dues quels que soient les délais convenus, majorées d’une indemnité de 20 % du montant à titre de clause pénale, ainsi que la possibilité de résilier le contrat unilatéralement au tort du Client.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">6.5 Réserve de propriété</p>
                                    <p>Les Produits vendus restent la propriété de Distri-Jantes.com jusqu’à complet paiement de leur prix, conformément à la présente clause de réserve de propriété.</p>

                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article7 = !article7" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_orange
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 7. RECLAMATION - RETRACTATION – GARANTIE</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article7" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article7" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">7.1 Service clientèle</p>
                                    <p>
                                        Le service clientèle du Site est accessible du lundi au vendredi de 9h à 19h au numéro de téléphone non surtaxé suivant : 0811484100, par courrier électronique à : <a href="mailto:contact@distri-jantes.com" class="no_deco tc_black font-weight-bold">contact@distri-jantes.com</a> ou par courrier postal à l’adresse indiquée à l’article 1 des présentes conditions générales. Dans ces deux derniers cas, Distri-Jantes.com s’engage à apporter une réponse sous 48h ouvrées.
                                    </p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">7.2 Droit de rétractation – Vente à distance</p>
                                    <p>Conformément à la législation en vigueur en matière de vente à distance, le Client ayant la qualité de consommateur dispose d’un délai de 14 jours pour exercer son droit de rétractation sans avoir à justifier de motifs ni à payer de pénalités, à l’exception,  des frais de retour.</p>
                                    <p>Le délai mentionné à l’alinéa précédent court à compter de la réception pour les biens ou de l’acceptation de l’offre pour les prestations de services. Lorsque le délai d’expire un samedi, un dimanche ou un jour férié ou chômé, il est prorogé jusqu’au premier jour ouvrable suivant.</p>
                                    <p>Lorsque le droit de rétractation est exercé, le professionnel est tenu de rembourser le consommateur de la totalité des sommes versées, dans les meilleurs délais et au plus tard dans les trente jours suivant la date à laquelle ce droit a été exercé. Au-delà, la somme due est, de plein droit, productive d’intérêts au taux légal en vigueur. Ce remboursement s’effectue par tout moyen de paiement. Sur proposition du professionnel, le consommateur ayant exercé son droit de rétractation peut toutefois opter pour une autre modalité de remboursement.</p>
                                    <p>Le droit de rétractation ne peut être exercé pour les contrats de fourniture de services dont l’exécution a commencé avant la fin du délai de 14 jours francs.</p>
                                    <p>De même, conformément à l’article L. 121-20-2 du Code de la consommation, le droit de rétractation ne pourra être exercé pour les Produits personnalisés ou spécifiquement traités, tels que les packs jantes, les pneus montés, équilibrés et valvés.</p>
                                    <p>Les pneumatiques retournés ne pourront être acceptés par l’éditeur et ne donneront lieu a aucun remboursement dès lors qu’ils autont été montés sur des jantes, cette opération de montage en empêchant la revente en tant que pneumatiques neufs.</p>
                                    <p>Il en sera de même si les produits (jantes pneus accessoires) renvoyés sont incomplets ou endommagés impropres à la revente.</p>
                                    <p>Tout risque lié au retour est à la charge du client.</p>
                                    <p>Après réception et vérification des produits l’éditeur remboursera le client dans un délai de 14 jours selon la date à laquelle le droit de rétractation du client a été exercé.</p>
                                    <br>
                                    <p>7.3 Résolution du contrat sur l’initiative du Client</p>
                                    <p>Le Client ayant la qualité de consommateur peut dénoncer le contrat par lettre recommandée avec demande d’avis de réception en cas de dépassement de la date de livraison du bien excédant 30 jours. Le Client sera alors remboursé des sommes engagées par lui lors de la commande.</p>
                                    <p>La présente clause n’a pas vocation à s’appliquer si le retard de livraison est dû à un cas de force majeure. En pareil cas, le Client s’engage à ne pas exercer de poursuites à l’encontre de Distri-Jantes.com et renonce à se prévaloir de la résolution de la vente prévue au présent article.</p>
                                    <br>
                                    <p>7.4 Garanties</p>
                                    <p>7.4.1 Garantie des vices et défaut apparents</p>
                                    <p>Il appartient au Client de vérifier le bon état des Produits au moment de la livraison. Cette vérification doit notamment porter sur la qualité, les quantités et les références des Produits ainsi que leur conformité à la commande. Aucune réclamation ne sera prise en compte après un délai de trois jours à compter de la livraison. En tout état de cause, toute réclamation concernant les colis livrés ne sera prise en compte que si le Client ayant la qualité de commerçant a émis des réserves auprès du transporteur conformément aux articles L. 133-3 et suivants du Code de commerce.</p>
                                    <p>7.4.2 Garantie des vices et défauts cachés</p>
                                    <p>Les Clients disposent d’une garantie légale de délivrance conforme (article 1604 du Code civil), d’une garantie légale contre les vices cachés (article 1604 et s. du Code civil) et d’une garantie de sécurité (article 1386-1 et s. du Code civil).</p>
                                    <p>Les Clients ayant la qualité de consommateurs disposent en outre d’une garantie légale de conformité (article L. 211-1 et s. Code de la consommation).</p>
                                    <p>7.4.3 garantie</p>
                                    <p>Toutes les jantes vendues par Distri-Jantes bénéficient d’une garantie commerciale de deux ans.</p>
                                    <p>La garantie n’est pas cessible et n’est valable que pour l’acheteur principal.</p>
                                    <p>Aucune garantie ne sera possible si la jante en question porte des traces de coups ou de frottements dus aux trottoirs ou autres.</p>
                                    <p>La garantie ne comprend pas les accessoires en plastique qui peuvent être sur la jante (caches centraux-visserie plastique dans le contour des jantes etc…).</p>
                                    <p>La garantie du vernis est de 1 an. (Attention toutes les jantes polies sont à nettoyer exclusivement avec des produits à PH Neutre – Comme tous constructeurs, tous problèmes avec le poli ne peuvent être pris en charge et aucune garantie ne sera applicable)</p>
                                    <p>Toutes les jantes polies sont à nettoyer réguliérement surtout en temps hivernal afin de ne pas laisser le sel agir.</p>
                                    <p>La garantie de la structure de jante est de 2 ans. Nous insistons sur le fait que cela ne rentre pas en ligne de compte si la jante comporte un plat, un coup ou un choc visible.</p>
                                    <p>La fabrication d’une jante étant industrielle celle-ci peut contenir de légers grains de poussière sous la peinture ou encore de petits points noirs autre que sur la face visible de celle-ci (Comme sur les jantes constructeurs). Aucun remplacement de jante ne peut être demandé sur cette observation.</p>
                                    <p>Pour bénéficier du SAV, la jante ne doit avoir subi aucun choc.</p>
                                    <p>Les frais de ports pour le retour des jantes chez Distri-Jantes sont à la charge du client. L’envoi de produit remplacé (si la garantie est accordée) est quant à lui gratuit.</p>
                                    <p>7.5.1 Modalités de retours des Produits</p>
                                    <p>7.5.1.1 Retours dans le cadre de la mise en œuvre du droit de rétractation</p>
                                    <p>Les Produits ne pourront être acceptés par Distri-Jantes.com qu’à condition d’être retournés en parfait état, dans son emballage d’origine et n’ait pas été utilisé avant le retour.</p>
                                    <p>7.5.1.2 Retours dans le cadre de la mise en œuvre de la garantie</p>
                                    <p>Afin de mettre en œuvre la garantie, il appartient au Client de retourner le Produit à l’adresse indiquée par Distri-jantes.com, accompagné d’une lettre explicative en demandant soit l’échange, soit le remboursement.</p>
                                    <p>7.5.1.3 Transporteur</p>
                                    <p>Le Client devra aviser Distri-Jantes.com de son intention de retourner un ou plusieurs Produits. A ce titre le Client pourra soit :</p>
                                    <ul>
                                        <li>demander à ce que les Produits soient repris par un transporteur désigné par Distri-Jantes.com à une adresse convenue préalablement entre le Client et Distri-Jantes.com ;</li>
                                        <li>procéder lui-même au retour des Produits à l’adresse indiquée par Distri-Jantes.com.</li>
                                    </ul>
                                    <p>7.5.2 Frais de retour</p>
                                    <p>Lorsqu’un Client souhaite effectuer un retour, il appartient au Client de retourner le Produit à l’adresse indiquée par Distri-jantes.com par ces propres moyens et à ses frais, accompagné d’une lettre explicative en demandant soit l’échange, soit le remboursement.</p>
                                    <p>Les frais de retour en cas de refus de marchandise auprès de notre transporteur sont de 25 €HT par jante et sont, en tout état de cause, à la charge du Client.</p>
                                    <p>En cas de remboursement de marchandise, un abattement de 20% sera imputé sur le remboursement, sauf en cas de retour de marchandise non dans son état et emballage d’origine, non propre à la revente, percée ou découpée ou présentant quelconque trace de “bricolage” par le client, un abattement de 50% sera imputé sur le remboursement.</p>
                                    <p>7.5.3 Cas particuliers</p>
                                    <p>Dans l’hypothèse d’un retour de « packs montés équilibres valves » tels que définis sur le Site, les frais de montage « équilibrage valvage » et retour seront supportés par le Client.</p>
                                    <p>Les pneus et jantes retournés ne pourront être acceptés par Distri-Jantes.com dès lors qu’ils auront été montés, cette opération de montage en empêchant la revente en tant que Produits neufs.</p>
                                    <p>Les pneumatiques retournés ne pourront être acceptés par l’éditeur et ne donneront lieu a aucun remboursement dès lors qu’ils auront été montés sur des jantes,cette opération de montage en empêchant la revente en tant que pneumatiques neufs.</p>
                                    <p>Il en sera de même pour les jantes sur lesquelles auront été montés des pneus ou si les produits (jantes pneus , accessoires) sont renvoyés incomplets, endommagés impropres à la revente.</p>
                                    <p>Tout risque lié au retour est à la charge du client.</p>
                                    <p> Nous portons votre attention sur le fait que pour les véhicules de gamme "performance S-RS-AMG-R etc..." ainsi que les jantes à déport, il peut etre nécéssaire de mettre des spacers ou élargisseurs de voies sur l'essieu avant et/ou arrière </p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article8 = !article8" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_orange
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 8. ESPACE CLIENT</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article8" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article8" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">8.1 Création de l’espace client</p>
                                    <p>
                                        La création d’un espace client est un préalable indispensable à toute commande d’un Internaute sur le Site. A cette fin, l’Internaute sera invité à fournir un certain nombre d’informations personnelles. Certaines de ces informations sont réputées indispensables à la création de l’espace client. Le refus par un Internaute de fournir lesdites informations aura pour effet d’empêcher la création de l’espace client ainsi que, incidemment, la validation de la commande.
                                    </p>
                                    <p>Lors de la création de l’espace client, l’Internaute est invité à choisir un mot de passe. Ce mot de passe constitue la garantie de la confidentialité des informations contenues dans l’espace client. L’Internaute s’interdit donc de le transmettre ou de le communiquer à un tiers. A défaut, Distri-Jantes.com ne pourra être tenu pour responsable des accès non autorisés à l’espace client d’un Internaute.</p>
                                    <p>Le Client s’engage à procéder à une vérification régulière des données qui le concernent et à procéder en ligne, depuis son espace personnel, aux actualisations et modifications nécessaires.</p>
                                    <p>Distri-Jantes.com s’engage à conserver de façon sécurisée tous les éléments contractuels dont la conservation est requise par la loi ou la réglementation en vigueur.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">8.2 Contenu de l’espace client</p>
                                    <p>L’espace client permet au Client de consulter et de suivre toutes ses commandes effectuées sur le Site.</p>
                                    <p>Les pages relatives aux espaces personnels sont librement imprimables par le titulaire du compte en question, mais ne constituent nullement une preuve admissible par un tribunal. Elles n’ont qu’un caractère informatif destiné à assurer une gestion efficace de ses commandes par le Client.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">8.3 Suppression de l’espace client</p>
                                    <p>Distri-Jantes.com se réserve le droit de supprimer le compte de tout Client qui contrevient aux présentes conditions générales, notamment lorsque le Client fournit des informations inexactes, incomplètes, mensongères ou frauduleuses, ainsi que lorsque l’espace client d’un Client sera resté inactif depuis au moins une année. Ladite suppression ne sera pas susceptible de constituer une faute de Distri-Jantes.com ou un dommage pour le Client exclu, qui ne pourra prétendre à aucune indemnité de ce fait.</p>
                                    <p>Cette exclusion est sans préjudice de la possibilité, pour Distri-Jantes.com, d’entreprendre des poursuites d’ordre judiciaire à l’encontre du Client, lorsque les faits l’auront justifié.</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article9 = !article9" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_orange
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 9. LIVRAISON</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article9" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article9" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">9.1 Frais de livraison</p>
                                    <p>
                                        Les frais de livraison ou de mise à disposition seront, en toute hypothèse, indiqués au Client avant tout règlement et ne concernent que les livraisons effectuées en France métropolitaine, Corse incluse. Pour tout autre lieu de livraison, il appartiendra au Client de prendre contact avec le service clientèle.
                                    </p>
                                    <p>Les frais de livraison indiqués sur le Site sont entendus en euros, toutes taxes comprises.</p>
                                   <br>
                                    <p class="font-weight-bold subtitle-1">9.2 Délai de livraison</p>
                                    <p>Les commandes sont livrées par Mazet ou tout autre transporteur choisi par Distri-Jantes.com dans un délai maximum de 10 jours ouvrés à compter du parfait encaissement du prix par Distri-Jantes.com.</p>
                                    <p>Certains produits ou certains volumes de commande peuvent néanmoins justifier un délai de livraison supérieur à 10 jours ouvrés. Il en sera fait mention expresse à l’attention du Client lors de la validation de la commande.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">9.3 Colis détérioré</p>
                                    <p>En cas de livraison d’un colis manifestement et visiblement détérioré, il appartient au Client de le refuser afin de jouir de la garantie offerte par le transporteur. Le Client devra par ailleurs en informer le vendeur sans délai, afin qu’un nouveau colis lui soit préparé, puis expédié dès réception du colis détérioré en retour. Dans pareil cas, les délais de livraison indiqués plus haut dans les présentes conditions générales ne seront plus applicables.</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article10 = !article10" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_orange
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 10. INFORMATIQUE ET LIBERTES</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article10" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article10" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">10.1 Déclaration CNIL – Responsable du traitement</p>
                                    <p>
                                        Le fichier de Distri-Jantes.com comportant les données personnelles des Internautes et des Clients a fait l’objet d’une déclaration auprès de la CNIL enregistrée sous le n° 1636586 V 04791B.
                                    </p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">10.2 Caractère facultatif de la fourniture de données</p>
                                    <p>Les Internautes disposent de la libre faculté de fournir des informations personnelles les concernant. La fourniture d’informations personnelles n’est pas indispensable pour la navigation sur le Site.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">10.3 Fourniture impérative de données pour l’inscription</p>
                                    <p>En revanche, l’inscription sur le présent Site suppose la collecte par Distri-Jantes.com d’un certain nombre d’informations personnelles concernant les Internautes. Les Internautes ne souhaitant pas fournir les informations nécessaires à l’inscription ne pourront pas passer commande sur le présent Site.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">10.4 Respect de la finalité de la collecte de données personnelles</p>
                                    <p>Les données personnelles collectées font l’objet d’un traitement informatique et sont exclusivement réservées à Distri-Jantes.com. Les données récoltées sont nécessaires à la bonne administration du Site, ainsi qu’au respect de ses obligations contractuelles par Distri-Jantes.com. Ces données sont conservées par Distri-Jantes.com en cette unique qualité. Distri-Jantes.com s’engage à ne pas les utiliser dans un autre cadre, ni à les transmettre à des tiers, hors accord express des Clients ou cas prévus par la loi. Les données personnelles récoltées ne font l’objet d’aucun transfert vers l’étranger.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">10.5 Droit d’accès, de rectification et d’opposition</p>
                                    <p>Les coordonnées de tous les Clients inscrits sur le présent Site sont sauvegardées pour une durée de 6 mois, durée raisonnable nécessaire à la bonne administration du Site et à une utilisation normale des données. Ces données sont conservées dans des conditions sécurisées, selon les moyens actuels de la technique, dans le respect des dispositions de la Loi Informatique et Libertés du 6 janvier 1978.</p>
                                    <p>Conformément à cette dernière, les Clients disposent d’un droit d’opposition, d’interrogation, d’accès et de rectification des données qu’ils ont fournies. Pour cela, il leur suffit d’en faire la demande à Distri-Jantes.com, en la formulant à l’adresse électronique suivante : {#email_cnil}, ou encore par courrier postal à l’adresse du siège de Distri-Jantes.com mentionnée à l’article 1 des présentes conditions générales.</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article11 = !article11" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_orange
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 11. COOKIES ET ADRESSES IP DES INTERNAUTES</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article11" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article11" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">11.1 Cookies</p>
                                    <p>11.1.1 Objet de l’implantation des cookies</p>
                                    <p>Afin de permettre à tous les Internautes une navigation optimale sur le présent Site ainsi qu’un meilleur fonctionnement des différentes interfaces et applications, Distri-Jantes.com pourra procéder à l’implantation d’un cookie sur le poste informatique de l’Internaute.</p>
                                    <p>11.1.2 Finalité des cookies</p>
                                    <p>Les cookies permettent de stocker des informations relatives à la navigation sur le Site (date, page, heures), ainsi qu’aux éventuelles données saisies par les Internautes au cours de leur visite (recherches, login, email, mot de passe). Ces cookies ont vocation à être conservés sur le poste informatique de l’Internaute pour une durée variable allant jusqu’à 1 an, et pourront être lus et utilisés par Distri-Jantes.com lors d’une visite ultérieure de l’Internaute sur le présent Site.</p>
                                    <p>11.1.3 Faculté d’opposition de l’Internaute à l’implantation des cookies</p>
                                    <p>L’Internaute dispose de la possibilité de bloquer, modifier la durée de conservation, ou supprimer ces cookies via l’interface de son navigateur (généralement : outils ou options / vie privée ou confidentialité). Dans un tel cas, la navigation sur le présent Site ne sera pas optimisée. Si la désactivation systématique des cookies sur le navigateur de l’Internaute l’empêche d’utiliser certains Services, ce dysfonctionnement ne saurait en aucun cas constituer un dommage pour le Client qui ne pourra prétendre à aucune indemnité de ce fait.</p>
                                    <p>11.1.4 Suppression des cookies implantés</p>
                                    <p>Les Internautes ont aussi la possibilité de supprimer les cookies implantés sur leur ordinateur, en se rendant dans le menu de leur navigateur prévu à cet effet (généralement, outils ou options / vie privée ou confidentialité). Une telle action fait perdre aux Internautes le bénéfice apporté par les cookies.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">11.2 Adresses IP</p>
                                    <p>11.2.1 Définition et collecte des adresses IP</p>
                                    <p>L’adresse IP correspond à une série de chiffres séparés par des points permettant l’identification unique d’un ordinateur sur le réseau Internet. Distri-Jantes.com se réserve le droit de collecter l’adresse IP publique de tous les Internautes. La collecte de cette adresse IP sera effectuée de façon anonyme. L’adresse IP des Internautes sera conservée pendant la durée requise par la loi.</p>
                                    <p>11.2.2 Communication des adresses IP</p>
                                    <p>Distri-Jantes.com devra communiquer toutes les données personnelles relatives à un Internaute à la Police sur réquisition judiciaire ou à toute personne sur décision judiciaire. L’adresse IP pourra faire l’objet d’un rapprochement avec l’identité effective de l’Internaute en cas de communication de cette information par un fournisseur d’accès à internet.</p>
                                    <br>

                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article12 = !article12" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_orange
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 12. RESPONSABILITE DE DISTRI-JANTES.COM</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article12" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article12" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">12.1 Nature des obligations de Distri-Jantes.com</p>
                                    <p>Distri-Jantes.com s’engage à apporter le soin et la diligence nécessaire à la fourniture de Produits de qualité conforme aux spécifications des présentes conditions générales. Distri-Jantes.com ne répond que d’une obligation de moyen concernant les services objet des présentes.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">12.2 Force majeure – Faute du Client</p>
                                    <p>Distri-Jantes.com n’engagera pas sa responsabilité en cas de force majeure ou de faute du Client, telles que définies au présent article :</p>
                                    <p>12.2.1 Force majeure</p>
                                    <p>Au sens des présentes conditions générales, sera considéré comme un cas de force majeure opposable au Client tout empêchement, limitation ou dérangement du Service du fait d’incendie, d’épidémie, d’explosion, de tremblement de terre, de fluctuations de la bande passante, de manquement imputable au fournisseur d’accès, de défaillance des réseaux de transmission, d’effondrement des installations, d’utilisation illicite ou frauduleuse des mots de passe, codes ou références fournis au Client, de piratage informatique, d’une faille de sécurité imputable à l’hébergeur du Site ou aux développeurs, d’inondation, de panne d’électricité, de guerre, d’embargo, de loi, d’injonction, de demande ou d’exigence de tout gouvernement, de réquisition, de grève, de boycott, ou autres circonstances hors du contrôle raisonnable de Distri-Jantes.com. Dans de telles circonstances, Distri-Jantes.com sera dispensé de l’exécution de ses obligations dans la limite de cet empêchement, de cette limitation ou de ce dérangement.</p>
                                    <p>12.2.2 Faute du Client</p>
                                    <p>Au sens des présentes Conditions générales, sera considéré comme une faute du Client opposable à ce dernier toute mauvaise utilisation du Service, faute, négligence, omission ou défaillance de sa part ou de celle de ses préposés, non-respect des conseils donnés par Distri-Jantes.com sur son Site, toute divulgation ou utilisation illicite du mot de passe, des codes et références du Client, ainsi que le renseignement d’informations erronées ou l’absence de mise à jour de telles informations dans son espace personnel. Sera également considérée comme une faute du Client la mis en œuvre de tout procédé technique, tels que des robots, ou des requêtes automatiques, dont la mise en œuvre contreviendrait à la lettre ou à l’esprit des présentes conditions générales de vente.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">12.3 Problèmes techniques – Liens hypertextes</p>
                                    <p>En cas d’impossibilité d’accès au Site, en raison de problèmes techniques de toutes natures, le Client ne pourra se prévaloir d’un dommage et ne pourra prétendre à aucune indemnité. L’indisponibilité, même prolongée et sans aucune durée limitative, d’un ou plusieurs services en ligne, ne peut être constitutive d’un préjudice pour les Clients et ne peut aucunement donner lieu à l’octroi de dommages et intérêts de la part de Distri-Jantes.com.</p>
                                    <p>Les liens hypertextes présents sur le Site peuvent renvoyer sur d’autres sites internet. La responsabilité de Distri-Jantes.com ne saurait être engagée si le contenu de ces sites contrevient aux législations en vigueur. De même la responsabilité de Distri-Jantes.com ne saurait être engagée si la visite, par l’Internaute, de l’un de ces sites, lui causait un préjudice.</p>
                                    <p>En l’état actuel de la technique, le rendu des représentations des Produits proposés à la vente sur le présent Site, notamment en termes de couleurs ou de formes, peut sensiblement varier d’un poste informatique à un autre ou différer de la réalité selon la qualité des accessoires graphiques et de l’écran ou selon la résolution de l’affichage. Ces variations et différences ne pourront en nulle hypothèse être imputées à Distri-Jantes.com qui ne pourra en aucun cas voir sa responsabilité engagée de ce fait.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">12.4 Dommages-intérêts à la charge de Distri-Jantes.com</p>
                                    <p>A défaut de dispositions légales ou réglementaires contraires, la responsabilité de Distri-Jantes.com est limitée au préjudice direct, personnel et certain subi par le Client et lié à la défaillance en cause. Distri-Jantes.com ne pourra en aucun cas être tenu responsable des dommages indirects tels que, notamment les pertes de données, les préjudices commerciaux, les pertes de commandes, les atteintes à l’image de marque, les troubles commerciaux et les pertes de bénéfices ou de clients. De même et dans les mêmes limites, le montant des dommages et intérêts mis à la charge de Distri-Jantes.com ne pourra en tout état de cause excéder le prix du Produit commandé.</p>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article13 = !article13" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_orange
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 13. PROPRIETE INTELLECTUELLE</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article13" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article13" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p>Le contenu (textes, images, schémas…), la structure et le logiciel mis en œuvre pour le fonctionnement du Site sont protégés par le droit d’auteur et le droit des bases de données. Toute représentation ou reproduction intégrale ou partielle faite sans le consentement de Distri-Jantes.com ou de ses ayants droit ou ayants cause constitue une violation des Livres I et III du Code de la propriété intellectuelle et sera susceptible de donner lieu à des poursuites judiciaires. Il en est de même pour la traduction, l’adaptation ou la transformation, l’arrangement ou la reproduction par un art ou un procédé quelconque.</p>
                                    <p>Les informations publiées sur le présent Site le sont à titre indicatif, sans garantie d’exactitude. Distri-Jantes.com ne peut en aucun cas être tenue responsable du fait d’une omission, d’une inexactitude ou de toute erreur contenue dans ces informations et qui serait à l’origine d’un dommage direct ou indirect causé à l’Internaute.</p>
                                    <br>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>
                <v-col offset-lg="1" lg="10">
                    <v-card >
                        <v-row  @click="article14 = !article14" >
                            <v-col offset-lg="1" lg="10" cols="10" class="border-right">
                                <v-card-text>
                                    <span  class="font-weight-bold tc_orange
                                                text-xl-h5
                                                text-lg-subtitle-1
                                                text-md-subtitle-1
                                                text-sm-subtitle-1
                                                text-subtitle-2">ARTICLE 14. DISPOSITIONS GENERALES</span>
                                </v-card-text>
                            </v-col>
                            <v-col lg="1" cols="2" class="align-self-center justify-end d-flex"  >
                                <v-icon v-if="!article14" x-large class="pr-4" >mdi mdi-chevron-down</v-icon>
                                <v-icon v-else x-large class="pr-4" >mdi mdi-chevron-up</v-icon>
                            </v-col>
                        </v-row>
                        <v-col v-if="article14" class="border-top">
                            <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <p class="font-weight-bold subtitle-1">14.1 Droit applicable</p>
                                    <p>Les présentes conditions générales sont soumises à l’application du droit français.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">14.2 Modifications des présentes conditions générales</p>
                                    <p>Les présentes conditions générales peuvent être modifiées à tout moment par Distri-Jantes.com. Les conditions générales applicables au Client sont celles en vigueur au jour de sa commande ou de sa connexion sur le présent Site, toute nouvelle connexion à l’espace personnel emportant acceptation le cas échéant des nouvelles conditions générales.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">14.3 Règlement amiable des litiges</p>
                                    <p>Sauf dispositions d’ordre public, tous litiges qui pourraient survenir dans le cadre de l’exécution des présentes conditions générales devront avant toute action judiciaire être soumis à l’appréciation de Distri-Jantes.com en vue d’un règlement amiable.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">14.4 Entièreté</p>
                                    <p>La nullité d’une des clauses du présent contrat n’entraînera pas la nullité des autres clauses du contrat ou du contrat dans sa globalité, qui garderont leur plein effet et portée. Dans une telle hypothèse, les parties devront dans la mesure du possible remplacer la stipulation annulée par une stipulation valable correspondant à l’esprit et à l’objet des présentes.</p>
                                    <br>
                                    <p class="font-weight-bold subtitle-1">14.5 Non-renonciation</p>
                                    <p>L’absence d’exercice par Distri-Jantes.com des droits qui lui sont reconnus par les présentes ne pourra en aucun cas être interprétée comme une renonciation à faire valoir lesdits droits.</p>
                                    <br>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-card>
                </v-col>

            </v-col>

        </v-main>


        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "../headers/HomepageHeaderEj";
import HomepageHeaderDj from "../headers/HomepageHeaderDj";
import HomepageFooterEj from "../footer/HomepageFooterEj";
import HomepageFooterDj from "../footer/HomepageFooterDj";

    export default {
        name: "CGV",
        components:{
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,
        },
        data: () => ({
            article1:false,
            article2:false,
            article3:false,
            article4:false,
            article5:false,
            article6:false,
            article7:false,
            article8:false,
            article9:false,
            article10:false,
            article11:false,
            article12:false,
            article13:false,
            article14:false,
            VUE_APP_SITE_ID:null
        }),
        methods: {

        },
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        }
    }
</script>

<style scoped>
    .border-top{
        border-top: 1px solid rgba(137, 158, 168, 0.15)
    }
</style>
