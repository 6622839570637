<template>
<!--    <v-app  class="background" fluid >-->
    <v-container fluid class="bc_white" >
        <v-col v-if="VUE_APP_SITE_ID === '1'" fluid offset-lg="1" lg="10" class="bc_white">


            <v-row v-if="forgottenPassw === false">

                <v-col cols="12" v-if="passwReseted">
                   <p class="font-weight-bold tc_turquoise"> Votre mot de passe a été réinitialisé, veuillez consultez vos mails.</p>
                </v-col>

                <v-col v-if="$vuetify.breakpoint.lgAndUp" lg="6" class="bulle--padding-bottom-xl">

                    <v-img  src="@/assets/forme.svg" style="position: absolute; width: 40%" alt='Espace-Jantes Creation de compte' title ='Espace-Jantes Creation de compte' />
                    <v-col offset-lg="5" class="font-weight-bold "
                           :class="{
                                'headline':$vuetify.breakpoint.lg,
                                'pt-12':$vuetify.breakpoint.lg,
                                'mt-8':$vuetify.breakpoint.lg,
                                'display-1':$vuetify.breakpoint.xl,
                                'bulle--padding-top-xl':$vuetify.breakpoint.xl,
                                'display-1':$vuetify.breakpoint.xl,
                                'pb-6':$vuetify.breakpoint.xl,
                                'tc_blue':VUE_APP_SITE_ID === '1',
                                'tc_darkOrange':VUE_APP_SITE_ID === '2',
                            }"
                           style="position: relative;"
                            >Se connecter</v-col>

                    <form style="position: relative;">
                        <v-col offset-lg="3" lg="7">
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="login.email"
                                    :error-messages="emailLogInErrors"
                                    required
                                    @input="$v.login.email.$touch()"
                                    @blur="$v.login.email.$touch()"
                            />
                           <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Mot de passe <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                id="textboxMdpLogin"
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="login.password"
                                    :error-messages="passwordLogInErrors"
                                    required
                                    @input="$v.login.password.$touch()"
                                    @blur="$v.login.password.$touch()"
                                    :append-icon="show_logIn ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show_logIn ? 'text' : 'password'"
                                    @click:append="show_logIn = !show_logIn"
                            />
                            <v-btn v-if="!isSubmitted_login && VUE_APP_SITE_ID === '1'" class="font-weight-bold " dark color="#1840DA" id="buttonMdpLogin" @click="submit_logIn">Identification</v-btn>
                            <v-btn v-if="isSubmitted_login && VUE_APP_SITE_ID === '1'" loading class="mr-4 text-right" color="primary">Identification</v-btn>
                            <v-btn v-if="!isSubmitted_login && VUE_APP_SITE_ID === '2'" class="font-weight-bold " dark color="#FC5C1F" id="buttonMdpLogin" @click="submit_logIn">Identification</v-btn>
                            <v-btn v-if="isSubmitted_login && VUE_APP_SITE_ID === '2'" loading class="mr-4 text-right" color="#FC5C1F">Identification</v-btn>


                            <v-row>
                                <span v-if="error_login" class="mt-5 ml-3" style="color:red">{{ errorMsg_login }}</span>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <span class="tc_black pointer" @click="forgottenPassw = true" :class="{'caption':$vuetify.breakpoint.lg, 'body-2':$vuetify.breakpoint.xl}">{{MessagePwd}}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </form>

                </v-col>

                <v-col v-if="$vuetify.breakpoint.mdAndDown" lg="6" class="bulle--padding-bottom-xl">
                    <v-card >
                        <v-card-text class="tc_black">
                            <v-col offset-lg="5" class="font-weight-bold"
                               :class="{
                                    'headline':$vuetify.breakpoint.lg,
                                    'pt-12':$vuetify.breakpoint.lg,
                                    'mt-8':$vuetify.breakpoint.lg,
                                    'display-1':$vuetify.breakpoint.xl,
                                    'bulle--padding-top-xl':$vuetify.breakpoint.xl,
                                    'display-1':$vuetify.breakpoint.xl,
                                    'pb-6':$vuetify.breakpoint.xl,
                                'tc_blue':VUE_APP_SITE_ID === '1',
                                'tc_darkOrange':VUE_APP_SITE_ID === '2',
                                }"
                               style="position: relative;"
                                >Se connecter</v-col>

                            <form style="position: relative;">
                        <v-col offset-lg="3" lg="7">
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="login.email"
                                    :error-messages="emailLogInErrors"
                                    required
                                    @input="$v.login.email.$touch()"
                                    @blur="$v.login.email.$touch()"
                            />
                           <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Mot de passe <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="login.password"
                                    :error-messages="passwordLogInErrors"
                                    required
                                    @input="$v.login.password.$touch()"
                                    @blur="$v.login.password.$touch()"
                                    :append-icon="show_logIn ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show_logIn ? 'text' : 'password'"
                                    @click:append="show_logIn = !show_logIn"
                            />
                            <v-btn v-if="!isSubmitted_login && VUE_APP_SITE_ID === '1'" class="font-weight-bold "  dark color="#1840DA" id="buttonMdpLogin" @click="submit_logIn">Identification</v-btn>
                            <v-btn v-if="isSubmitted_login && VUE_APP_SITE_ID === '1'" loading class="mr-4 text-right" color="primary">Identification</v-btn>
                            <v-btn v-if="!isSubmitted_login && VUE_APP_SITE_ID === '2'" class="font-weight-bold "  dark color="#FC5C1F" id="buttonMdpLogin" @click="submit_logIn">Identification</v-btn>
                            <v-btn v-if="isSubmitted_login && VUE_APP_SITE_ID === '2'" loading class="mr-4 text-right" color="#FC5C1F">Identification</v-btn>


                            <v-row>
                                <span v-if="error_login" class="mt-5 ml-3" style="color:red">{{ errorMsg_login }}</span>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <span class="tc_black pointer" @click="forgottenPassw = true" :class="{'caption':$vuetify.breakpoint.lg, 'body-2':$vuetify.breakpoint.xl}">{{MessagePwd}}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </form>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col lg="6" v-if="$vuetify.breakpoint.lgAndUp" :class="{'bulle--padding-bottom-xl':$vuetify.breakpoint.xl}">

                    <v-img src="@/assets/forme.svg" style="position: absolute; width: 40%" alt='Espace-Jantes Creation de compte' title ='Espace-Jantes Creation de compte'/>
                    <v-col offset-lg="5" offset-xl="4" class="font-weight-bold "
                           :class="{
                                'headline':$vuetify.breakpoint.lg,
                                'pt-12':$vuetify.breakpoint.lg,
                                'mt-8':$vuetify.breakpoint.lg,
                                'display-1':$vuetify.breakpoint.xl,
                                'bulle--padding-top-xl':$vuetify.breakpoint.xl,
                                'display-1':$vuetify.breakpoint.xl,
                                'pb-6':$vuetify.breakpoint.xl,
                                'tc_blue':VUE_APP_SITE_ID === '1',
                                'tc_darkOrange':VUE_APP_SITE_ID === '2',
                            }"
                           style="position: relative">Créer un compte</v-col>

                    <form style="position: relative;">
                        <v-col offset-lg="3" lg="7">
                            <v-row>
                            <v-col lg="6">
                                <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Nom <span class="tc_turquoise">*</span></p>
                                <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.firstname"
                                        :error-messages="firstnameRegisterErrors"
                                        required
                                        @input="$v.register.firstname.$touch()"
                                        @blur="$v.register.firstname.$touch()"
                                />
                            </v-col>

                            <v-col lg="6">
                                <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Prénom <span class="tc_turquoise">*</span></p>
                                <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.lastname"
                                        :error-messages="lastnameRegisterErrors"
                                        required
                                        @input="$v.register.lastname.$touch()"
                                        @blur="$v.register.lastname.$touch()"
                                />
                            </v-col>
                            </v-row>
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Téléphone <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="register.phone"
                                    :error-messages="phoneRegisterErrors"
                                    required
                                    @input="$v.register.phone.$touch()"
                                    @blur="$v.register.phone.$touch()"
                            />
                            <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="register.email"
                                    :error-messages="emailRegisterErrors"
                                    required
                                    @input="$v.register.email.$touch()"
                                    @blur="$v.register.email.$touch()"
                            />

                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Mot de passe <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                id="textboxMdpRegister"
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="register.password"
                                    :error-messages="passwordRegisterErrors"
                                    required
                                    @input="$v.register.password.$touch()"
                                    @blur="$v.register.password.$touch()"
                                    :append-icon="show_register ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show_register ? 'text' : 'password'"
                                    @click:append="show_register = !show_register"
                            />
                            <v-btn v-if="!isSubmitted_register && VUE_APP_SITE_ID === '1'" class="font-weight-bold" dark color="#1840DA" id="buttonMdpRegister" @click="submit_register">S'enregistrer</v-btn>
                            <v-btn v-if="isSubmitted_register && VUE_APP_SITE_ID === '1'" loading class="mr-4 text-right" color="primary">S'enregistrer</v-btn>
                            <v-btn v-if="!isSubmitted_register && VUE_APP_SITE_ID === '2'" class="font-weight-bold" dark color="#FC5C1F" id="buttonMdpRegister" @click="submit_register">S'enregistrer</v-btn>
                            <v-btn v-if="isSubmitted_register && VUE_APP_SITE_ID === '2'" loading class="mr-4 text-right" color="#FC5C1F">S'enregistrer</v-btn>
                            <br>
                            <v-row>
                                <span v-if="error_register" class="mt-5" style="color:red">{{ errorMsg_register }}</span>
                            </v-row>


                        </v-col>
                    </form>

                </v-col>

                <v-col lg="6" v-if="$vuetify.breakpoint.mdAndDown" :class="{'bulle--padding-bottom-xl':$vuetify.breakpoint.xl}">
                <v-card >
                    <v-card-text class="tc_black">
                    <v-col offset-lg="5" offset-xl="4" class="font-weight-bold "
                           :class="{
                                'headline':$vuetify.breakpoint.lg,
                                'pt-12':$vuetify.breakpoint.lg,
                                'mt-8':$vuetify.breakpoint.lg,
                                'display-1':$vuetify.breakpoint.xl,
                                'bulle--padding-top-xl':$vuetify.breakpoint.xl,
                                'display-1':$vuetify.breakpoint.xl,
                                'pb-6':$vuetify.breakpoint.xl,
                                'tc_blue':VUE_APP_SITE_ID === '1',
                                'tc_darkOrange':VUE_APP_SITE_ID === '2',
                            }"
                           style="position: relative">Créer un compte</v-col>

                    <form style="position: relative;">
                        <v-col offset-lg="3" lg="7">
                            <v-row>
                            <v-col lg="6">
                                <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Nom <span class="tc_turquoise">*</span></p>
                                <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.firstname"
                                        :error-messages="firstnameRegisterErrors"
                                        required
                                        @input="$v.register.firstname.$touch()"
                                        @blur="$v.register.firstname.$touch()"
                                />
                            </v-col>

                            <v-col lg="6">
                                <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Prénom <span class="tc_turquoise">*</span></p>
                                <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.lastname"
                                        :error-messages="lastnameRegisterErrors"
                                        required
                                        @input="$v.register.lastname.$touch()"
                                        @blur="$v.register.lastname.$touch()"
                                />
                            </v-col>
                            </v-row>
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Téléphone <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="register.phone"
                                    :error-messages="phoneRegisterErrors"
                                    required
                                    @input="$v.register.phone.$touch()"
                                    @blur="$v.register.phone.$touch()"
                            />
                            <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="register.email"
                                    :error-messages="emailRegisterErrors"
                                    required
                                    @input="$v.register.email.$touch()"
                                    @blur="$v.register.email.$touch()"
                            />

                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Mot de passe <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                id="textboxMdpRegister"
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="register.password"
                                    :error-messages="passwordRegisterErrors"
                                    required
                                    @input="$v.register.password.$touch()"
                                    @blur="$v.register.password.$touch()"
                                    :append-icon="show_register ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show_register ? 'text' : 'password'"
                                    @click:append="show_register = !show_register"
                            />
                            <v-btn v-if="!isSubmitted_register && VUE_APP_SITE_ID === '1'" class="font-weight-bold " dark color="#1840DA" id="buttonMdpRegister" @click="submit_register">S'enregistrer</v-btn>
                            <v-btn v-if="isSubmitted_register && VUE_APP_SITE_ID === '1'" loading class="mr-4 text-right" color="primary">S'enregistrer</v-btn>
                            <v-btn v-if="!isSubmitted_register && VUE_APP_SITE_ID === '2'" class="font-weight-bold " dark color="#FC5C1F" id="buttonMdpRegister" @click="submit_register">S'enregistrer</v-btn>
                            <v-btn v-if="isSubmitted_register && VUE_APP_SITE_ID === '2'" loading class="mr-4 text-right" color="#FC5C1F">S'enregistrer</v-btn>
                            <br>
                            <v-row>
                                <span v-if="error_register" class="mt-5" style="color:red">{{ errorMsg_register }}</span>
                            </v-row>


                        </v-col>
                    </form>
                    </v-card-text>
                </v-card>
                </v-col>

            </v-row>

            <v-row v-if="forgottenPassw === true" >


                <v-col offset="2" lg="6" class="bulle--padding-bottom-xl">

                    <v-img src="@/assets/forme.svg" style="position: absolute; width: 40%" alt='Espace-Jantes Creation de compte' title ='Espace-Jantes Creation de compte'/>
                    <v-col offset-lg="5" class="font-weight-bold "
                           :class="{
                                'headline':$vuetify.breakpoint.lg,
                                'pt-12':$vuetify.breakpoint.lg,
                                'mt-8':$vuetify.breakpoint.lg,
                                'display-1':$vuetify.breakpoint.xl,
                                'bulle--padding-top-xl':$vuetify.breakpoint.xl,
                                'display-1':$vuetify.breakpoint.xl,
                                'pb-6':$vuetify.breakpoint.xl,
                                'tc_blue':VUE_APP_SITE_ID === '1',
                                'tc_darkOrange':VUE_APP_SITE_ID === '2',
                            }"
                           style="position: relative;"
                    >{{MessagePwd}}</v-col>
                    <v-col offset-lg="4" lg="6">
                        <p class="mb-0" style="position: relative">Entrez votre e-mail et recevez votre nouveau mot de passe.</p>

                    </v-col>


                    <form style="position: relative;">
                        <v-col offset-lg="3" lg="7">
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_turquoise">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="forgotten_email"
                                    :error-messages="emailForgottenErrors"
                                    required
                                    @input="$v.forgotten_email.$touch()"
                                    @blur="$v.forgotten_email.$touch()"
                            />

                            <v-btn v-if="!isSubmitted_forgot && VUE_APP_SITE_ID === '1'" class="font-weight-bold " dark color="#1840DA" @click="submit_forgot">Recevoir un nouveau mot de passe</v-btn>
                            <v-btn v-if="isSubmitted_forgot && VUE_APP_SITE_ID === '1'" loading class="mr-4 text-right" color="primary">Recevoir un nouveau mot de passe</v-btn>
                            <v-btn v-if="!isSubmitted_forgot && VUE_APP_SITE_ID === '2'" class="font-weight-bold " dark color="#FC5C1F" @click="submit_forgot">Recevoir un nouveau mot de passe</v-btn>
                            <v-btn v-if="isSubmitted_forgot && VUE_APP_SITE_ID === '2'" loading class="mr-4 text-right" color="#FC5C1F">Recevoir un nouveau mot de passe</v-btn>
                            <v-row>
                                <span v-if="error_forgot" class="mt-5 ml-4" style="color:red">{{ errorMsg_forgot }}</span>
                            </v-row>
                        </v-col>
                    </form>

                </v-col>


            </v-row>


        </v-col>


        <v-col v-if="VUE_APP_SITE_ID === '2'" fluid offset-lg="1" lg="10" class="bc_white">

            <v-row v-if="forgottenPassw === false">

                <v-col cols="12" v-if="passwReseted">
                   <p class="font-weight-bold tc_orange"> Votre mot de passe a été réinitialisé, veuillez consultez vos mails.</p>
                </v-col>

                <v-col v-if="$vuetify.breakpoint.lgAndUp" offset-lg="1" lg="4" class="bulle--padding-bottom-xl">

                    <v-card elevation="10">
                        <v-col offset-lg="3" class="font-weight-bold "
                               :class="{
                                    'headline':$vuetify.breakpoint.lg,
                                    'pt-12':$vuetify.breakpoint.lg,
                                    'mt-8':$vuetify.breakpoint.lg,
                                    'display-1':$vuetify.breakpoint.xl,
                                    'pt-12':$vuetify.breakpoint.xl,
                                    'display-1':$vuetify.breakpoint.xl,
                                    'pb-6':$vuetify.breakpoint.xl,
                                    'tc_blue':VUE_APP_SITE_ID === '1',
                                    'tc_darkOrange':VUE_APP_SITE_ID === '2',
                                }"
                               style="position: relative;"
                                >Se connecter</v-col>

                        <form style="position: relative;">
                        <v-col offset-lg="2" lg="8">
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_orange">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="login.email"
                                    :error-messages="emailLogInErrors"
                                    required
                                    @input="$v.login.email.$touch()"
                                    @blur="$v.login.email.$touch()"
                            />
                           <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Mot de passe <span class="tc_orange">*</span></p>
                            <v-text-field
                                id="textboxMdpLogin"
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="login.password"
                                    :error-messages="passwordLogInErrors"
                                    required
                                    @input="$v.login.password.$touch()"
                                    @blur="$v.login.password.$touch()"
                                    :append-icon="show_logIn ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show_logIn ? 'text' : 'password'"
                                    @click:append="show_logIn = !show_logIn"
                            />
                            <v-btn v-if="!isSubmitted_login && VUE_APP_SITE_ID === '1'" class="font-weight-bold " dark color="#1840DA" id="buttonMdpLogin" @click="submit_logIn">Identification</v-btn>
                            <v-btn v-if="isSubmitted_login && VUE_APP_SITE_ID === '1'" loading class="mr-4 text-right" color="primary">Identification</v-btn>
                            <v-btn v-if="!isSubmitted_login && VUE_APP_SITE_ID === '2'" class="font-weight-bold " dark color="#FC5C1F" id="buttonMdpLogin" @click="submit_logIn">Identification</v-btn>
                            <v-btn v-if="isSubmitted_login && VUE_APP_SITE_ID === '2'" loading class="mr-4 text-right" color="#FC5C1F">Identification</v-btn>


                            <v-row>
                                <span v-if="error_login" class="mt-5 ml-3" style="color:red">{{ errorMsg_login }}</span>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <span class="tc_black pointer" @click="forgottenPassw = true" :class="{'caption':$vuetify.breakpoint.lg, 'body-2':$vuetify.breakpoint.xl}">{{MessagePwd}}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </form>
                    </v-card>
                </v-col>

                <v-col v-if="$vuetify.breakpoint.mdAndDown" lg="6" class="bulle--padding-bottom-xl">
                    <v-card >
                        <v-card-text class="tc_black">
                            <v-col offset-lg="5" class="font-weight-bold"
                               :class="{
                                    'headline':$vuetify.breakpoint.lg,
                                    'pt-12':$vuetify.breakpoint.lg,
                                    'mt-8':$vuetify.breakpoint.lg,
                                    'display-1':$vuetify.breakpoint.xl,
                                    'bulle--padding-top-xl':$vuetify.breakpoint.xl,
                                    'display-1':$vuetify.breakpoint.xl,
                                    'pb-6':$vuetify.breakpoint.xl,
                                'tc_blue':VUE_APP_SITE_ID === '1',
                                'tc_darkOrange':VUE_APP_SITE_ID === '2',
                                }"
                               style="position: relative;"
                                >Se connecter</v-col>

                            <form style="position: relative;">
                        <v-col offset-lg="3" lg="7">
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_orange">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="login.email"
                                    :error-messages="emailLogInErrors"
                                    required
                                    @input="$v.login.email.$touch()"
                                    @blur="$v.login.email.$touch()"
                            />
                           <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Mot de passe <span class="tc_orange">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="login.password"
                                    :error-messages="passwordLogInErrors"
                                    required
                                    @input="$v.login.password.$touch()"
                                    @blur="$v.login.password.$touch()"
                                    :append-icon="show_logIn ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show_logIn ? 'text' : 'password'"
                                    @click:append="show_logIn = !show_logIn"
                            />
                            <v-btn v-if="!isSubmitted_login && VUE_APP_SITE_ID === '1'" class="font-weight-bold "  dark color="#1840DA" id="buttonMdpLogin" @click="submit_logIn">Identification</v-btn>
                            <v-btn v-if="isSubmitted_login && VUE_APP_SITE_ID === '1'" loading class="mr-4 text-right" dark color="primary">Identification</v-btn>
                            <v-btn v-if="!isSubmitted_login && VUE_APP_SITE_ID === '2'" class="font-weight-bold "  dark color="#FC5C1F" id="buttonMdpLogin" @click="submit_logIn">Identification</v-btn>
                            <v-btn v-if="isSubmitted_login && VUE_APP_SITE_ID === '2'" loading class="mr-4 text-right" dark color="#FC5C1F">Identification</v-btn>


                            <v-row>
                                <span v-if="error_login" class="mt-5 ml-3" style="color:red">{{ errorMsg_login }}</span>
                            </v-row>

                            <v-row>
                                <v-col>
                                    <span class="tc_black pointer" @click="forgottenPassw = true" :class="{'caption':$vuetify.breakpoint.lg, 'body-2':$vuetify.breakpoint.xl}">{{MessagePwd}}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </form>
                        </v-card-text>
                    </v-card>
                </v-col>

                <v-col offset-lg="1" lg="5" v-if="$vuetify.breakpoint.lgAndUp" :class="{'bulle--padding-bottom-xl':$vuetify.breakpoint.xl}">

                    <v-card elevation="10">
                        <v-col offset-lg="2" class="font-weight-bold "
                               :class="{
                                    'headline':$vuetify.breakpoint.lg,
                                    'pt-12':$vuetify.breakpoint.lg,
                                    'mt-8':$vuetify.breakpoint.lg,
                                    'display-1':$vuetify.breakpoint.xl,
                                    'pt-12':$vuetify.breakpoint.xl,
                                    'display-1':$vuetify.breakpoint.xl,
                                    'pb-6':$vuetify.breakpoint.xl,
                                    'tc_blue':VUE_APP_SITE_ID === '1',
                                    'tc_darkOrange':VUE_APP_SITE_ID === '2',
                                }"
                               style="position: relative">Créer un compte</v-col>

                        <form style="position: relative;">
                        <v-col offset-lg="2" lg="8">
                            <v-row>
                            <v-col lg="6">
                                <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Nom <span class="tc_orange">*</span></p>
                                <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.firstname"
                                        :error-messages="firstnameRegisterErrors"
                                        required
                                        @input="$v.register.firstname.$touch()"
                                        @blur="$v.register.firstname.$touch()"
                                />
                            </v-col>

                            <v-col lg="6">
                                <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Prénom <span class="tc_orange">*</span></p>
                                <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.lastname"
                                        :error-messages="lastnameRegisterErrors"
                                        required
                                        @input="$v.register.lastname.$touch()"
                                        @blur="$v.register.lastname.$touch()"
                                />
                            </v-col>
                            </v-row>
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Téléphone <span class="tc_orange">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="register.phone"
                                    :error-messages="phoneRegisterErrors"
                                    required
                                    @input="$v.register.phone.$touch()"
                                    @blur="$v.register.phone.$touch()"
                            />
                            <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_orange">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="register.email"
                                    :error-messages="emailRegisterErrors"
                                    required
                                    @input="$v.register.email.$touch()"
                                    @blur="$v.register.email.$touch()"
                            />

                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Mot de passe <span class="tc_orange">*</span></p>
                            <v-text-field
                                id="textboxMdpRegister"
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="register.password"
                                    :error-messages="passwordRegisterErrors"
                                    required
                                    @input="$v.register.password.$touch()"
                                    @blur="$v.register.password.$touch()"
                                    :append-icon="show_register ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show_register ? 'text' : 'password'"
                                    @click:append="show_register = !show_register"
                            />
                            <v-btn v-if="!isSubmitted_register && VUE_APP_SITE_ID === '1'" class="mb-12 font-weight-bold" dark color="#1840DA" id="buttonMdpRegister" @click="submit_register">S'enregistrer</v-btn>
                            <v-btn v-if="isSubmitted_register && VUE_APP_SITE_ID === '1'" loading class="mb-12 mr-4 text-right" dark color="primary">S'enregistrer</v-btn>
                            <v-btn v-if="!isSubmitted_register && VUE_APP_SITE_ID === '2'" class="mb-12 font-weight-bold" dark color="#FC5C1F" id="buttonMdpRegister" @click="submit_register">S'enregistrer</v-btn>
                            <v-btn v-if="isSubmitted_register && VUE_APP_SITE_ID === '2'" loading class="mb-12 mr-4 text-right" dark color="#FC5C1F">S'enregistrer</v-btn>
                            <br>
                            <v-row>
                                <span v-if="error_register" class="mt-5" style="color:red">{{ errorMsg_register }}</span>
                            </v-row>


                        </v-col>
                    </form>
                    </v-card>
                </v-col>

                <v-col lg="6" v-if="$vuetify.breakpoint.mdAndDown" :class="{'bulle--padding-bottom-xl':$vuetify.breakpoint.xl}">
                <v-card >
                    <v-card-text class="tc_black">
                    <v-col offset-lg="5" offset-xl="4" class="font-weight-bold "
                           :class="{
                                'headline':$vuetify.breakpoint.lg,
                                'pt-12':$vuetify.breakpoint.lg,
                                'mt-8':$vuetify.breakpoint.lg,
                                'display-1':$vuetify.breakpoint.xl,
                                'bulle--padding-top-xl':$vuetify.breakpoint.xl,
                                'display-1':$vuetify.breakpoint.xl,
                                'pb-6':$vuetify.breakpoint.xl,
                                'tc_blue':VUE_APP_SITE_ID === '1',
                                'tc_darkOrange':VUE_APP_SITE_ID === '2',
                            }"
                           style="position: relative">Créer un compte</v-col>

                    <form style="position: relative;">
                        <v-col offset-lg="3" lg="7">
                            <v-row>
                            <v-col lg="6">
                                <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Nom <span class="tc_orange">*</span></p>
                                <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.firstname"
                                        :error-messages="firstnameRegisterErrors"
                                        required
                                        @input="$v.register.firstname.$touch()"
                                        @blur="$v.register.firstname.$touch()"
                                />
                            </v-col>

                            <v-col lg="6">
                                <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Prénom <span class="tc_orange">*</span></p>
                                <v-text-field
                                        dense
                                        outlined
                                        background-color="white"
                                        v-model="register.lastname"
                                        :error-messages="lastnameRegisterErrors"
                                        required
                                        @input="$v.register.lastname.$touch()"
                                        @blur="$v.register.lastname.$touch()"
                                />
                            </v-col>
                            </v-row>
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Téléphone <span class="tc_orange">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="register.phone"
                                    :error-messages="phoneRegisterErrors"
                                    required
                                    @input="$v.register.phone.$touch()"
                                    @blur="$v.register.phone.$touch()"
                            />
                            <p class="mb-0 font-weight-bold" :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_orange">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="register.email"
                                    :error-messages="emailRegisterErrors"
                                    required
                                    @input="$v.register.email.$touch()"
                                    @blur="$v.register.email.$touch()"
                            />

                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Mot de passe <span class="tc_orange">*</span></p>
                            <v-text-field
                                id="textboxMdpRegister"
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="register.password"
                                    :error-messages="passwordRegisterErrors"
                                    required
                                    @input="$v.register.password.$touch()"
                                    @blur="$v.register.password.$touch()"
                                    :append-icon="show_register ? 'mdi-eye' : 'mdi-eye-off'"
                                    :type="show_register ? 'text' : 'password'"
                                    @click:append="show_register = !show_register"
                            />
                            <v-btn v-if="!isSubmitted_register && VUE_APP_SITE_ID === '1'" class="font-weight-bold " dark color="#1840DA" id="buttonMdpRegister" @click="submit_register">S'enregistrer</v-btn>
                            <v-btn v-if="isSubmitted_register && VUE_APP_SITE_ID === '1'" loading class="mr-4 text-right" color="primary">S'enregistrer</v-btn>
                            <v-btn v-if="!isSubmitted_register && VUE_APP_SITE_ID === '2'" class="font-weight-bold " dark color="#FC5C1F" id="buttonMdpRegister" @click="submit_register">S'enregistrer</v-btn>
                            <v-btn v-if="isSubmitted_register && VUE_APP_SITE_ID === '2'" loading class="mr-4 text-right" color="#FC5C1F">S'enregistrer</v-btn>
                            <br>
                            <v-row>
                                <span v-if="error_register" class="mt-5" style="color:red">{{ errorMsg_register }}</span>
                            </v-row>


                        </v-col>
                    </form>
                    </v-card-text>
                </v-card>
                </v-col>

            </v-row>

            <v-row v-if="forgottenPassw === true" >


                <v-col offset="2" lg="6" class="bulle--padding-bottom-xl">
                    <v-card class="pb-6">
                        <v-col offset-lg="3" class="font-weight-bold "
                               :class="{
                                    'headline':$vuetify.breakpoint.lg,
                                    'pt-12':$vuetify.breakpoint.lg,
                                    'mt-8':$vuetify.breakpoint.lg,
                                    'display-1':$vuetify.breakpoint.xl,
                                    'bulle--padding-top-xl':$vuetify.breakpoint.xl,
                                    'display-1':$vuetify.breakpoint.xl,
                                    'pb-6':$vuetify.breakpoint.xl,
                                    'tc_blue':VUE_APP_SITE_ID === '1',
                                    'tc_darkOrange':VUE_APP_SITE_ID === '2',
                                }"
                               style="position: relative;"
                        >{{MessagePwd}}</v-col>
                        <v-col offset-lg="3" lg="6">
                            <p class="mb-0" style="position: relative">Entrez votre e-mail et recevez votre nouveau mot de passe.</p>

                        </v-col>

                        <form style="position: relative;" >
                        <v-col offset-lg="3" lg="7">
                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse e-mail <span class="tc_orange">*</span></p>
                            <v-text-field
                                    dense
                                    outlined
                                    background-color="white"
                                    v-model="forgotten_email"
                                    :error-messages="emailForgottenErrors"
                                    required
                                    @input="$v.forgotten_email.$touch()"
                                    @blur="$v.forgotten_email.$touch()"
                            />

                            <v-btn v-if="!isSubmitted_forgot && VUE_APP_SITE_ID === '1'" class="font-weight-bold " dark color="#1840DA" @click="submit_forgot">Recevoir un nouveau mot de passe</v-btn>
                            <v-btn v-if="isSubmitted_forgot && VUE_APP_SITE_ID === '1'" loading class="mr-4 text-right" color="primary">Recevoir un nouveau mot de passe</v-btn>
                            <v-btn v-if="!isSubmitted_forgot && VUE_APP_SITE_ID === '2'" class="font-weight-bold " dark color="#FC5C1F" @click="submit_forgot">Recevoir un nouveau mot de passe</v-btn>
                            <v-btn v-if="isSubmitted_forgot && VUE_APP_SITE_ID === '2'" loading class="mr-4 text-right" color="#FC5C1F">Recevoir un nouveau mot de passe</v-btn>
                            <v-row>
                                <span v-if="error_forgot" class="mt-5 ml-4" style="color:red">{{ errorMsg_forgot }}</span>
                            </v-row>
                        </v-col>
                    </form>
                    </v-card>
                </v-col>


            </v-row>


        </v-col>
    </v-container>
<!--    </v-app>-->
</template>

<script>

    const { validationMixin } = require('vuelidate')
    const { required, minLength,maxLength, email, numeric } = require('vuelidate/lib/validators')
    import SecureLS from "secure-ls";
    var ls = new SecureLS({ isCompression: false });

    import Vue from 'vue'
    import axios from 'axios'
    import VueAxios from 'vue-axios'
    import CryptoJS from 'crypto-js'
    import $ from "jquery";


    Vue.use(VueAxios, axios)

    export default {
        name: "Register.vue",
        components: {

        },
        mixins: [validationMixin],

        validations: {
            register: {
                firstname: {required},
                lastname: {required},
                phone: {required, numeric, minLength: minLength(10), maxLength: maxLength(10)},
                email: { required, email },
                password: {
                    required,
                    minLength: minLength(8)
                },
            },
            login:{
                email: { required, email },
                password: {
                    required,
                    minLength: minLength(8)
                },
            },

            forgotten_email : { required, email },

            select: { required },
            checkbox: {
                checked (val) {
                    return val
                },
            },
        },
        data: () => ({
            name: '',
            email_logIn: '',
            password_logIn: '',
            firstname_register: '',
            lastname_register: '',
            phone_register: '',
            email_register: '',
            password_register: '',
            checkbox: '',
            show_logIn: false,
            show_register: false,
            login : {},
            register : {},
            forgottenPassw: false,
            forgotten_email:'',
            passwReseted:false,
            isSubmitted_login:false,
            isSubmitted_register:false,
            isSubmitted_forgot:false,
            error_register:false,
            errorMsg_register:'',
            error_login:false,
            errorMsg_login:'',
            error_forgot:false,
            errorMsg_forgot:'',
            MessagePwd : 'Mot de passe oublié ?',
            ReInit : false,
            VUE_APP_SITE_ID:null,
        }),
        created() {
            if(this.$route.query.mode === "reInitNewCustomer" ){
               this.forgottenPassw = true 
               this.ReInit = true
               this.MessagePwd = 'Réinitialiser votre mot de passe'
            }

        },
        mounted (){
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID

            setTimeout(()=>{
                $("#textboxMdpLogin").keyup(function(event) {
                    if (event.keyCode === 13) {
                        $("#buttonMdpLogin").click();
                    }
                });
                $("#textboxMdpRegister").keyup(function(event) {
                    if (event.keyCode === 13) {
                        $("#buttonMdpRegister").click();
                    }
                });
            },2000)

        },
        updated() {
            if(this.$route.query.mode === "reInitNewCustomer" && this.forgottenPassw == true){
               this.ReInit = true
               this.MessagePwd = 'Réinitialiser votre mot de passe'
            }
        },
        computed: {
            emailLogInErrors () {
                const errors = [];
                if (!this.$v.login.email.$dirty) return errors
                !this.$v.login.email.email && errors.push('E-mail invalide')
                !this.$v.login.email.required && errors.push('E-mail obligatoire')
                return errors
            },
            passwordLogInErrors () {
                const errors = [];
                if (!this.$v.login.password.$dirty) return errors
                !this.$v.login.password.minLength && errors.push('Le mot de passe doit faire minimum 8 caractères')
                !this.$v.login.password.required && errors.push('Mot de passe obligatoire')
                return errors
            },
            firstnameRegisterErrors () {
                const errors = [];
                if (!this.$v.register.firstname.$dirty) return errors
                // !this.$v.register.firstname.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.register.firstname.required && errors.push('Le prénom est obligatoire')
                return errors
            },
            lastnameRegisterErrors () {
                const errors = [];
                if (!this.$v.register.lastname.$dirty) return errors
                // !this.$v.register.lastname.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.register.lastname.required && errors.push('Le nom est obligatoire')
                return errors
            },
            phoneRegisterErrors () {
                const errors = [];
                if (!this.$v.register.phone.$dirty) return errors
                !this.$v.register.phone.required && errors.push('Téléphone obligatoire')
                !this.$v.register.phone.minLength && errors.push('Téléphone invalide')
                !this.$v.register.phone.maxLength && errors.push('Téléphone invalide')
                return errors
            },
            emailRegisterErrors () {
                const errors = [];
                if (!this.$v.register.email.$dirty) return errors
                !this.$v.register.email.email && errors.push('E-mail invalide')
                !this.$v.register.email.required && errors.push('E-mail obligatoire')
                return errors
            },
            passwordRegisterErrors () {
                const errors = [];
                if (!this.$v.register.password.$dirty) return errors
                !this.$v.register.password.minLength && errors.push('Le mot de passe doit faire minimum 8 caractères')
                !this.$v.register.password.required && errors.push('Mot de passe obligatoire')
                return errors
            },
            emailForgottenErrors () {
                const errors = [];
                if (!this.$v.forgotten_email.$dirty) return errors
                !this.$v.forgotten_email.email && errors.push('E-mail invalide')
                !this.$v.forgotten_email.required && errors.push('E-mail obligatoire')
                return errors
            },
        },

        methods: {
            submit_logIn () {
                this.$v.login.email.$touch();
                this.$v.login.password.$touch();

                this.isSubmitted_login = true
                this.error_login = false

                if (this.$v.login.email.$invalid || this.$v.login.password.$invalid) {
                    this.isSubmitted_login = false
                    return false
                }

                this.login.email = this.$v.login.email.$model
                this.login.password = this.$v.login.password.$model


                let cryptedPassword = CryptoJS.AES.encrypt(this.login.password, process.env.VUE_APP_SECRET_KEY).toString()


                Vue.axios.get('https://api.espace-jantes.com/loginUser', {
                    params: {
                        mail: this.login.email,
                        password: cryptedPassword,
                        websiteid: this.VUE_APP_SITE_ID === "1" ? "1" : "2"
                    }
                })
                .then((response) => {
console.log('response', response)
                    if(response.data.etat){

                        let userId = response.data.data.id
                        let token = response.data.token
                        let isRevendeur = false
                        let waitingRevendeur = false

                        let tauxRemise = response.data.data.taux_remise

                        response.data.data.status === 2 ? isRevendeur = true : isRevendeur = false
                        response.data.data.status === 4 ? waitingRevendeur = true : waitingRevendeur = false

                        this.$store.dispatch('auth/AUTH_REQUEST', { userId, token, isRevendeur, waitingRevendeur, tauxRemise }).then(() => {
                            this.$emit('connect', true)
                        })

                        return true
                    }else if(response.data.etat === false) {

                        this.errorMsg_login = response.data.message
                        this.error_login = true
                        this.isSubmitted_login = false
                        // console.log("La connexion à échouer ")
                        // localStorage.removeItem('user-token')
                        ls.remove('user-token')
                        return false
                    }
                })

                if(!this.$v.checkbox.$invalid)
                    this.login.keepMeLog = this.$v.checkbox.$model
            },
            submit_register () {
                this.$v.register.firstname.$touch();
                this.$v.register.lastname.$touch();
                this.$v.register.phone.$touch();
                this.$v.register.email.$touch();
                this.$v.register.password.$touch();
                this.isSubmitted_register = true
                this.error_register = false


                if (this.$v.register.firstname.$invalid || this.$v.register.lastname.$invalid || this.$v.register.phone.$invalid || this.$v.register.email.$invalid || this.$v.register.password.$invalid ) {
                    this.isSubmitted_register = false
                    return false
                }

                this.register.firstname = this.$v.register.firstname.$model
                this.register.lastname = this.$v.register.lastname.$model
                this.register.phone = this.$v.register.phone.$model
                this.register.email = this.$v.register.email.$model
                this.register.password = this.$v.register.password.$model

                let cryptedPassword = CryptoJS.AES.encrypt(this.register.password, process.env.VUE_APP_SECRET_KEY).toString()


                Vue.axios.post('https://api.espace-jantes.com/addUser', {
                    params: { 
                            mail: this.register.email,
                            password: cryptedPassword,
                            nom: this.register.firstname,
                            prenom:this.register.lastname,
                            status: '3',
                            telephone:this.register.phone,
                            websiteid: this.VUE_APP_SITE_ID === "1" ? "1" : "2"
                    }
                })
                .then((response) => {
                    if(response.data.etat){

                        Vue.axios.get('https://api.espace-jantes.com/loginUser', {
                            params: {
                                mail: this.register.email,
                                password: cryptedPassword,
                                websiteid: this.VUE_APP_SITE_ID === "1" ? "1" : "2"
                            }
                        })
                        .then((response) => {
                            if(response.data.etat  === true){
                                let userId = response.data.data.id
                                let token = response.data.token
                                let isRevendeur = false
                                let waitingRevendeur = false

                                response.data.data.status === 2 ? isRevendeur = true : isRevendeur = false
                                response.data.data.status === 4 ? waitingRevendeur = true : waitingRevendeur = false

                                this.$store.dispatch('auth/AUTH_REQUEST', { userId, token, isRevendeur, waitingRevendeur }).then(() => {
                                    this.$emit('connect', true)
                                })

                                return true
                            }else if(response.data.etat === false) {

                                this.errorMsg_login = response.data.message
                                this.error_login = true
                                this.isSubmitted_register = false
                                // console.log("La connexion à échouer ")
                                // localStorage.removeItem('user-token')
                                ls.remove('user-token')
                                return false
                            }
                        })

                    return true
                    }
                else if(response.data.etat === false)
                        this.errorMsg_register = response.data.message
                        this.error_register = true
                        this.isSubmitted_register = false
                        // console.log("L'inscription à échouer ")
                    return false
                })
            },
            submit_forgot () {
                this.$v.forgotten_email.$touch();
                this.error_forgot = false
                this.isSubmitted_forgot = true

                if (this.$v.forgotten_email.$invalid) {
                    this.isSubmitted_forgot = false
                    return false
                }
                this.forgotten_email = this.$v.forgotten_email.$model
                Vue.axios.put('https://api.espace-jantes.com/mdpOublie', {
                    params: {
                        mail: this.forgotten_email,
                        websiteid: this.VUE_APP_SITE_ID === "1" ? "1" : "2"
                    }
                })
                    .then((response) => {
                        if(response.data.etat){
                            this.forgottenPassw = false
                            this.passwReseted = true
                            return true
                        }else if (response.data.etat === false) {

                            this.errorMsg_forgot = response.data.message
                            this.error_forgot = true
                            this.isSubmitted_forgot = false
                            return false
                        }
                    })

            },

        },
    }
</script>

<style scoped>
    .background{
        background-size: contain;
        background-repeat: repeat;
    }
    .dark--text-lg /deep/ label {
        font-size: 13px;
        color: black;
    }
    .dark--text-xl /deep/ label {
        font-size: 16px;
        color: black;
    }
    .bulle--padding-top-xl {
        padding-top: 17%;
    }
    .bulle--padding-bottom-xl {
        padding-bottom: 11%;
    }
</style>
