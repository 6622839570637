<template>
<!--    <v-app   fluid  style="background-color: yellow"  >-->
        <v-container fluid class="bc_white" >
            <v-col fluid offset-lg="1" lg="10" class="bc_white">
                <v-row>
                    <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="12" xl="2" lg="3">

                        <v-row  v-for="(menu, index) in menus" :key="menu.name">
                            <p v-on:click="clickHandler(index)"  class="font-weight-bold mb-2 pointer" :class="{'tc_blue': index === activeItem && VUE_APP_SITE_ID === '1', 'tc_darkOrange': index === activeItem && VUE_APP_SITE_ID === '2', 'title':$vuetify.breakpoint.xl, 'subtitle-2':$vuetify.breakpoint.lg}" >{{menu.title}}</p>
                        </v-row>

                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.mdAndDown" cols="12" xl="2" lg="3">
                        <v-row>
                            <v-col cols="6" sm="4" md="3" v-for="(menu, index) in menus" :key="menu.name" class="py-0">
                                <p v-on:click="clickHandler(index)"  class="font-weight-bold mb-2 pointer" :class="{'tc_blue': index === activeItem && VUE_APP_SITE_ID === '1', 'tc_darkOrange': index === activeItem && VUE_APP_SITE_ID === '2', 'title':$vuetify.breakpoint.xl, 'subtitle-2':$vuetify.breakpoint.lg}" >{{menu.title}}</p>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col xl="5" lg="6" v-if="activeItem === 0" class="subtitle-2">
                        <p>Bonjour,</p>
                        <p>À partir du tableau de bord de votre compte, vous pouvez visualiser vos commandes récentes,
                            gérer vos adresses de livraison et de facturation ainsi que de changer votre mot de passe et les détails de votre compte. </p>
                        <p v-if="waitingRevendeur === true && VUE_APP_SITE_ID === '1'" class="font-weight-bold">Votre demande pour devenir un revendeur Espace-jantes est en cours de traitement. </p>
                        <p v-if="waitingRevendeur === true && VUE_APP_SITE_ID === '2'" class="font-weight-bold">Votre demande pour devenir un revendeur Distri-jantes est en cours de traitement. </p>
                    </v-col>
                    <v-col xl="8" lg="8" v-if="activeItem === 1">

                        <template>
                            <v-card >
                                <v-card-text>
                                    <v-row v-if="$vuetify.breakpoint.mdAndUp">
                                        <v-expansion-panels>
                                            <v-expansion-panel>
                                                <v-expansion-panel-header class="pa-3 toHide">
                                                    <v-row v-if="$vuetify.breakpoint.mdAndUp" class="pa-3 font-weight-medium">
                                                        <v-col cols="3" >
                                                            N°Commande
                                                        </v-col>
                                                        <v-col cols="4" >
                                                            Statut
                                                        </v-col>
                                                        <v-col cols="3">
                                                            Montant
                                                        </v-col>
                                                        <v-col cols="2">
                                                            Date
                                                        </v-col>

                                                    </v-row>
                                                </v-expansion-panel-header>
                                            </v-expansion-panel>
                                        </v-expansion-panels>
                                    </v-row>
                                    <template v-if="$vuetify.breakpoint.smAndDown">
                                        <v-simple-table>
                                            <thead>
                                            <tr style="width: 100%">
                                                <th>Date</th>
                                                <th>Montant</th>
                                                <th>Statut</th>
                                                <th>N°Commande</th>
                                            </tr>
                                            </thead>

                                        </v-simple-table>
                                    </template>
                                </v-card-text>
                            </v-card>
                            <v-expansion-panels focusable hover>
                                <v-expansion-panel
                                    v-for="(item,index) in InfoUserListeCommande"
                                    :key="index+'panel'"
                                    @click="getDetailsOneCommande(item.id_commande)"

                                >

                                    <v-expansion-panel-header v-if="$vuetify.breakpoint.mdAndUp && item.statut !== 8" class="pa-3" style="overflow-x:auto; white-space: nowrap;">
                                        <v-col cols="3">
                                            {{InfoUserListeCommande[index].numero}}
                                        </v-col>
                                        <v-col cols="4">
                                          {{GetStatut(item.statut)}}
                                        </v-col>
                                        <v-col cols="3">
                                            {{ (item.montant + 9.90 ).toFixed(2)}}€
                                        </v-col>
                                        <v-col cols="2">
                                            {{ FormatedDate(item.date_creation) }}
                                        </v-col>

                                    </v-expansion-panel-header>
                                    <v-expansion-panel-header v-if="$vuetify.breakpoint.smAndDown && item.statut !== 8" style="overflow-x:auto; white-space: nowrap;">


                                        <template v-if="$vuetify.breakpoint.smAndDown" >
                                            <v-simple-table>
                                                <thead>
                                                <tr style="width: 100%">
                                                    <th>{{ FormatedDate(item.date_creation) }}</th>
                                                    <th>{{ (item.montant + 9.90 ).toFixed(2)}}€</th>
                                                    <th>{{GetStatut(item.statut)}}</th>
                                                    <th>{{InfoUserListeCommande[index].numero}}</th>
                                                </tr>
                                                </thead>

                                            </v-simple-table>
                                        </template>
                                    </v-expansion-panel-header>
                                    <v-expansion-panel-content>


                                        <template style="overflow-x:auto; white-space: nowrap;">
                                            <v-simple-table>
                                                <thead>
                                                <tr style="width: 100%">
                                                    <th>Type</th>
                                                    <th>References</th>
                                                    <th>Marque</th>
                                                    <th>Modele</th>
                                                    <th>Couleur</th>
                                                    <th>Diametre</th>
                                                    <th>Entraxe</th>
                                                    <th>Alesage</th>
                                                    <th>Largeur</th>
                                                    <th>HBV</th>
                                                    <th>RunFlat</th>
                                                </tr>
                                                </thead>
                                                <tbody>

                                                <tr v-for="(objetData, index) in objetData" :key="index">
<!--                                                    <td>{{ JSON.parse(objetData.descriptif).type === 1 ? 'Jantes' : JSON.parse(objetData.descriptif).type === 2 ? 'Pneus' : 'Accessoires' }} </td>-->
                                                    <td>{{ objetData.type }} </td>
                                                    <td>{{ objetData.reference }} </td>
                                                    <td> {{ objetData.marque}} </td>
                                                    <td> {{ objetData.modele}} </td>
                                                    <td> {{ JSON.parse(objetData.descriptif).couleur ? JSON.parse(objetData.descriptif).couleur : '-' }} </td>
                                                    <td> {{ JSON.parse(objetData.descriptif).diametre }} </td>
                                                    <td> {{ JSON.parse(objetData.descriptif).entraxe ? JSON.parse(objetData.descriptif).entraxe : '-' }} </td>
                                                    <td> {{ JSON.parse(objetData.descriptif).offset ? JSON.parse(objetData.descriptif).offset : '-' }} </td>
                                                    <td> {{ JSON.parse(objetData.descriptif).largeur ? JSON.parse(objetData.descriptif).largeur : JSON.parse(objetData.descriptif).width ? JSON.parse(objetData.descriptif).width : '-'}} </td>
                                                    <td> {{ JSON.parse(objetData.descriptif).HBV ? JSON.parse(objetData.descriptif).HBV : '-' }} </td>
                                                    <td> {{ JSON.parse(objetData.descriptif).runFlat === 0 ? 'Non' : JSON.parse(objetData.descriptif).runFlat === 1 ? 'Oui' : '-' }} </td>

                                                </tr>
                                                </tbody>
                                            </v-simple-table>
                                        </template>



                                    </v-expansion-panel-content>
                                </v-expansion-panel>
                            </v-expansion-panels>
                            <!--                            <tr v-for="objetData in objetData" :key="objetData.id">&ndash;&gt;-->
                            <!--                                <td>{{ objetData.reference }} </td>-->
                            <!--                                <td> {{ objetData.marque}} </td>-->
                            <!--                                <td> {{ objetData.modele}} </td>-->
                            <!--                                <td> {{ JSON.parse(objetData.descriptif).couleur ? JSON.parse(objetData.descriptif).couleur : '-' }} </td>-->
                            <!--                                <td> {{ JSON.parse(objetData.descriptif).diametre }} </td>-->
                            <!--                                <td> {{ JSON.parse(objetData.descriptif).entraxe ? JSON.parse(objetData.descriptif).entraxe : '-' }} </td>-->
                            <!--                                <td> {{ JSON.parse(objetData.descriptif).offset ? JSON.parse(objetData.descriptif).offset : '-' }} </td>-->
                            <!--                                <td> {{ JSON.parse(objetData.descriptif).largeur ? JSON.parse(objetData.descriptif).largeur : JSON.parse(objetData.descriptif).width ? JSON.parse(objetData.descriptif).width : '-'}} </td>-->
                            <!--                                <td> {{ JSON.parse(objetData.descriptif).HBV ? JSON.parse(objetData.descriptif).HBV : '-' }} </td>-->
                            <!--                                <td> {{ JSON.parse(objetData.descriptif).runFlat === 0 ? 'Non' : JSON.parse(objetData.descriptif).runFlat === 1 ? 'Oui' : '-' }} </td>-->

                            <!--                            </tr>-->
                        </template>






                    </v-col>
                    <v-col xl="5" v-if="activeItem === 2">
                        <v-simple-table>
                            <template v-slot:default>
                            <thead>
                                <tr>
                                <th class="text-left">N° Commande </th>
                                <th class="text-left"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in InfoUserListeCommande" :key="item.numero">
                                    <td v-if="item.statut !== 8">{{ item.numero }}</td>
                                    <td v-if="dlFacure[index] !== true && item.statut !== 8">
                                        <v-btn v-if="VUE_APP_SITE_ID === '1'" @click='ExporterCommande(item.id_commande, index)'> Telecharger </v-btn>
                                        <v-btn v-if="VUE_APP_SITE_ID === '2'" color="#FC5C1F" dark @click='ExporterCommande(item.id_commande, index)'> Telecharger </v-btn>
                                    </td>
                                    <td v-else-if="item.statut !== 8">
                                        <v-btn v-if="VUE_APP_SITE_ID === '1'" @click='ExporterCommande(item.id_commande)' loading> Telecharger </v-btn>
                                        <v-btn v-if="VUE_APP_SITE_ID === '2'" color="#FC5C1F" dark @click='ExporterCommande(item.id_commande)' loading> Telecharger </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                            </template>
                        </v-simple-table>
                    </v-col>
                    <v-col xl="8" v-if="activeItem === 3">
                         <v-col class="border-top">
                             ADRESSE DE FACTURATION  
                             <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <v-row>
                                        <v-col lg="5" cols="12" class="pb-0">
                                            <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Numéro de voie et nom de la rue <span :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">*</span></p>
                                            <v-text-field
                                                    dense
                                                    outlined
                                                    background-color="white"
                                                    v-model="InfoUserAdresseFact.adresse"
                                                    required
                                                    :error-messages="InfoUserFactAdresseErr"
                                                    @input="$v.InfoUserAdresseFact.adresse.$touch()"
                                                    @blur="$v.InfoUserAdresseFact.adresse.$touch()"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Bâtiment, appartement, lot,... <span class="font-weight-regular">(facultatif)</span></p>
                                            <v-text-field
                                                    dense
                                                    outlined
                                                    background-color="white"
                                                    v-model="InfoUserAdresseFact.complement_adresse"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Ville <span :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">*</span></p>
                                            <v-text-field
                                                    dense
                                                    outlined
                                                    background-color="white"
                                                    v-model="InfoUserAdresseFact.ville"
                                                    :error-messages="InfoUserFactVilleErr"
                                                    @input="$v.InfoUserAdresseFact.ville.$touch()"
                                                    @blur="$v.InfoUserAdresseFact.ville.$touch()"

                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Code postal <span :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">*</span></p>
                                            <v-text-field
                                                dense
                                                outlined
                                                background-color="white"
                                                v-model="InfoUserAdresseFact.code_postal"
                                                required
                                                :error-messages="InfoUserFactPostalCodeErr"
                                                @input="$v.InfoUserAdresseFact.code_postal.$touch()"
                                                @blur="$v.InfoUserAdresseFact.code_postal.$touch()"
                                            />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="py-0">
                                            <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Pays <span :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">*</span></p>

                                            <v-text-field
                                                    outlined
                                                    dense
                                                    background-color="white"
                                                    required
                                                    v-model=InfoUserAdresseFact.pays
                                                    :error-messages="InfoUserFactPaysErr"
                                                    @input="$v.InfoUserAdresseFact.pays.$touch()"
                                                    @blur="$v.InfoUserAdresseFact.pays.$touch()"
                                            />
                                        </v-col>
                                    </v-row>

                                    <v-checkbox v-model="BooladdressLivr"  class="colored" label="EXPÉDIER À UNE ADRESSE DIFFÉRENTE ?"/>
                                        </v-col>
                                <v-col offset-lg="1" lg="5" cols="12" :class="{'pt-0': $vuetify.breakpoint.smAndDown}">
                                    <v-row>
                                                <v-col class="py-0">
                                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Nom <span :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">*</span></p>
                                                    
                                                     <v-text-field
                                                    dense
                                                    outlined
                                                    background-color="white"
                                                    v-model=InfoUserAdresseFact.nom
                                                    required
                                                    :error-messages="InfoUserFactNomErr"
                                                    @input="$v.InfoUserAdresseFact.nom.$touch()"
                                                    @blur="$v.InfoUserAdresseFact.nom.$touch()"
                                                    />
                                                </v-col>
                                                <v-col class="py-0">
                                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Prénom <span :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">*</span></p>
                                                    <v-text-field
                                                            dense
                                                            outlined
                                                            background-color="white"
                                                            required
                                                            v-model=InfoUserAdresseFact.prenom
                                                            :error-messages="InfoUserFactPrenomErr"
                                                            @input="$v.InfoUserAdresseFact.prenom.$touch()"
                                                            @blur="$v.InfoUserAdresseFact.prenom.$touch()"
                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col class="py-0">
                                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Nom de l'entreprise <span class="font-weight-regular">(facultatif)</span></p>
                                                    <v-text-field
                                                            dense
                                                            outlined
                                                            background-color="white"
                                                            v-model=InfoUserAdresseFact.societe
                                                    />
                                                </v-col>
                                            </v-row>
                                </v-col>
                                    </v-row>
                                </v-col>
                             </v-row>
                             <v-btn color="primary" v-if="!BooladdressLivr && !isSubmitted_updateFact && VUE_APP_SITE_ID === '1'" @click='UpdateAdresse'> Mettre à jour </v-btn>
                             <v-btn loading color="primary" v-if="!BooladdressLivr && isSubmitted_updateFact && VUE_APP_SITE_ID === '1'" @click='UpdateAdresse'> Mettre à jour </v-btn>
                             <v-btn color="#FC5C1F" dark v-if="!BooladdressLivr && !isSubmitted_updateFact && VUE_APP_SITE_ID === '2'" @click='UpdateAdresse'> Mettre à jour </v-btn>
                             <v-btn loading color="#FC5C1F" dark v-if="!BooladdressLivr && isSubmitted_updateFact && VUE_APP_SITE_ID === '2'" @click='UpdateAdresse'> Mettre à jour </v-btn>

                             <v-row>
                                 <span v-if="error_updateFact" class="mt-5 ml-4" style="color:red">{{ errorMsg_updateFact }}</span>
                             </v-row>

                             <v-content v-if="BooladdressLivr">
                                 ADRESSE DE LIVRAISON
                                 <v-row>
                                     <v-col lg="11" class="tc_grey pt-0">
                                         <v-row>
                                             <v-col lg="5" cols="12" class="pb-0">
                                                 <v-row>
                                                     <v-col class="py-0">
                                                         <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Numéro de voie et nom de la rue <span :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">*</span></p>
                                                         <v-text-field
                                                             dense
                                                             outlined
                                                             background-color="white"
                                                             v-model="InfoUserAdresseLivr.adresse"
                                                             required
                                                             :error-messages="InfoUserLivrAdresseErr"
                                                             @input="$v.InfoUserAdresseLivr.adresse.$touch()"
                                                             @blur="$v.InfoUserAdresseLivr.adresse.$touch()"
                                                         />
                                                     </v-col>
                                                 </v-row>
                                                 <v-row>
                                                     <v-col class="py-0">
                                                         <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Bâtiment, appartement, lot,... <span class="font-weight-regular">(facultatif)</span></p>
                                                         <v-text-field
                                                             dense
                                                             outlined
                                                             background-color="white"
                                                             v-model="InfoUserAdresseLivr.complement_adresse"

                                                         />
                                                     </v-col>
                                                 </v-row>
                                                 <v-row>
                                                     <v-col class="py-0">
                                                         <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Ville <span :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">*</span></p>
                                                         <v-text-field
                                                             dense
                                                             outlined
                                                             background-color="white"
                                                             v-model="InfoUserAdresseLivr.ville"
                                                             :error-messages="InfoUserLivrVilleErr"
                                                             @input="$v.InfoUserAdresseLivr.ville.$touch()"
                                                             @blur="$v.InfoUserAdresseLivr.ville.$touch()"
                                                         />
                                                     </v-col>
                                                 </v-row>
                                                 <v-row>
                                                     <v-col class="py-0">
                                                         <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Code postal <span :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">*</span></p>
                                                         <v-text-field
                                                             dense
                                                             outlined
                                                             background-color="white"
                                                             v-model="InfoUserAdresseLivr.code_postal"
                                                             required
                                                             :error-messages="InfoUserLivrPostalCodeErr"
                                                             @input="$v.InfoUserAdresseLivr.code_postal.$touch()"
                                                             @blur="$v.InfoUserAdresseLivr.code_postal.$touch()"
                                                         />
                                                     </v-col>
                                                 </v-row>
                                                 <v-row>
                                                     <v-col class="py-0">
                                                         <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Pays <span :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">*</span></p>

                                                         <v-text-field
                                                             outlined
                                                             dense
                                                             background-color="white"
                                                             required
                                                             v-model=InfoUserAdresseLivr.pays
                                                             :error-messages="InfoUserLivrPaysErr"
                                                             @input="$v.InfoUserAdresseLivr.pays.$touch()"
                                                             @blur="$v.InfoUserAdresseLivr.pays.$touch()"
                                                         />
                                                     </v-col>
                                                 </v-row>

                                             </v-col>
                                             <v-col offset-lg="1" lg="5" cols="12" :class="{'pt-0': $vuetify.breakpoint.smAndDown}">
                                                 <v-row>
                                                     <v-col class="py-0">
                                                         <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Prénom <span :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">*</span></p>

                                                         <v-text-field
                                                             dense
                                                             outlined
                                                             background-color="white"
                                                             v-model=InfoUserAdresseLivr.nom
                                                             :error-messages="InfoUserLivrNomErr"
                                                             @input="$v.InfoUserAdresseLivr.nom.$touch()"
                                                             @blur="$v.InfoUserAdresseLivr.nom.$touch()"
                                                         />
                                                     </v-col>
                                                     <v-col class="py-0">
                                                         <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Nom <span :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">*</span></p>
                                                         <v-text-field
                                                             dense
                                                             outlined
                                                             background-color="white"
                                                             required
                                                             v-model=InfoUserAdresseLivr.prenom
                                                             :error-messages="InfoUserLivrPrenomErr"
                                                             @input="$v.InfoUserAdresseLivr.prenom.$touch()"
                                                             @blur="$v.InfoUserAdresseLivr.prenom.$touch()"
                                                         />
                                                     </v-col>
                                                 </v-row>
                                                 <v-row>
                                                     <v-col class="py-0">
                                                         <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Nom de l'entreprise <span class="font-weight-regular">(facultatif)</span></p>
                                                         <v-text-field
                                                             dense
                                                             outlined
                                                             background-color="white"
                                                             v-model=InfoUserAdresseLivr.societe
                                                         />
                                                     </v-col>
                                                 </v-row>
                                             </v-col>
                                         </v-row>
                                     </v-col>
                                 </v-row>
                                 <v-btn color="primary" v-if=" VUE_APP_SITE_ID === '1'" @click='UpdateAdresse'> Mettre à jour </v-btn>
                                 <v-btn color="#FC5C1F" dark v-if=" VUE_APP_SITE_ID === '2'" @click='UpdateAdresse'> Mettre à jour </v-btn>
                             </v-content>
                         </v-col>
                    </v-col>
                    <v-col xl="8" v-if="activeItem === 4">
                        <p>Vos informations</p>
                        <v-row>
                                <v-col lg="11" class="tc_grey pt-0">
                                    <v-row>
                                        <v-col lg="5" cols="12" class="pb-0">
                                            <v-row>
                                                <v-col class="py-0">
                                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Prénom <span :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">*</span></p>
                                                    
                                                     <v-text-field
                                                            dense
                                                            outlined
                                                            background-color="white"
                                                            v-model=infoUser.prenom
                                                            @input="$v.infoUser.prenom.$touch()"
                                                            @blur="$v.infoUser.prenom.$touch()"
                                                            :error-messages="firstnameChangeErrors"

                                                     />
                                                </v-col>
                                                <v-col class="py-0">
                                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Nom <span :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">*</span></p>
                                                    <v-text-field
                                                            dense
                                                            outlined
                                                            background-color="white"
                                                            required
                                                            v-model=infoUser.nom
                                                            @input="$v.infoUser.nom.$touch()"
                                                            @blur="$v.infoUser.nom.$touch()"
                                                            :error-messages="lastnameChangeErrors"

                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col class="py-0">
                                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Nom de l'entreprise <span class="font-weight-regular">(facultatif)</span></p>
                                                    <v-text-field
                                                            dense
                                                            outlined
                                                            background-color="white"
                                                            v-model=infoUser.societe
                                                            @input="$v.infoUser.societe.$touch()"
                                                            @blur="$v.infoUser.societe.$touch()"

                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col lg="6" class="py-0">
                                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Téléphone <span class="font-weight-regular">(facultatif)</span></p>
                                                    <v-text-field
                                                            dense
                                                            outlined
                                                            background-color="white"
                                                            required
                                                            v-model=infoUser.telephone
                                                            @input="$v.infoUser.telephone.$touch()"
                                                            @blur="$v.infoUser.telephone.$touch()"

                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col class="py-0">
                                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Adresse Email <span :class="{'tc_turquoise' : VUE_APP_SITE_ID === '1', 'tc_orange' : VUE_APP_SITE_ID ==='2'}">*</span></p>

                                                    <v-text-field
                                                            outlined
                                                            dense
                                                            background-color="white"
                                                            required
                                                            v-model=infoUser.mail
                                                            @input="$v.infoUser.mail.$touch()"
                                                            @blur="$v.infoUser.mail.$touch()"
                                                            :error-messages="emailChangeErrors"

                                                    />
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col class="py-0">
                                                    <p class="mb-0 font-weight-bold " :class="{'caption':$vuetify.breakpoint.lg, 'body-1':$vuetify.breakpoint.xl}">Mot de passe</p>

                                                    <v-text-field
                                                        onClick="this.setSelectionRange(0, this.value.length)"
                                                            outlined
                                                            dense
                                                            background-color="white"
                                                            required
                                                            v-model=infoUser.password
                                                            :error-messages="passwordChangeErrors"
                                                            :type="'password'"
                                                            @input="$v.infoUser.password.$touch()"
                                                            @blur="$v.infoUser.password.$touch()"
                                                    />
                                                </v-col>

                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-col>
                             </v-row>
                             <v-btn v-if="!updateLoading && VUE_APP_SITE_ID === '1'" color="primary" @click='UpdateInfoUser'> Mettre à jour </v-btn>
                             <v-btn v-else-if="updateLoading && VUE_APP_SITE_ID === '1'" color="primary" loading @click='UpdateInfoUser'> Mettre à jour </v-btn>
                             <v-btn v-if="!updateLoading && VUE_APP_SITE_ID === '2'" color="#FC5C1F" dark @click='UpdateInfoUser'> Mettre à jour </v-btn>
                             <v-btn v-else-if="updateLoading && VUE_APP_SITE_ID === '2'" color="#FC5C1F" dark loading @click='UpdateInfoUser'> Mettre à jour </v-btn>

                        <p v-if="updateSuccess === true">Vos informations ont été modifiées</p>
                    </v-col>
                    <v-col xl="5" v-if="activeItem === 5">
                        <p>Vous allez être déconnecté, êtes vous sûr ?</p>
                        <v-btn class="font-weight-bold " v-if="VUE_APP_SITE_ID === '1'" dark color="#1840DA" @click="disconnect">Se déconnecter</v-btn>
                        <v-btn class="font-weight-bold " v-if=" VUE_APP_SITE_ID === '2'" dark color="#FC5C1F" @click="disconnect">Se déconnecter</v-btn>
                    </v-col>
                </v-row>
            </v-col>

        </v-container>
<!--    </v-app>-->

</template>

<script>

    import axios from 'axios'
    import VueAxios from 'vue-axios'
    import Vue from 'vue'
    import JSPDF from 'jspdf'

    import {mapState} from 'vuex'
    import CryptoJS from "crypto-js";
    const { validationMixin } = require('vuelidate')
    const { required, minLength, maxLength, alpha, numeric, email } = require('vuelidate/lib/validators')

    Vue.use(VueAxios, axios)

    export default {
        name: "Connected.vue",
        mixins: [validationMixin],

        validations: {

            InfoUserAdresseFact: {
                prenom: {required, alpha},
                nom: {required, alpha},
                pays: {required, alpha},
                adresse: {required},
                code_postal: {required, numeric, minLength: minLength(5), maxLength: maxLength(5)},
                ville: {required, alpha},
                telephone: {required, numeric, minLength: minLength(10), maxLength: maxLength(10)},

            },
            InfoUserAdresseLivr: {
                prenom: {required},
                nom: {required},
                pays: {required, alpha},
                adresse: {required},
                code_postal: {required, numeric, minLength: minLength(5), maxLength: maxLength(5)},
                ville: {required, alpha},
                telephone: {required, numeric, minLength: minLength(10), maxLength: maxLength(10)},

            },
            infos:{
                mois: {required},
                annee: {required},
                valves: {required},
            },
            infoUser:{
                prenom: {required},
                nom: {required},
                mail:{required, email},
                password: {
                    minLength: minLength(8)
                },
            },



        },
        data: () => ({
            updateLoading:false,
            updateSuccess:false,
            active:true,
            menus: [
                { title: 'Tableau de bord'},
                { title: 'Commandes'},
                { title: 'Factures'},
                { title: 'Adresses'},
                { title: 'Détails du compte'},
                { title: 'Déconnexion'},
            ],
            expanded2: [],
            isLoading:true,
            objetData:{},
            singleExpand: true,
            headersDataTableCommande: [
                {
                text: 'N° Commande',
                align: 'start',
                sortable: false,
                value: 'numero',
                },
                { text: 'Statut', value: 'statut' ,sortable: false,},
                { text: 'Montant', value: 'montant' ,sortable: false,},
                { text: 'Date', value: 'date_creation' ,sortable: false,},
                { text: '', value: 'data-table-expand' },
            ],
            activeItem: 0,
            infoUser: '',
            InfoUserAdresseFact: {},
            InfoUserAdresseLivr : {},
            InfoUserListeCommande : '',
            BooladdressLivr: false,
            isSubmitted_updateFact: false,
            error_updateFact: false,
            errorMsg_updateFact: '',
            addr_fact:{},
            addr_livr:{},
            lastOpened : null,
            passwordListner: null,
            produits:[],
            produitsTmp2:[],
            produitsTmp3:[],
            produitsAccessoires:[],
            imgFactureEJ:require("@/assets/logo/ej-logo-baseline.jpg"),
            imgFactureDJ:require("@/assets/logo/dj-logo.png"),
            dlFacure:[],
            VUE_APP_SITE_ID:null,
        }),
        metaInfo() {

            return {
                title: `Mon Compte - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com` + `  - Jantes, Pneus & Accessoires`),
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `Mon Compte - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {name: 'twitter:description', content: `Mon Compte - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes` : `Distri Jantes`) + `, le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.`},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `Mon Compte -` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {property: 'og:site_name', content: this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`},
                    {property: 'og:type', content: 'Website'},
                    {property: 'og:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},
                    {property: 'og:description', content: `Mon Compte - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    {name: 'robots', content: 'index,follow'}
                ]
            }
        },
        created () {
            var userId = this.getUserId

            Vue.axios.get('https://api.espace-jantes.com/getOneUser', {
                params: { id_user: userId },
            })
            .then((response) => {

                this.infoUser = response.data[0]
                this.passwordListner = response.data[0].password
                // this.infoUser.password = ""
                this.getAdresses()
                this.getCommandes()
            })
            .catch(error => console.log(error))

        },
        methods: {

            ExporterCommande: function (id_commande, index) {
                // this.dlFacure[index] = true
                Vue.set(this.dlFacure, index, true)

                Vue.axios.get('https://api.espace-jantes.com/getOrder', {
                    params: { id_commande: id_commande },
                }).then((response)=>{
                    var dateSplit = response.data[0].date_creation.split('T')
                    var swicthDate = dateSplit[0].split('-')
                    var heureCommande = dateSplit[1].substr(0, 5)
                    response.data[0].heureCommande = heureCommande

                    response.data[0].dateFormater = swicthDate[2] + '/' + swicthDate[1] + '/' + swicthDate[0]
                    response.data[0].labelTypePaiemeent = this.GetTypePaiement(response.data[0].type_paiement)
                    response.data[0].montant = (parseFloat(response.data[0].montant)).toFixed(2)
                    response.data[0].adresse_facturation = JSON.parse(response.data[0].adresse_facturation)
                    response.data[0].adresse_livraison = JSON.parse(response.data[0].adresse_livraison)
                    response.data[0].valves = (response.data[0].hadValves === 1) ? 'oui' : 'non'
                    if (response.data[0].status === 1) {
                        this.transfertDataLayer = true
                    } else {
                        this.transfertDataLayer = false
                    }
                    this.infoCommande = response.data[0]
                    this.infoCommande.vehicule = JSON.parse(response.data[0].voitureClient)
                    this.InformationNumeroCommande = 'Information commande N° ' + response.data[0].numero
                    this.userId = response.data[0].id_client
                    this.infoCommande.numero_colis = (this.infoCommande.numero_colis === 'null') ? '' : this.infoCommande.numero_colis
                    this.infoCommande.url_suivi_colis = (this.infoCommande.url_suivi_colis === 'null') ? '' : this.infoCommande.url_suivi_colis
                    this.$localStorage.set('idUser', response.data[0].id_client)
                    Vue.axios.get('https://api.espace-jantes.com/getDetailsOrder', {
                        params: { id_commande: id_commande },
                    })
                        .then((response) => {

                            this.produits=[]
                            this.produitsTmp2=[]
                            this.produitsTmp3=[]
                            this.produitsAccessoires=[]

                            response.data.forEach(element => {
                                element.descriptif = JSON.parse(element.descriptif)
                                if (element.reference !== 'EJ-accessoires') {
                                    if (element.descriptif.type === 2) {
                                        // si Pneu
                                        element.descriptif.largeur = element.descriptif.HBV + '/' + element.descriptif.width
                                        element.descriptif.offset = element.descriptif.indiceDeCharge
                                        element.descriptif.alesage = element.descriptif.indiceDeVitesse
                                        element.descriptif.entraxe = element.descriptif.specificite
                                        element.descriptif.couleur = element.descriptif.saison
                                        if (element.descriptif.couleur === 'ete') element.descriptif.couleur = 'été'
                                    }
                                    this.produitsTmp2.push(element)
                                    this.produitsTmp3.push(element)
                                } else {
                                    this.produitsAccessoires.push(element)
                                }
                            })
                            this.produitsTmp2.forEach((elementProduit, index) => {
                                if (elementProduit.descriptif.type === 1) {
                                    this.produitsTmp3.forEach((elementProduit2, index2) => {
                                        if (elementProduit.id !== elementProduit2.id && JSON.stringify(elementProduit.descriptif) === JSON.stringify(elementProduit2.descriptif)) {
                                            elementProduit2.quantite = 4
                                            elementProduit2.prixFinalAffichage = elementProduit2.prix * 2
                                            this.produits.push(elementProduit2)
                                            this.produitsTmp2.splice(index, 1)
                                            this.produitsTmp3.splice(index2, 1)
                                        } else if (elementProduit.id !== elementProduit2.id && elementProduit.descriptif.type === elementProduit2.descriptif.type) {
                                            elementProduit2.prixFinalAffichage = elementProduit2.prix
                                            this.produits.push(elementProduit2)
                                        }
                                    })
                                }
                                if (elementProduit.descriptif.type === 2) {
                                    this.produitsTmp3.forEach((elementProduit2, index2) => {
                                        if (elementProduit.id !== elementProduit2.id && (elementProduit.descriptif.width) === (elementProduit2.descriptif.width) && (elementProduit.descriptif.HBV) === (elementProduit2.descriptif.HBV)) {
                                            elementProduit2.quantite = 4
                                            elementProduit2.prixFinalAffichage = elementProduit2.prix * 2
                                            this.produits.push(elementProduit2)
                                            this.produitsTmp2.splice(index, 1)
                                            this.produitsTmp3.splice(index2, 1)
                                        } else if (elementProduit.id !== elementProduit2.id && elementProduit.descriptif.type === elementProduit2.descriptif.type) {
                                            elementProduit2.prixFinalAffichage = elementProduit2.prix
                                            this.produits.push(elementProduit2)
                                        }
                                    })
                                }
                            })

                            setTimeout(()=>{
                                const doc = new JSPDF()
                                var img = new Image()
                                if( this.VUE_APP_SITE_ID === '1'){
                                    img.src = this.imgFactureEJ
                                    doc.addImage(img, 'JPEG', 0, 0, 100, 40)
                                }
                                else if (this.VUE_APP_SITE_ID === '2'){
                                    img.src = this.imgFactureDJ
                                    doc.addImage(img, 'JPEG', 5, 5, 100, 28)
                                }

                                var texteInformationEJ = 'Pour éviter tout litige de transport, il est impératif de vérifier le bon état des produits ainsi que leurs contenus en présence du chauffeur.'
                                texteInformationEJ += ' Toute réclamation devra être notifiée sur le bordereau de transport.Le colis devra être refusé et remis au transporteur le jour de la livraison.'
                                texteInformationEJ += ' Aucun retour ne sera accepté sans vérification du colis lors de la réception et au-dela d\'un délai de 24H.'
                                texteInformationEJ += ' La législation des transports prévoit que toute marchandise voyage aux risques et périls du destinataire.'
                                texteInformationEJ += ' (Article 105 et 106 du code du commerce). '
                                doc.setFontSize(20)
                                // doc.addImage(logo, 'JPEG', 15, 40, 180, 160)
                                doc.text('Facture', 10, 44, null, null)
                                doc.setFontSize(12)
                                var addMarge = 0
                                if (this.infoCommande.adresse_facturation.societe !== '' && this.infoCommande.adresse_facturation.societe !== null && this.infoCommande.adresse_facturation.societe !== undefined) {
                                    doc.text(this.infoCommande.adresse_facturation.societe, 10, 55, null, null)
                                    addMarge = 6
                                }
                                doc.text(this.infoCommande.adresse_facturation.nom + ' ' + this.infoCommande.adresse_facturation.prenom, 10, (55 + addMarge), null, null)
                                doc.text(this.infoCommande.adresse_facturation.adresse, 10, (61 + addMarge), null, null)
                                if (this.infoCommande.adresse_facturation.complement_adresse !== '' && this.infoCommande.adresse_facturation.complement_adresse !== null && this.infoCommande.adresse_facturation.complement_adresse !== undefined) {
                                    doc.text(this.infoCommande.adresse_facturation.complement_adresse, 10, (67 + addMarge), null, null)
                                }
                                doc.text(this.infoCommande.adresse_facturation.code_postal + ' ' + this.infoCommande.adresse_facturation.ville, 10, (72 + addMarge), null, null)

                                if(this.VUE_APP_SITE_ID === '1'){
                                    doc.text('Espace - Jantes SARL', 110, 17, null, null)
                                    doc.text('23 Rue Benjamin Franklin,', 110, 23, null, null)
                                    doc.text('84120 Pertuis', 110, 29, null, null)
                                    doc.setFontSize(9)
                                    doc.text('Siret: 53043737500039', 110, 35, null, null)
                                    doc.text('RCS: Avignon', 110, 39, null, null)
                                    doc.text('APE : 4532Z', 110, 43, null, null)
                                    doc.text('N° TVA intracommunautaire: FR 74530437375', 110, 47, null, null)
                                }
                                else if(this.VUE_APP_SITE_ID === '2') {

                                    doc.text('Distri - Jantes SAS', 110, 17, null, null)
                                    doc.text('1175 Route d\'Avignon,', 110, 23, null, null)
                                    doc.text('13090 Aix-en-Provence', 110, 29, null, null)
                                    doc.setFontSize(9)
                                    doc.text('Siret: 87814711500017', 110, 35, null, null)
                                    doc.text('RCS: Aix en provence', 110, 39, null, null)
                                    doc.text('APE : 4791B', 110, 43, null, null)
                                    doc.text('N° TVA intracommunautaire: FR 24878147115', 110, 47, null, null)
                                }

                                doc.setFontSize(12)
                                doc.text('N° de la facture:', 110, 55, null, null)
                                doc.setFontSize(10)
                                doc.text(this.infoCommande.numero, 145, 55, null, null)
                                doc.setFontSize(12)
                                doc.text('Date de la facture:', 110, 61, null, null)
                                doc.setFontSize(10)
                                doc.text(this.infoCommande.dateFormater, 150, 61, null, null)
                                doc.setFontSize(12)
                                doc.text('Date de commande:', 110, 67, null, null)
                                doc.setFontSize(10)
                                doc.text(this.infoCommande.dateFormater, 150, 67, null, null)
                                doc.setFontSize(12)
                                doc.text('Méthode de paiement:', 110, 73, null, null)
                                doc.setFontSize(10)
                                doc.text(this.infoCommande.labelTypePaiemeent, 155, 73, null, null)
                                doc.setFontSize(11)
                                doc.setLineWidth(6.5)
                                doc.line(10, 118, 200, 118)
                                doc.setTextColor(150)
                                doc.text('Produits', 11, 120, null, null)
                                doc.text('Prix HT', 130, 120, null, null)
                                doc.text('TVA 20%', 150, 120, null, null)
                                doc.text('Prix TTC', 180, 120, null, null)
                                doc.setTextColor(0)
                                doc.setFontSize(12)
                                var hauteurInitial = 130
                                var sousTotal = 0
                                var tvaTotal = 0
                                this.produits.forEach(produit => {
                                    var textProduit
                                    var textDescriptionProduit
                                    if ((produit.descriptif.type === 1)) {
                                        textProduit = produit.marque + ' ' + produit.modele + ' ' + produit.descriptif.couleur + ' Qté: ' + produit.quantite
                                        textDescriptionProduit = produit.descriptif.largeur + 'x' + produit.descriptif.diametre + ' ' + produit.descriptif.entraxe.toString() + ' ET' + produit.descriptif.offset + ' ' + produit.descriptif.alesage
                                    } else {
                                        textProduit = produit.marque + ' ' + produit.modele + ' ' + ' Qté: ' + produit.quantite
                                        textDescriptionProduit = produit.descriptif.HBV + '/' + produit.descriptif.width + 'R' + produit.descriptif.diametre + ' '
                                        if (produit.descriptif.indiceDeCharge !== undefined && produit.descriptif.indiceDeVitesse !== undefined) {
                                            textDescriptionProduit += produit.descriptif.indiceDeCharge + ' ' + produit.descriptif.indiceDeVitesse
                                                if(produit.descriptif.specificite)
                                                    textDescriptionProduit += ' (' + produit.descriptif.specificite + ')'
                                        }
                                    }
                                    // var textProduit = produit.marque + ' ' + produit.modele + ' ' + (produit.descriptif.type === 1) ? produit.descriptif.couleur : '' + ' Qté: ' + produit.quantite
                                    doc.text(textProduit, 11, hauteurInitial, null, null)
                                    doc.text(textDescriptionProduit, 11, hauteurInitial + 7, null, null)
                                    if (produit.quantite === 4 && produit.prixFinalAffichage !== undefined) {
                                        produit.prixFinalAffichage = produit.prix * 2
                                    } else if (produit.prixFinalAffichage === 'undefined' || produit.prixFinalAffichage === undefined) {
                                        produit.prixFinalAffichage = produit.prix * 4
                                    }
                                    doc.text((parseFloat(produit.prixFinalAffichage) / parseFloat(1.20)).toFixed(2) + '€', 130, hauteurInitial, null, null)
                                    var tva = (produit.prixFinalAffichage - (parseFloat(produit.prixFinalAffichage) / parseFloat(1.20)))
                                    doc.text(tva.toFixed(2) + '€', 150, hauteurInitial, null, null)
                                    doc.text((produit.prixFinalAffichage).toFixed(2) + '€', 180, hauteurInitial, null, null)
                                    doc.setLineWidth(0.1)
                                    doc.line(10, hauteurInitial + 9, 200, hauteurInitial + 9)
                                    hauteurInitial += 18
                                    sousTotal = parseFloat(sousTotal) + parseFloat(produit.prixFinalAffichage)
                                    tvaTotal = parseFloat(tvaTotal) + parseFloat(tva)
                                })
                                this.produitsAccessoires.forEach(produit => {
                                    var textProduit = produit.marque + ' ' + produit.modele + ' Qté: ' + produit.quantite
                                    doc.text(textProduit, 11, hauteurInitial, null, null)
                                    doc.text((parseFloat(produit.prix) / parseFloat(1.20)).toFixed(2) + '€', 130, hauteurInitial, null, null)
                                    var tva = (parseFloat(produit.prix) - ((parseFloat(produit.prix) / parseFloat(1.20)).toFixed(2)))
                                    doc.text(tva.toFixed(2) + '€', 150, hauteurInitial, null, null)
                                    doc.text((produit.prix).toFixed(2) + '€', 180, hauteurInitial, null, null)
                                    doc.setLineWidth(0.1)
                                    doc.line(10, hauteurInitial + 3, 200, hauteurInitial + 3)
                                    hauteurInitial += 9
                                    sousTotal = parseFloat(sousTotal) + parseFloat(produit.prix)
                                    tvaTotal = parseFloat(tvaTotal) + parseFloat(tva)
                                })
                                doc.setFontSize(8)
                                if(this.isRevendeur){
                                    doc.text('Vous bénéficiez d\'une remise de ' + this.tauxRemise + '% en tant que revendeur (la remise a été appliquée au préalable de cette facture)', 11, 240)
                                }
                                var splitTitle = doc.splitTextToSize(texteInformationEJ, 190)
                                doc.text(splitTitle, 10, 248, null, null)
                                doc.setFontSize(12)
                                doc.line(100, 260, 200, 260)
                                doc.setTextColor(0)
                                doc.setFontSize(10)
                                doc.text('Sous-total :', 100, 265, null, null)
                                doc.text(tvaTotal.toFixed(2).toString() + '€', 150, 265, null, null)
                                doc.text(sousTotal.toFixed(2).toString() + '€', 180, 265, null, null)
                                doc.text('Expédition :', 100, 270, null, null)
                                doc.text('Livraison gratuite', 180, 270, null, null)
                                doc.text('Frais d\'emballage :', 100, 275, null, null)
                                doc.text('9.90 €', 180, 275, null, null)
                                var total = parseFloat(sousTotal) + parseFloat(9.90)
                                // if (this.infoCommande.type_paiement === 3) {
                                //   total = parseFloat(this.infoCommande.montant)
                                // }
                                doc.text('Total :', 100, 280, null, null)
                                doc.text(total.toFixed(2).toString() + '€', 180, 280, null, null)
                                doc.setFontSize(8)
                                var texteInformationEJBottom = "Pas d'escompte pour paiement anticipé, passée la date d'échéance, tout paiement différé entraine l'application d'une pénalité de 3 fois le taux d'intérêt légal.(loi N°2008-776 du 4/8/2008) ainsi qu'une indemnité forfaitaire pour frais de recouvrement de 40€uros-décret 2012-1115 du 2/10/2012."
                                var splitTitleBottom = doc.splitTextToSize(texteInformationEJBottom, 190)
                                doc.text(splitTitleBottom, 10, 287, null, null)
                                doc.setFontSize(10)
                                doc.save('Facture-' + this.infoCommande.numero + '.pdf')

                                // this.dlFacure[index] = false
                                Vue.set(this.dlFacure, index, false)


                            },1000)

                        })
                        .catch(error => console.log(error))


                })

            },
            GetTypePaiement: function (idTypePaiement) {
                var elementToReturn
                switch (idTypePaiement) {
                    case 1:
                        elementToReturn = 'Carte Bleu'
                        break
                    case 2:
                        elementToReturn = 'Paypal'
                        break
                    case 3:
                        elementToReturn = 'Cofidis 4x'
                        break
                    case 4:
                        elementToReturn = 'Virement'
                        break
                    case 5:
                        elementToReturn = 'Chèque'
                        break
                    default:
                        elementToReturn = ''
                }
                return elementToReturn
            },
            getDetailsOneCommande(id_command) {
                // this.expanded.push(command)
                this.objetData = []

                     Vue.axios.get('https://api.espace-jantes.com/getDetailsOrder', {
                        params: { id_commande: id_command },
                    })
                    .then((resp) => {
                        let response = resp.data
                        // console.log('getDetailsOneCommande',response)

                        for(let i = 0; i < response.length; i++) {

                            // console.log('response', response[i])


                            if (JSON.parse(response[i].descriptif).type === 1) {

                                if (
                                    response[i].reference === response[i + 1]?.reference &&
                                    JSON.parse(response[i].descriptif).largeur === JSON.parse(response[i + 1]?.descriptif).largeur &&
                                    JSON.parse(response[i].descriptif).offset === JSON.parse(response[i + 1]?.descriptif).offset &&
                                    JSON.parse(response[i].descriptif).diametre === JSON.parse(response[i + 1]?.descriptif).diametre &&
                                    JSON.parse(response[i].descriptif).alesage === JSON.parse(response[i + 1]?.descriptif).alesage &&
                                    JSON.parse(response[i].descriptif).entraxe === JSON.parse(response[i + 1]?.descriptif).entraxe) {

                                    response[i].type =  '4 Jantes'
                                    this.objetData.push(response[i])
                                }
                                else if (
                                    response[i - 1] &&
                                    response[i].reference !== response[i - 1]?.reference &&
                                    (JSON.parse(response[i].descriptif).largeur !== JSON.parse(response[i - 1]?.descriptif).largeur ||
                                    JSON.parse(response[i].descriptif).offset !== JSON.parse(response[i - 1]?.descriptif).offset ||
                                    JSON.parse(response[i].descriptif).diametre !== JSON.parse(response[i - 1]?.descriptif).diametre ||
                                    JSON.parse(response[i].descriptif).alesage !== JSON.parse(response[i - 1]?.descriptif).alesage ||
                                    JSON.parse(response[i].descriptif).entraxe !== JSON.parse(response[i - 1]?.descriptif).entraxe)){

                                    response[i - 1].type =  '2 Jantes'
                                    this.objetData.push(response[i - 1])
                                    response[i].type =  '2 Jantes'
                                    this.objetData.push(response[i])
                                }
                            }
                            if (JSON.parse(response[i].descriptif).type === 2) {
                                if (
                                    response[i].reference === response[i + 1]?.reference &&
                                    JSON.parse(response[i].descriptif).width === JSON.parse(response[i + 1]?.descriptif).width &&
                                    JSON.parse(response[i].descriptif).HBV === JSON.parse(response[i + 1]?.descriptif).HBV &&
                                    JSON.parse(response[i].descriptif).diametre === JSON.parse(response[i + 1]?.descriptif).diametre &&
                                    JSON.parse(response[i].descriptif).indiceDeCharge === JSON.parse(response[i + 1]?.descriptif).indiceDeCharge &&
                                    JSON.parse(response[i].descriptif).saison === JSON.parse(response[i + 1]?.descriptif).saison) {

                                    response[i].type = '4 Pneus'
                                    this.objetData.push(response[i])
                                }
                                else if (
                                    response[i - 1] &&
                                    response[i].reference === response[i - 1]?.reference &&
                                    (JSON.parse(response[i].descriptif).width !== JSON.parse(response[i - 1]?.descriptif).width ||
                                    JSON.parse(response[i].descriptif).HBV !== JSON.parse(response[i - 1]?.descriptif).HBV ||
                                    JSON.parse(response[i].descriptif).diametre !== JSON.parse(response[i - 1]?.descriptif).diametre ||
                                    JSON.parse(response[i].descriptif).indiceDeCharge !== JSON.parse(response[i - 1]?.descriptif).indiceDeCharge ||
                                    JSON.parse(response[i].descriptif).saison !== JSON.parse(response[i - 1]?.descriptif).saison)) {

                                    response[i - 1].type = '2 Pneus'
                                    this.objetData.push(response[i - 1])
                                    response[i].type = '2 Pneus'
                                    this.objetData.push(response[i])
                                }
                            }
                            if(response[i].marque === "EJ-accessoires"){
                                response[i].type = 'Accessoires'
                                this.objetData.push(response[i])
                            }
                        }
                        return this.objetData

                    })
                },
            clickHandler(idx) {
                this.activeItem = idx
            },
            disconnect:function () {
                this.$store.dispatch('auth/AUTH_LOGOUT').then(() => {
                    this.$router.push({name:'Home'})
                })
            },
            getAdresses() {
                var userId = this.infoUser.id

                Vue.axios.get('https://api.espace-jantes.com/getAdresseUser', {
                params: { id_user: userId },
                })
                .then((response) => {
                    response.data.forEach(element =>{
                        if(element.type_adresse == 1 ){
                            this.InfoUserAdresseFact = element
                        }
                        if(element.type_adresse == 2 ){
                            this.InfoUserAdresseLivr = element
                        }

                    })
                })  
            },
            getCommandes() {
                var userId = this.infoUser.id

                Vue.axios.get('https://api.espace-jantes.com/getOrdersByUser', {
                params: { id_user: userId },
                })
                .then((response) => {

                    this.InfoUserListeCommande = response.data
                }) 
            },
            GetStatut: function (idStatut) {
                var elementToReturn
                switch (idStatut) {
                case 1:
                    elementToReturn = 'En attente de paiement'
                    break
                case 2:
                    elementToReturn = 'Payé'
                    break
                case 3:
                    elementToReturn = 'Préparation'
                    break
                case 4:
                    elementToReturn = 'Pris en charge'
                    break
                case 5:
                    elementToReturn = 'Annulé'
                    break
                case 6:
                    elementToReturn = 'Supprimé'
                    break
                case 7:
                    elementToReturn = 'Remboursé'
                    break
                case 8:
                    elementToReturn = 'Pré-commande'
                    break
                default:
                    elementToReturn = ''
                }
                return elementToReturn


                // statut_commande : 1 en attente de paiement, 2 payé, 3 preparation, 4Pris en charge, 5Annulé ,6 supprimé ,7 remboursé
            },
            FormatedDate: function(date){
                if(date.indexOf("T") == -1){
                    return date
                }
                var dateSplit =  date.split('T')[0].split('-')
                return dateSplit[2] +"/"+ dateSplit[1] +"/" + dateSplit[0]
            },
            UpdateAdresse: function() {
                this.$v.InfoUserAdresseFact.$touch()
                this.isSubmitted_updateFact = true
                // console.log('this.InfoUserAdresseFact', this.InfoUserAdresseFact)
                // console.log('this.InfoUserAdresseLivr', this.InfoUserAdresseLivr)

                if(!this.BooladdressLivr) {

                    if (!this.$v.InfoUserAdresseFact.prenom.$invalid
                        && !this.$v.InfoUserAdresseFact.nom.$invalid
                        && !this.$v.InfoUserAdresseFact.pays.$invalid
                        && !this.$v.InfoUserAdresseFact.adresse.$invalid
                        && !this.$v.InfoUserAdresseFact.code_postal.$invalid
                        && !this.$v.InfoUserAdresseFact.ville.$invalid) {

                        if (this.BooladdressLivr === false) {
                            this.addr_fact = this.InfoUserAdresseFact
                            this.addr_livr = this.InfoUserAdresseFact
                        } else {
                            this.addr_fact = this.InfoUserAdresseFact
                            this.addr_livr = this.InfoUserAdresseLivr
                        }


                        Vue.axios.post('https://api.espace-jantes.com/addAdresse', {
                            params: {
                                'id_user': this.infoUser.id,
                                'addr_facturation': this.addr_fact,
                                'addr_livraison': this.addr_livr,
                            },
                        }).then((response) => {
                            if (response.data.etat) {
                                // console.log(response.data)
                                this.isSubmitted_updateFact = false
                            } else if (response.data.etat === false) {
                                this.isSubmitted_updateFact = false
                                this.error_updateFact = true
                                this.errorMsg_updateFact = response.data.message
                            }
                        })
                    } else {
                        this.isSubmitted_updateFact = false
                        this.error_updateFact = true
                    }
                }else{

                    if (!this.$v.InfoUserAdresseFact.prenom.$invalid
                        && !this.$v.InfoUserAdresseFact.nom.$invalid
                        && !this.$v.InfoUserAdresseFact.pays.$invalid
                        && !this.$v.InfoUserAdresseFact.adresse.$invalid
                        && !this.$v.InfoUserAdresseFact.code_postal.$invalid
                        && !this.$v.InfoUserAdresseFact.ville.$invalid
                        && !this.$v.InfoUserAdresseLivr.prenom.$invalid
                        && !this.$v.InfoUserAdresseLivr.nom.$invalid
                        && !this.$v.InfoUserAdresseLivr.pays.$invalid
                        && !this.$v.InfoUserAdresseLivr.adresse.$invalid
                        && !this.$v.InfoUserAdresseLivr.code_postal.$invalid
                        && !this.$v.InfoUserAdresseLivr.ville.$invalid
                    ) {


                        Vue.axios.post('https://api.espace-jantes.com/addAdresse', {
                            params: {
                                'id_user': this.infoUser.id,
                                'addr_facturation': this.InfoUserAdresseFact,
                                'addr_livraison': this.InfoUserAdresseLivr,
                            },
                        }).then((response) => {
                            if (response.data.etat) {
                                // console.log(response.data)
                                this.isSubmitted_updateFact = false
                            } else if (response.data.etat === false) {
                                this.isSubmitted_updateFact = false
                                this.error_updateFact = true
                                this.errorMsg_updateFact = response.data.message
                            }
                        })
                    } else {
                        this.isSubmitted_updateFact = false
                        this.error_updateFact = true
                    }
                }

            },
            UpdateInfoUser: function() {


                this.$v.infoUser.nom.$touch();
                this.$v.infoUser.prenom.$touch();
                this.$v.infoUser.mail.$touch();
                this.$v.infoUser.password.$touch();

                if (this.$v.infoUser.nom.$invalid || this.$v.infoUser.prenom.$invalid || this.$v.infoUser.mail.$invalid || this.$v.infoUser.password.$invalid ) {
                    this.isSubmitted_register = false
                    return false
                }
                this.updateLoading = true
                this.updateSuccess = false

                let cryptedinfoUser = CryptoJS.AES.encrypt(JSON.stringify(this.infoUser), process.env.VUE_APP_SECRET_KEY).toString()


                Vue.axios.put('https://api.espace-jantes.com/updateUser', {
                    params: {
                        'infoUser': cryptedinfoUser,
                    },
                }).then((response) => {
                        if (response.data.etat === true) {
                            if(this.infoUser.password !== this.passwordListner) {

                                Vue.axios.put('https://api.espace-jantes.com/updatePassword', {
                                    params: {
                                        'infoUser': cryptedinfoUser,
                                    },
                                }).then((response) => {
                                    if (response.data.etat === true) {
                                        // console.log('UPADATE OK password')
                                        this.updateSuccess = true
                                        this.updateLoading = false

                                    } else {
                                        // console.log('UPDATE FAIL password')
                                        this.updateSuccess = false
                                        this.updateLoading = false

                                    }
                                })
                            }else{
                                    // console.log('UPADATE OK user')
                                this.updateSuccess = true
                                this.updateLoading = false
                            }

                        } else {
                            // console.log('UPDATE FAIL user')
                            this.updateSuccess = false
                            this.updateLoading = false

                        }
                    })
            }
        },
        computed: {

            ...mapState({
                getUserId: state => state.auth.userId,
                isRevendeur: state => state.auth.isRevendeur,
                waitingRevendeur: state => state.auth.waitingRevendeur,
                tauxRemise: state => state.auth.tauxRemise

            }),

            InfoUserFactPrenomErr () {
                const errors = [];
                if (!this.$v.InfoUserAdresseFact.prenom.$dirty) return errors
                !this.$v.InfoUserAdresseFact.prenom.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.InfoUserAdresseFact.prenom.required && errors.push('Le prénom est obligatoire')

                return errors

            },
            InfoUserFactNomErr () {
                const errors = [];

                if (!this.$v.InfoUserAdresseFact.nom.$dirty) return errors
                !this.$v.InfoUserAdresseFact.nom.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.InfoUserAdresseFact.nom.required && errors.push('Le nom est obligatoire')
                return errors

            },
            InfoUserFactPaysErr () {
                const errors = [];
                if (!this.$v.InfoUserAdresseFact.pays.$dirty) return errors
                !this.$v.InfoUserAdresseFact.pays.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.InfoUserAdresseFact.pays.required && errors.push('Le pays est obligatoire')
                return errors
            },
            InfoUserFactAdresseErr () {
                const errors = [];
                if (!this.$v.InfoUserAdresseFact.adresse.$dirty) return errors
                !this.$v.InfoUserAdresseFact.adresse.required && errors.push('L\'adresse est obligatoire')
                return errors
            },
            InfoUserFactPostalCodeErr () {
                const errors = [];
                if (!this.$v.InfoUserAdresseFact.code_postal.$dirty) return errors
                !this.$v.InfoUserAdresseFact.code_postal.numeric && errors.push('Le code postal est incorrect')
                !this.$v.InfoUserAdresseFact.code_postal.required && errors.push('Le code postal est obligatoire')
                !this.$v.InfoUserAdresseFact.code_postal.minLength && errors.push('Le code postal est incorrect')
                !this.$v.InfoUserAdresseFact.code_postal.maxLength && errors.push('Le code postal est incorrect')
                return errors
            },
            InfoUserFactVilleErr () {
                const errors = [];
                if (!this.$v.InfoUserAdresseFact.ville.$dirty) return errors
                !this.$v.InfoUserAdresseFact.ville.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.InfoUserAdresseFact.ville.required && errors.push('La ville est obligatoire')
                return errors
            },

            InfoUserLivrNomErr () {
                const errors = [];
                if (!this.$v.InfoUserAdresseLivr.prenom.$dirty) return errors
                !this.$v.InfoUserAdresseLivr.prenom.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.InfoUserAdresseLivr.prenom.required && errors.push('Le prénom est obligatoire')

                return errors
            },
            InfoUserLivrPrenomErr () {
                const errors = [];

                if (!this.$v.InfoUserAdresseLivr.nom.$dirty) return errors
                !this.$v.InfoUserAdresseLivr.nom.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.InfoUserAdresseLivr.nom.required && errors.push('Le nom est obligatoire')
                return errors

            },
            InfoUserLivrPaysErr () {
                const errors = [];
                if (!this.$v.InfoUserAdresseLivr.pays.$dirty) return errors
                !this.$v.InfoUserAdresseLivr.pays.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.InfoUserAdresseLivr.pays.required && errors.push('Le pays est obligatoire')
                return errors
            },
            InfoUserLivrAdresseErr () {
                const errors = [];
                if (!this.$v.InfoUserAdresseLivr.adresse.$dirty) return errors
                !this.$v.InfoUserAdresseLivr.adresse.required && errors.push('L\'adresse est obligatoire')
                return errors
            },
            InfoUserLivrPostalCodeErr () {
                const errors = [];
                if (!this.$v.InfoUserAdresseLivr.code_postal.$dirty) return errors
                !this.$v.InfoUserAdresseLivr.code_postal.numeric && errors.push('Le code postal est incorrect')
                !this.$v.InfoUserAdresseLivr.code_postal.required && errors.push('Le code postal est obligatoire')
                !this.$v.InfoUserAdresseLivr.code_postal.minLength && errors.push('Le code postal est incorrect')
                !this.$v.InfoUserAdresseLivr.code_postal.maxLength && errors.push('Le code postal est incorrect')
                return errors
            },
            InfoUserLivrVilleErr () {
                const errors = [];
                if (!this.$v.InfoUserAdresseLivr.ville.$dirty) return errors
                !this.$v.InfoUserAdresseLivr.ville.alpha && errors.push('Ce champ doit être des caractères alpha (A-Z a-Z)')
                !this.$v.InfoUserAdresseLivr.ville.required && errors.push('La ville est obligatoire')
                return errors
            },
            firstnameChangeErrors () {
                const errors = [];
                if (!this.$v.infoUser.prenom.$dirty) return errors
                !this.$v.infoUser.prenom.required && errors.push('Le prénom est obligatoire')
                return errors
            },
            lastnameChangeErrors () {
                const errors = [];
                if (!this.$v.infoUser.nom.$dirty) return errors
                !this.$v.infoUser.nom.required && errors.push('Le nom est obligatoire')
                return errors
            },
            emailChangeErrors () {
                const errors = [];
                if (!this.$v.infoUser.mail.$dirty) return errors
                !this.$v.infoUser.mail.email && errors.push('E-mail invalide')
                !this.$v.infoUser.mail.required && errors.push('E-mail obligatoire')
                return errors
            },
            passwordChangeErrors () {
                const errors = [];
                if (!this.$v.infoUser.password.$dirty) return errors
                !this.$v.infoUser.password.minLength && errors.push('Le mot de passe doit faire minimum 8 caractères')
                return errors
            },

        },
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        }

    }
</script>

<style >
.toHide .v-expansion-panel-header__icon{
    visibility: hidden!important;
}
</style>

