    <template>
        <v-app fluid>

            
            <v-row v-if="$vuetify.breakpoint.lgAndUp" class="pa-0">
<!--                <v-row>-->
                    <v-col lg="9" class="pa-0">

                        <ConfigurateurHeader :header-title="headerTitle"  class="bc_lightGrey"/>

                        <v-row class="bc_white">
                            <v-col lg="5" class=" align-center d-flex" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}" >
                                <v-col  class="pa-0">
                                    <v-row v-if="loaderStatus === true && VUE_APP_SITE_ID === '1'">
                                        <v-img aspect-ratio="1" src="@/assets/loader.gif" style="max-width: 40%; margin: auto"/>
                                    </v-row>
                                    <v-row v-if="loaderStatus === true && VUE_APP_SITE_ID === '2'">
                                        <v-img aspect-ratio="1" src="@/assets/loaderDJ.gif" style="max-width: 40%; margin: auto"/>
                                    </v-row>
                                    <v-row v-if="loaderStatus === false">
                                        <v-col class="pa-0">
                                            <div style='position:relative;margin-left:80px;width:500px;height:400px'>
                                                <v-img aspect-ratio="1" style='position:absolute ;top:0px; max-width: 20%;z-index:3;cursor: pointer' :src="getImgUrl(infoJante.marque)" :alt="infoJante.marque" />
                                                <v-img aspect-ratio="1" style='max-width: 70%;cursor: pointer' :class="{'ej-watermark': VUE_APP_SITE_ID === '1', 'dj-watermark': VUE_APP_SITE_ID === '2'}" @click="zoomPhoto = true" :src="photoPrincipal" :alt="photoPrincipal" />
<!--                                                <pic-zoom :url="photoPrincipal"  :scale="1.75" ></pic-zoom>-->
                                                <v-img aspect-ratio="1" :src="photoPrincipal" style="max-width: 10%;" class=" color" :class="{'ej-watermark': VUE_APP_SITE_ID === '1', 'dj-watermark': VUE_APP_SITE_ID === '2'}" :alt='infoJante.modele' :title="infoJante.modele"/>

                                            </div>
<!--                                            <v-col class="py-0">-->
<!--                                                <v-row class="pa-0">-->
<!--                                                    <v-col cols="4" class="py-0">-->
<!--&lt;!&ndash;                                                        <v-img aspect-ratio="1" :src="photoPrincipal" style="max-width: 50%;" class="mx-auto mt-12 first" :alt='infoJante.modele' :title="infoJante.modele"/>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <v-img aspect-ratio="1" :src="photoPrincipal" style="max-width: 30%;" class="mx-auto first" :alt='infoJante.modele' :title="infoJante.modele"/>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <v-img aspect-ratio="1" :src="photoPrincipal"  class="mx-auto mt-12 first" :alt='infoJante.modele' :title="infoJante.modele"/>&ndash;&gt;-->
<!--                                                    </v-col>-->
<!--&lt;!&ndash;                                                    <v-col cols="4" class="py-0">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <v-img aspect-ratio="1" :src="photoSecond" style="max-width: 50%;" class="mx-auto mt-12 magnifier-box vertical" :alt='infoJante.modele' :title="infoJante.modele"/>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </v-col>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    <v-col cols="4" class="py-0">&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <v-img aspect-ratio="1" :src="photoThird" style="max-width: 50%;" class="mx-auto mt-12 magnifier-box vertical" :alt='infoJante.modele' :title="infoJante.modele"/>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </v-col>&ndash;&gt;-->
<!--                                                </v-row>-->
<!--                                            </v-col>-->
                                        </v-col>

                                        <v-overlay
                                            :absolute="true"
                                            :value="zoomPhoto"
                                            :z-index="7"
                                            :opacity="0.46"
                                            class="ej-top-0"

                                        >
                                            <v-col >

                                                <v-card light style="min-width:550px;border-radius:50px;"  v-click-outside="onClickOutside" >

                                                    <v-col cols="12"  class="d-flex justify-end pb-0 ">
                                                        <v-btn text @click="zoomPhoto = false">
                                                            <v-icon large color="black">mdi mdi-close</v-icon>
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col class="d-flex justify-center pt-0">
                                                        <v-img aspect-ratio="1" style='max-width: 500px' @click="zoomPhoto = true" :src="photoPrincipal" :alt="photoPrincipal" class="align-center" :class="{'ej-watermark': VUE_APP_SITE_ID === '1', 'dj-watermark': VUE_APP_SITE_ID === '2'}" />
                                                    </v-col>


                                                </v-card>

                                            </v-col>
                                        </v-overlay>


                                    </v-row>
                                </v-col>
                            </v-col>
                            <v-col lg="6" class=""  :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}" >
<!--                                <v-row class="pl-3 font-weight-bold headline">-->
                                    <span class=" font-weight-bold headline">4 JANTES {{infoJante.marque}}  {{infoJante.modele}} </span><span v-if="infoJante.diametre" class="font-weight-bold headline "  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}"> {{ infoJante.diametre }}" pouces</span>
                                    <br>
                                    <span class=" headline font-weight-bold"  :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}"> En stock </span>
<!--                                </v-row>-->
                                <v-row class="pl-3 pt-3 pb-2 font-weight-bold  subtitle-2">Couleurs :</v-row>
                                <v-row>
                                <v-row class="pl-6 card-info-to-clicOn" >
                                <v-col lg="2" class="pa-0 text-center card-info-to-clicHere" v-for="color in colorisDisponible" :key="color.reference" style='margin-right:17px' @click='selectedJantes(color)'>
<!--                                            <v-img aspect-ratio="1" @error="color.image = 'http://pay.espace-jantes.com/img/specimentJantes.png'" class="magnifier-box vertical"  :src="color.image" style='width:70px !important;cursor: pointer' :alt='infoJante.modele' :title="infoJante.modele" />-->
                                            <v-img aspect-ratio="1"  v-if="VUE_APP_SITE_ID === '1'"  @error="color.image = 'http://pay.espace-jantes.com/img/specimentJantes.png'" class="ej-watermark color"  :src="color.image" style='width:70px !important;cursor: pointer' :alt='infoJante.modele' :title="infoJante.modele" />
                                            <v-img aspect-ratio="1"  v-else-if="VUE_APP_SITE_ID === '2'" class="dj-watermark color"  :src="color.image" style='width:70px !important;cursor: pointer' :alt='infoJante.modele' :title="infoJante.modele" />
                                            <v-content style="font-size: xx-small; text-align:left;margin-left:5px">{{color.couleurFinale}}</v-content>
                                        </v-col>
                                    </v-row>

                                </v-row>


                                <v-row class="pl-3 pt-2 font-weight-bold subtitle-2">Données techniques :</v-row>

                                <v-row>
                                    <v-col lg="2" class="px-1">
                                        <v-card class="text-center"  style="cursor: default;" hover height="100%" v-if="monteType == 'par4'">
                                            <v-card-text class=" pa-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">

                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageLargeurEJ" alt='Espace-Jantes Largeur de la jante' title="Espace-Jantes Largeur de la jante"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageLargeurDJ" alt='Distri-Jantes Largeur de la jante' title="Distri-Jantes Largeur de la jante"/>
                                                <div class="fontSize font-weight-medium mt-3" style="line-height: 0.5;padding-left: 18%;">
                                                    Avant
                                                </div>
                                                <div class="font-weight-medium caption"  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Largeur
                                                </div>
                                                <div class=" font-weight-bold caption" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                   {{infoJante.largeur}} pouces
                                                </div>
                                            </v-card-text>
                                        </v-card>

                                        <v-card class="text-center" style="cursor: default;" hover height="100%" v-if="monteType == 'decale'">
                                            <v-card-text class="pa-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">

                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageLargeurEJ" alt='Espace-Jantes Largeur de la jante' title="Espace-Jantes Largeur de la jante"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageLargeurDJ" alt='Distri-Jantes Largeur de la jante' title="Distri-Jantes Largeur de la jante"/>
                                                <div class="fontSize font-weight-medium mt-3" style="line-height: 0.5;padding-left: 20%;">
                                                    Avant
                                                </div>
                                                <div class=" font-weight-medium caption" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Largeur
                                                </div>
                                                <div class=" font-weight-bold caption" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                   {{infoJante.largeurMinAv}} pouces
                                                </div>
                                            </v-card-text>
                                        </v-card>

                                    </v-col>
                                    <v-col lg="2" class="px-1">
                                        <v-card class="text-center" style="cursor: default;" hover height="100%" v-if="monteType == 'par4'">

                                            <v-card-text class=" pa-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">

                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageLargeurEJ" alt='Espace-Jantes Largeur de la jante' title="Espace-Jantes Largeur de la jante"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageLargeurDJ" alt='Distri-Jantes Largeur de la jante' title="Distri-Jantes Largeur de la jante"/>
                                                <div class="fontSize font-weight-medium mt-3" style="line-height: 0.5;padding-left: 20%;">
                                                    Arriere
                                                </div>
                                                <div class=" font-weight-medium caption" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Largeur
                                                </div>
                                                <div class=" font-weight-bold  caption" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{infoJante.largeur}} pouces
                                                </div>
                                            </v-card-text>
                                        </v-card>

                                        <v-card class="text-center" style="cursor: default;" hover height="100%" v-if="monteType == 'decale'">
                                            <v-card-text class=" pa-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">

                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageLargeurEJ" alt='Espace-Jantes Largeur de la jante' title="Espace-Jantes Largeur de la jante"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageLargeurDJ" alt='Distri-Jantes Largeur de la jante' title="Distri-Jantes Largeur de la jante"/>
                                                <div class="fontSize font-weight-medium mt-3" style="line-height: 0.5;padding-left: 20%;">
                                                    Arriere
                                                </div>
                                                <div class=" font-weight-medium caption" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Largeur
                                                </div>
                                                <div class=" font-weight-bold caption" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}" >
                                                   {{infoJante.largeurMaxArr}} pouces
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
<!--                                    <v-col lg="2">-->
<!--                                        <v-card class="text-center" hover height="100%" v-if="monteType == 'par4'">-->
<!--                                            <v-card-text class="tc_darkBlue pa-1">-->
<!--                                                <v-img aspect-ratio="1" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageDiametre" alt='Espace-Jantes diametre de la jante' title="Espace-Jantes diametre de la jante" />-->
<!--                                                <div class="tc_turquoise font-weight-medium">-->
<!--                                                    Diametre-->
<!--&lt;!&ndash;                                                    <label >&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <select :v-model="infoJante.diametre" style=" width: 100%;appearance: none" class=" tc_darkBlue font-weight-bold caption border dropdown" @change="getDiametreAfterChange($event)">&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <option v-for="dimension in dimensions" :key="dimension" :selected="dimension == infoJante.diametre"> {{dimension}} pouces   </option>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </select>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </label>&ndash;&gt;-->
<!--                                                </div>-->

<!--                                                <div class="tc_darkBlue font-weight-bold caption">-->
<!--                                                    {{ infoJante.diametre }} pouces-->
<!--                                                </div>-->
<!--                                            </v-card-text>-->
<!--                                        </v-card>-->

<!--                                        <v-card class="text-center" hover height="100%" v-if="monteType == 'decale'">-->
<!--                                            <v-card-text class="tc_darkBlue pa-1">-->
<!--                                                <v-img aspect-ratio="1" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageDiametre" alt='Espace-Jantes diametre de la jante' title="Espace-Jantes diametre de la jante" />-->
<!--                                                <div class="tc_turquoise font-weight-medium">-->
<!--                                                    Diametre-->
<!--                                                </div>-->
<!--                                                <div class="tc_darkBlue font-weight-bold caption">-->
<!--                                                   {{ infoJante.diametre }} pouces-->
<!--                                                </div>-->
<!--                                            </v-card-text>-->
<!--                                        </v-card>-->
<!--                                    </v-col>-->
                                    <v-col lg="2" class="px-1">
                                        <v-card class="text-center" style="cursor: default;" hover height="100%" v-if="monteType == 'par4'">

                                            <v-card-text class=" pa-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageDeportEJ" alt='Espace-Jantes deport de la jante' title="Espace-Jantes deport de la jante"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageDeportDJ" alt='Distri-Jantes deport de la jante' title="Distri-Jantes deport de la jante"/>
                                                <div class="fontSize font-weight-medium mt-3" style="line-height: 0.5;padding-left: 18%;visibility: collapse;" >
                                                    collapse
                                                </div>
                                                <div class=" font-weight-medium caption" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Deport
                                                </div>
                                                <div class=" font-weight-bold caption" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{infoJante.offset}}
                                                </div>
                                            </v-card-text>
                                        </v-card>

                                        <v-card class="text-center" style="cursor: default;" hover height="100%" v-if="monteType == 'decale'">

                                            <v-card-text class=" pa-2"  :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">

                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageDeportEJ" alt='Espace-Jantes deport de la jante' title="Espace-Jantes deport de la jante"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageDeportDJ" alt='Distri-Jantes deport de la jante' title="Distri-Jantes deport de la jante"/>
                                                <div class="fontSize font-weight-medium mt-3" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Deport
                                                </div>
                                                <div class=" font-weight-bold caption" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                     AV: {{infoJante.offsetFront}}   AR: {{infoJante.offsetRear}}
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col lg="2" class="px-1">
                                        <v-card class="text-center" style="cursor: default;" hover height="100%">

                                            <v-card-text class=" pa-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">

                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageEntraxeEJ" alt='Espace-Jantes entraxe de la jante' title="Espace-Jantes entraxe de la jante"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageEntraxeDJ" alt='Distri-Jantes entraxe de la jante' title="Distri-Jantes entraxe de la jante"/>
                                                <div class="fontSize font-weight-medium mt-3" style="line-height: 0.5;padding-left: 18%;visibility: collapse;" >
                                                    collapse
                                                </div>
                                                <div class=" font-weight-medium caption" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Entraxe
                                                </div>
                                                <div class=" font-weight-bold caption" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{infoJante.entraxe}}
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col lg="2" class="px-1">
                                        <v-card class="text-center" style="cursor: default;" hover height="100%">

                                            <v-card-text class=" pa-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">

                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageAlesageEJ" alt='Espace-Jantes alesage de la jante' title="Espace-Jantes alesage de la jante"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" style="padding-top: 2%;width: 60%;" :src="imageAlesageDJ" alt='Distri-Jantes alesage de la jante' title="Distri-Jantes alesage de la jante"/>
                                                <div class="fontSize font-weight-medium mt-3 " style="line-height: 0.5;padding-left: 18%;visibility: collapse;" >
                                                    collapse
                                                </div>
                                                <div class=" font-weight-medium caption" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Alesage
                                                </div>
                                                <div class=" font-weight-bold caption" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    <span> {{infoJante.alesage}} </span>
                                                    <br>
                                                    <span style='font-size:0.50rem !important;line-height: 1.5;display: block;margin: 0 0;'>Bague alésage inférieur : {{bagueAlesage}} </span>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>

                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col lg="3" class="py-0 pl-0">
                        <Menu :update="triggerUpdate"/>
                    </v-col>
<!--                </v-row>-->
            </v-row>

            <div v-if="$vuetify.breakpoint.lgAndUp" style="height: 16vh"></div>

            <v-col v-if="$vuetify.breakpoint.mdAndDown" class="bc_lightGrey pa-0">
                <v-col lg="9" class="pa-0">

                    <ConfigurateurHeader :header-title="headerTitle"/>
                    <v-col class="pa-0 bc_white">
                        <v-col class="pl-0" style="height: 100%">
                            <v-col class="pl-3 pr-0 font-weight-bold headline">

<!--                                <span class=" font-weight-bold headline">4 JANTES {{infoJante.marque}}  {{infoJante.modele}} </span><span v-if="infoJante.diametre" class="font-weight-bold headline tc_turquoise"> {{ infoJante.diametre }}" pouces</span>-->

                                <br>
                                <span class=" headline font-weight-bold"  :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}"> En stock </span>

                            </v-col>

                            <v-col  lg="7" class="" style="position: relative" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                <v-img aspect-ratio="1" v-if="loaderStatus === true && VUE_APP_SITE_ID === '1'" src="@/assets/loader.gif" style="max-width: 40%; margin: auto"/>
                                <v-img aspect-ratio="1" v-if="loaderStatus === true && VUE_APP_SITE_ID === '2'" src="@/assets/loaderDJ.gif" style="max-width: 40%; margin: auto"/>


<!--                                <v-img aspect-ratio="1"  v-if="loaderStatus === false" style='position:absolute ;top:0px; max-width: 20%;z-index:3;'    :src="getImgUrl(infoJante.marque)" :alt="infoJante.marque" />-->
                                <v-img aspect-ratio="1"  v-if="loaderStatus === false" :src="photoPrincipal" :style="[ $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                                                     $vuetify.breakpoint.sm ? {'max-width' : '50%'} :
                                                                     $vuetify.breakpoint.xs ? {'max-width' : '70%'} : '']"
                                       class="mx-auto mt-12" :title='infoJante.modele' :alt='infoJante.modele' :class="{'ej-watermark': VUE_APP_SITE_ID === '1', 'dj-watermark': VUE_APP_SITE_ID === '2'}"/>

                            </v-col>
                            <v-col cols="12" class=" pr-0" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                <v-col class="pl-3 pt-3 pb-2 font-weight-bold
                                                                   text-xl-h5
                                                                   text-lg-h6
                                                                   text-md-subtitle-1
                                                                   text-sm-subtitle-1
                                                                   text-subtitle-2">Couleurs :</v-col>

                                <v-row class="pl-6 card-info-to-clicOn" >
                                    <v-col cols="4" class="pa-0 text-center card-info-to-clicHere" v-for="color in colorisDisponible" :key="color.reference" style="margin-top:5px" @click='selectedJantes(color)'>
                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'"  @error="color.image = 'http://pay.espace-jantes.com/img/specimentJantes.png'" :src="color.image" style='width:70px !important' :title='infoJante.modele' :alt='infoJante.modele'/>
                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" :src="color.image" style='width:70px !important' :title='infoJante.modele' :alt='infoJante.modele'/>
                                    <v-content style="font-size: xx-small; text-align:left; margin-left:5px">{{color.couleurFinale}}</v-content>

                                    </v-col>
                                    <!--                                    <v-col offset-lg="2" class="py-0">-->
    <!--                                        <v-img aspect-ratio="1" src="@/assets/icons/icon_cart_blue.svg" style="max-width: 40%"  />-->
    <!--                                        <v-content class="font-weight-medium  tc_turquoise" style="font-size: xx-small">En stock</v-content>-->
    <!--                                    </v-col>-->
                                </v-row>


                                <v-row class="pl-3 pt-2 font-weight-bold text-xl-h5
                                                                           text-lg-h6
                                                                           text-md-subtitle-1
                                                                           text-sm-subtitle-1
                                                                           text-subtitle-2">Données techniquesss :</v-row>

                                <v-row class=" text-xl-h5
                                                                       text-lg-h6
                                                                       text-md-subtitle-1
                                                                       text-sm-subtitle-1
                                                                       text-subtitle-2">
                                    <v-col cols="6" sm="4">
                                        <v-card class="text-center" hover height="100%" v-if="monteType == 'par4'">
                                            <v-card-text class=" pa-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">

                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" class="ma-auto" style="padding-top: 2%" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"
                                                       :src="imageLargeurEJ" alt='Espace-Jantes Largeur de la jante' title="Espace-Jantes Largeur de la jante"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" style="padding-top: 2%" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"
                                                       :src="imageLargeurDJ" alt='Distri-Jantes Largeur de la jante' title="Distri-Jantes Largeur de la jante"/>
                                                <div class=" font-weight-medium" style="padding-top:4%;line-height: 0.5;padding-left: 20%;">
                                                    Avant
                                                </div>
                                                <div class=" font-weight-medium"  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Largeur
                                                </div>
                                                <div class=" font-weight-bold " style="padding-top:4%;" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{infoJante.largeur}} pouces
                                                </div>
                                            </v-card-text>
                                        </v-card>

                                        <v-card class="text-center" hover height="100%" v-if="monteType == 'decale'">
                                            <v-card-text class=" pa-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">

                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" class="ma-auto" style="padding-top: 2%" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"
                                                       :src="imageLargeurEJ" alt='Espace-Jantes Largeur de la jante' title="Espace-Jantes Largeur de la jante"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" style="padding-top: 2%" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"
                                                       :src="imageLargeurDJ" alt='Distri-Jantes Largeur de la jante' title="Distri-Jantes Largeur de la jante"/>
                                                <div class=" font-weight-medium" style="padding-top:4%;line-height: 0.5;padding-left: 20%;">
                                                    Avant
                                                </div>
                                                <div class=" font-weight-medium"  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Largeur
                                                </div>
                                                <div class=" font-weight-bold " style="padding-top:4%;" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{infoJante.largeurMinAv}} pouces
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="6" sm="4">
                                        <v-card class="text-center" hover height="100%" v-if="monteType == 'par4'">

                                            <v-card-text class=" pa-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">

                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" class="ma-auto" style="padding-top: 2%;" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"
                                                       :src="imageLargeurEJ" alt='Espace-Jantes Largeur de la jante' title="Espace-Jantes Largeur de la jante"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" style="padding-top: 2%;" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"
                                                       :src="imageLargeurDJ" alt='Distri-Jantes Largeur de la jante' title="Distri-Jantes Largeur de la jante"/>
                                                <div class=" font-weight-medium" style="padding-top:4%;line-height: 0.5;padding-left: 20%;">
                                                    Arriere
                                                </div>
                                                <div class=" font-weight-medium"  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Largeur
                                                </div>
                                                <div class=" font-weight-bold " style="padding-top:4%;" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{infoJante.largeur}} pouces
                                                </div>
                                            </v-card-text>
                                        </v-card>

                                        <v-card class="text-center" hover height="100%" v-if="monteType == 'decale'">

                                            <v-card-text class=" pa-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">

                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" class="ma-auto" style="padding-top: 2%;" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"
                                                       :src="imageLargeurEJ" alt='Espace-Jantes Largeur de la jante' title="Espace-Jantes Largeur de la jante"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" style="padding-top: 2%;" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"
                                                       :src="imageLargeurDJ" alt='Distri-Jantes Largeur de la jante' title="Distri-Jantes Largeur de la jante"/>
                                                <div class=" font-weight-medium" style="padding-top:4%;line-height: 0.5;padding-left: 20%;">
                                                    Arriere
                                                </div>
                                                <div class=" font-weight-medium" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Largeur
                                                </div>
                                                <div class=" font-weight-bold " style="padding-top:4%;" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{infoJante.largeurMaxArr}} pouces
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
<!--                                    <v-col cols="6" sm="4">-->
<!--                                        <v-card class="text-center" hover height="100%" v-if="monteType == 'par4'">-->
<!--                                            <v-card-text class="tc_darkBlue pa-2">-->
<!--                                                <v-img aspect-ratio="1" class="ma-auto" style="padding-top: 2%" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :-->
<!--                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :-->
<!--                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"-->
<!--                                                       :src="imageDiametre" alt='Espace-Jantes diametre de la jante' title="Espace-Jantes diametre de la jante"/>-->
<!--                                                <div class="tc_turquoise font-weight-medium" style="padding-top:4%;">-->
<!--                                                    Diametre-->
<!--&lt;!&ndash;                                                    <label  >&ndash;&gt;-->
<!--&lt;!&ndash;                                                        <select  :v-model="infoJante.diametre" style=" width: 100%;appearance: none" class=" pl-1 tc_darkBlue font-weight-bold border dropdown">&ndash;&gt;-->
<!--&lt;!&ndash;                                                            <option v-for="dimension in dimensions" :key="dimension.id" :selected="dimension == infoJante.diametre">  {{dimension}} pouces   </option>&ndash;&gt;-->
<!--&lt;!&ndash;                                                        </select>&ndash;&gt;-->
<!--&lt;!&ndash;                                                    </label>&ndash;&gt;-->
<!--                                                </div>-->

<!--                                                <div class="tc_darkBlue font-weight-bold caption" style="padding-top:4%;">-->
<!--                                                    {{ infoJante.diametre }} pouces-->
<!--                                                </div>-->
<!--                                            </v-card-text>-->
<!--                                        </v-card>-->

<!--                                        <v-card class="text-center" hover height="100%" v-if="monteType == 'decale'">-->
<!--                                            <v-card-text class="tc_darkBlue pa-1">-->
<!--                                                <v-img aspect-ratio="1" class="ma-auto" style="padding-top: 2%;width: 90%;" :src="imageDiametre" alt='Espace-Jantes diametre de la jante' title="Espace-Jantes diametre de la jante" />-->
<!--                                                <div class="tc_turquoise font-weight-medium">-->
<!--                                                    Diametre-->
<!--                                                </div>-->
<!--                                                <div class="tc_darkBlue font-weight-bold caption" style="padding-top:4%;">-->
<!--                                                   {{infoJante.diametre}} pouces-->
<!--                                                </div>-->
<!--                                            </v-card-text>-->
<!--                                        </v-card>-->
<!--                                    </v-col>-->
                                    <v-col cols="6" sm="4">
                                        <v-card class="text-center" hover height="100%" v-if="monteType == 'par4'">

                                            <v-card-text class=" pa-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">

                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" class="ma-auto" style="padding-top: 2%" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"
                                                       :src="imageDeportEJ" alt='Espace-Jantes deport de la jante' title="Espace-Jantes deport de la jante"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" style="padding-top: 2%" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"
                                                       :src="imageDeportDJ" alt='Distri-Jantes deport de la jante' title="Distri-Jantes deport de la jante"/>
                                                <div class=" font-weight-medium" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Deport
                                                </div>
                                                <div class=" font-weight-bold" style="padding-top:4%;" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{infoJante.offset}}
                                                </div>
                                            </v-card-text>
                                        </v-card>

                                        <v-card class="text-center" hover height="100%" v-if="monteType == 'decale'">

                                            <v-card-text class=" pa-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">

                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" class="ma-auto" style="padding-top: 2%" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"
                                                       :src="imageDeportEJ" alt='Espace-Jantes deport de la jante' title="Espace-Jantes deport de la jante"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" style="padding-top: 2%" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"
                                                       :src="imageDeportDJ" alt='Distri-Jantes deport de la jante' title="Distri-Jantes deport de la jante"/>
                                                <div class=" font-weight-medium" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Deport
                                                </div>
                                                <div class=" font-weight-bold" style="padding-top:4%;" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    AV: {{infoJante.offsetFront}}   AR: {{infoJante.offsetRear}}
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="6" sm="4">
                                        <v-card class="text-center" hover height="100%">

                                            <v-card-text class=" pa-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">

                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" class="ma-auto" style="padding-top: 2%;" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"
                                                       :src="imageEntraxeEJ" alt='Espace-Jantes entraxe de la jante' title="Espace-Jantes entraxe de la jante"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" style="padding-top: 2%;" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"
                                                       :src="imageEntraxeDJ" alt='Distri-Jantes entraxe de la jante' title="Distri-Jantes entraxe de la jante"/>

                                                <div class=" font-weight-medium" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Entraxe
                                                </div>
                                                <div class=" font-weight-bold" style="padding-top:4%;" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{infoJante.entraxe}}
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="6" sm="4">
                                        <v-card class="text-center" hover height="100%">

                                            <v-card-text class=" pa-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">

                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" class="ma-auto" style="padding-top: 2%;" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"
                                                       :src="imageAlesageEJ" alt='Espace-Jantes alesage de la jante' title="Espace-Jantes alesage de la jante"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" style="padding-top: 2%;" :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"
                                                       :src="imageAlesageDJ" alt='Distri-Jantes alesage de la jante' title="Distri-Jantes alesage de la jante"/>

                                                <div class=" font-weight-medium" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Alesage
                                                </div>
                                                <div class=" font-weight-bold " style="padding-top:4%;" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{infoJante.alesage}}
                                                    <br>
                                                    <span>Bague alésage inferieur : {{bagueAlesage}} </span>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>

                            </v-col>
                        </v-col>
                    </v-col>

                </v-col>
                <v-col lg="3" class="pa-0">
                    <Menu/>
                </v-col>
            </v-col>


            <ConfigurateurFooter :update="triggerUpdate"/>

        </v-app>
</template>

<script>
    import ConfigurateurHeader from "../headers/ConfigurateurHeader";
    import ConfigurateurFooter from "../footer/ConfigurateurFooter";
    import Menu from "./Menu";

    import 'instantsearch.css/themes/algolia-min.css';
    import algoliasearch from 'algoliasearch';

    import LargeurEJ from '@/assets/icons/icon_largeurEJ.svg';
    import DiametreEJ from '@/assets/icons/icon_diametreEJ.svg';
    import EntraxeEJ from '@/assets/icons/icon_entraxeEJ.svg';
    import DeportEJ from '@/assets/icons/icon_deportEJ.svg';
    import AlesageEJ from '@/assets/icons/icon_alesageEJ.svg';

    import LargeurDJ from '@/assets/icons/icon_largeurDJ.svg';
    import DiametreDJ from '@/assets/icons/icon_diametreDJ.svg';
    import EntraxeDJ from '@/assets/icons/icon_entraxeDJ.svg';
    import DeportDJ from '@/assets/icons/icon_deportDJ.svg';
    import AlesageDJ from '@/assets/icons/icon_alesageDJ.svg';

    import {mapState} from "vuex";
    import SecureLS from "secure-ls";
    var ls = new SecureLS({ isCompression: false });

    import Vue from 'vue'
    import axios from 'axios'
    import VueAxios from 'vue-axios'
    import BagueCentrage from "@/assets/accessoires/bague-de-centrage.jpg";

    Vue.use(VueAxios, axios)

    export default {
        name: "Etape1.vue",
        components:{
            Menu,
            ConfigurateurHeader,
            ConfigurateurFooter,
            // PicZoom
        },
        data: () => ({
            optionDeMonte: [],
            optionFront:[],
            optionRear:[],
            photoPrincipal:"",
            photoSecond:"",
            photoThird:"",
            zoomerOptions : {
                zoomFactor: 3,
                pane: "pane",
                hoverDelay: 300,
                namespace: "zoomer-right",
                move_by_click: false,
                scroll_items: 2,
                choosed_thumb_border_color: "black",
                scroller_position: "left",
                zoomer_pane_position: "left"
            },
            bagueAlesage:"",
            infoJante: {},
            infoJanteArriere:'',
            selected: '9 pouces',
            headerTitle:1,
            configurateurJson: {},
            imageLargeurEJ: LargeurEJ,
            imageDiametreEJ: DiametreEJ,
            imageEntraxeEJ: EntraxeEJ,
            imageDeportEJ: DeportEJ,
            imageAlesageEJ: AlesageEJ,

            imageLargeurDJ: LargeurDJ,
            imageDiametreDJ: DiametreDJ,
            imageEntraxeDJ: EntraxeDJ,
            imageDeportDJ: DeportDJ,
            imageAlesageDJ: AlesageDJ,

            colorisDisponible: [],
            dimensions:[],
            dimensionsPossibleChassis: [],
            dimensionConfigurateur:"",
            filter_default: { value: '17 pouces'},
            filters: [
                { value: '17 pouces'},
                { value: '18 pouces'},
                { value: '19 pouces'},
                { value: '20 pouces'},
            ],
            currentColorIndex:0,
            currentColor: '',
            colors : [
                {id: '0', name: 'Black mat'},
                {id: '1', name: 'Antracite'},
                {id: '2', name: 'Silver'},
            ],
            options:[
                {name:'9 pouces'},
                {name:'10 pouces'},
                {name:'11 pouces'},
                {name:'12 pouces'},
            ],
            zoomPhoto:false,
            params:{},
            triggerUpdate: 1,
            VUE_APP_SITE_ID:null
        }),
        beforeCreate(){
            this.monteType =  (this.$route.params.decale).toString() === '0' ? 'par4' : 'decale'
        },
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
            if( process.env.NODE_ENV.trim() !== "development") {
                window.dataLayer.push({
                    'event': 'Pageview',
                    'pagePath': '/etape1',
                    'pageTitle': 'Etape1'
                });
            }

            this.monteType =  (this.$route.params.decale).toString() === '0' ? 'par4' : 'decale'

        // Ajout variable ==>> Avant rafraichissement de la page pour affichage des donneés Prix.

            const myUrl = window.location.href.split('/')
        // console.log('// Etapte1 - Ligne 710 ///////// url : ', myUrl)
            let strToFind = myUrl[myUrl.length - 2]
            strToFind.includes('%20') ? strToFind = strToFind.replace('%20', ' ') : strToFind
         // console.log('// Etape1 - Ligne 713 ///////// strToFind : ', strToFind)

            const triggerClickEj = () => {
                const containerToSearch = document.querySelectorAll('.card-info-to-clicOn .card-info-to-clicHere')
                containerToSearch.forEach(element => {
                        element.querySelector('main div').textContent == strToFind ? element.click() : null
                    })
            }
            setTimeout(()=>triggerClickEj(), 5000)
        },
        created() {
            this.infoJante = {marque: this.$route.params.marque, modele: this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur}
console.log('this.infoJantecrea', this.infoJante)
            this.$store.commit('loader/setLoader', true)
            this.$store.commit('loader/setLoaderPrix', true)

            this.getOptionchassis()
            setTimeout(() =>{ this.methodOncreated() }, 2000);


        },
        methods: {
            setOnglet(item, value){
                // console.log('setOnglet',value, item)

                switch(value){
                    case"4jantes":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : item, jantesArr : item})
                        this.infoJante = item
                        this.currentColor = this.colors[0].name;
                        this.photoPrincipal = this.infoJante.photo_1
                        this.triggerUpdate ++
                        break
                    case"jantesAv":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : item, jantesArr : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr})
                        this.triggerUpdate ++
                        break
                    case"jantesArr":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv, jantesArr : item})
                        this.triggerUpdate ++
                        break
                    case"4pneus":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : item, pneusArr : item})
                        this.triggerUpdate ++
                        break
                    case"pneusAv":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : item, pneusArr : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr})
                        this.triggerUpdate ++
                        break
                    case"pneusArr":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv, pneusArr : item})
                        this.triggerUpdate ++
                        break
                    case"accessoires":
                        this.TotalPriceAccessoires = 0
                        Object.values(item).forEach((e) => {
                            if(e != null) {
                                this.TotalPriceAccessoires += parseInt(e.data.price)
                            }
                        })
                        // console.log('this.TotalPriceAccessoires', this.TotalPriceAccessoires)
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires', {accessoires: item, totalPrice: this.TotalPriceAccessoires})

                        this.triggerUpdate ++
                        break
                    case"indexAccessoires":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires', {accessoires: ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').accessoires, selected: item })
                        this.triggerUpdate ++
                        break
                    case"modelePossibleAuCarre":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre', item)
                        break
                    case"modelePossibleFront":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront', item)
                        break
                    case"modelePossibleRear":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear', item)
                        break
                }

                ls.set('last_updated', this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale)

            },
            getOnglet(value){

                switch(value){
                    case'jantesAv':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv
                    case 'jantesArr':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr
                    case 'pneusAv':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv
                    case 'pneusArr':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr
                    case 'accessoires':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').accessoires
                    case 'modelePossibleAuCarre':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre')
                    case 'modelePossibleFront':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront')
                    case 'modelePossibleRear':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear')

                }

            },
            onClickOutside:function(){
                this.zoomPhoto = false
            },
            selectedJantes(color) {
                console.log('change color')
                this.photoPrincipal = color.image
                this.photoSecond = color.image2
                this.photoThird = color.image2
                // console.log('photoPrincipal', this.photoPrincipal)
                // console.log('photoSecond', this.photoSecond)
                // console.log('photoThird', this.photoThird)
                // console.log('color', color)

                var monteType = (this.$route.params.decale).toString() === '0' ? 'par4' : 'decale'
                if(monteType == 'decale') {
                    var largeurMinAv = this.infoJante.largeurMinAv
                    var largeurMaxArr = this.infoJante.largeurMaxArr
                    var offsetFront = this.infoJante.offsetFront
                    var offsetRear = this.infoJante.offsetRear
                }

                const client = algoliasearch('IXJF8AJTH7', '27829f3918903605b2f7665097a13737');
                const index = client.initIndex('dev_EJ');

                index.search('', {
                filters:'objectID: "' + color.reference  +'"'
            })
            .then(({ hits }) => {
                console.log('hits')
                this.infoJante = hits[0]

                if(monteType == 'decale') {
                    this.infoJante.largeurMinAv = largeurMinAv
                    this.infoJante.largeurMaxArr = largeurMaxArr
                    this.infoJante.offsetFront = offsetFront
                    this.infoJante.offsetRear = offsetRear


                    var couleurFront = []
                    var couleurRear = []
                    var largeurMini = []
                    var largeurMaxi = []

                    this.getOnglet('modelePossibleFront').forEach(elementFront => {
                        if(largeurMini.length == 0 || (largeurMini[0].largeur > elementFront.largeur  )){
                            if(this.infoJante.couleur == elementFront.couleur){
                                // if(largeurMini.length == 0 || largeurMini[0].offset < elementFront.offset) {
                                largeurMini = []
                                largeurMini.push(elementFront)
                                // console.log('elementFront')
                                // console.log(elementFront)
                            }
                        }
                        const resultat = couleurFront.find( couleur => couleur.couleur === elementFront.couleur);

                        if( resultat == 'undefined' || resultat == undefined){
                            var infoCouleur = {}
                            var couleurFinale =  elementFront.couleur
                            // var couleurFinale =  elementFront.couleur.replace(/\s/g, "+")
                            infoCouleur.couleurFinale = couleurFinale
                            infoCouleur.couleur = elementFront.couleur
                            infoCouleur.image = elementFront.photo_1
                            infoCouleur.reference = elementFront.objectID
                            couleurFront.push(infoCouleur)
                        }
                    })
                    this.getOnglet('modelePossibleRear').forEach(elementRear => {
                        if(largeurMaxi.length == 0 || largeurMaxi[0].largeur < elementRear.largeur){
                            if(largeurMaxi.length == 0 || (largeurMaxi[0].offset < elementRear.offset   ) ) {
                                if(this.infoJante.couleur == elementRear.couleur){
                                    largeurMaxi = []
                                    largeurMaxi.push(elementRear)
                                }
                            }
                        }
                        const resultat = couleurRear.find( couleur => couleur.couleur === elementRear.couleur);

                        if( resultat == 'undefined' || resultat == undefined){
                            var infoCouleur = {}
                            var couleurFinale =  elementRear.couleur
                            // var couleurFinale =  elementRear.couleur.replace(/\s/g, "+")
                            infoCouleur.couleurFinale = couleurFinale
                            infoCouleur.couleur = elementRear.couleur
                            infoCouleur.image = elementRear.photo_1
                            infoCouleur.reference = elementRear.objectID
                            couleurRear.push(infoCouleur)
                        }
                    })

                    this.infoJante.public_price = largeurMini[0].public_price
                    this.infoJante.public_price_rear = largeurMaxi[0].public_price
                }
                // localStorage.setItem("infoJantes", JSON.stringify(this.infoJante));
                // ls.set("infoJantes", JSON.stringify(this.infoJante));

                // this.$store.commit('infoJantes/setJante', {'Av': this.infoJante})
                // this.$store.commit('infoJantes/setJante', {'Arr': this.infoJante})

                this.updateRoute(color.couleur, 'couleur')

                this.setOnglet(this.infoJante, '4jantes')
            })
            },
            methodOncreated(onCreate = true) {
              console.log('this.infoJanteoncreaaaate', this.infoJante)
                this.$store.commit('footerNext/setNext', 'true')
                var modelePossible = []
                

                var monteType = (this.$route.params.decale).toString() === '0' ? 'par4' : 'decale'

                var infoChassis
                if( typeof ls.get('chassisVehiculeUtilisateur') == "string" ){
                    infoChassis = JSON.parse(ls.get('chassisVehiculeUtilisateur'))
                }
                else if(typeof ls.get('chassisVehiculeUtilisateur') == "object"){
                    infoChassis = ls.get('chassisVehiculeUtilisateur')
                }

                if(infoChassis.entraxe.includes('.'))
                {
                    infoChassis.entraxe = infoChassis.entraxe.split('.')[0]
                    ls.set('chassisVehiculeUtilisateur', infoChassis )
                }

                // infoChassis.entraxe =
                var stock = ' ( NOT stock:0 AND NOT stock:1 AND NOT stock:2 AND NOT stock:3 ) '
                var visibility = ' AND visible:1 '

                if(monteType == "par4" || monteType == "") {
                    console.log('4', this.infoJante)
                  Vue.axios.get('https://api.espace-jantes.com/getOptionsChassis', {params: {idChassisVehiculeUtilisateur : infoChassis.id_chassis}})
                        .then((response) => {
                          response.data.forEach(element => {
                            if(element.wheelSize.split("x")[1].trim() == this.dimensionConfigurateur.trim() && element.upsetType != 'RearUpstep' ){
                              this.optionDeMonte.push(element)
                            }
                          })
                        })
                    if(onCreate){
                    // this.SelectedJante ? this.infoJante = this.SelectedJante : this.infoJante = JSON.parse(this.$localStorage.get('infoJantes'))
                    // this.SelectedJante ? this.infoJante = this.SelectedJante : this.infoJante = JSON.parse(ls.get('infoJantes'))
                    //this.infoJante = {marque: this.$route.params.marque, modele: this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur}
                    // this.dimensionConfigurateur = this.$localStorage.get('diametre')
                    this.dimensionConfigurateur = this.$route.params.diametre.toString()
                    }

                    const client = algoliasearch('IXJF8AJTH7', '27829f3918903605b2f7665097a13737');
                    const index = client.initIndex('dev_EJ');
                    //this.infoJante = {marque: this.$route.params.marque, modele: this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur}
                    var alesage = ' alesage >= '+ infoChassis.alesage + ' '

                    index.search('', {
                    filters:'modele: "' + this.$route.params.modele + '" AND marque: "' + this.$route.params.marque + '" AND diametre: ' + this.$route.params.diametre +' AND entraxe:"'+ infoChassis.entraxe +'" AND ' + alesage +' AND ' + stock + visibility,
                    distinct: false,
                    hitsPerPage: 1000,
                    })
                    .then(({ hits }) => {
                    // Boucle pour la récuperation des couleurs
                    hits.forEach(element => {
                        modelePossible.push(element)

                        setTimeout(() => {
                          this.optionDeMonte.forEach(optionDeMonte => {
                            if(parseFloat(optionDeMonte.wheelSize.split("x")[0].trim()).toFixed(1) == element.largeur && element.offset >= optionDeMonte.minOffset && element.offset <= optionDeMonte.maxOffset ){
                              var couleurExiste = this.colorisDisponible.find( coloris => coloris.couleur == element.couleur)

                              if(couleurExiste == undefined || couleurExiste == 'undefined'){

                              var couleurReference =  {}
                              // var couleurFinale =  element.couleur.replace(/\s/g, "+")
                              var couleurFinale =  element.couleur
                              couleurReference.couleurFinale = couleurFinale
                              couleurReference.couleur = element.couleur
                              couleurReference.reference = element.objectID
                              couleurReference.image = element.photo_1
                              couleurReference.image2 = element.photo_2
                              couleurReference.image3 = element.photo_3

                              this.colorisDisponible.push(couleurReference)
                              }
                            }
                          })
                        }, 2000);
                    })

                    // ls.set('modelePossibleAuCarre', JSON.stringify(modelePossible))
                    this.setOnglet(modelePossible, 'modelePossibleAuCarre')
                    //Recuperation de la plus petite taille de jantes
                        //Filtre les resultats algolia par le diametre et la couleur choisi dans le listing
                        var resultTMP = hits.filter(element =>  element.diametre == this.dimensionConfigurateur && element.couleur == this.infoJante.couleur );
                        //Recuperation de la plus petite largeur de jante dans resultTMP
                        var min = Math.min(...resultTMP.map(e => e.largeur))
                        // console.log("min",min)
                        //Recuperation des informations de la jante avec la plus petite largeur
                        var arrayTMP = resultTMP.filter(element => element.largeur == min)
                        // console.log('arrayTMP',arrayTMP)
                        //Filtre pour verifier si le deport est strictement superieur au min_deport et strictement inferieur au max_deport par rapport aux informations chassis
                        var arrayJantesOffset = arrayTMP.filter(element => element.offset > infoChassis.min_deport_front && element.offset < infoChassis.max_deport_front)
                        console.log('arrayJantesOffset',arrayJantesOffset, arrayJantesOffset.length)
                        //Si le resultat est négatif et que les infos chassis deport ne sont pas compatible alors on va chercher les options du chassis
                        if(arrayJantesOffset.length == 0){

                        var arrayLargeur = []
                        Vue.axios.get('https://api.espace-jantes.com/getOptionsChassis', {params: {idChassisVehiculeUtilisateur : infoChassis.id_chassis}})
                        .then((response) => {

                            response.data.forEach(element => {
                                var wheelSize = element.wheelSize.split("x")
                                if(wheelSize[1].trim() == this.dimensionConfigurateur.trim() && arrayLargeur.indexOf(parseFloat(wheelSize[0].trim())) == -1 ){
                                    //On ajoute la largeur
                                    // console.log(element)
                                    arrayLargeur.push(parseFloat(wheelSize[0].trim()))
                                }
                            })
                            min = Math.min(...arrayLargeur)

                            arrayTMP = resultTMP.filter(element => element.largeur == min)

                            // Tant qu'il y a des element dans le tableau largeur et que arrayTMP n'a pas de compabilité recherche la largeur compatible
                            while(arrayTMP.length == 0 && arrayLargeur.length >= 1) {
                                var index = arrayLargeur.indexOf(min)
                                // console.log(typeof min)
                                // console.log("min")
                                // console.log(min)
                                // console.log('herererer')
                                // console.log(index)
                                // console.log("arrayLargeur")
                                // console.log(arrayLargeur)
                                // suppression de la largeur qui ne correspond pas
                                arrayLargeur.splice(index, 1);
                                // verification que le tableau largeur possede encore des elements.
                                if(arrayLargeur != 0) {
                                    //Recuperation de la plus petite largeur
                                    min = Math.min(...arrayLargeur)
                                    ///vérification si la jante est egale a la plus petite largeur d'option
                                    arrayTMP = resultTMP.filter(element => element.largeur == min)
                                    if(arrayTMP.length != 0) {
                                        response.data.forEach(element => {
                                            //wheelsize sous la forme 8.5 x 17
                                            var wheelSize = element.wheelSize.split("x")
                                            //Vérification du bon diametre de l'option
                                            if(wheelSize[1].trim() == this.dimensionConfigurateur.trim() && parseFloat(wheelSize[0].trim()) == min ){
                                                // console.log('here')
                                                // console.log(min)
                                                // console.log(element)
                                                // console.log(arrayTMP)
                                                if(arrayTMP.length == 0){
                                                    arrayJantesOffset = arrayTMP.filter(elementBis => elementBis.offset >= element.minOffset && elementBis.offset <= element.maxOffset)
                                                    arrayTMP = arrayJantesOffset
                                                }
                                            }
                                        })
                                    }
                                }
                            }
console.log('resp', response.data)
                            response.data.forEach(element => {
                                var wheelSize = element.wheelSize.split("x")
                                if(wheelSize[0].trim() == min && this.dimensionConfigurateur.trim() == wheelSize[1].trim() && (element.upsetType == "Upstep" || element.upsetType == "FrontUpstep")) {
                                    if(arrayTMP.length == 0 ) {

                                        this.infoJante = resultTMP[0]
                                        console.log('resultTMP',this.infoJante )

                                    }else{
                                        arrayJantesOffset = arrayTMP.filter(elementBis => elementBis.offset >= element.minOffset && elementBis.offset <= element.maxOffset)

                                        if(arrayJantesOffset.length){
                                            this.infoJante = arrayJantesOffset[0]
                                            // console.log('arrayJantesOffset',this.infoJante )

                                        }
                                    console.log('la')
                                    }
                                }
                            })
                            
                            
                            if(this.infoJante !== undefined || this.infoJante !== "undefined" ){
                            // if(this.infoJante !== undefined  ){

                                this.setOnglet(this.infoJante, '4jantes')
                            }
                        })
                    }
                else{
                        var arrayOffset = []
                        var minOffsetOption
                        var maxOffsetOption
                            console.log('ici', arrayJantesOffset)
                        Vue.axios.get('https://api.espace-jantes.com/getOptionsChassis', {params: {idChassisVehiculeUtilisateur : infoChassis.id_chassis}})
                            .then((response) => {
                                console.log('response', response.data)
                                response.data.forEach(element => {
                                    var wheelSize = element.wheelSize.split("x")
                                    if(element.upsetType != "RearUpset" && this.dimensionConfigurateur.trim() == wheelSize[1].trim() && wheelSize[0].trim() == min) {
                                        minOffsetOption = element.minOffset
                                        maxOffsetOption = element.maxOffset
                                        console.log('1', minOffsetOption, maxOffsetOption, element);
                                    }
                console.log('this.dimensionConfigurateur.trim() == wheelSize[1].trim()', this.dimensionConfigurateur.trim(), wheelSize[1].trim())

                                    arrayJantesOffset.forEach(element => {
                                        console.log('arrayOffset', arrayOffset.indexOf(element.offset))
                                        console.log('element.offset',minOffsetOption, element.offset, maxOffsetOption)

                                        if(element.offset >= minOffsetOption && element.offset <= maxOffsetOption && arrayOffset.indexOf(element.offset) == -1){
                                            arrayOffset.push(element.offset)
                                            console.log('2');
                                        }
                                    })
                                })
                                var maxOffset = Math.max(...arrayOffset)
                                arrayJantesOffset.forEach(element => {
                                    if(element.offset == maxOffset){
                                        this.infoJante = element
                                    }
                                })
                                console.log('this.infoJan', this.infoJante)
                                if(this.infoJante != undefined || this.infoJante != "undefined" ){
                                // if(this.infoJante != undefined ){

                                    this.setOnglet(this.infoJante, '4jantes')
                                    this.$store.commit('loader/setLoader', false)
                                    this.$store.commit('loader/setLoaderPrix', false)
                                }
                            })
                    }
                    this.infoJante = Object.assign({}, this.infoJante)


                    this.setOnglet(this.infoJante ,'4jantes')
                    this.$store.commit('loader/setLoader', false)
                    this.$store.commit('loader/setLoaderPrix', false)


                });
                    if(onCreate){

                    // var listKtype = (this.$localStorage.get('listKtype')).split(',')
                    var listKtype = ls.get('listKtype')
                    // console.log(getListKtype)
                    // var listKtype = getListKtype.split(',')
                    Vue.axios.get('https://api.espace-jantes.com/infoChassis', {params: {listKtype : listKtype}})
                    .then((response) => {
                        const _this_dimension = []

                        response.data.forEach(function(element) {
                            var wheelSizeSplit = element.wheelSize.trim().split('x')
                            wheelSizeSplit[1] = parseInt(wheelSizeSplit[1].trim())
                            if(_this_dimension.indexOf(wheelSizeSplit[1]) == -1 ){
                                _this_dimension.push(wheelSizeSplit[1])
                            }
                        })

                        const byValue  = (a,b) => a - b;
                        const sorted = [... _this_dimension].sort(byValue)
                        this.dimensionsPossibleChassis = sorted
                        //this.infoJante = {marque: this.$route.params.marque, modele: this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur}

                        index.search('', {
                            // filters:'modele: "' + this.infoJante.modele + '" AND marque: "' + this.infoJante.marque + '" AND entraxe:"'+ this.infoJante.entraxe +'" AND ' + stock + visibility,
                            filters:'modele: "' + this.$route.params.modele + '" AND marque: "' + this.$route.params.marque +'" AND entraxe:"'+ infoChassis.entraxe +'" AND ' + stock + visibility,
                            distinct: false,
                            hitsPerPage: 1000,
                        })
                        .then(({ hits }) => {
                            var dimensionsPossibleChassis = this.dimensionsPossibleChassis
                            hits.forEach(element => {
                                if(this.dimensions.indexOf(element.diametre) == -1 && dimensionsPossibleChassis.indexOf(element.diametre) != -1){
                                    this.dimensions.push(element.diametre)  
                                }
                            })
                        })
                    })
                    this.photoPrincipal = this.infoJante.photo_1

                }
                
                }
                else if(monteType == "decale"){
                        // // var infoOptionET = JSON.parse(this.$localStorage.get('storeEt'))
                        // var infoOptionET = JSON.parse(ls.get('storeEt'))
                    var modelePossibleFront = []
                    var modelePossibleRear = []

                    if(onCreate){
                        // this.SelectedJante ? this.infoJante = this.SelectedJante : this.infoJante = JSON.parse(this.$localStorage.get('infoJantes'))
                        // this.SelectedJante ? this.infoJante = this.SelectedJante : this.infoJante = JSON.parse(ls.get('infoJantes'))
                        //this.infoJante = {marque: this.$route.params.marque, modele: this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur}

                        //this.SelectedJante ? this.infoJanteArriere = this.SelectedJante : this.infoJanteArriere = JSON.parse(ls.get('infoJantes'))

                        // this.dimensionConfigurateur = this.$localStorage.get('diametre')
                        this.dimensionConfigurateur = this.$route.params.diametre.toString()
                        }
                    const client = algoliasearch('IXJF8AJTH7', 'c4c7c9439e003674579cb68a3352acc5');
                    const index = client.initIndex('dev_EJ');
                    //this.infoJante = {marque: this.$route.params.marque, modele: this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur}


                    index.search('', {
                        // filters:'modele: "' + this.infoJante.modele + '" AND marque: "' + this.infoJante.marque + '" AND diametre: ' + this.infoJante.diametre +' AND entraxe:"'+ this.infoJante.entraxe +'" AND ' + stock + ' AND  alesage >='+ infoChassis.alesage + ' AND '+ stock + visibility,
                        filters:'modele: "' + this.$route.params.modele + '" AND marque: "' + this.$route.params.marque + '" AND diametre: ' + this.$route.params.diametre +' AND entraxe:"'+ infoChassis.entraxe +'" AND  alesage >='+ infoChassis.alesage + ' AND ' + stock + visibility,
                        distinct: false,
                        hitsPerPage: 1000,
                    })
                    .then(({ hits }) => {
                        hits.forEach(element => {

                            this.optionFront.forEach(elementFront => {
                                if(parseFloat(elementFront.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                    if(element.offset >= elementFront.minOffset && element.offset <= elementFront.maxOffset){
                                        if(modelePossibleFront.indexOf(element) == -1){
                                            modelePossibleFront.push(element)
                                        }
                                    }
                                }
                            })

                            this.optionRear.forEach(elementRear => {
                                if(parseFloat(elementRear.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                    if(element.offset >= elementRear.minOffset && element.offset <= elementRear.maxOffset){
                                        if(modelePossibleRear.indexOf(element) == -1){
                                            modelePossibleRear.push(element)
                                        }
                                    }
                                }
                            })
                        })
                        var couleurFront = []
                        var couleurRear = []

                        var largeurMini = []
                        var largeurMaxi = []
                        //Passage pour l'etape 2
                        // ls.set('modelePossibleFront', JSON.stringify(modelePossibleFront))
                        this.setOnglet(modelePossibleFront, 'modelePossibleFront' )
                        // console.log('modelePossibleFront', modelePossibleFront)
                        // this.$localStorage.set('modelePossibleRear', JSON.stringify(modelePossibleRear))
                        // ls.set('modelePossibleRear', JSON.stringify(modelePossibleRear))
                        this.setOnglet(modelePossibleRear,'modelePossibleRear')

                        modelePossibleFront.forEach(elementFront => {
                            if(largeurMini.length == 0 || (largeurMini[0].largeur > elementFront.largeur  )){
                              if(this.infoJante.couleur == elementFront.couleur){
                                // if(largeurMini.length == 0 || largeurMini[0].offset < elementFront.offset) {
                                    largeurMini = []
                                    largeurMini.push(elementFront)
                                    // console.log('elementFront')
                                    // console.log(elementFront)
                                }
                            }
                                const resultat = couleurFront.find( couleur => couleur.couleur === elementFront.couleur);

                                if( resultat == 'undefined' || resultat == undefined){
                                    var infoCouleur = {}
                                    var couleurFinale =  elementFront.couleur
                                    // var couleurFinale =  elementFront.couleur.replace(/\s/g, "+")
                                    infoCouleur.couleurFinale = couleurFinale
                                    infoCouleur.couleur = elementFront.couleur
                                    infoCouleur.image = elementFront.photo_1
                                    infoCouleur.reference = elementFront.objectID
                                    couleurFront.push(infoCouleur)
                                }
                            })
                        modelePossibleRear.forEach(elementRear => {
                            if(largeurMaxi.length == 0 || largeurMaxi[0].largeur < elementRear.largeur){
                                if(largeurMaxi.length == 0 || (largeurMaxi[0].offset < elementRear.offset   ) ) {
                                  if(this.infoJante.couleur == elementRear.couleur){
                                    largeurMaxi = []
                                    largeurMaxi.push(elementRear)
                                  }
                                }
                            }
                            const resultat = couleurRear.find( couleur => couleur.couleur === elementRear.couleur);

                            if( resultat == 'undefined' || resultat == undefined){
                                    var infoCouleur = {}
                                    var couleurFinale =  elementRear.couleur
                                    // var couleurFinale =  elementRear.couleur.replace(/\s/g, "+")
                                    infoCouleur.couleurFinale = couleurFinale
                                    infoCouleur.couleur = elementRear.couleur
                                    infoCouleur.image = elementRear.photo_1
                                    infoCouleur.reference = elementRear.objectID
                                    couleurRear.push(infoCouleur)
                                }
                        })

                        var couleurDispo = []
                        couleurFront.forEach(elementFront =>{
                            couleurRear.forEach(elementRear => {

                                if(elementFront.couleur == elementRear.couleur && couleurDispo.indexOf(elementRear) == -1 &&  couleurDispo.indexOf(elementFront) == -1){
                                    couleurDispo.push(elementRear)
                                }
                            })
                        })
                        this.colorisDisponible = couleurDispo
                        //console.log('this.infoJante', this.infoJante)


                        //console.log('largeurMini[0].offset', largeurMini[0].offset)



                        var resultTMPAv = hits.filter(element =>  element.diametre == this.dimensionConfigurateur && element.couleur == this.infoJante.couleur && element.offset == largeurMini[0].offset);
                        //console.log('resultTMPAv', resultTMPAv)

                        this.infoJante = resultTMPAv[0]

                        this.infoJante.largeurMinAv = largeurMini[0].largeur
                        this.infoJante.largeurMaxArr = largeurMaxi[0].largeur
                        this.infoJante.offsetFront = largeurMini[0].offset
                        this.infoJante.offsetRear = largeurMaxi[0].offset
                        this.infoJante.entraxe = infoChassis.entraxe
                        this.infoJante.alesage = infoChassis.alesage
                        this.infoJante.photo_1 = this.colorisDisponible.find(element => element.couleurFinale === this.$route.params.couleur).image
                        this.photoPrincipal = this.infoJante.photo_1
                        // this.public_price = largeurMini[0].public_price
                        this.infoJante.public_price = largeurMini[0].public_price
                        this.infoJante.public_price_rear = largeurMaxi[0].public_price

                         //Pour la reactivité des elements
                        //https://fr.vuejs.org/v2/guide/reactivity.html
                        this.infoJante = Object.assign({}, this.infoJante)

                        this.setOnglet(this.infoJante, 'jantesAv')



                        var resultTMPArr = hits.filter(element =>  element.diametre == this.dimensionConfigurateur && element.couleur == this.infoJante.couleur && element.offset == largeurMaxi[0].offset);
                       // console.log('resultTMPAv', resultTMPArr)

                        this.infoJante = resultTMPArr[0]

                        this.infoJante.largeurMinAv = largeurMini[0].largeur
                        this.infoJante.largeurMaxArr = largeurMaxi[0].largeur
                        this.infoJante.offsetFront = largeurMini[0].offset
                        this.infoJante.offsetRear = largeurMaxi[0].offset
                        this.infoJante.entraxe = infoChassis.entraxe
                        this.infoJante.alesage = infoChassis.alesage
                        this.infoJante.photo_1 = this.colorisDisponible.find(element => element.couleurFinale === this.$route.params.couleur).image
                        this.photoPrincipal = this.infoJante.photo_1
                        // this.public_price = largeurMini[0].public_price
                        this.infoJante.public_price = largeurMini[0].public_price
                        this.infoJante.public_price_rear = largeurMaxi[0].public_price


                        this.setOnglet(this.infoJante, 'jantesArr')
                    })
                    this.$store.commit('loader/setLoader', false)
                    this.$store.commit('loader/setLoaderPrix', false)

                }
                setTimeout(()=>{
                    console.log('this.infoJante', this.infoJante)
                    if(this.infoJante.alesage){


                        if(parseFloat(infoChassis.alesage).toString() < this.infoJante.alesage.toString()) {
                            this.bagueAlesage = 'oui'

                            this.isAccessoire = {}
                            this.isAccessoire.index = 4
                            this.isAccessoire.data = {name:'4 Bagues de centrage', price:0, reference:'EJ-accessoires', marque:"EJ-accessoires", img:BagueCentrage, quantite: 1, type:3, flux:null, buying_price: null}
                            this.isAccessoire.selected = true

                            this.choosedAccessoires[4] = this.isAccessoire
                            this.isSelectedIndex[4] = true

                            this.setOnglet(this.choosedAccessoires, 'accessoires')
                            this.setOnglet(this.isSelectedIndex, 'indexAccessoires')
                            // this.$store.commit('infoAccessoires/setAccessoire', this.choosedAccessoires  )
                            // this.$store.commit('infoAccessoires/setSelected', this.isSelectedIndex)

                        }
                        if(parseFloat(infoChassis.alesage).toString() === this.infoJante.alesage.toString()) {
                            this.bagueAlesage = 'non'

                        }
                    }else{
                        this.bagueAlesage = 'non'

                    }
                },3000)


            },
            getOptionchassis(){

                var infoChassis
                if( typeof ls.get('chassisVehiculeUtilisateur') == "string" ){
                    infoChassis = JSON.parse(ls.get('chassisVehiculeUtilisateur'))
                }
                else if(typeof ls.get('chassisVehiculeUtilisateur') == "object"){
                    infoChassis = ls.get('chassisVehiculeUtilisateur')
                }
                if(infoChassis.entraxe.includes('.'))
                {
                    infoChassis.entraxe = infoChassis.entraxe.split('.')[0]
                    ls.set('chassisVehiculeUtilisateur', infoChassis )
                }
                var OptionFront = []
                var OptionRear = []

                 Vue.axios.get('https://api.espace-jantes.com/getOptionsChassis', {params: {idChassisVehiculeUtilisateur : infoChassis.id_chassis}})
                .then((response) => {
                    // var diametre = this.$localStorage.get('diametre')
                    var diametre = this.$route.params.diametre.toString()

                    response.data.forEach(element => {
                    var wheelSizeSplit = element.wheelSize.split('x')
                    if(element.upsetType == "RearUpstep") {
                        
                        if(wheelSizeSplit[1].trim() == diametre.trim()) {
                            OptionRear.push(element)
                        }   
                    }else{
                        if(wheelSizeSplit[1].trim() == diametre.trim()) {
                            OptionFront.push(element)
                        }
                    }
                    })
                    // console.log(OptionFront)
                    this.optionFront = OptionFront
                    this.optionRear = OptionRear

                })
            },
            getImgUrl(marque){
                 try{
                  var logoUrl = require('@/assets/logosMarquesJantes/'+ marque +'.png')
                  return logoUrl
                }
              catch{
                  if(this.VUE_APP_SITE_ID === '1'){
                      return require('@/assets/logo/ej-logo.svg')
                  }
                  else if(this.VUE_APP_SITE_ID === '2'){
                      return require('@/assets/distri.svg')
                  }
              }
             },
            updateRoute(params, type) {


                var splitedUrlNew = this.$route.path.split('/')
                var splitedUrlOld = this.$route.path.split('/')
                // eslint-disable-next-line no-unused-vars
                var joinedUrl

                switch(type){
                    case 'marque':
                        splitedUrlNew[2] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.marque = params
                        break
                    case 'modele':
                        splitedUrlNew[3] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.modele = params
                        break
                    case 'diametre':
                        splitedUrlNew[4] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.diametre = params
                        break
                    case 'couleur':
                        splitedUrlNew[5] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.couleur = params
                        break
                    case 'decale':
                        splitedUrlNew[6] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.decale = params
                        break
                }

                    history.pushState(
                        {},
                        null,
                        joinedUrl
                    )
            },
            updateOnglet(oldName, newName){

                ls.get(oldName + '__infosJantes') ?  ls.set(newName + '__infosJantes', ls.get(oldName + '__infosJantes')) : null
                ls.get(oldName + '__infosPneus') ?  ls.set(newName + '__infosPneus', ls.get(oldName + '__infosPneus')) : null
                ls.get(oldName + '__modelePossibleAuCarre') ?  ls.set(newName + '__modelePossibleAuCarre', ls.get(oldName + '__modelePossibleAuCarre')) : null
                ls.get(oldName + '__modelePossibleFront') ?  ls.set(newName + '__modelePossibleFront', ls.get(oldName + '__modelePossibleFront')) : null
                ls.get(oldName + '__modelePossibleRear') ?  ls.set(newName + '__modelePossibleRear', ls.get(oldName + '__modelePossibleRear')) : null
                

            }
        },
        beforeUpdate() {
            this.configurateurJson.color = this.currentColor;
            this.configurateurJson.diametre = this.selected;


            //for footer's watcher to detect object changes
            this.configurateurJson = Object.assign({}, this.configurateurJson)
            // console.log(this.configurateurJson)
        },
        computed:{
            ...mapState({
                Next: state => state.footerNext.Next,
                choosedAccessoires: state => state.infoAccessoires.SelectedAccessoires,
                isSelectedIndex: state => state.infoAccessoires.isSelected,
                loaderStatus: state => state.loader.isLoader

            })
        },

    }
    </script>

<style scoped>

        .border {
            border: 1px solid black;
        }

        .dropdown {
            -webkit-appearance: menulist !important; /* override vuetify style */
            -moze-appearance: menulist !important; /* override vuetify style */
        }
        .couleurBlack{
            background-color:black !important
        }
        .couleursilver{
            background-color:silver !important;
        }
        .couleurOr{
            background-color:gold !important;
        }
        .couleurRed{
            background-color:red !important;
        }
        .panel-color-step {
            transition: all ease-in-out 0.2s;
            width: 40px;
            height: 0;
            border-radius: 10px 100px / 120px;
            padding: 0 0 50% 0 !important;
            border-radius: 100%;
            margin: 0 auto;
        }
        .panel-color-step:hover {
            transform: scale(1.1);
        }

        /*.magnifier-box.vertical::before {*/
        /*    content: url('/img/ej-logo-baseline.e4a92426.svg');*/
        /*    position: absolute;*/
        /*    !* width: 50%; *!*/
        /*    !*transform: translateY(-50%);*!*/
        /*    width: 30%;*/
        /*    opacity: 0.5;*/
        /*    bottom: 0;*/
        /*    }*/

        /*.v-image.v-responsive.mx-auto.mt-12.theme--light::before {*/
        /*    content: url('/img/ej-logo-baseline.e4a92426.svg');*/
        /*    position: absolute;*/
        /*    width: 30%;*/
        /*    opacity: 0.5;*/
        /*    bottom: 0;*/
        /*    right: 40%;*/
        /*    !*transform: translateY(-50%);*!*/
        /*}*/
        /*.v-image.v-responsive.magnifier-box.vertical.theme--light::before {*/
        /*    content: url('/img/ej-logo-baseline.e4a92426.svg');*/
        /*    position: absolute;*/
        /*    !* width: 50%; *!*/
        /*    width: 30%;*/
        /*    opacity: 0.5;*/
        /*    bottom: 0;*/
        /*    right: 40%;*/
        /*    !*transform: translateY(-50%);*!*/
        /*}*/
        /*canvas.mouse-cover-canvas::before {*/
        /*    content: url('/img/ej-logo-baseline.e4a92426.svg');*/
        /*    position: absolute;*/
        /*    !* width: 50%; *!*/
        /*    width: 30%;*/
        /*    opacity: 0.5;*/
        /*    bottom: 0;*/
        /*    right: 40%;*/
        /*    transform: translateY(-50%);*/
        /*}*/

        .ej-top-0 >>> .v-overlay__content {
            top: 10% !important;
            position: absolute;
        }

        .ej-watermark::before {
            content: url('/img/ej-logo-baseline.e4a92426.svg');
            position: absolute;
            /* width: 50%; */
            transform: translateY(-50%);
            width: 50%;
            opacity: 0.5;
            bottom: 0;
            right: 27%;
        }
        /*.ej-watermark.color::before {*/
        /*    content: url('/img/ej-logo-baseline.e4a92426.svg');*/
        /*    position: absolute;*/
        /*    !* width: 50%; *!*/
        /*    transform: translateY(0);*/
        /*    width: 50%;*/
        /*    opacity: 0.5;*/
        /*    bottom: 0;*/
        /*    right: 27%;*/
        /*}*/

        .dj-watermark::before {
            content: url('https://cpt-distri-jantes.s3.eu-west-3.amazonaws.com/dj-watermark.svg');
            position: absolute;
            transform: translateY(-50%);
            width: 50%;
            opacity: 0.5;
            bottom: 0;
            right: 27%;
        }


        @media (min-width: 1250px) and (max-width:1400px) {
            .fontSize{
                font-size: 9px!important;
            }
        }
        @media (max-width: 1250px){
            .fontSize{
                font-size: 11px!important;
            }
        }
        @media (min-width:1400px) {
            .fontSize{
                font-size: 11px!important;
            }
        }


</style>
