

const state = () =>({
    isLoader:'',
    isLoaderPrix:''
})



const mutations = {
    setLoader(state, config){
        //console.log('setLoader', config)
        state.isLoader = config
    },
    setLoaderPrix(state, config){
        state.isLoaderPrix = config
    }
}


const actions = {

}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}