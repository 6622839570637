    <template>
        <v-app   fluid >

            <HomepageHeaderEj class="backgroud_gradient" @connect="isConnected" v-if="VUE_APP_SITE_ID === '1'"/>
            <HomepageHeaderDj class="backgroud_gradient" @connect="isConnected" v-else-if="VUE_APP_SITE_ID === '2'"/>

            <v-container fluid class="bc_white" >
                <v-row>
                    <v-col fluid offset-lg="1" lg="3" class="bc_white  d-flex align-center">
                        <span class=" font-weight-bold
                                    text-xl-h2
                                    text-lg-h4
                                    text-md-h5
                                    text-sm-h6
                                    text-h6" :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">Mon compte</span>
                    </v-col>
                    <v-col cols="1" v-if="isRevendeur === true">
                        <v-img src="@/assets/revendeur.png"/>
                    </v-col>
                </v-row>
                <v-img v-if="connected === '' && VUE_APP_SITE_ID === '1' " src="@/assets/loader.gif" style="max-width: 30%; margin: auto" alt='Espace-Jantes - Chargement Jantes' title="Espace-Jantes - Chargement Jantes"/>
                <v-img v-if="connected === '' && VUE_APP_SITE_ID === '2'" src="@/assets/loaderDJ.gif" style="max-width: 20%; margin: auto" alt='Distri-Jantes - Chargement Jantes' title="Distri-Jantes - Chargement Jantes"/>
                <Register v-if="connected === false"  @connect="isConnected" />
                <Connected v-if="connected"/>

            </v-container>

            <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
            <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

        </v-app>
    </template>


<script>
    import HomepageHeaderEj from "../headers/HomepageHeaderEj";
    import HomepageHeaderDj from "../headers/HomepageHeaderDj";
    import HomepageFooterEj from "../footer/HomepageFooterEj";
    import HomepageFooterDj from "../footer/HomepageFooterDj";
    import Register from "./Register";
    import Connected from "./Connected";
    //
    // import axios from "axios";
    import {mapState} from 'vuex'



    export default {
        name: "Register.vue",
        components: {
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,
            Register,
            Connected
        },
        data: () => ({
           connected: '',
            VUE_APP_SITE_ID:null

       }),
        methods:{
            isConnected: function (e) {

            if(e === true){
                this.connected = true
            }else if(e === false){
                this.connected = false
            }
        }
        },
        created() {

            let token = this.getToken
            let userId = this.getUserId
            let isRevendeur = this.isRevendeur
            let waitingRevendeur = this.waitingRevendeur
            let tauxRemise = this.tauxRemise

            this.$store.dispatch('auth/AUTH_REQUEST', { token, userId, isRevendeur, waitingRevendeur, tauxRemise }).then(() => {

                this.$emit('connect', true)
                this.connected = true
            }).catch(()=>{
                this.connected = false
            })

        },
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
            if( process.env.NODE_ENV.trim() !== "development") {
                window.dataLayer.push({
                    'event': 'Pageview',
                    'pagePath': '/account',
                    'pageTitle': 'Mon compte'
                });
            }
        },
        updated() {
            // console.log('updated')
            // console.log('isRevendeur', this.isRevendeur)

        },
        computed: {

            ...mapState({
                getToken: state => state.auth.token,
                getUserId: state => state.auth.userId,
                isRevendeur: state => state.auth.isRevendeur,
                waitingRevendeur: state => state.auth.waitingRevendeur,
                tauxRemise: state => state.auth.tauxRemise
            })

        },
    }
</script>

<style scoped>

    .dark--text-lg /deep/ label {
        font-size: 13px;
        color: black;
    }
    .dark--text-xl /deep/ label {
        font-size: 16px;
        color: black;
    }

</style>
