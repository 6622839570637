import Vue from 'vue'
import Vuex from 'vuex'
import infoJantes from './modules/infoJantes'
import infoPneus from './modules/infoPneus'
import typePayement from './modules/typePayement'
import infoAccessoires from './modules/infoAccessoires'
import infoVehicule from './modules/infoVehicule'
import infoCommande from './modules/infoCommande'
import footerNext from './modules/footerNext'
import auth from './modules/auth'
import addr_commande from './modules/addr_commande'
import catalogueJantes from './modules/catalogueJantes'
import loader from './modules/loader'
import refChecking from './modules/refChecking'
import filtreListingSave from './modules/filtreListingSave'
import nbrCart from './modules/nbrCart'
import detailCommande from './modules/detailCommande'
import SavePage from './modules/SavePage'


import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)


export default new Vuex.Store({
    modules: {
        auth,
        infoJantes,
        infoPneus,
        infoAccessoires,
        infoVehicule,
        infoCommande,
        footerNext,
        addr_commande,
        catalogueJantes,
        typePayement,
        loader,
        refChecking,
        filtreListingSave,
        nbrCart,
        detailCommande,
        SavePage,
    },
    plugins: [
        createPersistedState({
            storage: {
                getItem: key => ls.get(key),
                setItem: (key, value) => ls.set(key, value),
                removeItem: key => ls.remove(key)
            }
        })
    ],

    // plugins: [createPersistedState()]
})
