

const state = () =>({
    SelectedPneuAv:'',
    SelectedPneuArr:'',
    monteDecaPneu: false
})



const mutations = {
    setPneu(state, config){

        if (config.Av) {
            state.SelectedPneuAv = config.Av
        }
        if (config.Arr) {
            state.SelectedPneuArr = config.Arr
        }
    },
    setMonteDeca(state, config){
        state.monteDecaPneu = config
    },

    deletePneu: state => {
        state.SelectedPneuAv = ''
        state.SelectedPneuArr = ''
        state.monteDecaPneu = false
    },
}


const actions = {

}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}