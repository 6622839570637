<template>

    <v-content id="app" fluid >


        <v-col cols="12" class="py-0" :class="{'pa-0':this.$route.name === 'Home'}" >

            <v-row>
                <v-col offset-xl="1" offset-lg="1" offset-md="0" offset="1"  cols="6"  md="5" >
                    <router-link title='Distri-Jantes.com : Des jantes de qualité pour tous - Pneus et Accessoires' :to="{name: 'Home' }">
                        <v-img style="max-width: 50%" src="@/assets/logo/dj-logo.svg"/>
                    </router-link>
                </v-col>
    <!--            <v-col  class="d-flex align-center bc_darker" v-if="$vuetify.breakpoint.mdAndUp">-->
                <v-col offset-lg="1" xl="5" lg="5" md="7" cols="6"  class="d-flex align-center " :class="{'bc_orange': this.$route.name !== 'Home'}" v-if="$vuetify.breakpoint.mdAndUp">
                    <v-col offset="1" cols="3">
                        <router-link title='Contactez-nous - Distri-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'ContactUs'}" class="no_deco ">
                            <v-btn class=" pa-0 " text style="color:white;" >
                                <span class="font-weight-bold
                                            text-xl-h5
                                            text-lg-h6
                                            text-md-body-1"
                                        style="text-transform: capitalize;">Contact</span>
                            </v-btn>
                        </router-link>
                    </v-col>
                    <v-col cols="2">
                        <router-link title='Contactez-nous - Distri-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'FAQ'}" class="no_deco ">
                            <v-btn class=" pa-0 " text style="color:white;" >
                                <span class="font-weight-bold
                                            text-xl-h5
                                            text-lg-h6
                                            text-md-body-1"
                                        style="text-transform: capitalize;">Aide</span>
                            </v-btn>
                        </router-link>
                    </v-col>
                    <v-col cols="4">
                        <router-link title='Mon compte - Distri-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'Account'}" class="no_deco " >
                            <v-btn class="font-weight-bold pa-0"  text style="color:white;font-size: large;text-transform: capitalize;" @click="isConnected" >
                                <span class="font-weight-bold
                                            text-xl-h5
                                            text-lg-h6
                                            text-md-body-1"
                                        style="text-transform: capitalize;">Mon Compte</span>
                            </v-btn>
                        </router-link>
                    </v-col>
                    <v-col cols="3">
<!--                        text-align: -webkit-right;-->
                        <router-link title='Mon panier - Distri-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'Panier'}" class="no_deco"  >

                                <v-img src="@/assets/cartDJ.svg" style="position:relative;" :style="[$vuetify.breakpoint.xl ? {'max-width' : '30%'} :
                                                                                                $vuetify.breakpoint.lg && this.$route.name === 'Home' ? {'margin' : 'auto', 'margin-right': '0', 'max-width' : '40%'} :
                                                                                                $vuetify.breakpoint.lg ? {'max-width' : '40%'} :
                                                                                                $vuetify.breakpoint.md ? {'max-width' : '45%'} : '']">

                                    <span v-if="nbrCart > 0" style="color: white;
                                                position: absolute;
                                                background-color: #de3d00;
                                                border: 1px solid azure;
                                                border-radius: 20px;
                                                width: 50%;
                                                text-align: center;"
                                          class="font-weight-bold">{{ nbrCart }}</span>

                                </v-img>

                        </router-link>
                    </v-col>
                </v-col>


                <v-col cols="5" class="text-right bc_orange d-flex align-center justify-end" v-if="$vuetify.breakpoint.smAndDown">

                    <router-link title='Mon compte - Distri-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'Account'}" class="no_deco " >
                        <v-col  v-if="$vuetify.breakpoint.sm">
                            <v-icon  color="white" @click="isConnected" large>mdi mdi-account-circle</v-icon>
                        </v-col>
                        <v-icon v-if="$vuetify.breakpoint.xs" color="white" @click="isConnected" >mdi mdi-account-circle</v-icon>
                    </router-link>

                    <router-link title='Mon panier - Distri-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'Panier'}" class="no_deco " >
                            <v-btn text small>
                                <v-img src="@/assets/cart.svg" style="position:relative" :style="[$vuetify.breakpoint.sm ? {'max-width' : '85%'} :
                                                                                                $vuetify.breakpoint.xs ? {'max-width' : '75%'} : '']">
                                    <span v-if="nbrCart > 0" style="color: white;
                                                position: absolute;
                                                background-color: #de3d00;
                                                border: 1px solid azure;
                                                border-radius: 20px;
                                                width: 50%;
                                                left:0;
                                                text-align: center;"
                                          class="font-weight-bold">{{ nbrCart }}</span>
                                </v-img>
                            </v-btn>
                    </router-link>

                    <v-col cols="4" v-if="$vuetify.breakpoint.sm">
                        <v-icon  @click="showMenu" color="white" large>mdi mdi-menu</v-icon>
                    </v-col>
                    <v-icon v-if="$vuetify.breakpoint.xs" @click="showMenu" color="white" >mdi mdi-menu</v-icon>

                </v-col>
            </v-row>
        <!-- End Header -->

        </v-col>




        <v-content v-if="displayMenu" class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">
            <v-navigation-drawer v-if="$vuetify.breakpoint.smAndDown" v-model="displayMenu" hide-overlay temporary fixed  width="60%" style=" top:0; left:0; overflow-y:scroll;">
                <v-col cols="11"  class="text-right" >
                    <v-icon @click="displayMenu = false" color="black" large>mdi mdi-close</v-icon>
                </v-col>


                <v-col  offset="1" cols="10" class="pa-0 dj-menu-rwd">
                    <router-link title='FAQ - Distri-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'FAQ'}" class="no_deco ">
                        <v-btn class="font-weight-bold"  width="100%"   text style="text-transform: capitalize;">Aide</v-btn>
                    </router-link>
                </v-col>
                <v-col  offset="1" cols="10" class="pa-0 dj-menu-rwd">
                    <router-link title='Livraison - Distri-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'Livraison'}" class="no_deco ">
                        <v-btn class="font-weight-bold"  width="100%"   text style="text-transform: capitalize;">Livraison</v-btn>
                    </router-link>
                </v-col>

                <v-col  offset="1" cols="10" class="pa-0 dj-menu-rwd">
                    <router-link title='Contactez-nous - Distri-Jantes.com - Jantes, Pneus & Accessoires' :to="{ name: 'ContactUs'}" class="no_deco ">
                        <v-btn class="font-weight-bold"  width="100%"   text style="text-transform: capitalize;">Contact</v-btn>
                    </router-link>
                </v-col>

            </v-navigation-drawer>

        </v-content>
    </v-content>

</template>

<script>

import axios from "axios";
import {mapState} from "vuex";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

export default {
    name: "HomepageHeaderDj",
    data: () => ({

        displayMenu: false,
        drawer:false,
        nbrCartCounter: 0

    }),
    methods: {
        showBreakpoint:function(){

        },
        showMenu:function(){
            console.log('displayMenu')
            this.displayMenu = !this.displayMenu
        },
        isConnected:function () {
            const myLoginRoutine = user => new Promise ((resolve, reject) => {
                axios.get('https://api.espace-jantes.com/verificationToken', {
                    headers: {
                        Authorization: 'Bearer ' + user.token,
                    }
                })
                    .then(resp => {
                        resolve(resp)
                    })
                    .catch(err => {
                        // localStorage.removeItem('user-token')
                        ls.remove('user-token')
                        reject(err)
                    })
            })

            // let token = localStorage.getItem("user-token")
            let token = ls.get("user-token")

            myLoginRoutine({ token }).then(() => {
                this.$emit('connect', true)
            })
        }
    },
    mounted() {

        if((JSON.stringify(this.SelectedJanteAv) === JSON.stringify(this.SelectedJanteArr)) && JSON.stringify(this.SelectedJanteAv) !== '""' ) {

            if(this.monteDecaJante) {
                if (JSON.stringify(this.SelectedJanteAv.largeurMinAv) === JSON.stringify(this.SelectedJanteAv.largeurMaxArr)) {
                    // this.nbrCart ++
                    this.nbrCartCounter ++
                    this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)

                }
                else if (JSON.stringify(this.SelectedJanteAv.largeurMinAv) !== JSON.stringify(this.SelectedJanteAv.largeurMaxArr)) {
                    // this.nbrCart += 2
                    this.nbrCartCounter += 2
                    this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                }
            }
            else {
                // this.nbrCart ++
                this.nbrCartCounter ++
                this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
            }
        }
        else if(this.SelectedJanteAv && this.SelectedJanteArr) {

            this.nbrCartCounter += 2
            this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
        }


        if((JSON.stringify(this.SelectedPneuAv) === JSON.stringify(this.SelectedPneuArr)) && JSON.stringify(this.SelectedPneuAv) !== '""' ) { //Si pneu avant === pneu arriere

            if(this.monteDecaPneu === true) { //Si monte décalée
                if (this.SelectedPneuAv.HBV === this.SelectedPneuAv.HBVREAR) { //Si pneu avant  HBV === pneu arriere HBVREAR => pneu X4
                    // this.nbrCart ++
                    this.nbrCartCounter ++
                    this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                }
                else if (this.SelectedPneuAv.HBV !== this.SelectedPneuAv.HBVREAR) { //Si pneu avant  HBV !== pneu arriere HBVREAR => pneuAvant X2 && pneuArriere X2
                    // this.nbrCart += 2
                    this.nbrCartCounter += 2
                    this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                }
            }
            else{ //Si monte par 4
                // this.nbrCart ++
                this.nbrCartCounter ++
                this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
            }
        }
        else if(this.SelectedPneuAv && this.SelectedPneuArr) { //Sinon si pneu avant !== pneu arriere
            this.nbrCartCounter += 2
            this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
        }

        if(this.SelectedAccessoires) { //Si accessoires

            Object.values(this.SelectedAccessoires).forEach((accessoire) => {
                if (accessoire != null) {

                    this.nbrCartCounter ++
                    this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                }

            })
        }
    },

    computed: {

        ...mapState({
            SelectedJanteAv : state => state.infoJantes.SelectedJanteAv,
            SelectedJanteArr : state => state.infoJantes.SelectedJanteArr,
            SelectedPneuAv : state => state.infoPneus.SelectedPneuAv,
            SelectedPneuArr : state => state.infoPneus.SelectedPneuArr,
            monteDecaJante : state => state.infoJantes.monteDecaJante,
            monteDecaPneu : state => state.infoPneus.monteDecaPneu,
            SelectedAccessoires : state => state.infoAccessoires.SelectedAccessoires,
            nbrCart : state => state.nbrCart.nbrCart,
        })

    },
};
</script>

<style scoped>
.text-caption-custom {
    font-size: 0.7rem;
    line-height: 0.8rem;
}
.garantie .v-icon {
    background: #fff;
    border-radius: 50px;
    color:rgb(35, 45, 77);
    padding:0.35rem
}
.dj-menu-rwd {
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
.dj-menu-rwd:after {
    content:"";
    position: absolute;
    top:100%;
    right: 0;
    bottom:0;
    left:0;
    background:#FC5C1F;
    height:2px;
}
@media (min-width: 960px) and (max-width:1264px) {
    .text-caption-custom {
    font-size: 0.6rem;
    line-height: 0.7rem;
}
}
</style>
