<template>
    <v-app class="backgroud_gradient">

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>

            <v-col class="bc_white pa-0">
                <v-container v-if="$vuetify.breakpoint.mdAndUp" >

                    <v-row class="text-justify">
                        <v-col class="text-justify" v-if="VUE_APP_SITE_ID === '1'" >
                            <h1 v-if="lastType === 'voiture'" style="font-weight: bold;padding-bottom: 2%; text-align:center;"  :class="{'headline': $vuetify.breakpoint.lg, 'display-1': $vuetify.breakpoint.xl}">TROUVEZ VOS JANTES ALU POUR VOTRE  {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">Depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} POUCES</span> À PRIX RÉDUIT AVEC ESPACE JANTES</h1>
                            <h1 v-else style="font-weight: bold;padding-bottom: 2%; text-align:center;"  :class="{'headline': $vuetify.breakpoint.lg, 'display-1': $vuetify.breakpoint.xl}">TROUVEZ VOS JANTES ALU {{catalogue_marque}} POUR VOTRE VEHICULE À PRIX RÉDUIT AVEC ESPACE JANTES</h1>
                            <p v-if="lastType === 'voiture'" class="font-weight-bold headline">Choisissez vos jantes pour votre {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} pouces</span> :</p>
                            <p v-else class="font-weight-bold headline">Choisissez vos jantes {{catalogue_marque}} :</p>
                            <p><span v-if="lastType === 'voiture'">Vous souhaitez modifier l'allure de votre  {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} pouces</span> ?</span> Espace Jantes vous propose une offre très complète de jantes  {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} pouces</span> compatibles avec votre véhicule ! Découvrez les descriptifs de nos modèles, qui sont disponibles aux meilleurs prix ! Que vous souhaitiez un look sportif, bling-bling, original ou encore replica-constructeur pour votre <span v-if="lastType === 'voiture'">  {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} pouces</span></span><span v-else>véhicule</span> : Espace Jantes dispose d'un stock de jantes {{catalogue_marque}} mis à jour quotidiennement ! Grâce à vos nouvelles jantes, vous pourrez même améliorer les performances de votre <span v-if="lastType === 'voiture'">  {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} pouces</span></span><span v-else>véhicule</span>.</p>
                        </v-col>
                        <v-col class="text-justify" v-if="VUE_APP_SITE_ID === '2'" >
                            <h1 v-if="lastType === 'voiture'" style="font-weight: bold;padding-bottom: 2%; text-align:center;"  :class="{'headline': $vuetify.breakpoint.lg, 'display-1': $vuetify.breakpoint.xl}">LES MEILLEURES JANTES ALU POUR VOTRE {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">Depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} POUCES</span> AVEC DISTRI JANTES</h1>
                            <h1 v-else style="font-weight: bold;padding-bottom: 2%; text-align:center;"  :class="{'headline': $vuetify.breakpoint.lg, 'display-1': $vuetify.breakpoint.xl}">LES MEILLEURES JANTES ALU POUR VOTRE {{catalogue_marque}} AVEC DISTRI JANTES</h1>
                            <p v-if="lastType === 'voiture'" class="font-weight-bold headline">Trouvez vos jantes pour votre {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} pouces</span> :</p>
                            <p v-else class="font-weight-bold headline">Trouvez vos jantes {{catalogue_marque}} :</p>
                            <p><span v-if="lastType === 'voiture'">Bénéficiez du meilleur rapport qualité-prix chez Distri Jantes et découvrez une offre très large de jantes compatibles avec votre  {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} pouces</span> </span> Notre stock est actualisé quotidiennement pour que vous puissiez trouver le meilleur choix ! Améliorez le look et les performances de votre  {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} pouces</span> véhicule avec vos nouvelles jantes alu !</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="5">
                            <h2>Votre véhicule</h2>
                            <p style="padding-bottom: 2%;" class="headline" :class="{'tc_turquoise':VUE_APP_SITE_ID === '1', 'tc_orange' :VUE_APP_SITE_ID === '2'}">Modèles {{decoded(urlMarque)}} compatibles avec nos jantes</p>
                            <!--                        {{$route.name}}-->

                            <v-container style="text-align: center; background-color:#e5e5e5;" v-if="$route.name !== 'catalogueDimension'">

                                <v-row v-if="$route.name === 'catalogue' ">
                                    <v-col cols="4" v-for="modele in ModeleUnique" :key="modele.nom"> <!-- On est dans catalogue marque et on va vers catalogue modele -->
                                        <span v-if="lastType === 'voiture'" @click="toStoreVoiture({modele :modele})"  style="cursor: pointer">
                                        {{ modele.nom }}
                                    </span>
                                        <span v-else style="cursor: pointer">
                                        {{ decoded(modele) }}
                                    </span>
                                    </v-col>
                                </v-row>


                                <v-row v-if="$route.name === 'catalogueModele' && lastType === 'voiture'">
                                    <v-col cols="4" v-for="type in TypeUnique" :key="type.nom"> <!-- type -->
                                        <span @click="toStoreVoiture({type :type})"  style="cursor: pointer">
                                        Depuis {{ type.startDate }}
                                    </span>
                                    </v-col>
                                </v-row>

                                <!-- motorisation -->
                                <!-- <v-row v-if="$route.name === 'catalogueType'">
                                    <v-col cols="4" v-for="modele in ModeleUnique" :key="modele.id">
                                        <span @click="toStoreVoiture({motorisation :modele})"  style="cursor: pointer">
                                            {{ modele }}
                                        </span>
                                    </v-col>
                                </v-row> -->


                                <v-row v-if="$route.name === 'catalogueMotorisation'">
                                    <v-col cols="4" v-for="modele in ModeleUnique" :key="modele.id">
                                    <span @click="toStoreVoiture({dimension :modele})"  style="cursor: pointer">
                                        {{ modele }}
                                    </span>
                                    </v-col>
                                </v-row>


                                <v-row v-if="$route.name === 'catalogueType'">
                                    <v-col cols="4" v-for="dimension in dimensions" :key="dimension.id">
                                   <span @click="toStoreVoiture({dimension :dimension})"  style="cursor: pointer">
                                        {{dimension}}
                                    </span>
                                    </v-col>
                                </v-row>


                            </v-container>

                            <v-row v-if="$route.name === 'catalogueDimension'">
                                <p>Nous possédons les jantes pour votre véhicule, vous pouvez les retrouver via le configurateur de la page d'accueil</p>
                                <RouterLink :to="{name:'Home'}" class="no_deco">
                                    <v-btn>Aller au configurateur</v-btn>
                                </RouterLink>
                            </v-row>
                        </v-col>
                        <v-col cols="5 offset-2">
                            <v-row>
                                <v-col cols="3" class="pb-0">
                                    <v-content v-if="catalogue_logo">
                                        <v-img v-if="catalogue_logo.slice(16, -4).toUpperCase() === catalogue_marque" :src="require('@/assets/logo3x/'+catalogue_marque.toLowerCase()+'.png')" width="100%" :alt='catalogue_marque' :title="catalogue_marque"></v-img>
                                    </v-content>
                                    <!--                                <v-img  v-else :src="require('@/assets/ConfigurateurHome.jpg')" width="100%"></v-img>-->
                                </v-col>
                                <v-col cols="9" class="align-self-center">
                                    <p class="font-weight-bold pa-0"
                                       v-if="VUE_APP_SITE_ID === '1'"
                                       :class="{'caption': $vuetify.breakpoint.xs,
                                    'headline': $vuetify.breakpoint.sm,
                                    'headline': $vuetify.breakpoint.md,
                                    'headline': $vuetify.breakpoint.lg,
                                    'headline': $vuetify.breakpoint.xl,}">COMMANDEZ VOS JANTES {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">DEPUIS {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} POUCES</span> CHEZ ESPACE-JANTES ET SOYEZ SEREIN !</p>
                               <p class="font-weight-bold pa-0"
                                       v-if="VUE_APP_SITE_ID === '2'"
                                       :class="{'caption': $vuetify.breakpoint.xs,
                                    'headline': $vuetify.breakpoint.sm,
                                    'headline': $vuetify.breakpoint.md,
                                    'headline': $vuetify.breakpoint.lg,
                                    'headline': $vuetify.breakpoint.xl,}">Commander chez Distri-jantes, c’est bénéficier du meilleur choix et d’un service de qualité.</p>
                                </v-col>
                            </v-row>
                            <v-row>
                                <p v-if="VUE_APP_SITE_ID === '1'" style="border:solid #1840da; padding: 5% 10%;">Les jantes  {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} pouces</span> que vous choisirez pour votre véhicule vous seront livrées gratuitement à l'adresse de votre choix, grâce à notre réseau de professionnels réputés pour leur fiabilité. Toutes nos jantes {{catalogue_marque}} sont contrôlées avant leur expédition. Chez Espace Jantes, pas de mauvaise surprise ! Notre service commercial est disponible par téléphone pour vous conseiller et vous accompagner dans votre commande! Vous êtes satisfait ou remboursé ! Vous disposez d'un délai de rétractation de 15 jours pour renvoyer vos jantes  {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} pouces</span>.</p>
                                <p v-if="VUE_APP_SITE_ID === '2'" style="border:solid #FC5C1F; padding: 5% 10%;">Vos jantes vous sont livrées gratuitement chez vous ou à l’adresse de votre choix grâce à notre réseau de professionnels partout en France. Nos produits sont contrôlés avant envoi pour vous garantir la meilleure qualité. Chez nous, pas de mauvaise surprise !</p>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-container>

                <v-container v-if="$vuetify.breakpoint.smAndDown" >
                    <v-col v-if="VUE_APP_SITE_ID === '1'" class="text-justify">
                        <h1 style="font-weight: bold;padding-bottom: 2%; text-align:center;"  :class="{'title': $vuetify.breakpoint.smAndDown,'headline': $vuetify.breakpoint.lg, 'display-1': $vuetify.breakpoint.xl}">TROUVEZ VOS JANTES ALU POUR VOTRE  {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">DEPUIS {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} POUCES</span> À PRIX RÉDUIT AVEC ESPACE JANTES</h1>
                        <br>
                        <p><span v-if="lastType === 'voiture'">Vous souhaitez modifier l'allure de votre {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} pouces</span> ?</span> Espace Jantes vous propose une offre très complète de jantes {{decoded(urlMarque)}} compatibles avec votre véhicule ! Découvrez les descriptifs des nos modèles, qui sont disponibles aux meilleurs prix ! Que vous souhaitiez un look sportif, bling-bling, original ou encore replica-constructeur pour votre <span v-if="lastType === 'voiture'"> {{decoded(urlMarque)}}</span><span v-else>véhicule</span> : Espace Jantes dispose d'un stock de jantes {{decoded(urlMarque)}} mis à jour quotidiennement ! Grâce à vos nouvelles jantes, vous pourrez même améliorer les performances de votre <span v-if="lastType === 'voiture'"> {{decoded(urlMarque)}}</span><span v-else>véhicule</span>.</p>
                    </v-col>
                    <v-col v-if="VUE_APP_SITE_ID === '2'" class="text-justify">
                        <h1 style="font-weight: bold;padding-bottom: 2%; text-align:center;"  :class="{'title': $vuetify.breakpoint.smAndDown,'headline': $vuetify.breakpoint.lg, 'display-1': $vuetify.breakpoint.xl}">LES MEILLEURES JANTES ALU POUR VOTRE  {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">DEPUIS {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} POUCES</span> AVEC DISTRI JANTES</h1>
                        <br>
                        <p><span v-if="lastType === 'voiture'">Bénéficiez du meilleur rapport qualité-prix chez Distri Jantes et découvrez une offre très large de jantes compatibles avec votre  {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} pouces</span> </span> Notre stock est actualisé quotidiennement pour que vous puissiez trouver le meilleur choix ! Améliorez le look et les performances de votre  {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} pouces</span> véhicule avec vos nouvelles jantes alu !</p>
                    </v-col>
                    <v-col cols="12">

                        <h2  v-if="lastType === 'voiture'">Votre véhicule</h2>
                        <h2 v-else>Vos jantes</h2>
                        <p style="padding-bottom: 2%;" class="headline" :class="{'tc_turquoise':VUE_APP_SITE_ID === '1', 'tc_orange' :VUE_APP_SITE_ID === '2'}">Modèles {{decoded(urlMarque)}} compatibles avec nos jantes</p>
                        <v-container style="text-align: center; background-color:#e5e5e5;" v-if="$route.name !== 'catalogueDimension'">

                            <v-row v-if="$route.name === 'catalogue' ">
                                <v-col cols="4" v-for="modele in ModeleUnique" :key="modele.nom"> <!-- On est dans catalogue marque et on va vers catalogue modele -->
                                    <span v-if="lastType === 'voiture'" @click="toStoreVoiture({modele :modele})"  style="cursor: pointer">
                                        {{ modele.nom }}
                                    </span>
                                    <span v-else style="cursor: pointer">
                                        {{ decoded(modele) }}
                                    </span>
                                </v-col>
                            </v-row>


                            <v-row v-if="$route.name === 'catalogueModele' && lastType === 'voiture'">
                                <v-col cols="4" v-for="type in TypeUnique" :key="type.nom"> <!-- type -->
                                    <span @click="toStoreVoiture({type :type})"  style="cursor: pointer">
                                        Depuis {{ type.startDate }}
                                    </span>
                                </v-col>
                            </v-row>

                            <!-- motorisation -->
                            <!-- <v-row v-if="$route.name === 'catalogueType'">
                                <v-col cols="4" v-for="modele in ModeleUnique" :key="modele.id">
                                    <span @click="toStoreVoiture({motorisation :modele})"  style="cursor: pointer">
                                        {{ modele }}
                                    </span>
                                </v-col>
                            </v-row> -->


                            <v-row v-if="$route.name === 'catalogueMotorisation'">
                                <v-col cols="4" v-for="modele in ModeleUnique" :key="modele.id">
                                    <span @click="toStoreVoiture({dimension :modele})"  style="cursor: pointer">
                                        {{ modele }}
                                    </span>
                                </v-col>
                            </v-row>


                            <v-row v-if="$route.name === 'catalogueType'">
                                <v-col cols="4" v-for="dimension in dimensions" :key="dimension.id">
                                   <span @click="toStoreVoiture({dimension :dimension})"  style="cursor: pointer">
                                        {{dimension}}
                                    </span>
                                </v-col>
                            </v-row>


                        </v-container>

                        <v-row v-if="$route.name === 'catalogueDimension'">
                            <p>Nous possédons les jantes pour votre véhicule, vous pouvez les retrouver via le configurateur de la page d'accueil</p>
                            <RouterLink :to="{name:'Home'}" class="no_deco">
                                <v-btn>Aller au configurateur</v-btn>
                            </RouterLink>
                        </v-row>
                        <v-row>
                            <v-col cols="3" class="pb-0">
                                <v-content v-if="catalogue_logo">
                                    <v-img  v-if="catalogue_logo.slice(16, -4).toUpperCase() === catalogue_marque" :src="require('@/assets/logo3x/'+catalogue_marque.toLowerCase()+'.png')" width="100%" :alt='catalogue_marque' :title="catalogue_marque"></v-img>
                                </v-content>
<!--                                <v-img  v-else :src="require('@/assets/ConfigurateurHome.jpg')" width="100%" alt='Configurateur - Espace Jantes' title="Configurateur - Espace Jantes"></v-img>-->
                            </v-col>
                            <v-col cols="9" class="align-self-center">
                                <p class="font-weight-bold pa-0"
                                   v-if="VUE_APP_SITE_ID === '1'"
                                   :class="{'caption': $vuetify.breakpoint.xs,
                                    'headline': $vuetify.breakpoint.sm,
                                    'headline': $vuetify.breakpoint.md,
                                    'headline': $vuetify.breakpoint.lg,
                                    'headline': $vuetify.breakpoint.xl,}">COMMANDEZ VOS JANTES  {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">DEPUIS {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} POUCES</span> CHEZ ESPACE-JANTES ET SOYEZ SEREIN !</p>
                            <p class="font-weight-bold pa-0"
                                   v-if="VUE_APP_SITE_ID === '2'"
                                   :class="{'caption': $vuetify.breakpoint.xs,
                                    'headline': $vuetify.breakpoint.sm,
                                    'headline': $vuetify.breakpoint.md,
                                    'headline': $vuetify.breakpoint.lg,
                                    'headline': $vuetify.breakpoint.xl,}">Commander chez Distri-jantes, c’est bénéficier du meilleur choix et d’un service de qualité.</p>
                            </v-col>
                        </v-row>
                        <v-col class="pr-0">
                            <p v-if="VUE_APP_SITE_ID === '1'" style="border:solid #1840da; padding: 5% 10%;">Les jantes  {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} pouces</span> que vous choisirez pour votre véhicule vous seront livrées gratuitement à l'adresse de votre choix, grâce à notre réseau de professionnels réputés pour leur fiabilité. Toutes nos jantes {{catalogue_marque}} sont contrôlées avant leur expédition. Chez Espace Jantes, pas de mauvaise surprise ! Notre service commercial est disponible par téléphone pour vous conseiller et vous accompagner dans votre commande! Vous êtes satisfait ou remboursé ! Vous disposez d'un délai de rétractation de 15 jours pour renvoyer vos jantes  {{decoded(urlMarque)}} {{decoded(urlModele)}} <span v-if="urlType">depuis {{urlType}}</span><span v-if="urlDimension">, {{urlDimension}} pouces</span>.</p>
                            <p v-if="VUE_APP_SITE_ID === '2'" style="border:solid #FC5C1F; padding: 5% 10%;">Vos jantes vous sont livrées gratuitement chez vous ou à l’adresse de votre choix grâce à notre réseau de professionnels partout en France. Nos produits sont contrôlés avant envoi pour vous garantir la meilleure qualité. Chez nous, pas de mauvaise surprise !</p>
                        </v-col>
                    </v-col>
                </v-container>
            </v-col>


        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "./headers/HomepageHeaderEj";
import HomepageHeaderDj from "./headers/HomepageHeaderDj";
import HomepageFooterEj from "./footer/HomepageFooterEj";
import HomepageFooterDj from "./footer/HomepageFooterDj";

    import algoliasearch from 'algoliasearch/lite';
    import 'instantsearch.css/themes/algolia-min.css';
    import SecureLS from "secure-ls";
    var ls = new SecureLS({ isCompression: false });

    import Vue from 'vue'
    import {mapState} from "vuex";
    //import axios from 'axios'
    //import VueAxios from 'vue-axios'

    export default {
        name: "CatalogueJantes",
        components: {
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,
        },
        data: () => ({
            modelesMarque: [],
            modeles:[],
            catalogue_idMarque : null,
            catalogue_marque : null,
            catalogue_logo : null,
            urlMarque:null,
            urlModele:null,
            urlType:null,
            urlDimension:null,
            modele: [],
            ModeleUnique:[],
            TypeUnique:[],
            AllModele:[],
            dimensions: [],
            VUE_APP_SITE_ID:null
        }),
        metaInfo() { 
            return { 
                title: `Jantes ${ this.catalogue_marque } - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com` + ` - Jantes, Pneus et Accessoires`),
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `Jantes ${ this.catalogue_marque } - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com` + ` - Jantes, Pneus et Accessoires`)},
                    {name: 'twitter:description', content: (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes` : `Distri Jantes` + ` est spécialisée dans la vente de jantes ${ this.catalogue_marque }. Leader sur internet et meilleurs prix garantis ! Livraison gratuite. Paiement en 4xCB.`)},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `Jantes ${ this.catalogue_marque } - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com` + ` - Jantes, Pneus et Accessoires`)},
                    {property: 'og:site_name', content: (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`)},
                    {property: 'og:type', content: 'product.group'},
                    {property: 'og:image', content: (this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``)},
                    {property: 'og:description', content: (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes` : `Distri Jantes` + ` est spécialisée dans la vente de jantes ${ this.catalogue_marque } . Leader sur internet et meilleurs prix garantis ! Livraison gratuite. Paiement en 4xCB.`)},
                    {name: 'robots', content: 'index,follow'} 
                ]
            }
    },
        mounted () {

             this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID

            if( process.env.NODE_ENV.trim() !== "development") {
                window.dataLayer.push({
                    'event': 'Pageview',
                    'pagePath': '/catalogue-jantes',
                    'pageTitle': 'CatalogueJantes'
                });
            }
            
            var dataSplit = (this.$route.path).split('/')
            dataSplit[2] ? this.urlMarque = dataSplit[2] : this.urlMarque = null
            dataSplit[3] ? this.urlModele = dataSplit[3] : this.urlModele = null
            dataSplit[4] ? this.urlType = dataSplit[4] : this.urlType = null
            dataSplit[5] ? this.urlDimension = dataSplit[5] : this.urlDimension = null


            // console.log('this.decoded(this.urlMarque)', this.decoded(this.urlMarque))
            Vue.axios.get('https://api.espace-jantes.com/VehiculeInfoMarqueId', {params: {marque: this.decoded(this.urlMarque)}})
                .then((responseMarqueId) => {
                    // console.log('VehiculeInfoMarqueId', responseMarqueId)

                    if(responseMarqueId.data[0].id_manufacturer !== undefined) {

                        this.$store.commit('catalogueJantes/setLastType', 'voiture')
                        // localStorage.setItem('id_marque_vehicule', responseMarqueId.data[0].id_manufacturer)
                        ls.set('id_marque_vehicule', responseMarqueId.data[0].id_manufacturer)
                        this.catalogue_marque = this.urlMarque
                // console.log('this.lastType', this.lastType)
                        if (this.lastType === 'voiture') {
                            this.catalogue_logo = '@/assets/logo3x/' + this.urlMarque.toLowerCase() + '.png';


                            Vue.axios.get('https://api.espace-jantes.com/VehiculeInfo', {params: {marqueID: responseMarqueId.data[0].id_manufacturer}})
                                .then((response) => {
                                    this.ModeleUnique = []
                                    var __modeleUnique = []

                                    response.data.forEach(element => {
                                        this.AllModele.push(element)
                                        const resultat = __modeleUnique.find(modele => modele.nom === element.modele);

                                        if (resultat == 'undefined' || resultat == undefined) {
                                            var modeleToAdd = {}
                                            modeleToAdd.nom = element.modele
                                            modeleToAdd.id = [element.id]
                                            __modeleUnique.push(modeleToAdd)
                                        } else {
                                            __modeleUnique.forEach(modele => {
                                                if (modele.nom === element.modele) {
                                                    modele.id.push(element.id)
                                                }
                                            })
                                        }
                                    })
                                    this.ModeleUnique = __modeleUnique;
                                    // console.log('modeleunique',this.ModeleUnique)
                                    if(this.$route.name === 'catalogueModele' || this.$route.name === 'catalogueType') {
                                        // console.log(' this.AllModele',  this.AllModele[0])
                                        this.AllModele.forEach(element => {

                                            if(element.modele == this.urlModele){

                                                element.startDate = element.date_construction_from.split('/')[2].trim()
                                                var findElement = this.TypeUnique.find( typeUniqueElement => element.startDate == typeUniqueElement.startDate)
                                                if(findElement == undefined || findElement == 'undefined') {
                                                    this.TypeUnique.push(element)
                                                }
                                            // console.log('this.TypeUnique', this.TypeUnique)
                                            }
                                        })
                                    }
                                    if(this.$route.name === 'catalogueType') {
                                        var arrayKtype = []
                                        this.dimensions = []
                                        Vue.axios.get('https://api.espace-jantes.com/carModeles', {params: {modeleID : this.TypeVoiture.id_modele}}) // Recuperation des Ktypes
                                            .then((response) => {
                                                response.data.forEach(element => {
                                                    arrayKtype.push(element.Ktype)
                                                })
                                                return Vue.axios.get('https://api.espace-jantes.com/infoChassis', {params: {listKtype : arrayKtype}})
                                            })
                                            .then((responseKtype) => {
                                                responseKtype.data.forEach(element => {
                                                    var wheelSizeSplit = element.wheelSize.split('x')
                                                    if(this.dimensions.indexOf(wheelSizeSplit[1].trim()) == -1 ){
                                                        this.dimensions.push(wheelSizeSplit[1].trim()) // Ajout des dimensions
                                                    }
                                                })
                                            })
                                    }
                                })
                                .catch((error) => {
                                    console.log(error)
                                    this.$store.commit('catalogueJantes/setLastType', 'jante')

                                    if (this.lastType === 'jante'){

                                        const client = algoliasearch('IXJF8AJTH7', '27829f3918903605b2f7665097a13737');
                                        const index = client.initIndex('dev_EJ');

                                        // let marqueJantes = this.MarqueJante;
                                        index.search('', {
                                            filters:' marque: "' + this.urlMarque +'"',
                                            distinct: true,
                                            hitsPerPage: 50,
                                        })
                                            .then(({ hits }) => {
                                                this.ModeleUnique = [];

                                                let _this_modele = this.modele;
                                                hits.forEach(element => {
                                                    _this_modele.push(element.modele);
                                                })
                                                this.ModeleUnique = _this_modele;
                                                this.modele = _this_modele;
                                        });
                                    }
                                })
                        }
                    }else{
                        this.$store.commit('catalogueJantes/setLastType', 'jante')

                        if (this.lastType === 'jante'){
                            this.catalogue_marque = this.MarqueJante.toUpperCase();
                            const client = algoliasearch('IXJF8AJTH7', '27829f3918903605b2f7665097a13737');
                            const index = client.initIndex('dev_EJ');

                            // let marqueJantes = this.MarqueJante;
                            index.search('', {
                                filters:' marque: "' + this.urlMarque +'"',
                                distinct: true,
                                hitsPerPage: 50,
                            })
                                .then(({ hits }) => {
                                    this.ModeleUnique = [];
                                    let _this_modele = this.modele;

                                    hits.forEach(element => {
                                        _this_modele.push(element.modele);
                                    })
                                    this.ModeleUnique = _this_modele;
                                    this.modele = _this_modele;
                                });
                        }
                    }
                })
                .catch((error)=>{
                    console.log(error)
                    this.$store.commit('catalogueJantes/setLastType', 'jante')

                    if (this.lastType === 'jante'){

                        const client = algoliasearch('IXJF8AJTH7', '27829f3918903605b2f7665097a13737');
                        const index = client.initIndex('dev_EJ');

                        // let marqueJantes = this.MarqueJante;
                        index.search('', {
                            filters:' marque: "' + this.urlMarque +'"',
                            distinct: true,
                            hitsPerPage: 50,
                        })
                            .then(({ hits }) => {
                                this.ModeleUnique = [];

                                let _this_modele = this.modele;
                                hits.forEach(element => {
                                    _this_modele.push(element.modele);
                                })
                                this.ModeleUnique = _this_modele;
                                this.modele = _this_modele;
                                // console.log('ModeleUnique', this.ModeleUnique)
                            });
                    }
                })

        },
        watch:{
            $route (){

                var dataSplit = (this.$route.path).split('/')
                dataSplit[2] ? this.urlMarque = dataSplit[2] : this.urlMarque = null
                dataSplit[3] ? this.urlModele = dataSplit[3] : this.urlModele = null
                dataSplit[4] ? this.urlType = dataSplit[4] : this.urlType = null
                dataSplit[5] ? this.urlDimension = dataSplit[5] : this.urlDimension = null


                // console.log('this.decoded(this.urlMarque)', this.decoded(this.urlMarque))
                Vue.axios.get('https://api.espace-jantes.com/VehiculeInfoMarqueId', {params: {marque: this.decoded(this.urlMarque)}})
                    .then((responseMarqueId) => {
                        // console.log('VehiculeInfoMarqueId', responseMarqueId.data)

                        if(responseMarqueId.data[0].id_manufacturer) {

                            this.$store.commit('catalogueJantes/setLastType', 'voiture')
                            // localStorage.setItem('id_marque_vehicule', responseMarqueId.data[0].id_manufacturer)
                            ls.set('id_marque_vehicule', responseMarqueId.data[0].id_manufacturer)
                            this.catalogue_marque = this.urlMarque
                            // console.log('this.lastType', this.lastType)
                            if (this.lastType === 'voiture') {
                                this.catalogue_logo = '@/assets/logo3x/' + this.urlMarque.toLowerCase() + '.png';


                                Vue.axios.get('https://api.espace-jantes.com/VehiculeInfo', {params: {marqueID: responseMarqueId.data[0].id_manufacturer}})
                                    .then((response) => {
                                        this.ModeleUnique = []
                                        var __modeleUnique = []

                                        response.data.forEach(element => {
                                            this.AllModele.push(element)
                                            const resultat = __modeleUnique.find(modele => modele.nom === element.modele);

                                            if (resultat == 'undefined' || resultat == undefined) {
                                                var modeleToAdd = {}
                                                modeleToAdd.nom = element.modele
                                                modeleToAdd.id = [element.id]
                                                __modeleUnique.push(modeleToAdd)
                                            } else {
                                                __modeleUnique.forEach(modele => {
                                                    if (modele.nom === element.modele) {
                                                        modele.id.push(element.id)
                                                    }
                                                })
                                            }
                                        })
                                        this.ModeleUnique = __modeleUnique;
                                        // console.log('modeleunique',this.ModeleUnique)
                                        if(this.$route.name === 'catalogueModele' || this.$route.name === 'catalogueType') {
                                            // console.log(' this.AllModele',  this.AllModele[0])
                                            this.AllModele.forEach(element => {

                                                if(element.modele == this.urlModele){

                                                    element.startDate = element.date_construction_from.split('/')[2].trim()
                                                    var findElement = this.TypeUnique.find( typeUniqueElement => element.startDate == typeUniqueElement.startDate)
                                                    if(findElement == undefined || findElement == 'undefined') {
                                                        this.TypeUnique.push(element)
                                                    }
                                                    // console.log('this.TypeUnique', this.TypeUnique)
                                                }
                                            })
                                        }
                                        if(this.$route.name === 'catalogueType') {
                                            var arrayKtype = []
                                            this.dimensions = []
                                            Vue.axios.get('https://api.espace-jantes.com/carModeles', {params: {modeleID : this.TypeVoiture.id_modele}}) // Recuperation des Ktypes
                                                .then((response) => {
                                                    response.data.forEach(element => {
                                                        arrayKtype.push(element.Ktype)
                                                    })
                                                    return Vue.axios.get('https://api.espace-jantes.com/infoChassis', {params: {listKtype : arrayKtype}})
                                                })
                                                .then((responseKtype) => {
                                                    responseKtype.data.forEach(element => {
                                                        var wheelSizeSplit = element.wheelSize.split('x')
                                                        if(this.dimensions.indexOf(wheelSizeSplit[1].trim()) == -1 ){
                                                            this.dimensions.push(wheelSizeSplit[1].trim()) // Ajout des dimensions
                                                        }
                                                    })
                                                })
                                        }
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                        this.$store.commit('catalogueJantes/setLastType', 'jante')

                                        if (this.lastType === 'jante'){

                                            const client = algoliasearch('IXJF8AJTH7', '27829f3918903605b2f7665097a13737');
                                            const index = client.initIndex('dev_EJ');

                                            // let marqueJantes = this.MarqueJante;
                                            index.search('', {
                                                filters:' marque: "' + this.urlMarque +'"',
                                                distinct: true,
                                                hitsPerPage: 50,
                                            })
                                                .then(({ hits }) => {
                                                    this.ModeleUnique = [];

                                                    let _this_modele = this.modele;
                                                    hits.forEach(element => {
                                                        _this_modele.push(element.modele);
                                                    })
                                                    this.ModeleUnique = _this_modele;
                                                    this.modele = _this_modele;
                                                });
                                        }
                                    })
                            }
                        }else{
                            this.$store.commit('catalogueJantes/setLastType', 'jante')

                            if (this.lastType === 'jante'){
                                this.catalogue_marque = this.MarqueJante.toUpperCase();

                                const client = algoliasearch('IXJF8AJTH7', '27829f3918903605b2f7665097a13737');
                                const index = client.initIndex('dev_EJ');

                                // let marqueJantes = this.MarqueJante;
                                index.search('', {
                                    filters:' marque: "' + this.urlMarque +'"',
                                    distinct: true,
                                    hitsPerPage: 50,
                                })
                                    .then(({ hits }) => {
                                        this.ModeleUnique = [];
                                        let _this_modele = this.modele;

                                        hits.forEach(element => {
                                            _this_modele.push(element.modele);
                                        })
                                        this.ModeleUnique = _this_modele;
                                        this.modele = _this_modele;
                                    });
                            }
                        }
                    })
                    .catch((error)=>{
                        console.log(error)
                        this.$store.commit('catalogueJantes/setLastType', 'jante')

                        if (this.lastType === 'jante'){

                            const client = algoliasearch('IXJF8AJTH7', '27829f3918903605b2f7665097a13737');
                            const index = client.initIndex('dev_EJ');

                            // let marqueJantes = this.MarqueJante;
                            index.search('', {
                                filters:' marque: "' + this.urlMarque +'"',
                                distinct: true,
                                hitsPerPage: 50,
                            })
                                .then(({ hits }) => {
                                    this.ModeleUnique = [];

                                    let _this_modele = this.modele;
                                    hits.forEach(element => {
                                        _this_modele.push(element.modele);
                                    })
                                    this.ModeleUnique = _this_modele;
                                    this.modele = _this_modele;
                                });
                        }
                    })

            }
        },
        methods:{
            toStoreVoiture:function (obj){

                if(obj.modele){
                    // localStorage.setItem("id_modele", obj.modele.id);
                    ls.set("id_modele", obj.modele.id);
                    this.$store.dispatch('catalogueJantes/setVoiture', {modele: obj.modele.nom}).then(() => {
                        this.$router.push({name:'catalogueModele', params:{marque: this.encoded(this.catalogue_marque), modele: this.encoded(obj.modele.nom) }})
                    })
                }else if(obj.type){
                    this.$store.dispatch('catalogueJantes/setVoiture', {type: obj.type}).then(() => {
                        this.$router.push({name:'catalogueType', params:{marque: this.encoded(this.catalogue_marque), modele: this.encoded(this.ModeleVoiture), type: this.encoded(obj.type.startDate) }})
                    })
                }
                // else if(obj.motorisation){
                //     this.$store.dispatch('catalogueJantes/setVoiture', {motorisation: obj.motorisation}).then(() => {
                //         this.$router.push({name:'catalogueMotorisation', params:{marque: this.encoded(this.MarqueVoiture), modele: this.encoded(this.ModeleVoiture), type: this.encoded(this.TypeVoiture), motorisation: this.encoded(obj.motorisation) }})
                //     })
                // }
                else if(obj.dimension){
                    // this.Dimension
                    this.$store.dispatch('catalogueJantes/setVoiture', {dimension: obj.dimension}).then(() => {
                        this.$router.push({name:'catalogueDimension', params:{marque: this.encoded(this.catalogue_marque), modele: this.encoded(this.ModeleVoiture), type: this.encoded(this.TypeVoiture.startDate), dimension: this.encoded(obj.dimension) }})
                    })
                }else if(obj.idMarque){
                    this.$store.dispatch('catalogueJantes/setVoiture', {idMarque: obj.idMarque}).then(() => {
                    })
                }
            },
            encoded:function(nom){
                // console.log(nom)
                if(nom.indexOf(" ")){
                    return(nom.replace(/\s/g, "+"))
                }
                return nom
            },
            decoded:function(nom){
                if(nom){
                    if(nom.indexOf("+%2F")){
                        return(nom.replace(/\+/g, " ").replace(/%2F/g, "/"))
                    }
                     if(nom.indexOf("+")){
                        return(nom.replace(/\+/g, " "))
                    }
                }
                return nom
            },
        },
        computed: {

            ...mapState({
                MarqueVoiture: state => state.catalogueJantes.MarqueVoiture,
                ModeleVoiture: state => state.catalogueJantes.ModeleVoiture,
                TypeVoiture: state => state.catalogueJantes.TypeVoiture,
                MotorisationVoiture: state => state.catalogueJantes.MotorisationVoiture,
                DimensionVoiture: state => state.catalogueJantes.DimensionVoiture,
                idMarque: state => state.catalogueJantes.idMarque,
                MarqueJante: state => state.catalogueJantes.MarqueJante,
                lastType: state => state.catalogueJantes.lastType,
            })

        },
    }
</script>

<style scoped>

</style>
