import axios from "axios";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

import {
    AUTH_REQUEST,
    AUTH_ERROR,
    AUTH_SUCCESS,
    AUTH_LOGOUT
} from "../actions/auth";
// import { USER_REQUEST } from "../actions/user";


const state = () =>({
    // token: localStorage.getItem('user-token') || '',
    token: ls.get('user-token') || '',
    status: '',
    userId:'',
    isRevendeur:'',
    waitingRevendeur:'',
    tauxRemise:'',
})


const mutations = {
    AUTH_REQUEST: (state) => {
        state.status = 'loading'
    },
    AUTH_SUCCESS: (state, data) => {
        state.status = 'connected'
        state.token = data.token
        state.userId = data.userId
        state.isRevendeur = data.isRevendeur
        state.waitingRevendeur = data.waitingRevendeur
        state.tauxRemise = data.tauxRemise
    },
    AUTH_LOGOUT: (state) => {
        state.status = ''
        state.token = ''
        state.userId = ''
        state.isRevendeur = ''
        state.waitingRevendeur = ''
        state.tauxRemise = ''
    },
    AUTH_ERROR: (state) => {
        state.status = 'error'
        state.token = ''
        state.userId = ''
        state.isRevendeur = ''
        state.waitingRevendeur = ''
        state.tauxRemise = ''
    },

    setRevendeur: (state, data) => {
        state.isRevendeur = data
    }
}


const actions = {
    AUTH_REQUEST: ({commit}, user) => {
        return new Promise((resolve, reject) => { // The Promise used for router redirect in login
            commit(AUTH_REQUEST)

             axios.get('https://api.espace-jantes.com/verificationToken', {
                    headers: {
                        Authorization: 'Bearer ' + user.token,
                    }
                })
                    .then(resp => {
                        const token = user.token
                        const userId = user.userId
                        const isRevendeur = user.isRevendeur
                        const waitingRevendeur = user.waitingRevendeur
                        const tauxRemise = ('0' + user.tauxRemise).slice(-2) //add 0 if one digit

                        // localStorage.setItem('user-token', token) // store the token in localstorage
                        ls.set('user-token', token) // store the token in localstorage
                        commit(AUTH_SUCCESS, {token, userId, isRevendeur, waitingRevendeur, tauxRemise} )
                        // you have your token, now log in your user :)
                        // dispatch(USER_REQUEST)
                        resolve(resp)
                    })
                    .catch(err => {
                        commit(AUTH_ERROR, err)
                        // localStorage.removeItem('user-token') // if the request fails, remove any possible user token if possible
                        ls.remove('user-token') // if the request fails, remove any possible user token if possible
                        reject(err)
                    })
        })
    },
    AUTH_LOGOUT: ({commit}) => {
        return new Promise((resolve) => {
            commit(AUTH_LOGOUT)
            // localStorage.removeItem('user-token') // clear your user's token from localstorage
            ls.remove('user-token') // clear your user's token from localstorage
            resolve()
        })
    }
}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}