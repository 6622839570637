<template>
    <v-content>
        <v-row v-if="$vuetify.breakpoint.lgAndUp">
            <v-col lg="1" class="ml-8">
            </v-col>
            <v-col lg="8" class="align-self-center pl-0">
                <v-col class="py-0 pl-0 " :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}" v-for="(title, index) in titles" :key="title.id">
                    <span v-if="(index + 1) === titleStep" class="headline font-weight-bold">{{title.name}}</span>
                    <span v-if="(index + 1) === titleStep && titleStep !== 6" class="title">{{title.desc}}</span>
                    <span v-if="(index + 1) === titleStep && titleStep === 6" class="title">{{title.desc}}<span class="font-weight-bold">{{title.desc2}}</span></span>
                </v-col>
                <v-col class="py-0 pl-0 title">
                    <span  :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}"  style="font-size:17px">100% Compatibles</span> avec votre véhicule <span > {{vehiculeUtilisateur}} </span>
                </v-col>
            </v-col>
            <v-col lg="2" class="align-self-end">
                <img  :src="getImgUrl()" style="width: 70%"/>
            </v-col>

        </v-row>

<!--        <v-navigation-drawer v-if="$vuetify.breakpoint.mdAndDown " v-model="drawer" fixed permanent hide-overlay width="100%" height="50px" class="bc_lightGrey" style="top: 0; bottom: auto" >-->
        <v-navigation-drawer v-if="$vuetify.breakpoint.mdAndDown" fixed permanent hide-overlay width="100%" height="50px" class="bc_lightGrey" style="top: 0; bottom: auto" >
            <v-col class="pt-1">
                <v-row>
                    <v-col cols="6" class="pa-0">
                        <router-link v-if="(prevStep > 0)" :to="{name: 'Etape'+ prevStep, params: {marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}"  style="text-decoration: none">
                            <v-btn text class="pa-0" >
                                <v-icon large color="black">mdi mdi-arrow-left</v-icon>
                            </v-btn>
                        </router-link>
                        <router-link v-else :to="{name: 'Listing'}"  style="text-decoration: none">
                            <v-btn text class="pa-0" >
                                <v-icon large color="black">mdi mdi-arrow-left</v-icon>
                            </v-btn>
                        </router-link>
                    </v-col>
                    <v-col cols="6" class="pa-0 justify-end d-flex">
                        <router-link :to="{name: 'Listing'}"  style="text-decoration: none">
                            <v-btn text class="pa-0" >
                                <v-icon large color="black">mdi mdi-close</v-icon>
                            </v-btn>
                        </router-link>
                    </v-col>
                </v-row>
            </v-col>
        </v-navigation-drawer>

        <v-col v-if="$vuetify.breakpoint.mdAndDown">
            <v-row>
                <v-col cols="6" class="pa-0">
                    <router-link v-if="(prevStep > 0)" :to="{name: 'Etape'+ prevStep, params: {marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}"  style="text-decoration: none">
                        <v-btn text class="pa-0" >
                            <v-icon large color="black">mdi mdi-arrow-left</v-icon>
                        </v-btn>
                    </router-link>
                    <router-link v-else :to="{name: 'Listing'}"  style="text-decoration: none">
                        <v-btn text class="pa-0" >
                            <v-icon large color="black">mdi mdi-arrow-left</v-icon>
                        </v-btn>
                    </router-link>
                </v-col>
                <v-col cols="6" class="pa-0 justify-end d-flex">
                    <router-link :to="{name: 'Listing'}"  style="text-decoration: none">
                        <v-btn text class="pa-0" >
                            <v-icon large color="black">mdi mdi-close</v-icon>
                        </v-btn>
                    </router-link>
                </v-col>
            </v-row>
            <v-row>

                <v-col cols="12" class="align-self-center ">
                    <v-col cols="12" class="py-0"  :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}"  v-for="(title, index) in titles" :key="title.id">
                        <span v-if="(index + 1) === titleStep" class="headline font-weight-bold">{{title.name}}</span>
                        <span v-if="(index + 1) === titleStep && titleStep !== 6" class="title">{{title.desc}}</span>
                        <span v-if="(index + 1) === titleStep && titleStep === 6" class="title">{{title.desc}}<span class="font-weight-bold">{{title.desc2}}</span></span>
                    </v-col>
                    <v-col class="py-0 title" >
                        <v-row>
                            <v-col md="10" sm="10" cols="12">
                                <span  :class="{'tc_blue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}" >100% Compatibles</span> avec votre véhicule <span > {{vehiculeUtilisateur}} </span>
                            </v-col>
                            <v-col md="2" sm="2" v-if="$vuetify.breakpoint.smAndUp">
                                <v-img :src="getImgUrl()" style="width: 50%"/>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-col>

            </v-row>
        </v-col>
    </v-content>
</template>

<script>

import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });
    export default {
        name: "ConfigurateurHeader",
        data: () =>({
            vehiculeUtilisateur:'',
            titleStep:'',
            prevStep:'',
            titles:[
                {name:'Mes jantes'},
                {name:'Mon montage', desc:' - préconisé'},
                {name:'Mon montage', desc:' - personnalisé'},
                {name:'Mes pneus', desc:' - dimensions conseillées'},
                {name:'Mes pneus', desc:' - montage personnalisé'},
                {name:'Mes pneus', desc:' - Sélection des', desc2:' pneus avant et arrière'},
                {name:'Commander'},
                {name:'Règlement'},
            ],
            VUE_APP_SITE_ID:null,
        }),
        created(){
            this.titleStep = this.headerTitle
            this.vehiculeUtilisateur = ls.get('marque') + ' ' + ls.get('type') + ' ' + ls.get('motorisation')
        },
        mounted() {
            this.prevStep = (parseInt(this.$route.name.substring(5)) - 1);
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        },
        methods:{
            getImgUrl() {
              try{
                  var logoUrl = require('@/assets/logo3x/'+ ls.get('marque').toLowerCase() +'.png')
                  return logoUrl
                }
              catch{
                return require('@/assets/ConfigurateurHome.jpg')
              }
          }
        },
        watch:{
            '$props':{
                handler: function (val) {
                    this.titleStep = val.headerTitle
                },
                deep: true
            }
        },
        props:['header-title']
    }
</script>

<style scoped>

</style>
