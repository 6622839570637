<template>
    <v-app>

        <v-row v-if="$vuetify.breakpoint.lgAndUp" class="py-0">

                <v-col lg="9" class="pa-0">

                    <ConfigurateurHeader :header-title="headerTitle" class="bc_lightGrey"/>

                    <v-row class="bc_white">
                        <v-col>
                            <v-col offset-lg="3" lg="6" class="mt-5 pb-0">
                                <v-img aspect-ratio="3.15"  v-if="VUE_APP_SITE_ID === '1'" class="ml-10" src="@/assets/Voiture.svg"/>
                                <v-img aspect-ratio="3.48"  v-if="VUE_APP_SITE_ID === '2'" class="ml-10" src="@/assets/VoitureDJ.svg"/>
                            </v-col>


                            <v-row class="bc_white" v-if="montage === 'préconisé'">
                                <v-col offset-lg="2" lg="4">

                                    <v-content class="font-weight-bold text-center">
                                        Essieu avant :
                                    </v-content>
                                    <v-row>
                                        <v-col lg="3" class="px-2">
                                            <v-card class="text-center" style="cursor: default;" height="100%" hover v-if="monteType == 'par4'" >
                                                <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageLargeurEJ"/>
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageLargeurDJ"/>
                                                    <div  class="font-weight-medium  fontSize"  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                         Largeur
                                                    </div>
                                                    <div class=" font-weight-bold " style="font-size: 10px" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                        {{janteAv.largeur}} pouces
                                                    </div>
                                                </v-card-text>
                                            </v-card>

                                            <v-card class="text-center" style="cursor: default;" height="100%" hover v-if="monteType == 'decale'">
                                                <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageLargeurEJ"/>
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageLargeurDJ"/>
                                                    <div class="font-weight-medium  fontSize"  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                         Largeur
                                                    </div>
                                                    <div class=" font-weight-bold " style="font-size: 10px" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                        {{janteAv.largeurMinAv}} pouces
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col lg="3" class="px-2">
                                            <v-card class="text-center" style="cursor: default;" height="100%" hover >
                                                <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageDiametreEJ"/>
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageDiametreDJ"/>
                                                    <div class="font-weight-medium  fontSize"  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                         Dimensions
                                                    </div>
                                                    <div class=" font-weight-bold " style="font-size: 10px" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                        {{janteAv.diametre}} pouces
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col lg="3" class="px-2">
                                            <v-card class="text-center" style="cursor: default;" height="100%" hover >
                                                <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageEntraxeEJ"/>
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageEntraxeDJ"/>
                                                    <div class="font-weight-medium  fontSize"  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                         Entraxe
                                                    </div>
                                                    <div class=" font-weight-bold " style="font-size: 10px" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                        {{janteAv.entraxe}}
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col lg="3" class="px-2">
                                            <v-card class="text-center" style="cursor: default;" height="100%" hover v-if="monteType == 'par4'">
                                                <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageDeportEJ"/>
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageDeportDJ"/>
                                                    <div class="font-weight-medium  fontSize"  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                         Déport
                                                    </div>
                                                    <div class=" font-weight-bold " style="font-size: 10px" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                        {{janteAv.offset}}
                                                    </div>
                                                </v-card-text>
                                            </v-card>

                                            <v-card class="text-center" style="cursor: default;" height="100%" hover v-if="monteType == 'decale'">
                                                <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageDeportEJ"/>
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageDeportDJ"/>
                                                    <div class="font-weight-medium  fontSize"  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                         Déport
                                                    </div>
                                                    <div class=" font-weight-bold " style="font-size: 10px" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                        {{janteAv.offsetFront}}
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-col>
                                <v-col offset-lg="1" lg="4" >

                                    <v-content class="font-weight-bold text-center">
                                        Essieu arrière :
                                    </v-content>
                                    <v-row>
                                        <v-col lg="3" class="px-2">
                                            <v-card class="text-center" style="cursor: default;" height="100%" hover v-if="monteType == 'par4'">
                                                <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageLargeurEJ"/>
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageLargeurDJ"/>
                                                    <div class="font-weight-medium  fontSize"  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                         Largeur
                                                    </div>
                                                    <div class=" font-weight-bold " style="font-size: 10px" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                        {{janteArr.largeur}} pouces
                                                    </div>
                                                </v-card-text>
                                            </v-card>

                                             <v-card class="text-center" style="cursor: default;" height="100%" hover v-if="monteType == 'decale'">
                                                <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageLargeurEJ"/>
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageLargeurDJ"/>
                                                    <div class="font-weight-medium  fontSize"  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                         Largeur
                                                    </div>
                                                    <div class=" font-weight-bold " style="font-size: 10px" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                        {{janteArr.largeurMaxArr}} pouces
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col lg="3" class="px-2">
                                            <v-card class="text-center" style="cursor: default;" height="100%" hover >
                                                <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageDiametreEJ"/>
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageDiametreDJ"/>
                                                    <div class="font-weight-medium  fontSize"  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                         Dimensions
                                                    </div>
                                                    <div class=" font-weight-bold " style="font-size: 10px" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                        {{janteArr.diametre}} pouces
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col lg="3" class="px-2">
                                            <v-card class="text-center" style="cursor: default;" height="100%" hover >
                                                <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageEntraxeEJ"/>
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageEntraxeDJ"/>
                                                    <div class="font-weight-medium  fontSize"  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                         Entraxe
                                                    </div>
                                                    <div class=" font-weight-bold " style="font-size: 10px" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                        {{janteArr.entraxe}}
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                        <v-col lg="3" class="px-2">
                                            <v-card class="text-center" style="cursor: default;" height="100%" hover v-if="monteType == 'par4'">
                                                <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageDeportEJ"/>
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageDeportDJ"/>
                                                    <div class="font-weight-medium  fontSize"  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                         Déport
                                                    </div>
                                                    <div class=" font-weight-bold " style="font-size: 10px" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                        {{janteArr.offset}}
                                                    </div>
                                                </v-card-text>
                                            </v-card>

                                            <v-card class="text-center" style="cursor: default;" height="100%" hover v-if="monteType == 'decale'">
                                                <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageDeportEJ"/>
                                                    <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;width: 60%;" class="ma-auto" :src="imageDeportDJ"/>
                                                    <div class="font-weight-medium  fontSize"  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                         Déport
                                                    </div>
                                                    <div class=" font-weight-bold " style="font-size: 10px" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                        {{janteArr.offsetRear}}
                                                    </div>
                                                </v-card-text>
                                            </v-card>
                                        </v-col>
                                    </v-row>

                                </v-col>

                            </v-row>

                            <v-row class="bc_white" v-if="montage === 'personnalisé'">
                                <v-col offset-lg="2" lg="4">
                                    <v-content class="font-weight-bold pb-3 text-center">
                                        Essieu avant :
                                    </v-content>
                                    <v-select v-model="testessieusAvant" :items="optionDesJantesAv" item-text="toShow" label="Choix pour l'avant"  item-value="objectId" solo class="pa-0 font-weight-bold" :class="{'body-2':$vuetify.breakpoint.lg}"/>
<!--                                    <v-select v-model="testessieusAvant" :items="optionDesJantesAv" item-text="toShow" label="Choix pour l'avant"  item-value="toGet" solo class="pa-0 font-weight-bold" :class="{'body-2':$vuetify.breakpoint.lg}"/>-->

                                </v-col>
                                <v-col offset-lg="1" lg="4" >
                                    <v-content class="font-weight-bold pb-3 text-center">
                                        Essieu arrière :
                                    </v-content>
<!--                                    <v-select v-model="essieusArrierePerso_default" :items="optionDesJantesArr" @change="changeSizeArr" item-text="toShow" label="Choix pour l'arrière" item-value="toGet" solo  class="pa-0 font-weight-bold" :class="{'body-2':$vuetify.breakpoint.lg}"/>-->
                                    <v-select v-model="essieusArrierePerso_default" :items="optionDesJantesArr" item-text="toShow" label="Choix pour l'arrière" item-value="objectId" solo  class="pa-0 font-weight-bold" :class="{'body-2':$vuetify.breakpoint.lg}"/>

                                </v-col>


                            </v-row>


                            <v-row>
                                <v-col offset-lg="1">

                                    <span :class="{'link' : VUE_APP_SITE_ID === '1', 'linkDJ' : VUE_APP_SITE_ID ==='2'}" @click="overlaySwitch" v-if="montage === 'préconisé' && optionDesJantesAv.length > 1 && optionDesJantesArr.length > 1">
                                        Je souhaite le montage personnalisé
                                    </span>
                                    <span :class="{'link' : VUE_APP_SITE_ID === '1', 'linkDJ' : VUE_APP_SITE_ID ==='2'}" @click="montageSwitch" v-if="montage === 'personnalisé'">
                                        Je souhaite le montage préconisé
                                    </span>
                                </v-col>
                                <v-col>
                                    <v-overlay
                                            :absolute="absolute"
                                            :opacity="opacity"
                                            :value="overlay"
                                            :z-index="zIndex"
                                    >
                                        <v-col lg="7">

                                            <v-card light >

                                                <v-card-text  class=" text-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    <span class="font-weight-bold title">Attention</span>
                                                    <br>
                                                    <span v-if="VUE_APP_SITE_ID === '1'">Espace Jantes ne garantie pas la compatibilité de vos choix en montage personnalisé.</span>
                                                    <span v-if="VUE_APP_SITE_ID === '2'">Distri Jantes ne garantie pas la compatibilité de vos choix en montage personnalisé.</span>
                                                    <v-row>
                                                    <v-col lg="6" >
                                                        <v-btn @click="continueFunction">
                                                            continuer
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col lg="6">
                                                        <v-btn @click="overlaySwitch">
                                                            Annuler
                                                        </v-btn>
                                                    </v-col>
                                                    </v-row>
                                                </v-card-text>

                                            </v-card>
                                        </v-col>
                                    </v-overlay>

                                </v-col>
                            </v-row>

                        </v-col>
                    </v-row>
                </v-col>

                <v-col lg="3" class="pa-0">
                    <Menu/>
                </v-col>

        </v-row>
        <div v-if="$vuetify.breakpoint.lgAndUp" style="height: 16vh"></div>

        <v-col v-if="$vuetify.breakpoint.mdAndDown" class="pa-0 bc_lightGrey">
            <v-col lg="9" class="pa-0">

                <ConfigurateurHeader :header-title="headerTitle"/>

                <v-col class="bc_white" style="height: 100%">
                    <v-col>
                        <v-col offset-lg="3" lg="7" class="mt-5 pb-0">
                            <v-img aspect-ratio="3.2"  v-if="VUE_APP_SITE_ID === '1'" src="@/assets/Voiture.svg" />
                            <v-img aspect-ratio="3.5"  v-if="VUE_APP_SITE_ID === '2'" src="@/assets/VoitureDJ.svg" />
                        </v-col>


                        <v-row v-if="montage === 'préconisé'" class="bc_white text-xl-h5
                                                                       text-lg-h6
                                                                       text-md-subtitle-1
                                                                       text-sm-subtitle-1
                                                                       text-subtitle-2">
                            <v-col >

                                <v-content class="font-weight-bold text-center">
                                    Essieu avant
                                </v-content>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6" class="pa-2">
                                        <v-card class="text-center" height="100%" hover v-if="monteType == 'par4'">
                                            <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                <v-img aspect-ratio="1"  v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;" class="ma-auto" :src="imageLargeurEJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <v-img aspect-ratio="1"  v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;" class="ma-auto" :src="imageLargeurDJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <div  class="font-weight-medium "  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Largeur
                                                </div>
                                                <div class=" font-weight-bold " :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{janteAv.largeur}} pouces
                                                </div>
                                            </v-card-text>
                                        </v-card>

                                        <v-card class="text-center" height="100%" hover v-if="monteType == 'decale'">
                                            <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;" class="ma-auto" :src="imageLargeurEJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;" class="ma-auto" :src="imageLargeurDJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <div  class="font-weight-medium "  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Largeur
                                                </div>
                                                <div class=" font-weight-bold " :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{janteAv.largeurMinAv}} pouces
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6"  class="pa-2">
                                        <v-card class="text-center" height="100%" hover >
                                            <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;" class="ma-auto" :src="imageDiametreEJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;" class="ma-auto" :src="imageDiametreDJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <div  class="font-weight-medium "  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Dimensions
                                                </div>
                                                <div class=" font-weight-bold " :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{janteAv.diametre}} pouces
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6"  class="pa-2">
                                        <v-card class="text-center" height="100%" hover >
                                            <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;" class="ma-auto" :src="imageEntraxeEJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;" class="ma-auto" :src="imageEntraxeDJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <div class="font-weight-medium "  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Entraxe
                                                </div>
                                                <div class=" font-weight-bold " :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{janteAv.entraxe}}
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6"  class="pa-2">
                                        <v-card class="text-center" height="100%" hover v-if="monteType == 'par4'">
                                            <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;" class="ma-auto" :src="imageDeportEJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;" class="ma-auto" :src="imageDeportDJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <div  class="font-weight-medium "  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Déport
                                                </div>
                                                <div class=" font-weight-bold " :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{janteAv.offset}}
                                                </div>
                                            </v-card-text>
                                        </v-card>

                                        <v-card class="text-center" height="100%" hover v-if="monteType == 'decale'">
                                            <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;" class="ma-auto" :src="imageDeportEJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;" class="ma-auto" :src="imageDeportDJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <div  class="font-weight-medium "  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Déport
                                                </div>
                                                <div class=" font-weight-bold " :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{janteAv.offsetFront}}
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="pl-7" >

                                <v-content class="font-weight-bold text-center">
                                    Essieu arrière
                                </v-content>
                                <v-row>
                                    <v-col cols="12" sm="6" md="6"  class="pa-2">
                                        <v-card class="text-center" height="100%" hover v-if="monteType == 'par4'">
                                            <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;" class="ma-auto" :src="imageLargeurEJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;" class="ma-auto" :src="imageLargeurDJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <div class="font-weight-medium "  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Largeur
                                                </div>
                                                <div class=" font-weight-bold " :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{janteArr.largeur}} pouces
                                                </div>
                                            </v-card-text>
                                        </v-card>

                                        <v-card class="text-center" height="100%" hover v-if="monteType == 'decale'">
                                            <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;" class="ma-auto" :src="imageLargeurEJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;" class="ma-auto" :src="imageLargeurDJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <div class="font-weight-medium "  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Largeur
                                                </div>
                                                <div class=" font-weight-bold " :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{janteArr.largeurMaxArr}} pouces
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6"  class="pa-2">
                                        <v-card class="text-center" height="100%" hover >
                                            <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;" class="ma-auto" :src="imageDiametreEJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;" class="ma-auto" :src="imageDiametreDJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <div class="font-weight-medium "  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Dimensions
                                                </div>
                                                <div class=" font-weight-bold " :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{janteArr.diametre}} pouces
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6"  class="pa-2">
                                        <v-card class="text-center" height="100%" hover >
                                            <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;" class="ma-auto" :src="imageEntraxeEJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;" class="ma-auto" :src="imageEntraxeDJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <div class="font-weight-medium "  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Entraxe
                                                </div>
                                                <div class=" font-weight-bold " :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{janteArr.entraxe}}
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" sm="6" md="6"  class="pa-2">
                                        <v-card class="text-center" height="100%" hover v-if="monteType == 'par4'">
                                            <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;" class="ma-auto" :src="imageDeportEJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '2'" style="padding-top: 2%;" class="ma-auto" :src="imageDeportDJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <div class="font-weight-medium "  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Déport
                                                </div>
                                                <div class=" font-weight-bold " :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{janteArr.offset}}
                                                </div>
                                            </v-card-text>
                                        </v-card>

                                        <v-card class="text-center" height="100%" hover v-if="monteType == 'decale'">
                                            <v-card-text style="height: 100%" class="  px-1 align-content-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;" class="ma-auto" :src="imageDeportEJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <v-img aspect-ratio="1" v-if="VUE_APP_SITE_ID === '1'" style="padding-top: 2%;" class="ma-auto" :src="imageDeportDJ"
                                                       :style="[ $vuetify.breakpoint.md ? {'max-width' : '70%'} :
                                                                 $vuetify.breakpoint.sm ? {'max-width' : '80%'} :
                                                                 $vuetify.breakpoint.xs ? {'max-width' : '90%'} : '']"/>
                                                <div class="font-weight-medium "  :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">
                                                    Déport
                                                </div>
                                                <div class=" font-weight-bold " :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                    {{janteArr.offsetRear}}
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>

                            </v-col>

                        </v-row>

                        <v-row class="bc_white" v-if="montage === 'personnalisé'">
                            <v-col offset-lg="2" lg="4">
                                <v-content class="font-weight-bold pb-3 text-center">
                                    Essieu avant :
                                </v-content>
<!--                                <v-select v-model="testessieusAvant" :items="optionDesJantesAv" @change="changeSizeAv" item-text="toShow" label="Choix pour l'avant"  item-value="toGet" solo class="pa-0 font-weight-bold" :class="{'body-2':$vuetify.breakpoint.lg}"/>-->
                                <v-select v-model="testessieusAvant" :items="optionDesJantesAv" item-text="toShow" label="Choix pour l'avant"  item-value="objectId" solo class="pa-0 font-weight-bold" :class="{'body-2':$vuetify.breakpoint.lg}"/>

                            </v-col>
                            <v-col offset-lg="1" lg="4" >
                                <v-content class="font-weight-bold pb-3 text-center">
                                    Essieu arrière :
                                </v-content>
<!--                                <v-select v-model="essieusArrierePerso_default" :items="optionDesJantesArr" @change="changeSizeArr" item-text="toShow" label="Choix pour l'arrière" item-value="toGet" solo  class="pa-0 font-weight-bold" :class="{'body-2':$vuetify.breakpoint.lg}"/>-->
                                <v-select v-model="essieusArrierePerso_default" :items="optionDesJantesArr" item-text="toShow" label="Choix pour l'arrière" item-value="objectId" solo  class="pa-0 font-weight-bold" :class="{'body-2':$vuetify.breakpoint.lg}"/>

                            </v-col>


                        </v-row>

                        <!--                        {{configurateur}}-->
                        <v-row>
                            <v-col cols="12">
                                <v-content v-if="$vuetify.breakpoint.xs">
                                    <v-btn width="100%" @click="overlaySwitch" v-if="montage === 'préconisé'">
                                        Montage personnalisé
                                    </v-btn>
                                    <v-btn width="100%" @click="montageSwitch" v-if="montage === 'personnalisé'">
                                        Montage préconisé
                                    </v-btn>
                                </v-content>
                                <v-content v-if="$vuetify.breakpoint.smAndUp">
                                    <v-btn width="100%" @click="overlaySwitch" v-if="montage === 'préconisé' ">
                                        Je souhaite le montage personnalisé
                                    </v-btn>
                                    <v-btn width="100%" @click="montageSwitch" v-if="montage === 'personnalisé'">
                                        Je souhaite le montage préconisé
                                    </v-btn>
                                </v-content>
                            </v-col>
                            <v-col>
                                <v-overlay
                                        :absolute="absolute"
                                        :opacity="opacity"
                                        :value="overlay"
                                        :z-index="zIndex"
                                >
                                    <v-col lg="7">

                                        <v-card light >

                                            <v-card-text  class=" text-center" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                                <span class="font-weight-bold title">Attention</span>
                                                <br>
                                                <span v-if="VUE_APP_SITE_ID === '1'">Espace Jantes ne garantie pas la compatibilité de vos choix en montage personnalisé.</span>
                                                <span v-if="VUE_APP_SITE_ID === '2'">Distri Jantes ne garantie pas la compatibilité de vos choix en montage personnalisé.</span>
                                                <v-row>
                                                    <v-col lg="6" >
                                                        <v-btn @click="continueFunction">
                                                            continuer
                                                        </v-btn>
                                                    </v-col>
                                                    <v-col lg="6">
                                                        <v-btn @click="overlaySwitch">
                                                            Annuler
                                                        </v-btn>
                                                    </v-col>
                                                </v-row>
                                            </v-card-text>

                                        </v-card>
                                    </v-col>
                                </v-overlay>
                            </v-col>
                        </v-row>

                    </v-col>
                </v-col>
            </v-col>

            <v-col lg="3" class="pa-0">
                <Menu/>
            </v-col>
        </v-col>

        <ConfigurateurFooter :update="triggerUpdate"/>
    </v-app>
</template>

<script>
    import Menu from "./Menu";
    import ConfigurateurHeader from "../headers/ConfigurateurHeader";
    import ConfigurateurFooter from "../footer/ConfigurateurFooter";

    import LargeurEJ from '@/assets/icons/icon_largeurEJ.svg';
    import DiametreEJ from '@/assets/icons/icon_diametreEJ.svg';
    import EntraxeEJ from '@/assets/icons/icon_entraxeEJ.svg';
    import DeportEJ from '@/assets/icons/icon_deportEJ.svg';
    import AlesageEJ from '@/assets/icons/icon_alesageEJ.svg';

    import LargeurDJ from '@/assets/icons/icon_largeurDJ.svg';
    import DiametreDJ from '@/assets/icons/icon_diametreDJ.svg';
    import EntraxeDJ from '@/assets/icons/icon_entraxeDJ.svg';
    import DeportDJ from '@/assets/icons/icon_deportDJ.svg';
    import AlesageDJ from '@/assets/icons/icon_alesageDJ.svg';

    import 'instantsearch.css/themes/algolia-min.css';
    import SecureLS from "secure-ls";
    var ls = new SecureLS({ isCompression: false });

    import Vue from 'vue'
    import axios from 'axios'
    import VueAxios from 'vue-axios'

    Vue.use(VueAxios, axios)

    import {mapState} from 'vuex'
    import algoliasearch from "algoliasearch";

    export default {
        name: "Etape2.vue",
        components:{
            ConfigurateurHeader,
            ConfigurateurFooter,
            Menu
        },
        data: () =>({
            monteType: '',
            headerTitle:2,
            optionDesJantesAv:[],
            optionDesJantesArr:[],
            // montage: 'personnalisé',
            montage: 'préconisé',
            essieusAvantPerso_default:{text:'8X18 - 5x112 - et 45'},
            testessieusAvant:'',
            essieusAvantPerso:[
                {text:'8X18 - 5x112 - et 45'},
                {text:'8X18 - 5x112 - et 46'},
                {text:'8X20 - 5x112 - et 45'},
                {text:'8X120 - 5x112 - et 46'},
            ],
            essieusArrierePerso_default:'',
            essieusArrierePerso:[
                {text:'8X18 - 5x112 - et 45'},
                {text:'8X18 - 5x112 - et 46'},
                {text:'8X20 - 5x112 - et 45'},
                {text:'8X20 - 5x112 - et 46'},
            ],
            active:false,
            absolute: false,
            opacity: 0.46,
            overlay: false,
            zIndex: 5,
            colorr:'',

            imageLargeurEJ: LargeurEJ,
            imageDiametreEJ: DiametreEJ,
            imageEntraxeEJ: EntraxeEJ,
            imageDeportEJ: DeportEJ,
            imageAlesageEJ: AlesageEJ,

            imageLargeurDJ: LargeurDJ,
            imageDiametreDJ: DiametreDJ,
            imageEntraxeDJ: EntraxeDJ,
            imageDeportDJ: DeportDJ,
            imageAlesageDJ: AlesageDJ,

            MonteType: false,
            filterAlgolia: '',
            allOptionChassis:[],
            janteAv:{},
            janteArr:{},
            jantes:{},
            once:true,
            triggerUpdate: 1,
            triggerUpdateTmp: false,
            essieusAvTmp: null,
            essieusArrTmp: null,
            VUE_APP_SITE_ID:null

        }),
        methods:{
            getOnglet(value){

                switch(value){
                    case'jantesAv':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv
                    case 'jantesArr':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr
                    case 'pneusAv':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv
                    case 'pneusArr':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr
                    case 'accessoires':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').accessoires
                    case 'modelePossibleAuCarre':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre')
                    case 'modelePossibleFront':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront')
                    case 'modelePossibleRear':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear')

                }

            },
            overlaySwitch: function(){
                this.overlay = !this.overlay
            },
            montageSwitch: function() {
                if(this.montage === 'préconisé')
                {
                    console.log('montageSwitch préconisé')
                    this.headerTitle = 3;
                    this.montage = 'personnalisé';
                    this.$store.commit('footerNext/setNext', 'false')
                    this.$store.commit('infoJantes/setMonteStateDeca', true)
                    if(this.testessieusAvant && this.essieusArrierePerso_default){

                        var resAv = this.testessieusAvant.split("/")
                        var resArr = this.essieusArrierePerso_default.split("/")
                        if(this.testessieusAvant === this.essieusArrierePerso_default)
                        {
                            this.jantes = this.janteAv
                            this.jantes.largeur = resAv[0]
                            this.jantes.largeurMinAv = resAv[0]
                            this.jantes.largeurMaxArr = resArr[0]
                            this.jantes.diametre = resAv[1]
                            this.jantes.entraxe = resAv[2]
                            this.jantes.offsetFront = resAv[3]
                            this.jantes.offsetRear = resAv[3]
                            this.jantes.objectID = resAv[4]
                            this.jantes.public_price = resAv[5]
                            this.jantes.public_price_rear = resArr[5]

                            this.jantes.type = 1
                            this.jantes.quantite = 2
                            // this.$store.commit('infoJantes/setMonteStateDeca', false)
                            this.setOnglet(this.jantes, '4jantes')

                            // ls.set('monteType', 'par4')

                            this.updateRoute(0, 'decale')


                        }else{
                            // ls.set('monteType', 'decale')
                            this.updateRoute(1, 'decale')



                            this.janteAv.largeur = resAv[0]
                            this.janteAv.largeurMinAv = resAv[0]
                            this.janteAv.largeurMaxArr = resArr[0]
                            this.janteAv.diametre = resAv[1]
                            this.janteAv.entraxe = resAv[2]
                            this.janteAv.offsetFront = resAv[3]
                            this.janteAv.objectID = resAv[4]
                            this.janteAv.public_price = resAv[5]
                            this.janteAv.public_price_rear = resArr[5]

                            this.janteAv.type = 1
                            this.janteAv.quantite = 2

                            // console.log('essieusArrierePerso_default', this.essieusArrierePerso_default)


                            // var resArr = this.essieusArrierePerso_default.split("/")

                            // console.log('resArr', resArr)

                            this.janteArr.largeur = resArr[0]
                            this.janteArr.largeurMaxArr = resArr[0]
                            this.janteArr.largeurMinAv = resAv[0]
                            this.janteArr.diametre = resArr[1]
                            this.janteArr.entraxe = resArr[2]
                            this.janteArr.offset = resArr[3]
                            this.janteArr.offsetRear = resArr[3]
                            this.janteArr.objectID = resArr[4]
                            this.janteArr.public_price = resAv[5]
                            this.janteArr.public_price_rear = resArr[5]
                            this.janteArr.type = 1
                            this.janteArr.quantite = 2

                            this.setOnglet(this.janteAv, 'jantesAv')
                            this.setOnglet(this.janteArr, 'jantesArr')

                        }
                    }
                }

            else if(this.montage === 'personnalisé')
                {
                    console.log('montageSwitch personnalisé')

                    this.headerTitle = 2;
                    this.montage = 'préconisé'
                    this.$store.commit('footerNext/setNext', 'true')
                    this.$store.commit('infoJantes/setMonteStateDeca', false)
                    this.updateRoute(0, 'decale')
                    // this.setOnglet(this.jantes, '4jantes')
                    if(this.janteAv !== this.getOnglet('jantesAv') && this.janteArr !== this.getOnglet('jantesArr')){
                        this.janteAv = this.getOnglet('jantesAv')
                        this.janteArr = this.getOnglet('jantesArr')
                    }
                    this.triggerUpdate ++
                }
                window.scrollTo(0, 0);
            },
            continueFunction : function() {
                this.overlaySwitch();
                this.montageSwitch();
                this.$store.commit('footerNext/setNext', 'false')
            },
            getOptionChassis() {
               var typeDeMontage = (this.$route.params.decale).toString() === '0' ? 'par4' : 'decale'

                if(typeDeMontage == 'decale') {
                    // var modelePossibleFront = JSON.parse(this.$localStorage.get('modelePossibleFront'))
                    // var modelePossibleFront = JSON.parse(ls.get('modelePossibleFront'))
                    var modelePossibleFront = this.getOnglet('modelePossibleFront')
                    // var modelePossibleRear = JSON.parse(this.$localStorage.get('modelePossibleRear'))
                    // var modelePossibleRear = JSON.parse(ls.get('modelePossibleRear'))
                    var modelePossibleRear = this.getOnglet('modelePossibleRear')

                    let arrayTmpAv =[]
                    let arrayTmpArr =[]


                    modelePossibleFront.forEach(element => {
                        element.toGet = element.largeur +"/"+ element.diametre + "/"+ element.entraxe + "/" + element.offset
                        element.toShow = element.largeur +"X"+ element.diametre + " - "+ element.entraxe + " - ET " + element.offset
                        element.objectId = element.largeur +"/"+ element.diametre + "/"+ element.entraxe + "/" + element.offset + "/" + element.objectID + "/" + element.public_price

                        if(arrayTmpAv.indexOf(element.toGet) === -1 && element.couleur === this.$route.params.couleur)
                        {
                            arrayTmpAv.push(element.toGet)
                            this.optionDesJantesAv.push(element)
                        }
                    })
                    modelePossibleRear.forEach(element => {
                        element.toGet = element.largeur +"/"+ element.diametre + "/"+ element.entraxe + "/" + element.offset
                        element.toShow = element.largeur +"X"+ element.diametre + " - "+ element.entraxe + " - ET " + element.offset
                        element.objectId = element.largeur +"/"+ element.diametre + "/"+ element.entraxe + "/" + element.offset + "/" + element.objectID + "/" + element.public_price

                        if(arrayTmpArr.indexOf(element.toGet) === -1 && element.couleur === this.$route.params.couleur)
                        {
                            arrayTmpArr.push(element.toGet)
                            this.optionDesJantesArr.push(element)

                        }
                    })
                }else{
                    // var modelePossible = JSON.parse(ls.get('modelePossibleAuCarre'))
                    // var modelePossible = JSON.parse(this.getOnglet('modelePossibleAuCarre'))
                    var modelePossible = this.getOnglet('modelePossibleAuCarre')
                    let arrayTmp = []

                    modelePossible.forEach((element) => {

                        element.toGet = element.largeur +"/"+ element.diametre + "/"+ element.entraxe + "/" + element.offset
                        element.toShow = element.largeur +"X"+ element.diametre + " - "+ element.entraxe + " - ET " + element.offset
                        element.objectId = element.largeur +"/"+ element.diametre + "/"+ element.entraxe + "/" + element.offset + "/" + element.objectID + "/" + element.public_price

                        if(arrayTmp.indexOf(element.toGet) === -1 && element.couleur === this.$route.params.couleur)
                        {
                            arrayTmp.push(element.toGet)
                            this.optionDesJantesAv.push(element)
                            this.optionDesJantesArr.push(element)
                        }

                    })

                }
            },
            checkRearUpset(){
                // var chassisVehiculeUtilisateur = JSON.parse(this.$localStorage.get('chassisVehiculeUtilisateur'))
                var chassisVehiculeUtilisateur
                if( typeof ls.get('chassisVehiculeUtilisateur') == "string" ){
                    chassisVehiculeUtilisateur = JSON.parse(ls.get('chassisVehiculeUtilisateur'))
                }
                else if(typeof ls.get('chassisVehiculeUtilisateur') == "object"){
                    chassisVehiculeUtilisateur = ls.get('chassisVehiculeUtilisateur')
                }
                var id_chassis = chassisVehiculeUtilisateur.id_chassis

                Vue.axios.get('https://api.espace-jantes.com/getOptionsChassis', {params: {idChassisVehiculeUtilisateur : id_chassis}}) 
                .then((response) => {
                    response.data.forEach(element => {
                        this.allOptionChassis.push(element)
                        if(element.upsetType == "RearUpstep"){
                            this.MonteType = true
                        }
                    })
                    //this.getMontagePreco()
                    this.getOptionChassis()
                })
            },
            setOnglet(item, value){
                // console.log('setOnglet',value, item)

                switch(value){
                    case"4jantes":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : item, jantesArr : item})
                        this.triggerUpdate ++
                        break
                    case"jantesAv":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : item, jantesArr : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr})
                        this.triggerUpdate ++
                        break
                    case"jantesArr":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv, jantesArr : item})
                        this.triggerUpdate ++
                        break
                    case"4pneus":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : item, pneusArr : item})
                        this.triggerUpdate ++
                        break
                    case"pneusAv":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : item, pneusArr : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr})
                        this.triggerUpdate ++
                        break
                    case"pneusArr":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv, pneusArr : item})
                        this.triggerUpdate ++
                        break
                    case"modelePossibleAuCarre":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre', item)
                        break
                    case"modelePossibleFront":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront', item)
                        break
                    case"modelePossibleRear":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear', item)
                        break
                }

                ls.set('last_updated', this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale)

            },
            updateRoute(params, type) {

                // console.log("split", this.$route.path.split('/'))

                var splitedUrlNew = this.$route.path.split('/')
                var splitedUrlOld = this.$route.path.split('/')
                // eslint-disable-next-line no-unused-vars
                var joinedUrl

                switch(type){
                    case 'marque':
                        splitedUrlNew[2] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.marque = params
                        break
                    case 'modele':
                        splitedUrlNew[3] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.modele = params
                        break
                    case 'diametre':
                        splitedUrlNew[4] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.diametre = params
                        break
                    case 'couleur':
                        splitedUrlNew[5] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.couleur = params
                        break
                    case 'decale':
                        splitedUrlNew[6] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.decale = params
                        break
                }

                history.pushState(
                    {},
                    null,
                    joinedUrl
                )
            },
            updateOnglet(oldName, newName){
                // console.log('oldName', oldName)
                // console.log('newName', newName)

                ls.get(oldName + '__infosJantes') ?  ls.set(newName + '__infosJantes', ls.get(oldName + '__infosJantes')) : null
                ls.get(oldName + '__infosPneus') ?  ls.set(newName + '__infosPneus', ls.get(oldName + '__infosPneus')) : null
                ls.get(oldName + '__modelePossibleAuCarre') ?  ls.set(newName + '__modelePossibleAuCarre', ls.get(oldName + '__modelePossibleAuCarre')) : null
                ls.get(oldName + '__modelePossibleFront') ?  ls.set(newName + '__modelePossibleFront', ls.get(oldName + '__modelePossibleFront')) : null
                ls.get(oldName + '__modelePossibleRear') ?  ls.set(newName + '__modelePossibleRear', ls.get(oldName + '__modelePossibleRear')) : null


            }
        },
        created(){
            this.monteType =  (this.$route.params.decale).toString() === '0' ? 'par4' : 'decale'

            this.janteAv = this.getOnglet('jantesAv')

            this.janteArr = this.getOnglet('jantesArr')

        },
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
            this.checkRearUpset()
            this.$store.commit('footerNext/setNext', 'true')

            if( process.env.NODE_ENV.trim() !== "development") {

                window.dataLayer.push({
                    'event': 'Pageview',
                    'pagePath': '/etape2',
                    'pageTitle': 'Etape2'
                });
            }
        },
        updated() {
            // if(this.triggerUpdateTmp === false) {

                if( this.testessieusAvant !== this.essieusAvTmp || this.essieusArrierePerso_default !== this.essieusArrTmp) {
                    this.essieusAvTmp = this.testessieusAvant
                    this.essieusArrTmp = this.essieusArrierePerso_default
                    if (this.montage === 'personnalisé' && this.testessieusAvant && this.essieusArrierePerso_default) {

                        const client = algoliasearch('IXJF8AJTH7', '27829f3918903605b2f7665097a13737');
                        const index = client.initIndex('dev_EJ');

                        var resAv = this.testessieusAvant.split("/")
                        var resArr = this.essieusArrierePerso_default.split("/")
                        if (this.testessieusAvant === this.essieusArrierePerso_default) {

                            this.triggerUpdateTmp = true

                            // console.log('objectID: "' + resAv[4] + '"')
                            index.search('', {
                                filters: 'objectID: "' + resAv[4] + '"'
                            })
                                .then(({hits}) => {
                                    // console.log('hits', hits)

                                    this.setOnglet(hits[0], '4jantes')

                                    this.updateRoute(0, 'decale')

                                }).catch((err) => {
                                console.log('err', err)
                            })


                        } else {

                            this.updateRoute(1, 'decale')
                            this.triggerUpdateTmp = true

                            // console.log('objectID av: "' + resAv[4] + '"')
                            index.search('', {
                                filters: 'objectID: "' + resAv[4] + '"'
                            })
                                .then(({hits}) => {
                                    // console.log('hits', hits)

                                    this.janteAv = hits[0]

                                    this.janteAv.largeurMinAv = resAv[0]
                                    this.janteAv.largeurMaxArr = resArr[0]
                                    this.janteAv.offsetFront = resAv[3]
                                    this.janteAv.public_price_rear = resArr[5]

                                    this.janteAv.type = 1
                                    this.janteAv.quantite = 2

                                    this.setOnglet(this.janteAv, 'jantesAv')

                                }).catch((err) => {
                                console.log('err', err)
                            })

                            // console.log('objectID arr: "' + resArr[4] + '"')
                            index.search('', {
                                filters: 'objectID: "' + resArr[4] + '"'
                            })
                                .then(({hits}) => {
                                    // console.log('hits', hits)

                                    this.janteArr = hits[0]

                                    this.janteArr.largeurMaxArr = resArr[0]
                                    this.janteArr.largeurMinAv = resAv[0]
                                    this.janteArr.offsetRear = resArr[3]
                                    this.janteArr.public_price_rear = resArr[5]

                                    this.janteArr.type = 1
                                    this.janteArr.quantite = 2

                                    this.setOnglet(this.janteArr, 'jantesArr')

                                }).catch((err) => {
                                console.log('err', err)
                            })

                        }
                        this.$store.commit('footerNext/setNext', 'true')
                    }
                    this.monteType = (this.$route.params.decale).toString() === '0' ? 'par4' : 'decale'
                }
            // }
        },
        computed:{
            ...mapState({
                monteStateDeca: state => state.infoJantes.monteStateDeca,
            })
        }
    }
</script>

<style scoped>
    .v-select__selection .v-select__selection--comma {
        color: blue !important;
    }

    @media (min-width: 1250px) and (max-width:1400px) {
        .fontSize{
            font-size: 9px!important;
        }
    }
    @media (max-width: 1250px){
        .fontSize{
            font-size: 11px!important;
        }
    }
    @media (min-width:1400px) {
        .fontSize{
            font-size: 11px!important;
        }
    }

</style>
