

const state = () =>({
    Vehicule:{},
    infoValves: ''
})



const mutations = {
    setVehicule(state, config){

        if(config.marque){
            state.Vehicule.marque = config.marque
        }
        if(config.modele){
            state.Vehicule.modele = config.modele
        }
        if(config.type){
            state.Vehicule.type = config.type
        }
        if(config.motorisation){
            state.Vehicule.motorisation = config.motorisation
        }
    },

    setValve(state, config){

        if(config){
            state.infoValves = config
        }
    },
}


const actions = {

}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}