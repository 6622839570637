<template>

    <v-app fluid >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-if="VUE_APP_SITE_ID === '2'"/>

        <v-main v-if="VUE_APP_SITE_ID === '1'">
            <v-col class="py-0">
                <v-row class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 tc_blue font-weight-bold rotate">
                            Jantes - Options
                        </h1>

                </v-col>
                <v-col lg="5" >
                    <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5  tc_blue font-weight-bold ">
                        Jantes - Options
                    </h1>
                    <p class="font-weight-bold
                                   text-xl-h5
                                   text-lg-h6
                                   text-md-subtitle-1
                                   text-sm-subtitle-1
                                   text-subtitle-2">JANTES LARGES ET ACCESSOIRES</p>
                    <br>
                    <p class="font-weight-bold
                                   text-xl-h5
                                   text-lg-h6
                                   text-md-subtitle-1
                                   text-sm-subtitle-1
                                   text-subtitle-2">JANTES LARGES</p>
                    <p class=" mb-3 pl-4 border_left tc_turquoise font-weight-bold
                                            text-xl-h5
                                        text-lg-body-1
                                        text-md-body-1
                                        text-sm-body-1
                                        text-body-1">
                        Si vous choisissez une jante au déport inférieur à celui d’origine, la cote de parallélisme et la cote d’appui sont modifiées.
                        <br>
                        L’appui de la roue remaniée entraîne une usure plus rapide des cardans et des rotules.
                    </p>
                    <p>
                        Vous ressentirez plus de vibrations dans le volant et les pneumatiques auront une usure irrégulière à l’intérieur.
                        Votre train de roue plus large entraînera des travaux de carrosserie afin d’élargir les ailes pour le passage des roues.
                    <p>
                        Si vous choisissez une jante plus large que celle d’origine, les modifications seront moindres.
                        Le parallélisme est légèrement modifié.
                        La cote d’appui est identique ce qui permet de préserver l’essieu et les organes mécaniques de la roue.
                        Vous pourrez monter aisément des pneus plus larges et si les jantes – options ne dépassent pas 2 pouces de plus, les travaux de carrosserie seront évités.
                        Ce style de tuning assure un look trapu à votre véhicule.
                    </p>
                    <br>
                    <p class="font-weight-bold
                                   text-xl-h5
                                   text-lg-h6
                                   text-md-subtitle-1
                                   text-sm-subtitle-1
                                   text-subtitle-2">ACCESSOIRES DE JANTES</p>
                    <p>Pour compléter la gamme d’accessoires pour les jantes vous trouverez :</p>
                    <ul>
                        <li>
                            Des capuchons stylés de valves qui sont souvent en caoutchouc.
                            Ils sont standards, et se montent aisément à la place des anciens capuchons.
                        </li>
                        <br>
                        <li>
                            Des valves quasi invisibles qui permettent de métamorphoser vos jantes en faisant disparaître vos vieilles valves disgracieuses et proéminentes.
                            Elles sont résistantes et fabriquées en aluminium.
                            Elles sont vendues avec un adaptateur spécifique pour le gonflage de vos pneus.
                        </li>
                        <br>
                        <li>
                            Des caches moyeux qui cachent au moyen d’un capuchon esthétique l’axe apparent du moyeu.
                            Ils sont en acier inoxydable et ne sont pas standard.
                            Ils sont souvent livrés avec des anneaux d’équilibrages.
                            Avant l’achat vous devez mesurer consciencieusement la profondeur et la largeur du cache moyeu nécessaire à vos jantes.
                        </li>
                        <br>
                        <li>
                            Les cabochons de jante sont logo té au nom d’une marque.
                            Les plus connus sont les cabochons de jantes Fuchs de la marque Porsche qui date de 1966.
                            Le set de jantes Fuchs est très tendance, à la fois moderne et rétro.
                            Ces cabochons ont équipé toute une gamme de jante de la marque VW.
                        </li>
                        <br>
                        <li>
                            Les stickers personnalisés se collent au centre de vos enjoliveurs.
                            Vous pouvez les faire réaliser avec votre propre logo, ou celui de la marque de la voiture.
                            Il est déconseillé de laver vos jantes – options au jet haute pression, car les stickers pourraient se décoller.
                        </li>
                    </ul>

                </v-col>
                <v-col class="bc_blue" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                    <v-img src="@/assets/AboutUs.png"/>
                </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phone.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold tc_blue align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="3" xl="3" class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="2" offset-lg="1" xl="3" lg="3">
                        <v-img style="position: absolute; width: 20%;" src="@/assets/illustration-man.png"/>
                    </v-col>
                </v-row>
            </v-container>


            <v-col class="bc_white tc_black" >
            <v-row class="font-weight-regular
                                    text-xl-h5
                                    text-lg-body-1
                                    text-md-caption
                                    text-sm-caption
                                    text-caption">
                <v-col offset-lg="1" lg="7">
                    <br>
                    <br>
                    <p class="font-weight-bold
                               text-xl-h5
                               text-lg-h6
                               text-md-subtitle-1
                               text-sm-subtitle-1
                               text-subtitle-2">JANTES – OPTIONS – RABAISSER SA VOITURE</p>
                    <br>
                    <p>
                        On rencontre fréquemment des voitures rabaissées.
                        Les propriétaires ont d’abord changé les jantes – options et les pneus puis la suspension.
                        Les ingénieurs, à la conception des véhicules ont calculé des moyennes en ce qui concerne la suspension, le nombre de personnes dans l’habitacle, le déport dans les virages, le freinage et les accélérations.
                        Ces paramètres moyens peuvent être modifiés afin que la voiture corresponde à vos exigences.
                        Changer une jante d’usine, rabaisser la suspension, ajouter des ressorts sportifs, et vos sensations de conduite seront transformées.
                        Outre l’aspect plus agressif du véhicule, la perception de conduite est plus vive et plus sportive.
                    </p>
                    <br>
                    <p>
                        Pour votre sécurité, ces modifications, doivent avoir lieu avec un professionnel.
                        Chaque remaniement peu avoir des effets néfastes et implique d’autres réglages.
                        Vous devez définir vos besoins et supporter les conséquences de ces changements.
                        N’oublier pas que les jantes et les pneus sont le seul contact de votre voiture avec le sol.
                        Vous devez obtenir une fiabilité dans la conduite et une résistance de tenue de route.
                    </p>
                    <br>
                    <p>Pour en savoir plus sur les jantes, le lecteur pourra se reporter aux différents articles qui traitent de cette question, sur le présent site.</p>
                    <p>Droit d’auteur protégé par les articles L. 111-1 et L. 123-1, du code de la propriété intellectuelle.</p>
                </v-col>
            </v-row>
        </v-col>
        </v-main>


        <v-main v-if="VUE_APP_SITE_ID === '2'">
            <v-col class="py-0">
                <v-row class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 font-weight-bold rotate">
                            Jantes - Options
                        </h1>

                </v-col>
                <v-col lg="5" >
                    <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5  font-weight-bold ">
                        Jantes - Options
                    </h1>
                    <p class="font-weight-bold
                                   text-xl-h5
                                   text-lg-h6
                                   text-md-subtitle-1
                                   text-sm-subtitle-1
                                   text-subtitle-2">Accessoires de jantes et options de modification</p>
                    <br>
                    <p>
                        Un certain nombre d’accessoires existent sur le marché pour « peaufiner » le look de vos jantes !
                        Vous projetez d’opter pour des jantes larges ou de rabaisser votre voiture ? Ces quelques informations techniques vous seront utiles.
                    </p>

                    <br>
                    <p class="font-weight-bold
                                   text-xl-h5
                                   text-lg-h6
                                   text-md-subtitle-1
                                   text-sm-subtitle-1
                                   text-subtitle-2">Des accessoires de jantes variés</p>
                    <p>Pour compléter la gamme d’accessoires pour les jantes vous trouverez :</p>
                    <ul>
                        <li>
                            Des Bouchons de valve : Il s’agit de capuchons design en caoutchouc prévus pour venir remplacer les bouchons d’origine.
                        </li>
                        <br>
                        <li>
                            Des valves cachées :
                            Pourquoi opter pour des Valves dites dissimulées ? Les valves, à l’origine peu esthétiques, sont rapidement tachées et salies. De plus, elles peuvent faire l’objet de tentative de vol d’où le choix de valves pratiquement invisibles qui, de surcroît, se révèlent beaucoup plus discrètes et neutres.
                        <br>
                            Ces valves en aluminium sont bien sur livrées avec un adaptateur spécifique pour permettre le gonflage des pneus.
                        </li>
                        <br>
                        <li>
                            Des caches moyeux :
                            Le cache moyeu, conçu en acier inoxydable, vient se positionner sur le boulon de fixation du moyeu pour le protéger. Il n’est, par définition, pas normalisé. Il convient donc de mesurer très minutieusement (valeur exprimée en mm) le diamètre et la profondeur du cache moyeu compatible avec vos jantes.
                        </li>
                        <br>
                        <li>
                            Les cabochons de jante :
                            Ils viennent recouvrir les écrous de fixation. Généralement, les cabochons de jante sont personnalisés avec le logo d’une marque. Parmi les plus iconiques, on retiendra les cabochons Porsche (jantes Fuchs), Volkswagen sachant que toutes les marques automobiles sont représentées : Audi, BMW, Mercedes, Peugeot…

                        </li>
                        <br>
                        <li>
                            Les stickers personnalisés pour enjoliveurs :
                            Il est possible de les faire personnaliser avec une signalétique ou un graphisme particulier ou avec le logo de la marque du véhicule. Nous attirons juste votre attention sur les risques d’un lavage dans des installations en libre-service. Le sticker résiste rarement aux jets haute pression.

                        </li>
                    </ul>

                </v-col>
                <v-col class="pa-0" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                    <v-img src="@/assets/AboutUsDJ.png"/>
                </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phoneDJ.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33974191801">09 74 19 18 01</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="5" offset-lg="1"  class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>

                </v-row>
            </v-container>

            <v-col class="bc_white tc_black" >
                <v-row class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col offset-lg="1" lg="10">
                        <br>
                        <br>
                        <p class="font-weight-bold
                                   text-xl-h5
                                   text-lg-h6
                                   text-md-subtitle-1
                                   text-sm-subtitle-1
                                   text-subtitle-2">Quelques options de modification de jantes</p>
                        <br>
                        <p class="font-weight-bold
                                   text-xl-h5
                                   text-lg-h6
                                   text-md-subtitle-1
                                   text-sm-subtitle-1
                                   text-subtitle-2">Déport et largeur de jantes</p>
                        <br>
                        <p>
                            1) Hypothèse du choix d’une jante au déport inférieur à celui d’origine

                        </p>
                        <p>
                            Dans ce cas, le parallélisme et l’appui s’en trouvent affectés.
                        </p>
                        <p>Conséquences : </p>
                        <p>
                            Usure mécanique des cardans, rotules,
                            <br>
                            Erosion rapide des pneus
                            <br>
                            Instabilité dans la direction (vibrations)

                        </p>
                        <p>
                            Un passage de roue plus large requiert d’intervenir sur la carrosserie en vue d’élargir les ailes.
                        </p>
                        <br>
                        <p>
                            2) Hypothèse du choix d’une jante de largeur supérieure à celle d’origine

                        </p>
                        <p>
                            Seule la cote de parallélisme est légèrement impactée et non la cote d’appui.
                            D’un point de vue mécanique, il n’y a donc aucune incidence réelle.
                            Le montage d’un pneumatique plus large est possible si la jante ne déborde pas de plus de 2 pouces (1 pouce = 2,54 cm).
                        </p>
                        <p class="font-weight-bold
                                   text-xl-h5
                                   text-lg-h6
                                   text-md-subtitle-1
                                   text-sm-subtitle-1
                                   text-subtitle-2">Conséquences d’une voiture rabaissée</p>
                        <p>
                            Le rabaissement d’une voiture implique certes le changement de la suspension mais avant tout, celui des jantes et des pneus.
                        </p>
                        <p>
                            Les constructeurs ont calculé des seuils très précis quant au nombre de passagers possible, aux suspensions, au freinage, à l’accélération, au déport dans les courbes…qu’il sera possible de modifier sous certaines conditions et dans certaines limites.
                            Ainsi, avant tout changement des jantes d’origines, il est fortement recommandé pour votre sécurité et pour rester en conformité avec la loi, de vous adresser à un professionnel compétant.
                            Dés lors que les roues et les jantes sont concernées, de telles modifications impactent nécessairement sur la tenue de route de votre véhicule
                        </p>
                    </v-col>
                </v-row>
            </v-col>
        </v-main>

        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "../headers/HomepageHeaderEj";
import HomepageHeaderDj from "../headers/HomepageHeaderDj";
import HomepageFooterEj from "../footer/HomepageFooterEj";
import HomepageFooterDj from "../footer/HomepageFooterDj";

    export default {
        name: "JantesOptions",
        components:{
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,

        },
        data: () => ({
            VUE_APP_SITE_ID:null
        }),
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        },
        metaInfo() {
            return {
                title: `Options - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + `  - Jantes, Pneus & Accessoires`,
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `Options - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {name: 'twitter:description', content: `Découvrez les options disponibles pour agrémenter vos jantes, présentées par ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `Options -` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {property: 'og:site_name', content: this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`},
                    {property: 'og:type', content: 'Website'},
                    {property: 'og:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},
                    {property: 'og:description', content: `Découvrez les options disponibles pour agrémenter vos jantes, présentées par ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    {name: 'robots', content: 'index,follow'}
                ]
            }
    },
    }
</script>

<style scoped>
    .border_left{
        border-left: 2px solid #1DE4AF
    }
    .rotate {
        transform: rotate(-90deg);
        margin-left: -8%;
        margin-top: 12%;
        position: absolute;
    }
</style>
