

const state = () =>({
    detailCommande:false,
    detailAccessoires:false
})


const mutations = {

    setDetailCommande(state, config){
        state.detailCommande = config
    },
    setDetailAccessoires(state, config){
        // console.log('showDetailAccessoires', config);
        state.detailAccessoires = config
    },

}


const actions = {

}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}
