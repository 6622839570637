<template>
    <v-app >
        <v-row v-if="$vuetify.breakpoint.lgAndUp" class="bc_white " >
            <v-col lg="9" class="pa-0">

                <ConfigurateurHeader :header-title="headerTitle" class="bc_lightGrey"/>

                <v-col class="">
                    <v-col offset-lg="1" lg="9">
                        <v-container v-if="!montagePersoStep1" class="pa-0">
<!--                        <v-container class="pa-0">-->
                            <v-row>
                                <v-col cols="6" v-if="!montagePersoStep2" class="pb-0 px-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                    <span class="font-weight-bold">Pneumatiques conseillés pour votre véhicule :</span>
                                    <br/>
                                    <!-- <span v-if="CaracteristiquePneuPossible.length > 1" @click="switchMontage('montagePersoStep1')" class="body-2 font-weight-medium link " >
                                        Je souhaite le montage personnalisé
                                    </span> -->
                                </v-col>
                                <v-main v-if='typeMonte == "par4"'>
                                    <v-col class="pb-0" v-if="montagePreco">
                                        <v-col cols='6'>
                                            <span style='font-size:14px' > Choix 4 pneus : </span>
                                            <v-radio-group v-model="firstRadio">
                                                <v-radio
                                                    :class="{'tc_turquoise' : index === conseilId && VUE_APP_SITE_ID === '1', 'tc_orange' : index === conseilId && VUE_APP_SITE_ID === '2' }" class="font-weight-bold pl-3"
                                                    v-for="(conseil, index) in CaracteristiquePneuPossible"
                                                    :key="index"
                                                    :label="`${conseil}`"
                                                    @click="switchConseil(index, conseil)"
                                                ></v-radio>
                                            </v-radio-group>
<!--                                            <v-row v-for="(conseil, index) in CaracteristiquePneuPossible" :key="index">-->
<!--&lt;!&ndash;                                                <span @click="switchConseil(index, conseil)" :style="[index === conseilId && VUE_APP_SITE_ID === '1' ? {color:'#1DE4AF'} : {color: '#899EA8'}, index === conseilId && VUE_APP_SITE_ID === '2' ? {color:'#FC5C1F'} : {color: '#899EA8'} ]" class="font-weight-bold pl-3" style="cursor: pointer">{{conseil}}</span>&ndash;&gt;-->
<!--                                                <span @click="switchConseil(index, conseil)" style="cursor: pointer;color:#899EA8"  :class="{'tc_turquoise' : index === conseilId && VUE_APP_SITE_ID === '1', 'tc_orange' : index === conseilId && VUE_APP_SITE_ID === '2' }" class="font-weight-bold pl-3">{{conseil}}</span>-->
<!--                                            </v-row>-->
                                        </v-col>
                                    </v-col>
                                </v-main>

                                <v-main v-if='typeMonte == "decale"'>
                                    <v-row>
                                        <v-col cols='6'>
                                            <span style='font-size:14px' > Choix Avant : </span>
                                            <v-radio-group v-model="firstRadioAv">
                                                <v-radio
                                                    :class="{'tc_turquoise' : index === conseilId && VUE_APP_SITE_ID === '1', 'tc_orange' : index === conseilId && VUE_APP_SITE_ID === '2' }" class="font-weight-bold pl-3"
                                                    v-for="(conseil, index) in CaracteristiquePneuPossibleFront"
                                                    :key="index"
                                                    :label="`${conseil}`"
                                                    @click="switchConseilDecale(index, conseil, 'Front')"
                                                ></v-radio>
                                            </v-radio-group>
<!--                                            <v-row v-for="(conseil, index) in CaracteristiquePneuPossibleFront" :key="index">-->
<!--&lt;!&ndash;                                                <span @click="switchConseilDecale(index, conseil, 'Front')" :style="[index === conseilIdFront ? {color:'#1DE4AF'} : {color: '#899EA8'}]" class="font-weight-bold pl-3" style="cursor: pointer">{{conseil}}</span>&ndash;&gt;-->
<!--                                                <span @click="switchConseilDecale(index, conseil, 'Front')" :class="{'tc_turquoise' : index === conseilIdFront && VUE_APP_SITE_ID === '1', 'tc_orange' : index === conseilIdFront && VUE_APP_SITE_ID === '2' }" class="font-weight-bold pl-3" style="cursor: pointer">{{conseil}}</span>-->
<!--                                            </v-row>-->
                                        </v-col>
                                        <v-col cols='6'>
                                            <span style='font-size:14px'>  Choix Arrière : </span>

                                            <v-radio-group v-model="firstRadioArr">
                                                <v-radio
                                                    :class="{'tc_turquoise' : index === conseilId && VUE_APP_SITE_ID === '1', 'tc_orange' : index === conseilId && VUE_APP_SITE_ID === '2' }" class="font-weight-bold pl-3"
                                                    v-for="(conseil, index) in CaracteristiquePneuPossibleRear"
                                                    :key="index"
                                                    :label="`${conseil}`"
                                                    @click="switchConseilDecale(index, conseil, 'Rear')"
                                                ></v-radio>
                                            </v-radio-group>
<!--                                            <v-row v-for="(conseil, index) in CaracteristiquePneuPossibleRear" :key="index">-->
<!--&lt;!&ndash;                                                <span @click="switchConseilDecale(index, conseil, 'Rear')" :style="[index === conseilIdRear ? {color:'#1DE4AF'} : {color: '#899EA8'}]" class="font-weight-bold pl-3" style="cursor: pointer">{{conseil}}</span>&ndash;&gt;-->
<!--                                                <span @click="switchConseilDecale(index, conseil, 'Rear')" :class="{'tc_turquoise' : index === conseilIdRear && VUE_APP_SITE_ID === '1', 'tc_orange' : index === conseilIdRear && VUE_APP_SITE_ID === '2' }" class="font-weight-bold pl-3" style="cursor: pointer">{{conseil}}</span>-->
<!--                                            </v-row>-->
                                        </v-col>
                                    </v-row>
                                </v-main>

                            </v-row>

                          <ais-instant-search :search-client="searchClient" index-name="ej_pneu">
                            <ais-configure :hitsPerPage="20" :filters='filtreAlgolia' />

                            <v-row >
                                <v-col :class="{'col-lg-8':montagePersoStep2}">
                                    <v-card>
                                        <v-row>
                                            <v-col :class="{'col-lg-3':montagePreco, 'col-lg-5': montagePersoStep2}" class="align-self-center border_right">
                                                <v-card-text class="py-0">
                                                   <!-- <ais-menu-select class='font-weight-bold fontSize' attribute="marque" :limit="400" style="padding-right: 0px;">
                                                    <template slot="defaultOption" >
                                                        Toutes les marques
                                                    </template>
                                                    <template slot="item" slot-scope="{ item }">
                                                        {{ item.label }}
                                                    </template>
                                                </ais-menu-select> -->
                                                <v-select hide-details solo label="Marque" :items=MarquePneu class="bold" :value='MarquePneuValue' v-model="MarquePneuValue" style="z-index:3" @change="changeMarquePneu(MarquePneuValue)"/>
                                                <v-btn class="mt-3" @click='ResetFiltre()'>Réinitialiser</v-btn>
                                                </v-card-text>
                                            </v-col>
                                            <v-col :class="{'col-lg-3':montagePreco, 'col-lg-6': montagePersoStep2, 'border_right' : montagePreco}" class="flex-center">
                                                <v-card-text class="py-0  font-weight-bold">
                                                    <span class="tc_lightGrey">Options</span>

                                                    <v-radio-group @change="swap(optionsRadios)" hide-details dense v-model="optionsRadios" row class="fontSize mt-0">
                                                        <v-radio label="Non runflat" value="0" />
                                                        <v-radio label="Runflat" value="1" />
                                                    </v-radio-group>
                                                </v-card-text>
                                            </v-col>
                                            <v-col lg="6" v-if="!montagePersoStep2">
                                                <v-card-text class="pa-0  font-weight-bold">
                                                    <span class="tc_lightGrey">Saisons</span>

                                                    <v-radio-group  @change="swap(saisonsRadios)" hide-details dense v-model="saisonsRadios" row class="fontSize mt-0">
                                                        <v-radio class="mr-2" label="Été" value="3"/><v-icon color="yellow" class="mr-3">mdi mdi-brightness-7</v-icon>
                                                        <v-radio class="mr-2" label="Hiver" value="2"/><v-icon color="light-blue">mdi mdi-snowflake</v-icon>
                                                        <v-radio class="mr-2" label="4 saisons" value="1"/><v-img src="@/assets/icons/mi_saison.svg" style="max-width: 6%"/>
                                                    </v-radio-group>

<!--                                                    <v-radio-group  @change="swap(saisonsRadios)" hide-details dense v-model="saisonsRadios" row class="fontSize mt-0">-->
<!--                                                        <v-radio class="mr-2" label="Été" value="3"/><v-icon color="yellow" class="mr-3">mdi mdi-brightness-7</v-icon>-->
<!--                                                        <v-radio class="mr-2" label="Hiver" value="2"/><v-icon color="light-blue">mdi mdi-snowflake</v-icon>-->
<!--                                                        <div class="d-flex">-->
<!--                                                            <v-radio class="mr-2" label="4 saisons" value="1"/><v-img src="@/assets/icons/mi_saison.svg" style="max-width: 6%"/>-->
<!--                                                        </div>-->
<!--                                                    </v-radio-group>-->
                                                </v-card-text>
                                               <!-- <v-btn style='width: 25px;height: 22px;' @click='ResetFiltre()'> Reset </v-btn> -->
                                            </v-col>

                                        </v-row>
                                    </v-card>
                                </v-col>
                                <v-col v-if="montagePersoStep2" class="align-self-center">
                                     <span @click="switchMontage('montagePersoStep1')" class="body-2 font-weight-medium link" >
                                        Modifier dimensions
                                    </span>
                                </v-col>
                            </v-row>
                            <v-row >
<!--                                <v-container class="overflow-y-auto" style="position: relative" :style="[montagePreco ? {'max-height': '270px'} : {'max-height' : '360px'}]">-->

  <!-- ALGOLIA -->
                                <v-container  style="position: relative; overflow-y: scroll" ref='TopListPneu' :style="[montagePreco ? {'max-height': '400px'} : {'max-height' : '360px'}]">

                                    <v-col class="bc_white" >
                                        <v-content v-if="loaderStatus === true">
                                            <v-img v-if="VUE_APP_SITE_ID === '1'" src="@/assets/loader.gif" style="max-width: 40%; margin: auto" alt='Espace-Jantes - Chargement Jantes' title="Espace-Jantes - Chargement Jantes"/>
                                            <v-img v-if="VUE_APP_SITE_ID === '2'" src="@/assets/loaderDJ.gif" style="max-width: 40%; margin: auto" alt='Distri-Jantes - Chargement Jantes' title="Distri-Jantes - Chargement Jantes"/>
                                        </v-content>
                                        <v-content v-if="loaderStatus === false">
                                            <v-row v-for="(pneu, index) in currentListePneu " :key="index" >
                                            <v-row  class="border_lightGrey">
                                                <v-col lg="2" class=" justify-end align-end " style="position: relative">
<!--                                                <v-img :src="require('@/assets/Logo_Pneus/' + (pneu.marque).toLowerCase() + '.svg')" style="position: absolute;top: -46px;left: 0;width: 100%;height:80%;z-index:3;"/>-->
                                                    <v-img :src="getLogoPneuUrlImg(pneu.marque)" contain style="height:80px;width: 100%;z-index:3;background-color: rgba(255,255,255,0.7); background-size: 100%" class="img_logoPneu"/>
                                                    <v-img :src="pneu.photo" @error="pneu.photo = pneuDefaut" class="pneuPhoto" style="min-width: 60%; z-index:2; width:70%;margin: 0 auto" :class="[ pneu.cheat !== 'InstaTurbo' ? '' : 'mt-4' ]"/>
                                                    <!-- <v-img :src="getLogoPneuUrlImg(pneu.marque)" /> -->
                                                    <!-- <v-img src="@/assets/pneu_etape3.png" style="position: absolute;bottom: 0;right: 0;min-width: 60%; z-index:99" :class="[ pneu.cheat !== 'InstaTurbo' ? 'mt-9' : 'mt-4' ]"/> -->
                                                </v-col>

                                                <v-col lg="4" class="body-2 pb-0 text__img "  :class="{'choosenTire' : selectedTireIndex === index, 'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}" >

                                                    <v-container v-if="montagePreco" class="pa-0">
                                                        <v-col class="pa-0 font-weight-bold"> {{pneu.marque}} {{pneu.modele}} </v-col>
                                                        <template v-if='typeMonte == "decale"'>
                                                            <v-col class="pa-0 caption"> Pneu Avant : {{ParamsFront}} {{pneu.indiceDeCharge}}  {{pneu.indiceDeVitesse}}</v-col>
                                                            <v-col class="pa-0 caption"> Pneu Arriere : {{ParamsRear}} {{pneu.indiceDeChargeRear}}  {{pneu.indiceDeVitesse}} </v-col>
                                                        </template>

                                                        <template v-if='typeMonte == "par4"'>
                                                            <v-col class="pa-0 caption"> Pneu : {{pneu.HBV}}/{{pneu.width}} R{{pneu.diametre}} {{pneu.indiceDeCharge}}  {{pneu.indiceDeVitesse}}</v-col>
                                                        </template>

                                                        <v-col class="pa-0 caption">
                                                            <span style="display: flex;align-items: center;">
                                                                Pneu {{pneu.articleDescription}}
                                                                <v-icon v-if="pneu.ete === 1" color="yellow">mdi mdi-brightness-7</v-icon>
                                                                <v-icon v-if="pneu.hiver === 1" color="light-blue">mdi mdi-snowflake</v-icon>
                                                                <v-img v-if="pneu.allsaison === 1" src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                            </span>

                                                            <br>
                                                            <span style='text-decoration: underline'>Spécificités :</span>
                                                            <div v-for="(OneSpecificite, index) in pneu.tableauSpecificite" :key="OneSpecificite" class='d-inline'>
                                                                <v-tooltip right>
                                                                    <template v-slot:activator="{ on, attrs }">
                                                                        <span v-bind="attrs" v-on="on" style="color: #1840DA !important; font-weight: bold;">  {{ OneSpecificite }} <span v-if="index +1 < pneu.tableauSpecificite.length">,</span> </span>

                                                                    </template>
                                                                    <span >{{gestionSpecifite(OneSpecificite)}}</span>
                                                                </v-tooltip>
                                                            </div>

                                                        </v-col>
                                                        <v-col  class="pa-0 d-flex caption" style="align-items: center">
                                                            <span>{{pneu.desc}}</span>
                                                            <v-icon v-if="pneu.season === 'été'" color="yellow">mdi mdi-brightness-7</v-icon>
                                                            <v-icon v-if="pneu.season === 'hiver'" color="light-blue">mdi mdi-snowflake</v-icon>
                                                            <v-img v-if="pneu.season === '4 saisons'" src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                        </v-col>

                                                        <v-col class="py-0">
                                                                <v-row>
                                                                    <v-col lg="2" class="py-0 pl-2">
                                                                        <v-icon class="ml-2">mdi mdi-gas-station</v-icon>
                                                                        <v-img v-if="getUrlImgRate(pneu.FuelEfficiency)" style="height: 70%" :src="getUrlImgRate(pneu.FuelEfficiency)"/>
                                                                        <p v-else class="ml-3" >-</p>
                                                                    </v-col>
                                                                    <v-col lg="2" class="py-0 pl-2">
                                                                        <v-icon class="ml-2">mdi mdi-weather-pouring</v-icon>
                                                                        <v-img v-if="getUrlImgRate(pneu.wetGrip)" style="height: 70%" :src="getUrlImgRate(pneu.wetGrip)"/>
                                                                        <p v-else class="ml-3" >-</p>
                                                                    </v-col>
                                                                    <v-col lg="2" class="py-0 pl-2">
                                                                        <v-icon class="ml-2">mdi mdi-volume-high</v-icon>
                                                                        <p v-if="pneu.noiseInDb !== 0" class="ml-2 mt-2 mb-0 font-weight-bold"> {{pneu.noiseInDb}} </p>
                                                                        <p v-else class="ml-3" >-</p>
                                                                    </v-col>
                                                                </v-row>
                                                                <v-row>
                                                                    <v-col lg="2" class="pa-0">
                                                                        <!-- <v-img :src="pneu.rollingResistance" /> -->
                                                                    </v-col>
                                                                    <v-col lg="2" class="pa-0">
                                                                        <!-- <v-img :src="pneu.wetGrip"/> -->
                                                                    </v-col>
                                                                    <v-col lg="2" class="pa-0">
                                                                        <!-- <v-img :src="pneu.noiseInDb"/> -->
                                                                    </v-col>
                                                                </v-row>
                                                            </v-col>

                                                    </v-container>
                                                    <v-container v-if="montagePersoStep2" class="pa-0">
                                                        <v-col class="pa-0 font-weight-bold">{{pneu.modele}}</v-col>
                                                        <v-col class="pa-0 caption">Avant : {{pneu.caracAvant}} {{pneu.detailAvant}}</v-col>
                                                        <v-col class="pa-0 caption">Arrière : {{pneu.caracArriere}} {{pneu.detailArriere}}</v-col>
                                                        <v-col  class="pa-0 d-flex caption" style="align-items: center">
                                                            <span>{{pneu.desc}}</span>
                                                            <v-icon v-if="pneu.season === 'été'" color="yellow">mdi mdi-brightness-7</v-icon>
                                                            <v-icon v-if="pneu.season === 'hiver'" color="light-blue">mdi mdi-snowflake</v-icon>
                                                            <v-img v-if="pneu.season === '4 saisons'" src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                        </v-col>

                                                        <v-col class="py-0">
                                                            <v-row>
                                                                <v-col lg="2" class="py-0 pl-2"><v-icon>mdi mdi-gas-station</v-icon></v-col>
                                                                <v-col lg="2" class="py-0 pl-2"><v-icon>mdi mdi-weather-pouring</v-icon></v-col>
                                                                <v-col lg="2" class="py-0 pl-2"><v-icon>mdi mdi-volume-high</v-icon></v-col>
                                                            </v-row>
                                                            <v-row>
                                                                <v-col lg="2" class="pa-0">
                                                                    <v-img :src="pneu.consoRate" />
                                                                </v-col>
                                                                <v-col lg="2" class="pa-0">
                                                                    <v-img :src="pneu.weatherRate"/>
                                                                </v-col>
                                                                <v-col lg="2" class="pa-0">
                                                                    <v-img :src="pneu.soundRate"/>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                    </v-container>




    <!--                                                <v-img @mouseover="switchHover(index)" @mouseleave="switchHover(index)"  src="@/assets/icons/icon_infos.svg" style="max-height: 40%; cursor: pointer"/>-->


                                                </v-col>

                                                <v-col lg="2"  v-if='typeMonte == "par4"' class=" text-center d-flex" style=" align-items: center"  :class="{'choosenTire' : selectedTireIndex === index}" >
                                                    <v-col class="pa-0">
                                                        <v-col class="font-weight-bold body-1 pa-0 tc_blue" >{{getRoundPrice(null,pneu.prixNet, '4pneus')}} € </v-col>
                                                        <v-col class="caption pa-0 font-weight-medium">les 4 pneus</v-col>
                                                    </v-col>
                                                </v-col>
                                                <v-col lg="2"  v-if='typeMonte == "par4"' class=" text-center d-flex" style="align-items: center"  :class="{'choosenTire' : selectedTireIndex === index}"  >
                                                    <v-col class="pa-0">
                                                    <v-col  class="font-weight-bold pa-0" :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">{{getRoundPrice(SelectedJanteAv.public_price, pneu.prixNet, 'SOUS TOTAL' )}} € </v-col>
                                                    <v-col class="pa-0 caption font-weight-medium">le pack complet</v-col>
                                                    </v-col>
                                                </v-col>

                                                <v-col lg="2"  v-if='typeMonte == "decale"' class=" text-center d-flex" style="align-items: center"  :class="{'choosenTire' : selectedTireIndex === index}" >
                                                    <v-col class="pa-0">
                                                        <v-col class="font-weight-bold body-1 pa-0 tc_blue">{{getRoundPriceMonteDecale(null, null, pneu.prixNet, pneu.prixNetRear, '4pneus decale')}} € </v-col>
                                                        <v-col class="caption pa-0 font-weight-medium">les 4 pneus</v-col>
                                                    </v-col>
                                                </v-col>
                                                <v-col lg="2"  v-if='typeMonte == "decale"' class=" text-center d-flex" style="align-items: center"  :class="{'choosenTire' : selectedTireIndex === index}"  >
                                                    <v-col class="pa-0">
                                                    <v-col  class="font-weight-bold pa-0 " :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">{{getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, pneu.prixNet, pneu.prixNetRear,  'SOUS TOTAL DECALE' )}} €</v-col>
                                                    <v-col class="pa-0 caption font-weight-medium">le pack complet</v-col>
                                                    </v-col>
                                                </v-col>
                                                <v-col lg="2" style=" align-items: center" class="caption  text-center d-flex px-1"  :class="{'choosenTire' : selectedTireIndex === index, 'tc_blue': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}" >
                                                <v-col class="pa-0">
                                                <v-img v-if="VUE_APP_SITE_ID === '1'" src="@/assets/icons/icon_cart_blue.svg" style="width: 35%;" class="ma-auto"/>
                                                <v-img v-if="VUE_APP_SITE_ID === '2'" src="@/assets/icons/icon_cart_orange.svg" style="width: 35%;" class="ma-auto"/>
                                                <span>En stock</span>
                                                <br>
                                                <v-btn @click="selectPneuDecale(index,pneu)" dense small v-if='typeMonte == "decale"'>Sélectionner</v-btn>

                                                <v-btn @click="selectPneu(index, pneu)" dense small v-if='typeMonte == "par4"'>Sélectionner</v-btn>
                                                </v-col>
                                            </v-col>

                                        </v-row>
                                    </v-row>


                                        </v-content>
                                        <v-row v-if="(currentListePneu.length == 0)">
                                            <span>Aucun résultat ne correspond à votre recherche. <br> Pour plus d'information n'hesitez pas a nous contacter au : <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a> </span>
                                        </v-row>

                                    </v-col>

                                </v-container>

                            </v-row>
                          <v-row v-if="(showPagination)">
                            <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="8" class="pt-0 align-self-center">
                            <ais-pagination  class="pt-3 MyCustomPaginationRoot" >
                        <!-- 'ais-Pagination-item': 'MyCustomPagination',
                        'ais-Pagination-link': 'MyCustomPagination',
                        'ais-Pagination-item--selected': 'MyCustomPaginationActive',
                        'ais-Pagination-item--firstPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--previousPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--nextPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--lastPage': 'MyCustomPaginationNavigate',
                        }"> -->
                            <ul
                            class="MyCustomPagination ais-Pagination-list"
                            slot-scope="{
                              currentRefinement,
                              nbPages,
                              pages,
                              isFirstPage,
                              isLastPage,
                              refine,
                              createURL
                            }"
                          >
                            <li class="MyCustomPagination MyCustomPaginationNavigate" style="float:left; margin-left:5px;">
                              <a :href="createURL(0)" @click.prevent="refine(0)" @click="changeOffset(0)" class="ais-Pagination-link MyCustomPagination" >
                                ‹‹
                              </a>
                            </li>
                            <li style="float:left; margin-left:2px;" class="MyCustomPaginationNavigate">
                              <a
                              class="ais-Pagination-link MyCustomPagination"
                                :href="createURL(currentRefinement - 1)"
                                @click.prevent="refine(currentRefinement - 1)"
                                @click="changeOffset(0)"
                              >
                                ‹
                              </a>
                            </li>
                            <li v-for="page in pages" :key="page" style="float:left; margin-left:5px;" class="ais-Pagination-item MyCustomPagination">
                              <a
                                class="ais-Pagination-link MyCustomPagination"
                                :href="createURL(page)"
                                :class="{'bc_turquoise' : page === currentRefinement && VUE_APP_SITE_ID === '1', 'bc_orange' : page === currentRefinement && VUE_APP_SITE_ID === '2' , 'tc_white' : page === currentRefinement && VUE_APP_SITE_ID === '2' }"
                                @click.prevent="refine(page)"
                                @click="changeOffset(page)"
                              >
                                {{ page + 1 }}
                              </a>
                            </li>
                            <li v-if="!isLastPage" style="float:left; margin-left:5px;" class="MyCustomPaginationNavigate">
                              <a
                                :href="createURL(currentRefinement + 1)"
                                @click.prevent="refine(currentRefinement + 1)"
                                @click="changeOffset(currentRefinement+1)"
                                class="ais-Pagination-link MyCustomPagination "
                              >
                                ›
                              </a>
                            </li>
                            <li v-if="!isLastPage" style="float:left;margin-left:5px;" class="MyCustomPaginationNavigate">
                              <a :href="createURL(nbPages)" @click.prevent="refine(nbPages)" @click="changeOffset(nbPages-1)" class="ais-Pagination-link MyCustomPagination" >
                                ››
                              </a>
                            </li>
                          </ul>
                        </ais-pagination>
                                </v-col>
                        </v-row>
                          </ais-instant-search>
                        </v-container>



                        <v-container  v-if="montagePersoStep1" class="pa-0">

                            <v-col offset-lg="2" lg="9" class="mt-12">
                                <v-img src="@/assets/Voiture.svg"/>
                            </v-col>
                            <v-row class="bc_white" >
                                <v-col offset-lg="2" lg="4">
                                    <v-content class="font-weight-bold pb-3 caption text-center">
                                        Choisissez votre dimension de pneus avant
                                    </v-content>
                                    <v-select v-model="SelectedTireAvant" :items="CaracteristiquePneuPossible"  @change="changeSizeAv"  label="Taille de pneus compatibles" solo class="pa-0 font-weight-bold" :class="{'body-2':$vuetify.breakpoint.lg}"/>

                                </v-col>
                                <v-col offset-lg="1" lg="4" >
                                    <v-content class="font-weight-bold pb-3 caption text-center">
                                        Choisissez votre dimension de pneus arrière
                                    </v-content>
                                    <v-select v-model="SelectedTireArriere" :items="CaracteristiquePneuPossible" @change="changeSizeArr" label="Taille de pneus compatibles" solo  class="pa-0 font-weight-bold" :class="{'body-2':$vuetify.breakpoint.lg}"/>

                                </v-col>
                                <v-col cols="6" class=" pb-0 align-center d-flex" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                    <span @click="switchMontage('montagePreco')" class="body-2 font-weight-medium link  " >
                                            Je souhaite le montage préconisé
                                    </span>
                                </v-col>
                                <v-col cols="5" v-if="SelectedTireAvant && SelectedTireArriere" class=" pb-0 text-end" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                    <v-btn v-if="loadSwitching === false" @click="loadAndSwitch" class="body-2" >
                                            Trouver des pneus compatibles
                                    </v-btn>
                                    <v-btn v-else loading class="body-2" >
                                            Trouver des pneus compatibles
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-col>
                </v-col>
            </v-col>

            <v-overlay
                    :absolute="true"
                    :value="askPneus"
                    :z-index="7"
                    class="ej-top-0"
            >
                <v-col>

                    <v-card light style="min-width:500px;" :style="[this.VUE_APP_SITE_ID === '1' ? {'border-radius': '50px'} : '']"  >
                        <v-col>
                            <RouterLink :to="{name: 'Etape4', params: {marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}" class="no_deco" >
                                <v-row >
                                    <v-col offset-lg="2" lg="2" class="pa-0">
                                        <v-img v-if="VUE_APP_SITE_ID === '1'" src="@/assets/jante.png" style="min-width: 140%" />
                                        <v-img v-if="VUE_APP_SITE_ID === '2'" src="@/assets/jante_home.png" style="min-width: 140%" />
                                    </v-col>

                                    <v-row>
                                        <v-col offset-lg="1" class="px-0">
                                            <v-col  lg="8" class=" text-center"  :class="{'bc_blue': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2'}">
                                                <v-img v-if="VUE_APP_SITE_ID === '1'" src="@/assets/cart_turquoise.svg" style="max-width: 20%;" class="ma-auto"/>
                                                <v-img v-if="VUE_APP_SITE_ID === '2'" src="@/assets/cartDJ.svg" style="max-width: 20%;" class="ma-auto"/>
                                                <span class="font-weight-bold tc_white">4 jantes seules</span>
                                            </v-col>
                                        </v-col>
                                    </v-row>
                                </v-row>
                            </RouterLink>

                            <v-row @click="askPneus = false" class="pointer">
                                <v-col offset-lg="2" lg="2" class="pa-0 mt-5">
                                    <v-img src="@/assets/pneus-monte-rayon-jantes.png" style="min-width: 140%"/>
                                </v-col>

                                <v-row>
                                    <v-col offset-lg="1" class="px-0">

                                        <v-col lg="8"  class="mt-5 text-center" :class="{'bc_blue': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2'}">
                                            <v-img v-if="VUE_APP_SITE_ID === '1'" src="@/assets/cart_turquoise.svg" style="max-width: 20%;" class="ma-auto"/>
                                            <v-img v-if="VUE_APP_SITE_ID === '2'" src="@/assets/cartDJ.svg" style="max-width: 20%;" class="ma-auto"/>
                                            <span class="font-weight-bold tc_white">4 jantes + 4 pneus</span>
                                        </v-col>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-col>

                    </v-card>
                </v-col>
            </v-overlay>

            <v-col lg="3" class="pa-0">
                <Menu :update="triggerUpdate"/>
            </v-col>
        </v-row>

        <div v-if="$vuetify.breakpoint.lgAndUp" style="height: 16vh"></div>


        <v-col v-if="$vuetify.breakpoint.mdAndDown" class="bc_lightGrey pa-0">
            <v-col lg="9" class="pa-0">

                <ConfigurateurHeader :header-title="headerTitle"/>

                <v-col class="bc_white" style="height: 100%">
                    <v-col offset-lg="1" lg="9">
                        <v-container v-if="!montagePersoStep1" class="pa-0">
                            <!--                        <v-container class="pa-0">-->
                            <v-row>
                                <v-col cols="12" sm="8" v-if="!montagePersoStep2" class="pb-0  px-2" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">
                                    <span class="font-weight-bold">Pneumatiques conseillés pour votre véhicule :</span>
                                </v-col>
                                <v-col cols="12" sm="4" class="pb-0" v-if="montagePreco">
                                    <v-main v-if='typeMonte == "par4"'>
                                        <v-col class="py-0" v-if="montagePreco">
                                            <span style='font-size:14px' > Choix 4 pneus : </span>

                                            <v-radio-group v-model="firstRadio">
                                                <v-radio
                                                    :class="{'tc_turquoise' : index === conseilId && VUE_APP_SITE_ID === '1', 'tc_orange' : index === conseilId && VUE_APP_SITE_ID === '2' }" class="font-weight-bold pl-3"
                                                    v-for="(conseil, index) in CaracteristiquePneuPossible"
                                                    :key="index"
                                                    :label="`${conseil}`"
                                                    @click="switchConseil(index, conseil)"
                                                ></v-radio>
                                            </v-radio-group>
<!--                                            <v-row v-for="(conseil, index) in CaracteristiquePneuPossible" :key="index">-->
<!--                                                <span @click="switchConseil(index, conseil)" :class="{'tc_turquoise' : index === conseilId && VUE_APP_SITE_ID === '1', 'tc_orange' : index === conseilId && VUE_APP_SITE_ID === '2' }"  class="font-weight-bold pl-3" style="cursor: pointer;color:#899EA8">{{conseil}}</span>-->
<!--                                            </v-row>-->
                                        </v-col>
                                    </v-main>

                                    <v-main v-if='typeMonte == "decale"'>
                                        <v-row>
                                            <v-col class="py-0" cols='6'>
                                                <span style='font-size:14px' > Choix Avant : </span>
                                                <v-radio-group v-model="firstRadioAv">
                                                    <v-radio
                                                        :class="{'tc_turquoise' : index === conseilId && VUE_APP_SITE_ID === '1', 'tc_orange' : index === conseilId && VUE_APP_SITE_ID === '2' }" class="font-weight-bold pl-3"
                                                        v-for="(conseil, index) in CaracteristiquePneuPossibleFront"
                                                        :key="index"
                                                        :label="`${conseil}`"
                                                        @click="switchConseilDecale(index, conseil, 'Front')"
                                                    ></v-radio>
                                                </v-radio-group>
<!--                                                <v-row v-for="(conseil, index) in CaracteristiquePneuPossibleFront" :key="index">-->
<!--                                                    <span @click="switchConseilDecale(index, conseil, 'Front')" :style="[index === conseilIdFront ? {color:'#1DE4AF'} : {color: '#899EA8'}]" class="font-weight-bold pl-3" style="cursor: pointer">{{conseil}}</span>-->
<!--                                                </v-row>-->
                                            </v-col>
                                            <v-col class="py-0" cols='6'>
                                                <span style='font-size:14px'>  Choix Arrière : </span>
                                                <v-radio-group v-model="firstRadioArr">
                                                    <v-radio
                                                        :class="{'tc_turquoise' : index === conseilId && VUE_APP_SITE_ID === '1', 'tc_orange' : index === conseilId && VUE_APP_SITE_ID === '2' }" class="font-weight-bold pl-3"
                                                        v-for="(conseil, index) in CaracteristiquePneuPossibleRear"
                                                        :key="index"
                                                        :label="`${conseil}`"
                                                        @click="switchConseilDecale(index, conseil, 'Rear')"
                                                    ></v-radio>
                                                </v-radio-group>
<!--                                                <v-row v-for="(conseil, index) in CaracteristiquePneuPossibleRear" :key="index">-->
<!--                                                    <span @click="switchConseilDecale(index, conseil, 'Rear')" :style="[index === conseilIdRear ? {color:'#1DE4AF'} : {color: '#899EA8'}]" class="font-weight-bold pl-3" style="cursor: pointer">{{conseil}}</span>-->
<!--                                                </v-row>-->
                                            </v-col>
                                        </v-row>
                                    </v-main>

                                </v-col>
                                <v-col>

                                    <!-- <v-content v-if="$vuetify.breakpoint.xs">
                                        <v-btn v-if="montagePreco" width="100%" @click="switchMontage('montagePersoStep1')" class="body-2 font-weight-medium " >
                                            Montage personnalisé
                                        </v-btn>
                                        <v-btn v-if="montagePersoStep2" width="100%" @click="switchMontage('montagePreco')" class="body-2 font-weight-medium " >
                                            Montage préconisé
                                        </v-btn>
                                    </v-content>
                                    <v-content v-if="$vuetify.breakpoint.smAndUp">
                                        <v-btn v-if="CaracteristiquePneuPossible.length > 1 && montagePreco" width="100%" @click="switchMontage('montagePersoStep1')" class="body-2 font-weight-medium " >
                                            Je souhaite le montage personnalisé
                                        </v-btn>
                                        <v-btn v-if="montagePersoStep2" width="100%" @click="switchMontage('montagePreco')" class="body-2 font-weight-medium " >
                                            Je souhaite le montage préconisé
                                        </v-btn>
                                    </v-content> -->
                                </v-col>
                            </v-row>

                            <v-row >
                                <v-col cols="12" class="pa-0 mb-3">
                                    <v-card>
                                        <v-col class="align-self-center border_right">
                                            <v-card-text class="pa-0">
                                                <v-select hide-details solo label="Marque" :items=MarquePneu class="bold" :value='MarquePneuValue' v-model="MarquePneuValue" style="z-index:3" @change="changeMarquePneu(MarquePneuValue)"/>
                                                <v-btn class="mt-3" @click='ResetFiltre()'>Réinitialiser</v-btn>
                                            </v-card-text>
                                        </v-col>
                                        <v-col :class="{'col-lg-4':montagePreco, 'col-lg-6': montagePersoStep2, 'border_right' : montagePreco}" class="flex-center">
                                            <v-card-text class="pa-0  font-weight-bold">
                                                <span class="tc_lightGrey">Options</span>

                                                <v-radio-group @change="swap(optionsRadios)" hide-details dense v-model="optionsRadios" row class="fontSize mt-0">
                                                    <v-radio label="Non runflat" value="0" />
                                                    <v-radio label="Runflat" value="1" />
                                                </v-radio-group>
                                            </v-card-text>
                                        </v-col>
                                        <v-col lg="5" v-if="!montagePersoStep2">
                                            <v-card-text class="pa-0  font-weight-bold">
                                                <span class="tc_lightGrey">Saisons</span>

                                                <v-radio-group  @change="swap(saisonsRadios)" hide-details dense v-model="saisonsRadios" row class="fontSize mt-0">
                                                    <v-radio class="mr-2" label="Été" value="3"/><v-icon color="yellow" class="mr-3">mdi mdi-brightness-7</v-icon>
                                                    <v-radio class="mr-2" label="Hiver" value="2"/><v-icon color="light-blue">mdi mdi-snowflake</v-icon>
                                                    <v-radio class="mr-2" label="4 saisons" value="1"/><v-img src="@/assets/icons/mi_saison.svg" :style="[$vuetify.breakpoint.md ? {'max-width' : '3%'} :
                                                                                                                                                         $vuetify.breakpoint.sm ? {'max-width' : '3%'} :
                                                                                                                                                         $vuetify.breakpoint.xs ? {'max-width' : '5%'} : '']"/>
                                                </v-radio-group>

                                            </v-card-text>
                                        </v-col>
                                        <v-col lg="5" v-if="!montagePersoStep2">
                                            <v-btn class="mt-3" @click='ResetFiltre()'>Réinitialiser</v-btn>
                                        </v-col>
                                        <v-col v-if="montagePersoStep2" class="align-self-center">
                                            <v-btn width="100%" @click="switchMontage('montagePersoStep1')" class="body-2 font-weight-medium" >
                                                Modifier dimensions
                                            </v-btn>
                                        </v-col>
                                    </v-card>
                                </v-col>

                            </v-row>

                            <v-row >
                                <!--                                <v-container class="overflow-y-auto" style="position: relative" :style="[montagePreco ? {'max-height': '270px'} : {'max-height' : '360px'}]">-->
                                <v-container  style="position: relative; overflow-y: scroll" ref='TopListPneu' :style="[montagePreco ? {'max-height': '400px'} : {'max-height' : '360px'}]">
                                    <v-col class="bc_white" >

                                        <v-content v-if="loaderStatus === true">
                                            <v-img v-if="VUE_APP_SITE_ID === '1'" src="@/assets/loader.gif" style="max-width: 40%; margin: auto" alt='Espace-Jantes - Chargement Jantes' title="Espace-Jantes - Chargement Jantes"/>
                                            <v-img v-if="VUE_APP_SITE_ID === '2'" src="@/assets/loaderDJ.gif" style="max-width: 40%; margin: auto" alt='Distri-Jantes - Chargement Jantes' title="Distri-Jantes - Chargement Jantes"/>
                                        </v-content>
                                        <v-content v-if="loaderStatus === false">
                                            <v-row v-for="(pneu, index) in currentListePneu" :key="index">
                                            <v-row>

                                                <v-col cols="4" class="pr-0 pb-0 justify-end align-end NoBorderRightBot_black " style="position: relative">
<!--                                                    <v-img :src="require('@/assets/Logo_Pneus/' + (pneu.marque).toLowerCase() + '.svg')" style="position: absolute;top: -46px;left: 0;width: 100%;height:80%;z-index:3;"/>-->
                                                    <v-img :src="getLogoPneuUrlImg(pneu.marque)" style="position: absolute;left: 0;width: 100%;height:100px;z-index:3;background-color: rgba(255,255,255,0.7)"/>
                                                    <v-img :src="pneu.photo" @error="pneu.photo = pneuDefaut" style="position: absolute;bottom: 0;right: 0;min-width: 60%; z-index:2; width:70%" :class="[ pneu.cheat !== 'InstaTurbo' ? 'mt-9' : 'mt-4' ]"/>
                                                </v-col>

                                                <v-col cols="8" class=" body-2 pb-0 text__img noBorderLeft_black borderLeft_lightGrey"  :class="{'choosenTire' : selectedTireIndex === index, 'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}" >

                                                    <v-container v-if="montagePreco" class="pa-0">
                                                        <v-col class="pa-0 font-weight-bold"> {{pneu.marque}} {{pneu.modele}} </v-col>
                                                        <template v-if='typeMonte == "decale"'>
                                                            <v-col class="pa-0 caption"> Pneu Avant : {{ParamsFront}} {{pneu.indiceDeCharge}}  {{pneu.indiceDeVitesse}}</v-col>
                                                            <v-col class="pa-0 caption"> Pneu Arriere : {{ParamsRear}} {{pneu.indiceDeChargeRear}}  {{pneu.indiceDeVitesse}}</v-col>
                                                        </template>

                                                        <template v-if='typeMonte == "par4"'>
                                                            <v-col class="pa-0 caption"> Pneu: {{pneu.HBV}}/{{pneu.width}} R{{pneu.diametre}} {{pneu.indiceDeCharge}}  {{pneu.indiceDeVitesse}}</v-col>
                                                        </template>
                                                        <v-col class="pa-0 caption">

                                                        <span style="display: flex;align-items: center;">
                                                            Pneu {{pneu.articleDescription}}
                                                            <v-icon v-if="pneu.ete === 1" color="yellow">mdi mdi-brightness-7</v-icon>
                                                            <v-icon v-if="pneu.hiver === 1" color="light-blue">mdi mdi-snowflake</v-icon>
                                                            <v-img v-if="pneu.allsaison === 1" src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                        </span>

                                                            <br>
                                                            <span style='text-decoration: underline'>Spécificités :</span>
                                                            <div v-for="(OneSpecificite, index) in pneu.tableauSpecificite" :key="OneSpecificite" class='d-inline'>
                                                            <v-tooltip right>
                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <span v-bind="attrs" v-on="on" style="color: #1840DA !important; font-weight: bold;">  {{ OneSpecificite }}<span v-if="index +1 < pneu.tableauSpecificite.length">,</span> </span>
                                                                </template>
                                                                <span>{{gestionSpecifite(OneSpecificite)}}</span>
                                                            </v-tooltip>
                                                        </div>
                                                        </v-col>
                                                        <v-col  class="pa-0 d-flex caption" style="align-items: center">
                                                            <span>{{pneu.desc}}</span>
                                                            <v-icon v-if="pneu.season === 'été'" color="yellow">mdi mdi-brightness-7</v-icon>
                                                            <v-icon v-if="pneu.season === 'hiver'" color="light-blue">mdi mdi-snowflake</v-icon>
                                                            <v-img v-if="pneu.season === '4 saisons'" src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                        </v-col>

                                                        <v-col class="py-0">
                                                            <v-row>
                                                                <v-col cols="3" sm="1" md="1" class="py-0 pl-2">
                                                                    <v-icon class="" :class="{'ml-1' :$vuetify.breakpoint.xs}">mdi mdi-gas-station</v-icon>
                                                                    <v-img v-if="getUrlImgRate(pneu.rollingResistance)" style="height: 50%" :src="getUrlImgRate(pneu.rollingResistance)"/>
                                                                    <p v-else class="ml-2" >-</p>
                                                                </v-col>
                                                                <v-col cols="3" sm="1" md="1" class="py-0 pl-2">
                                                                    <v-icon :class="{'ml-1' :$vuetify.breakpoint.xs}">mdi mdi-weather-pouring</v-icon>
                                                                    <v-img v-if="getUrlImgRate(pneu.wetGrip)" style="height: 50%" :src="getUrlImgRate(pneu.wetGrip)"/>
                                                                    <p v-else class="ml-2" >-</p>
                                                                </v-col>
                                                                <v-col cols="3" sm="1" md="1" class="py-0 pl-2">
                                                                    <v-icon class="ml-2">mdi mdi-volume-high</v-icon>
                                                                    <p v-if="pneu.noiseInDb !== 0" class="ml-2 mt-2 mb-0 font-weight-bold"> {{pneu.noiseInDb}} </p>
                                                                    <p v-else class="ml-3" >-</p>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                        <v-row class="borderTop_lightGrey">
                                                            <v-col v-if='typeMonte == "par4"'  class=" text-center d-flex" style="padding-top: 4%">
                                                                <v-col  class="pa-0">
                                                                    <v-col class="font-weight-bold body-1 pa-0 tc_blue">{{getRoundPrice(null,pneu.prixNet, '4pneus')}} €</v-col>
                                                                    <v-col class="caption pa-0 font-weight-medium">les 4 pneus</v-col>
                                                                </v-col>
                                                                <v-col class="pa-0">
                                                                    <v-col  class="font-weight-bold body-1 pa-0 " :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">{{getRoundPrice(SelectedJanteAv.public_price, pneu.prixNet, 'SOUS TOTAL')}} € </v-col>
                                                                    <v-col class="pa-0 caption font-weight-medium">le pack complet</v-col>
                                                                </v-col>
                                                            </v-col>
                                                            <v-col v-if='typeMonte == "decale"' class=" text-center d-flex" style="padding-top: 4%">
                                                                <v-col class="pa-0">
                                                                    <v-col class="font-weight-bold body-1 pa-0 tc_blue">{{getRoundPriceMonteDecale(null, null, pneu.prixNet, pneu.prixNetRear, '4pneus decale')}} €</v-col>
                                                                    <v-col class="caption pa-0 font-weight-medium">les 4 pneus</v-col>
                                                                </v-col>
                                                                <v-col class="pa-0">
                                                                    <v-col  class="font-weight-bold body-1 pa-0 " :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">{{getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, pneu.prixNet, pneu.prixNetRear,  'SOUS TOTAL DECALE' )}} € </v-col>
                                                                    <v-col class="pa-0 caption font-weight-medium">le pack complet</v-col>
                                                                </v-col>
                                                            </v-col>
                                                        </v-row>
                                                        <v-row >
                                                            <v-col>
                                                                <v-btn  width="100%" @click="selectPneuDecale(index,pneu)" dense small v-if='typeMonte == "decale"'>Sélectionner</v-btn>

                                                                <v-btn  width="100%" @click="selectPneu(index, pneu)" dense small v-if='typeMonte == "par4"'>Sélectionner</v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>

                                                    <v-container v-if="montagePersoStep2" class="pa-0">
                                                        <v-col class="pa-0 font-weight-bold">{{pneu.modele}}</v-col>
                                                        <v-col class="pa-0 caption">Avant : {{pneu.caracAvant}} {{pneu.detailAvant}}</v-col>
                                                        <v-col class="pa-0 caption">Arrière : {{pneu.caracArriere}} {{pneu.detailArriere}}</v-col>
                                                        <v-col  class="pa-0 d-flex caption" style="align-items: center">
                                                            <span>{{pneu.desc}}</span>
                                                            <v-icon v-if="pneu.season === 'été'" color="yellow">mdi mdi-brightness-7</v-icon>
                                                            <v-icon v-if="pneu.season === 'hiver'" color="light-blue">mdi mdi-snowflake</v-icon>
                                                            <v-img v-if="pneu.season === '4 saisons'" src="@/assets/icons/mi_saison.svg" style="max-width: 8%"/>
                                                        </v-col>

                                                        <v-col class="py-0">
                                                            <v-row>
                                                                <v-col cols="4" class="py-0 pl-0">
                                                                    <v-col>
                                                                        <v-icon>mdi mdi-gas-station</v-icon>
                                                                    </v-col>
                                                                    <v-col class="pa-0">
                                                                        <v-img style="max-width: 70%" :src="pneu.consoRate" />
                                                                    </v-col>
                                                                </v-col>
                                                                <v-col cols="4" class="py-0 pl-0">
                                                                    <v-col>
                                                                        <v-icon>mdi mdi-weather-pouring</v-icon>
                                                                    </v-col>
                                                                    <v-col class="pa-0">
                                                                        <v-img style="max-width: 70%" :src="pneu.weatherRate" />
                                                                    </v-col>
                                                                </v-col>
                                                                <v-col cols="4" class="py-0 pl-0">
                                                                    <v-col>
                                                                        <v-icon>mdi mdi-volume-high</v-icon>
                                                                    </v-col>
                                                                    <v-col class="pa-0">
                                                                        <v-img style="max-width: 70%" :src="pneu.soundRate" />
                                                                    </v-col>
                                                                </v-col>
                                                            </v-row>
                                                        </v-col>
                                                        <v-row class="borderTop_lightGrey">
                                                            <v-col class=" text-center d-flex" style="align-items: center">
                                                                <v-col class="pa-0">
                                                                    <v-col class="font-weight-bold body-1 pa-0 tc_blue">{{pneu.tire_price}} €</v-col>
                                                                    <v-col class="caption pa-0 font-weight-medium">les 4 pneus</v-col>
                                                                </v-col>
                                                                <v-col class="pa-0">
                                                                    <v-col  class="font-weight-bold body-1 pa-0 " :class="{'tc_turquoise': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">{{pneu.pack_price}} €</v-col>
                                                                    <v-col class="pa-0 caption font-weight-medium">le pack complet</v-col>
                                                                </v-col>
                                                            </v-col>
                                                            <v-col>
                                                                <v-btn  width="100%" @click="selectPneuDecale(index,pneu)" dense small v-if='typeMonte == "decale"'>Sélectionner</v-btn>

                                                                <v-btn  width="100%" @click="selectPneu(index, pneu)" dense small v-if='typeMonte == "par4"'>Sélectionner</v-btn>
                                                            </v-col>
                                                        </v-row>
                                                    </v-container>
                                                </v-col>

                                            </v-row>

                                            <!--                                            <v-row v-else>-->
                                            <!--                                                <span>Aucun resultat ne correspond a votre recherche</span>-->
                                            <!--                                            </v-row>-->
                                        </v-row>
                                        </v-content>
                                        <v-row v-if="(currentListePneu.length == 0)">
                                           <span>Aucun résultat ne correspond à votre recherche. <br> Pour plus d'information n'hesitez pas a nous contacter au : <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a> </span>
                                       </v-row>
                                    </v-col>


                                </v-container>
                            </v-row>
                        </v-container>
                                                    <ais-instant-search :search-client="searchClient" index-name="ej_pneu">
                            <ais-configure :hitsPerPage="20" :filters='filtreAlgolia' />
<v-row v-if="(showPagination)">
                                                <v-col cols="8" class="pt-0 align-self-center">
                            <ais-pagination  class="pt-3 MyCustomPaginationRoot" >
                        <!-- 'ais-Pagination-item': 'MyCustomPagination',
                        'ais-Pagination-link': 'MyCustomPagination',
                        'ais-Pagination-item--selected': 'MyCustomPaginationActive',
                        'ais-Pagination-item--firstPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--previousPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--nextPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--lastPage': 'MyCustomPaginationNavigate',
                        }"> -->
  <ul
    class="MyCustomPagination ais-Pagination-list"
    slot-scope="{
      currentRefinement,
      nbPages,
      pages,
      isFirstPage,
      isLastPage,
      refine,
      createURL
    }"
  >
    <li class="MyCustomPagination MyCustomPaginationNavigate" style="float:left; margin-left:5px;">
      <a :href="createURL(0)" @click.prevent="refine(0)" @click="changeOffset(0)" class="ais-Pagination-link MyCustomPagination" >
        ‹‹
      </a>
    </li>
    <li style="float:left; margin-left:2px;" class="MyCustomPaginationNavigate">
      <a
      class="ais-Pagination-link MyCustomPagination"
        :href="createURL(currentRefinement - 1)"
        @click.prevent="refine(currentRefinement - 1)"
        @click="changeOffset(0)"
      >
        ‹
      </a>
    </li>
    <li v-for="page in pages" :key="page" style="float:left; margin-left:5px;" class="ais-Pagination-item MyCustomPagination">
      <a
        class="ais-Pagination-link MyCustomPagination"
        :href="createURL(page)"
        :class="{'bc_turquoise' : page === currentRefinement && VUE_APP_SITE_ID === '1', 'bc_orange' : page === currentRefinement && VUE_APP_SITE_ID === '2' , 'tc_white' : page === currentRefinement && VUE_APP_SITE_ID === '2' }"
        @click.prevent="refine(page)"
        @click="changeOffset(page)"
      >
        {{ page + 1 }}
      </a>
    </li>
    <li v-if="!isLastPage" class="MyCustomPaginationNavigate">
      <a
        :href="createURL(currentRefinement + 1)"
        @click.prevent="refine(currentRefinement + 1)"
        @click="changeOffset(currentRefinement+1)"
        class="ais-Pagination-link MyCustomPagination"
      >
        ›
      </a>
    </li>
    <li v-if="!isLastPage" class="MyCustomPaginationNavigate">
      <a :href="createURL(nbPages)" @click.prevent="refine(nbPages)" @click="changeOffset(nbPages-1)" class="ais-Pagination-link MyCustomPagination" >
        ››
      </a>
    </li>
  </ul>
</ais-pagination>
        </v-col>
</v-row>
                          </ais-instant-search>



                        <v-container  v-if="montagePersoStep1" class="pa-0">
                            <v-col class=" pb-0" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_black': VUE_APP_SITE_ID === '2'}">

                                <v-btn width="100%" @click="switchMontage('montagePreco')" class="body-2 font-weight-medium " >
                                    Je souhaite le montage préconisé
                                </v-btn>

                            </v-col>
                            <v-col offset-lg="2" lg="9" class="mt-12">
                                <v-img src="@/assets/Voiture.svg"/>
                            </v-col>
                            <v-row class="bc_white" >
                                <v-col cols="6">
                                    <v-content class="font-weight-bold pb-3 caption text-center">
                                        Choisissez votre dimension de pneus avant
                                    </v-content>
                                    <v-select v-model="SelectedTireAvant" :items="CaracteristiquePneuPossible"  label="Taille de pneus compatibles" solo class="pa-0 font-weight-bold" :class="{'body-2':$vuetify.breakpoint.lg}"/>

                                </v-col>
                                <v-col cols="6">
                                    <v-content class="font-weight-bold pb-3 caption text-center">
                                        Choisissez votre dimension de pneus arrière
                                    </v-content>
                                    <v-select v-model="SelectedTireArriere" :items="CaracteristiquePneuPossible"  label="Taille de pneus compatibles" solo  class="pa-0 font-weight-bold" :class="{'body-2':$vuetify.breakpoint.lg}"/>

                                </v-col>
                                <v-col>
                                    <v-btn v-if="SelectedTireAvant && SelectedTireArriere" @click="switchMontage('montagePersoStep2')" width="100%" class="body-2 font-weight-bold" >
                                        Trouver des pneus compatibles
                                    </v-btn>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-col>
                </v-col>
            </v-col>

            <v-overlay
                    :absolute="overlayPos"
                    :value="askPneus"
                    :z-index="7"
                    :opacity="overlayOpacity"
            >
                <v-col >

                    <v-card v-if="$vuetify.breakpoint.lgAndUp" light style="min-width:500px;border-radius:50px;"  >
                        <v-col>
                            <RouterLink :to="{name: 'Etape4', params: {marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}" class="no_deco" >
                                <v-row >
                                    <v-col offset-lg="2" lg="2" class="pa-0">
                                        <v-img v-if="VUE_APP_SITE_ID === '1'" src="@/assets/jante.png" style="min-width: 140%" />
                                        <v-img v-if="VUE_APP_SITE_ID === '2'" src="@/assets/jante_home.png" style="min-width: 140%" />
                                    </v-col>

                                    <v-row>
                                        <v-col offset-lg="1" class="px-0">
                                            <v-col  lg="8" class=" text-center" :class="{'bc_blue': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2'}">
                                                <v-img v-if="VUE_APP_SITE_ID === '1'" src="@/assets/cart_turquoise.svg" style="max-width: 20%;" class="ma-auto"/>
                                                <v-img v-if="VUE_APP_SITE_ID === '2'" src="@/assets/cartDJ.svg" style="max-width: 20%;" class="ma-auto"/>
                                                <span class="font-weight-bold tc_white">4 jantes seules</span>
                                            </v-col>
                                        </v-col>
                                    </v-row>
                                </v-row>
                            </RouterLink>

                            <v-row @click="askPneus = false" class="pointer">
                                <v-col offset-lg="2" lg="2" class="pa-0 mt-5">
                                    <v-img src="@/assets/pneus-monte-rayon-jantes.png" style="min-width: 140%"/>
                                </v-col>

                                <v-row>
                                    <v-col offset-lg="1" class="px-0">

                                        <v-col lg="8"  class="mt-5 text-center" :class="{'bc_blue': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2'}">
                                            <v-img v-if="VUE_APP_SITE_ID === '1'" src="@/assets/cart_turquoise.svg" style="max-width: 20%;" class="ma-auto"/>
                                            <v-img v-if="VUE_APP_SITE_ID === '2'" src="@/assets/cartDJ.svg" style="max-width: 20%;" class="ma-auto"/>
                                            <span class="font-weight-bold tc_white">4 jantes + 4 pneus</span>
                                        </v-col>
                                    </v-col>
                                </v-row>
                            </v-row>
                        </v-col>

                    </v-card>


                    <v-card v-if="$vuetify.breakpoint.mdAndDown" light style="border-radius:20px;">
                        <v-col cols="12">
                            <RouterLink :to="{name: 'Etape4', params: {marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}" class="no_deco" >
                                <v-col>
                                    <v-row>
                                        <v-col cols="12" class="px-0">
                                            <v-col  lg="8" class="text-center" style="border-radius:20px;" :class="{'bc_blue': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2'}">
                                                <v-img v-if="VUE_APP_SITE_ID === '1'" src="@/assets/cart_turquoise.svg" style="max-width: 20%;" class="ma-auto"/>
                                                <v-img v-if="VUE_APP_SITE_ID === '2'" src="@/assets/cartDJ.svg" style="max-width: 20%;" class="ma-auto"/>
                                                <span class="font-weight-bold tc_white">4 jantes seules</span>
                                            </v-col>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </RouterLink>
                            <v-content class="text-center font-weight-bold">
                                <span>OU</span>
                            </v-content>
                            <v-row @click="askPneus = false" class="pointer">
                                 <v-col >
                                    <v-col offset-lg="1" class="px-0">
                                        <v-col lg="8"  class=" text-center" style="border-radius:20px;" :class="{'bc_blue': VUE_APP_SITE_ID === '1', 'bc_orange': VUE_APP_SITE_ID === '2'}">
                                            <v-img v-if="VUE_APP_SITE_ID === '1'" src="@/assets/cart_turquoise.svg" style="max-width: 20%;" class="ma-auto"/>
                                            <v-img v-if="VUE_APP_SITE_ID === '2'" src="@/assets/cartDJ.svg" style="max-width: 20%;" class="ma-auto"/>
                                            <span class="font-weight-bold tc_white">4 jantes + 4 pneus</span>
                                        </v-col>
                                    </v-col>
                                </v-col>
                            </v-row>
                        </v-col>

                    </v-card>


                </v-col>
            </v-overlay>

            <v-col lg="3" class="pa-0">
                <Menu :update="triggerUpdate"/>
            </v-col>
        </v-col>

        <ConfigurateurFooter :update="triggerUpdate"/>
    </v-app>
</template>

<script>
    import ConfigurateurHeader from "../headers/ConfigurateurHeader";
    import ConfigurateurFooter from "../footer/ConfigurateurFooter";
    import Menu from "./Menu";
    import SecureLS from "secure-ls";
    import pneuDefaut from '@/assets/pneuDefaut.jpg'
    var ls = new SecureLS({ isCompression: false });

    import algoliasearch from 'algoliasearch';
    import 'instantsearch.css/themes/algolia-min.css';

    import {mapState} from 'vuex'

    import Vue from 'vue'
    import axios from 'axios'
    import VueAxios from 'vue-axios'
    // import $ from 'jquery'


    Vue.use(VueAxios, axios)

    export default {
        name: "Etape3",
        components:{
            ConfigurateurHeader,
            ConfigurateurFooter,
            Menu
        },
        data:() =>({
          searchClient: algoliasearch(
            'IXJF8AJTH7',
            'c4c7c9439e003674579cb68a3352acc5'
          ),
          filtreAlgolia: "",
            typeMonte:"",
            currenthbvFront:"",
            currentwidthFront:"",
            currentDiametreFront:"",
            MarquePneuValue:"",
            headerTitle:4,
            askPneus:true,
            overlayPos:null,
            overlayOpacity:null,
            CaracteristiquePneuPossible:[],
            CaracteristiquePneuPossibleFront:[],
            CaracteristiquePneuPossibleRear:[],
            allPneuPossible:[],
            currentListePneu:[],
            conseilId: 0,
            conseilValue: '215/40 R18',
            conseils:[
                {value:'215/40 R18'},
                {value:'225/40 R18'},
                {value:'205/40 R18'},
            ],
            pneus:[],

            optionsRadios: '0',
            saisonsRadios: '3',
            selectedTireIndex:'',
            selectedTire:{},
            // montage:'préconisé',
            // montage:'personnalisé',
            montagePreco:true,
            montagePersoStep1:false,
            montagePersoStep2:false,
            SelectedTireAvant:null,
            SelectedTireArriere:null,
            MonteType: false,
            tiresAvantPerso :[
                {text:'205/40 R18'},
                {text:'215/40 R18'},
                {text:'225/40 R18'},

            ],
            tiresArrierePerso :[
                {text:'205/40 R18'},
                {text:'215/40 R18'},
                {text:'225/40 R18'},

            ],
            tableauCorrespondanceSpe:[
                {specificite:'Xl',description:'XL : Extra Load : pneu renforcé ayant un indice de charge supérieur'},
                {specificite:'8PR',description:'8PR : Plis : mesure de la résistance du flanc du pneu en fonction de la dureté et épaisseur'},
                {specificite:'3PMSF',description:'3PMSF : Pneu hiver avec la garantie de haute performance ( 3 Pics Mountain Snow Flake)'},
                {specificite:'FSL',description:'FSL : Pneu avec rebord de protection pour les jantes'},
                {specificite:'Run Ft',description:'Run Ft : Pneu capable de rouler à plat'},
                {specificite:'Run Flat',description:'Run Flat : Pneu capable de rouler à plat'},
                {specificite:'M+S',description:'M+S : Marquage Mud and Snow (Boue et neige)'},
                {specificite:'10PR',description:'10PR : Plis : mesure de la résistance du flanc du pneu en fonction de la dureté et épaisseur'},
                {specificite:'HO',description:"HO : Pneu développé pour HONDA, il peut être monté sur d'autres voitures"},
                {specificite:'6PR',description:'6PR : Plis : mesure de la résistance du flanc du pneu en fonction de la dureté et épaisseur'},
                {specificite:'Mazda',description:"Mazda : Pneu développé pour MAZDA, il peut être monté sur d'autres voitures"},
                {specificite:'WSW',description:'WSW : White Side Wall : pneu à flanc blanc'},
                {specificite:'MFS',description:'MFS : Pneu avec rebord de protection pour les jantes'},
                {specificite:'Oldtimer',description:'Oldtimer : Pneu pour voiture de collection '},
                {specificite:'DOT 2013',description:'DOT 2013 : Année de fabrication du pneu'},
                {specificite:'Semi-Slick',description:'Semi-Slick'},
                {specificite:'180 STREET',description:'180 STREET : Hard rubber (180)'},
                {specificite:'DOT 2016',description:'DOT 2016 : Année de fabrication du pneu'},
                {specificite:'DOT 2012',description:'DOT 2012 : Année de fabrication du pneu'},
                {specificite:'Lexus',description:"Lexus : Pneu développé pour LEXUS, il peut être monté sur d'autres voitures"},
                {specificite:'FR',description:'FR : Pneu avec rebord de protection pour les jantes'},
                {specificite:'Alfa Romeo',description:"Alfa Romeo : Pneu développé pour ALFA ROMEO, il peut être monté sur d'autres voitures"},
                {specificite:'N0',description:'N0 : Pneu homologué Porsche'},
                {specificite:'Medium',description:'Medium : Gomme intermédiaire'},
                {specificite:'Nissan',description:"Nissan : Pneu développé pour NISSAN, il peut être monté sur d'autres voitures"},
                {specificite:'Toyota',description:"Toyota : Pneu développé pour TOYOTA, il peut être monté sur d'autres voitures"},
                {specificite:'VW',description:"VW : Pneu développé pour VW, il peut être monté sur d'autres voitures"},
                {specificite:'MO1',description:"MO1 : Pneu développé pour Mercedes SL65 AMG, il peut être monté sur d'autres voitures"},
                {specificite:'PE',description:"PE : Pneu développé pour PEUGEOT, il peut être monté sur d'autres voitures"},
                {specificite:'TT',description:'TT : Tubetype : pneu nécessitant une chambre à air (non fournie)'},
                {specificite:'K1',description:'K1 : Pneu homologué FERRARI'},
                {specificite:'MO',description:"MO : Pneu développé pour MERCEDES, il peut être monté sur d'autres voitures"},
                {specificite:'Fiat',description:"Fiat : Pneu développé pour FIAT, il peut être monté sur d'autres voitures"},
                {specificite:'(*)',description:"(*) : Pneu développé pour BMW, il peut être monté sur d'autres voitures"},
                {specificite:'OWL',description:'OWL : Outline White Letters : contour de lettres blanc'},
                {specificite:'RPB',description:'RPB : Pneu avec rebord de protection pour les jantes'},
                {specificite:'AO',description:"AO : Pneu développé pour AUDI, il peut être monté sur d'autres voitures"},
                {specificite:'Mitsubishi',description:"Mitsubishi : Pneu développé pour Mitsubishi, il peut être monté sur d'autres voitures"},
                {specificite:'N1',description:"N1 : Pneu homologué Porsche"},
                {specificite:'N2',description:'N2 : Pneu homologué Porsche'},
                {specificite:'DOT 2014',description:'DOT 2014 : Année de fabrication du pneu'},
                {specificite:"Subaru",description:"Subaru : Pneu développé pour SUBARU, il peut être monté sur d'autres voitures"},
                {specificite:"DOT 2015",description:"DOT 2015 : Année de fabrication du pneu"},
                {specificite:"FP",description:"FP : Pneu avec rebord de protection pour les jantes"},
                {specificite:"DEMO",description:"DEMO : Pneu ayant été monté puis démonté. Il a parcouru au plus quelques kilomètres. Il est conforme à un produit neuf"},
                {specificite:"Pneus nordiques",description:"Pneus nordiques"},
                {specificite:"RO1",description:"RO1 : Pneu développé pour AUDI RS, il peut être monté sur d'autres voitures"},
                {specificite:"N4",description:"N4 : Pneu homologué Porsche"},
                {specificite:"N3",description:"N3 : Pneu homologué Porsche"},
                {specificite:"Seat",description:"Seat : Pneu développé pour SEAT, il peut être monté sur d'autres voitures "},
                {specificite:"RBL",description:"RBL : Raised Black Letter : pneu dont le marquage est de couleur noire"},
                {specificite:"MOE",description:"MOE : Pneu run flat uniquement pour Mercedes"},
                {specificite:"Hyuni",description:"Hyuni : Pneu développé pour Hyundai, il peut être monté sur d'autres voitures "},
                {specificite:"KIA",description:"KIA : Pneu développé pour KIA, il peut être monté sur d'autres voitures"},
                {specificite:"POR",description:"POR : Professional Off-Road"},
                {specificite:"F",description:"F"},
                {specificite:"AMR",description:"AMR : Pneu homologué ASTON MARTIN"},
                {specificite:"AMS",description:"AMS : Pneu homologué ASTON MARTIN"},
                {specificite:"AOE",description:"AOE : Pneu d'origine Audi avec propriétés run flat étendues. Il peut être monté sur d'autres voitures"},
                {specificite:"G1",description:"G1 : Différence de taille de la bande de roulement entre un pneu avec marquage G1 et un sans marquage G1. Si votre voiture possède 4 roues motrices, il faut mettre 4 pneus G1"},
                {specificite:"V1",description:"V1 : Nouveau mélange de gommes offrant de meilleurs performances et économie"},
                {specificite:"CP",description:"CP"},
                {specificite:"FO",description:"FO : Pneu développé pour FORD, il peut être monté sur d'autres voitures "},
                {specificite:"ZP",description:"ZP : Technologie run flat de chez Michelin"},
                {specificite:"Mini",description:"Mini : Pneu développé pour MINI, il peut être monté sur d'autres voitures "},
                {specificite:"HRS",description:"HRS : Technologie run flat de chez HANKOOK"},
                {specificite:"land Rover",description:"land Rover : Pneu développé pour land Rover, il peut être monté sur d'autres voitures "},
                {specificite:"NCS",description:"NCS : Noise Cancelling System : réduction du bruit"},
                {specificite:"lamborghini",description:"lamborghini : Pneu homologué lamborghini"},
                {specificite:"J",description:"J : Pneu homologué JAGUAR"},
                {specificite:"JRS",description:"JRS : Pneu homologué JAGUAR"},
                {specificite:"AM4",description:"AM4 : Pneu homologué ASTON MARTIN"},
                {specificite:"B",description:"B : Pneu homologué BENTLEY"},
                {specificite:"NST",description:"NST : Noise Shield Technology : technologie anti bruit"},
                {specificite:"K2AO1",description:"K2AO1"},
                {specificite:"AM9",description:"AM9 : Pneu homologué ASTON MARTIN"},
                {specificite:"Suzuki",description:"Suzuki : Pneu développé pour Suzuki, il peut être monté sur d'autres voitures"},
                {specificite:"RSC",description:"RSC : run flat"},
                {specificite:"B1",description:"B1 : Pneu homologué BENTLEY"},
                {specificite:"ROWL",description:"ROWL : Lettres blanches contourées et légèrement en relief"},
                {specificite:"RO2",description:"RO2 : Pneu homologué par Audi pour les voitures 4 roues motrices"},
                {specificite:"MGT",description:"MGT : Pneu homologué MASERATI"},
                {specificite:"DT1",description:"DT1 : amélioration de la bande de roulement, nouveau profil Michelin"},
                {specificite:"Seal Inside",description:"Seal Inside : Auto colmatage en cas de crevaison"},
                {specificite:"12PR",description:"12PR : Plis : mesure de la résistance du flanc du pneu en fonction de la dureté et épaisseur"},
                {specificite:"LRR",description:"LRR : Low Rolling Resistance : Mélange de gomme donnant de meilleure performance globale"},
                {specificite:"Connect",description:"Connect"},
                {specificite:"ZPS",description:"ZPS : abbréviation Yokohama pour désigné un pneu run flat"},
                {specificite:"SSR",description:"SSR : Self Supporting run flat chez Continental"},
                {specificite:"4PR",description:"4PR : Plis : mesure de la résistance du flanc du pneu en fonction de la dureté et épaisseur"},
                {specificite:"14PR",description:"14PR : Plis : mesure de la résistance du flanc du pneu en fonction de la dureté et épaisseur"},
                {specificite:"Sko",description:"Sko : Pneu développé pour SKODA, il peut être monté sur d'autres voitures"},
                {specificite:"Dacia",description:"Dacia : Pneu développé pour DACIA, il peut être monté sur d'autres voitures"},
                {specificite:"VOLVO",description:"VOLVO : Pneu développé pour VOLVO, il peut être monté sur d'autres voitures"},
                {specificite:"HZ",description:"HZ : Monte d'origine Bridgestone : pneu sélectionné par les constructeurs automobiles"},
                {specificite:"Roue de secours",description:"Roue de secours"},
                {specificite:"Acoustic",description:"Acoustic"},
                {specificite:"RWL",description:"RWL : Raised White Letter : pneu dont le marquage est de couleur blanche"},
                {specificite:"Renault",description:"Renault : Pneu développé pour RENAULT, il peut être monté sur d'autres voitures"},
                {specificite:"Sound Absorber",description:"Sound Absorber : réduction du bruit "},
                {specificite:"TESLA",description:"TESLA : Pneu développé pour TESLA, il peut être monté sur d'autres voitures"},
                {specificite:"XRP",description:"XRP : runflat"},
                {specificite:"SCT",description:"SCT : réduction du bruit "},
                {specificite:"DT",description:"DT : Different Tread Design : 2 profils de melangent sur la bande de roulement"},
                {specificite:"Jeep",description:"Jeep : Pneu développé pour JEEP, il peut être monté sur d'autres voitures"},
                {specificite:"S1",description:"S1 : Pneus répondant aux nouvelles normes anti-bruit"},
                {specificite:"DOT 2010",description:"DOT 2010 : Année de fabrication du pneu"},
                {specificite:"DSST",description:"DSST : Dunlop Self-Supporting Technology : pneu run flat par Dunlop"},
                {specificite:"WW",description:"WW : White Wall : pneu à flanc blanc"},
                {specificite:"Opel",description:"Opel : Pneu développé pour OPEL, il peut être monté sur d'autres voitures"},
                {specificite:"RB",description:"RB : Lettrage des pneus noir et entouré de blanc"},
                {specificite:"Lotus",description:"Lotus : Pneu développé pour LOTUS, il peut être monté sur d'autres voitures"},
                {specificite:"EMT",description:"EMT : Pneu run flat par Goodyear"},
                {specificite:"GG",description:"GG : Gomme dure"},
                {specificite:"R1",description:"R1"},
                {specificite:"SealGuard",description:"SealGuard : Run flat"},
                {specificite:"TPC",description:"TPC : Pneu développé pour Cadillac/chevrolet, il peut être monté sur d'autres voitures"},
                {specificite:"Ssangyong",description:"Ssangyong : Pneu développé pour Ssangyong, il peut être monté sur d'autres voitures"},
                {specificite:"-Hard",description:"Hard : Gomme dure"},
                {specificite:"MC1",description:"MC1 : Pneu développé pour McLaren, il peut être monté sur d'autres voitures"},
                {specificite:"BSS",description:"BSS : Flanc noir"},
                {specificite:"Cloutable",description:"Cloutable : Pneu pouvant être clouté"},
                {specificite:"Iveco",description:"Iveco : Pneu développé pour IVECO, il peut être monté sur d'autres voitures"},
                {specificite:"Dodge",description:"Dodge : Pneu développé pour DODGE, il peut être monté sur d'autres voitures"},
                {specificite:"Sealtech",description:"Sealtech : Run flat"},
                {specificite:"Chrysler",description:"Chrysler : Pneu développé pour Chrysler, il peut être monté sur d'autres voitures"},
                {specificite:"ML",description:"ML : Pneu avec cordon protecteur"},
                {specificite:"NO E-mark ",description:"NO E-mark : Non homologué sur route"},
                {specificite:"K3",description:"K3 : Pneu homologué FERRARI"},
                {specificite:"Trailer ONLY",description:"Trailer ONLY : Pneu remorque"},
                {specificite:"AM6",description:"AM6 : Pneu homologué ASTON MARTIN"},
                {specificite:"80 COMP",description:"80 COMP"},
                {specificite:"VO",description:"VO : Pneu développé pour VW, il peut être monté sur d'autres voitures"},
                {specificite:"DOT 2017",description:"DOT 2017 : Année de fabrication du pneu"},
                {specificite:"DOT 2007",description:"DOT 2007 : Année de fabrication du pneu"},
                {specificite:"70mm",description:"70mm"},
                {specificite:"ewoo",description:"ewoo"},
                {specificite:"60mm",description:"60mm"},
                {specificite:"RBT",description:"RBT : Le marquage sur le flanc du pneu est noir et avec du relief"},
                {specificite:"F01",description:"F01 : Pneu développé pour BMW, il peut être monté sur d'autres voitures"},
                {specificite:"Chevrolet",description:"Chevrolet : Pneu développé pour CHEVROLET, il peut être monté sur d'autres voitures"},
                {specificite:"AMV",description:"AMV : Pneu homologué ASTON MARTIN"},
                {specificite:"DOT 2011",description:"DOT 2011 : Année de fabrication du pneu"},
                {specificite:"AO2",description:"AO2 : Pneu développé pour AUDI, il peut être monté sur d'autres voitures"},
                {specificite:"ContiSilentContiSeal",description:"Pneu limitant le risque de crevaison grâce à un traitement spécial"},
                {specificite:"ZR",description:"ZR"},
                {specificite:"Droite",description:"Droite"},
                {specificite:"10mm",description:"10mm"},
                {specificite:"65mm",description:"65mm"},
                {specificite:"AM8",description:"AM8 : Pneu homologué ASTON MARTIN"},
                {specificite:"Porsche",description:"Pneu homologué PORSCHE"},
                {specificite:"DA",description:"DA : pneu avec un léger défaut d'aspect mais 100% conforme selon le manufacturier "},
                {specificite:"N5",description:"N5 : Pneu homologué Porsche"},
                {specificite:"ALP",description:"ALP"},
                {specificite:"F03",description:"F03 : Pneu homologué FERRARI"},
                {specificite:"F02",description:"F02 : Pneu homologué FERRARI"},
                {specificite:"Soft",description:"Soft : Gomme tendre"},
                {specificite:"ME2",description:"ME2"},
                {specificite:"WLT",description:"WLT : Lettres blanches"},
                {specificite:"NO SPIKE",description:"NO SPIKE"},
                {specificite:"65mm",description:"65mm"},
                {specificite:"Pagani",description:"Pneu homologué Pagani"},
                {specificite:"La",description:"La"},
                {specificite:"Gauche",description:"Gauche"},
                {specificite:"ARR",description:"ARR : Pneu développé pour Alfa romeo, il peut être monté sur d'autres voitures"},
                {specificite:"WL",description:"WL : Lettres blanches "},
                {specificite:"SBL",description:"SBL : Lettres noires "},
                {specificite:"ContiSilent",description:"ContiSilent : Technologie pour réduire les nuisances sonores "},
                {specificite:"ContiSeal",description:"ContiSeal : Pneu limitant le risque de crevaison grâce à un traitement spécial"},
                {specificite:"NR1",description:"NR1 : Pneu développé pour NISSAN, il peut être monté sur d'autres voitures"},
                {specificite:"2PR",description:"2PR : Plis : mesure de la résistance du flanc du pneu en fonction de la dureté et épaisseur"},
                {specificite:"C1 Viper",description:"C1 Viper : Pneu développé pour Dodge Viper, il peut être monté sur d'autres voitures"},
                {specificite:"0PR",description:"0PR : Plis : mesure de la résistance du flanc du pneu en fonction de la dureté et épaisseur"},
                {specificite:"RWLS",description:"RWLS : lettrage blanc"},
                {specificite:"DOT 2008",description:"DOT 2008 : Année de fabrication du pneu"},
                {specificite:"DOT 2009",description:"DOT 2009 : Année de fabrication du pneu"},
                {specificite:"3 RIB",description:"3 RIB"},
                {specificite:"VSB",description:"VSB"},
                {specificite:"Infiniti",description:"Infiniti : Pneu développé pour Infiniti, il peut être monté sur d'autres voitures"},
                {specificite:"Cadilc",description:"Cadilc : Pneu développé pour Cadillac, il peut être monté sur d'autres voitures"},
                {specificite:"TWI100",description:"TWI100"}
                ],
            MarquePneu: [],
            listePneuAvant: [],
            listePneuArriere: [],
            conseilIdFront:0,
            ParamsFront:"",
            conseilIdRear:0,
            ParamsRear:"",
            loadSwitching:false,
            paginationOffset:0,
            paginationHitPerPage:20,
            indexConseilMontage: 0,
            indexConseilMontageFront:0,
            indexConseilMontageRear:0,
            showPagination:true,
            saveHitFrontRear:[],
            SelectedJanteAv:{},
            SelectedJanteArr:{},
            triggerUpdate: 1,
            VUE_APP_SITE_ID:null,
            firstRadio:0,
            firstRadioAv:0,
            firstRadioArr:0,
            pneuDefaut:pneuDefaut,
        }),
        methods:{

            loadAndSwitch:function(){
                this.loadSwitching = true
                this.switchMontage('montagePreco')
            },
            ResetFiltre(){
                this.MarquePneuValue = ''
                this.optionsRadios = "0"
                this.saisonsRadios = "3"

                this.getPneuByFilter()

            },
            gestionSpecifite(SpecificitePneu) {
                var elementToReturn
                this.tableauCorrespondanceSpe.forEach(element => {
                    if( element.specificite.toLowerCase() == SpecificitePneu.toLowerCase()) {
                        elementToReturn = element.description
                    }
                })
                return elementToReturn
            },
            changeMarquePneu(element){
                    this.$store.commit('loader/setLoader', true)
                    this.MarquePneuValue = element
                    // this.showPagination = false
                    this.paginationOffset = 0
                    this.getPneuFromDataBase()
                    this.$store.commit('loader/setLoader', true)
                    var _this = this
                    setTimeout(function() {
                          _this.$store.commit('loader/setLoader', false)
                    }, 2200);
            },
            getListePneu(pneu) {
                var runflat = this.optionsRadios
                var ete = 1
                if(this.typeMonte == "decale") {
                    // console.log('this.ParamsFront', this.ParamsFront)
                    var arrayPneuPossibleSplitFront = this.ParamsFront.split('/');
                    var widthFront = arrayPneuPossibleSplitFront[0]

                    var arrayHBDDiametreFront =  arrayPneuPossibleSplitFront[1].split('R');
                    var hbvFront =  arrayHBDDiametreFront[0]
                    var diametreFront = arrayHBDDiametreFront[1]

                    this.currenthbvFront = hbvFront
                    this.currentwidthFront = widthFront
                    this.currentDiametreFront = diametreFront


                 runflat = this.optionsRadios
                // var saison = this.saisonsRadios

                 ete = 1
                this.currentListePneu = pneu.filter(function(result) {
                    return result.width == hbvFront && result.HBV == widthFront && result.diametre == diametreFront &&  (result.stock >= 4 || result.stock == "> 20")  && result.ete == ete && result.runFlat == runflat
                });
                this.getMarquePneu()
                this.currentListePneu.forEach(element => {
                    var tableauSpecificite = []
                    var elementsplit  = element.articleDescription.split('/');
                    element.indiceDeVitesse = elementsplit[1]

                    element.articleDescription = elementsplit[0]


                    var speficiteSplit = element.specificite.split(',')
                    speficiteSplit.forEach(element => {
                        tableauSpecificite.push(element.trim())
                    })
                    element.tableauSpecificite = tableauSpecificite
                })

                }
                if(this.typeMonte == "par4"){


                var arrayPneuPossibleSplit = this.CaracteristiquePneuPossible[0].split('/');
                var width = arrayPneuPossibleSplit[0]

                var arrayHBDDiametre =  arrayPneuPossibleSplit[1].split('R');
                var hbv =  arrayHBDDiametre[0]
                var diametre = arrayHBDDiametre[1]

                this.currenthbvFront = hbv
                this.currentwidthFront = width
                this.currentDiametreFront = diametre

                 runflat = this.optionsRadios
                 ete = 1

                this.currentListePneu = pneu.filter(function(result) {
                    return result.width == hbv && result.HBV == width && result.diametre == diametre  &&  (result.stock >= 4 || result.stock == "> 20")  && result.ete == ete && result.runFlat == runflat
                });

                // if(this.currentListePneu.length == 0 ){
                //     this.saisonsRadios = '3'

                //     this.currentListePneu = pneu.filter(function(result) {
                //     return result.width == hbv && result.HBV == width && result.diametre == diametre  &&  (result.stock >= 4 || result.stock == "> 20")  && result.ete == ete && result.runFlat == runflat  // A RETIRER
                // });
                // }



                this.getMarquePneu()

                this.currentListePneu.forEach(element => {
                    var tableauSpecificite = []
                    var elementsplit  = element.articleDescription.split('/');
                    element.indiceDeVitesse = elementsplit[1]

                    element.articleDescription = elementsplit[0]


                    var speficiteSplit = element.specificite.split(',')
                    speficiteSplit.forEach(element => {
                        tableauSpecificite.push(element.trim())
                    })
                    element.tableauSpecificite = tableauSpecificite
                })
                }
                this.currentListePneu.sort((a, b) => parseFloat(a.prixNet) - parseFloat(b.prixNet));
                this.$store.commit('loader/setLoader', false)
            },
            getPneuMontePerso(){
              this.CaracteristiquePneuPossibleFront = this.CaracteristiquePneuPossible
              this.CaracteristiquePneuPossibleRear = this.CaracteristiquePneuPossible
                this.$store.commit('loader/setLoader', true)

                Vue.axios.get('https://api.espace-jantes.com/getPneuCompatibleMonteDecale', {params: { caracteristiquePneuPossible : [this.ParamsFront,this.ParamsRear]}})
                    .then((response) => {
                        response.data.forEach(element => {
                            // console.log(element)
                            this.allPneuPossible.push(element)
                        })
                        this.getListePneu(this.allPneuPossible)
                    })
            },
            getPneuFromDataBase() {
                //Faire tableau et envoyer le tableau au back office et faire gestion
                var caracteristiquePneuPossible  = this.CaracteristiquePneuPossible
                var stock = 'stock > 3'
                // var hits = []
                 var runflat = this.optionsRadios
                    var allsaison = (this.saisonsRadios == 1)? 1:0
                    var hiver = (this.saisonsRadios == 2)? 1:0
                    var ete = (this.saisonsRadios == 3)? 1:0

                if(this.typeMonte == "decale"){
                  this.$store.commit('loader/setLoader', true)

                    // console.log('this.CaracteristiquePneuPossibleFront', this.CaracteristiquePneuPossibleFront)
                    // console.log('this.CaracteristiquePneuPossibleRear', this.CaracteristiquePneuPossibleRear)

                    var frontSplitCarac = this.splitCaracteristique(this.CaracteristiquePneuPossibleFront[this.indexConseilMontageFront])
                    var rearSplitCarac = this.splitCaracteristique(this.CaracteristiquePneuPossibleRear[this.indexConseilMontageRear])

                    const client = algoliasearch('IXJF8AJTH7', 'c4c7c9439e003674579cb68a3352acc5');
                    const index = client.initIndex('ej_pneu');
                    // this.filtreAlgolia = '(width: '+ frontSplitCarac.width + ' OR width:' + rearSplitCarac.width +') AND diametre: '+ rearSplitCarac.diametre + ' AND (HBV: ' + rearSplitCarac.hbv + ' OR HBV: ' + frontSplitCarac.hbv + ') AND ete: '+ ete + ' AND runFlat: ' + runflat + ' AND allsaison: ' + allsaison+ ' AND hiver: ' + hiver
                    this.filtreAlgolia = 'width: '+ frontSplitCarac.width + ' OR width:' + rearSplitCarac.width +' AND diametre: '+ rearSplitCarac.diametre + ' AND HBV: ' + rearSplitCarac.hbv + ' OR HBV: ' + frontSplitCarac.hbv + ' AND ete: '+ ete + ' AND runFlat: ' + runflat + ' AND allsaison: ' + allsaison+ ' AND hiver: ' + hiver + ' AND ' +  stock
                        index.search('', {
                            filters:this.filtreAlgolia
                          })
                        .then(({hits}) => {
                          var hitTmp = []
                          var hitRear = []
                          var hitFront = []
                          var _self = this
                          this.MarquePneu = []
                          hits.forEach(element => {
                              if(element.width == frontSplitCarac.width && element.HBV == frontSplitCarac.hbv){
                                hitFront.push(element)

                              }
                              if(element.width == rearSplitCarac.width && element.HBV == rearSplitCarac.hbv){
                                hitRear.push(element)
                                // console.log(element)
                              }
                          })

                          hitFront.forEach(elementFront => {
                            hitRear.forEach(elementRear => {
                              if(elementFront.marque == elementRear.marque && elementFront.modele == elementRear.modele ){
                                if(this.MarquePneuValue != "" && elementFront.marque == this.MarquePneuValue){
                                  elementFront.prixNetRear = elementRear.prixNet
                                  elementFront.indiceDeChargeRear = elementRear.indiceDeCharge

                                    hitTmp.push(elementFront.objectID)
                                    this.saveHitFrontRear.push(elementFront)
                                }else if(this.MarquePneuValue == ""){

                                  elementFront.prixNetRear = elementRear.prixNet
                                  elementFront.indiceDeChargeRear = elementRear.indiceDeCharge

                                    hitTmp.push(elementFront.objectID)
                                    this.saveHitFrontRear.push(elementFront)
                                }
                                this.MarquePneu.push(elementFront.marque)
                              }
                            })
                          })
                          var modeleAlgolia = ""
                                hitTmp.forEach(element => {
                                     if(modeleAlgolia != ""){
                                        if(element.length > 0 && element !== " "){
                                            modeleAlgolia += " OR "
                                        }
                                    }
                                    if(element.length > 0 && element !== " "){
                                        modeleAlgolia += " objectID: '"+ element + "' "
                                    }
                                })
                                // console.log(modeleAlgolia)
                          this.filtreAlgolia = modeleAlgolia

                          setTimeout(function() {
                            _self.MarquePneu.sort()

                            index.search('', {
                            filters:_self.filtreAlgolia,
                            offset: _self.paginationOffset,
                            length: _self.paginationHitPerPage,
                          })
                        .then(({hits}) => {
                          hits.forEach(elementFront => {
                           var oldElement = _self.saveHitFrontRear.find(element => element.objectID == elementFront.objectID)
                           elementFront.indiceDeChargeRear = oldElement.indiceDeChargeRear
                           elementFront.prixNetRear = oldElement.prixNetRear

                            var tableauSpecificite = []
                              var elementsplit  = elementFront.articleDescription.split('/');
                              elementFront.indiceDeVitesse = elementsplit[1]

                              elementFront.articleDescription = elementsplit[0]

                              var speficiteSplit = elementFront.specificite.split(',')
                              speficiteSplit.forEach(element => {
                                  tableauSpecificite.push(element.trim())
                              })
                              elementFront.tableauSpecificite = tableauSpecificite
                          })
                          _self.currentListePneu = hits
                          setTimeout(function() {
                            _self.$store.commit('loader/setLoader', false)
                          },1800)
                        })
                    }, 2300);
                             })
                }

                if(this.typeMonte == "par4"){
                    // console.log('caracteristiquePneuPossible', caracteristiquePneuPossible)
                    // console.log('this.indexConseilMontage', this.indexConseilMontage)
                    var splitCarac = this.splitCaracteristique(caracteristiquePneuPossible[this.indexConseilMontage])
                    var marquePneu = ""
                    // console.log(splitCarac)
                    const client = algoliasearch('IXJF8AJTH7', 'c4c7c9439e003674579cb68a3352acc5');
                    const index = client.initIndex('ej_pneu');
                    if(this.MarquePneuValue != ""){
                      marquePneu = ' AND marque:'+this.MarquePneuValue
                    }

                     runflat = this.optionsRadios
                     allsaison = (this.saisonsRadios == 1)? 1:0
                     hiver = (this.saisonsRadios == 2)? 1:0
                     ete = (this.saisonsRadios == 3)? 1:0

                    this.filtreAlgolia ='width: '+ splitCarac.width + ' AND diametre: '+ splitCarac.diametre + ' AND HBV: ' + splitCarac.hbv+ ' AND ete: '+ ete + ' AND runFlat: ' + runflat + ' AND allsaison: ' + allsaison+ ' AND hiver: ' + hiver + ' AND ' +  stock
                    if(this.MarquePneuValue != ""){
                      this.filtreAlgolia += marquePneu
                    }
                    console.log('filtreAlgolia', this.filtreAlgolia + marquePneu)

                    index.search('', {
                        filters:this.filtreAlgolia + marquePneu,
                        offset: this.paginationOffset,
                        length: this.paginationHitPerPage,
                          })
                        .then(({hits}) => {
                            if(hits.length < 20){
                                this.showPagination = false
                            }else{
                                this.showPagination = true
                            }
                            console.log('hits', hits.length)

                            hits.forEach(element => {
                              var tableauSpecificite = []
                              var elementsplit  = element.articleDescription.split('/');
                              element.indiceDeVitesse = elementsplit[1]

                              element.articleDescription = elementsplit[0]

                              var speficiteSplit = element.specificite.split(',')
                              speficiteSplit.forEach(element => {
                                  tableauSpecificite.push(element.trim())
                              })
                              element.tableauSpecificite = tableauSpecificite

                          })
                          this.currentListePneu = hits
                          this.getMarquePneu()

                          this.$store.commit('loader/setLoader', false)
                             }).catch(error => console.log(error))
                }
            },
            splitCaracteristique(caracteristique){
              var arrayPneuPossibleSplit = caracteristique.split('/');
              var hbv = arrayPneuPossibleSplit[0]

              var arrayHBDDiametre =  arrayPneuPossibleSplit[1].split('R');
              var width =  arrayHBDDiametre[0]
              var diametre = arrayHBDDiametre[1]

              return { diametre:diametre,width:width,hbv:hbv}
            },
            getPneuPossible() {
                //Rajouter infoChassis
                // var chassisVehiculeUtilisateur = JSON.parse(this.$localStorage.get('chassisVehiculeUtilisateur'))
                var chassisVehiculeUtilisateur
                if( typeof ls.get('chassisVehiculeUtilisateur') == "string" ){
                    chassisVehiculeUtilisateur = JSON.parse(ls.get('chassisVehiculeUtilisateur'))
                }
                else if(typeof ls.get('chassisVehiculeUtilisateur') == "object"){
                    chassisVehiculeUtilisateur = ls.get('chassisVehiculeUtilisateur')
                }
                var id_chassis = chassisVehiculeUtilisateur.id_chassis
                Vue.axios.get('https://api.espace-jantes.com/getMonteSerieOptionsChassisVerificationDiametreBis', {params: { idChassisVehiculeUtilisateur : id_chassis, diametre:this.$route.params.diametre}})
                    .then((response) => {
                        // console.log('getPneuPossible resp', response)

                        response.data.forEach(element => {
                            // if( element.wheelSize.split('x')[1].trim() != this.$localStorage.get('diametre').trim()){
                            // if( element.wheelSize.split('x')[1].trim() != ls.get('diametre').trim()){
                            if(element.wheelSize != undefined && element.wheelSize.split('x')[1].trim() !== this.$route.params.diametre.toString()){
                                return
                            }

                            // if(this.typeMonte == "decale" && this.monteStateDeca === true){
                            if(this.typeMonte === "decale"){
                                if(element.tyre && element.tyre != ""){
                                    if(this.CaracteristiquePneuPossibleFront.indexOf(element.tyre) === -1){
                                        this.CaracteristiquePneuPossibleFront.push(element.tyre)
                                    }
                                    if(this.CaracteristiquePneuPossibleRear.indexOf(element.tyre) === -1) {
                                        this.CaracteristiquePneuPossibleRear.push(element.tyre)
                                    }
                                }
                                if(element.tyre1 && element.tyre1 != ""){
                                    // console.log('element.tyre1', element.upsetType)
                                    if((element.upsetType == 'Upstep' || element.upsetType == 'FrontUpstep') && this.CaracteristiquePneuPossibleFront.indexOf(element.tyre1) == -1  && parseFloat(element.wheelSize.split("x")[0].trim()).toFixed(1) == parseFloat(this.SelectedJanteAv.largeurMinAv).toFixed(1) ){ //Largeur est  Inverser
                                        this.CaracteristiquePneuPossibleFront.push(element.tyre1)
                                        // console.log('element.front')
                                    }
                                    if(element.upsetType == 'RearUpstep' && this.CaracteristiquePneuPossibleRear.indexOf(element.tyre1) == -1 && parseFloat(element.wheelSize.split("x")[0].trim()).toFixed(1) == parseFloat(this.SelectedJanteAv.largeurMaxArr).toFixed(1)){
                                        this.CaracteristiquePneuPossibleRear.push(element.tyre1)
                                        // console.log('element.reaer')
                                    }
                                }
                                if(element.tyre2 && element.tyre2 != ""){
                                    // console.log('element.tyre2')
                                    if((element.upsetType == 'Upstep' || element.upsetType == 'FrontUpstep') && this.CaracteristiquePneuPossibleFront.indexOf(element.tyre2) == -1 && parseFloat(element.wheelSize.split("x")[0].trim()).toFixed(1) == parseFloat(this.SelectedJanteAv.largeurMinAv).toFixed(1)){
                                        this.CaracteristiquePneuPossibleFront.push(element.tyre2)
                                    }
                                    if(element.upsetType == 'RearUpstep' && this.CaracteristiquePneuPossibleRear.indexOf(element.tyre2) == -1 && parseFloat(element.wheelSize.split("x")[0].trim()).toFixed(1) == parseFloat(this.SelectedJanteAv.largeurMaxArr).toFixed(1)){
                                        this.CaracteristiquePneuPossibleRear.push(element.tyre2)
                                    }
                                }
                                if(element.tyre3 && element.tyre3 != ""){
                                    // console.log('element.tyre3')
                                    if((element.upsetType == 'Upstep' || element.upsetType == 'FrontUpstep') && this.CaracteristiquePneuPossibleFront.indexOf(element.tyre3) == -1 && parseFloat(element.wheelSize.split("x")[0].trim()).toFixed(1) == parseFloat(this.SelectedJanteAv.largeurMinAv).toFixed(1) ){
                                        this.CaracteristiquePneuPossibleFront.push(element.tyre3)
                                    }
                                    if(element.upsetType == 'RearUpstep' && this.CaracteristiquePneuPossibleRear.indexOf(element.tyre3) == -1 && parseFloat(element.wheelSize.split("x")[0].trim()).toFixed(1) == parseFloat(this.SelectedJanteAv.largeurMaxArr).toFixed(1) ){
                                        this.CaracteristiquePneuPossibleRear.push(element.tyre3)
                                    }
                                }
                                if(element.tyre4 && element.tyre4 != ""){
                                    // console.log('element.tyre4')
                                    if((element.upsetType == 'Upstep' || element.upsetType == 'FrontUpstep' ) && this.CaracteristiquePneuPossibleFront.indexOf(element.tyre4) == -1 && parseFloat(element.wheelSize.split("x")[0].trim()).toFixed(1) == parseFloat(this.SelectedJanteAv.largeurMinAv).toFixed(1) ){
                                        this.CaracteristiquePneuPossibleFront.push(element.tyre4)
                                    }
                                    if(element.upsetType == 'RearUpstep' && this.CaracteristiquePneuPossibleRear.indexOf(element.tyre4) == -1 && parseFloat(element.wheelSize.split("x")[0].trim()).toFixed(1) == parseFloat(this.SelectedJanteAv.largeurMaxArr).toFixed(1)){
                                        this.CaracteristiquePneuPossibleRear.push(element.tyre4)
                                    }
                                }
                                this.ParamsFront = this.CaracteristiquePneuPossibleFront[0]
                                this.ParamsRear = this.CaracteristiquePneuPossibleRear[0]
                            }else{
                                // if(element.tyre != "" && this.CaracteristiquePneuPossible.indexOf(element.tyre) == -1 && parseFloat(this.SelectedJanteAv.largeur).toFixed(1) >= parseFloat(element.largeurMin).toFixed(1) && parseFloat(this.SelectedJanteAv.largeur).toFixed(1) <= parseFloat(element.largeurMax).toFixed(1)){
                                if(element.tyre && element.tyre != "" && this.CaracteristiquePneuPossible.indexOf(element.tyre) == -1){
                                    this.CaracteristiquePneuPossible.push(element.tyre)
                                }
                                if(element.tyre1 && element.tyre1 != "" && this.CaracteristiquePneuPossible.indexOf(element.tyre1) == -1 && (element.upsetType == 'Upstep' || element.upsetType == 'FrontUpstep') && parseFloat(this.SelectedJanteAv.largeur).toFixed(1) == parseFloat(element.wheelSize.split('x')[0].trim()).toFixed(1) ){
                                    this.CaracteristiquePneuPossible.push(element.tyre1)
                                }
                                if(element.tyre2 && element.tyre2 != "" && this.CaracteristiquePneuPossible.indexOf(element.tyre2) == -1 && (element.upsetType == 'Upstep' || element.upsetType == 'FrontUpstep')  && parseFloat(this.SelectedJanteAv.largeur).toFixed(1) == parseFloat(element.wheelSize.split('x')[0].trim()).toFixed(1) ){
                                    this.CaracteristiquePneuPossible.push(element.tyre2)
                                }
                                if(element.tyre3 && element.tyre3 != "" && this.CaracteristiquePneuPossible.indexOf(element.tyre3) == -1 && (element.upsetType == 'Upstep' || element.upsetType == 'FrontUpstep')  && parseFloat(this.SelectedJanteAv.largeur).toFixed(1) == parseFloat(element.wheelSize.split('x')[0].trim()).toFixed(1) ){
                                    this.CaracteristiquePneuPossible.push(element.tyre3)
                                }
                                if(element.tyre4 && element.tyre4 != "" && this.CaracteristiquePneuPossible.indexOf(element.tyre4) == -1 && (element.upsetType == 'Upstep' || element.upsetType == 'FrontUpstep')  && parseFloat(this.SelectedJanteAv.largeur).toFixed(1) == parseFloat(element.wheelSize.split('x')[0].trim()).toFixed(1) ){
                                    this.CaracteristiquePneuPossible.push(element.tyre4)
                                }
                            }
                        })
                        if(this.CaracteristiquePneuPossibleRear.length == 0){
                            response.data.forEach(element => {
                                if(element.wheelSize != undefined && element.wheelSize.split('x')[1].trim() !== this.$route.params.diametre.toString()){
                                    return
                                }
                                if(this.typeMonte === "decale"){
                                    if(element.tyre1 != ""){
                                        // console.log('element.tyre1', element.upsetType)
                                        if((element.upsetType == 'Upstep' || element.upsetType == 'FrontUpstep') && this.CaracteristiquePneuPossibleRear.indexOf(element.tyre1) == -1  && parseFloat(element.wheelSize.split("x")[0].trim()).toFixed(1) == parseFloat(this.SelectedJanteArr.largeurMaxArr).toFixed(1) ){
                                            this.CaracteristiquePneuPossibleRear.push(element.tyre1)
                                        }
                                    }
                                    if(element.tyre2 != ""){
                                        // console.log('element.tyre2')
                                        if((element.upsetType == 'Upstep' || element.upsetType == 'FrontUpstep') && this.CaracteristiquePneuPossibleRear.indexOf(element.tyre2) == -1 && parseFloat(element.wheelSize.split("x")[0].trim()).toFixed(1) == parseFloat(this.SelectedJanteArr.largeurMaxArr).toFixed(1)){
                                            this.CaracteristiquePneuPossibleRear.push(element.tyre2)
                                        }
                                    }
                                    if(element.tyre3 != ""){
                                        // console.log('element.tyre3')
                                        if((element.upsetType == 'Upstep' || element.upsetType == 'FrontUpstep') && this.CaracteristiquePneuPossibleRear.indexOf(element.tyre3) == -1 && parseFloat(element.wheelSize.split("x")[0].trim()).toFixed(1) == parseFloat(this.SelectedJanteArr.largeurMaxArr).toFixed(1) ){
                                            this.CaracteristiquePneuPossibleRear.push(element.tyre3)
                                        }
                                    }
                                    if(element.tyre4 != ""){
                                        // console.log('element.tyre4')
                                        if((element.upsetType == 'Upstep' || element.upsetType == 'FrontUpstep' ) && this.CaracteristiquePneuPossibleRear.indexOf(element.tyre4) == -1 && parseFloat(element.wheelSize.split("x")[0].trim()).toFixed(1) == parseFloat(this.SelectedJanteArr.largeurMaxArr).toFixed(1) ){
                                            this.CaracteristiquePneuPossibleRear.push(element.tyre4)
                                        }
                                    }
                                    this.ParamsRear = this.CaracteristiquePneuPossibleRear[0]
                                }
                            })
                        }

                        this.getPneuFromDataBase()
                    })
            },
            getMarquePneu() {
              const client = algoliasearch('IXJF8AJTH7', 'c4c7c9439e003674579cb68a3352acc5');
              const index = client.initIndex('ej_pneu');
              this.MarquePneu = []
              index.search('', {
                filters:this.filtreAlgolia,
              })
              .then(({hits}) => {
                hits.forEach(element => {
                  this.MarquePneu.push(element.marque)
                })
              })
              var _self = this
              setTimeout(function() {
                _self.MarquePneu.sort()
              }, 1000);

            },
            selectPneu:function(index, pneu){
                // console.log('pneu.carac', pneu)
                this.selectedTireIndex = index
                this.selectedTire.carac = pneu.carac
                this.selectedTire.desc = pneu.desc
                // this.$store.commit('addToConfigurateur', this.selectedTire)
                pneu.type = 2
                pneu.quantite = 2
                pneu.flux = 'LDPrestige'
                // this.$store.commit('infoPneus/setPneu', {Av: pneu, Arr: pneu})
                this.$store.commit('footerNext/setNext', 'true')


                this.setOnglet(pneu,'4pneus')
                // localStorage.setItem('cart', JSON.stringify({"pneus": this.SelectedJante}))

            },
            selectPneuDecale:function(index,pneu){
                this.selectedTireIndex = index

                this.$store.commit('infoPneus/setMonteDeca', true)

                pneu.type = 2
                pneu.quantite = 2
                pneu.flux = 'LDPrestige'

                var pneuFront = pneu
                var pneuRear = pneu

                var infoFront = this.ParamsFront.split('/')
                pneuFront.HBV = infoFront[0]
                var infoFrontBis = infoFront[1].split('R')
                pneuFront.width = infoFrontBis[0]


                var infoRear = this.ParamsRear.split('/')
                pneuRear.HBV = infoRear[0]
                var infoRearBis = infoRear[1].split('R')
                pneuRear.width = infoRearBis[0]

                pneu.HBV = infoFront[0]
                pneu.HBVREAR = infoRear[0]

                pneu.width = infoFrontBis[0]
                pneu.widthRear = infoRearBis[0]

                // console.log(pneu)

                //  var elementsplit  = element.articleDescription.split('/');
                //     element.indiceDeVitesse = elementsplit[1]

                //     element.articleDescription = elementsplit[0]
                // console.log(this.ParamsFront)
                // console.log(this.ParamsRear)
                //console.log(pneu)
                // this.$store.commit('addToConfigurateur', this.selectedTire)
                // this.$store.commit('infoPneus/setPneu', {Av: pneu, Arr: pneu})
                this.$store.commit('footerNext/setNext', 'true')

                this.setOnglet(pneu, '4pneus')
                // localStorage.setItem('cart', JSON.stringify({"pneus": this.SelectedJante}))

            },
            swap:function(){
              this.MarquePneuValue = ""
              this.paginationOffset = 0
              this.getPneuByFilter()
            },
            changeOffset: function(page){
              var arrayPageToOffset = [0, 20,40,60,80,100,120,140,160,180,200,220,240,260,280,300,320,340,360,380,400,420,440,460,480,500,520,540,560,580,600]
              this.paginationOffset = arrayPageToOffset[page]
              this.$refs.TopListPneu.scrollTo(0,0)
              this.getPneuByFilter()
            },
            getPneuByFilter : function() {
              this.showPagination = true

                this.getPneuFromDataBase()
                if(this.MarquePneuValue != ''){
                    //console.log(this.MarquePneuValue)
                    var MarquePneuValue = this.MarquePneuValue

                    this.currentListePneu = this.currentListePneu.filter(function(result) {
                        return result.marque == MarquePneuValue
                    })
                }
                this.getMarquePneu()
            },
            switchMontage : function (montageState) {
                if(montageState === 'montagePreco'){
                    this.montagePreco = true;
                    this.montagePersoStep1 = false;
                    this.montagePersoStep2 = false;
                    this.headerTitle = 4;
                    if(this.SelectedTireAvant === this.SelectedTireArriere){
                        this.$store.commit('infoPneus/setMonteDeca', false)
                        this.updateRoute(0, 'decale')
                        this.typeMonte = 'par4'
                    }else if(this.SelectedTireAvant !== this.SelectedTireArriere){
                        this.$store.commit('infoPneus/setMonteDeca', true)
                        this.updateRoute(1, 'decale')
                        this.typeMonte = 'decale'
                        this.getPneuMontePerso()
                    }

                }
                if(montageState === 'montagePersoStep1'){
                    this.montagePreco = false;
                    this.montagePersoStep1 = true;
                    this.montagePersoStep2 = false;
                    this.headerTitle = 5;
                }
                if(montageState === 'montagePersoStep2'){
                    this.montagePreco = false;
                    this.montagePersoStep1 = false;
                    this.montagePersoStep2 = true;
                    this.headerTitle = 6;
                }
                this.loadSwitching = false
            },
            getLogoPneuUrlImg(image){
                try{
                  var logoUrl = require('@/assets/Logo_Pneus/'+ image.toLowerCase() +'.svg')
                  // var logoUrl = require('@/assets/logosMarquesPneus/'+ image.toLowerCase() +'.png')
                  return logoUrl
                }
              catch{
                    if(this.VUE_APP_SITE_ID === '1'){
                        return require('@/assets/logo/ej-logo.svg')
                    }
                    else if(this.VUE_APP_SITE_ID === '2'){
                        return require('@/assets/distri.svg')
                    }
              }
            },
            getUrlImgRate(image){
                try{
                  var logoUrl = require('@/assets/rate/' + image.toLowerCase() +'.svg')
                  return logoUrl
                }
                catch{
                    return false
                }
            },
            switchConseil: function (index) {
                console.log('index', index)
              // console.log(conseilValue)
              this.MarquePneuValue = ""
                this.indexConseilMontage = index
                this.conseilId = index
                this.getPneuFromDataBase()
                this.$store.commit('loader/setLoader', true)
                this.showPagination = true

               let self=this
                setTimeout(function(){
                    self.$store.commit('loader/setLoader', false)
                }, 800);
            },
            switchConseilDecale: function (index, conseilValue, Zone) {
              this.MarquePneuValue = ""
                this.$store.commit('loader/setLoader', true)

                if(Zone == 'Front'){
                  this.indexConseilMontageFront = index
                    this.conseilIdFront = index
                    this.ParamsFront = conseilValue
                }

                 if(Zone == 'Rear'){
                   this.indexConseilMontageRear = index
                    this.conseilIdRear = index
                    this.ParamsRear = conseilValue
                }
                // console.log(this.ParamsFront)
                // console.log(this.ParamsRear)

                this.getPneuFromDataBase()
                this.showPagination = true

               let self=this
                setTimeout(function(){
                    self.$store.commit('loader/setLoader', false)

                }, 1000);
            },
            getRoundPrice: function (Prixjante, PrixPneu, type){
                let price
                var tmp = Math.pow(10, 2);

                if(this.isProfessional){
                    let taux = (1 - parseFloat('0.' + this.tauxRemise))
                    taux = (Math.round( taux * tmp )/tmp).toFixed(2)

                    Prixjante = Prixjante * taux
                }

                Prixjante = (Math.round( Prixjante * tmp )/tmp).toFixed(2)
                PrixPneu = Math.round(PrixPneu * tmp )/tmp.toFixed(2)

                switch(type){
                    case('4jantes'):
                        if(Prixjante){
                            price  = (Prixjante * 4)
                        }
                        break;
                    case('1jante'):
                        if(Prixjante){
                            price  = Prixjante
                        }
                        break;
                    case('2jantes'):
                        if(Prixjante){
                            price  = (Prixjante * 2)
                        }
                        break;
                    case('4pneus'):
                        if(PrixPneu){
                            price  = ((PrixPneu + 10) * 4)
                        }
                        break;
                    case('2pneus'):
                        if(PrixPneu){
                            price  = ((PrixPneu + 10) * 2)
                        }
                        break;
                    case('1pneu'):
                        if(PrixPneu){
                            price  = (PrixPneu + 10)
                        }
                        break;
                    case('SOUS TOTAL'):
                        if(Prixjante && PrixPneu){
                            price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires)
                        }else if(Prixjante){
                            price  = ((Prixjante * 4) + this.TotalPriceAccessoires)
                        }
                        break;
                    case('TOTAL'):
                        if(Prixjante && PrixPneu){
                            price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90)
                        }else if(Prixjante){
                            price  = ((Prixjante * 4) + this.TotalPriceAccessoires + 9.90)
                        }
                        break;
                    case('TOTAL COFIDIS'):
                        if(Prixjante && PrixPneu){
                            price  = (((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90) * 1.022)
                        }else if(Prixjante){
                            price  = (((Prixjante * 4) + this.TotalPriceAccessoires + 9.90) * 1.022)
                        }
                        break;
                    case('TVA'):
                        if(Prixjante && PrixPneu){
                            price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90 ) * 0.2
                        }else if(Prixjante){
                            price  = ((Prixjante * 4) + this.TotalPriceAccessoires + 9.90) * 0.2
                        }
                        break;
                }

                return (Math.round( price * tmp )/tmp).toFixed(2)
            },
            getRoundPriceMonteDecale: function (PrixjanteAv, PrixjanteArr, PrixPneuAv, PrixPneuArr, type){
                let price
                var tmp = Math.pow(10, 2);

                if(this.isProfessional){
                    let taux = (1 - parseFloat('0.' + this.tauxRemise))
                    taux = (Math.round( taux * tmp )/tmp).toFixed(2)

                    PrixjanteAv = PrixjanteAv * taux
                    PrixjanteArr = PrixjanteArr * taux
                }

                PrixjanteAv = Math.round(PrixjanteAv * tmp )/tmp.toFixed(2)
                PrixjanteArr = Math.round(PrixjanteArr * tmp )/tmp.toFixed(2)
                PrixPneuAv = Math.round(PrixPneuAv * tmp )/tmp.toFixed(2)
                PrixPneuArr = Math.round(PrixPneuArr * tmp )/tmp.toFixed(2)

                switch(type){
                    case('4jantes decale'):
                        if(PrixjanteAv && PrixjanteArr){
                            price  = (PrixjanteAv * 2) + (PrixjanteArr * 2)
                        }
                        break;
                    case('4pneus decale'):
                        if(PrixPneuAv && PrixPneuArr){
                            price  = (((PrixPneuAv + 10) * 2) + ((PrixPneuArr + 10) * 2))
                        }
                        break;
                    case('2jantesAv'):
                        if(PrixjanteAv){
                            price  = (PrixjanteAv * 2)
                        }
                        break;
                    case('2jantesArr'):
                        if(PrixjanteArr){
                            price  = (PrixjanteArr * 2)
                        }
                        break;
                    case('2pneusAv'):
                        if(PrixPneuAv){
                            price  = ((PrixPneuAv + 10) * 2)
                        }
                        break;
                    case('2pneusArr'):
                        if(PrixPneuArr){
                            price  = ((PrixPneuArr + 10) * 2)
                        }
                        break;
                    case('1jante'):
                        if(PrixjanteAv){
                            price  = PrixjanteAv
                        }
                        if(PrixjanteArr){
                            price  = PrixjanteArr
                        }
                        break;
                    case('1pneu'):
                        if(PrixPneuAv){
                            price  = (PrixPneuAv + 10)
                        }
                        if(PrixPneuArr){
                            price  = (PrixPneuArr + 10)
                        }
                        break;
                    case('SOUS TOTAL DECALE'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires)
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires)
                        }
                        break;
                    case('TOTAL'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires + 9.90)
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires + 9.90)
                        }
                        break;
                    case('TOTAL COFIDIS'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = (((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires + 9.90) * 1.022)
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = (((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires + 9.90) * 1.022)
                        }
                        break;
                    case('TVA'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires + 9.90) * 0.2
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires + 9.90) * 0.2
                        }
                        break;
                    case('COFIDIS DECALE'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)  +  (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires) /4
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)  + this.TotalPriceAccessoires) /4
                        }
                        break;
                }
                return (Math.round( price * tmp )/tmp).toFixed(2)
            },
            changeSizeAv: function(){

                // this.conseilIdFront = this.CaracteristiquePneuPossible.indexOf(size)
                // this.ParamsFront = size
                //
                //
                // // console.log('SIZEAv', size)
                // var resAv1 = size.split("/")
                // var resAvHbv = resAv1[0]
                // var resAvTmp = resAv1[1]
                //
                // var resAv2 = resAvTmp.split("R")
                // var resAvWidth = resAv2[0]
                // var resAvDiametre = resAv2[1]
                // console.log('resAv1', resAv1)
                // console.log('resAvHbv', resAvHbv)
                // console.log('resAvWidth', resAvWidth)
                // console.log('resAvDiametre', resAvDiametre)
            },
            changeSizeArr: function(){

            //     this.ParamsRear = size
            //
            //     this.conseilIdRear = this.CaracteristiquePneuPossible.indexOf(size)
            //
            //     // console.log('SIZEArr', size)
            //     var resArr1 = size.split("/")
            //     var resArrHbv = resArr1[0]
            //     var resArrTmp = resArr1[1]
            //
            //     var resArr2 = resArrTmp.split("R")
            //     var resArrWidth = resArr2[0]
            //     var resArrDiametre = resArr2[1]
            //     console.log('resArr1', resArr1)
            //     console.log('resArrHbv', resArrHbv)
            //     console.log('resArrWidth', resArrWidth)
            //     console.log('resArrDiametre', resArrDiametre)
            },
            getOnglet(value){

                switch(value){
                    case'jantesAv':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv
                    case 'jantesArr':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr
                    case 'pneusAv':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv
                    case 'pneusArr':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr
                    case 'accessoires':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').accessoires
                    case 'modelePossibleAuCarre':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre')
                    case 'modelePossibleFront':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront')
                    case 'modelePossibleRear':
                        return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear')

                }

            },
            setOnglet(item, value){
                // console.log('setOnglet',value, item)

                switch(value){
                    case"4jantes":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : item, jantesArr : item})
                        this.triggerUpdate ++
                        break
                    case"jantesAv":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : item, jantesArr : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr})
                        this.triggerUpdate ++
                        break
                    case"jantesArr":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes', {jantesAv : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv, jantesArr : item})
                        this.triggerUpdate ++
                        break
                    case"4pneus":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : item, pneusArr : item})
                        this.triggerUpdate ++
                        break
                    case"pneusAv":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : item, pneusArr : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr})
                        this.triggerUpdate ++
                        break
                    case"pneusArr":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus', {pneusAv : ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv, pneusArr : item})
                        this.triggerUpdate ++
                        break
                    case"modelePossibleAuCarre":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre', item)
                        break
                    case"modelePossibleFront":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront', item)
                        break
                    case"modelePossibleRear":
                        ls.set(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear', item)
                        break
                }

                ls.set('last_updated', this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale)

            },
            updateRoute(params, type) {

                var splitedUrlNew = this.$route.path.split('/')
                var splitedUrlOld = this.$route.path.split('/')
                // eslint-disable-next-line no-unused-vars
                var joinedUrl

                switch(type){
                    case 'marque':
                        splitedUrlNew[2] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.marque = params
                        break
                    case 'modele':
                        splitedUrlNew[3] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.modele = params
                        break
                    case 'diametre':
                        splitedUrlNew[4] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.diametre = params
                        break
                    case 'couleur':
                        splitedUrlNew[5] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.couleur = params
                        break
                    case 'decale':
                        splitedUrlNew[6] = params
                        joinedUrl = splitedUrlNew.join("/")
                        this.updateOnglet(splitedUrlOld.splice(-5,  5 ).join("/"), splitedUrlNew.splice(-5,  5 ).join("/"))
                        this.$route.params.decale = params
                        break
                }

                history.pushState(
                    {},
                    null,
                    joinedUrl
                )
            },
            updateOnglet(oldName, newName){

                ls.get(oldName + '__infosJantes') ?  ls.set(newName + '__infosJantes', ls.get(oldName + '__infosJantes')) : null
                ls.get(oldName + '__infosPneus') ?  ls.set(newName + '__infosPneus', ls.get(oldName + '__infosPneus')) : null
                ls.get(oldName + '__modelePossibleAuCarre') ?  ls.set(newName + '__modelePossibleAuCarre', ls.get(oldName + '__modelePossibleAuCarre')) : null
                ls.get(oldName + '__modelePossibleFront') ?  ls.set(newName + '__modelePossibleFront', ls.get(oldName + '__modelePossibleFront')) : null
                ls.get(oldName + '__modelePossibleRear') ?  ls.set(newName + '__modelePossibleRear', ls.get(oldName + '__modelePossibleRear')) : null


            }
        },
        created() {
            this.SelectedJanteAv = this.getOnglet('jantesAv')
            this.SelectedJanteArr = this.getOnglet('jantesArr')


            this.typeMonte = (this.$route.params.decale).toString() === '0' ? 'par4' : 'decale'
            this.$store.commit('loader/setLoader', true)
            if( this.typeMonte != 'par4' && this.monteStateDeca === true){
                this.$store.commit('infoPneus/setMonteDeca', true)
            }else if(this.monteStateDeca === false){
                this.$store.commit('infoPneus/setMonteDeca', false)
            }

            this.$store.commit('footerNext/setNext', 'false')

            if(this.$vuetify.breakpoint.lgAndUp){
                this.overlayPos = true;
                this.overlayOpacity = 0.46
            }
            if(this.$vuetify.breakpoint.mdAndDown){
                this.overlayPos = false;
                this.overlayOpacity = 0.95
            }

        },
        mounted(){
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
            this.getPneuPossible()

            if( this.typeMonte !== 'par4' && this.monteStateDeca === true ){
                this.updateRoute(1, 'decale')
              this.typeMonte = 'decale'
              this.$store.commit('infoPneus/setMonteDeca', true)
            }

            if( process.env.NODE_ENV.trim() !== "development") {
                window.dataLayer.push({
                    'event': 'Pageview',
                    'pagePath': '/etape3',
                    'pageTitle': 'Etape3'
                });
            }
        },
      computed:{
          ...mapState({
            monteStateDeca: state => state.infoJantes.monteStateDeca,

            Next : state => state.footerNext.Next,
            idUser: state => state.auth.userId,
            TotalPriceAccessoires : state => state.infoAccessoires.TotalPriceAccessoires,
            isProfessional: state => state.auth.isRevendeur,
            loaderStatus: state => state.loader.isLoader,
            tauxRemise: state => state.auth.tauxRemise,


          })
      }
    }

</script>

<style scoped>
    .fontSize >>> label{
        font-size: small;
        color: #232D4D;
    }
    .bold >>> label{
        font-weight: bold;
    }
    .choosenTire{
        background-color:rgba(137, 158, 168, 0.30);
    }
    .border_lightGrey{
        border: 2px solid #899EA8;
        border-bottom: 1px;
    }
    .border_lightGrey > div:not(:last-child) {
        border-right: 2px solid #899EA8;
    }


    .borderLeft_lightGrey{
        border-left: 1px solid #899EA8
    }
    .borderTop_lightGrey{
        border-top: 1px solid #899EA8
    }
    .NoBorderRightBot_black{
        border-left: 1px solid black;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
    }
    .noBorderLeft_black{
        border-right: 1px solid black;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
    }

    .border_right{
        border-right: 1px solid rgba(137, 158, 168, 0.15)
    }

    .v-application--is-ltr .v-input--selection-controls__input {
        margin-right: 0 !important;
    }

    .marg >>> .v-input--selection-controls__input {
        margin-right: 0 !important;
    }
     @media (max-width: 960px) {
        .MyCustomHitsItem {
            margin-top: 1rem !important;
            margin-right: 1rem !important;
            margin-left: 0rem !important;
            padding: 0 !important;
            width: calc(50% - 1rem) !important;
            border: 1px solid #c4c8d8 !important;
            -webkit-box-shadow: 0 2px 5px 0 #e3e5ec !important;
            box-shadow: 0 2px 5px 0 #e3e5ec !important;
        }
    }
    .ais-Pagination-list{
        padding: 0!important;
    }

    .MyCustomPaginationRoot{
        width: 40%;
        margin-left: auto;
        margin-right: 0;
    }


    .MyCustomPagination{
        /*text-decoration: underline;*/
        color:#232D4D!important;
        /*border-bottom: 2px solid black;*/
        border: none
    }
    .MyCustomPagination .tc_white{
        /*text-decoration: underline;*/
        color:white!important;
        /*border-bottom: 2px solid black;*/
        border: none
    }
    .MyCustomPaginationActiveEJ .MyCustomPagination{
        /*text-decoration: underline;*/
        background-color:#1DE4AF
        /*border-bottom: 2px solid black;*/
    }
    .MyCustomPaginationActiveDJ .MyCustomPagination{
        /*text-decoration: underline;*/
        background-color:#1DE4AF
        /*border-bottom: 2px solid black;*/
    }



    .MyCustomPaginationNavigate .MyCustomPagination{
        font-size: xx-large;
    }

    .ej-top-0 >>> .v-overlay__content {
        top: 10% !important;
        position: absolute;
    }


</style>
<style>
.img_logoPneu .v-image__image {
    background-size: 100%;
}
 .pneuPhoto .v-responsive__content{
    max-width: fit-content;
}

</style>
