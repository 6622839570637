<template>
    <v-app fluid >

        <HomepageHeaderEj class="backgroud_gradient" v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>


        <v-container fluid class="bc_white" >

            <v-col fluid offset-lg="1" lg="10" class="bc_white">
                <p class=" font-weight-bold  mb-6" :class="{'display-2':$vuetify.breakpoint.lg, 'display-3':$vuetify.breakpoint.xl, 'tc_blue': VUE_APP_SITE_ID === '1', 'tc_darkOrange': VUE_APP_SITE_ID === '2'}">Mon Panier</p>
            </v-col>
            <v-col offset-lg="2" lg="8" style=" margin-bottom: 2%">
                <v-data-table
                    :headers="headers"
                    :items="cart"
                    class="elevation-1"
                    hide-default-footer
                >
                    <template v-slot:item.img="{ item }" style="max-width: 30%">

                        <v-col v-if="$vuetify.breakpoint.mdAndUp" class="">
                            <v-img class="contain"  :class="{'ej-watermark': VUE_APP_SITE_ID === '1', 'dj-watermark': VUE_APP_SITE_ID === '2'}" :src="item.img" :alt="item.name" height="20vh" width="10vw"></v-img>
                        </v-col>
                        <v-img v-else class="contain"  :class="{'ej-watermark': VUE_APP_SITE_ID === '1', 'dj-watermark': VUE_APP_SITE_ID === '2'}" :src="item.img" :alt="item.name" width="10vw"></v-img>
                    </template>

                <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        @click="deleteItem(item)"
                    >
                        mdi-delete
                    </v-icon>
                </template>
                <template v-slot:no-data>
                    <span>Panier vide</span>
                </template>
                </v-data-table>

                <v-row v-if="cartJanteAv" class=" mt-7 font-weight-bold">
                    <v-col lg="2" class="text-right">

                        <span>Total</span>

                    </v-col>
                    <v-col lg="9" v-if="!monteDecaJante" class="text-right">
                        <span v-if="SelectedPneuAv">{{getRoundPrice(SelectedJanteAv.public_price, SelectedPneuAv.prixNet, 'SOUS TOTAL')}} €</span>
                        <span v-else>{{getRoundPrice(SelectedJanteAv.public_price, null, 'SOUS TOTAL')}} €</span>
                    </v-col>
                    <v-col lg="9" v-if="monteDecaJante" class="text-right">
                        <span v-if="SelectedPneuAv">{{getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, SelectedPneuAv.prixNet, SelectedPneuArr.prixNetRear, 'SOUS TOTAL DECALE')}} €</span>
                        <span v-else>{{getRoundPriceMonteDecale(SelectedJanteAv.public_price, SelectedJanteArr.public_price_rear, null, null, 'SOUS TOTAL DECALE')}} €</span>
                    </v-col>

                </v-row>
            </v-col>
            <v-col offset-lg="2">
                <span v-if="this.SelectedJanteAv && this.SelectedPneuAv" class="font-weight-bold" >FORFAIT MONTAGE 4 JANTES + 4 PNEUS (MONTÉ, VALVÉ ET ÉQUILIBRÉ) OFFERT</span>

            </v-col>

            <v-row v-if="cartJanteAv">
                <v-col lg="10" class="text-right">
<!--                    <router-link :to="{ name: 'Etape4'}" class="no_deco ">-->
                        <v-btn v-if="VUE_APP_SITE_ID === '1'" color="primary" @click="continueOrder">Finaliser ma commande</v-btn>
                        <v-btn v-else-if="VUE_APP_SITE_ID === '2'" color="#FC5C1F" style="color: white" @click="continueOrder">Finaliser ma commande</v-btn>
<!--                    </router-link>-->

                </v-col>

            </v-row>

        </v-container>



        <div>

            <v-overlay
                :absolute="false"
                :value="modalJante"
                :z-index="5"
            >
                <v-col >

                    <v-card light >

                        <v-card-text  class="tc_darkBlue text-center">
                            <span class="font-weight-bold title">Supprimer les jantes ?</span>
                            <br>
<!--                            <span>Vous allez supprimer les jantes sélectionnées de votre panier.</span>-->
                            <span>Vous allez supprimer toutes les jantes de votre panier.</span>
                            <br>
                            <span>La suppression des jantes entrainera la suppression du panier.</span>
                            <v-row>
                                <v-col lg="6" >
                                    <v-btn @click="deleteJante">
                                        Confirmer
                                    </v-btn>
                                </v-col>
                                <v-col lg="6">
                                    <v-btn @click="modalJante = false">
                                        Annuler
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>
                </v-col>
            </v-overlay>


            <v-overlay
                    :absolute="false"
                    :value="modalPneu"
                    :z-index="5"
                >
                    <v-col >

                        <v-card light >

                            <v-card-text  class="tc_darkBlue text-center">
                                <span class="font-weight-bold title">Supprimer les pneus ?</span>
                                <br>
                                <span>Vous allez supprimer les pneus sélectionnées de votre panier.</span>
                                <v-row>
                                    <v-col lg="6" >
                                        <v-btn @click="deletePneu">
                                            Confirmer
                                        </v-btn>
                                    </v-col>
                                    <v-col lg="6">
                                        <v-btn @click="modalPneu = false">
                                            Annuler
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-text>

                        </v-card>
                    </v-col>
                </v-overlay>

            <v-overlay
                :absolute="false"
                :value="modalAccessoire"
                :z-index="5"
            >
                <v-col >

                    <v-card light >

                        <v-card-text  class="tc_darkBlue text-center">
                            <span class="font-weight-bold title">Supprimer cet accessoire ?</span>
                            <br>
                            <span>Vous allez supprimer l'accessoire sélectionné de votre panier.</span>
                            <v-row>
                                <v-col lg="6" >
                                    <v-btn @click="deleteAccessoire">
                                        Confirmer
                                    </v-btn>
                                </v-col>
                                <v-col lg="6">
                                    <v-btn @click="modalAccessoire = false">
                                        Annuler
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>

                    </v-card>
                </v-col>
            </v-overlay>

        </div>


        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "../headers/HomepageHeaderEj";
import HomepageHeaderDj from "../headers/HomepageHeaderDj";
import HomepageFooterEj from "../footer/HomepageFooterEj";
import HomepageFooterDj from "../footer/HomepageFooterDj";

import {mapState} from 'vuex'
// import BagueCentrage from "@/assets/accessoires/bague-de-centrage.jpg";

    import SecureLS from "secure-ls";
    var ls = new SecureLS({ isCompression: false });

export default {
    name: "Cart.vue",
    components: {
        HomepageHeaderEj,
        HomepageHeaderDj,
        HomepageFooterEj,
        HomepageFooterDj,
    },
    data: () => ({
      cartJanteAv:null,
      cartJanteArr:null,
      modalJante: false,
      modalPneu: false,
      modalAccessoire: false,
      emptyCart: true,
      accessoireToDelete:null,
      dialog: false,
      headers: [
          { text: '', value: 'img', sortable: false },
          { text: 'Article', value: 'type', sortable: false },
          { text: 'Decription', value: 'desc', sortable: false },
          { text: 'Details', value: 'details', sortable: false },
          { text: 'Quantité ', value: 'quantite',sortable: false },
          { text: 'Prix TTC', value: 'prix',sortable: false },
          { text: 'Supprimer', value: 'actions', sortable: false },
      ],
      cart: [],
      editedIndex: -1,
      editedItem: {
          type:'',
          desc: '',
          quantite: 0,
          prix: 0,
      },
      defaultItem: {
          type:'',
          desc: '',
          quantite: 0,
          prix: 0,
      },
      nbrCartCounter: 0,
        typeMonte:null,
        SelectedJanteAv: {},
        SelectedJanteArr: {},
        SelectedPneuAv: {},
        SelectedPneuArr: {},
        monteDecaJante: false,
        monteDecaPneu: false,
        VUE_APP_SITE_ID:null

    }),
    metaInfo() {
        return {
            title: `Mon panier - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`),
        }
    },
    created() {
        this.SelectedJanteAv = ls.get(ls.get('last_updated') + '__infosJantes').jantesAv ? ls.get(ls.get('last_updated') + '__infosJantes').jantesAv : null
        this.SelectedJanteArr = ls.get(ls.get('last_updated') + '__infosJantes').jantesArr ? ls.get(ls.get('last_updated') + '__infosJantes').jantesArr : null
        this.SelectedPneuAv = ls.get(ls.get('last_updated') + '__infosPneus').pneusAv ? ls.get(ls.get('last_updated') + '__infosPneus').pneusAv : null
        this.SelectedPneuArr = ls.get(ls.get('last_updated') + '__infosPneus').pneusArr ? ls.get(ls.get('last_updated') + '__infosPneus').pneusArr : null
        this.SelectedAccessoires = ls.get(ls.get('last_updated') + '__infosAccessoires').accessoires ? ls.get(ls.get('last_updated') + '__infosAccessoires').accessoires : []
        this.TotalPriceAccessoires = ls.get(ls.get('last_updated') + '__infosAccessoires').totalPrice ? ls.get(ls.get('last_updated') + '__infosAccessoires').totalPrice : 0

        this.cartJanteAv = this.SelectedJanteAv
        this.cartJanteArr = this.SelectedJanteArr


      this.initialize()
    },
    methods:{
        continueOrder(){
            var lastUpdated = ls.get('last_updated')

            var params = lastUpdated.split('/')

            this.$router.push({name:'Etape4',params: { marque: params[0], modele : params[1], diametre: params[2], couleur: params[3], decale: params[4] }})

        },
        removeOngletData(value){
                // console.log('removeOngletData',value)
            var lastUpdated = ls.get('last_updated')

            switch(value){
                case"jantes":
                    ls.remove(lastUpdated + '__infosJantes')
                    ls.remove(lastUpdated + '__infosPneus')
                    ls.remove(lastUpdated + '__infosAccessoires')

                    this.triggerUpdate ++
                    break
                case"pneus":
                    ls.remove(lastUpdated + '__infosPneus')

                    this.triggerUpdate ++
                    break
                case"accessoires":
                    ls.remove(lastUpdated + '__infosAccessoires')
                    this.triggerUpdate ++
                    break

            }
        },
        deleteJante:function () {
            this.removeOngletData('jantes')
            this.modalJante = false
            this.initialize()
            this.cart = []

            this.$store.commit('nbrCart/setNbrCart', 0)

        },
        deletePneu:function () {
            this.removeOngletData('pneus')
            this.modalPneu = false
            this.initialize()


            if((JSON.stringify(this.SelectedJanteAv) === JSON.stringify(this.SelectedJanteArr)) && this.SelectedJanteAv ) {

                if(this.monteDecaJante) {
                    if (JSON.stringify(this.SelectedJanteAv.largeurMinAv) === JSON.stringify(this.SelectedJanteAv.largeurMaxArr) && JSON.stringify(this.SelectedJanteAv.offsetFront) === JSON.stringify(this.SelectedJanteAv.offsetRear)) {
                        // this.nbrCart ++
                        this.nbrCartCounter ++
                        this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)

                    }
                    else if (JSON.stringify(this.SelectedJanteAv.largeurMinAv) !== JSON.stringify(this.SelectedJanteAv.largeurMaxArr) || JSON.stringify(this.SelectedJanteAv.offsetFront) !== JSON.stringify(this.SelectedJanteAv.offsetRear)) {
                        // this.nbrCart += 2
                        this.nbrCartCounter += 2
                        this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                    }
                }
                else {
                    // this.nbrCart ++
                    this.nbrCartCounter ++
                    this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                }
            }
            else if(this.SelectedJanteAv && this.SelectedJanteArr) {

                this.nbrCartCounter += 2
                this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
            }


            if((JSON.stringify(this.SelectedPneuAv) === JSON.stringify(this.SelectedPneuArr)) && this.SelectedPneuAv ) { //Si pneu avant === pneu arriere

                if(this.monteDecaPneu === true) { //Si monte décalée
                    if (this.SelectedPneuAv.HBV === this.SelectedPneuAv.HBVREAR) { //Si pneu avant  HBV === pneu arriere HBVREAR => pneu X4
                        // this.nbrCart ++
                        this.nbrCartCounter ++
                        this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                    }
                    else if (this.SelectedPneuAv.HBV !== this.SelectedPneuAv.HBVREAR) { //Si pneu avant  HBV !== pneu arriere HBVREAR => pneuAvant X2 && pneuArriere X2
                        this.nbrCart += 2
                        this.nbrCartCounter += 2
                        this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                    }
                }
                else{ //Si monte par 4
                    // this.nbrCart ++
                    this.nbrCartCounter ++
                    this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                }
            }
            else if(this.SelectedPneuAv && this.SelectedPneuArr) { //Sinon si pneu avant !== pneu arriere
                this.nbrCartCounter += 2
                this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
            }

            if(this.SelectedAccessoires) { //Si accessoires

                Object.values(this.SelectedAccessoires).forEach((accessoire) => {
                    if (accessoire != null) {

                        this.nbrCartCounter ++
                        this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                    }

                })
            }

        },
        deleteAccessoire:function () {
            this.removeOngletData('accessoires')
            this.$store.commit('infoAccessoires/deleteOneAccessoire', this.accessoireToDelete)
            this.modalAccessoire = false
            this.initialize()

            if((JSON.stringify(this.SelectedJanteAv) === JSON.stringify(this.SelectedJanteArr)) && this.SelectedJanteAv ) {

                if(this.monteDecaJante) {
                    if (JSON.stringify(this.SelectedJanteAv.largeurMinAv) === JSON.stringify(this.SelectedJanteAv.largeurMaxArr) && JSON.stringify(this.SelectedJanteAv.offsetFront) === JSON.stringify(this.SelectedJanteAv.offsetRear)) {
                        // this.nbrCart ++
                        this.nbrCartCounter ++
                        this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)

                    }
                    else if (JSON.stringify(this.SelectedJanteAv.largeurMinAv) !== JSON.stringify(this.SelectedJanteAv.largeurMaxArr) || JSON.stringify(this.SelectedJanteAv.offsetFront) !== JSON.stringify(this.SelectedJanteAv.offsetRear)) {
                        // this.nbrCart += 2
                        this.nbrCartCounter += 2
                        this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                    }
                }
                else {
                    // this.nbrCart ++
                    this.nbrCartCounter ++
                    this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                }
            }
            else if(this.SelectedJanteAv && this.SelectedJanteArr) {

                this.nbrCartCounter += 2
                this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
            }


            if((JSON.stringify(this.SelectedPneuAv) === JSON.stringify(this.SelectedPneuArr)) && this.SelectedPneuAv ) { //Si pneu avant === pneu arriere

                if(this.monteDecaPneu === true) { //Si monte décalée
                    if (this.SelectedPneuAv.HBV === this.SelectedPneuAv.HBVREAR) { //Si pneu avant  HBV === pneu arriere HBVREAR => pneu X4
                        // this.nbrCart ++
                        this.nbrCartCounter ++
                        this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                    }
                    else if (this.SelectedPneuAv.HBV !== this.SelectedPneuAv.HBVREAR) { //Si pneu avant  HBV !== pneu arriere HBVREAR => pneuAvant X2 && pneuArriere X2
                        this.nbrCart += 2
                        this.nbrCartCounter += 2
                        this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                    }
                }
                else{ //Si monte par 4
                    // this.nbrCart ++
                    this.nbrCartCounter ++
                    this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                }
            }
            else if(this.SelectedPneuAv && this.SelectedPneuArr) { //Sinon si pneu avant !== pneu arriere
                this.nbrCartCounter += 2
                this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
            }

            if(this.SelectedAccessoires) { //Si accessoires

                Object.values(this.SelectedAccessoires).forEach((accessoire) => {
                    if (accessoire != null) {

                        this.nbrCartCounter ++
                        this.$store.commit('nbrCart/setNbrCart', this.nbrCartCounter)
                    }

                })
            }
        },
        getRoundPrice: function (Prixjante, PrixPneu, type){
            let price

            if(this.isProfessional){

                let taux = (1 - parseFloat('0.' + this.tauxRemise))
                let tmp = Math.pow(10, 2);
                taux = (Math.round( taux * tmp )/tmp).toFixed(2)

                Prixjante = Prixjante * taux
            }

            switch(type){
                case('4jantes'):
                    if(Prixjante){
                        price  = (Prixjante * 4)
                    }
                    break;
                case('2jantes'):
                    if(Prixjante){
                        price  = (Prixjante * 2)
                    }
                    break;
                case('4pneus'):
                    if(PrixPneu){
                        price  = ((PrixPneu + 10) * 4)
                    }
                    break;
                case('2pneus'):
                    if(PrixPneu){
                        price  = ((PrixPneu + 10) * 2)
                    }
                    break;
                case('SOUS TOTAL'):
                    if(Prixjante && PrixPneu){
                        price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires)
                    }else if(Prixjante){
                        price  = ((Prixjante * 4) + this.TotalPriceAccessoires)
                    }
                    break;
                case('TOTAL'):
                    if(Prixjante && PrixPneu){
                        price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90)
                    }else if(Prixjante){
                        price  = ((Prixjante * 4) + this.TotalPriceAccessoires + 9.90)
                    }
                    break;
                case('TVA'):
                    if(Prixjante && PrixPneu){
                        price  = ((Prixjante * 4) + ((PrixPneu + 10) * 4) + this.TotalPriceAccessoires + 9.90 ) * 0.2
                    }else if(Prixjante){
                        price  = ((Prixjante * 4) + this.TotalPriceAccessoires + 9.90) * 0.2
                    }
                    break;
            }
            var tmp = Math.pow(10, 2);
            return (Math.round( price * tmp )/tmp).toFixed(2)
        },
        getRoundPriceMonteDecale: function (PrixjanteAv, PrixjanteArr, PrixPneuAv, PrixPneuArr, type){
                let price

            if(this.isProfessional){

                let taux = (1 - parseFloat('0.' + this.tauxRemise))
                let tmp = Math.pow(10, 2);
                taux = (Math.round( taux * tmp )/tmp).toFixed(2)

                PrixjanteAv = PrixjanteAv * taux
                PrixjanteArr = PrixjanteArr * taux
            }

                switch(type){
                    case('4jantes decale'):
                        if(PrixjanteAv && PrixjanteArr){
                            price  = (PrixjanteAv * 2) + (PrixjanteArr * 2)
                        }
                        break;
                    case('4pneus decale'):
                        if(PrixPneuAv && PrixPneuArr){
                            price  = (((PrixPneuAv + 10) * 2) + ((PrixPneuArr + 10) * 2))
                        }
                        break;
                    case('2jantesAv'):
                        if(PrixjanteAv){
                            price  = (PrixjanteAv * 2)
                        }
                        break;
                    case('2jantesArr'):
                        if(PrixjanteArr){
                            price  = (PrixjanteArr * 2)
                        }
                        break;
                    case('2pneusAv'):
                        if(PrixPneuAv){
                            price  = ((PrixPneuAv + 10) * 2)
                        }
                        break;
                    case('2pneusArr'):
                        if(PrixPneuArr){
                            price  = ((PrixPneuArr + 10) * 2)
                        }
                        break;
                    case('SOUS TOTAL DECALE'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires)
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires)
                        }
                        break;
                    case('TOTAL'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires + 9.90)
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires + 9.90)
                        }
                        break;
                    case('TVA'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2) + (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires + 9.90) * 0.2
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)+ this.TotalPriceAccessoires + 9.90) * 0.2
                        }
                        break;
                    case('COFIDIS DECALE'):
                        if((PrixjanteAv && PrixjanteArr) && (PrixPneuAv && PrixPneuArr)){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)  +  (((PrixPneuAv + 10) * 2) +  ((PrixPneuArr + 10) * 2)) + this.TotalPriceAccessoires) /4
                        }else if(PrixjanteAv && PrixjanteArr){
                            price  = ((PrixjanteAv * 2) + (PrixjanteArr * 2)  + this.TotalPriceAccessoires) /4
                        }
                        break;
                }
                var tmp = Math.pow(10, 2);
                return (Math.round( price * tmp )/tmp).toFixed(2)
            },
        initialize () {
            // console.log('this.SelectedJanteAv',this.SelectedJanteAv)
            // console.log('this.SelectedJanteArr', this.SelectedJanteArr)
            // console.log('this.SelectedPneuAv',this.SelectedPneuAv)
            // console.log('this.SelectedPneuArr', this.SelectedPneuArr)
            // this.typeMonte = ls.get('monteType')

            var splitedUrl = ls.get('last_updated').split('/')

            this.typeMonte =  splitedUrl.splice(-1, 1).toString() === '0' ? 'par4' : 'decale'
            this.typeMonte === 'decale' ? this.monteDecaJante = true : this.monteDecaJante = false
            this.typeMonte === 'decale' ? this.monteDecaPneu = true : this.monteDecaPneu = false

            this.SelectedJanteAv = ls.get(ls.get('last_updated') + '__infosJantes').jantesAv ? ls.get(ls.get('last_updated') + '__infosJantes').jantesAv : null
            this.SelectedJanteArr = ls.get(ls.get('last_updated') + '__infosJantes').jantesArr ? ls.get(ls.get('last_updated') + '__infosJantes').jantesArr : null
            this.SelectedPneuAv = ls.get(ls.get('last_updated') + '__infosPneus').pneusAv ? ls.get(ls.get('last_updated') + '__infosPneus').pneusAv : null
            this.SelectedPneuArr = ls.get(ls.get('last_updated') + '__infosPneus').pneusArr ? ls.get(ls.get('last_updated') + '__infosPneus').pneusArr : null
            this.SelectedAccessoires = ls.get(ls.get('last_updated') + '__infosAccessoires').accessoires ? ls.get(ls.get('last_updated') + '__infosAccessoires').accessoires : []
            this.TotalPriceAccessoires = ls.get(ls.get('last_updated') + '__infosAccessoires').totalPrice ? ls.get(ls.get('last_updated') + '__infosAccessoires').totalPrice : 0


            if((JSON.stringify(this.SelectedJanteAv) === JSON.stringify(this.SelectedJanteArr)) && this.SelectedJanteAv ) {
                this.emptyCart = false
                if(this.monteDecaJante) {
                    if (JSON.stringify(this.SelectedJanteAv.largeurMinAv) === JSON.stringify(this.SelectedJanteAv.largeurMaxArr) && JSON.stringify(this.SelectedJanteAv.offsetFront) === JSON.stringify(this.SelectedJanteAv.offsetRear)) {
                        this.cart = [
                            {
                                img: this.SelectedJanteAv.photo_1,
                                type: 'Jantes',
                                desc: this.SelectedJanteAv.marque + ' ' + this.SelectedJanteAv.modele + ' ' + this.SelectedJanteAv.couleur,
                                details: (this.SelectedJanteAv.largeur ? this.SelectedJanteAv.largeur : this.SelectedJanteAv.largeurMinAv) + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + (this.SelectedJanteAv.offset ? this.SelectedJanteAv.offset : this.SelectedJanteAv.offsetRear),
                                quantite: 4,
                                prix: this.getRoundPrice(this.SelectedJanteAv.public_price, null, '4jantes') + ' €',

                            }
                        ]
                    }
                    else if (JSON.stringify(this.SelectedJanteAv.largeurMinAv) !== JSON.stringify(this.SelectedJanteAv.largeurMaxArr) || JSON.stringify(this.SelectedJanteAv.offsetFront) !== JSON.stringify(this.SelectedJanteAv.offsetRear)) {
                        this.cart = [
                            {
                                img: this.SelectedJanteAv.photo_1,
                                type: 'Jantes',
                                desc: this.SelectedJanteAv.marque + ' ' + this.SelectedJanteAv.modele + ' ' + this.SelectedJanteAv.couleur,
                                details: this.SelectedJanteAv.largeurMinAv + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.SelectedJanteAv.offsetFront,
                                quantite: 2,
                                prix: this.getRoundPriceMonteDecale(this.SelectedJanteAv.public_price, null, null, null, '2jantesAv') + ' €',

                            },
                            {
                                img: this.SelectedJanteArr.photo_1,
                                type: 'Jantes',
                                desc: this.SelectedJanteArr.marque + ' ' + this.SelectedJanteArr.modele + ' ' + this.SelectedJanteArr.couleur,
                                details: this.SelectedJanteArr.largeurMaxArr + 'X' + this.SelectedJanteArr.diametre + ' - ' + this.SelectedJanteArr.entraxe + ' - ET ' + this.SelectedJanteArr.offsetRear,
                                quantite: 2,
                                prix: this.getRoundPriceMonteDecale(null, this.SelectedJanteArr.public_price_rear, null, null, '2jantesArr') + ' €',

                            }
                        ]
                    }
                }
                else {
                    this.cart = [
                        {
                            img: this.SelectedJanteAv.photo_1,
                            type: 'Jantes',
                            desc: this.SelectedJanteAv.marque + ' ' + this.SelectedJanteAv.modele + ' ' + this.SelectedJanteAv.couleur,
                            details: this.SelectedJanteAv.largeur + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.SelectedJanteAv.offset,
                            quantite: 4,
                            prix: this.getRoundPrice(this.SelectedJanteAv.public_price, null, '4jantes') + ' €',

                        }
                    ]
                }
             }
            else if(this.SelectedJanteAv && this.SelectedJanteArr) {
                this.emptyCart = false
                this.cart = [
                    {
                        img: this.SelectedJanteAv.photo_1,
                        type: 'Jantes',
                        desc: this.SelectedJanteAv.marque + ' ' + this.SelectedJanteAv.modele + ' ' + this.SelectedJanteAv.couleur,
                        details: this.SelectedJanteAv.largeur + 'X' + this.SelectedJanteAv.diametre + ' - ' + this.SelectedJanteAv.entraxe + ' - ET ' + this.SelectedJanteAv.offset,
                        quantite: 2,
                        prix: this.getRoundPrice(this.SelectedJanteAv.public_price, null, '2jantes') + ' €',

                    },
                    {
                        img: this.SelectedJanteArr.photo_1,
                        type: 'Jantes',
                        desc: this.SelectedJanteArr.marque + ' ' + this.SelectedJanteArr.modele + ' ' + this.SelectedJanteArr.couleur,
                        details: this.SelectedJanteArr.largeur + 'X' + this.SelectedJanteArr.diametre + ' - ' + this.SelectedJanteArr.entraxe + ' - ET ' + this.SelectedJanteArr.offset,
                        quantite: 2,
                        prix: this.getRoundPrice(this.SelectedJanteArr.public_price, null, '2jantes') + ' €',

                    }
                ]
            }


            if((JSON.stringify(this.SelectedPneuAv) === JSON.stringify(this.SelectedPneuArr)) && this.SelectedPneuAv ) { //Si pneu avant === pneu arriere
                this.emptyCart = false
                if(this.monteDecaPneu === true) { //Si monte décalée
                    if (this.SelectedPneuAv.HBV === this.SelectedPneuAv.HBVREAR) { //Si pneu avant  HBV === pneu arriere HBVREAR => pneu X4
                        this.cart.push(
                            {
                                img: this.SelectedPneuAv.photo,
                                type: 'Pneus',
                                desc: this.SelectedPneuAv.marque + ' ' + this.SelectedPneuAv.modele,
                                details: this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' + this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' ' + this.SelectedPneuAv?.indiceDeVitesse + ' ' + (this.SelectedPneuAv.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuAv.hiver === 1 ? 'Hiver' : this.SelectedPneuAv.ete === 1 ? 'Eté' : ' 4 saisons'),
                                quantite: 4,
                                prix: this.getRoundPrice(null, this.SelectedPneuAv.prixNet, '4pneus') + ' €',

                            })
                    }
                    else if (this.SelectedPneuAv.HBV !== this.SelectedPneuAv.HBVREAR) { //Si pneu avant  HBV !== pneu arriere HBVREAR => pneuAvant X2 && pneuArriere X2
                        this.cart.push(
                            {
                                img: this.SelectedPneuAv.photo,
                                type: 'Pneus',
                                desc: this.SelectedPneuAv.marque + ' ' + this.SelectedPneuAv.modele,
                                details: this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' + this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' ' + this.SelectedPneuAv?.indiceDeVitesse + ' ' + (this.SelectedPneuAv.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuAv.hiver === 1 ? 'Hiver' : this.SelectedPneuAv.ete === 1 ? 'Eté' : ' 4 saisons'),
                                quantite: 2,
                                prix: this.getRoundPriceMonteDecale(null,null, this.SelectedPneuAv.prixNet, null, '2pneusAv') + ' €',

                            },
                            {
                                img: this.SelectedPneuArr.photo,
                                type: 'Pneus',
                                desc: this.SelectedPneuArr.marque + ' ' + this.SelectedPneuArr.modele,
                                details: this.SelectedPneuArr.HBVREAR + '/' + this.SelectedPneuArr.widthRear + 'R' + this.SelectedPneuArr.diametre + ' ' + this.SelectedPneuArr.indiceDeChargeRear + ' ' + this.SelectedPneuArr.indiceDeVitesse + ' ' + (this.SelectedPneuArr.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuArr.hiver === 1 ? 'Hiver' : this.SelectedPneuArr.ete === 1 ? 'Eté' : ' 4 saisons'),
                                quantite: 2,
                                prix: this.getRoundPriceMonteDecale(null,null,null, this.SelectedPneuArr.prixNetRear, '2pneusArr') + ' €',

                            }
                        )
                    }
                }
                else{ //Si monte par 4
                    this.cart.push(
                        {
                            img: this.SelectedPneuAv.photo,
                            type: 'Pneus',
                            desc: this.SelectedPneuAv.marque + ' ' + this.SelectedPneuAv.modele,
                            details: this.SelectedPneuAv.HBV + '/' + this.SelectedPneuAv.width + 'R' + this.SelectedPneuAv.diametre + ' ' + this.SelectedPneuAv.indiceDeCharge + ' ' + this.SelectedPneuAv?.indiceDeVitesse + ' ' + (this.SelectedPneuAv.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuAv.hiver === 1 ? 'Hiver' : this.SelectedPneuAv.ete === 1 ? 'Eté' : ' 4 saisons'),
                            quantite: 4,
                            prix: this.getRoundPrice(null, this.SelectedPneuAv.prixNet, '4pneus') + ' €',

                        })
                }
            }
            else if(this.SelectedPneuAv && this.SelectedPneuArr) { //Sinon si pneu avant !== pneu arriere
                this.emptyCart = false
                this.cart.push(
                    {
                        img: this.SelectedPneuAv.photo,
                        type: 'Pneus',
                        desc: this.SelectedPneuAv.marque + ' ' + this.SelectedPneuAv.modele ,
                        details: this.SelectedPneuAv.width +'/'+ this.SelectedPneuAv.HBV + ' ' + this.SelectedPneuAv.marque + ' ' + this.SelectedPneuAv.diametre + ' ' + (this.SelectedPneuAv.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuAv.hiver === 1 ? 'Hiver' : this.SelectedPneuAv.ete === 1 ? 'Eté' : ' 4 saisons'),
                        quantite: 2,
                        prix: this.getRoundPrice(null, this.SelectedPneuAv.prixNet, '2pneus')  + ' €',

                    },
                    {
                        img: this.SelectedPneuArr.photo,
                        type: 'Pneus',
                        desc: this.SelectedPneuArr.marque + ' ' + this.SelectedPneuArr.modele ,
                        details: this.SelectedPneuArr.width +'/'+ this.SelectedPneuArr.HBV + ' ' + this.SelectedPneuArr.marque + ' ' + this.SelectedPneuArr.diametre + ' ' + (this.SelectedPneuArr.runFlat === 1 ? 'Runflat ' : 'Non Runflat ') + (this.SelectedPneuArr.hiver === 1 ? 'Hiver' : this.SelectedPneuArr.ete === 1 ? 'Eté' : ' 4 saisons'),
                        quantite: 2,
                        prix: this.getRoundPrice(null, this.SelectedPneuArr.prixNet, '2pneus')  + ' €',

                    }
                )
            }

            if(this.SelectedAccessoires) { //Si accessoires
                this.emptyCart = false

                Object.values(this.SelectedAccessoires).forEach((accessoire)=> {
                    if(accessoire != null){
                        this.cart.push(
                            {
                                img: accessoire.data.img,
                                type: 'Accessoire',
                                desc: accessoire.data.name,
                                details: '',
                                quantite: 1,
                                prix: accessoire.data.price  + ' €',
                                index: accessoire.index

                            })
                    }

                })

            }

        },
        deleteItem (item) {
            if(item.type === 'Jantes'){
                this.modalJante = true
            }
            if(item.type === 'Pneus'){
                this.modalPneu = true
            }
            if(item.type === 'Accessoire'){
                this.accessoireToDelete = item.index
                this.modalAccessoire = true
            }
        },

    },
    mounted() {
        this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID

        if( process.env.NODE_ENV.trim() !== "development") {
            window.dataLayer.push({
                'event': 'Pageview',
                'pagePath': '/cart',
                'pageTitle': 'Panier'
            });
        }
    },
    computed:{
        ...mapState({
            isProfessional: state => state.auth.isRevendeur,
            nbrCart : state => state.nbrCart.nbrCart,
            tauxRemise: state => state.auth.tauxRemise,

        })
    },
    updated() {
        this.cartJanteAv = this.SelectedJanteAv
        this.cartJanteArr = this.SelectedJanteArr
    }
}
</script>

<style scoped>

.border-title{
    border-bottom: black solid 1px;
    border-left: black solid 1px;
    border-top: black solid 1px;
}

.contain >>> .v-image__image.v-image__image--cover{
    background-size: contain!important;
}

.ej-watermark::before {
    content: url('/img/ej-logo-baseline.e4a92426.svg');
    position: absolute;
    /* width: 50%; */
    /*transform: translateY(-50%);*/
    width: 50%;
    opacity: 0.5;
    bottom: 0;
    right: 27%;
}
.dj-watermark::before {
    content: url('https://cpt-distri-jantes.s3.eu-west-3.amazonaws.com/dj-watermark.svg');
    position: absolute;
    /* width: 50%; */
    /*transform: translateY(-50%);*/
    width: 50%;
    opacity: 0.5;
    bottom: 0;
    right: 27%;
}

/*.dj-watermark::before {*/
/*    content: url('/img/dj-logo.22b3f7f1.svg');*/
/*    position: absolute;*/
/*    transform: translateY(-50%);*/
/*    width: 50%;*/
/*    opacity: 0.5;*/
/*    bottom: 0;*/
/*    right: 27%;*/
/*}*/




/*@media (max-width: 1264px) {*/
/*    .dj-watermark::before {*/
/*        content: url('/img/dj-logo.22b3f7f1.svg');*/
/*        position: absolute;*/
/*        opacity: 0.5;*/
/*        bottom: -8%;*/
/*        transform: scale(0.2);*/
/*        right: -57%;*/

/*    }*/
/*}*/
/*@media (min-width: 1264px) and (max-width: 1904px) {*/
/*    .dj-watermark::before {*/
/*        content: url('/img/dj-logo.22b3f7f1.svg');*/
/*        position: absolute;*/
/*        opacity: 0.5;*/
/*        bottom: 0;*/
/*        transform: scale(0.4);*/
/*        right: -15%;*/

/*    }*/
/*}*/
/*@media (min-width: 1904px) {*/
/*    .dj-watermark::before {*/
/*        content: "";*/
/*        display: block;*/
/*        width: 100%;*/
/*        height: 100%;*/
/*        position: absolute;*/
/*        bottom: -26%;*/
/*        left: 11%;*/
/*        background-image: url('/img/dj-logo.22b3f7f1.svg');*/
/*        background-size: 70px 70px;*/
/*        background-position: 30px 30px;*/
/*        background-repeat: no-repeat;*/
/*        opacity: 0.5;*/
/*    }*/
/*}*/



/*content: url('/img/dj-logo.22b3f7f1.svg');
        position: absolute;
        opacity: 0.5;
        bottom: -8%;
        transform: scale(0.2);
        right: -57%;
*/

</style>
