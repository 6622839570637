<template>

    <v-app fluid >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>


        <v-main v-if="VUE_APP_SITE_ID === '1'">
            <v-col class="py-0">
                <v-row class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.mdAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 tc_blue font-weight-bold rotate">
                            Jantes - Entretien
                        </h1>

                    </v-col>
                    <v-col lg="5" >
                        <h1 v-if="$vuetify.breakpoint.smAndDown" class="text-md-h4 text-sm-h5 text-h5  tc_blue font-weight-bold ">
                            Jantes - Entretien
                        </h1>
                        <p class="font-weight-bold headline">ENTRETIEN COURANT</p>
                        <br>
                        <p class="font-weight-bold title">JANTES ANCIENNES </p>
                        <p class=" mb-3 pl-4 border_left tc_turquoise font-weight-bold
                                            text-xl-h5
                                        text-lg-body-1
                                        text-md-body-1
                                        text-sm-body-1
                                        text-body-1" >
                            Certaines jantes sont plus fragiles que d’autre, notamment les jantes en tôle.
                        </p>
                        <p>
                            Elles peuvent provoquer des crevaisons.
                            En effet, ce type de jantes vintage n’a pas de “talon “, et elles ont été prévues pour rouler avec des pneus sans chambre à air.
                            L’âge des jantes (35 à 40 ans) nécessite une vérification régulière.
                            Les infiltrations d’eau dans la valve et les tâches de rouille derrière les enjoliveurs réclament un entretien.
                        <p>
                            Suivant l’état de la jante, vous devez soit sabler la roue à moins qu’un simple brossage à la brosse métallique suffise.
                            Une fois l’oxyde de fer disparu, il faut dégraisser et nettoyer la jante.
                            Ensuite vous devez appliquer sur la jante de l’antirouille.
                            Après deux couches et un séchage complet, vos jantes – entretien, sont protégées et sécurisées pour de nombreuses années.
                        </p>
                        <br>
                        <p class="font-weight-bold title">RÉPARATION RAYURES</p>
                        <p>
                            Les jantes peuvent se rayer par une mauvaise manœuvre ou bien un accrochage.
                            Si la rayure est superficielle, vous pouvez utiliser du papier de verre ultra fin ou de la laine de fer spéciale finition.
                            Vous devez frotter jusqu’à disparition des traces. Ensuite pour obtenir une surface homogène, il faut polir et lustrer comme une peinture de carrosserie.
                            Ce procédé est permis uniquement sur des jantes – entretien non vernies.
                        </p>
                        <p>
                            Quand la rayure est profonde, il y a la technique de surfaçage.
                            Cette méthode est employée par les professionnels, elle est coûteuse, mais le résultat est impeccable.
                            Si vous souhaitez une solution plus simple, vous appliquez du mastic dans la rayure de la jante, puis après avoir poli la surface, vous apposez une peinture soit en contraste avec la carrosserie de la voiture soit identique à celle-ci.
                        </p>
                        <br>
                        <p class="font-weight-bold title">POLISSAGE</p>
                        <p>
                            Pour ceux qui sont méticuleux et épris de rutilance, le polissage est fait pour vous.
                            Il redonne à vos jantes – entretien un éclat incomparable.
                            Vous obtiendrez un aspect brillant ou miroir suivant la finition voulue.
                            Pour parvenir au résultat final, il faut compter environ 4 heures de travail par jante et un peu d’huile de coude.
                        </p>
                        <p>
                            Pour réussir l’opération, il faut vous munir d’une brosse métallique, d’un pinceau, d’un produit décapant universel, de papier de verre plutôt fin et enfin le produit nommé “belgom alu”.
                            Après avoir démonté et récuré vos jantes, vous appliquez le décapant au pinceau et vous grattez avec la brosse métallique.
                            Vous devez frottez avec le papier de verre jusqu’à l’apparition de l’alu de la jante.
                            Dès que toutes traces de peinture et de vernis sont enlevées, vous devez rincer abondamment.
                            Puis vous poncez les jantes jusqu’à l’obtention d’une surface lisse sans rayure.
                            Après cette étape, il ne vous reste plus qu’à étaler le “belgom alu” en utilisant une polisseuse.
                        </p>

                    </v-col>
                    <v-col class="bc_blue" lg="5" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-img src="@/assets/AboutUs.png"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phone.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold tc_blue align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="3" xl="3" class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.mdAndUp" cols="2" offset-lg="1" xl="3" lg="3">
                        <v-img style="position: absolute; width: 20%;" src="@/assets/illustration-man.png"/>
                    </v-col>
                </v-row>
            </v-container>

            <v-col  class="bc_white tc_black" >
            <v-row class="font-weight-regular
                                    text-xl-h5
                                    text-lg-body-1
                                    text-md-caption
                                    text-sm-caption
                                    text-caption">
                <v-col offset-lg="1" lg="7">
                    <br>
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">NETTOYAGE</p>
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">PRINCIPE</p>
                    <p>
                        Une voiture exige un entretien régulier si vous souhaitez conserver une belle carrosserie, non ternie et non délavée.
                        La carrosserie de la voiture, les chromes, les jantes subissent des agressions quotidiennes dues aux conditions atmosphériques et à la pollution ambiante.
                        C’est pour cette raison qu’il est nécessaire de laver fréquemment votre véhicule.
                    </p>
                    <br>
                    <p>
                        Les jantes se salissent rapidement notamment avec les plaquettes de freins qui déposent une pellicule de poussière noirâtre.
                        Avec les freins à tambours, les salissures des jantes seront moins rapides qu’avec des freins à disques.
                        Mais en général le dépôt sera plus important sur les deux jantes avant de la voiture, car les plaquettes sont plus sollicitées.
                    </p>
                    <br>
                    <p>
                        Toutes les jantes – entretien de voiture ne s’entretiennent pas de la même façon.
                        Quelles soient en aluminium, en acier, vernies ou peintes, vous devez tester le produit de nettoyage sur une partie cachée de la jante.
                        Sur les jantes neuves, les fabricants appliquent une pellicule de paraffine.
                        Il est conseillé de nettoyer au diluant cette paraffine au risque de voir vos jantes devenir rapidement sales et noires.
                        Vous devez prendre des précautions lors du lavage de vos jantes.
                        Vous avez l’obligation de vous servir des stations de lavage appropriées afin de préserver l’environnement.
                        Il faut éviter la pénétration dans les égouts et les eaux potables de certains produits corrosifs que vous utilisez pour le lavage.
                        En cas d’épandage, il faut neutraliser avec une solution acide et avertir les autorités si le produit pénètre dans le domaine public.
                        Certains produits commercialisés sont toxiques et corrosifs. Vous devez prendre des précautions et respecter le mode d’emploi en cas d’inhalation et de contact avec la peau afin d’éviter des conséquences irrémédiables.
                    </p>
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">PRODUITS SPÉCIFIQUES DE NETTOYAGE POUR JANTES</p>
                    <p>
                        Il y a toute une gamme de produits techniques vendus dans des magasins spécialisés.
                        Vous devez respecter la notice d’utilisation.
                        Ces produits sont souvent coûteux, mais leur efficacité est avérée.
                        La plupart s’utilise de la façon suivante : humidifier les jantes – entretien, pulvériser le produit, laisser agir, frotter pour éliminer les tâches rebelles et rincer copieusement à l’eau claire.
                    </p>
                    <p>
                        Désormais, vous trouverez un produit nettoyant jante et enjoliveur biodégradable.
                        Ce produit est écologique, et non agressif pour la nature et totalement recyclable.
                        Néanmoins, il est actif et élimine poussières de plaquettes de frein, graisses et salissures de la route.
                        Il peut s’utiliser sans eau, un simple essuyage suffit.
                        Il respecte les directives de l’OCDE (Organisation de Coopération et de Développement Economique), concernant l’aérobiose et l’anaérobiose.
                        Il est composé d’eau déminéralisée et de concentré de savon.
                    </p>
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">TRUC ET ASTUCES POUR NETTOYER VOS JANTES</p>
                    <p>Il existe beaucoup d’astuces et de produits qui vous aideront à rendre un aspect neuf à vos jantes.</p>
                    <ul>
                        <li>
                            Les jantes et les enjoliveurs des voitures peuvent se nettoyer avec un mélange de bicarbonate de soude et d’eau.
                            Vous devez frotter les jantes avec cette pâte puis rincer et essuyer toutes les traces.
                        </li>
                        <br>
                        <li>
                            Vous pouvez utiliser de la soude caustique avec moult précaution.
                            Il faut diluer le produit à 90 %, sinon il pourrait endommager les jantes.
                            Vous devez rincer abondamment avant et après le passage du produit.
                            Pour votre sécurité, il est conseillé de porter des bottes, des gants et des lunettes afin de vous prémunir d’éventuelles projections.
                            Par contre le résultat est probant, vous jantes redeviennent comme neuve.
                        </li>
                        <br>
                        <li>
                            Il y a la formule “Jex Four” et autres produits ménagers de cuisine.
                            Ces produits doivent être rincés aussitôt la pose effectuée.
                            Ce système est très bien quand vos jantes sont très encrassées, mais le vernis peut être endommagé.
                        </li>
                        <br>
                        <li>
                            Certain utilise du “mirrror” spécial aluminium ou de la “Pierre d’Argent “.
                            Avec un peu d’huile coude vous obtiendrez des jantes étincelantes et propres.
                        </li>
                        <br>
                        <li>
                            Les cristaux de soude diluée dans l’eau fonctionnent très bien.
                            Il faut appliquer ce mélange avec une éponge sur les jantes.
                            Il faut insister dans les recoins puis rincer soigneusement au jet d’eau.
                        </li>
                        <br>
                        <li>
                            Si vous souhaiter que vos chromes de jantes étincelles, il faut nettoyer avec un chiffon imbibé d’alcool à brûler, puis faire briller avec un chiffon tamponné dans la farine.
                        </li>
                        <br>
                        <li>
                            En cas de tâches de rouille sur une jante, vous devez badigeonner à l’aide d’un pinceau la zone avec de l’essence ” F” puis laisser agir quelques minutes, et bien rincer à l’eau savonneuse.
                        </li>
                        <br>
                        <li>
                            Les tâches de goudron sur les jantes arrivent fréquemment.
                            Il faut tamponner la jante avec un chiffon imbibé de “White Spirit “, frotter jusqu’à la disparition de la tâche, et laver à l’eau.
                        </li>
                        <br>
                        <li>
                            Le procédé le moins chère du marché pour rendre à vos jantes une nouvelle jeunesse, consiste à utiliser une eau savonneuse quelconque.
                            Le procédé est simple mais nécessite beaucoup d’huile de coude et un peu de temps.
                            La première chose à faire est de mouiller la jante. Vous devez avoir quelques outils indispensables pour le nettoyage, une brosse souple qui permettra d’enlever la poussière des freins et autres saletés (lubrifiants carbonisés, résidus provenant des pots d’échappement).
                            Cette brosse permet d’accéder aux recoins inaccessibles de la jante.
                            Il faut aussi une brosse à poils durs, elle permet d’insister sur les tâches tenaces.
                            Vous devez posséder un gant en microfibre et des lingettes microfibres.
                            Elles sont idéales pour le lustrage des jantes, pour un dépoussiérage ou bien pour les essuyer.
                            L’avantage est qu’elles peuvent s’utiliser à sec ou humide, sans provoquer aucunes rayures à vos jantes – entretien.
                        </li>
                        <br>
                    </ul>
                    <br>
                    <p>Pour en savoir plus sur les jantes, le lecteur pourra se reporter aux différents articles qui traitent de cette question, sur le présent site.</p>
                    <p>Droit d’auteur protégé par les articles L. 111-1 et L. 123-1, du code de la propriété intellectuelle.</p>
                </v-col>
            </v-row>
        </v-col>
        </v-main>


        <v-main v-if="VUE_APP_SITE_ID === '2'">
            <v-col class="py-0">
                <v-row class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.mdAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 font-weight-bold rotate">
                            Jantes - Entretien
                        </h1>

                    </v-col>
                    <v-col lg="5" >
                        <h1 v-if="$vuetify.breakpoint.smAndDown" class="text-md-h4 text-sm-h5 text-h5  font-weight-bold ">
                            Jantes - Entretien
                        </h1>
                        <p class="font-weight-bold headline">
                            Comment entretenir les jantes de sa voiture ?
                        </p>
                        <br>
                        <p>
                            Rayures, salissures, graisses… pourquoi et comment entretenir les jantes de sa voiture ?
                            Quels sont les produits à utiliser ? Comment intervenir en cas de micro rayures ?
                            En plus de répondre à ces questions, nous vous livrons quelques petits secrets de beauté pour retrouver des jantes étincelantes !

                        <p class="font-weight-bold headline">
                            Pourquoi entretenir vos jantes ?
                        </p>
                        <br>
                        <p>
                            Sur un véhicule, les roues et en particulier les jantes, de par leur positionnement, sont exposées à toutes sortes de projection :
                        </p>

                        <p>
                            - Boues, salissures, pollution,<br>
                            - Traces de rouille,<br>
                            - Graisses et poussières émanant des plaquettes de freins (phénomène plus marqué sur les jantes des 2 roues avant), etc.<br>
                        </p>
                        <p>
                            Un entretien régulier permet de conserver vos jantes en bon état.
                        </p>
                        <p class="font-weight-bold title">Nettoyage des jantes et produits spécifiques</p>
                        <p>
                            Il est fortement conseillé de privilégier les stations de lavage pour nettoyer vos jantes. Ces dernières sont équipées de grilles et de fosse de décantation conçues pour éviter toute rejet de produits toxiques dans la nature.
                            Autre conseil préliminaire : retirer à l’aide d’un diluant la couche de paraffine sur vos jantes neuves.
                            <br>
                            Evidemment, Le type de nettoyage diffère selon qu’il s’agit de jantes alu, jantes acier, jantes alliage, selon qu’elles sont peintes ou vernies.
                            Il existe sur le marché un grand nombre de produits spécifiques sous forme de gel, de spray ainsi que des rénovateurs de jantes. Nous attirons votre attention sur leur dangerosité en cas d’inhalation. Nous vous recommandons également de respecter scrupuleusement les notices d’utilisation et de bien tester, avant usage, sur un petit recoin non visible de la jante.
                            <br>
                            En règle générale, après humidification et pulvérisation du produit sur les jantes, il convient de laisser poser quelques minutes avant de nettoyer et de rincer.
                            Une nouvelle génération de produits biodégradables, plus respectueux de l’environnement, à base d’eau déminéralisée et de savon concentré, qui dispense de tout rinçage est apparue il y a quelques années.
                            <br>
                            En marge de ces produits spécifiques pour jantes et enjoliveurs, des produits traditionnels, ayant fait leurs preuves, peuvent être utilisés avec beaucoup de précautions, il va sans dire, comme :
                            <br>
                            - La soude caustique à diluer impérativement à 90 ° : l’usage de gants et de masque est impératif compte tenu de l’extrême toxicité du produit.
                            <br>
                            - Les cristaux de soude à faire fondre dans de l’eau.
                            <br>
                            Pour ces deux produits, outre les précautions au niveau des risques d’inhalation et de manipulation, un rinçage très abondant de la jante est requis avant et après application.
                            <br>
                            De la même manière, de nombreux propriétaires de véhicules ont recours à des produits ménagers décapants du type Jex Four®, une extrême vigilance est de mise par rapport au vernis qui peut ne pas résister.
                            <br>
                            Des nettoyants de métaux comme le Miror® ou la Pierre d’Argent, combinés à de l’huile de coude, présentent l’avantage de dégraisser, de polir et de faire briller la jante, les chromes et les éventuels enjoliveurs.
                            <br>
                            Et enfin, n’oublions pas, dans cette liste, les produits ménagers naturels comme le vinaigre ménager, le savon noir ou encore le bicarbonate de soude à diluer pour obtenir une pâte.
                        </p>


                    </v-col>
                    <v-col class="pa-0" lg="5" v-if="$vuetify.breakpoint.mdAndUp">
                        <v-img src="@/assets/AboutUsDJ.png"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phoneDJ.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33974191801">09 74 19 18 01</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="5" offset-lg="1"  class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>

                </v-row>
            </v-container>

            <v-col  class="bc_white tc_black" >
            <v-row class="font-weight-regular
                                    text-xl-h5
                                    text-lg-body-1
                                    text-md-caption
                                    text-sm-caption
                                    text-caption">
                <v-col offset-lg="1" lg="10">
                    <br>
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">Le polissage des jantes</p>
                    <br>
                    <p>
                        Une technique incontournable, sous réserve de bien la maîtriser, pour retrouver des jantes brillantes comme neuves. Rappelons que l’abrasion est une étape délicate à manier, avec précaution et parcimonie.
                        <br>
                        Les brosses métalliques, polish ou autres abrasifs (laines de verre) devront être fins et non agressifs tout comme le produit décapant utilisé
                    </p>
                    <p>
                        Les principales phases d’un polissage de jante :<br>
                        - Démontage de la jante,<br>
                        - Nettoyage, application du produit décapant,<br>
                        - Polissage jusqu’à retrait des couches de peinture et de vernis et apparition de l’aluminium,<br>
                        - Rinçage,<br>
                        - Ponçage jusqu’à obtention d’une surface sans aspérité,<br>
                        - Application d’un produit lustrant et brillant adapté à l’aluminium (Belgom Alu®) à l’aide d’une polisseuse (sous réserve de savoir l’utiliser !).<br>

                    </p>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">Techniques d’entretien et de nettoyage des jantes</p>
                    <p>
                       1) Que faire en cas de rayures ?
                    </p>
                    <p>
                        Tout dépend de l’importance des rayures que vous constatez sur vos jantes.
                       - Des rayures profondes et bien incrustées <br>
                        Ce type de rayure impose bien souvent le recours d’un professionnel qui saura mettre en œuvre des techniques spécifiques comme le surfaçage.
                        Autre alternative : combler les fissures avec de la pâte plastique (mastic), ponçage et peinture de la zone de la jante concernée par les rayures.
                        <br>
                        - Des micro rayures sur des jantes non vernies
                        Elles sont décelables quand vous passez votre ongle. Une double action de polissage et de lustrage pourra être pratiquée pour gommer des micro rayures ou des éraflures légères.
                    </p>
                    <p>
                        2) Comment entretenir des jantes anciennes ?
                    </p>
                    <p>
                        Il s’agit ici non pas de se limiter à des questions esthétiques mais bel et bien de protéger vos jantes à des fins sécuritaires surtout quand il est question de jantes très anciennes de plus de 30 ans ou de jantes fragiles comme les jantes en tôle.
                        <br>
                        Celles-ci devront faire l’objet de contrôles réguliers pour vérifier l’absence d’eau dans la valve du pneu ou de traces de rouille.<br>
                        Voici quelques conseils pratiques pour entretenir une jante ancienne et lui apporter une protection durable :<br>
                        - Polissage léger à l’aide de sable ou d’une brosse métallique jusqu’à disparition des traces d’oxydation,<br>
                        - Nettoyage,<br>
                        - Application de 2 couches d’un produit anti rouille avec double séchage (intermédiaire et final).<br>
                    </p>
                    <p>
                        3) Comment nettoyer et revigorer vos jantes ?
                    </p>
                    <p>
                        Recette traditionnelle de cure de jouvence pour vos jantes à base d’eau savonneuse :<br>
                        Vous prendrez soin de dépoussiérer préalablement la jante avant d’appliquer la solution savonneuse avec une brosse suffisamment souple pour atteindre recoins et autres parties inaccessibles.<br>
                        Vous n’hésiterez pas à recourir à une brosse de ménage plus dure pour insister et venir à bout des zones sales plus résistantes.<br>
                        L’utilisation de lingettes microfibres permet de lustrer, d’essuyer sans aucun risque de micro rayures sur vos jantes.
                    </p>
                    <p>
                        4) Comment retirer des traces de rouille ?
                    </p>
                    <p>
                        Appliquer de l’essence « F » sur la trace de rouille, laisser poser quelques minutes et rincer à l’eau.
                    </p>
                    <p>
                        5) Comment enlever un dépôt de goudron ?
                    </p>
                    <p>
                        Un peu de white spirit étalé avec parcimonie sur la zone incrustée de goudron suffira à retrouver une jante propre. Ne pas oublier d’éliminer le white spirit avec de l’eau après application.
                    </p>
                    <p>
                        6) Comment récupérer ou faire briller les chromes de la jante ?
                    </p>
                    <p>
                        Voici deux petits secrets de beauté non conventionnels mais très efficaces pour faire briller les chromes des jantes des roues. <br>
                        - Pour récupérer un chrome : Une recette à base de Coca-Cola® avec deux variantes
                        Vous appliquez du Coca-Cola® à l’aide d’un chiffon pour frotter les chromes ou vous remplacez ce dernier par une feuille de papier d’aluminium !!!!<br>
                        - Pour Faire briller : utilisez une éponge ou un tissu, versez-y quelques gouttes d’alcool à brûler. Dans un deuxième temps, versez de la farine dans une assiette. A l’aide d’un torchon, venez appliquer par tamponnements, sur les chromes de vos jantes : l’effet brillance est garanti !<br>
                    </p>
                </v-col>
            </v-row>
        </v-col>
        </v-main>

        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "../headers/HomepageHeaderEj";
import HomepageHeaderDj from "../headers/HomepageHeaderDj";
import HomepageFooterEj from "../footer/HomepageFooterEj";
import HomepageFooterDj from "../footer/HomepageFooterDj";

    export default {
        name: "JantesEntretien",
        components:{
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,

        },
        data: () => ({
            VUE_APP_SITE_ID:null
        }),
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        },
        metaInfo() {
            return {
                title: `Entretien - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + `  - Jantes, Pneus & Accessoires`,
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `Entretien - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {name: 'twitter:description', content: `Découvrez les conseils d'entretien pour vos jantes ` + (this.VUE_APP_SITE_ID === '1' ? `d'Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `de Distri Jantes l'expert en ligne des jantes`)},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `Entretien -` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {property: 'og:site_name', content: this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`},
                    {property: 'og:type', content: 'Website'},
                    {property: 'og:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},
                    {property: 'og:description', content: `Découvrez les conseils d'entretien pour vos jantes ` + (this.VUE_APP_SITE_ID === '1' ? `Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `Distri Jantes l'expert en ligne des jantes`)},
                    {name: 'robots', content: 'index,follow'}
                ]
            }
    },
    }
</script>

<style scoped>
    .border_left{
        border-left: 2px solid #1DE4AF
    }
    .rotate {
        transform: rotate(-90deg);
        margin-left: -8%;
        margin-top: 12%;
        position: absolute;
    }
</style>
