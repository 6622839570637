<template>

    <v-app  fluid  >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>

        <v-main v-if="VUE_APP_SITE_ID === '1'">
            <v-col class="py-0">
                <v-row class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 tc_blue font-weight-bold rotate">
                            Jantes - Conseils
                        </h1>

                    </v-col>
                    <v-col lg="5" >
                        <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5 tc_blue font-weight-bold ">
                            Jantes - Conseils
                        </h1>
                        <p class="font-weight-bold
                               text-xl-h5
                               text-lg-h6
                               text-md-subtitle-1
                               text-sm-subtitle-1
                               text-subtitle-2 ">JANTES – CONSEILS – CHOIX</p>
                        <br>

                        <p>
                            Chaque marque et chaque modèle de voitures requièrent un modèle de jantes spécifiques.
                            Vous remarquerez qu’il existe une multitude de choix, de marques, de tailles, de prix et de design différents.
                            Les quatre jantes de votre voiture doivent être identiques.
                            Vous pouvez vous procurer les jantes – conseils, par l’intermédiaire de magasins spécialisés mais aussi sur notre catalogue de vente en ligne.
                        </p>
                        <p class=" mb-3 pl-4 border_left tc_turquoise font-weight-bold
                                            text-xl-h5
                                        text-lg-body-1
                                        text-md-body-1
                                        text-sm-body-1
                                        text-body-1">
                            Sur vos pneus vous avez une référence inscrite.
                            <br>
                            Le dernier chiffre après la lettre signifie la dimension de la jante sur laquelle le pneu devra être monté.
                        </p>
                        <p>
                            L’unité de cette indication est le pouce (1 pouce = 2.54 cm).
                            La largeur des jantes varie de 5 pouces à 23 pouces.
                            A chaque dimension de jantes correspond une largeur minimum et maximum de taille de pneu à installer.
                            La plupart des jantes sont garanties pendant 1 an.
                        </p>
                        <p>
                            Les jantes ont plusieurs caractéristiques auxquelles vous devez prêter attention.
                            Outre le type, la taille, la largeur de la jante, il faut vérifier l’entraxe et le déport de la jante.
                            Chaque jante à un nombre de fixation avec le moyeu qui varie de 3 à 6 écrous.
                            Pour les roues à rayons vous pouvez avoir une seule fixation centrale.
                            Le prix des jantes est très variable autant que la gamme présentée.
                        </p>

                    </v-col>
                    <v-col class="bc_blue" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-img src="@/assets/AboutUs.png"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phone.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold tc_blue align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="3" xl="3" class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>
                    <v-col v-if="$vuetify.breakpoint.lgAndUp" cols="2" offset-lg="1" xl="3" lg="3">
                        <v-img style="position: absolute; width: 20%;" src="@/assets/illustration-man.png"/>
                    </v-col>
                </v-row>
            </v-container>

            <v-col  v-if="$vuetify.breakpoint.lgAndUp" class="bc_white tc_black" >
                <v-row class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col offset-lg="1" lg="11">
                        <br>
                        <br>
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">JANTES – CONSEILS – INSTALLATION</p>
                        <br>
                        <v-row>
                            <v-col lg="5">
                                <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">LE MONTAGE</p>
                                <p>Vous pourrez faire monter vos jantes soit par un professionnel soit par vous-même.
                                    Dans ce cas vous ferez des économies mais vous devez respecter certaines règles de montage.
                                    La roue est le seul élément qui relie votre voiture à la route.
                                    L’ensemble jantes-pneus est donc un élément essentiel qui garantira votre sécurité.
                                    Les roues transmettent les accélérations, les freinages et les changements de trajectoire.
                                    De plus la surface de contact avec le sol est réduite avec la vitesse du véhicule et les mauvaises conditions climatiques.
                                </p>
                                <br>
                                <p>
                                    Vous devez donc vos reporter aux normes préconisées par les constructeurs de jantes et celle en vigueur dans le pays ou vous roulez.
                                    Vous devez utiliser les éléments de fixation conçus par le constructeur.
                                    Vous devez avoir quatre roues similaires.
                                    Sachant que pour un essieu, il faut obligatoirement deux pneus de même structure, même marque, même dimension et même catégorie.
                                    Le code de la route défini une réglementation très exhaustive sur les dispositions techniques des véhicules.
                                </p>
                                <br>
                                <p>
                                    Vous devez vérifier régulièrement les pneumatiques.
                                    Vos jantes – conseils, doivent être serrées à l’aide d’une clé dynamométrique, cela vous garantira un couple de serrage recommandé par le constructeur du véhicule.
                                    Quand vous changez les pneus de votre voiture, vous devez protéger les jantes afin de ne pas les abîmer.
                                    A chaque fois que vous changer un pneu ou vos jantes, vous devez vérifier l’équilibrage, la pression des pneumatiques et contrôler l’usure des valves.
                                    Ces opérations garantissent un confort de conduite mais aussi la sécurité et la tenue de route.
                                </p>
                            </v-col>
                            <v-col offset-lg="1" lg="4">
                                <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">L’ÉQUILIBRAGE</p>
                                <p>
                                    L’équilibrage des jantes sert à empêcher les vibrations.
                                    Une roue bien équilibrée permet de répartir la masse uniformément autour de l’essieu.
                                    Le centre de gravité se situe au centre de cet axe.
                                    L’usure de vos pneumatiques et la conduite brusque entraînent une modification de l’équilibre des roues.
                                </p>
                                <br>
                                <p>
                                    A chaque changement de pneumatiques ou de jantes vous devez procéder à un équilibrage des roues.
                                    L’équilibrage se fait en deux opérations.
                                    Tout d’abord l’équilibrage statique règle les oscillations verticales en plaçant des masselottes à l’intérieur qui corrigent le balourd dû à une mauvaise répartition des masses de la jante.
                                    Puis l’équilibrage dynamique rectifie les oscillations horizontales en plaçant des masselottes à l’extérieur de la jante.
                                </p>
                                <br>
                                <p>
                                    Si vous n’équilibrez pas vos jantes – conseils, vous aurez une usure anormale des organes de suspension, de direction et des pneumatiques qui mettront votre sécurité en danger.
                                    Si vous ressentez des vibrations dans le volant au dessus d’une vitesse de 90 Km/h, c’est le train avant qui est déséquilibré.
                                    Si les vibrations apparaissent dans le siège conducteur, ce sont les roues arrière qui sont déséquilibrées.
                                </p>
                                <br>
                                <p>Le réglage se fait sur une machine appelée équilibreuse.
                                    Elle va déterminer après le test, le poids et le lieu des masselottes de plomb ou d’étain qui seront collées ou clipsées sur la jante.
                                    L’équilibreuse se règle différemment selon le type de jantes (aluminium, tôle, composite).
                                </p>
                            </v-col>
                        </v-row>
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">CENTRAGE ET GÉOMÉTRIE</p>
                        <v-row>
                            <v-col lg="5">
                                <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">LE CENTRAGE</p>
                                <p>
                                    Il se peut que vous remarquiez du jeu, sur les jantes de votre voiture.
                                    Cela peut provenir de la bague de centrage.
                                    C’est une petite pièce qui est utilisée quand les jantes – conseils ne sont pas monobloc.
                                    Les constructeurs mettent une bague entre le moyeu et la jante, ce qui permet d’éviter qu’il y ait du jeu.
                                    Cette bague peut-être en plastique haute résistance ou en aluminium.
                                </p>
                                <br>
                                <p>
                                    Les bagues sont spécifiques à chaque marque de jante.
                                    Elles sont indispensables, et doivent être remplacée si elles sont abîmées.
                                    Une bague détériorée peut occasionner de gros dégâts si vous ne la changez pas.
                                    Les vis de la jante peuvent se rompre et vous risquez de perdre une roue ou bien de ressentir de fortes vibrations dans votre voiture.
                                </p>
                                <br>
                                <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">LES PNEUS</p>
                                <p>
                                    Les pneus doivent développer une résistance accru à la déformation ainsi qu’une excellente élasticité pour amortir les vibrations de la route et une bonne dissipation de la chaleur du au frottement et au freinage.
                                    Ils doivent pouvoir se démonter facilement de la jante en cas d’intervention.
                                    Les caractéristiques des pneus et des jantes sont notées soit dans le manuel du véhicule, sur le clapet du réservoir de carburant ou bien encore dans la boîte à gants.
                                </p>
                                <br>
                                <p>
                                    En ce qui concerne les pneus que vous souhaitez monter sur vos jantes, il faut contrôler les indices préconisés par les constructeurs.
                                    L’indice de charge et l’indice de vitesse sont indiqués sur le pneu.
                                    L’indice de charge correspond à la charge que peut encaisser le pneu.
                                    Si vous monter un pneu avec un indice de charge inférieure, c’est la jante qui reçoit une partie des vibrations et des irrégularités de la route.
                                    La jante se détériore et va se fissurer. De même, vous ne devez pas monter un pneu avec un indice de vitesse inférieur à celui d’origine.
                                    Dans l’absolu c’est la vitesse maximum à laquelle vous pouvez rouler.
                                    Le non respect de cet indice peu entrainer un échauffement du pneu puis à l’éclatement de celui-ci.
                                </p>
                                <br>
                                <p>
                                    Vous avez des pneus directionnels et des pneus asymétriques.
                                    Pour un pneu asymétrique, l’indice « outside » précise de quel côté le pneu doit être monté sur la jante ainsi que son sens.
                                    Et inversement l’indice « inside » est un symbole indiquant le côté du flanc du pneu qui doit être à l’intérieur.
                                    Il précise aussi le sens du montage avec la jante. Sur un pneu directionnel on prend en compte l’indice de rotation.
                                    Cet indice est noté sur le flanc du pneu et indique par une flèche la direction du roulement.
                                </p>
                                <br>
                                <p>
                                    Il existe cinq profils type de pneumatique.
                                    L’usage urbain, autoroutier, sportif, utilitaire et le 4×4, ils possèdent une technologie et des caractéristiques spécifiques.
                                    Chaque marque a développé ses jantes – conseils et ses pneus pour une utilisation précise.
                                </p>
                            </v-col>
                            <v-col offset-lg="1" lg="4">
                                <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">LA GÉOMÉTRIE</p>
                                <p>
                                    Les experts appellent géométrie ou parallélisme, le fait de régler l’alignement des 4 roues de votre véhicule.
                                    C’est l’ensemble des points de contact des jantes et pneus avec le sol.
                                    Un parallélisme défectueux provoque une mauvaise tenue de route, une surconsommation de carburant et une usure irrégulière des pneus.
                                    Les données des constructeurs conseillent de vérifier la géométrie tous les 25 000 Km.
                                </p>
                                <br>
                                <p>
                                    Plus précisément, le parallélisme doit limiter la liberté de la suspension.
                                    L’angle d’ouverture pour deux jantes – conseils sur un même essieu se situe généralement entre 0 et 3 mm.
                                    Une ouverture trop importante occasionne une usure du bord intérieur des pneumatiques.
                                    Tandis qu’une pince déclenche une usure du bord extérieur des pneus.
                                </p>
                                <br>
                                <p>
                                    Techniquement la géométrie prend en compte trois critères pour corriger la trajectoire de conduite d’un véhicule.
                                    Le carrossage qui est l’angle formé par l’ensemble jante et pneu posé sur le sol et l’axe longitudinal du véhicule.
                                    Puis la chasse qui génère un auto-alignement de l’ensemble jante et pneu dans l’axe de déplacement de l’automobile.
                                    Et enfin le parallélisme qui est le seul réglage pouvant être fait sur les véhicules de série.
                                    La plupart des caractéristiques de la géométrie sont réglables uniquement à la fabrication des véhicules.
                                </p>
                                <br>
                                <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">CHANGEMENT D’UNE ROUE</p>
                                <p>
                                    Tous les conducteurs de véhicule doivent appréhender la crevaison.
                                    Il faut savoir réagir, sans paniquer et être en mesure de réparer seul sur le bord de la route.
                                    La procédure en elle-même n’est pas compliquée, et on peut tous changer une roue.
                                    Il faut néanmoins être très vigilent sur le serrage des jantes – conseils car il peut occasionner de sérieux ennuis.
                                    Vous devez garer votre véhicule sur un sol stable et plat avant de commencer.
                                </p>
                                <br>
                                <p>
                                    Si votre roue à un enjoliveur, il faut l’enlever avant de commencer.
                                    Puis vous devez vous munir de la clé en croix (placée dans le coffre avec votre roue de secours) et desserrez les écrous de la jante dans le sens inverse des aiguilles d’une montre.
                                    Ensuite placez le cric (encoches prévues devant ou derrière chaque roue), et levez la voiture à quelques centimètres du sol.
                                    Puis finir de dévisser les boulons, et enlevez la roue crevée.
                                    Remplacez-la par la roue de secours en prenant soin de bien la placer en face des trous de serrage.
                                    Enfin vous pouvez revissez les boulons de la jante dans le sens des aiguilles d’une montre et les serrez aussi fort que vous le pouvez.
                                    Il ne vous reste plus qu’à ranger la roue usagée avec les outils dans le coffre.
                                    Après une crevaison, la roue usagée doit être réparée ou changée par un professionnel.
                                </p>
                            </v-col>
                        </v-row>
                        <br>
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">JANTES – CONSEILS – DÉCORATION</p>
                        <v-row>
                            <v-col lg="5">
                                <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">LE TUNING</p>
                                <p>
                                    Le tuning est la personnalisation d’un véhicule fabriqué en série.
                                    Cela se caractérise par l’installation d’accessoires comme des jantes et par des paramétrages mécanique ou électronique.
                                    Le but du Tuning est de rendre esthétique et ou d’améliorer les performances d’un véhicule.
                                    Mais la jante idéale reste celle qui est adaptée à la puissance du moteur du véhicule.
                                    Les caractéristiques des véhicules ayant subi des transformations sont soumises à des règles et à des lois propres à chaque pays.
                                </p>
                                <br>
                                <p>
                                    Il existe un large éventail de packs de jantes de voiture.
                                    Vous trouverez des jantes qui donneront aussi bien un aspect classique, sportif ou bien élégant sans négliger le maintien du pneu sur la jante.
                                    Vous pouvez modifier le diamètre et la largeur de vos jantes – conseils, mais vous devez respecter les tolérances autorisées par les constructeurs.
                                    La plupart du temps un changement de jante implique un changement du système de freinage et de la suspension.
                                    En cas d’accident, l’assureur pourra se retourner contre vous en évoquant le non respect des normes constructeur.
                                </p>
                            </v-col>
                            <v-col offset-lg="1" lg="4">
                                <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">LES ENJOLIVEURS</p>
                                <p>
                                    Un enjoliveur de jante est un accessoire qui vient se poser sur la roue d’un véhicule et qui dissimule les jantes en tôle.
                                    Il sert à masquer l’apparence inesthétique d’une roue. Il vient se fixer sur la jante par des clips ou par des petites vis très discrètes.
                                    La finition des roues de la voiture est parfaite, propre et efficace.
                                    Il existe toute une gamme d’enjoliveurs qui sont spécialement conçu pour chaque marque de véhicules et pour toutes les tailles de jantes.
                                    Ils sont toujours vendus par jeu de quatre.
                                </p>
                                <br>
                                <p>
                                    Les enjoliveurs sont la plupart du temps en matière plastique rigide.
                                    Ils peuvent être peints, verni ou avoir un aspect poli ou chromé.
                                    Vous pouvez aisément changer les enjoliveurs pour donner un aspect plus moderne à vos jantes.
                                    Si vous craignez les vols vous pouvez les visser sur vos jantes – conseils.
                                    Vous pouvez aussi ajouter des enjoliveurs sur des jantes aluminium qui sont trop dégradées, corrodées ou abîmées.
                                    Les enjoliveurs sont conçus pour résister aux intempéries, aux vibrations de la route et aux lavages haute pression.
                                </p>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <p>Pour en savoir plus sur les jantes, le lecteur pourra se reporter aux différents articles qui traitent de cette question, sur le présent site.</p>
                                <p>Droit d’auteur protégé par les articles L. 111-1 et L. 123-1, du code de la propriété intellectuelle.</p>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>

            <v-col  v-if="$vuetify.breakpoint.mdAndDown" class="bc_white tc_black" >
            <v-row class="font-weight-regular
                                    text-xl-h5
                                    text-lg-body-1
                                    text-md-caption
                                    text-sm-caption
                                    text-caption">
                <v-col offset-lg="1" lg="11">
                    <br>
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">JANTES – CONSEILS – INSTALLATION</p>
                    <br>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">LE MONTAGE</p>
                    <p>Vous pourrez faire monter vos jantes soit par un professionnel soit par vous-même.
                        Dans ce cas vous ferez des économies mais vous devez respecter certaines règles de montage.
                        La roue est le seul élément qui relie votre voiture à la route.
                        L’ensemble jantes-pneus est donc un élément essentiel qui garantira votre sécurité.
                        Les roues transmettent les accélérations, les freinages et les changements de trajectoire.
                        De plus la surface de contact avec le sol est réduite avec la vitesse du véhicule et les mauvaises conditions climatiques.
                    </p>
                    <br>
                    <p>
                        Vous devez donc vos reporter aux normes préconisées par les constructeurs de jantes et celle en vigueur dans le pays ou vous roulez.
                        Vous devez utiliser les éléments de fixation conçus par le constructeur.
                        Vous devez avoir quatre roues similaires.
                        Sachant que pour un essieu, il faut obligatoirement deux pneus de même structure, même marque, même dimension et même catégorie.
                        Le code de la route défini une réglementation très exhaustive sur les dispositions techniques des véhicules.
                    </p>
                    <br>
                    <p>
                        Vous devez vérifier régulièrement les pneumatiques.
                        Vos jantes – conseils, doivent être serrées à l’aide d’une clé dynamométrique, cela vous garantira un couple de serrage recommandé par le constructeur du véhicule.
                        Quand vous changez les pneus de votre voiture, vous devez protéger les jantes afin de ne pas les abîmer.
                        A chaque fois que vous changer un pneu ou vos jantes, vous devez vérifier l’équilibrage, la pression des pneumatiques et contrôler l’usure des valves.
                        Ces opérations garantissent un confort de conduite mais aussi la sécurité et la tenue de route.
                    </p>
                    <br>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">L’ÉQUILIBRAGE</p>
                    <p>
                        L’équilibrage des jantes sert à empêcher les vibrations.
                        Une roue bien équilibrée permet de répartir la masse uniformément autour de l’essieu.
                        Le centre de gravité se situe au centre de cet axe.
                        L’usure de vos pneumatiques et la conduite brusque entraînent une modification de l’équilibre des roues.
                    </p>
                    <br>
                    <p>
                        A chaque changement de pneumatiques ou de jantes vous devez procéder à un équilibrage des roues.
                        L’équilibrage se fait en deux opérations.
                        Tout d’abord l’équilibrage statique règle les oscillations verticales en plaçant des masselottes à l’intérieur qui corrigent le balourd dû à une mauvaise répartition des masses de la jante.
                        Puis l’équilibrage dynamique rectifie les oscillations horizontales en plaçant des masselottes à l’extérieur de la jante.
                    </p>
                    <br>
                    <p>
                        Si vous n’équilibrez pas vos jantes – conseils, vous aurez une usure anormale des organes de suspension, de direction et des pneumatiques qui mettront votre sécurité en danger.
                        Si vous ressentez des vibrations dans le volant au dessus d’une vitesse de 90 Km/h, c’est le train avant qui est déséquilibré.
                        Si les vibrations apparaissent dans le siège conducteur, ce sont les roues arrière qui sont déséquilibrées.
                    </p>
                    <br>
                    <p>Le réglage se fait sur une machine appelée équilibreuse.
                        Elle va déterminer après le test, le poids et le lieu des masselottes de plomb ou d’étain qui seront collées ou clipsées sur la jante.
                        L’équilibreuse se règle différemment selon le type de jantes (aluminium, tôle, composite).
                    </p>
                    <br>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">CENTRAGE ET GÉOMÉTRIE</p>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">LE CENTRAGE</p>
                    <p>
                        Il se peut que vous remarquiez du jeu, sur les jantes de votre voiture.
                        Cela peut provenir de la bague de centrage.
                        C’est une petite pièce qui est utilisée quand les jantes – conseils ne sont pas monobloc.
                        Les constructeurs mettent une bague entre le moyeu et la jante, ce qui permet d’éviter qu’il y ait du jeu.
                        Cette bague peut-être en plastique haute résistance ou en aluminium.
                    </p>
                    <br>
                    <p>
                        Les bagues sont spécifiques à chaque marque de jante.
                        Elles sont indispensables, et doivent être remplacée si elles sont abîmées.
                        Une bague détériorée peut occasionner de gros dégâts si vous ne la changez pas.
                        Les vis de la jante peuvent se rompre et vous risquez de perdre une roue ou bien de ressentir de fortes vibrations dans votre voiture.
                    </p>
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">LES PNEUS</p>
                    <p>
                        Les pneus doivent développer une résistance accru à la déformation ainsi qu’une excellente élasticité pour amortir les vibrations de la route et une bonne dissipation de la chaleur du au frottement et au freinage.
                        Ils doivent pouvoir se démonter facilement de la jante en cas d’intervention.
                        Les caractéristiques des pneus et des jantes sont notées soit dans le manuel du véhicule, sur le clapet du réservoir de carburant ou bien encore dans la boîte à gants.
                    </p>
                    <br>
                    <p>
                        En ce qui concerne les pneus que vous souhaitez monter sur vos jantes, il faut contrôler les indices préconisés par les constructeurs.
                        L’indice de charge et l’indice de vitesse sont indiqués sur le pneu.
                        L’indice de charge correspond à la charge que peut encaisser le pneu.
                        Si vous monter un pneu avec un indice de charge inférieure, c’est la jante qui reçoit une partie des vibrations et des irrégularités de la route.
                        La jante se détériore et va se fissurer. De même, vous ne devez pas monter un pneu avec un indice de vitesse inférieur à celui d’origine.
                        Dans l’absolu c’est la vitesse maximum à laquelle vous pouvez rouler.
                        Le non respect de cet indice peu entrainer un échauffement du pneu puis à l’éclatement de celui-ci.
                    </p>
                    <br>
                    <p>
                        Vous avez des pneus directionnels et des pneus asymétriques.
                        Pour un pneu asymétrique, l’indice « outside » précise de quel côté le pneu doit être monté sur la jante ainsi que son sens.
                        Et inversement l’indice « inside » est un symbole indiquant le côté du flanc du pneu qui doit être à l’intérieur.
                        Il précise aussi le sens du montage avec la jante. Sur un pneu directionnel on prend en compte l’indice de rotation.
                        Cet indice est noté sur le flanc du pneu et indique par une flèche la direction du roulement.
                    </p>
                    <br>
                    <p>
                        Il existe cinq profils type de pneumatique.
                        L’usage urbain, autoroutier, sportif, utilitaire et le 4×4, ils possèdent une technologie et des caractéristiques spécifiques.
                        Chaque marque a développé ses jantes – conseils et ses pneus pour une utilisation précise.
                    </p>
                    <br>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">LA GÉOMÉTRIE</p>
                    <p>
                        Les experts appellent géométrie ou parallélisme, le fait de régler l’alignement des 4 roues de votre véhicule.
                        C’est l’ensemble des points de contact des jantes et pneus avec le sol.
                        Un parallélisme défectueux provoque une mauvaise tenue de route, une surconsommation de carburant et une usure irrégulière des pneus.
                        Les données des constructeurs conseillent de vérifier la géométrie tous les 25 000 Km.
                    </p>
                    <br>
                    <p>
                        Plus précisément, le parallélisme doit limiter la liberté de la suspension.
                        L’angle d’ouverture pour deux jantes – conseils sur un même essieu se situe généralement entre 0 et 3 mm.
                        Une ouverture trop importante occasionne une usure du bord intérieur des pneumatiques.
                        Tandis qu’une pince déclenche une usure du bord extérieur des pneus.
                    </p>
                    <br>
                    <p>
                        Techniquement la géométrie prend en compte trois critères pour corriger la trajectoire de conduite d’un véhicule.
                        Le carrossage qui est l’angle formé par l’ensemble jante et pneu posé sur le sol et l’axe longitudinal du véhicule.
                        Puis la chasse qui génère un auto-alignement de l’ensemble jante et pneu dans l’axe de déplacement de l’automobile.
                        Et enfin le parallélisme qui est le seul réglage pouvant être fait sur les véhicules de série.
                        La plupart des caractéristiques de la géométrie sont réglables uniquement à la fabrication des véhicules.
                    </p>
                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">CHANGEMENT D’UNE ROUE</p>
                    <p>
                        Tous les conducteurs de véhicule doivent appréhender la crevaison.
                        Il faut savoir réagir, sans paniquer et être en mesure de réparer seul sur le bord de la route.
                        La procédure en elle-même n’est pas compliquée, et on peut tous changer une roue.
                        Il faut néanmoins être très vigilent sur le serrage des jantes – conseils car il peut occasionner de sérieux ennuis.
                        Vous devez garer votre véhicule sur un sol stable et plat avant de commencer.
                    </p>
                    <br>
                    <p>
                        Si votre roue à un enjoliveur, il faut l’enlever avant de commencer.
                        Puis vous devez vous munir de la clé en croix (placée dans le coffre avec votre roue de secours) et desserrez les écrous de la jante dans le sens inverse des aiguilles d’une montre.
                        Ensuite placez le cric (encoches prévues devant ou derrière chaque roue), et levez la voiture à quelques centimètres du sol.
                        Puis finir de dévisser les boulons, et enlevez la roue crevée.
                        Remplacez-la par la roue de secours en prenant soin de bien la placer en face des trous de serrage.
                        Enfin vous pouvez revissez les boulons de la jante dans le sens des aiguilles d’une montre et les serrez aussi fort que vous le pouvez.
                        Il ne vous reste plus qu’à ranger la roue usagée avec les outils dans le coffre.
                        Après une crevaison, la roue usagée doit être réparée ou changée par un professionnel.
                    </p>

                    <br>
                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">JANTES – CONSEILS – DÉCORATION</p>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">LE TUNING</p>
                    <p>
                        Le tuning est la personnalisation d’un véhicule fabriqué en série.
                        Cela se caractérise par l’installation d’accessoires comme des jantes et par des paramétrages mécanique ou électronique.
                        Le but du Tuning est de rendre esthétique et ou d’améliorer les performances d’un véhicule.
                        Mais la jante idéale reste celle qui est adaptée à la puissance du moteur du véhicule.
                        Les caractéristiques des véhicules ayant subi des transformations sont soumises à des règles et à des lois propres à chaque pays.
                    </p>
                    <br>
                    <p>
                        Il existe un large éventail de packs de jantes de voiture.
                        Vous trouverez des jantes qui donneront aussi bien un aspect classique, sportif ou bien élégant sans négliger le maintien du pneu sur la jante.
                        Vous pouvez modifier le diamètre et la largeur de vos jantes – conseils, mais vous devez respecter les tolérances autorisées par les constructeurs.
                        La plupart du temps un changement de jante implique un changement du système de freinage et de la suspension.
                        En cas d’accident, l’assureur pourra se retourner contre vous en évoquant le non respect des normes constructeur.
                    </p>
                    <br>

                    <p class="font-weight-bold
                       text-xl-h5
                       text-lg-h6
                       text-md-subtitle-1
                       text-sm-subtitle-1
                       text-subtitle-2">LES ENJOLIVEURS</p>
                    <p>
                        Un enjoliveur de jante est un accessoire qui vient se poser sur la roue d’un véhicule et qui dissimule les jantes en tôle.
                        Il sert à masquer l’apparence inesthétique d’une roue. Il vient se fixer sur la jante par des clips ou par des petites vis très discrètes.
                        La finition des roues de la voiture est parfaite, propre et efficace.
                        Il existe toute une gamme d’enjoliveurs qui sont spécialement conçu pour chaque marque de véhicules et pour toutes les tailles de jantes.
                        Ils sont toujours vendus par jeu de quatre.
                    </p>
                    <br>
                    <p>
                        Les enjoliveurs sont la plupart du temps en matière plastique rigide.
                        Ils peuvent être peints, verni ou avoir un aspect poli ou chromé.
                        Vous pouvez aisément changer les enjoliveurs pour donner un aspect plus moderne à vos jantes.
                        Si vous craignez les vols vous pouvez les visser sur vos jantes – conseils.
                        Vous pouvez aussi ajouter des enjoliveurs sur des jantes aluminium qui sont trop dégradées, corrodées ou abîmées.
                        Les enjoliveurs sont conçus pour résister aux intempéries, aux vibrations de la route et aux lavages haute pression.
                    </p>
                    <br>


                    <p>Pour en savoir plus sur les jantes, le lecteur pourra se reporter aux différents articles qui traitent de cette question, sur le présent site.</p>
                    <p>Droit d’auteur protégé par les articles L. 111-1 et L. 123-1, du code de la propriété intellectuelle.</p>
                </v-col>
            </v-row>
        </v-col>
        </v-main>


        <v-main v-if="VUE_APP_SITE_ID === '2'">
            <v-col class="py-0" >
                <v-row>
                    <v-col md="2" lg="2" v-if="$vuetify.breakpoint.lgAndUp" class="mt-10">
                        <h1 class="text-xl-h2 text-lg-h3 font-weight-bold rotate" style="margin-left: 0!important">
                            Livraison
                        </h1>
                    </v-col>
                    <v-col lg="5" >
                        <h1 v-if="$vuetify.breakpoint.mdAndDown" class="text-md-h4 text-sm-h5 text-h5  font-weight-bold ">
                            Livraison
                        </h1>
                        <v-content class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                            <p>
                                Choix et installation des jantes, tuning, changement de pneus, enjoliveurs…voici quelques conseils utiles relatifs aux jantes et à la roue dans son ensemble.
                            </p>
                            <p>
                                Mais revenons d’abord sur des notions techniques indispensables, inhérentes à l’utilisation d’une jante.
                            </p>
                            <p class="font-weight-bold
                                        text-xl-h5
                                        text-lg-h6
                                        text-md-subtitle-1
                                        text-sm-subtitle-1
                                        text-subtitle-2">Notions techniques pour le réglage de la roue
                            </p>
                            <p>
                                1) Equilibrage des roues
                            </p>
                            <p>
                                L’équilibrage des roues (ensemble pneus-jantes) consiste à vérifier la répartition uniforme de la masse sur l’essieu, et plus spécialement par rapport au centre de gravité, point central de ce même essieu.
                            </p>
                            <p>
                                Un mauvais équilibrage des jantes nuit à la direction, à la suspension mais également précipite l’usure des pneus.
                            </p>
                            <p>
                                La nature des vibrations indique l’origine du déséquilibre. Si vous ressentez des vibrations dans le volant au-delà de 90 km/heure, il est temps de contrôler les deux roues avant. Des sensations de tremblement dans le siège conducteur sont annonciateurs d’un déséquilibre des roues arrière. En d’autres termes, régler un équilibrage revient à limiter les vibrations.
                            </p>
                            <p>
                                Un équilibrage est préconisé lors de tout changement de jantes et/ou de pneus.
                            </p>
                            <p>
                                On distingue deux niveaux d’équilibrage complémentaires :
                            </p>
                            <p>
                                - Equilibrage statique : ajouter des masses pour rééquilibrer le centre de gravité de la roue et le positionner sur le centre du moyeu de la jante.
                                Le déséquilibrage statique est à l’origine des vibrations dites de balourd.
                            </p>
                            <p>
                                - Equilibrage dynamique : répartir les masses sur un plan horizontal.
                            </p>
                            <p>
                                Le rééquilibrage se règle non pas manuellement mais au moyen d’une équilibreuse dont le réglage varie selon les modèles de jantes (alu, alliage...). Les différents tests réalisés permettront de définir l’endroit et la valeur des masses à ajouter sur la jante, sous forme de masselottes de plomb ou d’étain.
                            </p>
                            <p>
                                2) Centrage
                            </p>
                            <p>
                                La bague de centrage est une pièce d’origine, en plastique ou aluminium, positionnée entre le moyeu et le trou central de la jante (non monobloc) réduisant ainsi le diamètre de ce dernier et empêchant un éventuel jeu au niveau de la jante.
                            </p>
                            <p>
                                Une bague de centrage détériorée peut induire de lourdes conséquences allant de la simple vibration jusqu’au risque de perte de la roue.
                                A chaque marque de jante, sa bague !
                            </p>
                            <p>
                                3) Réglage des géométries de suspension
                            </p>
                            <p>
                                Le réglage des géométries de suspension, à contrôler tous les 25 000KM (préconisations constructeur) fait intervenir trois critères :
                            </p>
                            <p>

                                - Angle de carrossage : angle de la roue par rapport au sol (plan et vue de face).
                                <br>
                                - Angle de chasse : angle entre la roue (jantes-pneus) et l’axe de déplacement du véhicule.
                                <br>
                                - Parallélisme :
                                <br>
                                On parle de parallélisme ou de réglage de géométrie de suspension. Il s’agit ici d’ajuster l’alignement des 4 roues, autrement dit de régler l’angle roues/sol et l’angle entre les roues.
                                L’objectif à atteindre : des roues (ensemble jante-pneus) parallèles entre elles et perpendiculaires à la route.
                            </p>
                            <p>
                                Une défaillance du parallélisme est perceptible dés lors que votre véhicule se déporte naturellement d’un côté ou de l’autre, que les pneus émettent des crissements…<br>
                                Ses conséquences ne sont pas négligeables : usure prématurée des pneumatiques, sensation de tenue de route non maîtrisée, consommation excessive de carburant…
                            </p>
                            <p>
                                En cas de mauvais alignement, on parle d’ouverture des roues « en canard » (parallélisme négatif avec usure du bord intérieur du pneu) ou de pincement des roues, sous-entendu vers l’intérieur. Dans ce cas, le parallélisme est positif avec, comme conséquence, une sollicitation excessive du bord extérieur du pneumatique.
                            </p>
                        </v-content>
                    </v-col>
                    <v-col class="pa-0" lg="5" v-if="$vuetify.breakpoint.lgAndUp">
                        <v-img src="@/assets/AboutUsDJ.png"/>
                    </v-col>
                </v-row>
            </v-col>

            <v-container fluid class="bc_lightGrey">
                <v-row style="padding-top: 1%;padding-bottom: 1%">

                    <v-col v-if="$vuetify.breakpoint.smAndUp" cols="1" lg="1" xl="1" offset-lg="1" offset-sm="1" class="align-self-center ">
                        <v-img src="@/assets/phoneDJ.svg" :style="[$vuetify.breakpoint.xl ? {'max-width' : '40%'} :
                                         $vuetify.breakpoint.lg ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.md ? {'max-width' : '50%'} :
                                         $vuetify.breakpoint.sm ? {'max-width' : '55%'} :
                                         $vuetify.breakpoint.xs ? {'max-width' : '60%'} : '']"/>
                    </v-col>
                    <v-col cols="5" sm="3" md="3" lg="3" xl="3" class="font-weight-bold align-self-center
                                                text-xl-h3
                                                text-lg-h5
                                                text-md-h5
                                                text-sm-h5
                                                text-subtitle-1">
                        <a class="no_deco" href="tel:+33974191801">09 74 19 18 01</a>
                    </v-col>
                    <v-col cols="7" sm="6" md="4" lg="5" offset-lg="1"  class="px-1 font-weight-bold align-self-center">
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Commandez par téléphone
                            </h2>
                        </v-col>
                        <v-col class="font-weight-bold pa-0
                                    text-xl-h5
                                    text-lg-subtitle-1
                                    text-md-subtitle-2
                                    text-sm-subtitle-2
                                    text-caption">
                            <h2>
                                Nous vous accompagnons !
                            </h2>
                        </v-col>
                    </v-col>

                </v-row>
            </v-container>

            <v-col class="bc_white tc_black">
                <v-row style="margin-top: 5%"  class="font-weight-regular
                                        text-xl-h5
                                        text-lg-body-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption">
                    <v-col offset-lg="1" lg="10" >
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">Comment choisir une jante ?</p>
                        <br>
                        <p>
                            Il va sans dire que les les jantes d’une Audi A3, les jantes d’une Porsche Cayenne, les jantes d’une BMW série 3 ou les jantes d’une Golf VW ne se ressemblent pas et ne sont pas compatibles entre elles.
                            <br>
                            Une jante est propre à un modèle de véhicule et à une marque.
                        </p>
                        <p>
                            Caractéristiques principales d’une jante :
                            <br>
                            - Type, largeur, diamètre, déport, profil de la joue, contour,
                            Ces indications, en lettres et en chiffres, sont mentionnées sur la jante.
                            <br>
                            - Largeur et diamètres de la jante sont indiquées en pouces (1 pouce : 2,54 cm)
                            <br>
                            - Vérifier l’entraxe et nombre d’écrous (entre 3 et 6) pour la fixation avec le moyeu, sauf pour les roues à rayons dotées d’une seule fixation centrale.
                        </p>

                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">Comment monter une jante ?</p>
                        <p>
                            Rappelons l’importance du couple pneus-jantes sur les performances de votre véhicule (vitesse, accélération) et sur la sécurité : les roues sont étroitement liées au bon fonctionnement des systèmes de direction, de freinage, et à la tenue de route qui plus est, en cas de sol glissant et de fortes pluies.
                        </p>

                        <p>
                            Ainsi, à défaut de maîtriser de bonnes connaissances mécaniques, vous aurez le loisir de vous adresser à un professionnel pour monter les jantes sur votre voiture. Quoi qu’il en soit, il est indispensable de suivre les notices de montage et de se conformer aux normes constructeur, variables selon les pays.
                        </p>
                        <p>
                            Rappel des consignes de base pour monter une jante :<br>
                            - Reprise des écrous et autres éléments de fixation d’origine, fournis par le constructeur,<br>
                            - Montage de 4 jantes identiques,<br>
                            - Contrôle après installation de nouvelles jantes et/ou de nouveaux pneus, de la pression des pneus, de l’équilibrage des roues ainsi que de l’usure des valves.<br>
                            - Serrage des jantes à l’aide d’une clef dynamométrique : se référer aux indications de couple de serrage transmises par le constructeur.

                        </p>
                        <p>
                            Nous vous invitons à lire notre article réservé à la règlementation relative aux jantes (code de la route, contrôle technique…).
                        </p>

                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">Choix et montage d’un pneu sur la jante</p>
                        <p>
                            Rappelons les dispositions normales d’un pneu :<br>
                           - Résistance à la déformation,<br>
                           - Elasticité pour optimiser l’amortissement des chocs et des aspérités de l’asphalte,<br>
                           - Dissipation thermique : freinage et frottement entraînent une forte chaleur qui doit s’éliminer rapidement.

                        </p>
                        <p>
                            Les caractéristiques techniques des pneus sont précisées dans le manuel constructeur voire dans le véhicule (boîte à gants, clapet du réservoir, portière…)
                        </p>
                        <p>
                            Il est obligatoire de monter, sur un même essieu, deux pneus identiques en tout point : structure, marque, dimension, catégorie.
                        </p>
                        <p>
                            A titre informatif, nous vous conseillons de veiller à ne pas détériorer la jante lors du changement de pneu.
                        </p>
                        <p>
                            Le constructeur recommande deux indices spécifiques, indiqués sur le flanc du pneu, qu’il est primordial de respecter :
                        </p>
                        <p>
                            - Indice de charge : poids (exprimé en KG) maximal que peut supporter le pneu
                            En montant un pneumatique conçu avec un indice de charge inférieur, la jante est sollicitée anormalement et va accuser une partie des chocs à la place du pneu.
                        </p>
                        <p>
                           - Indice de vitesse (maximum du pneu)
                            En cas d’indice de vitesse incorrect, vous risquez un éclatement du pneu, une perte de votre garantie en cas de problème et en plus, une belle amende en cas de contrôle de police !
                        </p>
                        <p>
                            On distingue 3 profils de pneus :

                            - Les pneus symétriques : plus fréquents dans les petites tailles, équipent souvent les petites citadines, sans sens de montage.<br>
                            - Les pneus directionnels : sens de montage indiqué par une flèche gravée sur le flanc du pneu, permutation avant- arrière possible, permutation gauche-droite interdite.<br>
                            - Les pneus asymétriques : plus fréquents dans les grandes tailles de pneus (voitures sportives puissantes)<br>
                            Montage des deux pneus dans un sens différent par rapport à la jante : côté du pneu portant l’inscription « Outside » monté à l’extérieur et côté du pneu « Inside » monté à l’intérieur.
                        </p>
                        <p>
                            Enfin, les pneus et les jantes ont des profils spécifiques selon l’usage auquel ils sont destinés : urbain, autoroutier, sportif, utilitaire et 4x4.
                        </p>

                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">Comment changer une roue de secours ?</p>


                        <p>
                            Choisir une jante, un pneu, certes ! mais que faire en cas de crevaison, un risque qui statistiquement va vous arriver au moins une fois dans votre vie !
                        </p>
                        <p>
                            Voici qui vous rappellera quelques notions de base acquises lors de votre permis de conduire :
                        </p>
                        <p>
                            - Immobiliser son véhicule dans un endroit sécurisé par rapport à la circulation, sur une surface plane et retirer les éventuels enjoliveurs,<br>
                            - Dévisser quelque peu les écrous de la jante de la roue crevée (sens inverse des aiguilles d’une montre)<br>
                            - Positionner le cric dans les encoches à l’avant et à l’arrière de la roue en question, le fixer et tourner jusqu’au levage du véhicule.<br>
                            - Desserrer totalement les boulons de la jante et retirer la roue crevée,<br>
                            - Placer la roue de secours (rangée dans le coffre de la voiture) et visser les écrous (sens normal).<br>
                        </p>
                        <p>
                            Vous serez ainsi en mesure de vous rendre au garage le plus proche pour faire réparer la roue crevée. Une roue de secours est un dispositif de dépannage transitoire, vous ne devez en aucun cas effectué de longs trajets.
                        </p>
                        <p class="font-weight-bold
                           text-xl-h5
                           text-lg-h6
                           text-md-subtitle-1
                           text-sm-subtitle-1
                           text-subtitle-2">Un peu d’esthétique sur les jantes : enjoliveurs et tuning</p>

                        <p>
                            1) Les enjoliveurs
                        </p>
                        <p>
                            Vous souhaitez camoufler des jantes en tôle quelque peu disgracieuses, ou des jantes en alu abimées, personnaliser votre véhicule ou renforcer l’identité visuelle de la marque de votre voiture ?
                        </p>
                        <p>
                            Les enjoliveurs permettent d’habiller les jantes partiellement voire totalement. Ils sont disponibles en différentes tailles, différentes formes ou différentes matières. Ils se déclinent en plusieurs modèles. Chaque marque de véhicule a développé sa propre gamme d’enjoliveurs avec notamment reprise de leur logo.
                        </p>
                        <p>
                            Les chapeaux de roues (autre nom donné à l’enjoliveur) se fixent aisément sur la jante au moyen de clips ou peuvent même se visser pour éviter tout risque de vol.
                        </p>
                        <p>
                            L’enjoliveur, en dehors de toute considération esthétique, est également utile pour préserver les écrous des jantes de toutes les projections extérieures. Notons, de surcroît, qu’ils résistent aux jets haute pression des stations de lavage.
                        </p>
                        <p>
                            Le diamètre des enjoliveurs adaptés à vos jantes est indiqué sur le flanc du pneu.
                        </p>
                        <p>
                            2) Tuning
                        </p>
                        <p>
                            Le tuning consiste à customiser ou personnaliser un véhicule ce qui revient à apporter des modifications à un modèle de série.
                        </p>
                        <p>
                            L’objectif du tuning est double : changer de look et accroître les performances intrinsèques de la voiture d’où une intervention au niveau du paramétrage du moteur et de ses équipements mécaniques périphériques.
                        </p>
                        <p>
                            La jante est une des premières pièces à laquelle un amateur de tuning s’intéresse.
                        </p>
                        <p>
                            Tout changement de jante impacte directement sur le système de freinage, la direction, la tenue de route…
                        </p>
                        <p>
                            Attention de bien vous référer aux normes-constructeurs avant d’envisager une modification de la largeur et du diamètre de vos jantes.
                        </p>
                        <p>
                            Il existe une grande variété de packs de jantes disponibles dans tous les styles : classique, sportif…
                        </p>
                        <p>
                            Vous pourrez vous référer à des fédérations spécialisées dans le tuning pour toute question quant à la règlementation en vigueur, à la sécurité…ou tout conseil d’ordre technique ou esthétique relatif aux jantes.
                        </p>
                    </v-col>
                </v-row>

            </v-col>
        </v-main>



        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "../headers/HomepageHeaderEj";
import HomepageHeaderDj from "../headers/HomepageHeaderDj";
import HomepageFooterEj from "../footer/HomepageFooterEj";
import HomepageFooterDj from "../footer/HomepageFooterDj";

    export default {
        name: "JantesConseils",
        components:{
            HomepageHeaderEj,
            HomepageHeaderDj,
            HomepageFooterEj,
            HomepageFooterDj,

        },
        data: () => ({
            VUE_APP_SITE_ID:null
        }),
        metaInfo() {
            return {
                title: `Conseils - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + `  - Jantes, Pneus & Accessoires`,
                meta: [
                    {name: 'twitter:card', content: 'summary'},
                    {name: 'twitter:title', content: `Conseils - ` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {name: 'twitter:description', content: `Découvrez les conseils ` + (this.VUE_APP_SITE_ID === '1' ? `d'Espace Jantes` : `de Distri Jantes`) + `, le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.`},
                    // image must be an absolute path // 280 par 150
                    {name: 'twitter:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},

                    {property: 'og:title', content: `Conseils -` + (this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`) + ` - Jantes, Pneus & Accessoires`},
                    {property: 'og:site_name', content: this.VUE_APP_SITE_ID === '1' ? `Espace-Jantes.com` : `Distri-Jantes.com`},
                    {property: 'og:type', content: 'Website'},
                    {property: 'og:image', content: this.VUE_APP_SITE_ID === '1' ? `https://www.espace-jantes.com/imageV2/auto-plus_article.jpeg` : ``},
                    {property: 'og:description', content: `Découvrez les conseils ` + (this.VUE_APP_SITE_ID === '1' ? `d'Espace Jantes , le spécialiste de la vente en ligne de Jantes, pneus et accessoires pour votre voiture.` : `de Distri Jantes l'expert en ligne des jantes`)},
                    {name: 'robots', content: 'index,follow'}
                ]
            }
        },
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
        }
    }
</script>

<style scoped>
    .border_left{
        border-left: 2px solid #1DE4AF
    }
    .rotate {
        transform: rotate(-90deg);
        margin-left: -8%;
        margin-top: 12%;
        position: absolute;
    }
</style>
