<template>

    <v-app  class="backgroud_gradient" fluid >

        <HomepageHeaderEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageHeaderDj v-else-if="VUE_APP_SITE_ID === '2'"/>

        <v-content>

            <v-col class="bc_white text-center">
                <p class="font-weight-bold" :class="{'display-2': $vuetify.breakpoint.mdAndUp,'display-1': $vuetify.breakpoint.smAndDown}">Malheureusement votre mode de paiement a été refusé.</p>
                <p class="font-weight-bold" :class="{'headline': $vuetify.breakpoint.mdAndUp,'title': $vuetify.breakpoint.smAndDown}">Quelque chose s'est mal passé durant le paiement de votre commande.</p>
                <p class="font-weight-bold" :class="{'headline': $vuetify.breakpoint.mdAndUp,'title': $vuetify.breakpoint.smAndDown}">Veuillez réessayer, si l'erreur persiste contactez nous au <a class="no_deco" href="tel:+33970721916">09 70 72 19 16</a>.</p>

                <RouterLink :to="{name: 'Etape5', params: {marque: this.$route.params.marque, modele : this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur, decale: this.$route.params.decale }}" class="no_deco" >
                    <v-btn class="font-weight-bold">Retour à ma commande</v-btn>
                </RouterLink>
            </v-col>
        </v-content>

        <HomepageFooterEj v-if="VUE_APP_SITE_ID === '1'"/>
        <HomepageFooterDj v-else-if="VUE_APP_SITE_ID === '2'"/>

    </v-app>
</template>

<script>
import HomepageHeaderEj from "../headers/HomepageHeaderEj";
import HomepageHeaderDj from "../headers/HomepageHeaderDj";
import HomepageFooterEj from "../footer/HomepageFooterEj";
import HomepageFooterDj from "../footer/HomepageFooterDj";
import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {mapState} from "vuex";
// eslint-disable-next-line no-unused-vars
import CryptoJS from "crypto-js";
Vue.use(VueAxios, axios)

import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

export default {
name: "Etape6.vue",
    components: {
        HomepageHeaderEj,
        HomepageHeaderDj,
        HomepageFooterEj,
        HomepageFooterDj,
    },
    data:() =>({
        avisVerifCommande:{},
        formatedJanteAv : {},
        formatedJanteArr : {},
        formatedPneuAv : {},
        formatedPneuArr : {},
        paymentError: null,
        test: {},
        SelectedJanteAv: {},
        SelectedJanteArr: {},
        SelectedPneuAv: {},
        SelectedPneuArr: {},
        refChecking:null,
        VUE_APP_SITE_ID:null
    }),
    mounted() {
        this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID
    },
    created() {
        this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID




    },
    methods:{
        encryptWithKey(plaintext, key){
            var encrypted = CryptoJS.AES.encrypt(plaintext, key, { mode: CryptoJS.mode.ECB });
            console.log("Ciphertext (Base64):\n" + encrypted.toString());        // Ciphertext
            var decrypted = CryptoJS.AES.decrypt(encrypted.toString(), key, { mode: CryptoJS.mode.ECB });
            console.log("Decrypted:\n" + decrypted.toString(CryptoJS.enc.Utf8)); // Plaintext
        },
        getOnglet(value){
            switch(value){
                case'jantesAv':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesAv
                case 'jantesArr':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes').jantesArr
                case 'pneusAv':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusAv
                case 'pneusArr':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus').pneusArr
                case 'accessoires':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').accessoires
                case 'totalPriceAccessoires':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires').totalPrice
                case 'modelePossibleAuCarre':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre')
                case 'modelePossibleFront':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront')
                case 'modelePossibleRear':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear')
                case 'reference':
                    return ls.get(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__reference')

            }
        },
        removeOngletData(value){

            var lastUpdated = ls.get('last_updated')

            switch(value){
                case"jantes":
                    ls.remove(lastUpdated + '__infosJantes')
                    ls.remove(lastUpdated + '__infosPneus')
                    ls.remove(lastUpdated + '__infosAccessoires')

                    this.triggerUpdate ++
                    break
                case"pneus":
                    ls.remove(lastUpdated + '__infosPneus')

                    this.triggerUpdate ++
                    break
                case"accessoires":
                    ls.remove(lastUpdated + '__infosAccessoires')
                    this.triggerUpdate ++
                    break
                case"all":
                    ls.remove(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosJantes')
                    ls.remove(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosPneus')
                    ls.remove(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__infosAccessoires')
                    ls.remove(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleAuCarre')
                    ls.remove(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleFront')
                    ls.remove(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__modelePossibleRear')
                    ls.remove(this.$route.params.marque + "/" + this.$route.params.modele + "/" + this.$route.params.diametre + "/" + this.$route.params.couleur + "/" + this.$route.params.decale + '__reference')
                    this.$store.commit('nbrCart/setNbrCart', 0)
                    break
            }
        },
        formatDate: function() {
            let d = new Date();
            let month = '' + (d.getMonth() + 1);
            let day = '' + d.getDate();
            let year = d.getFullYear();
            let hour = ' ' + d.getHours();
            let min = d.getMinutes();
            let sec = d.getSeconds();

            if (month.length < 2)
                month = '0' + month;
            if (day.length < 2)
                day = '0' + day;
            if (hour.length < 3) // hour.length < 3 because there is a space
                hour = '0' + hour;
            if (min < 10)
                min = '0' + min;
            if (sec < 10)
                sec = '0' + sec;

            return [year, month, day].join('-') + [hour, min, sec].join(':');
        },
    },
    computed:{
        ...mapState({
            getUserId: state => state.auth.userId,
            AddrFact : state => state.addr_commande.addr_commande_facturation,
            AddrLivr : state => state.addr_commande.addr_commande_livraison,
            notesCommande : state => state.addr_commande.notesCommande,
            infoValves : state => state.infoVehicule.infoValves,
            Vehicule : state => state.infoVehicule.Vehicule,
            isProfessional: state => state.auth.isRevendeur,
            // refChecking: state => state.refChecking.ref,
            typePaiement: state => state.typePayement.typePayement,
            loaderStatus: state => state.loader.isLoader,
            infoCommande : state => state.infoCommande.infoCommande,
        })
    }
}
</script>

<style scoped>

</style>
