

const state = () =>({
    Next:'true'
})



const mutations = {
    setNext(state, config){

        if(config){
            state.Next = config
        }
    },

    getNext: state => state.Next,
    deleteNext: state => state.Next = '',
    // setNext: (state, value) => {
    //     if(value) {
    //         state.Next = value
    //     }
    // }

}


const actions = {

}


export default {
    namespaced: true,
    state,
    actions,
    mutations
}