<template>
    <v-app>
        <v-col class="topPage" lg="12">
            <v-row>
                <v-col cols="12" v-if="$vuetify.breakpoint.mdAndDown">
                    <v-btn width="100%" @click="emitDrawer">Modifier mes filtres</v-btn>
                </v-col>
                <!-- <v-col lg="2" cols="12" class="pb-0 px-1 font-weight-bold " :class="{'subtitle-1':$vuetify.breakpoint.lg, 'title':$vuetify.breakpoint.xl}" >
                    <p style="border-bottom: solid" class="">TRIER PAR</p>
                </v-col> -->
                <!-- <v-col lg="4" class="pa-0" :class="{'mb-6':$vuetify.breakpoint.mdAndDown}">
                    <v-select v-model="filter_default" hide-details :items="filters" item-text="text" solo  class="pa-0  font-weight-bold" :class="{'body-2':$vuetify.breakpoint.lg}"/>
                </v-col> -->
                <v-col v-if="$vuetify.breakpoint.lgAndUp" @click="SetPage" cols="8" class="pt-0 align-self-center">
                    <ais-pagination v-if="VUE_APP_SITE_ID === '1'" class="pt-3" :class-names="{
                        'ais-Pagination': 'MyCustomPaginationRoot',
                        'ais-Pagination-item': 'MyCustomPagination',
                        'ais-Pagination-list': 'MyCustomPagination',
                        'ais-Pagination-link': 'MyCustomPagination',
                        'ais-Pagination-item--selected': 'MyCustomPaginationActiveEJ',
                        'ais-Pagination-item--firstPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--previousPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--nextPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--lastPage': 'MyCustomPaginationNavigate',
                        }"
                    />
                    <ais-pagination v-else-if="VUE_APP_SITE_ID === '2'" class="pt-3" :class-names="{
                        'ais-Pagination': 'MyCustomPaginationRoot',
                        'ais-Pagination-item': 'MyCustomPagination',
                        'ais-Pagination-list': 'MyCustomPagination',
                        'ais-Pagination-link': 'MyCustomPagination',
                        'ais-Pagination-item--selected': 'MyCustomPaginationActiveDJ',
                        'ais-Pagination-item--firstPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--previousPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--nextPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--lastPage': 'MyCustomPaginationNavigate',
                        }"
                    />

                </v-col>
            </v-row>

            <ais-configure :hitsPerPage="20" :page="SavePage"/>
            <ais-stats class=" font-weight-bold">
                <h1 slot-scope="{nbHits}" class=" font-weight-bold tc_black "  :class="{'headline':$vuetify.breakpoint.lg, 'display-1':$vuetify.breakpoint.xl}" style="color: #324F9E">
                    <span v-if="nbHits === 0">Aucun résultat ne correspond à votre recherche</span>

                </h1>
            </ais-stats>
            <!-- <ais-configure :hitsPerPage="20" filters='diametre > 20 AND marque:GMP' :query="query" /> -->
            <ais-hits class="1" :class-names="{'ais-Hits': 'MyCustomHits','ais-Hits-list': 'MyCustomHitsList','ais-Hits-item':'MyCustomHitsItem' + (this.VUE_APP_SITE_ID === '2' ? ' ais-distri' : '')}" :transform-items="transformItems">

                <v-img v-if="loaderStatus && VUE_APP_SITE_ID === '1'" src="@/assets/loader.gif" style="max-width: 40%; margin: auto" alt='Espace-Jantes - Chargement Jantes' title="Espace-Jantes - Chargement Jantes"/>
                <v-img v-if="loaderStatus && VUE_APP_SITE_ID === '2'" src="@/assets/loaderDJ.gif" style="max-width: 30%; margin: auto" alt='Distri-Jantes - Chargement Jantes' title="Distri-Jantes - Chargement Jantes"/>


                <div class="2" slot="item" slot-scope="{ item }" >
<!--                    <router-link v-if="$vuetify.breakpoint.lgAndUp " @click.native="envoiDonnees2(item, index)" :to="{name: 'Etape1', params: {  idJantes: item.objectID, data: envoiDonnees(index, item) }}" class="no_deco tc_black">-->
                    <router-link v-if="$vuetify.breakpoint.lgAndUp " :to="{name: 'Etape1', params: {  idJantes: item.objectID, marque: item.marque, modele : item.modele, diametre: item.diametre, couleur: item.couleur, decale: getDeca() }}" class="no_deco tc_black">
                        <v-card :elevation="0" cols="3"  class="" @click="envoiDonnees(item)" >
<!--                          <v-card v-if="$vuetify.breakpoint.lgAndUp" :elevation="0" >-->
                            <v-img v-if="VUE_APP_SITE_ID === '1'" @error="item.photo_1 = 'http://pay.espace-jantes.com/img/specimentJantes.png'" :class="{'ej-watermark': VUE_APP_SITE_ID === '1', 'dj-watermark': VUE_APP_SITE_ID === '2'}" style="padding-top: 2%" :src="item.photo_1" :alt='"Jante - " + item.marque + " - " + item.modele + " - " + Vehicule.marque + " - " + Vehicule.modele ' :title='"Jante - " + item.marque + " - " + item.modele + " - " + Vehicule.marque + " - " + Vehicule.modele '></v-img>
                            <v-img v-if="VUE_APP_SITE_ID === '2'" :class="{'ej-watermark': VUE_APP_SITE_ID === '1', 'dj-watermark': VUE_APP_SITE_ID === '2'}" style="padding-top: 2%" :src="item.photo_1" :alt='"Jante - " + item.marque + " - " + item.modele + " - " + Vehicule.marque + " - " + Vehicule.modele ' :title='"Jante - " + item.marque + " - " + item.modele + " - " + Vehicule.marque + " - " + Vehicule.modele '></v-img>
                            <p class="pt-2 mb-0 body-2 font-weight-bold text-center">{{ item.marque }}</p>
                            <p class="mb-2 body-2 font-weight-bold text-center">{{ item.modele }}  / {{ item.diametre }}"</p>
                            <p class="mb-2 body-2 font-weight-bold text-center">{{ item.couleur }}</p>
                            <p class="mb-0 body-2 ">Les 4 jantes</p>
                            <p class="mb-0 body-2 font-weight-bold d-flex justify-end">{{  item.finalprice }} €</p>
                            <p class="mb-0 body-2 " >+ 4 pneus à partir de</p>
                            <p class="mb-2 body-2 font-weight-bold d-flex justify-end ">{{ item.finalpricePneu }} €</p>
                            <p v-if="getRandomInt(10) === 0" class="font-weight-bold mb-0 text-left" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">Plus que {{ getRandomInt(9) + 1 }} en stock</p>
                            <p v-else  class="font-weight-bold mb-0 text-left" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">En stock</p>

                          </v-card>
                    </router-link>
<!--                    <RouterLink  v-if="$vuetify.breakpoint.lgAndUp" :to="{name: 'DetailsJante', params : {jante: item.modele}}" @click.native="detailJante(item)" target='_blank' class="no_deco">-->
                    <RouterLink  v-if="$vuetify.breakpoint.lgAndUp" :to="{name: 'DetailsJante', params : {marque: item.marque, modele : item.modele, diametre: item.diametre, couleur: item.couleur, decale: getDeca() }}" @click.native="detailJante(item)" target='_blank' class="no_deco">
<!--                    <RouterLink @click="detailJante(item)">-->
                        <v-icon class="d-flex justify-end">mdi mdi-help-circle-outline</v-icon>
                    </RouterLink>
                    <router-link v-if="$vuetify.breakpoint.mdAndDown" :to="{name: 'Etape1', params: {  idJantes: item.objectID, marque: item.marque, modele : item.modele, diametre: item.diametre, couleur: item.couleur, decale: getDeca() }}"  class="no_deco tc_black">
                        <v-card :elevation="0" cols="4" class="mb-3 pa-2" @click="envoiDonnees(item)">
                            <v-img v-if="VUE_APP_SITE_ID === '1'" @error="item.photo_1 = 'http://pay.espace-jantes.com/img/specimentJantes.png'" class="ma-auto" :class="{'ej-watermark': VUE_APP_SITE_ID === '1', 'dj-watermark': VUE_APP_SITE_ID === '2'}" :src="item.photo_1" :alt='"Jante - " + item.marque + " - " + item.modele + " - " + Vehicule.marque + " - " + Vehicule.modele ' :title='"Jante - " + item.marque + " - " + item.modele + " - " + Vehicule.marque + " - " + Vehicule.modele '/>
                            <v-img v-if="VUE_APP_SITE_ID === '2'" class="ma-auto" :class="{'ej-watermark': VUE_APP_SITE_ID === '1', 'dj-watermark': VUE_APP_SITE_ID === '2'}" :src="item.photo_1" :alt='"Jante - " + item.marque + " - " + item.modele + " - " + Vehicule.marque + " - " + Vehicule.modele ' :title='"Jante - " + item.marque + " - " + item.modele + " - " + Vehicule.marque + " - " + Vehicule.modele '/>

                            <p class="pt-2 mb-0 body-2 font-weight-bold text-center">{{item.marque}}</p>
                            <p class="mb-2 body-2 font-weight-bold text-center">{{item.modele}}  / {{ item.diametre }}"</p>
                            <p class="mb-2 body-2 font-weight-bold text-center">{{ item.couleur }}</p>
<!--                            <p class="mb-2 body-2 font-weight-bold text-center">{{ item.objectID }}</p>-->
<!--                            <p class="mb-2 body-2 font-weight-bold text-center">{{ item.flux }}</p>-->
                            <p class="mb-0 body-2 ">Les 4 jantes</p>
                            <p class="mb-0 body-2 font-weight-bold d-flex justify-end">{{ item.finalprice }} €</p>

                            <p class="mb-0 body-2 " >+ 4 pneus à partir de</p>
                            <p class="mb-2 body-2 font-weight-bold d-flex justify-end ">{{ item.finalpricePneu }} €</p>

                            <p v-if="getRandomInt(10) === 0" class="font-weight-bold mb-0 text-left" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">Plus que {{ getRandomInt(9) + 1 }} en stock</p>
                            <p v-else class="font-weight-bold mb-0 text-left" :class="{'tc_darkBlue': VUE_APP_SITE_ID === '1', 'tc_orange': VUE_APP_SITE_ID === '2'}">En stock</p>
                        </v-card>
                    </router-link>
                    <v-col offset-md="10" offset-sm="10" offset="8" cols="2" v-if="$vuetify.breakpoint.mdAndDown" class="pt-0">
                        <RouterLink   :to="{name: 'DetailsJante', params : {jante: item.modele}}" @click.native="detailJante(item)" target='_blank' class="no_deco">
                            <v-icon class=" ">mdi mdi-help-circle-outline</v-icon>
                        </RouterLink>
                    </v-col>

                </div>
            </ais-hits>
            <v-col cols="8" class="pt-0 align-self-center">
            <div  @click="toTop">
                <ais-pagination v-if="VUE_APP_SITE_ID === '1'" class="pt-3" :class-names="{
                        'ais-Pagination': 'MyCustomPaginationRoot',
                        'ais-Pagination-item': 'MyCustomPagination',
                        'ais-Pagination-list': 'MyCustomPagination',
                        'ais-Pagination-link': 'MyCustomPagination',
                        'ais-Pagination-item--selected': 'MyCustomPaginationActiveEJ',
                        'ais-Pagination-item--firstPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--previousPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--nextPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--lastPage': 'MyCustomPaginationNavigate',
                        }"
                />
                <ais-pagination v-else-if="VUE_APP_SITE_ID === '2'" class="pt-3" :class-names="{
                        'ais-Pagination': 'MyCustomPaginationRoot',
                        'ais-Pagination-item': 'MyCustomPagination',
                        'ais-Pagination-list': 'MyCustomPagination',
                        'ais-Pagination-link': 'MyCustomPagination',
                        'ais-Pagination-item--selected': 'MyCustomPaginationActiveDJ',
                        'ais-Pagination-item--firstPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--previousPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--nextPage': 'MyCustomPaginationNavigate',
                        'ais-Pagination-item--lastPage': 'MyCustomPaginationNavigate',
                        }"
                />
                </div>
            </v-col>

            <v-content v-if="VUE_APP_SITE_ID === '1'" class="bc_white">

                <v-col offset-lg="1" offset-xl="2" lg="10" xl="8">
                    <v-row >
                        <v-col v-for="card in cardsEJ" :key="card.title" lg="3" xl="3" md="3" sm="3" cols="6" class="px-2" >
                            <v-card style=" height: 100%" hover >
                                <v-card-text class="text-center align-self-center justify-center">
                                    <v-content class="px-5">
                                        <v-img aspect-ratio="1"  :src="card.img"></v-img>
                                    </v-content>
                                    <div class="tc_turquoise
                                        font-weight-bold
                                        text-xl-h5
                                        text-lg-h6
                                        text-md-subtitle-1
                                        text-sm-body-2
                                        text-caption" style="margin-top: 15%">
                                        {{card.title}}
                                    </div>
                                    <p class="text--primary
                                        font-weight-regular
                                        text-xl-h5
                                        text-lg-subtitle-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption" style="color:black;padding-top:4%">
                                        {{card.text}}
                                    </p>
                                </v-card-text>
                            </v-card>

                        </v-col>

                    </v-row>
                </v-col>
            </v-content>

            <v-content v-else-if="VUE_APP_SITE_ID === '2'" class="bc_white">

                <v-col offset-lg="1" offset-xl="2" lg="10" xl="8">
                    <v-row >
                        <v-col v-for="card in cardsDJ" :key="card.title" lg="3" xl="3" md="3" sm="3" cols="6" class="px-2" >
                            <v-card style=" height: 100%" hover >
                                <v-card-text class="text-center align-self-center justify-center">
                                    <v-content class="px-5">
                                        <v-img aspect-ratio="1" :src="card.img" style="max-width: 50%; margin: auto"></v-img>
                                    </v-content>
                                    <div class="tc_orange
                                        font-weight-bold
                                        text-xl-h5
                                        text-lg-h6
                                        text-md-subtitle-1
                                        text-sm-body-2
                                        text-caption" style="margin-top: 15%">
                                        {{card.title}}
                                    </div>
                                    <p class="text--primary
                                        font-weight-regular
                                        text-xl-h6
                                        text-lg-subtitle-1
                                        text-md-caption
                                        text-sm-caption
                                        text-caption" style="color:black;padding-top:4%">
                                        {{card.text}}
                                    </p>
                                </v-card-text>
                            </v-card>

                        </v-col>

                    </v-row>
                </v-col>
            </v-content>

        </v-col>
  </v-app>
</template>

<script>
    //import Pagination from 'vue-pagination-2';

    //import jante from '@/assets/jante.png';
    import cardCbEJ from "@/assets/card-cbEJ.svg";
    import cardDeliveryEJ from "@/assets/card-deliveryEJ.svg";
    import cardGuaranteeEJ from "@/assets/card-guaranteeEJ.svg";
    import cardRefundedEJ from "@/assets/card-refundedEJ.svg";

    import cardCbDJ from "@/assets/card-cbDJ.svg";
    import cardDeliveryDJ from "@/assets/card-deliveryDJ.svg";
    import cardGuaranteeDJ from "@/assets/card-guaranteeDJ.svg";
    import cardRefundedDJ from "@/assets/card-refundedDJ.svg";

    import { mapState} from "vuex";
    import SecureLS from "secure-ls";
    var ls = new SecureLS({ isCompression: false });

    import axios from 'axios'
    import VueAxios from 'vue-axios'
    import Vue from 'vue'
    import algoliasearch from "algoliasearch";

    // import $ from "jquery";

    // import {mapMutations, mapActions, mapState} from 'vuex'

    Vue.use(VueAxios, axios)

    export default {
        name: "ListingJante.vue",
        components:{
            //Pagination
        },
        data: () => ({
            filter_default: { text: 'Ordre Croissant (De A à Z)'},
            filters: [
                { text: 'Ordre Croissant (De A à Z)'},
                { text: 'Ordre décroissant (De Z à A)'},
                { text: 'Du moins cher au plus cher'},
                { text: 'Du plus cher au moins cher'},
            ],
            current:1,
            length:10,
            showed:5,
            page: 1,
            pageTmp:1,
            sliceMin:0,
            sliceMax:null,
            nbrDisplayed:16,
            articles:[],
            imageLoadError:false,
            nbreHits:0,
            cardsEJ: [
                {title:'Facilités de paiement',text:'En 4x par CB, sans justificatifs\n Accord immédiat!',img:cardCbEJ},
                {title:'Livraison gratuite', text:'Avec suivi des commandes', img:cardDeliveryEJ},
                {title:'Satisfait ou remboursé', text:'Délai de 14 jours de rétractation', img:cardRefundedEJ},
                {title:'Jantes garanties et controlées', text:'2 ans', img:cardGuaranteeEJ}
            ],
            cardsDJ: [
                {title:'Facilités de paiement',text:'En 4x par CB, sans justificatifs\n Accord immédiat!',img:cardCbDJ},
                {title:'Livraison gratuite', text:'Avec suivi des commandes', img:cardDeliveryDJ},
                {title:'Satisfait ou remboursé', text:'Délai de 14 jours de rétractation', img:cardRefundedDJ},
                {title:'Jantes garanties et controlées', text:'2 ans', img:cardGuaranteeDJ}
            ],
            currentIndex:null,
            VUE_APP_SITE_ID:null

        }),
        created (){
            if(this.idUser != "") {

                    Vue.axios.get('https://api.espace-jantes.com/getOneUser', {
                        params: { id_user: this.idUser },
                    })
                    .then((response) => {
                        if(response.data[0].status == 2){
                            // this.isProfessional = true
                            this.$store.commit('auth/setRevendeur', true)
                        }
                    })
                    .catch(error => console.log(error))
                }

        },
        mounted() {
            this.VUE_APP_SITE_ID = process.env.VUE_APP_SITE_ID

            this.sliceMax = this.nbrDisplayed;
            // var el = document.getElementById('toTop')
            //
            //
            // el.addEventListener("click", function (){
            //     window.scrollTo(0,0)
            // });


            // console.log('Vehicule MARQUE', this.Vehicule.marque)
            if( process.env.NODE_ENV.trim() !== "development") {

                window.dataLayer.push({
                    'event': 'Pageview',
                    'pagePath': '/listing',
                    'pageTitle': 'Listing'
                });
            }
        },
        methods: {
            getDeca(){
                if(ls.get('MontageSave') === "par4" || ls.get('monteType') === "par4"){
                    return 0
                }else{
                    return 1
                }
            },
            SetPage(){
                setTimeout(()=>{
                    this.$store.commit('SavePage/setPage', (document.querySelector('li.ais-Pagination-item--selected a').innerHTML -1))
                },500)
            },
            methodOncreated(onCreate = true) {

                var monteType = this.SaveMontage
                console.log('monteTyep is', monteType)

                var infoChassis
                if( typeof ls.get('chassisVehiculeUtilisateur') == "string" ){
                    infoChassis = JSON.parse(ls.get('chassisVehiculeUtilisateur'))
                }
                else if(typeof ls.get('chassisVehiculeUtilisateur') == "object"){
                    infoChassis = ls.get('chassisVehiculeUtilisateur')
                }

                if(infoChassis.entraxe.includes('.'))
                {
                    infoChassis.entraxe = infoChassis.entraxe.split('.')[0]
                    ls.set('chassisVehiculeUtilisateur', infoChassis )
                }

                // infoChassis.entraxe =
                var stock = ' ( NOT stock:0 AND NOT stock:1 AND NOT stock:2 AND NOT stock:3 ) '
                var visibility = ' AND visible:1 '

                if(monteType == "decale"){
                    // // var infoOptionET = JSON.parse(this.$localStorage.get('storeEt'))
                    // var infoOptionET = JSON.parse(ls.get('storeEt'))
                    var modelePossibleFront = []
                    var modelePossibleRear = []

                    if(onCreate){
                        this.dimensionConfigurateur = ls.get('diametre')
                    }
                    const client = algoliasearch('IXJF8AJTH7', 'c4c7c9439e003674579cb68a3352acc5');
                    const index = client.initIndex('dev_EJ');
                    //this.infoJante = {marque: this.$route.params.marque, modele: this.$route.params.modele, diametre: this.$route.params.diametre, couleur: this.$route.params.couleur}


                    index.search('', {
                        // filters:'modele: "' + this.infoJante.modele + '" AND marque: "' + this.infoJante.marque + '" AND diametre: ' + this.infoJante.diametre +' AND entraxe:"'+ this.infoJante.entraxe +'" AND ' + stock + ' AND  alesage >='+ infoChassis.alesage + ' AND '+ stock + visibility,
                        filters:'modele: "' + this.$route.params.modele + '" AND marque: "' + this.$route.params.marque + '" AND diametre: ' + this.$route.params.diametre +' AND entraxe:"'+ infoChassis.entraxe +'" AND  alesage >='+ infoChassis.alesage + ' AND ' + stock + visibility,
                        distinct: false,
                        hitsPerPage: 1000,
                    })
                        .then(({ hits }) => {
                            hits.forEach(element => {

                                this.optionFront.forEach(elementFront => {
                                    if(parseFloat(elementFront.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                        if(element.offset >= elementFront.minOffset && element.offset <= elementFront.maxOffset){
                                            if(modelePossibleFront.indexOf(element) == -1){
                                                modelePossibleFront.push(element)
                                            }
                                        }
                                    }
                                })

                                this.optionRear.forEach(elementRear => {
                                    if(parseFloat(elementRear.wheelSize.split('x')[0].trim()).toFixed(1) == parseFloat(element.largeur).toFixed(1)){
                                        if(element.offset >= elementRear.minOffset && element.offset <= elementRear.maxOffset){
                                            if(modelePossibleRear.indexOf(element) == -1){
                                                modelePossibleRear.push(element)
                                            }
                                        }
                                    }
                                })
                            })

                            var couleurFront = []
                            var couleurRear = []

                            var largeurMini = []
                            var largeurMaxi = []
                            //Passage pour l'etape 2
                            // ls.set('modelePossibleFront', JSON.stringify(modelePossibleFront))
                            // this.setOnglet(modelePossibleFront, 'modelePossibleFront' )
                            // console.log('modelePossibleFront', modelePossibleFront)
                            // this.$localStorage.set('modelePossibleRear', JSON.stringify(modelePossibleRear))
                            // ls.set('modelePossibleRear', JSON.stringify(modelePossibleRear))
                            // this.setOnglet(modelePossibleRear,'modelePossibleRear')

                            console.log('modelePossibleFront', modelePossibleFront)


                            modelePossibleFront.forEach(elementFront => {
                                if(largeurMini.length == 0 || (largeurMini[0].largeur > elementFront.largeur  )){
                                    if(this.infoJante.couleur == elementFront.couleur){
                                        // if(largeurMini.length == 0 || largeurMini[0].offset < elementFront.offset) {
                                        largeurMini = []
                                        largeurMini.push(elementFront)
                                        // console.log('elementFront')
                                        // console.log(elementFront)
                                    }
                                }
                                const resultat = couleurFront.find( couleur => couleur.couleur === elementFront.couleur);

                                if( resultat == 'undefined' || resultat == undefined){
                                    var infoCouleur = {}
                                    var couleurFinale =  elementFront.couleur
                                    // var couleurFinale =  elementFront.couleur.replace(/\s/g, "+")
                                    infoCouleur.couleurFinale = couleurFinale
                                    infoCouleur.couleur = elementFront.couleur
                                    infoCouleur.image = elementFront.photo_1
                                    infoCouleur.reference = elementFront.objectID
                                    couleurFront.push(infoCouleur)
                                }
                            })
                            modelePossibleRear.forEach(elementRear => {
                                if(largeurMaxi.length == 0 || largeurMaxi[0].largeur < elementRear.largeur){
                                    if(largeurMaxi.length == 0 || (largeurMaxi[0].offset < elementRear.offset   ) ) {
                                        if(this.infoJante.couleur == elementRear.couleur){
                                            largeurMaxi = []
                                            largeurMaxi.push(elementRear)
                                        }
                                    }
                                }
                                const resultat = couleurRear.find( couleur => couleur.couleur === elementRear.couleur);

                                if( resultat == 'undefined' || resultat == undefined){
                                    var infoCouleur = {}
                                    var couleurFinale =  elementRear.couleur
                                    // var couleurFinale =  elementRear.couleur.replace(/\s/g, "+")
                                    infoCouleur.couleurFinale = couleurFinale
                                    infoCouleur.couleur = elementRear.couleur
                                    infoCouleur.image = elementRear.photo_1
                                    infoCouleur.reference = elementRear.objectID
                                    couleurRear.push(infoCouleur)
                                }
                            })

                            var couleurDispo = []
                            couleurFront.forEach(elementFront =>{
                                couleurRear.forEach(elementRear => {

                                    if(elementFront.couleur == elementRear.couleur && couleurDispo.indexOf(elementRear) == -1 &&  couleurDispo.indexOf(elementFront) == -1){
                                        couleurDispo.push(elementRear)
                                    }
                                })
                            })
                            this.colorisDisponible = couleurDispo


                            this.infoJante.public_price = largeurMini[0].public_price
                            this.infoJante.public_price_rear = largeurMaxi[0].public_price

                        })

                }

            },
            getOptionchassis(){

                var infoChassis
                if( typeof ls.get('chassisVehiculeUtilisateur') == "string" ){
                    infoChassis = JSON.parse(ls.get('chassisVehiculeUtilisateur'))
                }
                else if(typeof ls.get('chassisVehiculeUtilisateur') == "object"){
                    infoChassis = ls.get('chassisVehiculeUtilisateur')
                }
                if(infoChassis.entraxe.includes('.'))
                {
                    infoChassis.entraxe = infoChassis.entraxe.split('.')[0]
                    ls.set('chassisVehiculeUtilisateur', infoChassis )
                }
                var OptionFront = []
                var OptionRear = []

                Vue.axios.get('https://api.espace-jantes.com/getOptionsChassis', {params: {idChassisVehiculeUtilisateur : infoChassis.id_chassis}})
                    .then((response) => {
                        // var diametre = this.$localStorage.get('diametre')
                        var diametre = this.$route.params.diametre.toString()

                        response.data.forEach(element => {
                            var wheelSizeSplit = element.wheelSize.split('x')
                            if(element.upsetType == "RearUpstep") {

                                if(wheelSizeSplit[1].trim() == diametre.trim()) {
                                    OptionRear.push(element)
                                }
                            }else{
                                if(wheelSizeSplit[1].trim() == diametre.trim()) {
                                    OptionFront.push(element)
                                }
                            }
                        })
                        // console.log(OptionFront)
                        this.optionFront = OptionFront
                        this.optionRear = OptionRear

                    })
            },
            transformItems(items) {
                items.forEach(item => {
                    // if(this.SaveMontage !== 'par4'){
                    //     this.methodOncreated()
                    // }
                    // else{
                        item.finalprice = this.getRoundPrice(item,'jante')
                        item.finalpricePneu = this.getRoundPrice(item,'pneu')
                    // }
                    // console.log('item', item)

                })
                var arrayDistinct = []
                // console.log('items', items)
                items.forEach(item => {

                    // if(item.visible === 1){

                        if(arrayDistinct.length == 0){
                            // item = null
                          arrayDistinct.push(item)
                        }
                        var information = arrayDistinct.filter(elementDistinct => { return elementDistinct.couleur.toString() === item.couleur.toString() && elementDistinct.modele.toString() === item.modele.toString()  })
                        if(information == 0){
                          arrayDistinct.push(item)
                        }

                    // }
                })
                // console.log(arrayDistinct.length)
                return items = arrayDistinct
                // return items
              },
            getRandomInt(max) {
                return Math.floor(Math.random() * Math.floor(max));
            },
            newTab: function(index, item){

                let params = item.marque + '/' + item.modele + '/' + item.diametre + '/' + item.couleur

                return params
            },
            envoiDonnees: function(item){
                // console.log('item 1', item)
                //public price
                // item = item
                // item.largeurAv = item.largeur
                // item.diametreAV = item.diametre
                // item.entraxeAv = item.entraxe
                // item.offsetAv = item.offset
                //
                // item.largeurArr = item.largeur
                // item.diametreArr = item.diametre
                // item.entraxeArr = item.entraxe
                // item.offsetArr = item.offset
                //
                // delete item.entraxe
                // delete item.largeur
                // delete item.offset
                //
                //
                //
                // console.log('item 2', item)
                if(this.monteStateDeca === true){
                    this.$store.commit('infoJantes/setMonteDeca', true)
                    this.$store.commit('infoPneus/setMonteDeca', true)
                }else if(this.monteStateDeca === false){
                    this.$store.commit('infoJantes/setMonteDeca', false)
                    this.$store.commit('infoPneus/setMonteDeca', false)
                }
                item.type = 1
                item.quantite = 2
                this.$store.commit('infoPneus/deletePneu')
                this.$store.commit('infoAccessoires/deleteAllAccessoires')
                this.$store.commit('infoAccessoires/deleteAllSelected')
                this.$store.commit('infoJantes/resetCodePromo')
                // this.$localStorage.set('infoJantes', JSON.stringify(item))
                ls.set('infoJantes', JSON.stringify(item))
                this.$store.commit('infoJantes/setJante', {'Av': item})
                this.$store.commit('infoJantes/setJante', {'Arr': item})
            },
            detailJante: function(item){
                item.type = 1
                item.quantite = 2

                // localStorage.setItem('detailsJante', JSON.stringify(item))
                ls.set('detailsJante', JSON.stringify(item))

            },
            emitDrawer:function(){
              this.$emit('drawerTrue')
            },
            toTop: function (){
                window.scrollTo(0,0)

            },
            getRoundPrice: function (item, Type){
                var price
                var tmp = Math.pow(10, 2);


                if(item.public_price === null) {
                    if (item.buying_price) {
                        item.public_price = item.buying_price * 1.2
                    } else {
                        item.public_price = 300
                    }
                }
                if(this.isProfessional){
                    let taux = (1 - parseFloat('0.' + this.tauxRemise))
                    taux = (Math.round( taux * tmp )/tmp).toFixed(2)

                    item.public_price = item.public_price * taux
                }
                if(Type === 'jante'){
                    price  = (item.public_price * 4)
                }else if(Type === 'pneu'){
                    price  = (item.public_price * 6)
                }

                return (Math.round( price * tmp )/tmp).toFixed(2)
            },
        },
        computed:{
            ...mapState({
                SelectedJanteAv: state => state.infoJantes.SelectedJanteAv,
                idUser: state => state.auth.userId,
                isProfessional: state => state.auth.isRevendeur,
                Vehicule: state => state.infoVehicule.Vehicule,
                loaderStatus: state => state.loader.isLoader,
                monteStateDeca: state => state.infoJantes.monteStateDeca,
                SavePage: state => state.SavePage.SavePage,
                tauxRemise: state => state.auth.tauxRemise,
                SaveMontage: state => state.filtreListingSave.SaveMontage


            })
        }

    }
</script>

<style >
    @media (max-width: 960px) {
        .MyCustomHitsItem {
            margin-top: 1rem !important;
            margin-right: 1rem !important;
            margin-left: 0rem !important;
            padding: 0 !important;
            width: calc(50% - 1rem) !important;
            border: 1px solid #c4c8d8 !important;
            -webkit-box-shadow: 0 2px 5px 0 #e3e5ec !important;
            box-shadow: 0 2px 5px 0 #e3e5ec !important;
        }
    }
    .MyCustomHitsItem.ais-distri {
        margin-top: 1rem;
        margin-left: 1rem;
        padding: 1rem;
        width: calc(20% - 1rem);
        border: 1px solid #c4c8d8;
        -webkit-box-shadow: 0 2px 5px 0 #e3e5ec;
        box-shadow: 0 2px 5px 0 #e3e5ec;
    }

    .ais-Pagination-list{
        padding: 0!important;
    }

    .MyCustomPaginationRoot{
        width: 40%;
        margin-left: auto;
        margin-right: 0;
    }

    .MyCustomPagination{
        /*text-decoration: underline;*/
        color:#232D4D!important;
        /*border-bottom: 2px solid black;*/
        border: none
    }
    .MyCustomPaginationActiveEJ .MyCustomPagination{
        /*text-decoration: underline;*/
        background-color:#1DE4AF
        /*border-bottom: 2px solid black;*/
    }
    .MyCustomPaginationActiveDJ .MyCustomPagination{
        /*text-decoration: underline;*/
        background-color:#FC5C1F;
        color: #ffffff!important;
        /*border-bottom: 2px solid black;*/
    }

    .MyCustomPaginationNavigate .MyCustomPagination{
        font-size: xx-large;
    }




</style>
<style scoped>
.ej-watermark::before {
    content: url('/img/ej-logo-baseline.e4a92426.svg');
    position: absolute;
    transform: translateY(-50%);
    width: 50%;
    opacity: 0.5;
    bottom: 0;
    right: 27%;
}
.dj-watermark::before {
    content: url('https://cpt-distri-jantes.s3.eu-west-3.amazonaws.com/dj-watermark.svg');
    position: absolute;
    transform: translateY(-50%);
    width: 50%;
    opacity: 0.5;
    bottom: 0;
    right: 27%;
}



</style>
